/**
 * @fileoverview gRPC-Web generated client stub for pomerium.dashboard
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!

/* eslint-disable */
// @ts-nocheck

import * as grpcWeb from 'grpc-web';

import * as activity_log_pb from './activity_log_pb';

export class ActivityLogServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string };
  options_: null | { [index: string]: any };

  constructor(
    hostname: string,
    credentials?: null | { [index: string]: string },
    options?: null | { [index: string]: any }
  ) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetActivityLogEntry = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.ActivityLogService/GetActivityLogEntry',
    grpcWeb.MethodType.UNARY,
    activity_log_pb.GetActivityLogEntryRequest,
    activity_log_pb.GetActivityLogEntryResponse,
    (request: activity_log_pb.GetActivityLogEntryRequest) => {
      return request.serializeBinary();
    },
    activity_log_pb.GetActivityLogEntryResponse.deserializeBinary
  );

  getActivityLogEntry(
    request: activity_log_pb.GetActivityLogEntryRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<activity_log_pb.GetActivityLogEntryResponse>;

  getActivityLogEntry(
    request: activity_log_pb.GetActivityLogEntryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: activity_log_pb.GetActivityLogEntryResponse
    ) => void
  ): grpcWeb.ClientReadableStream<activity_log_pb.GetActivityLogEntryResponse>;

  getActivityLogEntry(
    request: activity_log_pb.GetActivityLogEntryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: activity_log_pb.GetActivityLogEntryResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/pomerium.dashboard.ActivityLogService/GetActivityLogEntry',
        request,
        metadata || {},
        this.methodInfoGetActivityLogEntry,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ +
        '/pomerium.dashboard.ActivityLogService/GetActivityLogEntry',
      request,
      metadata || {},
      this.methodInfoGetActivityLogEntry
    );
  }

  methodInfoListActivityLogEntries = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.ActivityLogService/ListActivityLogEntries',
    grpcWeb.MethodType.UNARY,
    activity_log_pb.ListActivityLogEntriesRequest,
    activity_log_pb.ListActivityLogEntriesResponse,
    (request: activity_log_pb.ListActivityLogEntriesRequest) => {
      return request.serializeBinary();
    },
    activity_log_pb.ListActivityLogEntriesResponse.deserializeBinary
  );

  listActivityLogEntries(
    request: activity_log_pb.ListActivityLogEntriesRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<activity_log_pb.ListActivityLogEntriesResponse>;

  listActivityLogEntries(
    request: activity_log_pb.ListActivityLogEntriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: activity_log_pb.ListActivityLogEntriesResponse
    ) => void
  ): grpcWeb.ClientReadableStream<activity_log_pb.ListActivityLogEntriesResponse>;

  listActivityLogEntries(
    request: activity_log_pb.ListActivityLogEntriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: activity_log_pb.ListActivityLogEntriesResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/pomerium.dashboard.ActivityLogService/ListActivityLogEntries',
        request,
        metadata || {},
        this.methodInfoListActivityLogEntries,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ +
        '/pomerium.dashboard.ActivityLogService/ListActivityLogEntries',
      request,
      metadata || {},
      this.methodInfoListActivityLogEntries
    );
  }
}
