import MuiAvatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { FC, useRef, useState } from 'react';

import { pushError } from '../../../slices/notifications';
import { getErrorMessage } from '../../../utils/errors';
import ImpersonateFormDialog from './ImpersonateFormDialog';

import { Avatar } from 'src/components/Avatar';
import { ImpersonateIcon } from 'src/components/icons';
import { PomeriumSessionServiceClient } from 'src/pb/UsersServiceClientPb';
import { ImpersonateRequest } from 'src/pb/users_pb';
import { loginWithImplicitCredentials } from 'src/slices/session';
import { useDispatch, useSelector } from 'src/store';
import { getClient, getMetadata } from 'src/utils/grpc';

const client = getClient(PomeriumSessionServiceClient);

const Account: FC = () => {
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [showImpersonate, setShowImpersonate] = useState(false);
  const { tree } = useSelector((state) => state.namespaces);
  const dispatch = useDispatch();
  const session = useSelector((state) => state.session);
  const { jwt, userInfo } = session;

  const isImpersonated = !!userInfo?.getIsImpersonated();

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = (evt: React.MouseEvent): void => {
    evt.preventDefault();
    if (jwt) {
      dispatch(loginWithImplicitCredentials());
    } else {
      location.href = '/.pomerium/sign_out';
    }
  };
  const handleClickImpersonate = (evt: React.MouseEvent): void => {
    evt.preventDefault();
    setShowImpersonate(true);
  };
  function handleClickUnimpersonate(evt: React.MouseEvent) {
    evt.preventDefault();
    client.impersonate(
      new ImpersonateRequest(),
      getMetadata(session),
      (err): void => {
        if (err) {
          dispatch(pushError(getErrorMessage(err)));
        } else {
          location.reload();
        }
      }
    );
  }

  return (
    <>
      <ImpersonateFormDialog
        open={showImpersonate}
        onClose={(): void => setShowImpersonate(false)}
      />
      {isImpersonated ? (
        <Chip
          ref={ref}
          label={`Impersonating ${userInfo?.getName()}`}
          color="primary"
          avatar={
            <MuiAvatar>
              <ImpersonateIcon />
            </MuiAvatar>
          }
          onClick={handleOpen}
        />
      ) : (
        <IconButton color="inherit" ref={ref} onClick={handleOpen} size="large">
          <Avatar />
        </IconButton>
      )}
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        sx={{
          paper: {
            height: 32,
            width: 32,
            marginRight: 1
          }
        }}
        anchorEl={ref.current}
        open={isOpen}
      >
        {isImpersonated ? (
          <MenuItem onClick={handleClickUnimpersonate}>Unimpersonate</MenuItem>
        ) : tree?.root?.permission === 'admin' ? (
          <MenuItem onClick={handleClickImpersonate}>Impersonate</MenuItem>
        ) : (
          <></>
        )}
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
};

export default Account;
