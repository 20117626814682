import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { KeyChainServiceClient } from '../pb/Key_chainServiceClientPb';
import { pushError, pushSuccess } from './notifications';

import {
  DeleteKeyPairRequest,
  CreateKeyPairRequest,
  ListKeyPairsResponse,
  ListKeyPairsRequest,
  UpdateKeyPairRequest
} from 'src/pb/key_chain_pb';
import { AppThunk, KeyChainState } from 'src/store/state';
import { getErrorMessage } from 'src/utils/errors';
import { getClient, getMetadata } from 'src/utils/grpc';

const auditKeyPairName = '61411803-7d82-4d7d-83c7-1df916db665f';

const initialState: KeyChainState = {
  entries: [],
  count: 0
};

const slice = createSlice({
  name: 'keyChain',
  initialState,
  reducers: {
    setKeyChains(
      state: KeyChainState,
      action: PayloadAction<ListKeyPairsResponse>
    ): void {
      // note state is already a copy because of immer library
      state.entries = action.payload
        .getKeyPairsList()
        .map((e) => e.toObject())
        .filter((o) => o.name !== auditKeyPairName);
      state.count = state.entries.length;
    }
  }
});

export const { reducer } = slice;

const keyChainClient = getClient(KeyChainServiceClient);

export const getKeyChains =
  (req?: ListKeyPairsRequest): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    req = req || getState().keyChains.listRequest || new ListKeyPairsRequest();
    req.setNamespaceId(getState().namespaces.currentNamespace.id);
    try {
      const res = await keyChainClient.listKeyPairs(
        req,
        getMetadata(getState().session)
      );
      dispatch(slice.actions.setKeyChains(res));
    } catch (e) {
      dispatch(pushError(getErrorMessage(e)));
    }
  };

export const saveKeyChain =
  (req: CreateKeyPairRequest): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    try {
      await keyChainClient.createKeyPair(req, getMetadata(getState().session));
      dispatch(getKeyChains());
      dispatch(pushSuccess('Certificate ' + req?.getName() + 'Saved!'));
    } catch (e) {
      dispatch(pushError(getErrorMessage(e)));
    }
  };

export const updateKeyChain =
  (req: UpdateKeyPairRequest): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    try {
      await keyChainClient.updateKeyPair(req, getMetadata(getState().session));
      dispatch(getKeyChains());
      dispatch(pushSuccess('Certificate Updated!'));
    } catch (e) {
      dispatch(pushError(getErrorMessage(e)));
    }
  };

export const deleteKeyChains =
  (keyChainIds: Iterable<string>): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    for (const id of keyChainIds) {
      const req = new DeleteKeyPairRequest();
      req.setId(id);
      try {
        // synchronous so sqlite db doesn't lock
        await keyChainClient.deleteKeyPair(
          req,
          getMetadata(getState().session)
        );
        dispatch(getKeyChains());
      } catch (e) {
        dispatch(pushError(getErrorMessage(e)));
      }
    }
  };
