import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { pushError, pushSuccess } from './notifications';

import { RouteServiceClient } from 'src/pb/RoutesServiceClientPb';
import {
  GetRouteRequest,
  DeleteRouteRequest,
  ListRoutesRequest,
  ListRoutesResponse,
  Route,
  SetRouteRequest
} from 'src/pb/routes_pb';
import { AppThunk, RouteState, Tuple, fieldName } from 'src/store/state';
import { getErrorMessage } from 'src/utils/errors';
import { getClient, getMetadata } from 'src/utils/grpc';

export const SET_REQUEST_HEADERS = 'SetRequestHeaders';

const initialState: RouteState = {
  jsonList: [],
  protoMap: {},
  mapsAsArrays: {},
  saving: false
};

const slice = createSlice({
  name: 'routes',
  initialState,
  reducers: {
    getRoute(state: RouteState, action: PayloadAction<Route>): void {
      const jsonIndex = state.jsonList.findIndex(
        (route) => route.id == action.payload.getId()
      );
      state.jsonList[jsonIndex] = action.payload.toObject();
      state.protoMap[action.payload.getId()] = action.payload;
      state.mapsAsArrays[SET_REQUEST_HEADERS] = action.payload
        .getSetRequestHeadersMap()
        .toArray()
        .map((entry) => {
          return { key: entry[0], value: entry[1] };
        });
    },
    setMapAsArray(
      state: RouteState,
      action: PayloadAction<{ fieldName: fieldName; arr: Tuple[] }>
    ): void {
      state.mapsAsArrays[action.payload.fieldName] = action.payload.arr;
    },
    listRoutes(
      state: RouteState,
      action: PayloadAction<ListRoutesResponse>
    ): void {
      state.jsonList = action.payload
        .getRoutesList()
        .map((route) => route.toObject());
      action.payload.getRoutesList().forEach((route) => {
        state.protoMap[route.getId()] = route;
      });
    },
    deleteRoute(state: RouteState, action: PayloadAction<string>): void {
      delete state.protoMap[action.payload];
      const jsonIndex = state.jsonList.findIndex(
        (route) => route.id == action.payload
      );
      state.jsonList.splice(jsonIndex, 1);
    },
    setSaving(state: RouteState, action: PayloadAction<boolean>): void {
      state.saving = action.payload;
    }
  }
});

export const { reducer } = slice;

const client = getClient(RouteServiceClient);

export const getRoute =
  (routeId: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    if (routeId) {
      const req = new GetRouteRequest();
      req.setId(routeId);
      try {
        const res = await client.getRoute(req, getMetadata(getState().session));
        dispatch(slice.actions.getRoute(res.getRoute()));
      } catch (e) {
        dispatch(pushError(getErrorMessage(e)));
      }
    }
  };

export const setMapAsArray =
  (fieldName: fieldName, arr: Tuple[]): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setMapAsArray({ fieldName, arr }));
  };

export const setRoute =
  (route: Route): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const req = new SetRouteRequest();
    route.clearSetRequestHeadersMap();
    const asArray = getState().routes.mapsAsArrays[SET_REQUEST_HEADERS];
    if (asArray?.length) {
      asArray.forEach((entry) =>
        route.getSetRequestHeadersMap().set(entry.key, entry.value)
      );
    }
    req.setRoute(route);
    try {
      dispatch(slice.actions.setSaving(true));
      const res = await client.setRoute(req, getMetadata(getState().session));
      dispatch(slice.actions.getRoute(res.getRoute()));
      dispatch(pushSuccess('Route Saved!'));
    } catch (e) {
      dispatch(pushError(getErrorMessage(e)));
    } finally {
      dispatch(slice.actions.setSaving(false));
    }
  };

export const listRoutes =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const req = new ListRoutesRequest();
    req.setLimit(100000);
    req.setNamespace(getState().namespaces.currentNamespace.id);
    try {
      const res = await client.listRoutes(req, getMetadata(getState().session));
      dispatch(slice.actions.listRoutes(res));
    } catch (e) {
      dispatch(pushError(getErrorMessage(e)));
    }
  };

export const deleteRoutes =
  (routeIds: Iterable<string>): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    try {
      for (const routeId of routeIds) {
        const req = new DeleteRouteRequest();
        req.setId(routeId);
        const routeName = getState().routes.protoMap[routeId].getName();
        await client.deleteRoute(req, getMetadata(getState().session));
        dispatch(pushSuccess('Deleted Route: ' + routeName));
        dispatch(slice.actions.deleteRoute(routeId));
      }
    } catch (e) {
      dispatch(pushError(getErrorMessage(e)));
    }
  };
