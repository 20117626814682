/**
 * @fileoverview gRPC-Web generated client stub for pomerium.dashboard
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!

/* eslint-disable */
// @ts-nocheck

import * as grpcWeb from 'grpc-web';

import * as policy_pb from './policy_pb';

export class PolicyServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string };
  options_: null | { [index: string]: any };

  constructor(
    hostname: string,
    credentials?: null | { [index: string]: string },
    options?: null | { [index: string]: any }
  ) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoDeletePolicy = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.PolicyService/DeletePolicy',
    grpcWeb.MethodType.UNARY,
    policy_pb.DeletePolicyRequest,
    policy_pb.DeletePolicyResponse,
    (request: policy_pb.DeletePolicyRequest) => {
      return request.serializeBinary();
    },
    policy_pb.DeletePolicyResponse.deserializeBinary
  );

  deletePolicy(
    request: policy_pb.DeletePolicyRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<policy_pb.DeletePolicyResponse>;

  deletePolicy(
    request: policy_pb.DeletePolicyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: policy_pb.DeletePolicyResponse
    ) => void
  ): grpcWeb.ClientReadableStream<policy_pb.DeletePolicyResponse>;

  deletePolicy(
    request: policy_pb.DeletePolicyRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: policy_pb.DeletePolicyResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + '/pomerium.dashboard.PolicyService/DeletePolicy',
        request,
        metadata || {},
        this.methodInfoDeletePolicy,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + '/pomerium.dashboard.PolicyService/DeletePolicy',
      request,
      metadata || {},
      this.methodInfoDeletePolicy
    );
  }

  methodInfoGetPolicy = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.PolicyService/GetPolicy',
    grpcWeb.MethodType.UNARY,
    policy_pb.GetPolicyRequest,
    policy_pb.GetPolicyResponse,
    (request: policy_pb.GetPolicyRequest) => {
      return request.serializeBinary();
    },
    policy_pb.GetPolicyResponse.deserializeBinary
  );

  getPolicy(
    request: policy_pb.GetPolicyRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<policy_pb.GetPolicyResponse>;

  getPolicy(
    request: policy_pb.GetPolicyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: policy_pb.GetPolicyResponse
    ) => void
  ): grpcWeb.ClientReadableStream<policy_pb.GetPolicyResponse>;

  getPolicy(
    request: policy_pb.GetPolicyRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: policy_pb.GetPolicyResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + '/pomerium.dashboard.PolicyService/GetPolicy',
        request,
        metadata || {},
        this.methodInfoGetPolicy,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + '/pomerium.dashboard.PolicyService/GetPolicy',
      request,
      metadata || {},
      this.methodInfoGetPolicy
    );
  }

  methodInfoListPolicies = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.PolicyService/ListPolicies',
    grpcWeb.MethodType.UNARY,
    policy_pb.ListPoliciesRequest,
    policy_pb.ListPoliciesResponse,
    (request: policy_pb.ListPoliciesRequest) => {
      return request.serializeBinary();
    },
    policy_pb.ListPoliciesResponse.deserializeBinary
  );

  listPolicies(
    request: policy_pb.ListPoliciesRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<policy_pb.ListPoliciesResponse>;

  listPolicies(
    request: policy_pb.ListPoliciesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: policy_pb.ListPoliciesResponse
    ) => void
  ): grpcWeb.ClientReadableStream<policy_pb.ListPoliciesResponse>;

  listPolicies(
    request: policy_pb.ListPoliciesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: policy_pb.ListPoliciesResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + '/pomerium.dashboard.PolicyService/ListPolicies',
        request,
        metadata || {},
        this.methodInfoListPolicies,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + '/pomerium.dashboard.PolicyService/ListPolicies',
      request,
      metadata || {},
      this.methodInfoListPolicies
    );
  }

  methodInfoSetPolicy = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.PolicyService/SetPolicy',
    grpcWeb.MethodType.UNARY,
    policy_pb.SetPolicyRequest,
    policy_pb.SetPolicyResponse,
    (request: policy_pb.SetPolicyRequest) => {
      return request.serializeBinary();
    },
    policy_pb.SetPolicyResponse.deserializeBinary
  );

  setPolicy(
    request: policy_pb.SetPolicyRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<policy_pb.SetPolicyResponse>;

  setPolicy(
    request: policy_pb.SetPolicyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: policy_pb.SetPolicyResponse
    ) => void
  ): grpcWeb.ClientReadableStream<policy_pb.SetPolicyResponse>;

  setPolicy(
    request: policy_pb.SetPolicyRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: policy_pb.SetPolicyResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + '/pomerium.dashboard.PolicyService/SetPolicy',
        request,
        metadata || {},
        this.methodInfoSetPolicy,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + '/pomerium.dashboard.PolicyService/SetPolicy',
      request,
      metadata || {},
      this.methodInfoSetPolicy
    );
  }
}
