import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TreeItem from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';

import { NamespaceIcon } from 'src/components/icons';
import { setCurrentNamespace } from 'src/slices/namespaces';
import { useDispatch, useSelector } from 'src/store';
import { NamespaceTreeNode } from 'src/types/namespace_tree';

const getTreeItemsFromData = (node): JSX.Element => {
  return node.map((node) => {
    const [toggle, setToggle] = useState(true);
    let children = undefined;
    if (node.children && node.children.length > 0) {
      children = getTreeItemsFromData(node.children);
    }
    return (
      <TreeItem
        key={node.id}
        nodeId={node.id}
        onClick={(): void => setToggle(!toggle)}
        collapseIcon={<ExpandMoreIcon />}
        expandIcon={<ChevronRightIcon />}
        label={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '4px 0px'
            }}
          >
            <NamespaceIcon sx={{ marginRight: 1 }} />
            <Typography
              variant="body2"
              sx={{
                fontWeight: 'inherit',
                flexGrow: 1
              }}
            >
              {node.name}
            </Typography>
          </div>
        }
        children={children}
      />
    );
  });
};

export default function FileSystemNavigator(): JSX.Element {
  const dispatch = useDispatch();
  const { currentNamespace, tree } = useSelector((state) => state.namespaces);
  const root: NamespaceTreeNode = tree.root;
  const [expanded, setExpanded] = useState((): string[] => {
    const currentNode = tree.get(currentNamespace.id);
    const ancestors = tree.ancestors(currentNode).map((node) => node.id);
    ancestors.push(currentNamespace.id);
    return ancestors;
  });
  const [selected, setSelected] = useState(currentNamespace.id);
  const handleExpanded = (e, i): void => {
    setExpanded(i);
  };
  const handleSelected = (v): void => {
    setSelected(v);
    dispatch(setCurrentNamespace(tree.get(v)));
  };
  return (
    <TreeView
      sx={{
        height: `100%`,
        width: `100%`,
        minWidth: `300px`,
        flexGrow: 1,
        maxWidth: `500px`,
        overflow: `auto`
      }}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      expanded={expanded}
      onNodeToggle={(e, i): void => handleExpanded(e, i)}
      onNodeSelect={(e, v): void => handleSelected(v)}
      selected={selected}
    >
      {getTreeItemsFromData([root])}
    </TreeView>
  );
}
