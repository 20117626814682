// source: policy.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_struct_pb = require('google-protobuf/google/protobuf/struct_pb.js');
goog.object.extend(proto, google_protobuf_struct_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.pomerium.dashboard.DeletePolicyRequest', null, global);
goog.exportSymbol(
  'proto.pomerium.dashboard.DeletePolicyResponse',
  null,
  global
);
goog.exportSymbol('proto.pomerium.dashboard.GetPolicyRequest', null, global);
goog.exportSymbol('proto.pomerium.dashboard.GetPolicyResponse', null, global);
goog.exportSymbol('proto.pomerium.dashboard.ListPoliciesRequest', null, global);
goog.exportSymbol(
  'proto.pomerium.dashboard.ListPoliciesResponse',
  null,
  global
);
goog.exportSymbol('proto.pomerium.dashboard.Policy', null, global);
goog.exportSymbol('proto.pomerium.dashboard.SetPolicyRequest', null, global);
goog.exportSymbol('proto.pomerium.dashboard.SetPolicyResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.Policy = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.pomerium.dashboard.Policy.repeatedFields_,
    null
  );
};
goog.inherits(proto.pomerium.dashboard.Policy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.Policy.displayName =
    'proto.pomerium.dashboard.Policy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.DeletePolicyRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.DeletePolicyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.DeletePolicyRequest.displayName =
    'proto.pomerium.dashboard.DeletePolicyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.DeletePolicyResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.DeletePolicyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.DeletePolicyResponse.displayName =
    'proto.pomerium.dashboard.DeletePolicyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.GetPolicyRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.GetPolicyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.GetPolicyRequest.displayName =
    'proto.pomerium.dashboard.GetPolicyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.GetPolicyResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.GetPolicyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.GetPolicyResponse.displayName =
    'proto.pomerium.dashboard.GetPolicyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.ListPoliciesRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.ListPoliciesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.ListPoliciesRequest.displayName =
    'proto.pomerium.dashboard.ListPoliciesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.ListPoliciesResponse = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.pomerium.dashboard.ListPoliciesResponse.repeatedFields_,
    null
  );
};
goog.inherits(proto.pomerium.dashboard.ListPoliciesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.ListPoliciesResponse.displayName =
    'proto.pomerium.dashboard.ListPoliciesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.SetPolicyRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.SetPolicyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.SetPolicyRequest.displayName =
    'proto.pomerium.dashboard.SetPolicyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.SetPolicyResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.SetPolicyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.SetPolicyResponse.displayName =
    'proto.pomerium.dashboard.SetPolicyResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pomerium.dashboard.Policy.repeatedFields_ = [6, 7, 8, 9];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.Policy.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.Policy.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.Policy} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.Policy.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, ''),
        namespaceId: jspb.Message.getFieldWithDefault(msg, 10, ''),
        createdAt:
          (f = msg.getCreatedAt()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        modifiedAt:
          (f = msg.getModifiedAt()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        deletedAt:
          (f = msg.getDeletedAt()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        name: jspb.Message.getFieldWithDefault(msg, 5, ''),
        description: jspb.Message.getFieldWithDefault(msg, 16, ''),
        allowedUsersList:
          (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
        allowedGroupsList:
          (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
        allowedDomainsList:
          (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
        allowedIdpClaimsMap: (f = msg.getAllowedIdpClaimsMap())
          ? f.toObject(
              includeInstance,
              proto.google.protobuf.ListValue.toObject
            )
          : [],
        regoList:
          (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
        ppl: jspb.Message.getFieldWithDefault(msg, 15, ''),
        enforced: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
        routesMap: (f = msg.getRoutesMap())
          ? f.toObject(includeInstance, undefined)
          : [],
        namespaceName: jspb.Message.getFieldWithDefault(msg, 12, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.Policy}
 */
proto.pomerium.dashboard.Policy.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.Policy();
  return proto.pomerium.dashboard.Policy.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.Policy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.Policy}
 */
proto.pomerium.dashboard.Policy.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 10:
        var value = /** @type {string} */ (reader.readString());
        msg.setNamespaceId(value);
        break;
      case 2:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        );
        msg.setCreatedAt(value);
        break;
      case 3:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        );
        msg.setModifiedAt(value);
        break;
      case 4:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        );
        msg.setDeletedAt(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 16:
        var value = /** @type {string} */ (reader.readString());
        msg.setDescription(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.addAllowedUsers(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.addAllowedGroups(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.addAllowedDomains(value);
        break;
      case 14:
        var value = msg.getAllowedIdpClaimsMap();
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(
            message,
            reader,
            jspb.BinaryReader.prototype.readString,
            jspb.BinaryReader.prototype.readMessage,
            proto.google.protobuf.ListValue.deserializeBinaryFromReader,
            '',
            new proto.google.protobuf.ListValue()
          );
        });
        break;
      case 9:
        var value = /** @type {string} */ (reader.readString());
        msg.addRego(value);
        break;
      case 15:
        var value = /** @type {string} */ (reader.readString());
        msg.setPpl(value);
        break;
      case 13:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setEnforced(value);
        break;
      case 11:
        var value = msg.getRoutesMap();
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(
            message,
            reader,
            jspb.BinaryReader.prototype.readString,
            jspb.BinaryReader.prototype.readString,
            null,
            '',
            ''
          );
        });
        break;
      case 12:
        var value = /** @type {string} */ (reader.readString());
        msg.setNamespaceName(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.Policy.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.pomerium.dashboard.Policy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.Policy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.Policy.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getNamespaceId();
  if (f.length > 0) {
    writer.writeString(10, f);
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getModifiedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeletedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(5, f);
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(16, f);
  }
  f = message.getAllowedUsersList();
  if (f.length > 0) {
    writer.writeRepeatedString(6, f);
  }
  f = message.getAllowedGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedString(7, f);
  }
  f = message.getAllowedDomainsList();
  if (f.length > 0) {
    writer.writeRepeatedString(8, f);
  }
  f = message.getAllowedIdpClaimsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(
      14,
      writer,
      jspb.BinaryWriter.prototype.writeString,
      jspb.BinaryWriter.prototype.writeMessage,
      proto.google.protobuf.ListValue.serializeBinaryToWriter
    );
  }
  f = message.getRegoList();
  if (f.length > 0) {
    writer.writeRepeatedString(9, f);
  }
  f = message.getPpl();
  if (f.length > 0) {
    writer.writeString(15, f);
  }
  f = message.getEnforced();
  if (f) {
    writer.writeBool(13, f);
  }
  f = message.getRoutesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(
      11,
      writer,
      jspb.BinaryWriter.prototype.writeString,
      jspb.BinaryWriter.prototype.writeString
    );
  }
  f = message.getNamespaceName();
  if (f.length > 0) {
    writer.writeString(12, f);
  }
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.Policy.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Policy} returns this
 */
proto.pomerium.dashboard.Policy.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string namespace_id = 10;
 * @return {string}
 */
proto.pomerium.dashboard.Policy.prototype.getNamespaceId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Policy} returns this
 */
proto.pomerium.dashboard.Policy.prototype.setNamespaceId = function (value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};

/**
 * optional google.protobuf.Timestamp created_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pomerium.dashboard.Policy.prototype.getCreatedAt = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_timestamp_pb.Timestamp,
      2
    )
  );
};

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.pomerium.dashboard.Policy} returns this
 */
proto.pomerium.dashboard.Policy.prototype.setCreatedAt = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.Policy} returns this
 */
proto.pomerium.dashboard.Policy.prototype.clearCreatedAt = function () {
  return this.setCreatedAt(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Policy.prototype.hasCreatedAt = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional google.protobuf.Timestamp modified_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pomerium.dashboard.Policy.prototype.getModifiedAt = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_timestamp_pb.Timestamp,
      3
    )
  );
};

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.pomerium.dashboard.Policy} returns this
 */
proto.pomerium.dashboard.Policy.prototype.setModifiedAt = function (value) {
  return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.Policy} returns this
 */
proto.pomerium.dashboard.Policy.prototype.clearModifiedAt = function () {
  return this.setModifiedAt(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Policy.prototype.hasModifiedAt = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional google.protobuf.Timestamp deleted_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pomerium.dashboard.Policy.prototype.getDeletedAt = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_timestamp_pb.Timestamp,
      4
    )
  );
};

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.pomerium.dashboard.Policy} returns this
 */
proto.pomerium.dashboard.Policy.prototype.setDeletedAt = function (value) {
  return jspb.Message.setWrapperField(this, 4, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.Policy} returns this
 */
proto.pomerium.dashboard.Policy.prototype.clearDeletedAt = function () {
  return this.setDeletedAt(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Policy.prototype.hasDeletedAt = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional string name = 5;
 * @return {string}
 */
proto.pomerium.dashboard.Policy.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Policy} returns this
 */
proto.pomerium.dashboard.Policy.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};

/**
 * optional string description = 16;
 * @return {string}
 */
proto.pomerium.dashboard.Policy.prototype.getDescription = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Policy} returns this
 */
proto.pomerium.dashboard.Policy.prototype.setDescription = function (value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};

/**
 * repeated string allowed_users = 6;
 * @return {!Array<string>}
 */
proto.pomerium.dashboard.Policy.prototype.getAllowedUsersList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.pomerium.dashboard.Policy} returns this
 */
proto.pomerium.dashboard.Policy.prototype.setAllowedUsersList = function (
  value
) {
  return jspb.Message.setField(this, 6, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.Policy} returns this
 */
proto.pomerium.dashboard.Policy.prototype.addAllowedUsers = function (
  value,
  opt_index
) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.Policy} returns this
 */
proto.pomerium.dashboard.Policy.prototype.clearAllowedUsersList = function () {
  return this.setAllowedUsersList([]);
};

/**
 * repeated string allowed_groups = 7;
 * @return {!Array<string>}
 */
proto.pomerium.dashboard.Policy.prototype.getAllowedGroupsList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.pomerium.dashboard.Policy} returns this
 */
proto.pomerium.dashboard.Policy.prototype.setAllowedGroupsList = function (
  value
) {
  return jspb.Message.setField(this, 7, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.Policy} returns this
 */
proto.pomerium.dashboard.Policy.prototype.addAllowedGroups = function (
  value,
  opt_index
) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.Policy} returns this
 */
proto.pomerium.dashboard.Policy.prototype.clearAllowedGroupsList = function () {
  return this.setAllowedGroupsList([]);
};

/**
 * repeated string allowed_domains = 8;
 * @return {!Array<string>}
 */
proto.pomerium.dashboard.Policy.prototype.getAllowedDomainsList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.pomerium.dashboard.Policy} returns this
 */
proto.pomerium.dashboard.Policy.prototype.setAllowedDomainsList = function (
  value
) {
  return jspb.Message.setField(this, 8, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.Policy} returns this
 */
proto.pomerium.dashboard.Policy.prototype.addAllowedDomains = function (
  value,
  opt_index
) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.Policy} returns this
 */
proto.pomerium.dashboard.Policy.prototype.clearAllowedDomainsList =
  function () {
    return this.setAllowedDomainsList([]);
  };

/**
 * map<string, google.protobuf.ListValue> allowed_idp_claims = 14;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.google.protobuf.ListValue>}
 */
proto.pomerium.dashboard.Policy.prototype.getAllowedIdpClaimsMap = function (
  opt_noLazyCreate
) {
  return /** @type {!jspb.Map<string,!proto.google.protobuf.ListValue>} */ (
    jspb.Message.getMapField(
      this,
      14,
      opt_noLazyCreate,
      proto.google.protobuf.ListValue
    )
  );
};

/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.pomerium.dashboard.Policy} returns this
 */
proto.pomerium.dashboard.Policy.prototype.clearAllowedIdpClaimsMap =
  function () {
    this.getAllowedIdpClaimsMap().clear();
    return this;
  };

/**
 * repeated string rego = 9;
 * @return {!Array<string>}
 */
proto.pomerium.dashboard.Policy.prototype.getRegoList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.pomerium.dashboard.Policy} returns this
 */
proto.pomerium.dashboard.Policy.prototype.setRegoList = function (value) {
  return jspb.Message.setField(this, 9, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.Policy} returns this
 */
proto.pomerium.dashboard.Policy.prototype.addRego = function (
  value,
  opt_index
) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.Policy} returns this
 */
proto.pomerium.dashboard.Policy.prototype.clearRegoList = function () {
  return this.setRegoList([]);
};

/**
 * optional string ppl = 15;
 * @return {string}
 */
proto.pomerium.dashboard.Policy.prototype.getPpl = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Policy} returns this
 */
proto.pomerium.dashboard.Policy.prototype.setPpl = function (value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};

/**
 * optional bool enforced = 13;
 * @return {boolean}
 */
proto.pomerium.dashboard.Policy.prototype.getEnforced = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 13, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.pomerium.dashboard.Policy} returns this
 */
proto.pomerium.dashboard.Policy.prototype.setEnforced = function (value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};

/**
 * map<string, string> routes = 11;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.pomerium.dashboard.Policy.prototype.getRoutesMap = function (
  opt_noLazyCreate
) {
  return /** @type {!jspb.Map<string,string>} */ (
    jspb.Message.getMapField(this, 11, opt_noLazyCreate, null)
  );
};

/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.pomerium.dashboard.Policy} returns this
 */
proto.pomerium.dashboard.Policy.prototype.clearRoutesMap = function () {
  this.getRoutesMap().clear();
  return this;
};

/**
 * optional string namespace_name = 12;
 * @return {string}
 */
proto.pomerium.dashboard.Policy.prototype.getNamespaceName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Policy} returns this
 */
proto.pomerium.dashboard.Policy.prototype.setNamespaceName = function (value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.DeletePolicyRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.DeletePolicyRequest.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.DeletePolicyRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.DeletePolicyRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.DeletePolicyRequest}
 */
proto.pomerium.dashboard.DeletePolicyRequest.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.DeletePolicyRequest();
  return proto.pomerium.dashboard.DeletePolicyRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.DeletePolicyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.DeletePolicyRequest}
 */
proto.pomerium.dashboard.DeletePolicyRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setId(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.DeletePolicyRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.DeletePolicyRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.DeletePolicyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.DeletePolicyRequest.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getId();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
  };

/**
 * optional string id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.DeletePolicyRequest.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.DeletePolicyRequest} returns this
 */
proto.pomerium.dashboard.DeletePolicyRequest.prototype.setId = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.DeletePolicyResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.DeletePolicyResponse.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.DeletePolicyResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.DeletePolicyResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {};

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.DeletePolicyResponse}
 */
proto.pomerium.dashboard.DeletePolicyResponse.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.DeletePolicyResponse();
  return proto.pomerium.dashboard.DeletePolicyResponse.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.DeletePolicyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.DeletePolicyResponse}
 */
proto.pomerium.dashboard.DeletePolicyResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.DeletePolicyResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.DeletePolicyResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.DeletePolicyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.DeletePolicyResponse.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.GetPolicyRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.GetPolicyRequest.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.GetPolicyRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.GetPolicyRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.GetPolicyRequest}
 */
proto.pomerium.dashboard.GetPolicyRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.GetPolicyRequest();
  return proto.pomerium.dashboard.GetPolicyRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.GetPolicyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.GetPolicyRequest}
 */
proto.pomerium.dashboard.GetPolicyRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setId(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.GetPolicyRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.GetPolicyRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.GetPolicyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.GetPolicyRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.GetPolicyRequest.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.GetPolicyRequest} returns this
 */
proto.pomerium.dashboard.GetPolicyRequest.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.GetPolicyResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.GetPolicyResponse.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.GetPolicyResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.GetPolicyResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        policy:
          (f = msg.getPolicy()) &&
          proto.pomerium.dashboard.Policy.toObject(includeInstance, f)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.GetPolicyResponse}
 */
proto.pomerium.dashboard.GetPolicyResponse.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.GetPolicyResponse();
  return proto.pomerium.dashboard.GetPolicyResponse.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.GetPolicyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.GetPolicyResponse}
 */
proto.pomerium.dashboard.GetPolicyResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.Policy();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.Policy.deserializeBinaryFromReader
          );
          msg.setPolicy(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.GetPolicyResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.GetPolicyResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.GetPolicyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.GetPolicyResponse.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getPolicy();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pomerium.dashboard.Policy.serializeBinaryToWriter
    );
  }
};

/**
 * optional Policy policy = 1;
 * @return {?proto.pomerium.dashboard.Policy}
 */
proto.pomerium.dashboard.GetPolicyResponse.prototype.getPolicy = function () {
  return /** @type{?proto.pomerium.dashboard.Policy} */ (
    jspb.Message.getWrapperField(this, proto.pomerium.dashboard.Policy, 1)
  );
};

/**
 * @param {?proto.pomerium.dashboard.Policy|undefined} value
 * @return {!proto.pomerium.dashboard.GetPolicyResponse} returns this
 */
proto.pomerium.dashboard.GetPolicyResponse.prototype.setPolicy = function (
  value
) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.GetPolicyResponse} returns this
 */
proto.pomerium.dashboard.GetPolicyResponse.prototype.clearPolicy = function () {
  return this.setPolicy(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.GetPolicyResponse.prototype.hasPolicy = function () {
  return jspb.Message.getField(this, 1) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.ListPoliciesRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.ListPoliciesRequest.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.ListPoliciesRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.ListPoliciesRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        namespace: jspb.Message.getFieldWithDefault(msg, 1, ''),
        query: jspb.Message.getFieldWithDefault(msg, 2, ''),
        offset: jspb.Message.getFieldWithDefault(msg, 3, 0),
        limit: jspb.Message.getFieldWithDefault(msg, 4, 0),
        orderBy: jspb.Message.getFieldWithDefault(msg, 5, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.ListPoliciesRequest}
 */
proto.pomerium.dashboard.ListPoliciesRequest.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.ListPoliciesRequest();
  return proto.pomerium.dashboard.ListPoliciesRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.ListPoliciesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.ListPoliciesRequest}
 */
proto.pomerium.dashboard.ListPoliciesRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setNamespace(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setQuery(value);
          break;
        case 3:
          var value = /** @type {number} */ (reader.readInt64());
          msg.setOffset(value);
          break;
        case 4:
          var value = /** @type {number} */ (reader.readInt64());
          msg.setLimit(value);
          break;
        case 5:
          var value = /** @type {string} */ (reader.readString());
          msg.setOrderBy(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.ListPoliciesRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.ListPoliciesRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.ListPoliciesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.ListPoliciesRequest.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getNamespace();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 2));
    if (f != null) {
      writer.writeString(2, f);
    }
    f = /** @type {number} */ (jspb.Message.getField(message, 3));
    if (f != null) {
      writer.writeInt64(3, f);
    }
    f = /** @type {number} */ (jspb.Message.getField(message, 4));
    if (f != null) {
      writer.writeInt64(4, f);
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 5));
    if (f != null) {
      writer.writeString(5, f);
    }
  };

/**
 * optional string namespace = 1;
 * @return {string}
 */
proto.pomerium.dashboard.ListPoliciesRequest.prototype.getNamespace =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ListPoliciesRequest} returns this
 */
proto.pomerium.dashboard.ListPoliciesRequest.prototype.setNamespace = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string query = 2;
 * @return {string}
 */
proto.pomerium.dashboard.ListPoliciesRequest.prototype.getQuery = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ListPoliciesRequest} returns this
 */
proto.pomerium.dashboard.ListPoliciesRequest.prototype.setQuery = function (
  value
) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.ListPoliciesRequest} returns this
 */
proto.pomerium.dashboard.ListPoliciesRequest.prototype.clearQuery =
  function () {
    return jspb.Message.setField(this, 2, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.ListPoliciesRequest.prototype.hasQuery = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional int64 offset = 3;
 * @return {number}
 */
proto.pomerium.dashboard.ListPoliciesRequest.prototype.getOffset = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.pomerium.dashboard.ListPoliciesRequest} returns this
 */
proto.pomerium.dashboard.ListPoliciesRequest.prototype.setOffset = function (
  value
) {
  return jspb.Message.setField(this, 3, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.ListPoliciesRequest} returns this
 */
proto.pomerium.dashboard.ListPoliciesRequest.prototype.clearOffset =
  function () {
    return jspb.Message.setField(this, 3, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.ListPoliciesRequest.prototype.hasOffset = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional int64 limit = 4;
 * @return {number}
 */
proto.pomerium.dashboard.ListPoliciesRequest.prototype.getLimit = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {number} value
 * @return {!proto.pomerium.dashboard.ListPoliciesRequest} returns this
 */
proto.pomerium.dashboard.ListPoliciesRequest.prototype.setLimit = function (
  value
) {
  return jspb.Message.setField(this, 4, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.ListPoliciesRequest} returns this
 */
proto.pomerium.dashboard.ListPoliciesRequest.prototype.clearLimit =
  function () {
    return jspb.Message.setField(this, 4, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.ListPoliciesRequest.prototype.hasLimit = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional string order_by = 5;
 * @return {string}
 */
proto.pomerium.dashboard.ListPoliciesRequest.prototype.getOrderBy =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 5, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ListPoliciesRequest} returns this
 */
proto.pomerium.dashboard.ListPoliciesRequest.prototype.setOrderBy = function (
  value
) {
  return jspb.Message.setField(this, 5, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.ListPoliciesRequest} returns this
 */
proto.pomerium.dashboard.ListPoliciesRequest.prototype.clearOrderBy =
  function () {
    return jspb.Message.setField(this, 5, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.ListPoliciesRequest.prototype.hasOrderBy =
  function () {
    return jspb.Message.getField(this, 5) != null;
  };

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pomerium.dashboard.ListPoliciesResponse.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.ListPoliciesResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.ListPoliciesResponse.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.ListPoliciesResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.ListPoliciesResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        policiesList: jspb.Message.toObjectList(
          msg.getPoliciesList(),
          proto.pomerium.dashboard.Policy.toObject,
          includeInstance
        ),
        totalCount: jspb.Message.getFieldWithDefault(msg, 2, 0)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.ListPoliciesResponse}
 */
proto.pomerium.dashboard.ListPoliciesResponse.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.ListPoliciesResponse();
  return proto.pomerium.dashboard.ListPoliciesResponse.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.ListPoliciesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.ListPoliciesResponse}
 */
proto.pomerium.dashboard.ListPoliciesResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.Policy();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.Policy.deserializeBinaryFromReader
          );
          msg.addPolicies(value);
          break;
        case 2:
          var value = /** @type {number} */ (reader.readInt64());
          msg.setTotalCount(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.ListPoliciesResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.ListPoliciesResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.ListPoliciesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.ListPoliciesResponse.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getPoliciesList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        proto.pomerium.dashboard.Policy.serializeBinaryToWriter
      );
    }
    f = message.getTotalCount();
    if (f !== 0) {
      writer.writeInt64(2, f);
    }
  };

/**
 * repeated Policy policies = 1;
 * @return {!Array<!proto.pomerium.dashboard.Policy>}
 */
proto.pomerium.dashboard.ListPoliciesResponse.prototype.getPoliciesList =
  function () {
    return /** @type{!Array<!proto.pomerium.dashboard.Policy>} */ (
      jspb.Message.getRepeatedWrapperField(
        this,
        proto.pomerium.dashboard.Policy,
        1
      )
    );
  };

/**
 * @param {!Array<!proto.pomerium.dashboard.Policy>} value
 * @return {!proto.pomerium.dashboard.ListPoliciesResponse} returns this
 */
proto.pomerium.dashboard.ListPoliciesResponse.prototype.setPoliciesList =
  function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
  };

/**
 * @param {!proto.pomerium.dashboard.Policy=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.Policy}
 */
proto.pomerium.dashboard.ListPoliciesResponse.prototype.addPolicies = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.pomerium.dashboard.Policy,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.ListPoliciesResponse} returns this
 */
proto.pomerium.dashboard.ListPoliciesResponse.prototype.clearPoliciesList =
  function () {
    return this.setPoliciesList([]);
  };

/**
 * optional int64 total_count = 2;
 * @return {number}
 */
proto.pomerium.dashboard.ListPoliciesResponse.prototype.getTotalCount =
  function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };

/**
 * @param {number} value
 * @return {!proto.pomerium.dashboard.ListPoliciesResponse} returns this
 */
proto.pomerium.dashboard.ListPoliciesResponse.prototype.setTotalCount =
  function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.SetPolicyRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.SetPolicyRequest.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.SetPolicyRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.SetPolicyRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        policy:
          (f = msg.getPolicy()) &&
          proto.pomerium.dashboard.Policy.toObject(includeInstance, f)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.SetPolicyRequest}
 */
proto.pomerium.dashboard.SetPolicyRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.SetPolicyRequest();
  return proto.pomerium.dashboard.SetPolicyRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.SetPolicyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.SetPolicyRequest}
 */
proto.pomerium.dashboard.SetPolicyRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.Policy();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.Policy.deserializeBinaryFromReader
          );
          msg.setPolicy(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.SetPolicyRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.SetPolicyRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.SetPolicyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.SetPolicyRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getPolicy();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pomerium.dashboard.Policy.serializeBinaryToWriter
    );
  }
};

/**
 * optional Policy policy = 1;
 * @return {?proto.pomerium.dashboard.Policy}
 */
proto.pomerium.dashboard.SetPolicyRequest.prototype.getPolicy = function () {
  return /** @type{?proto.pomerium.dashboard.Policy} */ (
    jspb.Message.getWrapperField(this, proto.pomerium.dashboard.Policy, 1)
  );
};

/**
 * @param {?proto.pomerium.dashboard.Policy|undefined} value
 * @return {!proto.pomerium.dashboard.SetPolicyRequest} returns this
 */
proto.pomerium.dashboard.SetPolicyRequest.prototype.setPolicy = function (
  value
) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.SetPolicyRequest} returns this
 */
proto.pomerium.dashboard.SetPolicyRequest.prototype.clearPolicy = function () {
  return this.setPolicy(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.SetPolicyRequest.prototype.hasPolicy = function () {
  return jspb.Message.getField(this, 1) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.SetPolicyResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.SetPolicyResponse.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.SetPolicyResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.SetPolicyResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        policy:
          (f = msg.getPolicy()) &&
          proto.pomerium.dashboard.Policy.toObject(includeInstance, f)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.SetPolicyResponse}
 */
proto.pomerium.dashboard.SetPolicyResponse.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.SetPolicyResponse();
  return proto.pomerium.dashboard.SetPolicyResponse.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.SetPolicyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.SetPolicyResponse}
 */
proto.pomerium.dashboard.SetPolicyResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.Policy();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.Policy.deserializeBinaryFromReader
          );
          msg.setPolicy(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.SetPolicyResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.SetPolicyResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.SetPolicyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.SetPolicyResponse.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getPolicy();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pomerium.dashboard.Policy.serializeBinaryToWriter
    );
  }
};

/**
 * optional Policy policy = 1;
 * @return {?proto.pomerium.dashboard.Policy}
 */
proto.pomerium.dashboard.SetPolicyResponse.prototype.getPolicy = function () {
  return /** @type{?proto.pomerium.dashboard.Policy} */ (
    jspb.Message.getWrapperField(this, proto.pomerium.dashboard.Policy, 1)
  );
};

/**
 * @param {?proto.pomerium.dashboard.Policy|undefined} value
 * @return {!proto.pomerium.dashboard.SetPolicyResponse} returns this
 */
proto.pomerium.dashboard.SetPolicyResponse.prototype.setPolicy = function (
  value
) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.SetPolicyResponse} returns this
 */
proto.pomerium.dashboard.SetPolicyResponse.prototype.clearPolicy = function () {
  return this.setPolicy(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.SetPolicyResponse.prototype.hasPolicy = function () {
  return jspb.Message.getField(this, 1) != null;
};

goog.object.extend(exports, proto.pomerium.dashboard);
