import MuiAvatar from '@mui/material/Avatar';
import React from 'react';

import { PersonIcon } from './icons';

import { useSelector } from 'src/store';

export const Avatar = (): JSX.Element => {
  const { userInfo } = useSelector((state) => state.session);

  const name = userInfo?.getName?.();
  let pictureURL = userInfo?.getPictureUrl?.();
  if (pictureURL === 'https://graph.microsoft.com/v1.0/me/photo/$value') {
    pictureURL = null;
  }

  return pictureURL ? (
    <MuiAvatar alt={name} src={pictureURL} />
  ) : (
    <PersonIcon />
  );
};
