// source: users.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_struct_pb = require('google-protobuf/google/protobuf/struct_pb.js');
goog.object.extend(proto, google_protobuf_struct_pb);
goog.exportSymbol(
  'proto.pomerium.dashboard.AddPomeriumServiceAccountRequest',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.AddPomeriumServiceAccountResponse',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.DeletePomeriumServiceAccountRequest',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.DeletePomeriumServiceAccountResponse',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.DeletePomeriumSessionRequest',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.DeletePomeriumSessionResponse',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.GetPomeriumServiceAccountRequest',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.GetPomeriumServiceAccountResponse',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.GetPomeriumSessionRequest',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.GetPomeriumSessionResponse',
  null,
  global
);
goog.exportSymbol('proto.pomerium.dashboard.GetUserInfoRequest', null, global);
goog.exportSymbol('proto.pomerium.dashboard.GetUserInfoResponse', null, global);
goog.exportSymbol('proto.pomerium.dashboard.GroupInfo', null, global);
goog.exportSymbol('proto.pomerium.dashboard.ImpersonateRequest', null, global);
goog.exportSymbol('proto.pomerium.dashboard.ImpersonateResponse', null, global);
goog.exportSymbol(
  'proto.pomerium.dashboard.ListPomeriumServiceAccountsRequest',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.ListPomeriumServiceAccountsResponse',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.ListPomeriumSessionsRequest',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.ListPomeriumSessionsResponse',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.PomeriumServiceAccount',
  null,
  global
);
goog.exportSymbol('proto.pomerium.dashboard.PomeriumSession', null, global);
goog.exportSymbol(
  'proto.pomerium.dashboard.PomeriumSession.Group',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.PomeriumSession.User',
  null,
  global
);
goog.exportSymbol('proto.pomerium.dashboard.QueryGroupsRequest', null, global);
goog.exportSymbol('proto.pomerium.dashboard.QueryGroupsResponse', null, global);
goog.exportSymbol('proto.pomerium.dashboard.QueryUsersRequest', null, global);
goog.exportSymbol('proto.pomerium.dashboard.QueryUsersResponse', null, global);
goog.exportSymbol('proto.pomerium.dashboard.RecoveryToken', null, global);
goog.exportSymbol('proto.pomerium.dashboard.UserInfo', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.RecoveryToken = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.RecoveryToken, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.RecoveryToken.displayName =
    'proto.pomerium.dashboard.RecoveryToken';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.GroupInfo = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.GroupInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.GroupInfo.displayName =
    'proto.pomerium.dashboard.GroupInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.UserInfo = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.pomerium.dashboard.UserInfo.repeatedFields_,
    null
  );
};
goog.inherits(proto.pomerium.dashboard.UserInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.UserInfo.displayName =
    'proto.pomerium.dashboard.UserInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.GetUserInfoRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.GetUserInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.GetUserInfoRequest.displayName =
    'proto.pomerium.dashboard.GetUserInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.GetUserInfoResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.GetUserInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.GetUserInfoResponse.displayName =
    'proto.pomerium.dashboard.GetUserInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.QueryGroupsRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.QueryGroupsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.QueryGroupsRequest.displayName =
    'proto.pomerium.dashboard.QueryGroupsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.QueryGroupsResponse = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.pomerium.dashboard.QueryGroupsResponse.repeatedFields_,
    null
  );
};
goog.inherits(proto.pomerium.dashboard.QueryGroupsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.QueryGroupsResponse.displayName =
    'proto.pomerium.dashboard.QueryGroupsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.QueryUsersRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.QueryUsersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.QueryUsersRequest.displayName =
    'proto.pomerium.dashboard.QueryUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.QueryUsersResponse = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.pomerium.dashboard.QueryUsersResponse.repeatedFields_,
    null
  );
};
goog.inherits(proto.pomerium.dashboard.QueryUsersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.QueryUsersResponse.displayName =
    'proto.pomerium.dashboard.QueryUsersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.PomeriumServiceAccount = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.PomeriumServiceAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.PomeriumServiceAccount.displayName =
    'proto.pomerium.dashboard.PomeriumServiceAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.AddPomeriumServiceAccountRequest = function (
  opt_data
) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(
  proto.pomerium.dashboard.AddPomeriumServiceAccountRequest,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.AddPomeriumServiceAccountRequest.displayName =
    'proto.pomerium.dashboard.AddPomeriumServiceAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.AddPomeriumServiceAccountResponse = function (
  opt_data
) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(
  proto.pomerium.dashboard.AddPomeriumServiceAccountResponse,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.AddPomeriumServiceAccountResponse.displayName =
    'proto.pomerium.dashboard.AddPomeriumServiceAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.DeletePomeriumServiceAccountRequest = function (
  opt_data
) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(
  proto.pomerium.dashboard.DeletePomeriumServiceAccountRequest,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.DeletePomeriumServiceAccountRequest.displayName =
    'proto.pomerium.dashboard.DeletePomeriumServiceAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.DeletePomeriumServiceAccountResponse = function (
  opt_data
) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(
  proto.pomerium.dashboard.DeletePomeriumServiceAccountResponse,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.DeletePomeriumServiceAccountResponse.displayName =
    'proto.pomerium.dashboard.DeletePomeriumServiceAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.GetPomeriumServiceAccountRequest = function (
  opt_data
) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(
  proto.pomerium.dashboard.GetPomeriumServiceAccountRequest,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.GetPomeriumServiceAccountRequest.displayName =
    'proto.pomerium.dashboard.GetPomeriumServiceAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.GetPomeriumServiceAccountResponse = function (
  opt_data
) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(
  proto.pomerium.dashboard.GetPomeriumServiceAccountResponse,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.GetPomeriumServiceAccountResponse.displayName =
    'proto.pomerium.dashboard.GetPomeriumServiceAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.ListPomeriumServiceAccountsRequest = function (
  opt_data
) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(
  proto.pomerium.dashboard.ListPomeriumServiceAccountsRequest,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.ListPomeriumServiceAccountsRequest.displayName =
    'proto.pomerium.dashboard.ListPomeriumServiceAccountsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.ListPomeriumServiceAccountsResponse = function (
  opt_data
) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.pomerium.dashboard.ListPomeriumServiceAccountsResponse
      .repeatedFields_,
    null
  );
};
goog.inherits(
  proto.pomerium.dashboard.ListPomeriumServiceAccountsResponse,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.ListPomeriumServiceAccountsResponse.displayName =
    'proto.pomerium.dashboard.ListPomeriumServiceAccountsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.PomeriumSession = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.pomerium.dashboard.PomeriumSession.repeatedFields_,
    null
  );
};
goog.inherits(proto.pomerium.dashboard.PomeriumSession, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.PomeriumSession.displayName =
    'proto.pomerium.dashboard.PomeriumSession';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.PomeriumSession.Group = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.PomeriumSession.Group, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.PomeriumSession.Group.displayName =
    'proto.pomerium.dashboard.PomeriumSession.Group';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.PomeriumSession.User = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.PomeriumSession.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.PomeriumSession.User.displayName =
    'proto.pomerium.dashboard.PomeriumSession.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.DeletePomeriumSessionRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(
  proto.pomerium.dashboard.DeletePomeriumSessionRequest,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.DeletePomeriumSessionRequest.displayName =
    'proto.pomerium.dashboard.DeletePomeriumSessionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.DeletePomeriumSessionResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(
  proto.pomerium.dashboard.DeletePomeriumSessionResponse,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.DeletePomeriumSessionResponse.displayName =
    'proto.pomerium.dashboard.DeletePomeriumSessionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.GetPomeriumSessionRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.GetPomeriumSessionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.GetPomeriumSessionRequest.displayName =
    'proto.pomerium.dashboard.GetPomeriumSessionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.GetPomeriumSessionResponse = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.pomerium.dashboard.GetPomeriumSessionResponse.repeatedFields_,
    null
  );
};
goog.inherits(
  proto.pomerium.dashboard.GetPomeriumSessionResponse,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.GetPomeriumSessionResponse.displayName =
    'proto.pomerium.dashboard.GetPomeriumSessionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.ListPomeriumSessionsRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(
  proto.pomerium.dashboard.ListPomeriumSessionsRequest,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.ListPomeriumSessionsRequest.displayName =
    'proto.pomerium.dashboard.ListPomeriumSessionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.ListPomeriumSessionsResponse = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.pomerium.dashboard.ListPomeriumSessionsResponse.repeatedFields_,
    null
  );
};
goog.inherits(
  proto.pomerium.dashboard.ListPomeriumSessionsResponse,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.ListPomeriumSessionsResponse.displayName =
    'proto.pomerium.dashboard.ListPomeriumSessionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.ImpersonateRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.ImpersonateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.ImpersonateRequest.displayName =
    'proto.pomerium.dashboard.ImpersonateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.ImpersonateResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.ImpersonateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.ImpersonateResponse.displayName =
    'proto.pomerium.dashboard.ImpersonateResponse';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.RecoveryToken.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.RecoveryToken.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.RecoveryToken} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.RecoveryToken.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, ''),
        namespace: jspb.Message.getFieldWithDefault(msg, 2, ''),
        createdAt:
          (f = msg.getCreatedAt()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        modifiedAt:
          (f = msg.getModifiedAt()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        expiresAt:
          (f = msg.getExpiresAt()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        publicKey: jspb.Message.getFieldWithDefault(msg, 6, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.RecoveryToken}
 */
proto.pomerium.dashboard.RecoveryToken.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.RecoveryToken();
  return proto.pomerium.dashboard.RecoveryToken.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.RecoveryToken} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.RecoveryToken}
 */
proto.pomerium.dashboard.RecoveryToken.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setNamespace(value);
        break;
      case 3:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        );
        msg.setCreatedAt(value);
        break;
      case 4:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        );
        msg.setModifiedAt(value);
        break;
      case 5:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        );
        msg.setExpiresAt(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setPublicKey(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.RecoveryToken.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.pomerium.dashboard.RecoveryToken.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.RecoveryToken} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.RecoveryToken.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getNamespace();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getModifiedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getExpiresAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPublicKey();
  if (f.length > 0) {
    writer.writeString(6, f);
  }
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.RecoveryToken.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.RecoveryToken} returns this
 */
proto.pomerium.dashboard.RecoveryToken.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string namespace = 2;
 * @return {string}
 */
proto.pomerium.dashboard.RecoveryToken.prototype.getNamespace = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.RecoveryToken} returns this
 */
proto.pomerium.dashboard.RecoveryToken.prototype.setNamespace = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional google.protobuf.Timestamp created_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pomerium.dashboard.RecoveryToken.prototype.getCreatedAt = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_timestamp_pb.Timestamp,
      3
    )
  );
};

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.pomerium.dashboard.RecoveryToken} returns this
 */
proto.pomerium.dashboard.RecoveryToken.prototype.setCreatedAt = function (
  value
) {
  return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.RecoveryToken} returns this
 */
proto.pomerium.dashboard.RecoveryToken.prototype.clearCreatedAt = function () {
  return this.setCreatedAt(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.RecoveryToken.prototype.hasCreatedAt = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional google.protobuf.Timestamp modified_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pomerium.dashboard.RecoveryToken.prototype.getModifiedAt = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_timestamp_pb.Timestamp,
      4
    )
  );
};

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.pomerium.dashboard.RecoveryToken} returns this
 */
proto.pomerium.dashboard.RecoveryToken.prototype.setModifiedAt = function (
  value
) {
  return jspb.Message.setWrapperField(this, 4, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.RecoveryToken} returns this
 */
proto.pomerium.dashboard.RecoveryToken.prototype.clearModifiedAt = function () {
  return this.setModifiedAt(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.RecoveryToken.prototype.hasModifiedAt = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional google.protobuf.Timestamp expires_at = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pomerium.dashboard.RecoveryToken.prototype.getExpiresAt = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_timestamp_pb.Timestamp,
      5
    )
  );
};

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.pomerium.dashboard.RecoveryToken} returns this
 */
proto.pomerium.dashboard.RecoveryToken.prototype.setExpiresAt = function (
  value
) {
  return jspb.Message.setWrapperField(this, 5, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.RecoveryToken} returns this
 */
proto.pomerium.dashboard.RecoveryToken.prototype.clearExpiresAt = function () {
  return this.setExpiresAt(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.RecoveryToken.prototype.hasExpiresAt = function () {
  return jspb.Message.getField(this, 5) != null;
};

/**
 * optional string public_key = 6;
 * @return {string}
 */
proto.pomerium.dashboard.RecoveryToken.prototype.getPublicKey = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.RecoveryToken} returns this
 */
proto.pomerium.dashboard.RecoveryToken.prototype.setPublicKey = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 6, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.GroupInfo.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.GroupInfo.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.GroupInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.GroupInfo.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, ''),
        name: jspb.Message.getFieldWithDefault(msg, 2, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.GroupInfo}
 */
proto.pomerium.dashboard.GroupInfo.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.GroupInfo();
  return proto.pomerium.dashboard.GroupInfo.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.GroupInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.GroupInfo}
 */
proto.pomerium.dashboard.GroupInfo.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.GroupInfo.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.pomerium.dashboard.GroupInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.GroupInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.GroupInfo.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.GroupInfo.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.GroupInfo} returns this
 */
proto.pomerium.dashboard.GroupInfo.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string name = 2;
 * @return {string}
 */
proto.pomerium.dashboard.GroupInfo.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.GroupInfo} returns this
 */
proto.pomerium.dashboard.GroupInfo.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pomerium.dashboard.UserInfo.repeatedFields_ = [4];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.UserInfo.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.UserInfo.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.UserInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.UserInfo.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, ''),
        name: jspb.Message.getFieldWithDefault(msg, 2, ''),
        email: jspb.Message.getFieldWithDefault(msg, 3, ''),
        groupsList:
          (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
        namespaceRolesMap: (f = msg.getNamespaceRolesMap())
          ? f.toObject(includeInstance, undefined)
          : [],
        pictureUrl: jspb.Message.getFieldWithDefault(msg, 6, ''),
        isImpersonated: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.UserInfo}
 */
proto.pomerium.dashboard.UserInfo.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.UserInfo();
  return proto.pomerium.dashboard.UserInfo.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.UserInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.UserInfo}
 */
proto.pomerium.dashboard.UserInfo.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setEmail(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.addGroups(value);
        break;
      case 5:
        var value = msg.getNamespaceRolesMap();
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(
            message,
            reader,
            jspb.BinaryReader.prototype.readString,
            jspb.BinaryReader.prototype.readString,
            null,
            '',
            ''
          );
        });
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setPictureUrl(value);
        break;
      case 7:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setIsImpersonated(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.UserInfo.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.pomerium.dashboard.UserInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.UserInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.UserInfo.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedString(4, f);
  }
  f = message.getNamespaceRolesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(
      5,
      writer,
      jspb.BinaryWriter.prototype.writeString,
      jspb.BinaryWriter.prototype.writeString
    );
  }
  f = message.getPictureUrl();
  if (f.length > 0) {
    writer.writeString(6, f);
  }
  f = message.getIsImpersonated();
  if (f) {
    writer.writeBool(7, f);
  }
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.UserInfo.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.UserInfo} returns this
 */
proto.pomerium.dashboard.UserInfo.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string name = 2;
 * @return {string}
 */
proto.pomerium.dashboard.UserInfo.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.UserInfo} returns this
 */
proto.pomerium.dashboard.UserInfo.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string email = 3;
 * @return {string}
 */
proto.pomerium.dashboard.UserInfo.prototype.getEmail = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.UserInfo} returns this
 */
proto.pomerium.dashboard.UserInfo.prototype.setEmail = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * repeated string groups = 4;
 * @return {!Array<string>}
 */
proto.pomerium.dashboard.UserInfo.prototype.getGroupsList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.pomerium.dashboard.UserInfo} returns this
 */
proto.pomerium.dashboard.UserInfo.prototype.setGroupsList = function (value) {
  return jspb.Message.setField(this, 4, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.UserInfo} returns this
 */
proto.pomerium.dashboard.UserInfo.prototype.addGroups = function (
  value,
  opt_index
) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.UserInfo} returns this
 */
proto.pomerium.dashboard.UserInfo.prototype.clearGroupsList = function () {
  return this.setGroupsList([]);
};

/**
 * map<string, string> namespace_roles = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.pomerium.dashboard.UserInfo.prototype.getNamespaceRolesMap = function (
  opt_noLazyCreate
) {
  return /** @type {!jspb.Map<string,string>} */ (
    jspb.Message.getMapField(this, 5, opt_noLazyCreate, null)
  );
};

/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.pomerium.dashboard.UserInfo} returns this
 */
proto.pomerium.dashboard.UserInfo.prototype.clearNamespaceRolesMap =
  function () {
    this.getNamespaceRolesMap().clear();
    return this;
  };

/**
 * optional string picture_url = 6;
 * @return {string}
 */
proto.pomerium.dashboard.UserInfo.prototype.getPictureUrl = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.UserInfo} returns this
 */
proto.pomerium.dashboard.UserInfo.prototype.setPictureUrl = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};

/**
 * optional bool is_impersonated = 7;
 * @return {boolean}
 */
proto.pomerium.dashboard.UserInfo.prototype.getIsImpersonated = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 7, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.pomerium.dashboard.UserInfo} returns this
 */
proto.pomerium.dashboard.UserInfo.prototype.setIsImpersonated = function (
  value
) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.GetUserInfoRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.GetUserInfoRequest.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.GetUserInfoRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.GetUserInfoRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        userId: jspb.Message.getFieldWithDefault(msg, 1, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.GetUserInfoRequest}
 */
proto.pomerium.dashboard.GetUserInfoRequest.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.GetUserInfoRequest();
  return proto.pomerium.dashboard.GetUserInfoRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.GetUserInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.GetUserInfoRequest}
 */
proto.pomerium.dashboard.GetUserInfoRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setUserId(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.GetUserInfoRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.GetUserInfoRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.GetUserInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.GetUserInfoRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(1, f);
  }
};

/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.GetUserInfoRequest.prototype.getUserId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.GetUserInfoRequest} returns this
 */
proto.pomerium.dashboard.GetUserInfoRequest.prototype.setUserId = function (
  value
) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.GetUserInfoRequest} returns this
 */
proto.pomerium.dashboard.GetUserInfoRequest.prototype.clearUserId =
  function () {
    return jspb.Message.setField(this, 1, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.GetUserInfoRequest.prototype.hasUserId = function () {
  return jspb.Message.getField(this, 1) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.GetUserInfoResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.GetUserInfoResponse.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.GetUserInfoResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.GetUserInfoResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        userInfo:
          (f = msg.getUserInfo()) &&
          proto.pomerium.dashboard.UserInfo.toObject(includeInstance, f)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.GetUserInfoResponse}
 */
proto.pomerium.dashboard.GetUserInfoResponse.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.GetUserInfoResponse();
  return proto.pomerium.dashboard.GetUserInfoResponse.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.GetUserInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.GetUserInfoResponse}
 */
proto.pomerium.dashboard.GetUserInfoResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.UserInfo();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.UserInfo.deserializeBinaryFromReader
          );
          msg.setUserInfo(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.GetUserInfoResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.GetUserInfoResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.GetUserInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.GetUserInfoResponse.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getUserInfo();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        proto.pomerium.dashboard.UserInfo.serializeBinaryToWriter
      );
    }
  };

/**
 * optional UserInfo user_info = 1;
 * @return {?proto.pomerium.dashboard.UserInfo}
 */
proto.pomerium.dashboard.GetUserInfoResponse.prototype.getUserInfo =
  function () {
    return /** @type{?proto.pomerium.dashboard.UserInfo} */ (
      jspb.Message.getWrapperField(this, proto.pomerium.dashboard.UserInfo, 1)
    );
  };

/**
 * @param {?proto.pomerium.dashboard.UserInfo|undefined} value
 * @return {!proto.pomerium.dashboard.GetUserInfoResponse} returns this
 */
proto.pomerium.dashboard.GetUserInfoResponse.prototype.setUserInfo = function (
  value
) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.GetUserInfoResponse} returns this
 */
proto.pomerium.dashboard.GetUserInfoResponse.prototype.clearUserInfo =
  function () {
    return this.setUserInfo(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.GetUserInfoResponse.prototype.hasUserInfo =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.QueryGroupsRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.QueryGroupsRequest.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.QueryGroupsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.QueryGroupsRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        query: jspb.Message.getFieldWithDefault(msg, 1, ''),
        offset: jspb.Message.getFieldWithDefault(msg, 2, 0),
        limit: jspb.Message.getFieldWithDefault(msg, 3, 0)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.QueryGroupsRequest}
 */
proto.pomerium.dashboard.QueryGroupsRequest.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.QueryGroupsRequest();
  return proto.pomerium.dashboard.QueryGroupsRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.QueryGroupsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.QueryGroupsRequest}
 */
proto.pomerium.dashboard.QueryGroupsRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setQuery(value);
          break;
        case 2:
          var value = /** @type {number} */ (reader.readInt64());
          msg.setOffset(value);
          break;
        case 3:
          var value = /** @type {number} */ (reader.readInt64());
          msg.setLimit(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.QueryGroupsRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.QueryGroupsRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.QueryGroupsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.QueryGroupsRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt64(2, f);
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(3, f);
  }
};

/**
 * optional string query = 1;
 * @return {string}
 */
proto.pomerium.dashboard.QueryGroupsRequest.prototype.getQuery = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.QueryGroupsRequest} returns this
 */
proto.pomerium.dashboard.QueryGroupsRequest.prototype.setQuery = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional int64 offset = 2;
 * @return {number}
 */
proto.pomerium.dashboard.QueryGroupsRequest.prototype.getOffset = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.pomerium.dashboard.QueryGroupsRequest} returns this
 */
proto.pomerium.dashboard.QueryGroupsRequest.prototype.setOffset = function (
  value
) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional int64 limit = 3;
 * @return {number}
 */
proto.pomerium.dashboard.QueryGroupsRequest.prototype.getLimit = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.pomerium.dashboard.QueryGroupsRequest} returns this
 */
proto.pomerium.dashboard.QueryGroupsRequest.prototype.setLimit = function (
  value
) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pomerium.dashboard.QueryGroupsResponse.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.QueryGroupsResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.QueryGroupsResponse.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.QueryGroupsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.QueryGroupsResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        groupsList: jspb.Message.toObjectList(
          msg.getGroupsList(),
          proto.pomerium.dashboard.GroupInfo.toObject,
          includeInstance
        ),
        totalCount: jspb.Message.getFieldWithDefault(msg, 2, 0)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.QueryGroupsResponse}
 */
proto.pomerium.dashboard.QueryGroupsResponse.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.QueryGroupsResponse();
  return proto.pomerium.dashboard.QueryGroupsResponse.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.QueryGroupsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.QueryGroupsResponse}
 */
proto.pomerium.dashboard.QueryGroupsResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.GroupInfo();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.GroupInfo.deserializeBinaryFromReader
          );
          msg.addGroups(value);
          break;
        case 2:
          var value = /** @type {number} */ (reader.readInt64());
          msg.setTotalCount(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.QueryGroupsResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.QueryGroupsResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.QueryGroupsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.QueryGroupsResponse.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getGroupsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        proto.pomerium.dashboard.GroupInfo.serializeBinaryToWriter
      );
    }
    f = message.getTotalCount();
    if (f !== 0) {
      writer.writeInt64(2, f);
    }
  };

/**
 * repeated GroupInfo groups = 1;
 * @return {!Array<!proto.pomerium.dashboard.GroupInfo>}
 */
proto.pomerium.dashboard.QueryGroupsResponse.prototype.getGroupsList =
  function () {
    return /** @type{!Array<!proto.pomerium.dashboard.GroupInfo>} */ (
      jspb.Message.getRepeatedWrapperField(
        this,
        proto.pomerium.dashboard.GroupInfo,
        1
      )
    );
  };

/**
 * @param {!Array<!proto.pomerium.dashboard.GroupInfo>} value
 * @return {!proto.pomerium.dashboard.QueryGroupsResponse} returns this
 */
proto.pomerium.dashboard.QueryGroupsResponse.prototype.setGroupsList =
  function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
  };

/**
 * @param {!proto.pomerium.dashboard.GroupInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.GroupInfo}
 */
proto.pomerium.dashboard.QueryGroupsResponse.prototype.addGroups = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.pomerium.dashboard.GroupInfo,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.QueryGroupsResponse} returns this
 */
proto.pomerium.dashboard.QueryGroupsResponse.prototype.clearGroupsList =
  function () {
    return this.setGroupsList([]);
  };

/**
 * optional int64 total_count = 2;
 * @return {number}
 */
proto.pomerium.dashboard.QueryGroupsResponse.prototype.getTotalCount =
  function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };

/**
 * @param {number} value
 * @return {!proto.pomerium.dashboard.QueryGroupsResponse} returns this
 */
proto.pomerium.dashboard.QueryGroupsResponse.prototype.setTotalCount =
  function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.QueryUsersRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.QueryUsersRequest.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.QueryUsersRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.QueryUsersRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        query: jspb.Message.getFieldWithDefault(msg, 1, ''),
        offset: jspb.Message.getFieldWithDefault(msg, 2, 0),
        limit: jspb.Message.getFieldWithDefault(msg, 3, 0)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.QueryUsersRequest}
 */
proto.pomerium.dashboard.QueryUsersRequest.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.QueryUsersRequest();
  return proto.pomerium.dashboard.QueryUsersRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.QueryUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.QueryUsersRequest}
 */
proto.pomerium.dashboard.QueryUsersRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setQuery(value);
          break;
        case 2:
          var value = /** @type {number} */ (reader.readInt64());
          msg.setOffset(value);
          break;
        case 3:
          var value = /** @type {number} */ (reader.readInt64());
          msg.setLimit(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.QueryUsersRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.QueryUsersRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.QueryUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.QueryUsersRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt64(2, f);
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(3, f);
  }
};

/**
 * optional string query = 1;
 * @return {string}
 */
proto.pomerium.dashboard.QueryUsersRequest.prototype.getQuery = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.QueryUsersRequest} returns this
 */
proto.pomerium.dashboard.QueryUsersRequest.prototype.setQuery = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional int64 offset = 2;
 * @return {number}
 */
proto.pomerium.dashboard.QueryUsersRequest.prototype.getOffset = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.pomerium.dashboard.QueryUsersRequest} returns this
 */
proto.pomerium.dashboard.QueryUsersRequest.prototype.setOffset = function (
  value
) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional int64 limit = 3;
 * @return {number}
 */
proto.pomerium.dashboard.QueryUsersRequest.prototype.getLimit = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.pomerium.dashboard.QueryUsersRequest} returns this
 */
proto.pomerium.dashboard.QueryUsersRequest.prototype.setLimit = function (
  value
) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pomerium.dashboard.QueryUsersResponse.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.QueryUsersResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.QueryUsersResponse.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.QueryUsersResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.QueryUsersResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        usersList: jspb.Message.toObjectList(
          msg.getUsersList(),
          proto.pomerium.dashboard.UserInfo.toObject,
          includeInstance
        ),
        totalCount: jspb.Message.getFieldWithDefault(msg, 2, 0)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.QueryUsersResponse}
 */
proto.pomerium.dashboard.QueryUsersResponse.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.QueryUsersResponse();
  return proto.pomerium.dashboard.QueryUsersResponse.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.QueryUsersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.QueryUsersResponse}
 */
proto.pomerium.dashboard.QueryUsersResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.UserInfo();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.UserInfo.deserializeBinaryFromReader
          );
          msg.addUsers(value);
          break;
        case 2:
          var value = /** @type {number} */ (reader.readInt64());
          msg.setTotalCount(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.QueryUsersResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.QueryUsersResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.QueryUsersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.QueryUsersResponse.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pomerium.dashboard.UserInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt64(2, f);
  }
};

/**
 * repeated UserInfo users = 1;
 * @return {!Array<!proto.pomerium.dashboard.UserInfo>}
 */
proto.pomerium.dashboard.QueryUsersResponse.prototype.getUsersList =
  function () {
    return /** @type{!Array<!proto.pomerium.dashboard.UserInfo>} */ (
      jspb.Message.getRepeatedWrapperField(
        this,
        proto.pomerium.dashboard.UserInfo,
        1
      )
    );
  };

/**
 * @param {!Array<!proto.pomerium.dashboard.UserInfo>} value
 * @return {!proto.pomerium.dashboard.QueryUsersResponse} returns this
 */
proto.pomerium.dashboard.QueryUsersResponse.prototype.setUsersList = function (
  value
) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.pomerium.dashboard.UserInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.UserInfo}
 */
proto.pomerium.dashboard.QueryUsersResponse.prototype.addUsers = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.pomerium.dashboard.UserInfo,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.QueryUsersResponse} returns this
 */
proto.pomerium.dashboard.QueryUsersResponse.prototype.clearUsersList =
  function () {
    return this.setUsersList([]);
  };

/**
 * optional int64 total_count = 2;
 * @return {number}
 */
proto.pomerium.dashboard.QueryUsersResponse.prototype.getTotalCount =
  function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };

/**
 * @param {number} value
 * @return {!proto.pomerium.dashboard.QueryUsersResponse} returns this
 */
proto.pomerium.dashboard.QueryUsersResponse.prototype.setTotalCount = function (
  value
) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.PomeriumServiceAccount.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.PomeriumServiceAccount.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.PomeriumServiceAccount} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.PomeriumServiceAccount.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, ''),
        namespaceId: jspb.Message.getFieldWithDefault(msg, 8, ''),
        description: jspb.Message.getFieldWithDefault(msg, 9, ''),
        userId: jspb.Message.getFieldWithDefault(msg, 2, ''),
        expiresAt:
          (f = msg.getExpiresAt()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        issuedAt:
          (f = msg.getIssuedAt()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.PomeriumServiceAccount}
 */
proto.pomerium.dashboard.PomeriumServiceAccount.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.PomeriumServiceAccount();
  return proto.pomerium.dashboard.PomeriumServiceAccount.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.PomeriumServiceAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.PomeriumServiceAccount}
 */
proto.pomerium.dashboard.PomeriumServiceAccount.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setId(value);
          break;
        case 8:
          var value = /** @type {string} */ (reader.readString());
          msg.setNamespaceId(value);
          break;
        case 9:
          var value = /** @type {string} */ (reader.readString());
          msg.setDescription(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setUserId(value);
          break;
        case 3:
          var value = new google_protobuf_timestamp_pb.Timestamp();
          reader.readMessage(
            value,
            google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
          );
          msg.setExpiresAt(value);
          break;
        case 4:
          var value = new google_protobuf_timestamp_pb.Timestamp();
          reader.readMessage(
            value,
            google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
          );
          msg.setIssuedAt(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.PomeriumServiceAccount.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.PomeriumServiceAccount.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.PomeriumServiceAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.PomeriumServiceAccount.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getId();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 8));
    if (f != null) {
      writer.writeString(8, f);
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 9));
    if (f != null) {
      writer.writeString(9, f);
    }
    f = message.getUserId();
    if (f.length > 0) {
      writer.writeString(2, f);
    }
    f = message.getExpiresAt();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
      );
    }
    f = message.getIssuedAt();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
      );
    }
  };

/**
 * optional string id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.PomeriumServiceAccount.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.PomeriumServiceAccount} returns this
 */
proto.pomerium.dashboard.PomeriumServiceAccount.prototype.setId = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string namespace_id = 8;
 * @return {string}
 */
proto.pomerium.dashboard.PomeriumServiceAccount.prototype.getNamespaceId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 8, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.PomeriumServiceAccount} returns this
 */
proto.pomerium.dashboard.PomeriumServiceAccount.prototype.setNamespaceId =
  function (value) {
    return jspb.Message.setField(this, 8, value);
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.PomeriumServiceAccount} returns this
 */
proto.pomerium.dashboard.PomeriumServiceAccount.prototype.clearNamespaceId =
  function () {
    return jspb.Message.setField(this, 8, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.PomeriumServiceAccount.prototype.hasNamespaceId =
  function () {
    return jspb.Message.getField(this, 8) != null;
  };

/**
 * optional string description = 9;
 * @return {string}
 */
proto.pomerium.dashboard.PomeriumServiceAccount.prototype.getDescription =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 9, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.PomeriumServiceAccount} returns this
 */
proto.pomerium.dashboard.PomeriumServiceAccount.prototype.setDescription =
  function (value) {
    return jspb.Message.setField(this, 9, value);
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.PomeriumServiceAccount} returns this
 */
proto.pomerium.dashboard.PomeriumServiceAccount.prototype.clearDescription =
  function () {
    return jspb.Message.setField(this, 9, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.PomeriumServiceAccount.prototype.hasDescription =
  function () {
    return jspb.Message.getField(this, 9) != null;
  };

/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.pomerium.dashboard.PomeriumServiceAccount.prototype.getUserId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 2, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.PomeriumServiceAccount} returns this
 */
proto.pomerium.dashboard.PomeriumServiceAccount.prototype.setUserId = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional google.protobuf.Timestamp expires_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pomerium.dashboard.PomeriumServiceAccount.prototype.getExpiresAt =
  function () {
    return /** @type{?proto.google.protobuf.Timestamp} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_timestamp_pb.Timestamp,
        3
      )
    );
  };

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.pomerium.dashboard.PomeriumServiceAccount} returns this
 */
proto.pomerium.dashboard.PomeriumServiceAccount.prototype.setExpiresAt =
  function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.PomeriumServiceAccount} returns this
 */
proto.pomerium.dashboard.PomeriumServiceAccount.prototype.clearExpiresAt =
  function () {
    return this.setExpiresAt(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.PomeriumServiceAccount.prototype.hasExpiresAt =
  function () {
    return jspb.Message.getField(this, 3) != null;
  };

/**
 * optional google.protobuf.Timestamp issued_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pomerium.dashboard.PomeriumServiceAccount.prototype.getIssuedAt =
  function () {
    return /** @type{?proto.google.protobuf.Timestamp} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_timestamp_pb.Timestamp,
        4
      )
    );
  };

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.pomerium.dashboard.PomeriumServiceAccount} returns this
 */
proto.pomerium.dashboard.PomeriumServiceAccount.prototype.setIssuedAt =
  function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.PomeriumServiceAccount} returns this
 */
proto.pomerium.dashboard.PomeriumServiceAccount.prototype.clearIssuedAt =
  function () {
    return this.setIssuedAt(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.PomeriumServiceAccount.prototype.hasIssuedAt =
  function () {
    return jspb.Message.getField(this, 4) != null;
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.AddPomeriumServiceAccountRequest.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.AddPomeriumServiceAccountRequest.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.AddPomeriumServiceAccountRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.AddPomeriumServiceAccountRequest.toObject =
    function (includeInstance, msg) {
      var f,
        obj = {
          serviceAccount:
            (f = msg.getServiceAccount()) &&
            proto.pomerium.dashboard.PomeriumServiceAccount.toObject(
              includeInstance,
              f
            )
        };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }
      return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.AddPomeriumServiceAccountRequest}
 */
proto.pomerium.dashboard.AddPomeriumServiceAccountRequest.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pomerium.dashboard.AddPomeriumServiceAccountRequest();
    return proto.pomerium.dashboard.AddPomeriumServiceAccountRequest.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.AddPomeriumServiceAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.AddPomeriumServiceAccountRequest}
 */
proto.pomerium.dashboard.AddPomeriumServiceAccountRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.PomeriumServiceAccount();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.PomeriumServiceAccount
              .deserializeBinaryFromReader
          );
          msg.setServiceAccount(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.AddPomeriumServiceAccountRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.AddPomeriumServiceAccountRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.AddPomeriumServiceAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.AddPomeriumServiceAccountRequest.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getServiceAccount();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        proto.pomerium.dashboard.PomeriumServiceAccount.serializeBinaryToWriter
      );
    }
  };

/**
 * optional PomeriumServiceAccount service_account = 1;
 * @return {?proto.pomerium.dashboard.PomeriumServiceAccount}
 */
proto.pomerium.dashboard.AddPomeriumServiceAccountRequest.prototype.getServiceAccount =
  function () {
    return /** @type{?proto.pomerium.dashboard.PomeriumServiceAccount} */ (
      jspb.Message.getWrapperField(
        this,
        proto.pomerium.dashboard.PomeriumServiceAccount,
        1
      )
    );
  };

/**
 * @param {?proto.pomerium.dashboard.PomeriumServiceAccount|undefined} value
 * @return {!proto.pomerium.dashboard.AddPomeriumServiceAccountRequest} returns this
 */
proto.pomerium.dashboard.AddPomeriumServiceAccountRequest.prototype.setServiceAccount =
  function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.AddPomeriumServiceAccountRequest} returns this
 */
proto.pomerium.dashboard.AddPomeriumServiceAccountRequest.prototype.clearServiceAccount =
  function () {
    return this.setServiceAccount(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.AddPomeriumServiceAccountRequest.prototype.hasServiceAccount =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.AddPomeriumServiceAccountResponse.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.AddPomeriumServiceAccountResponse.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.AddPomeriumServiceAccountResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.AddPomeriumServiceAccountResponse.toObject =
    function (includeInstance, msg) {
      var f,
        obj = {
          serviceAccount:
            (f = msg.getServiceAccount()) &&
            proto.pomerium.dashboard.PomeriumServiceAccount.toObject(
              includeInstance,
              f
            ),
          jwt: jspb.Message.getFieldWithDefault(msg, 2, '')
        };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }
      return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.AddPomeriumServiceAccountResponse}
 */
proto.pomerium.dashboard.AddPomeriumServiceAccountResponse.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pomerium.dashboard.AddPomeriumServiceAccountResponse();
    return proto.pomerium.dashboard.AddPomeriumServiceAccountResponse.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.AddPomeriumServiceAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.AddPomeriumServiceAccountResponse}
 */
proto.pomerium.dashboard.AddPomeriumServiceAccountResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.PomeriumServiceAccount();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.PomeriumServiceAccount
              .deserializeBinaryFromReader
          );
          msg.setServiceAccount(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setJwt(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.AddPomeriumServiceAccountResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.AddPomeriumServiceAccountResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.AddPomeriumServiceAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.AddPomeriumServiceAccountResponse.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getServiceAccount();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        proto.pomerium.dashboard.PomeriumServiceAccount.serializeBinaryToWriter
      );
    }
    f = message.getJwt();
    if (f.length > 0) {
      writer.writeString(2, f);
    }
  };

/**
 * optional PomeriumServiceAccount service_account = 1;
 * @return {?proto.pomerium.dashboard.PomeriumServiceAccount}
 */
proto.pomerium.dashboard.AddPomeriumServiceAccountResponse.prototype.getServiceAccount =
  function () {
    return /** @type{?proto.pomerium.dashboard.PomeriumServiceAccount} */ (
      jspb.Message.getWrapperField(
        this,
        proto.pomerium.dashboard.PomeriumServiceAccount,
        1
      )
    );
  };

/**
 * @param {?proto.pomerium.dashboard.PomeriumServiceAccount|undefined} value
 * @return {!proto.pomerium.dashboard.AddPomeriumServiceAccountResponse} returns this
 */
proto.pomerium.dashboard.AddPomeriumServiceAccountResponse.prototype.setServiceAccount =
  function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.AddPomeriumServiceAccountResponse} returns this
 */
proto.pomerium.dashboard.AddPomeriumServiceAccountResponse.prototype.clearServiceAccount =
  function () {
    return this.setServiceAccount(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.AddPomeriumServiceAccountResponse.prototype.hasServiceAccount =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

/**
 * optional string JWT = 2;
 * @return {string}
 */
proto.pomerium.dashboard.AddPomeriumServiceAccountResponse.prototype.getJwt =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 2, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.AddPomeriumServiceAccountResponse} returns this
 */
proto.pomerium.dashboard.AddPomeriumServiceAccountResponse.prototype.setJwt =
  function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.DeletePomeriumServiceAccountRequest.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.DeletePomeriumServiceAccountRequest.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.DeletePomeriumServiceAccountRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.DeletePomeriumServiceAccountRequest.toObject =
    function (includeInstance, msg) {
      var f,
        obj = {
          id: jspb.Message.getFieldWithDefault(msg, 1, '')
        };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }
      return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.DeletePomeriumServiceAccountRequest}
 */
proto.pomerium.dashboard.DeletePomeriumServiceAccountRequest.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg =
      new proto.pomerium.dashboard.DeletePomeriumServiceAccountRequest();
    return proto.pomerium.dashboard.DeletePomeriumServiceAccountRequest.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.DeletePomeriumServiceAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.DeletePomeriumServiceAccountRequest}
 */
proto.pomerium.dashboard.DeletePomeriumServiceAccountRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setId(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.DeletePomeriumServiceAccountRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.DeletePomeriumServiceAccountRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.DeletePomeriumServiceAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.DeletePomeriumServiceAccountRequest.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getId();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
  };

/**
 * optional string id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.DeletePomeriumServiceAccountRequest.prototype.getId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.DeletePomeriumServiceAccountRequest} returns this
 */
proto.pomerium.dashboard.DeletePomeriumServiceAccountRequest.prototype.setId =
  function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.DeletePomeriumServiceAccountResponse.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.DeletePomeriumServiceAccountResponse.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.DeletePomeriumServiceAccountResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.DeletePomeriumServiceAccountResponse.toObject =
    function (includeInstance, msg) {
      var f,
        obj = {};

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }
      return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.DeletePomeriumServiceAccountResponse}
 */
proto.pomerium.dashboard.DeletePomeriumServiceAccountResponse.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg =
      new proto.pomerium.dashboard.DeletePomeriumServiceAccountResponse();
    return proto.pomerium.dashboard.DeletePomeriumServiceAccountResponse.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.DeletePomeriumServiceAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.DeletePomeriumServiceAccountResponse}
 */
proto.pomerium.dashboard.DeletePomeriumServiceAccountResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.DeletePomeriumServiceAccountResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.DeletePomeriumServiceAccountResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.DeletePomeriumServiceAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.DeletePomeriumServiceAccountResponse.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.GetPomeriumServiceAccountRequest.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.GetPomeriumServiceAccountRequest.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.GetPomeriumServiceAccountRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.GetPomeriumServiceAccountRequest.toObject =
    function (includeInstance, msg) {
      var f,
        obj = {
          id: jspb.Message.getFieldWithDefault(msg, 1, '')
        };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }
      return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.GetPomeriumServiceAccountRequest}
 */
proto.pomerium.dashboard.GetPomeriumServiceAccountRequest.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pomerium.dashboard.GetPomeriumServiceAccountRequest();
    return proto.pomerium.dashboard.GetPomeriumServiceAccountRequest.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.GetPomeriumServiceAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.GetPomeriumServiceAccountRequest}
 */
proto.pomerium.dashboard.GetPomeriumServiceAccountRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setId(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.GetPomeriumServiceAccountRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.GetPomeriumServiceAccountRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.GetPomeriumServiceAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.GetPomeriumServiceAccountRequest.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getId();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
  };

/**
 * optional string id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.GetPomeriumServiceAccountRequest.prototype.getId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.GetPomeriumServiceAccountRequest} returns this
 */
proto.pomerium.dashboard.GetPomeriumServiceAccountRequest.prototype.setId =
  function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.GetPomeriumServiceAccountResponse.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.GetPomeriumServiceAccountResponse.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.GetPomeriumServiceAccountResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.GetPomeriumServiceAccountResponse.toObject =
    function (includeInstance, msg) {
      var f,
        obj = {
          serviceAccount:
            (f = msg.getServiceAccount()) &&
            proto.pomerium.dashboard.PomeriumServiceAccount.toObject(
              includeInstance,
              f
            )
        };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }
      return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.GetPomeriumServiceAccountResponse}
 */
proto.pomerium.dashboard.GetPomeriumServiceAccountResponse.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pomerium.dashboard.GetPomeriumServiceAccountResponse();
    return proto.pomerium.dashboard.GetPomeriumServiceAccountResponse.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.GetPomeriumServiceAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.GetPomeriumServiceAccountResponse}
 */
proto.pomerium.dashboard.GetPomeriumServiceAccountResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.PomeriumServiceAccount();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.PomeriumServiceAccount
              .deserializeBinaryFromReader
          );
          msg.setServiceAccount(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.GetPomeriumServiceAccountResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.GetPomeriumServiceAccountResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.GetPomeriumServiceAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.GetPomeriumServiceAccountResponse.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getServiceAccount();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        proto.pomerium.dashboard.PomeriumServiceAccount.serializeBinaryToWriter
      );
    }
  };

/**
 * optional PomeriumServiceAccount service_account = 1;
 * @return {?proto.pomerium.dashboard.PomeriumServiceAccount}
 */
proto.pomerium.dashboard.GetPomeriumServiceAccountResponse.prototype.getServiceAccount =
  function () {
    return /** @type{?proto.pomerium.dashboard.PomeriumServiceAccount} */ (
      jspb.Message.getWrapperField(
        this,
        proto.pomerium.dashboard.PomeriumServiceAccount,
        1
      )
    );
  };

/**
 * @param {?proto.pomerium.dashboard.PomeriumServiceAccount|undefined} value
 * @return {!proto.pomerium.dashboard.GetPomeriumServiceAccountResponse} returns this
 */
proto.pomerium.dashboard.GetPomeriumServiceAccountResponse.prototype.setServiceAccount =
  function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.GetPomeriumServiceAccountResponse} returns this
 */
proto.pomerium.dashboard.GetPomeriumServiceAccountResponse.prototype.clearServiceAccount =
  function () {
    return this.setServiceAccount(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.GetPomeriumServiceAccountResponse.prototype.hasServiceAccount =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.ListPomeriumServiceAccountsRequest.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.ListPomeriumServiceAccountsRequest.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.ListPomeriumServiceAccountsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.ListPomeriumServiceAccountsRequest.toObject =
    function (includeInstance, msg) {
      var f,
        obj = {
          namespace: jspb.Message.getFieldWithDefault(msg, 1, '')
        };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }
      return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.ListPomeriumServiceAccountsRequest}
 */
proto.pomerium.dashboard.ListPomeriumServiceAccountsRequest.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pomerium.dashboard.ListPomeriumServiceAccountsRequest();
    return proto.pomerium.dashboard.ListPomeriumServiceAccountsRequest.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.ListPomeriumServiceAccountsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.ListPomeriumServiceAccountsRequest}
 */
proto.pomerium.dashboard.ListPomeriumServiceAccountsRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setNamespace(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.ListPomeriumServiceAccountsRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.ListPomeriumServiceAccountsRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.ListPomeriumServiceAccountsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.ListPomeriumServiceAccountsRequest.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getNamespace();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
  };

/**
 * optional string namespace = 1;
 * @return {string}
 */
proto.pomerium.dashboard.ListPomeriumServiceAccountsRequest.prototype.getNamespace =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ListPomeriumServiceAccountsRequest} returns this
 */
proto.pomerium.dashboard.ListPomeriumServiceAccountsRequest.prototype.setNamespace =
  function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
  };

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pomerium.dashboard.ListPomeriumServiceAccountsResponse.repeatedFields_ = [
  1
];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.ListPomeriumServiceAccountsResponse.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.ListPomeriumServiceAccountsResponse.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.ListPomeriumServiceAccountsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.ListPomeriumServiceAccountsResponse.toObject =
    function (includeInstance, msg) {
      var f,
        obj = {
          serviceAccountsList: jspb.Message.toObjectList(
            msg.getServiceAccountsList(),
            proto.pomerium.dashboard.PomeriumServiceAccount.toObject,
            includeInstance
          )
        };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }
      return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.ListPomeriumServiceAccountsResponse}
 */
proto.pomerium.dashboard.ListPomeriumServiceAccountsResponse.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg =
      new proto.pomerium.dashboard.ListPomeriumServiceAccountsResponse();
    return proto.pomerium.dashboard.ListPomeriumServiceAccountsResponse.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.ListPomeriumServiceAccountsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.ListPomeriumServiceAccountsResponse}
 */
proto.pomerium.dashboard.ListPomeriumServiceAccountsResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.PomeriumServiceAccount();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.PomeriumServiceAccount
              .deserializeBinaryFromReader
          );
          msg.addServiceAccounts(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.ListPomeriumServiceAccountsResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.ListPomeriumServiceAccountsResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.ListPomeriumServiceAccountsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.ListPomeriumServiceAccountsResponse.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getServiceAccountsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        proto.pomerium.dashboard.PomeriumServiceAccount.serializeBinaryToWriter
      );
    }
  };

/**
 * repeated PomeriumServiceAccount service_accounts = 1;
 * @return {!Array<!proto.pomerium.dashboard.PomeriumServiceAccount>}
 */
proto.pomerium.dashboard.ListPomeriumServiceAccountsResponse.prototype.getServiceAccountsList =
  function () {
    return /** @type{!Array<!proto.pomerium.dashboard.PomeriumServiceAccount>} */ (
      jspb.Message.getRepeatedWrapperField(
        this,
        proto.pomerium.dashboard.PomeriumServiceAccount,
        1
      )
    );
  };

/**
 * @param {!Array<!proto.pomerium.dashboard.PomeriumServiceAccount>} value
 * @return {!proto.pomerium.dashboard.ListPomeriumServiceAccountsResponse} returns this
 */
proto.pomerium.dashboard.ListPomeriumServiceAccountsResponse.prototype.setServiceAccountsList =
  function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
  };

/**
 * @param {!proto.pomerium.dashboard.PomeriumServiceAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.PomeriumServiceAccount}
 */
proto.pomerium.dashboard.ListPomeriumServiceAccountsResponse.prototype.addServiceAccounts =
  function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(
      this,
      1,
      opt_value,
      proto.pomerium.dashboard.PomeriumServiceAccount,
      opt_index
    );
  };

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.ListPomeriumServiceAccountsResponse} returns this
 */
proto.pomerium.dashboard.ListPomeriumServiceAccountsResponse.prototype.clearServiceAccountsList =
  function () {
    return this.setServiceAccountsList([]);
  };

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pomerium.dashboard.PomeriumSession.repeatedFields_ = [3, 7];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.PomeriumSession.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.PomeriumSession.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.PomeriumSession} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.PomeriumSession.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, ''),
        user:
          (f = msg.getUser()) &&
          proto.pomerium.dashboard.PomeriumSession.User.toObject(
            includeInstance,
            f
          ),
        groupsList: jspb.Message.toObjectList(
          msg.getGroupsList(),
          proto.pomerium.dashboard.PomeriumSession.Group.toObject,
          includeInstance
        ),
        issuer: jspb.Message.getFieldWithDefault(msg, 4, ''),
        issuedAt:
          (f = msg.getIssuedAt()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        expiresAt:
          (f = msg.getExpiresAt()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        audienceList:
          (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
        claimsMap: (f = msg.getClaimsMap())
          ? f.toObject(
              includeInstance,
              proto.google.protobuf.ListValue.toObject
            )
          : []
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.PomeriumSession}
 */
proto.pomerium.dashboard.PomeriumSession.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.PomeriumSession();
  return proto.pomerium.dashboard.PomeriumSession.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.PomeriumSession} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.PomeriumSession}
 */
proto.pomerium.dashboard.PomeriumSession.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setId(value);
          break;
        case 2:
          var value = new proto.pomerium.dashboard.PomeriumSession.User();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.PomeriumSession.User
              .deserializeBinaryFromReader
          );
          msg.setUser(value);
          break;
        case 3:
          var value = new proto.pomerium.dashboard.PomeriumSession.Group();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.PomeriumSession.Group
              .deserializeBinaryFromReader
          );
          msg.addGroups(value);
          break;
        case 4:
          var value = /** @type {string} */ (reader.readString());
          msg.setIssuer(value);
          break;
        case 5:
          var value = new google_protobuf_timestamp_pb.Timestamp();
          reader.readMessage(
            value,
            google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
          );
          msg.setIssuedAt(value);
          break;
        case 6:
          var value = new google_protobuf_timestamp_pb.Timestamp();
          reader.readMessage(
            value,
            google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
          );
          msg.setExpiresAt(value);
          break;
        case 7:
          var value = /** @type {string} */ (reader.readString());
          msg.addAudience(value);
          break;
        case 8:
          var value = msg.getClaimsMap();
          reader.readMessage(value, function (message, reader) {
            jspb.Map.deserializeBinary(
              message,
              reader,
              jspb.BinaryReader.prototype.readString,
              jspb.BinaryReader.prototype.readMessage,
              proto.google.protobuf.ListValue.deserializeBinaryFromReader,
              '',
              new proto.google.protobuf.ListValue()
            );
          });
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.PomeriumSession.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.PomeriumSession.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.PomeriumSession} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.PomeriumSession.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.pomerium.dashboard.PomeriumSession.User.serializeBinaryToWriter
    );
  }
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.pomerium.dashboard.PomeriumSession.Group.serializeBinaryToWriter
    );
  }
  f = message.getIssuer();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
  f = message.getIssuedAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getExpiresAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAudienceList();
  if (f.length > 0) {
    writer.writeRepeatedString(7, f);
  }
  f = message.getClaimsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(
      8,
      writer,
      jspb.BinaryWriter.prototype.writeString,
      jspb.BinaryWriter.prototype.writeMessage,
      proto.google.protobuf.ListValue.serializeBinaryToWriter
    );
  }
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.PomeriumSession.Group.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.PomeriumSession.Group.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.PomeriumSession.Group} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.PomeriumSession.Group.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, ''),
        name: jspb.Message.getFieldWithDefault(msg, 2, ''),
        email: jspb.Message.getFieldWithDefault(msg, 3, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.PomeriumSession.Group}
 */
proto.pomerium.dashboard.PomeriumSession.Group.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.PomeriumSession.Group();
  return proto.pomerium.dashboard.PomeriumSession.Group.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.PomeriumSession.Group} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.PomeriumSession.Group}
 */
proto.pomerium.dashboard.PomeriumSession.Group.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setId(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setName(value);
          break;
        case 3:
          var value = /** @type {string} */ (reader.readString());
          msg.setEmail(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.PomeriumSession.Group.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.PomeriumSession.Group.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.PomeriumSession.Group} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.PomeriumSession.Group.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getId();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(2, f);
    }
    f = message.getEmail();
    if (f.length > 0) {
      writer.writeString(3, f);
    }
  };

/**
 * optional string id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.PomeriumSession.Group.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.PomeriumSession.Group} returns this
 */
proto.pomerium.dashboard.PomeriumSession.Group.prototype.setId = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string name = 2;
 * @return {string}
 */
proto.pomerium.dashboard.PomeriumSession.Group.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.PomeriumSession.Group} returns this
 */
proto.pomerium.dashboard.PomeriumSession.Group.prototype.setName = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string email = 3;
 * @return {string}
 */
proto.pomerium.dashboard.PomeriumSession.Group.prototype.getEmail =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 3, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.PomeriumSession.Group} returns this
 */
proto.pomerium.dashboard.PomeriumSession.Group.prototype.setEmail = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.PomeriumSession.User.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.PomeriumSession.User.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.PomeriumSession.User} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.PomeriumSession.User.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, ''),
        name: jspb.Message.getFieldWithDefault(msg, 2, ''),
        email: jspb.Message.getFieldWithDefault(msg, 3, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.PomeriumSession.User}
 */
proto.pomerium.dashboard.PomeriumSession.User.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.PomeriumSession.User();
  return proto.pomerium.dashboard.PomeriumSession.User.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.PomeriumSession.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.PomeriumSession.User}
 */
proto.pomerium.dashboard.PomeriumSession.User.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setId(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setName(value);
          break;
        case 3:
          var value = /** @type {string} */ (reader.readString());
          msg.setEmail(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.PomeriumSession.User.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.PomeriumSession.User.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.PomeriumSession.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.PomeriumSession.User.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getId();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(2, f);
    }
    f = message.getEmail();
    if (f.length > 0) {
      writer.writeString(3, f);
    }
  };

/**
 * optional string id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.PomeriumSession.User.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.PomeriumSession.User} returns this
 */
proto.pomerium.dashboard.PomeriumSession.User.prototype.setId = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string name = 2;
 * @return {string}
 */
proto.pomerium.dashboard.PomeriumSession.User.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.PomeriumSession.User} returns this
 */
proto.pomerium.dashboard.PomeriumSession.User.prototype.setName = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string email = 3;
 * @return {string}
 */
proto.pomerium.dashboard.PomeriumSession.User.prototype.getEmail = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.PomeriumSession.User} returns this
 */
proto.pomerium.dashboard.PomeriumSession.User.prototype.setEmail = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.PomeriumSession.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.PomeriumSession} returns this
 */
proto.pomerium.dashboard.PomeriumSession.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional User user = 2;
 * @return {?proto.pomerium.dashboard.PomeriumSession.User}
 */
proto.pomerium.dashboard.PomeriumSession.prototype.getUser = function () {
  return /** @type{?proto.pomerium.dashboard.PomeriumSession.User} */ (
    jspb.Message.getWrapperField(
      this,
      proto.pomerium.dashboard.PomeriumSession.User,
      2
    )
  );
};

/**
 * @param {?proto.pomerium.dashboard.PomeriumSession.User|undefined} value
 * @return {!proto.pomerium.dashboard.PomeriumSession} returns this
 */
proto.pomerium.dashboard.PomeriumSession.prototype.setUser = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.PomeriumSession} returns this
 */
proto.pomerium.dashboard.PomeriumSession.prototype.clearUser = function () {
  return this.setUser(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.PomeriumSession.prototype.hasUser = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * repeated Group groups = 3;
 * @return {!Array<!proto.pomerium.dashboard.PomeriumSession.Group>}
 */
proto.pomerium.dashboard.PomeriumSession.prototype.getGroupsList = function () {
  return /** @type{!Array<!proto.pomerium.dashboard.PomeriumSession.Group>} */ (
    jspb.Message.getRepeatedWrapperField(
      this,
      proto.pomerium.dashboard.PomeriumSession.Group,
      3
    )
  );
};

/**
 * @param {!Array<!proto.pomerium.dashboard.PomeriumSession.Group>} value
 * @return {!proto.pomerium.dashboard.PomeriumSession} returns this
 */
proto.pomerium.dashboard.PomeriumSession.prototype.setGroupsList = function (
  value
) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};

/**
 * @param {!proto.pomerium.dashboard.PomeriumSession.Group=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.PomeriumSession.Group}
 */
proto.pomerium.dashboard.PomeriumSession.prototype.addGroups = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    3,
    opt_value,
    proto.pomerium.dashboard.PomeriumSession.Group,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.PomeriumSession} returns this
 */
proto.pomerium.dashboard.PomeriumSession.prototype.clearGroupsList =
  function () {
    return this.setGroupsList([]);
  };

/**
 * optional string issuer = 4;
 * @return {string}
 */
proto.pomerium.dashboard.PomeriumSession.prototype.getIssuer = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.PomeriumSession} returns this
 */
proto.pomerium.dashboard.PomeriumSession.prototype.setIssuer = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * optional google.protobuf.Timestamp issued_at = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pomerium.dashboard.PomeriumSession.prototype.getIssuedAt = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_timestamp_pb.Timestamp,
      5
    )
  );
};

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.pomerium.dashboard.PomeriumSession} returns this
 */
proto.pomerium.dashboard.PomeriumSession.prototype.setIssuedAt = function (
  value
) {
  return jspb.Message.setWrapperField(this, 5, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.PomeriumSession} returns this
 */
proto.pomerium.dashboard.PomeriumSession.prototype.clearIssuedAt = function () {
  return this.setIssuedAt(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.PomeriumSession.prototype.hasIssuedAt = function () {
  return jspb.Message.getField(this, 5) != null;
};

/**
 * optional google.protobuf.Timestamp expires_at = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pomerium.dashboard.PomeriumSession.prototype.getExpiresAt = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_timestamp_pb.Timestamp,
      6
    )
  );
};

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.pomerium.dashboard.PomeriumSession} returns this
 */
proto.pomerium.dashboard.PomeriumSession.prototype.setExpiresAt = function (
  value
) {
  return jspb.Message.setWrapperField(this, 6, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.PomeriumSession} returns this
 */
proto.pomerium.dashboard.PomeriumSession.prototype.clearExpiresAt =
  function () {
    return this.setExpiresAt(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.PomeriumSession.prototype.hasExpiresAt = function () {
  return jspb.Message.getField(this, 6) != null;
};

/**
 * repeated string audience = 7;
 * @return {!Array<string>}
 */
proto.pomerium.dashboard.PomeriumSession.prototype.getAudienceList =
  function () {
    return /** @type {!Array<string>} */ (
      jspb.Message.getRepeatedField(this, 7)
    );
  };

/**
 * @param {!Array<string>} value
 * @return {!proto.pomerium.dashboard.PomeriumSession} returns this
 */
proto.pomerium.dashboard.PomeriumSession.prototype.setAudienceList = function (
  value
) {
  return jspb.Message.setField(this, 7, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.PomeriumSession} returns this
 */
proto.pomerium.dashboard.PomeriumSession.prototype.addAudience = function (
  value,
  opt_index
) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.PomeriumSession} returns this
 */
proto.pomerium.dashboard.PomeriumSession.prototype.clearAudienceList =
  function () {
    return this.setAudienceList([]);
  };

/**
 * map<string, google.protobuf.ListValue> claims = 8;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.google.protobuf.ListValue>}
 */
proto.pomerium.dashboard.PomeriumSession.prototype.getClaimsMap = function (
  opt_noLazyCreate
) {
  return /** @type {!jspb.Map<string,!proto.google.protobuf.ListValue>} */ (
    jspb.Message.getMapField(
      this,
      8,
      opt_noLazyCreate,
      proto.google.protobuf.ListValue
    )
  );
};

/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.pomerium.dashboard.PomeriumSession} returns this
 */
proto.pomerium.dashboard.PomeriumSession.prototype.clearClaimsMap =
  function () {
    this.getClaimsMap().clear();
    return this;
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.DeletePomeriumSessionRequest.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.DeletePomeriumSessionRequest.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.DeletePomeriumSessionRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.DeletePomeriumSessionRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.DeletePomeriumSessionRequest}
 */
proto.pomerium.dashboard.DeletePomeriumSessionRequest.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pomerium.dashboard.DeletePomeriumSessionRequest();
    return proto.pomerium.dashboard.DeletePomeriumSessionRequest.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.DeletePomeriumSessionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.DeletePomeriumSessionRequest}
 */
proto.pomerium.dashboard.DeletePomeriumSessionRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setId(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.DeletePomeriumSessionRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.DeletePomeriumSessionRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.DeletePomeriumSessionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.DeletePomeriumSessionRequest.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getId();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
  };

/**
 * optional string id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.DeletePomeriumSessionRequest.prototype.getId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.DeletePomeriumSessionRequest} returns this
 */
proto.pomerium.dashboard.DeletePomeriumSessionRequest.prototype.setId =
  function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.DeletePomeriumSessionResponse.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.DeletePomeriumSessionResponse.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.DeletePomeriumSessionResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.DeletePomeriumSessionResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {};

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.DeletePomeriumSessionResponse}
 */
proto.pomerium.dashboard.DeletePomeriumSessionResponse.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pomerium.dashboard.DeletePomeriumSessionResponse();
    return proto.pomerium.dashboard.DeletePomeriumSessionResponse.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.DeletePomeriumSessionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.DeletePomeriumSessionResponse}
 */
proto.pomerium.dashboard.DeletePomeriumSessionResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.DeletePomeriumSessionResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.DeletePomeriumSessionResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.DeletePomeriumSessionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.DeletePomeriumSessionResponse.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.GetPomeriumSessionRequest.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.GetPomeriumSessionRequest.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.GetPomeriumSessionRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.GetPomeriumSessionRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.GetPomeriumSessionRequest}
 */
proto.pomerium.dashboard.GetPomeriumSessionRequest.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pomerium.dashboard.GetPomeriumSessionRequest();
    return proto.pomerium.dashboard.GetPomeriumSessionRequest.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.GetPomeriumSessionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.GetPomeriumSessionRequest}
 */
proto.pomerium.dashboard.GetPomeriumSessionRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setId(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.GetPomeriumSessionRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.GetPomeriumSessionRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.GetPomeriumSessionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.GetPomeriumSessionRequest.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getId();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
  };

/**
 * optional string id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.GetPomeriumSessionRequest.prototype.getId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.GetPomeriumSessionRequest} returns this
 */
proto.pomerium.dashboard.GetPomeriumSessionRequest.prototype.setId = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pomerium.dashboard.GetPomeriumSessionResponse.repeatedFields_ = [2];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.GetPomeriumSessionResponse.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.GetPomeriumSessionResponse.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.GetPomeriumSessionResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.GetPomeriumSessionResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        session:
          (f = msg.getSession()) &&
          proto.pomerium.dashboard.PomeriumSession.toObject(includeInstance, f),
        associatedSessionsList: jspb.Message.toObjectList(
          msg.getAssociatedSessionsList(),
          proto.pomerium.dashboard.PomeriumSession.toObject,
          includeInstance
        )
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.GetPomeriumSessionResponse}
 */
proto.pomerium.dashboard.GetPomeriumSessionResponse.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pomerium.dashboard.GetPomeriumSessionResponse();
    return proto.pomerium.dashboard.GetPomeriumSessionResponse.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.GetPomeriumSessionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.GetPomeriumSessionResponse}
 */
proto.pomerium.dashboard.GetPomeriumSessionResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.PomeriumSession();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.PomeriumSession.deserializeBinaryFromReader
          );
          msg.setSession(value);
          break;
        case 2:
          var value = new proto.pomerium.dashboard.PomeriumSession();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.PomeriumSession.deserializeBinaryFromReader
          );
          msg.addAssociatedSessions(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.GetPomeriumSessionResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.GetPomeriumSessionResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.GetPomeriumSessionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.GetPomeriumSessionResponse.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getSession();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        proto.pomerium.dashboard.PomeriumSession.serializeBinaryToWriter
      );
    }
    f = message.getAssociatedSessionsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        2,
        f,
        proto.pomerium.dashboard.PomeriumSession.serializeBinaryToWriter
      );
    }
  };

/**
 * optional PomeriumSession session = 1;
 * @return {?proto.pomerium.dashboard.PomeriumSession}
 */
proto.pomerium.dashboard.GetPomeriumSessionResponse.prototype.getSession =
  function () {
    return /** @type{?proto.pomerium.dashboard.PomeriumSession} */ (
      jspb.Message.getWrapperField(
        this,
        proto.pomerium.dashboard.PomeriumSession,
        1
      )
    );
  };

/**
 * @param {?proto.pomerium.dashboard.PomeriumSession|undefined} value
 * @return {!proto.pomerium.dashboard.GetPomeriumSessionResponse} returns this
 */
proto.pomerium.dashboard.GetPomeriumSessionResponse.prototype.setSession =
  function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.GetPomeriumSessionResponse} returns this
 */
proto.pomerium.dashboard.GetPomeriumSessionResponse.prototype.clearSession =
  function () {
    return this.setSession(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.GetPomeriumSessionResponse.prototype.hasSession =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

/**
 * repeated PomeriumSession associated_sessions = 2;
 * @return {!Array<!proto.pomerium.dashboard.PomeriumSession>}
 */
proto.pomerium.dashboard.GetPomeriumSessionResponse.prototype.getAssociatedSessionsList =
  function () {
    return /** @type{!Array<!proto.pomerium.dashboard.PomeriumSession>} */ (
      jspb.Message.getRepeatedWrapperField(
        this,
        proto.pomerium.dashboard.PomeriumSession,
        2
      )
    );
  };

/**
 * @param {!Array<!proto.pomerium.dashboard.PomeriumSession>} value
 * @return {!proto.pomerium.dashboard.GetPomeriumSessionResponse} returns this
 */
proto.pomerium.dashboard.GetPomeriumSessionResponse.prototype.setAssociatedSessionsList =
  function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 2, value);
  };

/**
 * @param {!proto.pomerium.dashboard.PomeriumSession=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.PomeriumSession}
 */
proto.pomerium.dashboard.GetPomeriumSessionResponse.prototype.addAssociatedSessions =
  function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(
      this,
      2,
      opt_value,
      proto.pomerium.dashboard.PomeriumSession,
      opt_index
    );
  };

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.GetPomeriumSessionResponse} returns this
 */
proto.pomerium.dashboard.GetPomeriumSessionResponse.prototype.clearAssociatedSessionsList =
  function () {
    return this.setAssociatedSessionsList([]);
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.ListPomeriumSessionsRequest.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.ListPomeriumSessionsRequest.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.ListPomeriumSessionsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.ListPomeriumSessionsRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        query: jspb.Message.getFieldWithDefault(msg, 1, ''),
        offset: jspb.Message.getFieldWithDefault(msg, 2, 0),
        limit: jspb.Message.getFieldWithDefault(msg, 3, 0),
        orderBy: jspb.Message.getFieldWithDefault(msg, 4, ''),
        userId: jspb.Message.getFieldWithDefault(msg, 5, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.ListPomeriumSessionsRequest}
 */
proto.pomerium.dashboard.ListPomeriumSessionsRequest.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pomerium.dashboard.ListPomeriumSessionsRequest();
    return proto.pomerium.dashboard.ListPomeriumSessionsRequest.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.ListPomeriumSessionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.ListPomeriumSessionsRequest}
 */
proto.pomerium.dashboard.ListPomeriumSessionsRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setQuery(value);
          break;
        case 2:
          var value = /** @type {number} */ (reader.readInt64());
          msg.setOffset(value);
          break;
        case 3:
          var value = /** @type {number} */ (reader.readInt64());
          msg.setLimit(value);
          break;
        case 4:
          var value = /** @type {string} */ (reader.readString());
          msg.setOrderBy(value);
          break;
        case 5:
          var value = /** @type {string} */ (reader.readString());
          msg.setUserId(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.ListPomeriumSessionsRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.ListPomeriumSessionsRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.ListPomeriumSessionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.ListPomeriumSessionsRequest.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = /** @type {string} */ (jspb.Message.getField(message, 1));
    if (f != null) {
      writer.writeString(1, f);
    }
    f = /** @type {number} */ (jspb.Message.getField(message, 2));
    if (f != null) {
      writer.writeInt64(2, f);
    }
    f = /** @type {number} */ (jspb.Message.getField(message, 3));
    if (f != null) {
      writer.writeInt64(3, f);
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 4));
    if (f != null) {
      writer.writeString(4, f);
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 5));
    if (f != null) {
      writer.writeString(5, f);
    }
  };

/**
 * optional string query = 1;
 * @return {string}
 */
proto.pomerium.dashboard.ListPomeriumSessionsRequest.prototype.getQuery =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ListPomeriumSessionsRequest} returns this
 */
proto.pomerium.dashboard.ListPomeriumSessionsRequest.prototype.setQuery =
  function (value) {
    return jspb.Message.setField(this, 1, value);
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.ListPomeriumSessionsRequest} returns this
 */
proto.pomerium.dashboard.ListPomeriumSessionsRequest.prototype.clearQuery =
  function () {
    return jspb.Message.setField(this, 1, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.ListPomeriumSessionsRequest.prototype.hasQuery =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

/**
 * optional int64 offset = 2;
 * @return {number}
 */
proto.pomerium.dashboard.ListPomeriumSessionsRequest.prototype.getOffset =
  function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };

/**
 * @param {number} value
 * @return {!proto.pomerium.dashboard.ListPomeriumSessionsRequest} returns this
 */
proto.pomerium.dashboard.ListPomeriumSessionsRequest.prototype.setOffset =
  function (value) {
    return jspb.Message.setField(this, 2, value);
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.ListPomeriumSessionsRequest} returns this
 */
proto.pomerium.dashboard.ListPomeriumSessionsRequest.prototype.clearOffset =
  function () {
    return jspb.Message.setField(this, 2, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.ListPomeriumSessionsRequest.prototype.hasOffset =
  function () {
    return jspb.Message.getField(this, 2) != null;
  };

/**
 * optional int64 limit = 3;
 * @return {number}
 */
proto.pomerium.dashboard.ListPomeriumSessionsRequest.prototype.getLimit =
  function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };

/**
 * @param {number} value
 * @return {!proto.pomerium.dashboard.ListPomeriumSessionsRequest} returns this
 */
proto.pomerium.dashboard.ListPomeriumSessionsRequest.prototype.setLimit =
  function (value) {
    return jspb.Message.setField(this, 3, value);
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.ListPomeriumSessionsRequest} returns this
 */
proto.pomerium.dashboard.ListPomeriumSessionsRequest.prototype.clearLimit =
  function () {
    return jspb.Message.setField(this, 3, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.ListPomeriumSessionsRequest.prototype.hasLimit =
  function () {
    return jspb.Message.getField(this, 3) != null;
  };

/**
 * optional string order_by = 4;
 * @return {string}
 */
proto.pomerium.dashboard.ListPomeriumSessionsRequest.prototype.getOrderBy =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 4, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ListPomeriumSessionsRequest} returns this
 */
proto.pomerium.dashboard.ListPomeriumSessionsRequest.prototype.setOrderBy =
  function (value) {
    return jspb.Message.setField(this, 4, value);
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.ListPomeriumSessionsRequest} returns this
 */
proto.pomerium.dashboard.ListPomeriumSessionsRequest.prototype.clearOrderBy =
  function () {
    return jspb.Message.setField(this, 4, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.ListPomeriumSessionsRequest.prototype.hasOrderBy =
  function () {
    return jspb.Message.getField(this, 4) != null;
  };

/**
 * optional string user_id = 5;
 * @return {string}
 */
proto.pomerium.dashboard.ListPomeriumSessionsRequest.prototype.getUserId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 5, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ListPomeriumSessionsRequest} returns this
 */
proto.pomerium.dashboard.ListPomeriumSessionsRequest.prototype.setUserId =
  function (value) {
    return jspb.Message.setField(this, 5, value);
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.ListPomeriumSessionsRequest} returns this
 */
proto.pomerium.dashboard.ListPomeriumSessionsRequest.prototype.clearUserId =
  function () {
    return jspb.Message.setField(this, 5, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.ListPomeriumSessionsRequest.prototype.hasUserId =
  function () {
    return jspb.Message.getField(this, 5) != null;
  };

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pomerium.dashboard.ListPomeriumSessionsResponse.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.ListPomeriumSessionsResponse.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.ListPomeriumSessionsResponse.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.ListPomeriumSessionsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.ListPomeriumSessionsResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        sessionsList: jspb.Message.toObjectList(
          msg.getSessionsList(),
          proto.pomerium.dashboard.PomeriumSession.toObject,
          includeInstance
        ),
        totalCount: jspb.Message.getFieldWithDefault(msg, 2, 0)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.ListPomeriumSessionsResponse}
 */
proto.pomerium.dashboard.ListPomeriumSessionsResponse.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pomerium.dashboard.ListPomeriumSessionsResponse();
    return proto.pomerium.dashboard.ListPomeriumSessionsResponse.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.ListPomeriumSessionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.ListPomeriumSessionsResponse}
 */
proto.pomerium.dashboard.ListPomeriumSessionsResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.PomeriumSession();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.PomeriumSession.deserializeBinaryFromReader
          );
          msg.addSessions(value);
          break;
        case 2:
          var value = /** @type {number} */ (reader.readInt64());
          msg.setTotalCount(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.ListPomeriumSessionsResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.ListPomeriumSessionsResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.ListPomeriumSessionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.ListPomeriumSessionsResponse.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getSessionsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        proto.pomerium.dashboard.PomeriumSession.serializeBinaryToWriter
      );
    }
    f = message.getTotalCount();
    if (f !== 0) {
      writer.writeInt64(2, f);
    }
  };

/**
 * repeated PomeriumSession sessions = 1;
 * @return {!Array<!proto.pomerium.dashboard.PomeriumSession>}
 */
proto.pomerium.dashboard.ListPomeriumSessionsResponse.prototype.getSessionsList =
  function () {
    return /** @type{!Array<!proto.pomerium.dashboard.PomeriumSession>} */ (
      jspb.Message.getRepeatedWrapperField(
        this,
        proto.pomerium.dashboard.PomeriumSession,
        1
      )
    );
  };

/**
 * @param {!Array<!proto.pomerium.dashboard.PomeriumSession>} value
 * @return {!proto.pomerium.dashboard.ListPomeriumSessionsResponse} returns this
 */
proto.pomerium.dashboard.ListPomeriumSessionsResponse.prototype.setSessionsList =
  function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
  };

/**
 * @param {!proto.pomerium.dashboard.PomeriumSession=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.PomeriumSession}
 */
proto.pomerium.dashboard.ListPomeriumSessionsResponse.prototype.addSessions =
  function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(
      this,
      1,
      opt_value,
      proto.pomerium.dashboard.PomeriumSession,
      opt_index
    );
  };

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.ListPomeriumSessionsResponse} returns this
 */
proto.pomerium.dashboard.ListPomeriumSessionsResponse.prototype.clearSessionsList =
  function () {
    return this.setSessionsList([]);
  };

/**
 * optional int64 total_count = 2;
 * @return {number}
 */
proto.pomerium.dashboard.ListPomeriumSessionsResponse.prototype.getTotalCount =
  function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };

/**
 * @param {number} value
 * @return {!proto.pomerium.dashboard.ListPomeriumSessionsResponse} returns this
 */
proto.pomerium.dashboard.ListPomeriumSessionsResponse.prototype.setTotalCount =
  function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.ImpersonateRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.ImpersonateRequest.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.ImpersonateRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.ImpersonateRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        sessionId: jspb.Message.getFieldWithDefault(msg, 1, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.ImpersonateRequest}
 */
proto.pomerium.dashboard.ImpersonateRequest.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.ImpersonateRequest();
  return proto.pomerium.dashboard.ImpersonateRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.ImpersonateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.ImpersonateRequest}
 */
proto.pomerium.dashboard.ImpersonateRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setSessionId(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.ImpersonateRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.ImpersonateRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.ImpersonateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.ImpersonateRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
};

/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.ImpersonateRequest.prototype.getSessionId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ImpersonateRequest} returns this
 */
proto.pomerium.dashboard.ImpersonateRequest.prototype.setSessionId = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.ImpersonateResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.ImpersonateResponse.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.ImpersonateResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.ImpersonateResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {};

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.ImpersonateResponse}
 */
proto.pomerium.dashboard.ImpersonateResponse.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.ImpersonateResponse();
  return proto.pomerium.dashboard.ImpersonateResponse.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.ImpersonateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.ImpersonateResponse}
 */
proto.pomerium.dashboard.ImpersonateResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.ImpersonateResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.ImpersonateResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.ImpersonateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.ImpersonateResponse.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
  };

goog.object.extend(exports, proto.pomerium.dashboard);
