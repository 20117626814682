import { Alert, AlertTitle } from '@mui/material';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import React, { FC } from 'react';

import Page from 'src/components/Page';

export const FatalErrorView: FC<{ error: string }> = ({ error }) => {
  return (
    <Page title="Fatal Error">
      <Container maxWidth={'sm'}>
        <Paper elevation={3}>
          <Alert severity="error">
            <AlertTitle>Fatal Error</AlertTitle>
            {error}
          </Alert>
        </Paper>
      </Container>
    </Page>
  );
};
