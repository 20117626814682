import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

import {
  AppThunk,
  NotificationState,
  NotistackNotification
} from 'src/store/state';

const initialState: NotificationState = {
  notifications: []
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    pushNotification(
      state: NotificationState,
      action: PayloadAction<NotistackNotification>
    ): void {
      state.notifications.push(action.payload);
    },
    removeNotification(
      state: NotificationState,
      action: PayloadAction<string | number>
    ): void {
      state.notifications = state.notifications.filter(
        (notification) => notification.options.key !== action.payload
      );
    }
  }
});

export const { reducer } = slice;

export const pushError =
  (message: string): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(
      slice.actions.pushNotification({
        message: message,
        options: {
          key: uuid(),
          variant: 'error'
        }
      })
    );
  };

export const pushSuccess =
  (message: string): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(
      slice.actions.pushNotification({
        message: message,
        options: {
          key: uuid(),
          variant: 'success'
        }
      })
    );
  };

export const removeNotification =
  (key: string | number): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.removeNotification(key));
  };
