import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import React, { useEffect, useState } from 'react';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Account from './Account';
import PopDown from './PopDown';
import Settings from './Settings';

import Logo from 'src/components/Logo';
import { MenuIcon } from 'src/components/icons';
import { useSelector } from 'src/store';

interface TopBarProps {
  onMobileNavOpen?: () => void;
}

const calcShowPopDownFromPathName = (pathname: string): boolean => {
  switch (pathname) {
    case '/app/reports/runtime':
    case '/app/management/sessions':
    case '/app/reports/events':
    case '/app/management/settings':
    case '/app/management/devices':
      return false;
    default:
      return true;
  }
};

const TopBar: FC<TopBarProps> = ({ onMobileNavOpen, ...rest }) => {
  const { currentNamespace } = useSelector((state) => state.namespaces);
  const { pathname } = useSelector((state) => state.router.location);
  const [showPopDown, setShowPopDown] = useState(
    calcShowPopDownFromPathName(pathname)
  );

  useEffect(() => {
    setShowPopDown(calcShowPopDownFromPathName(pathname));
  }, [pathname]);

  return (
    <AppBar
      sx={(theme) => ({
        zIndex: theme.zIndex.drawer + 1
      })}
      {...rest}
      enableColorOnDark
    >
      <Toolbar sx={{ minHeight: '64px' }}>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen} size="large">
            <MenuIcon fontSize="small" />
          </IconButton>
          {showPopDown && <PopDown />}
        </Hidden>
        <Hidden lgDown>
          <RouterLink
            to={{
              pathname: '/',
              search: `?namespace=${currentNamespace.id}`
            }}
          >
            <Logo />
          </RouterLink>
          {showPopDown && (
            <Box sx={{ marginLeft: 14.5 }}>
              <PopDown />
            </Box>
          )}
        </Hidden>
        <Box ml={2} flexGrow={1} />
        <Settings />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
