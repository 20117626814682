import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { removeNotification } from '../slices/notifications';
import { useSelector } from '../store';

const useNotifier = (): void => {
  const dispatch = useDispatch();
  const { notifications } = useSelector((state) => state.notifications);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [alreadyDisplayed, setAlreadyDisplayed] = useState([]);

  const addToDisplayed = (id: string) => {
    setAlreadyDisplayed((prevState) => [...prevState, id]);
  };

  const removeFromDisplayed = (id) => {
    setAlreadyDisplayed((prevState) => prevState.filter((key) => id !== key));
  };

  useEffect(() => {
    notifications.forEach(({ message, options = {} }) => {
      if (alreadyDisplayed.includes(options.key)) return;
      enqueueSnackbar(message, {
        ...options,
        autoHideDuration: 5000,
        onClose: (event, reason, key) => {
          dispatch(removeNotification(key));
          removeFromDisplayed(key);
        },
        onExited: (event, key) => {
          dispatch(removeNotification(key));
          removeFromDisplayed(key);
        }
      });

      addToDisplayed(options.key);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);
};

export default useNotifier;
