import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import dedent from 'dedent';
import React, { FC } from 'react';

import { useSelector } from 'src/store';

const SmallFontTypography = styled(Typography)({
  fontSize: '.75rem',
  marginBottom: '2px'
});

const NavBarFooter: FC = () => {
  const { pomeriumVersion, pomeriumConsoleVersion } = useSelector(
    (state) => state.session
  );
  const supportLink =
    'mailto:support@pomerium.com?' +
    new URLSearchParams({
      subject: 'Pomerium Enterprise Support Request',
      body: dedent`
        What happened?

        What did you expect to happen?

        What does your configuration look like?

        Are there any helpful logs or screenshots?


        Enterprise: ${pomeriumConsoleVersion}
        Core: ${pomeriumVersion}
      `
    }).toString();

  return (
    <>
      <Box m={2}>
        <SmallFontTypography variant="h6">Core</SmallFontTypography>
        <SmallFontTypography variant="body2">
          {pomeriumVersion}
        </SmallFontTypography>
        <SmallFontTypography variant="h6">Enterprise</SmallFontTypography>
        <SmallFontTypography variant="body2">
          {pomeriumConsoleVersion}
        </SmallFontTypography>
      </Box>
      <Divider />
      <Box
        sx={{
          margin: 1,
          display: `flex`,
          flexFlow: `row nowrap`,
          justifyContent: `space-around`
        }}
      >
        <Typography variant="caption">
          <Link
            href="https://www.pomerium.com/docs/"
            rel="noreferrer"
            variant="caption"
            sx={{
              fontWeight: `bold`,
              color: 'secondary'
            }}
          >
            Docs
          </Link>
        </Typography>
        <Typography variant="caption">
          <Link
            href={supportLink}
            rel="noreferrer"
            variant="caption"
            sx={{
              fontWeight: `bold`,
              color: 'secondary'
            }}
          >
            Support
          </Link>
        </Typography>
        <Typography variant="caption">
          {' '}
          <Link
            href="https://app.fossa.com/attribution/0ff005b5-71b0-4ad9-a367-989564084e26"
            variant="caption"
            sx={{
              fontWeight: `bold`,
              color: 'secondary'
            }}
          >
            Licenses
          </Link>
        </Typography>
      </Box>
    </>
  );
};

export default NavBarFooter;
