import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { pushError } from './notifications';

import { PomeriumServiceAccountServiceClient } from 'src/pb/UsersServiceClientPb';
import {
  PomeriumServiceAccount,
  AddPomeriumServiceAccountRequest,
  ListPomeriumServiceAccountsRequest,
  DeletePomeriumServiceAccountRequest,
  ListPomeriumServiceAccountsResponse
} from 'src/pb/users_pb';
import { AppThunk, PomeriumServiceAccountState } from 'src/store/state';
import { getErrorMessage } from 'src/utils/errors';
import { getClient, getMetadata } from 'src/utils/grpc';

const initialState: PomeriumServiceAccountState = {
  jsonList: [],
  saving: false,
  jwt: ''
};

export const slice = createSlice({
  name: 'service accounts',
  initialState,
  reducers: {
    getPomeriumServiceAccount(
      state: PomeriumServiceAccountState,
      action: PayloadAction<PomeriumServiceAccount>
    ): void {
      const jsonIndex = state.jsonList.findIndex(
        (serviceAccount) => serviceAccount.id == action.payload.getId()
      );
      state.jsonList[jsonIndex] = action.payload.toObject();
    },
    listPomeriumServiceAccounts(
      state: PomeriumServiceAccountState,
      action: PayloadAction<ListPomeriumServiceAccountsResponse>
    ): void {
      state.jsonList = action.payload
        .getServiceAccountsList()
        .map((account) => account.toObject());
    },
    deleteServiceAccount(
      state: PomeriumServiceAccountState,
      action: PayloadAction<string>
    ): void {
      const jsonIndex = state.jsonList.findIndex(
        (account) => account.id == action.payload
      );
      state.jsonList.splice(jsonIndex, 1);
    },
    setJWT(
      state: PomeriumServiceAccountState,
      action: PayloadAction<string>
    ): void {
      state.jwt = action.payload;
    },
    setSaving(
      state: PomeriumServiceAccountState,
      action: PayloadAction<boolean>
    ): void {
      state.saving = action.payload;
    }
  }
});

export const { reducer } = slice;

const client = getClient(PomeriumServiceAccountServiceClient);

export const addPomeriumServiceAccount =
  (pomeriumServiceAccount: PomeriumServiceAccount): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const req = new AddPomeriumServiceAccountRequest();
    req.setServiceAccount(pomeriumServiceAccount);
    dispatch(slice.actions.setSaving(true));
    try {
      const res = await client.addPomeriumServiceAccount(
        req,
        getMetadata(getState().session)
      );
      dispatch(slice.actions.setJWT(res.getJwt()));
      dispatch(listPomeriumServiceAccounts());
    } catch (e) {
      dispatch(pushError(getErrorMessage(e)));
    } finally {
      dispatch(slice.actions.setSaving(false));
    }
  };

export const listPomeriumServiceAccounts =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const req = new ListPomeriumServiceAccountsRequest();
    req.setNamespace(getState().namespaces.currentNamespace.id);
    try {
      const res = await client.listPomeriumServiceAccounts(
        req,
        getMetadata(getState().session)
      );
      dispatch(slice.actions.listPomeriumServiceAccounts(res));
    } catch (e) {
      dispatch(pushError(getErrorMessage(e)));
    }
  };

export const deletePomeriumServiceAccount =
  (accountIds: Iterable<string>): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    try {
      for (const accountId of accountIds) {
        const req = new DeletePomeriumServiceAccountRequest();
        req.setId(accountId);
        await client.deletePomeriumServiceAccount(
          req,
          getMetadata(getState().session)
        );
        dispatch(slice.actions.deleteServiceAccount(accountId));
      }
    } catch (e) {
      dispatch(pushError(getErrorMessage(e)));
    }
  };
