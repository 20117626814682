// source: envoy/type/v3/range.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var udpa_annotations_status_pb = require('../../../udpa/annotations/status_pb.js');
goog.object.extend(proto, udpa_annotations_status_pb);
var udpa_annotations_versioning_pb = require('../../../udpa/annotations/versioning_pb.js');
goog.object.extend(proto, udpa_annotations_versioning_pb);
goog.exportSymbol('proto.envoy.type.v3.DoubleRange', null, global);
goog.exportSymbol('proto.envoy.type.v3.Int32Range', null, global);
goog.exportSymbol('proto.envoy.type.v3.Int64Range', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.type.v3.Int64Range = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.envoy.type.v3.Int64Range, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.type.v3.Int64Range.displayName = 'proto.envoy.type.v3.Int64Range';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.type.v3.Int32Range = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.envoy.type.v3.Int32Range, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.type.v3.Int32Range.displayName = 'proto.envoy.type.v3.Int32Range';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.type.v3.DoubleRange = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.envoy.type.v3.DoubleRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.type.v3.DoubleRange.displayName =
    'proto.envoy.type.v3.DoubleRange';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.type.v3.Int64Range.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.type.v3.Int64Range.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.type.v3.Int64Range} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.type.v3.Int64Range.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        start: jspb.Message.getFieldWithDefault(msg, 1, 0),
        end: jspb.Message.getFieldWithDefault(msg, 2, 0)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.type.v3.Int64Range}
 */
proto.envoy.type.v3.Int64Range.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.type.v3.Int64Range();
  return proto.envoy.type.v3.Int64Range.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.type.v3.Int64Range} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.type.v3.Int64Range}
 */
proto.envoy.type.v3.Int64Range.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setStart(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setEnd(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.type.v3.Int64Range.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.envoy.type.v3.Int64Range.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.type.v3.Int64Range} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.type.v3.Int64Range.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getStart();
  if (f !== 0) {
    writer.writeInt64(1, f);
  }
  f = message.getEnd();
  if (f !== 0) {
    writer.writeInt64(2, f);
  }
};

/**
 * optional int64 start = 1;
 * @return {number}
 */
proto.envoy.type.v3.Int64Range.prototype.getStart = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.envoy.type.v3.Int64Range} returns this
 */
proto.envoy.type.v3.Int64Range.prototype.setStart = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional int64 end = 2;
 * @return {number}
 */
proto.envoy.type.v3.Int64Range.prototype.getEnd = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.envoy.type.v3.Int64Range} returns this
 */
proto.envoy.type.v3.Int64Range.prototype.setEnd = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.type.v3.Int32Range.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.type.v3.Int32Range.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.type.v3.Int32Range} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.type.v3.Int32Range.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        start: jspb.Message.getFieldWithDefault(msg, 1, 0),
        end: jspb.Message.getFieldWithDefault(msg, 2, 0)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.type.v3.Int32Range}
 */
proto.envoy.type.v3.Int32Range.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.type.v3.Int32Range();
  return proto.envoy.type.v3.Int32Range.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.type.v3.Int32Range} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.type.v3.Int32Range}
 */
proto.envoy.type.v3.Int32Range.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setStart(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setEnd(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.type.v3.Int32Range.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.envoy.type.v3.Int32Range.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.type.v3.Int32Range} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.type.v3.Int32Range.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getStart();
  if (f !== 0) {
    writer.writeInt32(1, f);
  }
  f = message.getEnd();
  if (f !== 0) {
    writer.writeInt32(2, f);
  }
};

/**
 * optional int32 start = 1;
 * @return {number}
 */
proto.envoy.type.v3.Int32Range.prototype.getStart = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.envoy.type.v3.Int32Range} returns this
 */
proto.envoy.type.v3.Int32Range.prototype.setStart = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional int32 end = 2;
 * @return {number}
 */
proto.envoy.type.v3.Int32Range.prototype.getEnd = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.envoy.type.v3.Int32Range} returns this
 */
proto.envoy.type.v3.Int32Range.prototype.setEnd = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.type.v3.DoubleRange.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.type.v3.DoubleRange.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.type.v3.DoubleRange} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.type.v3.DoubleRange.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        start: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
        end: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.type.v3.DoubleRange}
 */
proto.envoy.type.v3.DoubleRange.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.type.v3.DoubleRange();
  return proto.envoy.type.v3.DoubleRange.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.type.v3.DoubleRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.type.v3.DoubleRange}
 */
proto.envoy.type.v3.DoubleRange.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setStart(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setEnd(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.type.v3.DoubleRange.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.envoy.type.v3.DoubleRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.type.v3.DoubleRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.type.v3.DoubleRange.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getStart();
  if (f !== 0.0) {
    writer.writeDouble(1, f);
  }
  f = message.getEnd();
  if (f !== 0.0) {
    writer.writeDouble(2, f);
  }
};

/**
 * optional double start = 1;
 * @return {number}
 */
proto.envoy.type.v3.DoubleRange.prototype.getStart = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.envoy.type.v3.DoubleRange} returns this
 */
proto.envoy.type.v3.DoubleRange.prototype.setStart = function (value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};

/**
 * optional double end = 2;
 * @return {number}
 */
proto.envoy.type.v3.DoubleRange.prototype.getEnd = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.envoy.type.v3.DoubleRange} returns this
 */
proto.envoy.type.v3.DoubleRange.prototype.setEnd = function (value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};

goog.object.extend(exports, proto.envoy.type.v3);
