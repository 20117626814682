/**
 * @fileoverview gRPC-Web generated client stub for pomerium.dashboard
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!

/* eslint-disable */
// @ts-nocheck

import * as grpcWeb from 'grpc-web';

import * as namespaces_pb from './namespaces_pb';

export class NamespaceServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string };
  options_: null | { [index: string]: any };

  constructor(
    hostname: string,
    credentials?: null | { [index: string]: string },
    options?: null | { [index: string]: any }
  ) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoDeleteNamespace = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.NamespaceService/DeleteNamespace',
    grpcWeb.MethodType.UNARY,
    namespaces_pb.DeleteNamespaceRequest,
    namespaces_pb.DeleteNamespaceResponse,
    (request: namespaces_pb.DeleteNamespaceRequest) => {
      return request.serializeBinary();
    },
    namespaces_pb.DeleteNamespaceResponse.deserializeBinary
  );

  deleteNamespace(
    request: namespaces_pb.DeleteNamespaceRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<namespaces_pb.DeleteNamespaceResponse>;

  deleteNamespace(
    request: namespaces_pb.DeleteNamespaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: namespaces_pb.DeleteNamespaceResponse
    ) => void
  ): grpcWeb.ClientReadableStream<namespaces_pb.DeleteNamespaceResponse>;

  deleteNamespace(
    request: namespaces_pb.DeleteNamespaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: namespaces_pb.DeleteNamespaceResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + '/pomerium.dashboard.NamespaceService/DeleteNamespace',
        request,
        metadata || {},
        this.methodInfoDeleteNamespace,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + '/pomerium.dashboard.NamespaceService/DeleteNamespace',
      request,
      metadata || {},
      this.methodInfoDeleteNamespace
    );
  }

  methodInfoGetNamespace = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.NamespaceService/GetNamespace',
    grpcWeb.MethodType.UNARY,
    namespaces_pb.GetNamespaceRequest,
    namespaces_pb.GetNamespaceResponse,
    (request: namespaces_pb.GetNamespaceRequest) => {
      return request.serializeBinary();
    },
    namespaces_pb.GetNamespaceResponse.deserializeBinary
  );

  getNamespace(
    request: namespaces_pb.GetNamespaceRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<namespaces_pb.GetNamespaceResponse>;

  getNamespace(
    request: namespaces_pb.GetNamespaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: namespaces_pb.GetNamespaceResponse
    ) => void
  ): grpcWeb.ClientReadableStream<namespaces_pb.GetNamespaceResponse>;

  getNamespace(
    request: namespaces_pb.GetNamespaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: namespaces_pb.GetNamespaceResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + '/pomerium.dashboard.NamespaceService/GetNamespace',
        request,
        metadata || {},
        this.methodInfoGetNamespace,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + '/pomerium.dashboard.NamespaceService/GetNamespace',
      request,
      metadata || {},
      this.methodInfoGetNamespace
    );
  }

  methodInfoListNamespaces = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.NamespaceService/ListNamespaces',
    grpcWeb.MethodType.UNARY,
    namespaces_pb.ListNamespacesRequest,
    namespaces_pb.ListNamespacesResponse,
    (request: namespaces_pb.ListNamespacesRequest) => {
      return request.serializeBinary();
    },
    namespaces_pb.ListNamespacesResponse.deserializeBinary
  );

  listNamespaces(
    request: namespaces_pb.ListNamespacesRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<namespaces_pb.ListNamespacesResponse>;

  listNamespaces(
    request: namespaces_pb.ListNamespacesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: namespaces_pb.ListNamespacesResponse
    ) => void
  ): grpcWeb.ClientReadableStream<namespaces_pb.ListNamespacesResponse>;

  listNamespaces(
    request: namespaces_pb.ListNamespacesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: namespaces_pb.ListNamespacesResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + '/pomerium.dashboard.NamespaceService/ListNamespaces',
        request,
        metadata || {},
        this.methodInfoListNamespaces,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + '/pomerium.dashboard.NamespaceService/ListNamespaces',
      request,
      metadata || {},
      this.methodInfoListNamespaces
    );
  }

  methodInfoSetNamespace = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.NamespaceService/SetNamespace',
    grpcWeb.MethodType.UNARY,
    namespaces_pb.SetNamespaceRequest,
    namespaces_pb.SetNamespaceResponse,
    (request: namespaces_pb.SetNamespaceRequest) => {
      return request.serializeBinary();
    },
    namespaces_pb.SetNamespaceResponse.deserializeBinary
  );

  setNamespace(
    request: namespaces_pb.SetNamespaceRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<namespaces_pb.SetNamespaceResponse>;

  setNamespace(
    request: namespaces_pb.SetNamespaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: namespaces_pb.SetNamespaceResponse
    ) => void
  ): grpcWeb.ClientReadableStream<namespaces_pb.SetNamespaceResponse>;

  setNamespace(
    request: namespaces_pb.SetNamespaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: namespaces_pb.SetNamespaceResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + '/pomerium.dashboard.NamespaceService/SetNamespace',
        request,
        metadata || {},
        this.methodInfoSetNamespace,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + '/pomerium.dashboard.NamespaceService/SetNamespace',
      request,
      metadata || {},
      this.methodInfoSetNamespace
    );
  }
}

export class NamespacePermissionServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string };
  options_: null | { [index: string]: any };

  constructor(
    hostname: string,
    credentials?: null | { [index: string]: string },
    options?: null | { [index: string]: any }
  ) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoDeleteNamespacePermission = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.NamespacePermissionService/DeleteNamespacePermission',
    grpcWeb.MethodType.UNARY,
    namespaces_pb.DeleteNamespacePermissionRequest,
    namespaces_pb.DeleteNamespacePermissionResponse,
    (request: namespaces_pb.DeleteNamespacePermissionRequest) => {
      return request.serializeBinary();
    },
    namespaces_pb.DeleteNamespacePermissionResponse.deserializeBinary
  );

  deleteNamespacePermission(
    request: namespaces_pb.DeleteNamespacePermissionRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<namespaces_pb.DeleteNamespacePermissionResponse>;

  deleteNamespacePermission(
    request: namespaces_pb.DeleteNamespacePermissionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: namespaces_pb.DeleteNamespacePermissionResponse
    ) => void
  ): grpcWeb.ClientReadableStream<namespaces_pb.DeleteNamespacePermissionResponse>;

  deleteNamespacePermission(
    request: namespaces_pb.DeleteNamespacePermissionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: namespaces_pb.DeleteNamespacePermissionResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/pomerium.dashboard.NamespacePermissionService/DeleteNamespacePermission',
        request,
        metadata || {},
        this.methodInfoDeleteNamespacePermission,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ +
        '/pomerium.dashboard.NamespacePermissionService/DeleteNamespacePermission',
      request,
      metadata || {},
      this.methodInfoDeleteNamespacePermission
    );
  }

  methodInfoGetNamespacePermission = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.NamespacePermissionService/GetNamespacePermission',
    grpcWeb.MethodType.UNARY,
    namespaces_pb.GetNamespacePermissionRequest,
    namespaces_pb.GetNamespacePermissionResponse,
    (request: namespaces_pb.GetNamespacePermissionRequest) => {
      return request.serializeBinary();
    },
    namespaces_pb.GetNamespacePermissionResponse.deserializeBinary
  );

  getNamespacePermission(
    request: namespaces_pb.GetNamespacePermissionRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<namespaces_pb.GetNamespacePermissionResponse>;

  getNamespacePermission(
    request: namespaces_pb.GetNamespacePermissionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: namespaces_pb.GetNamespacePermissionResponse
    ) => void
  ): grpcWeb.ClientReadableStream<namespaces_pb.GetNamespacePermissionResponse>;

  getNamespacePermission(
    request: namespaces_pb.GetNamespacePermissionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: namespaces_pb.GetNamespacePermissionResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/pomerium.dashboard.NamespacePermissionService/GetNamespacePermission',
        request,
        metadata || {},
        this.methodInfoGetNamespacePermission,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ +
        '/pomerium.dashboard.NamespacePermissionService/GetNamespacePermission',
      request,
      metadata || {},
      this.methodInfoGetNamespacePermission
    );
  }

  methodInfoListNamespacePermissions = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.NamespacePermissionService/ListNamespacePermissions',
    grpcWeb.MethodType.UNARY,
    namespaces_pb.ListNamespacePermissionsRequest,
    namespaces_pb.ListNamespacePermissionsResponse,
    (request: namespaces_pb.ListNamespacePermissionsRequest) => {
      return request.serializeBinary();
    },
    namespaces_pb.ListNamespacePermissionsResponse.deserializeBinary
  );

  listNamespacePermissions(
    request: namespaces_pb.ListNamespacePermissionsRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<namespaces_pb.ListNamespacePermissionsResponse>;

  listNamespacePermissions(
    request: namespaces_pb.ListNamespacePermissionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: namespaces_pb.ListNamespacePermissionsResponse
    ) => void
  ): grpcWeb.ClientReadableStream<namespaces_pb.ListNamespacePermissionsResponse>;

  listNamespacePermissions(
    request: namespaces_pb.ListNamespacePermissionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: namespaces_pb.ListNamespacePermissionsResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/pomerium.dashboard.NamespacePermissionService/ListNamespacePermissions',
        request,
        metadata || {},
        this.methodInfoListNamespacePermissions,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ +
        '/pomerium.dashboard.NamespacePermissionService/ListNamespacePermissions',
      request,
      metadata || {},
      this.methodInfoListNamespacePermissions
    );
  }

  methodInfoListNamespacePermissionGroups = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.NamespacePermissionService/ListNamespacePermissionGroups',
    grpcWeb.MethodType.UNARY,
    namespaces_pb.ListNamespacePermissionGroupsRequest,
    namespaces_pb.ListNamespacePermissionGroupsResponse,
    (request: namespaces_pb.ListNamespacePermissionGroupsRequest) => {
      return request.serializeBinary();
    },
    namespaces_pb.ListNamespacePermissionGroupsResponse.deserializeBinary
  );

  listNamespacePermissionGroups(
    request: namespaces_pb.ListNamespacePermissionGroupsRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<namespaces_pb.ListNamespacePermissionGroupsResponse>;

  listNamespacePermissionGroups(
    request: namespaces_pb.ListNamespacePermissionGroupsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: namespaces_pb.ListNamespacePermissionGroupsResponse
    ) => void
  ): grpcWeb.ClientReadableStream<namespaces_pb.ListNamespacePermissionGroupsResponse>;

  listNamespacePermissionGroups(
    request: namespaces_pb.ListNamespacePermissionGroupsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: namespaces_pb.ListNamespacePermissionGroupsResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/pomerium.dashboard.NamespacePermissionService/ListNamespacePermissionGroups',
        request,
        metadata || {},
        this.methodInfoListNamespacePermissionGroups,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ +
        '/pomerium.dashboard.NamespacePermissionService/ListNamespacePermissionGroups',
      request,
      metadata || {},
      this.methodInfoListNamespacePermissionGroups
    );
  }

  methodInfoListNamespacePermissionUsers = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.NamespacePermissionService/ListNamespacePermissionUsers',
    grpcWeb.MethodType.UNARY,
    namespaces_pb.ListNamespacePermissionUsersRequest,
    namespaces_pb.ListNamespacePermissionUsersResponse,
    (request: namespaces_pb.ListNamespacePermissionUsersRequest) => {
      return request.serializeBinary();
    },
    namespaces_pb.ListNamespacePermissionUsersResponse.deserializeBinary
  );

  listNamespacePermissionUsers(
    request: namespaces_pb.ListNamespacePermissionUsersRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<namespaces_pb.ListNamespacePermissionUsersResponse>;

  listNamespacePermissionUsers(
    request: namespaces_pb.ListNamespacePermissionUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: namespaces_pb.ListNamespacePermissionUsersResponse
    ) => void
  ): grpcWeb.ClientReadableStream<namespaces_pb.ListNamespacePermissionUsersResponse>;

  listNamespacePermissionUsers(
    request: namespaces_pb.ListNamespacePermissionUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: namespaces_pb.ListNamespacePermissionUsersResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/pomerium.dashboard.NamespacePermissionService/ListNamespacePermissionUsers',
        request,
        metadata || {},
        this.methodInfoListNamespacePermissionUsers,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ +
        '/pomerium.dashboard.NamespacePermissionService/ListNamespacePermissionUsers',
      request,
      metadata || {},
      this.methodInfoListNamespacePermissionUsers
    );
  }

  methodInfoSetNamespacePermission = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.NamespacePermissionService/SetNamespacePermission',
    grpcWeb.MethodType.UNARY,
    namespaces_pb.SetNamespacePermissionRequest,
    namespaces_pb.SetNamespacePermissionResponse,
    (request: namespaces_pb.SetNamespacePermissionRequest) => {
      return request.serializeBinary();
    },
    namespaces_pb.SetNamespacePermissionResponse.deserializeBinary
  );

  setNamespacePermission(
    request: namespaces_pb.SetNamespacePermissionRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<namespaces_pb.SetNamespacePermissionResponse>;

  setNamespacePermission(
    request: namespaces_pb.SetNamespacePermissionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: namespaces_pb.SetNamespacePermissionResponse
    ) => void
  ): grpcWeb.ClientReadableStream<namespaces_pb.SetNamespacePermissionResponse>;

  setNamespacePermission(
    request: namespaces_pb.SetNamespacePermissionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: namespaces_pb.SetNamespacePermissionResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/pomerium.dashboard.NamespacePermissionService/SetNamespacePermission',
        request,
        metadata || {},
        this.methodInfoSetNamespacePermission,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ +
        '/pomerium.dashboard.NamespacePermissionService/SetNamespacePermission',
      request,
      metadata || {},
      this.methodInfoSetNamespacePermission
    );
  }
}
