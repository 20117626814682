/**
 * @fileoverview gRPC-Web generated client stub for pomerium.dashboard
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!

/* eslint-disable */
// @ts-nocheck

import * as grpcWeb from 'grpc-web';

import * as settings_pb from './settings_pb';

export class SettingsServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string };
  options_: null | { [index: string]: any };

  constructor(
    hostname: string,
    credentials?: null | { [index: string]: string },
    options?: null | { [index: string]: any }
  ) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetSettings = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.SettingsService/GetSettings',
    grpcWeb.MethodType.UNARY,
    settings_pb.GetSettingsRequest,
    settings_pb.GetSettingsResponse,
    (request: settings_pb.GetSettingsRequest) => {
      return request.serializeBinary();
    },
    settings_pb.GetSettingsResponse.deserializeBinary
  );

  getSettings(
    request: settings_pb.GetSettingsRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<settings_pb.GetSettingsResponse>;

  getSettings(
    request: settings_pb.GetSettingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: settings_pb.GetSettingsResponse
    ) => void
  ): grpcWeb.ClientReadableStream<settings_pb.GetSettingsResponse>;

  getSettings(
    request: settings_pb.GetSettingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: settings_pb.GetSettingsResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + '/pomerium.dashboard.SettingsService/GetSettings',
        request,
        metadata || {},
        this.methodInfoGetSettings,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + '/pomerium.dashboard.SettingsService/GetSettings',
      request,
      metadata || {},
      this.methodInfoGetSettings
    );
  }

  methodInfoSetSettings = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.SettingsService/SetSettings',
    grpcWeb.MethodType.UNARY,
    settings_pb.SetSettingsRequest,
    settings_pb.SetSettingsResponse,
    (request: settings_pb.SetSettingsRequest) => {
      return request.serializeBinary();
    },
    settings_pb.SetSettingsResponse.deserializeBinary
  );

  setSettings(
    request: settings_pb.SetSettingsRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<settings_pb.SetSettingsResponse>;

  setSettings(
    request: settings_pb.SetSettingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: settings_pb.SetSettingsResponse
    ) => void
  ): grpcWeb.ClientReadableStream<settings_pb.SetSettingsResponse>;

  setSettings(
    request: settings_pb.SetSettingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: settings_pb.SetSettingsResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + '/pomerium.dashboard.SettingsService/SetSettings',
        request,
        metadata || {},
        this.methodInfoSetSettings,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + '/pomerium.dashboard.SettingsService/SetSettings',
      request,
      metadata || {},
      this.methodInfoSetSettings
    );
  }
}
