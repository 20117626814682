import {
  ChartPieIcon,
  ChartSquareBarIcon,
  MailIcon,
  StatusOnlineIcon,
  UserIcon,
  UsersIcon
} from '@heroicons/react/solid';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { Launch, ShuffleOutlined } from '@mui/icons-material';
import { HourglassEmpty } from '@mui/icons-material';
import DnsIcon from '@mui/icons-material/Dns';
import FolderIcon from '@mui/icons-material/Folder';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import GavelIcon from '@mui/icons-material/Gavel';
import HistoryIcon from '@mui/icons-material/History';
import HttpIcon from '@mui/icons-material/Http';
import HttpsIcon from '@mui/icons-material/Https';
import OpenInNew from '@mui/icons-material/OpenInNew';
import SettingsIcon from '@mui/icons-material/Settings';
import React from 'react';
import {
  Archive,
  AlertTriangle,
  Copy,
  Eye,
  Edit,
  Filter,
  Menu,
  MinusCircle,
  PlusCircle,
  XCircle,
  Search,
  Trash,
  Upload,
  User,
  RotateCcw,
  Edit2,
  Clock,
  Maximize2,
  Aperture,
  AlignJustify,
  HelpCircle,
  Settings,
  Users,
  Home,
  Activity
} from 'react-feather';

export const ArchiveIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <Archive />
  </SvgIcon>
);
export const AlertTriangleIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <AlertTriangle />
  </SvgIcon>
);
export const BackIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <RotateCcw />
  </SvgIcon>
);
export const CertificateIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <UserIcon />
  </SvgIcon>
);
export const CircleMinus = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <MinusCircle />
  </SvgIcon>
);
export const CirclePlus = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <PlusCircle />
  </SvgIcon>
);
export const CircleX = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <XCircle />
  </SvgIcon>
);
export const ClockIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <Clock />
  </SvgIcon>
);
export const CookieIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <Aperture />
  </SvgIcon>
);
export const DeleteIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <Trash />
  </SvgIcon>
);
export const DeploymentHistoryIcon = (props: SvgIconProps): JSX.Element => (
  <HistoryIcon {...props} />
);
export const DNSIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <DnsIcon />
  </SvgIcon>
);
export const EditIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <Edit />
  </SvgIcon>
);
export const ViewIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <Eye />
  </SvgIcon>
);
export const EventsIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <StatusOnlineIcon />
  </SvgIcon>
);
export const FilterListIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <Filter />
  </SvgIcon>
);
export const FilledFolderIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <FolderIcon />
  </SvgIcon>
);
export const FilledSettingsIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <SettingsIcon />
  </SvgIcon>
);
export const HourglassEmptyIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <HourglassEmpty />
  </SvgIcon>
);
export const HeadersIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <AlignJustify />
  </SvgIcon>
);
export const HTTPIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <HttpIcon />
  </SvgIcon>
);
export const HTTPSIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <HttpsIcon />
  </SvgIcon>
);
export const ImpersonateIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <Users />
  </SvgIcon>
);
export const InfoPageIcon = (props: SvgIconProps): JSX.Element => (
  <FormatListBulletedOutlinedIcon {...props} />
);

export const LaunchIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <Launch />
  </SvgIcon>
);

export const HomeIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <Home />
  </SvgIcon>
);

export const ActivityIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <Activity />
  </SvgIcon>
);

export const LoadBalancerIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <ShuffleOutlined />
  </SvgIcon>
);
export const MaxIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <Maximize2 />
  </SvgIcon>
);
export const MenuIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <Menu />
  </SvgIcon>
);
export const MoveIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <Copy />
  </SvgIcon>
);
export const NamespaceIcon = (props: SvgIconProps): JSX.Element => (
  <FolderOutlinedIcon {...props} />
);
export const PencilIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <Edit2 />
  </SvgIcon>
);
export const PersonIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <User />
  </SvgIcon>
);
export const PolicyIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <GavelIcon />
  </SvgIcon>
);
export const QuestionMarkCircle = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <HelpCircle />
  </SvgIcon>
);
export const RouteIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <ChartPieIcon />
  </SvgIcon>
);
export const RuntimeIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <ChartPieIcon />
  </SvgIcon>
);
export const SearchIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <Search />
  </SvgIcon>
);
export const ServiceAccountIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <MailIcon />
  </SvgIcon>
);
export const SessionIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <UsersIcon />
  </SvgIcon>
);
export const OpenInNewIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <OpenInNew />
  </SvgIcon>
);
export const OutlinedSettingsIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <Settings />
  </SvgIcon>
);
export const TrafficIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <ChartSquareBarIcon />
  </SvgIcon>
);
export const UploadIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <Upload />
  </SvgIcon>
);
