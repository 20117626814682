// source: udpa/annotations/migrate.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_descriptor_pb = require('google-protobuf/google/protobuf/descriptor_pb.js');
goog.object.extend(proto, google_protobuf_descriptor_pb);
goog.exportSymbol(
  'proto.udpa.annotations.FieldMigrateAnnotation',
  null,
  global
);
goog.exportSymbol('proto.udpa.annotations.FileMigrateAnnotation', null, global);
goog.exportSymbol('proto.udpa.annotations.MigrateAnnotation', null, global);
goog.exportSymbol('proto.udpa.annotations.enumMigrate', null, global);
goog.exportSymbol('proto.udpa.annotations.enumValueMigrate', null, global);
goog.exportSymbol('proto.udpa.annotations.fieldMigrate', null, global);
goog.exportSymbol('proto.udpa.annotations.fileMigrate', null, global);
goog.exportSymbol('proto.udpa.annotations.messageMigrate', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.udpa.annotations.MigrateAnnotation = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.udpa.annotations.MigrateAnnotation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.udpa.annotations.MigrateAnnotation.displayName =
    'proto.udpa.annotations.MigrateAnnotation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.udpa.annotations.FieldMigrateAnnotation = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.udpa.annotations.FieldMigrateAnnotation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.udpa.annotations.FieldMigrateAnnotation.displayName =
    'proto.udpa.annotations.FieldMigrateAnnotation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.udpa.annotations.FileMigrateAnnotation = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.udpa.annotations.FileMigrateAnnotation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.udpa.annotations.FileMigrateAnnotation.displayName =
    'proto.udpa.annotations.FileMigrateAnnotation';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.udpa.annotations.MigrateAnnotation.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.udpa.annotations.MigrateAnnotation.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.udpa.annotations.MigrateAnnotation} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.udpa.annotations.MigrateAnnotation.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        rename: jspb.Message.getFieldWithDefault(msg, 1, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.udpa.annotations.MigrateAnnotation}
 */
proto.udpa.annotations.MigrateAnnotation.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.udpa.annotations.MigrateAnnotation();
  return proto.udpa.annotations.MigrateAnnotation.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.udpa.annotations.MigrateAnnotation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.udpa.annotations.MigrateAnnotation}
 */
proto.udpa.annotations.MigrateAnnotation.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setRename(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.udpa.annotations.MigrateAnnotation.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.udpa.annotations.MigrateAnnotation.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.udpa.annotations.MigrateAnnotation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.udpa.annotations.MigrateAnnotation.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getRename();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
};

/**
 * optional string rename = 1;
 * @return {string}
 */
proto.udpa.annotations.MigrateAnnotation.prototype.getRename = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.udpa.annotations.MigrateAnnotation} returns this
 */
proto.udpa.annotations.MigrateAnnotation.prototype.setRename = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.udpa.annotations.FieldMigrateAnnotation.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.udpa.annotations.FieldMigrateAnnotation.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.udpa.annotations.FieldMigrateAnnotation} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.udpa.annotations.FieldMigrateAnnotation.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        rename: jspb.Message.getFieldWithDefault(msg, 1, ''),
        oneofPromotion: jspb.Message.getFieldWithDefault(msg, 2, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.udpa.annotations.FieldMigrateAnnotation}
 */
proto.udpa.annotations.FieldMigrateAnnotation.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.udpa.annotations.FieldMigrateAnnotation();
  return proto.udpa.annotations.FieldMigrateAnnotation.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.udpa.annotations.FieldMigrateAnnotation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.udpa.annotations.FieldMigrateAnnotation}
 */
proto.udpa.annotations.FieldMigrateAnnotation.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setRename(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setOneofPromotion(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.udpa.annotations.FieldMigrateAnnotation.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.udpa.annotations.FieldMigrateAnnotation.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.udpa.annotations.FieldMigrateAnnotation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.udpa.annotations.FieldMigrateAnnotation.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getRename();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
    f = message.getOneofPromotion();
    if (f.length > 0) {
      writer.writeString(2, f);
    }
  };

/**
 * optional string rename = 1;
 * @return {string}
 */
proto.udpa.annotations.FieldMigrateAnnotation.prototype.getRename =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.udpa.annotations.FieldMigrateAnnotation} returns this
 */
proto.udpa.annotations.FieldMigrateAnnotation.prototype.setRename = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string oneof_promotion = 2;
 * @return {string}
 */
proto.udpa.annotations.FieldMigrateAnnotation.prototype.getOneofPromotion =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 2, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.udpa.annotations.FieldMigrateAnnotation} returns this
 */
proto.udpa.annotations.FieldMigrateAnnotation.prototype.setOneofPromotion =
  function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.udpa.annotations.FileMigrateAnnotation.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.udpa.annotations.FileMigrateAnnotation.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.udpa.annotations.FileMigrateAnnotation} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.udpa.annotations.FileMigrateAnnotation.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        moveToPackage: jspb.Message.getFieldWithDefault(msg, 2, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.udpa.annotations.FileMigrateAnnotation}
 */
proto.udpa.annotations.FileMigrateAnnotation.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.udpa.annotations.FileMigrateAnnotation();
  return proto.udpa.annotations.FileMigrateAnnotation.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.udpa.annotations.FileMigrateAnnotation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.udpa.annotations.FileMigrateAnnotation}
 */
proto.udpa.annotations.FileMigrateAnnotation.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setMoveToPackage(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.udpa.annotations.FileMigrateAnnotation.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.udpa.annotations.FileMigrateAnnotation.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.udpa.annotations.FileMigrateAnnotation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.udpa.annotations.FileMigrateAnnotation.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getMoveToPackage();
    if (f.length > 0) {
      writer.writeString(2, f);
    }
  };

/**
 * optional string move_to_package = 2;
 * @return {string}
 */
proto.udpa.annotations.FileMigrateAnnotation.prototype.getMoveToPackage =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 2, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.udpa.annotations.FileMigrateAnnotation} returns this
 */
proto.udpa.annotations.FileMigrateAnnotation.prototype.setMoveToPackage =
  function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
  };

/**
 * A tuple of {field number, class constructor} for the extension
 * field named `messageMigrate`.
 * @type {!jspb.ExtensionFieldInfo<!proto.udpa.annotations.MigrateAnnotation>}
 */
proto.udpa.annotations.messageMigrate = new jspb.ExtensionFieldInfo(
  171962766,
  { messageMigrate: 0 },
  proto.udpa.annotations.MigrateAnnotation,
  /** @type {?function((boolean|undefined),!jspb.Message=): !Object} */ (
    proto.udpa.annotations.MigrateAnnotation.toObject
  ),
  0
);

google_protobuf_descriptor_pb.MessageOptions.extensionsBinary[171962766] =
  new jspb.ExtensionFieldBinaryInfo(
    proto.udpa.annotations.messageMigrate,
    jspb.BinaryReader.prototype.readMessage,
    jspb.BinaryWriter.prototype.writeMessage,
    proto.udpa.annotations.MigrateAnnotation.serializeBinaryToWriter,
    proto.udpa.annotations.MigrateAnnotation.deserializeBinaryFromReader,
    false
  );
// This registers the extension field with the extended class, so that
// toObject() will function correctly.
google_protobuf_descriptor_pb.MessageOptions.extensions[171962766] =
  proto.udpa.annotations.messageMigrate;

/**
 * A tuple of {field number, class constructor} for the extension
 * field named `fieldMigrate`.
 * @type {!jspb.ExtensionFieldInfo<!proto.udpa.annotations.FieldMigrateAnnotation>}
 */
proto.udpa.annotations.fieldMigrate = new jspb.ExtensionFieldInfo(
  171962766,
  { fieldMigrate: 0 },
  proto.udpa.annotations.FieldMigrateAnnotation,
  /** @type {?function((boolean|undefined),!jspb.Message=): !Object} */ (
    proto.udpa.annotations.FieldMigrateAnnotation.toObject
  ),
  0
);

google_protobuf_descriptor_pb.FieldOptions.extensionsBinary[171962766] =
  new jspb.ExtensionFieldBinaryInfo(
    proto.udpa.annotations.fieldMigrate,
    jspb.BinaryReader.prototype.readMessage,
    jspb.BinaryWriter.prototype.writeMessage,
    proto.udpa.annotations.FieldMigrateAnnotation.serializeBinaryToWriter,
    proto.udpa.annotations.FieldMigrateAnnotation.deserializeBinaryFromReader,
    false
  );
// This registers the extension field with the extended class, so that
// toObject() will function correctly.
google_protobuf_descriptor_pb.FieldOptions.extensions[171962766] =
  proto.udpa.annotations.fieldMigrate;

/**
 * A tuple of {field number, class constructor} for the extension
 * field named `enumMigrate`.
 * @type {!jspb.ExtensionFieldInfo<!proto.udpa.annotations.MigrateAnnotation>}
 */
proto.udpa.annotations.enumMigrate = new jspb.ExtensionFieldInfo(
  171962766,
  { enumMigrate: 0 },
  proto.udpa.annotations.MigrateAnnotation,
  /** @type {?function((boolean|undefined),!jspb.Message=): !Object} */ (
    proto.udpa.annotations.MigrateAnnotation.toObject
  ),
  0
);

google_protobuf_descriptor_pb.EnumOptions.extensionsBinary[171962766] =
  new jspb.ExtensionFieldBinaryInfo(
    proto.udpa.annotations.enumMigrate,
    jspb.BinaryReader.prototype.readMessage,
    jspb.BinaryWriter.prototype.writeMessage,
    proto.udpa.annotations.MigrateAnnotation.serializeBinaryToWriter,
    proto.udpa.annotations.MigrateAnnotation.deserializeBinaryFromReader,
    false
  );
// This registers the extension field with the extended class, so that
// toObject() will function correctly.
google_protobuf_descriptor_pb.EnumOptions.extensions[171962766] =
  proto.udpa.annotations.enumMigrate;

/**
 * A tuple of {field number, class constructor} for the extension
 * field named `enumValueMigrate`.
 * @type {!jspb.ExtensionFieldInfo<!proto.udpa.annotations.MigrateAnnotation>}
 */
proto.udpa.annotations.enumValueMigrate = new jspb.ExtensionFieldInfo(
  171962766,
  { enumValueMigrate: 0 },
  proto.udpa.annotations.MigrateAnnotation,
  /** @type {?function((boolean|undefined),!jspb.Message=): !Object} */ (
    proto.udpa.annotations.MigrateAnnotation.toObject
  ),
  0
);

google_protobuf_descriptor_pb.EnumValueOptions.extensionsBinary[171962766] =
  new jspb.ExtensionFieldBinaryInfo(
    proto.udpa.annotations.enumValueMigrate,
    jspb.BinaryReader.prototype.readMessage,
    jspb.BinaryWriter.prototype.writeMessage,
    proto.udpa.annotations.MigrateAnnotation.serializeBinaryToWriter,
    proto.udpa.annotations.MigrateAnnotation.deserializeBinaryFromReader,
    false
  );
// This registers the extension field with the extended class, so that
// toObject() will function correctly.
google_protobuf_descriptor_pb.EnumValueOptions.extensions[171962766] =
  proto.udpa.annotations.enumValueMigrate;

/**
 * A tuple of {field number, class constructor} for the extension
 * field named `fileMigrate`.
 * @type {!jspb.ExtensionFieldInfo<!proto.udpa.annotations.FileMigrateAnnotation>}
 */
proto.udpa.annotations.fileMigrate = new jspb.ExtensionFieldInfo(
  171962766,
  { fileMigrate: 0 },
  proto.udpa.annotations.FileMigrateAnnotation,
  /** @type {?function((boolean|undefined),!jspb.Message=): !Object} */ (
    proto.udpa.annotations.FileMigrateAnnotation.toObject
  ),
  0
);

google_protobuf_descriptor_pb.FileOptions.extensionsBinary[171962766] =
  new jspb.ExtensionFieldBinaryInfo(
    proto.udpa.annotations.fileMigrate,
    jspb.BinaryReader.prototype.readMessage,
    jspb.BinaryWriter.prototype.writeMessage,
    proto.udpa.annotations.FileMigrateAnnotation.serializeBinaryToWriter,
    proto.udpa.annotations.FileMigrateAnnotation.deserializeBinaryFromReader,
    false
  );
// This registers the extension field with the extended class, so that
// toObject() will function correctly.
google_protobuf_descriptor_pb.FileOptions.extensions[171962766] =
  proto.udpa.annotations.fileMigrate;

goog.object.extend(exports, proto.udpa.annotations);
