// source: envoy/config/core/v3/config_source.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var envoy_config_core_v3_grpc_service_pb = require('../../../../envoy/config/core/v3/grpc_service_pb.js');
goog.object.extend(proto, envoy_config_core_v3_grpc_service_pb);
var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js');
goog.object.extend(proto, google_protobuf_duration_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var xds_core_v3_authority_pb = require('../../../../xds/core/v3/authority_pb.js');
goog.object.extend(proto, xds_core_v3_authority_pb);
var envoy_annotations_deprecation_pb = require('../../../../envoy/annotations/deprecation_pb.js');
goog.object.extend(proto, envoy_annotations_deprecation_pb);
var udpa_annotations_status_pb = require('../../../../udpa/annotations/status_pb.js');
goog.object.extend(proto, udpa_annotations_status_pb);
var udpa_annotations_versioning_pb = require('../../../../udpa/annotations/versioning_pb.js');
goog.object.extend(proto, udpa_annotations_versioning_pb);
var validate_validate_pb = require('../../../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol(
  'proto.envoy.config.core.v3.AggregatedConfigSource',
  null,
  global
);
goog.exportSymbol('proto.envoy.config.core.v3.ApiConfigSource', null, global);
goog.exportSymbol(
  'proto.envoy.config.core.v3.ApiConfigSource.ApiType',
  null,
  global
);
goog.exportSymbol('proto.envoy.config.core.v3.ApiVersion', null, global);
goog.exportSymbol('proto.envoy.config.core.v3.ConfigSource', null, global);
goog.exportSymbol(
  'proto.envoy.config.core.v3.ConfigSource.ConfigSourceSpecifierCase',
  null,
  global
);
goog.exportSymbol('proto.envoy.config.core.v3.RateLimitSettings', null, global);
goog.exportSymbol('proto.envoy.config.core.v3.SelfConfigSource', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.ApiConfigSource = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.envoy.config.core.v3.ApiConfigSource.repeatedFields_,
    null
  );
};
goog.inherits(proto.envoy.config.core.v3.ApiConfigSource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.ApiConfigSource.displayName =
    'proto.envoy.config.core.v3.ApiConfigSource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.AggregatedConfigSource = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.envoy.config.core.v3.AggregatedConfigSource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.AggregatedConfigSource.displayName =
    'proto.envoy.config.core.v3.AggregatedConfigSource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.SelfConfigSource = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.envoy.config.core.v3.SelfConfigSource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.SelfConfigSource.displayName =
    'proto.envoy.config.core.v3.SelfConfigSource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.RateLimitSettings = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.envoy.config.core.v3.RateLimitSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.RateLimitSettings.displayName =
    'proto.envoy.config.core.v3.RateLimitSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.ConfigSource = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.envoy.config.core.v3.ConfigSource.repeatedFields_,
    proto.envoy.config.core.v3.ConfigSource.oneofGroups_
  );
};
goog.inherits(proto.envoy.config.core.v3.ConfigSource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.ConfigSource.displayName =
    'proto.envoy.config.core.v3.ConfigSource';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.envoy.config.core.v3.ApiConfigSource.repeatedFields_ = [2, 4];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.ApiConfigSource.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.config.core.v3.ApiConfigSource.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.ApiConfigSource} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.ApiConfigSource.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        apiType: jspb.Message.getFieldWithDefault(msg, 1, 0),
        transportApiVersion: jspb.Message.getFieldWithDefault(msg, 8, 0),
        clusterNamesList:
          (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
        grpcServicesList: jspb.Message.toObjectList(
          msg.getGrpcServicesList(),
          envoy_config_core_v3_grpc_service_pb.GrpcService.toObject,
          includeInstance
        ),
        refreshDelay:
          (f = msg.getRefreshDelay()) &&
          google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
        requestTimeout:
          (f = msg.getRequestTimeout()) &&
          google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
        rateLimitSettings:
          (f = msg.getRateLimitSettings()) &&
          proto.envoy.config.core.v3.RateLimitSettings.toObject(
            includeInstance,
            f
          ),
        setNodeOnFirstMessageOnly: jspb.Message.getBooleanFieldWithDefault(
          msg,
          7,
          false
        )
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.ApiConfigSource}
 */
proto.envoy.config.core.v3.ApiConfigSource.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.ApiConfigSource();
  return proto.envoy.config.core.v3.ApiConfigSource.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.ApiConfigSource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.ApiConfigSource}
 */
proto.envoy.config.core.v3.ApiConfigSource.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value =
            /** @type {!proto.envoy.config.core.v3.ApiConfigSource.ApiType} */ (
              reader.readEnum()
            );
          msg.setApiType(value);
          break;
        case 8:
          var value = /** @type {!proto.envoy.config.core.v3.ApiVersion} */ (
            reader.readEnum()
          );
          msg.setTransportApiVersion(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.addClusterNames(value);
          break;
        case 4:
          var value = new envoy_config_core_v3_grpc_service_pb.GrpcService();
          reader.readMessage(
            value,
            envoy_config_core_v3_grpc_service_pb.GrpcService
              .deserializeBinaryFromReader
          );
          msg.addGrpcServices(value);
          break;
        case 3:
          var value = new google_protobuf_duration_pb.Duration();
          reader.readMessage(
            value,
            google_protobuf_duration_pb.Duration.deserializeBinaryFromReader
          );
          msg.setRefreshDelay(value);
          break;
        case 5:
          var value = new google_protobuf_duration_pb.Duration();
          reader.readMessage(
            value,
            google_protobuf_duration_pb.Duration.deserializeBinaryFromReader
          );
          msg.setRequestTimeout(value);
          break;
        case 6:
          var value = new proto.envoy.config.core.v3.RateLimitSettings();
          reader.readMessage(
            value,
            proto.envoy.config.core.v3.RateLimitSettings
              .deserializeBinaryFromReader
          );
          msg.setRateLimitSettings(value);
          break;
        case 7:
          var value = /** @type {boolean} */ (reader.readBool());
          msg.setSetNodeOnFirstMessageOnly(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.ApiConfigSource.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.ApiConfigSource.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.ApiConfigSource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.ApiConfigSource.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getApiType();
  if (f !== 0.0) {
    writer.writeEnum(1, f);
  }
  f = message.getTransportApiVersion();
  if (f !== 0.0) {
    writer.writeEnum(8, f);
  }
  f = message.getClusterNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(2, f);
  }
  f = message.getGrpcServicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      envoy_config_core_v3_grpc_service_pb.GrpcService.serializeBinaryToWriter
    );
  }
  f = message.getRefreshDelay();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getRequestTimeout();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getRateLimitSettings();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.envoy.config.core.v3.RateLimitSettings.serializeBinaryToWriter
    );
  }
  f = message.getSetNodeOnFirstMessageOnly();
  if (f) {
    writer.writeBool(7, f);
  }
};

/**
 * @enum {number}
 */
proto.envoy.config.core.v3.ApiConfigSource.ApiType = {
  DEPRECATED_AND_UNAVAILABLE_DO_NOT_USE: 0,
  REST: 1,
  GRPC: 2,
  DELTA_GRPC: 3,
  AGGREGATED_GRPC: 5,
  AGGREGATED_DELTA_GRPC: 6
};

/**
 * optional ApiType api_type = 1;
 * @return {!proto.envoy.config.core.v3.ApiConfigSource.ApiType}
 */
proto.envoy.config.core.v3.ApiConfigSource.prototype.getApiType = function () {
  return /** @type {!proto.envoy.config.core.v3.ApiConfigSource.ApiType} */ (
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};

/**
 * @param {!proto.envoy.config.core.v3.ApiConfigSource.ApiType} value
 * @return {!proto.envoy.config.core.v3.ApiConfigSource} returns this
 */
proto.envoy.config.core.v3.ApiConfigSource.prototype.setApiType = function (
  value
) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};

/**
 * optional ApiVersion transport_api_version = 8;
 * @return {!proto.envoy.config.core.v3.ApiVersion}
 */
proto.envoy.config.core.v3.ApiConfigSource.prototype.getTransportApiVersion =
  function () {
    return /** @type {!proto.envoy.config.core.v3.ApiVersion} */ (
      jspb.Message.getFieldWithDefault(this, 8, 0)
    );
  };

/**
 * @param {!proto.envoy.config.core.v3.ApiVersion} value
 * @return {!proto.envoy.config.core.v3.ApiConfigSource} returns this
 */
proto.envoy.config.core.v3.ApiConfigSource.prototype.setTransportApiVersion =
  function (value) {
    return jspb.Message.setProto3EnumField(this, 8, value);
  };

/**
 * repeated string cluster_names = 2;
 * @return {!Array<string>}
 */
proto.envoy.config.core.v3.ApiConfigSource.prototype.getClusterNamesList =
  function () {
    return /** @type {!Array<string>} */ (
      jspb.Message.getRepeatedField(this, 2)
    );
  };

/**
 * @param {!Array<string>} value
 * @return {!proto.envoy.config.core.v3.ApiConfigSource} returns this
 */
proto.envoy.config.core.v3.ApiConfigSource.prototype.setClusterNamesList =
  function (value) {
    return jspb.Message.setField(this, 2, value || []);
  };

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.envoy.config.core.v3.ApiConfigSource} returns this
 */
proto.envoy.config.core.v3.ApiConfigSource.prototype.addClusterNames =
  function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
  };

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.envoy.config.core.v3.ApiConfigSource} returns this
 */
proto.envoy.config.core.v3.ApiConfigSource.prototype.clearClusterNamesList =
  function () {
    return this.setClusterNamesList([]);
  };

/**
 * repeated GrpcService grpc_services = 4;
 * @return {!Array<!proto.envoy.config.core.v3.GrpcService>}
 */
proto.envoy.config.core.v3.ApiConfigSource.prototype.getGrpcServicesList =
  function () {
    return /** @type{!Array<!proto.envoy.config.core.v3.GrpcService>} */ (
      jspb.Message.getRepeatedWrapperField(
        this,
        envoy_config_core_v3_grpc_service_pb.GrpcService,
        4
      )
    );
  };

/**
 * @param {!Array<!proto.envoy.config.core.v3.GrpcService>} value
 * @return {!proto.envoy.config.core.v3.ApiConfigSource} returns this
 */
proto.envoy.config.core.v3.ApiConfigSource.prototype.setGrpcServicesList =
  function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 4, value);
  };

/**
 * @param {!proto.envoy.config.core.v3.GrpcService=} opt_value
 * @param {number=} opt_index
 * @return {!proto.envoy.config.core.v3.GrpcService}
 */
proto.envoy.config.core.v3.ApiConfigSource.prototype.addGrpcServices =
  function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(
      this,
      4,
      opt_value,
      proto.envoy.config.core.v3.GrpcService,
      opt_index
    );
  };

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.envoy.config.core.v3.ApiConfigSource} returns this
 */
proto.envoy.config.core.v3.ApiConfigSource.prototype.clearGrpcServicesList =
  function () {
    return this.setGrpcServicesList([]);
  };

/**
 * optional google.protobuf.Duration refresh_delay = 3;
 * @return {?proto.google.protobuf.Duration}
 */
proto.envoy.config.core.v3.ApiConfigSource.prototype.getRefreshDelay =
  function () {
    return /** @type{?proto.google.protobuf.Duration} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_duration_pb.Duration,
        3
      )
    );
  };

/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.envoy.config.core.v3.ApiConfigSource} returns this
 */
proto.envoy.config.core.v3.ApiConfigSource.prototype.setRefreshDelay =
  function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.ApiConfigSource} returns this
 */
proto.envoy.config.core.v3.ApiConfigSource.prototype.clearRefreshDelay =
  function () {
    return this.setRefreshDelay(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.ApiConfigSource.prototype.hasRefreshDelay =
  function () {
    return jspb.Message.getField(this, 3) != null;
  };

/**
 * optional google.protobuf.Duration request_timeout = 5;
 * @return {?proto.google.protobuf.Duration}
 */
proto.envoy.config.core.v3.ApiConfigSource.prototype.getRequestTimeout =
  function () {
    return /** @type{?proto.google.protobuf.Duration} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_duration_pb.Duration,
        5
      )
    );
  };

/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.envoy.config.core.v3.ApiConfigSource} returns this
 */
proto.envoy.config.core.v3.ApiConfigSource.prototype.setRequestTimeout =
  function (value) {
    return jspb.Message.setWrapperField(this, 5, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.ApiConfigSource} returns this
 */
proto.envoy.config.core.v3.ApiConfigSource.prototype.clearRequestTimeout =
  function () {
    return this.setRequestTimeout(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.ApiConfigSource.prototype.hasRequestTimeout =
  function () {
    return jspb.Message.getField(this, 5) != null;
  };

/**
 * optional RateLimitSettings rate_limit_settings = 6;
 * @return {?proto.envoy.config.core.v3.RateLimitSettings}
 */
proto.envoy.config.core.v3.ApiConfigSource.prototype.getRateLimitSettings =
  function () {
    return /** @type{?proto.envoy.config.core.v3.RateLimitSettings} */ (
      jspb.Message.getWrapperField(
        this,
        proto.envoy.config.core.v3.RateLimitSettings,
        6
      )
    );
  };

/**
 * @param {?proto.envoy.config.core.v3.RateLimitSettings|undefined} value
 * @return {!proto.envoy.config.core.v3.ApiConfigSource} returns this
 */
proto.envoy.config.core.v3.ApiConfigSource.prototype.setRateLimitSettings =
  function (value) {
    return jspb.Message.setWrapperField(this, 6, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.ApiConfigSource} returns this
 */
proto.envoy.config.core.v3.ApiConfigSource.prototype.clearRateLimitSettings =
  function () {
    return this.setRateLimitSettings(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.ApiConfigSource.prototype.hasRateLimitSettings =
  function () {
    return jspb.Message.getField(this, 6) != null;
  };

/**
 * optional bool set_node_on_first_message_only = 7;
 * @return {boolean}
 */
proto.envoy.config.core.v3.ApiConfigSource.prototype.getSetNodeOnFirstMessageOnly =
  function () {
    return /** @type {boolean} */ (
      jspb.Message.getBooleanFieldWithDefault(this, 7, false)
    );
  };

/**
 * @param {boolean} value
 * @return {!proto.envoy.config.core.v3.ApiConfigSource} returns this
 */
proto.envoy.config.core.v3.ApiConfigSource.prototype.setSetNodeOnFirstMessageOnly =
  function (value) {
    return jspb.Message.setProto3BooleanField(this, 7, value);
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.AggregatedConfigSource.prototype.toObject =
    function (opt_includeInstance) {
      return proto.envoy.config.core.v3.AggregatedConfigSource.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.AggregatedConfigSource} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.AggregatedConfigSource.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {};

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.AggregatedConfigSource}
 */
proto.envoy.config.core.v3.AggregatedConfigSource.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.AggregatedConfigSource();
  return proto.envoy.config.core.v3.AggregatedConfigSource.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.AggregatedConfigSource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.AggregatedConfigSource}
 */
proto.envoy.config.core.v3.AggregatedConfigSource.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.AggregatedConfigSource.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.AggregatedConfigSource.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.AggregatedConfigSource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.AggregatedConfigSource.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.SelfConfigSource.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.config.core.v3.SelfConfigSource.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.SelfConfigSource} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.SelfConfigSource.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        transportApiVersion: jspb.Message.getFieldWithDefault(msg, 1, 0)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.SelfConfigSource}
 */
proto.envoy.config.core.v3.SelfConfigSource.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.SelfConfigSource();
  return proto.envoy.config.core.v3.SelfConfigSource.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.SelfConfigSource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.SelfConfigSource}
 */
proto.envoy.config.core.v3.SelfConfigSource.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {!proto.envoy.config.core.v3.ApiVersion} */ (
            reader.readEnum()
          );
          msg.setTransportApiVersion(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.SelfConfigSource.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.SelfConfigSource.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.SelfConfigSource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.SelfConfigSource.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getTransportApiVersion();
  if (f !== 0.0) {
    writer.writeEnum(1, f);
  }
};

/**
 * optional ApiVersion transport_api_version = 1;
 * @return {!proto.envoy.config.core.v3.ApiVersion}
 */
proto.envoy.config.core.v3.SelfConfigSource.prototype.getTransportApiVersion =
  function () {
    return /** @type {!proto.envoy.config.core.v3.ApiVersion} */ (
      jspb.Message.getFieldWithDefault(this, 1, 0)
    );
  };

/**
 * @param {!proto.envoy.config.core.v3.ApiVersion} value
 * @return {!proto.envoy.config.core.v3.SelfConfigSource} returns this
 */
proto.envoy.config.core.v3.SelfConfigSource.prototype.setTransportApiVersion =
  function (value) {
    return jspb.Message.setProto3EnumField(this, 1, value);
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.RateLimitSettings.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.config.core.v3.RateLimitSettings.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.RateLimitSettings} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.RateLimitSettings.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        maxTokens:
          (f = msg.getMaxTokens()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
        fillRate:
          (f = msg.getFillRate()) &&
          google_protobuf_wrappers_pb.DoubleValue.toObject(includeInstance, f)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.RateLimitSettings}
 */
proto.envoy.config.core.v3.RateLimitSettings.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.RateLimitSettings();
  return proto.envoy.config.core.v3.RateLimitSettings.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.RateLimitSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.RateLimitSettings}
 */
proto.envoy.config.core.v3.RateLimitSettings.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new google_protobuf_wrappers_pb.UInt32Value();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
          );
          msg.setMaxTokens(value);
          break;
        case 2:
          var value = new google_protobuf_wrappers_pb.DoubleValue();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.DoubleValue.deserializeBinaryFromReader
          );
          msg.setFillRate(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.RateLimitSettings.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.RateLimitSettings.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.RateLimitSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.RateLimitSettings.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getMaxTokens();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
      );
    }
    f = message.getFillRate();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        google_protobuf_wrappers_pb.DoubleValue.serializeBinaryToWriter
      );
    }
  };

/**
 * optional google.protobuf.UInt32Value max_tokens = 1;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.core.v3.RateLimitSettings.prototype.getMaxTokens =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        1
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.core.v3.RateLimitSettings} returns this
 */
proto.envoy.config.core.v3.RateLimitSettings.prototype.setMaxTokens = function (
  value
) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.RateLimitSettings} returns this
 */
proto.envoy.config.core.v3.RateLimitSettings.prototype.clearMaxTokens =
  function () {
    return this.setMaxTokens(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.RateLimitSettings.prototype.hasMaxTokens =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

/**
 * optional google.protobuf.DoubleValue fill_rate = 2;
 * @return {?proto.google.protobuf.DoubleValue}
 */
proto.envoy.config.core.v3.RateLimitSettings.prototype.getFillRate =
  function () {
    return /** @type{?proto.google.protobuf.DoubleValue} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.DoubleValue,
        2
      )
    );
  };

/**
 * @param {?proto.google.protobuf.DoubleValue|undefined} value
 * @return {!proto.envoy.config.core.v3.RateLimitSettings} returns this
 */
proto.envoy.config.core.v3.RateLimitSettings.prototype.setFillRate = function (
  value
) {
  return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.RateLimitSettings} returns this
 */
proto.envoy.config.core.v3.RateLimitSettings.prototype.clearFillRate =
  function () {
    return this.setFillRate(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.RateLimitSettings.prototype.hasFillRate =
  function () {
    return jspb.Message.getField(this, 2) != null;
  };

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.envoy.config.core.v3.ConfigSource.repeatedFields_ = [7];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.envoy.config.core.v3.ConfigSource.oneofGroups_ = [[1, 2, 3, 5]];

/**
 * @enum {number}
 */
proto.envoy.config.core.v3.ConfigSource.ConfigSourceSpecifierCase = {
  CONFIG_SOURCE_SPECIFIER_NOT_SET: 0,
  PATH: 1,
  API_CONFIG_SOURCE: 2,
  ADS: 3,
  SELF: 5
};

/**
 * @return {proto.envoy.config.core.v3.ConfigSource.ConfigSourceSpecifierCase}
 */
proto.envoy.config.core.v3.ConfigSource.prototype.getConfigSourceSpecifierCase =
  function () {
    return /** @type {proto.envoy.config.core.v3.ConfigSource.ConfigSourceSpecifierCase} */ (
      jspb.Message.computeOneofCase(
        this,
        proto.envoy.config.core.v3.ConfigSource.oneofGroups_[0]
      )
    );
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.ConfigSource.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.config.core.v3.ConfigSource.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.ConfigSource} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.ConfigSource.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        authoritiesList: jspb.Message.toObjectList(
          msg.getAuthoritiesList(),
          xds_core_v3_authority_pb.Authority.toObject,
          includeInstance
        ),
        path: jspb.Message.getFieldWithDefault(msg, 1, ''),
        apiConfigSource:
          (f = msg.getApiConfigSource()) &&
          proto.envoy.config.core.v3.ApiConfigSource.toObject(
            includeInstance,
            f
          ),
        ads:
          (f = msg.getAds()) &&
          proto.envoy.config.core.v3.AggregatedConfigSource.toObject(
            includeInstance,
            f
          ),
        self:
          (f = msg.getSelf()) &&
          proto.envoy.config.core.v3.SelfConfigSource.toObject(
            includeInstance,
            f
          ),
        initialFetchTimeout:
          (f = msg.getInitialFetchTimeout()) &&
          google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
        resourceApiVersion: jspb.Message.getFieldWithDefault(msg, 6, 0)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.ConfigSource}
 */
proto.envoy.config.core.v3.ConfigSource.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.ConfigSource();
  return proto.envoy.config.core.v3.ConfigSource.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.ConfigSource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.ConfigSource}
 */
proto.envoy.config.core.v3.ConfigSource.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 7:
        var value = new xds_core_v3_authority_pb.Authority();
        reader.readMessage(
          value,
          xds_core_v3_authority_pb.Authority.deserializeBinaryFromReader
        );
        msg.addAuthorities(value);
        break;
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setPath(value);
        break;
      case 2:
        var value = new proto.envoy.config.core.v3.ApiConfigSource();
        reader.readMessage(
          value,
          proto.envoy.config.core.v3.ApiConfigSource.deserializeBinaryFromReader
        );
        msg.setApiConfigSource(value);
        break;
      case 3:
        var value = new proto.envoy.config.core.v3.AggregatedConfigSource();
        reader.readMessage(
          value,
          proto.envoy.config.core.v3.AggregatedConfigSource
            .deserializeBinaryFromReader
        );
        msg.setAds(value);
        break;
      case 5:
        var value = new proto.envoy.config.core.v3.SelfConfigSource();
        reader.readMessage(
          value,
          proto.envoy.config.core.v3.SelfConfigSource
            .deserializeBinaryFromReader
        );
        msg.setSelf(value);
        break;
      case 4:
        var value = new google_protobuf_duration_pb.Duration();
        reader.readMessage(
          value,
          google_protobuf_duration_pb.Duration.deserializeBinaryFromReader
        );
        msg.setInitialFetchTimeout(value);
        break;
      case 6:
        var value = /** @type {!proto.envoy.config.core.v3.ApiVersion} */ (
          reader.readEnum()
        );
        msg.setResourceApiVersion(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.ConfigSource.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.ConfigSource.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.ConfigSource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.ConfigSource.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getAuthoritiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      xds_core_v3_authority_pb.Authority.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(1, f);
  }
  f = message.getApiConfigSource();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.envoy.config.core.v3.ApiConfigSource.serializeBinaryToWriter
    );
  }
  f = message.getAds();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.envoy.config.core.v3.AggregatedConfigSource.serializeBinaryToWriter
    );
  }
  f = message.getSelf();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.envoy.config.core.v3.SelfConfigSource.serializeBinaryToWriter
    );
  }
  f = message.getInitialFetchTimeout();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getResourceApiVersion();
  if (f !== 0.0) {
    writer.writeEnum(6, f);
  }
};

/**
 * repeated xds.core.v3.Authority authorities = 7;
 * @return {!Array<!proto.xds.core.v3.Authority>}
 */
proto.envoy.config.core.v3.ConfigSource.prototype.getAuthoritiesList =
  function () {
    return /** @type{!Array<!proto.xds.core.v3.Authority>} */ (
      jspb.Message.getRepeatedWrapperField(
        this,
        xds_core_v3_authority_pb.Authority,
        7
      )
    );
  };

/**
 * @param {!Array<!proto.xds.core.v3.Authority>} value
 * @return {!proto.envoy.config.core.v3.ConfigSource} returns this
 */
proto.envoy.config.core.v3.ConfigSource.prototype.setAuthoritiesList =
  function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 7, value);
  };

/**
 * @param {!proto.xds.core.v3.Authority=} opt_value
 * @param {number=} opt_index
 * @return {!proto.xds.core.v3.Authority}
 */
proto.envoy.config.core.v3.ConfigSource.prototype.addAuthorities = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    7,
    opt_value,
    proto.xds.core.v3.Authority,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.envoy.config.core.v3.ConfigSource} returns this
 */
proto.envoy.config.core.v3.ConfigSource.prototype.clearAuthoritiesList =
  function () {
    return this.setAuthoritiesList([]);
  };

/**
 * optional string path = 1;
 * @return {string}
 */
proto.envoy.config.core.v3.ConfigSource.prototype.getPath = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.ConfigSource} returns this
 */
proto.envoy.config.core.v3.ConfigSource.prototype.setPath = function (value) {
  return jspb.Message.setOneofField(
    this,
    1,
    proto.envoy.config.core.v3.ConfigSource.oneofGroups_[0],
    value
  );
};

/**
 * Clears the field making it undefined.
 * @return {!proto.envoy.config.core.v3.ConfigSource} returns this
 */
proto.envoy.config.core.v3.ConfigSource.prototype.clearPath = function () {
  return jspb.Message.setOneofField(
    this,
    1,
    proto.envoy.config.core.v3.ConfigSource.oneofGroups_[0],
    undefined
  );
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.ConfigSource.prototype.hasPath = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional ApiConfigSource api_config_source = 2;
 * @return {?proto.envoy.config.core.v3.ApiConfigSource}
 */
proto.envoy.config.core.v3.ConfigSource.prototype.getApiConfigSource =
  function () {
    return /** @type{?proto.envoy.config.core.v3.ApiConfigSource} */ (
      jspb.Message.getWrapperField(
        this,
        proto.envoy.config.core.v3.ApiConfigSource,
        2
      )
    );
  };

/**
 * @param {?proto.envoy.config.core.v3.ApiConfigSource|undefined} value
 * @return {!proto.envoy.config.core.v3.ConfigSource} returns this
 */
proto.envoy.config.core.v3.ConfigSource.prototype.setApiConfigSource =
  function (value) {
    return jspb.Message.setOneofWrapperField(
      this,
      2,
      proto.envoy.config.core.v3.ConfigSource.oneofGroups_[0],
      value
    );
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.ConfigSource} returns this
 */
proto.envoy.config.core.v3.ConfigSource.prototype.clearApiConfigSource =
  function () {
    return this.setApiConfigSource(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.ConfigSource.prototype.hasApiConfigSource =
  function () {
    return jspb.Message.getField(this, 2) != null;
  };

/**
 * optional AggregatedConfigSource ads = 3;
 * @return {?proto.envoy.config.core.v3.AggregatedConfigSource}
 */
proto.envoy.config.core.v3.ConfigSource.prototype.getAds = function () {
  return /** @type{?proto.envoy.config.core.v3.AggregatedConfigSource} */ (
    jspb.Message.getWrapperField(
      this,
      proto.envoy.config.core.v3.AggregatedConfigSource,
      3
    )
  );
};

/**
 * @param {?proto.envoy.config.core.v3.AggregatedConfigSource|undefined} value
 * @return {!proto.envoy.config.core.v3.ConfigSource} returns this
 */
proto.envoy.config.core.v3.ConfigSource.prototype.setAds = function (value) {
  return jspb.Message.setOneofWrapperField(
    this,
    3,
    proto.envoy.config.core.v3.ConfigSource.oneofGroups_[0],
    value
  );
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.ConfigSource} returns this
 */
proto.envoy.config.core.v3.ConfigSource.prototype.clearAds = function () {
  return this.setAds(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.ConfigSource.prototype.hasAds = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional SelfConfigSource self = 5;
 * @return {?proto.envoy.config.core.v3.SelfConfigSource}
 */
proto.envoy.config.core.v3.ConfigSource.prototype.getSelf = function () {
  return /** @type{?proto.envoy.config.core.v3.SelfConfigSource} */ (
    jspb.Message.getWrapperField(
      this,
      proto.envoy.config.core.v3.SelfConfigSource,
      5
    )
  );
};

/**
 * @param {?proto.envoy.config.core.v3.SelfConfigSource|undefined} value
 * @return {!proto.envoy.config.core.v3.ConfigSource} returns this
 */
proto.envoy.config.core.v3.ConfigSource.prototype.setSelf = function (value) {
  return jspb.Message.setOneofWrapperField(
    this,
    5,
    proto.envoy.config.core.v3.ConfigSource.oneofGroups_[0],
    value
  );
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.ConfigSource} returns this
 */
proto.envoy.config.core.v3.ConfigSource.prototype.clearSelf = function () {
  return this.setSelf(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.ConfigSource.prototype.hasSelf = function () {
  return jspb.Message.getField(this, 5) != null;
};

/**
 * optional google.protobuf.Duration initial_fetch_timeout = 4;
 * @return {?proto.google.protobuf.Duration}
 */
proto.envoy.config.core.v3.ConfigSource.prototype.getInitialFetchTimeout =
  function () {
    return /** @type{?proto.google.protobuf.Duration} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_duration_pb.Duration,
        4
      )
    );
  };

/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.envoy.config.core.v3.ConfigSource} returns this
 */
proto.envoy.config.core.v3.ConfigSource.prototype.setInitialFetchTimeout =
  function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.ConfigSource} returns this
 */
proto.envoy.config.core.v3.ConfigSource.prototype.clearInitialFetchTimeout =
  function () {
    return this.setInitialFetchTimeout(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.ConfigSource.prototype.hasInitialFetchTimeout =
  function () {
    return jspb.Message.getField(this, 4) != null;
  };

/**
 * optional ApiVersion resource_api_version = 6;
 * @return {!proto.envoy.config.core.v3.ApiVersion}
 */
proto.envoy.config.core.v3.ConfigSource.prototype.getResourceApiVersion =
  function () {
    return /** @type {!proto.envoy.config.core.v3.ApiVersion} */ (
      jspb.Message.getFieldWithDefault(this, 6, 0)
    );
  };

/**
 * @param {!proto.envoy.config.core.v3.ApiVersion} value
 * @return {!proto.envoy.config.core.v3.ConfigSource} returns this
 */
proto.envoy.config.core.v3.ConfigSource.prototype.setResourceApiVersion =
  function (value) {
    return jspb.Message.setProto3EnumField(this, 6, value);
  };

/**
 * @enum {number}
 */
proto.envoy.config.core.v3.ApiVersion = {
  AUTO: 0,
  V2: 1,
  V3: 2
};

goog.object.extend(exports, proto.envoy.config.core.v3);
