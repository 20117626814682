import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import React, { FC } from 'react';
import { ChevronDown } from 'react-feather';
import { useHistory } from 'react-router-dom';

import FileSystemNavigator from './TreeView';

import { FilledFolderIcon, OpenInNewIcon } from 'src/components/icons';
import { useSelector } from 'src/store';

const PopDown: FC = () => {
  const { currentNamespace, enableSelector } = useSelector(
    (state) => state.namespaces
  );
  const history = useHistory();

  return (
    <PopupState variant="popover" popupId="namespace-tree">
      {(popupState): JSX.Element => (
        <Grid container direction={'row'} sx={{ m: 0 }}>
          <Button
            startIcon={<FilledFolderIcon />}
            sx={{
              display: 'flex',
              flexFlow: 'row nowrap',
              justifyContent: 'space-around'
            }}
            className="POMERIUM__Namespace-Tree-Dropdown"
            variant="contained"
            color="primary"
            {...bindTrigger(popupState)}
            endIcon={<ChevronDown />}
            size={'small'}
            disabled={!enableSelector}
          >
            <Typography variant="h6">{currentNamespace.name}</Typography>
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
          >
            <Box
              p={2}
              sx={{
                display: 'flex',
                flexFlow: 'row nowrap',
                justifyContent: 'space-evenly',
                alignItems: `flex-start`
              }}
            >
              {history.location.pathname !== '/app/management/namespaces' && (
                <Tooltip title="Namespace Settings">
                  <IconButton
                    color="inherit"
                    size={'small'}
                    sx={{
                      margin: 0.75,
                      padding: 0
                    }}
                    onClick={() => history.push(`/app/management/namespaces`)}
                  >
                    <SvgIcon fontSize="small">
                      <OpenInNewIcon />
                    </SvgIcon>
                  </IconButton>
                </Tooltip>
              )}
              <FileSystemNavigator />
            </Box>
          </Popover>
        </Grid>
      )}
    </PopupState>
  );
};

export default PopDown;
