// source: envoy/config/core/v3/socket_option.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var udpa_annotations_status_pb = require('../../../../udpa/annotations/status_pb.js');
goog.object.extend(proto, udpa_annotations_status_pb);
var udpa_annotations_versioning_pb = require('../../../../udpa/annotations/versioning_pb.js');
goog.object.extend(proto, udpa_annotations_versioning_pb);
var validate_validate_pb = require('../../../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.envoy.config.core.v3.SocketOption', null, global);
goog.exportSymbol(
  'proto.envoy.config.core.v3.SocketOption.SocketState',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.core.v3.SocketOption.ValueCase',
  null,
  global
);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.SocketOption = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    null,
    proto.envoy.config.core.v3.SocketOption.oneofGroups_
  );
};
goog.inherits(proto.envoy.config.core.v3.SocketOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.SocketOption.displayName =
    'proto.envoy.config.core.v3.SocketOption';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.envoy.config.core.v3.SocketOption.oneofGroups_ = [[4, 5]];

/**
 * @enum {number}
 */
proto.envoy.config.core.v3.SocketOption.ValueCase = {
  VALUE_NOT_SET: 0,
  INT_VALUE: 4,
  BUF_VALUE: 5
};

/**
 * @return {proto.envoy.config.core.v3.SocketOption.ValueCase}
 */
proto.envoy.config.core.v3.SocketOption.prototype.getValueCase = function () {
  return /** @type {proto.envoy.config.core.v3.SocketOption.ValueCase} */ (
    jspb.Message.computeOneofCase(
      this,
      proto.envoy.config.core.v3.SocketOption.oneofGroups_[0]
    )
  );
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.SocketOption.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.config.core.v3.SocketOption.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.SocketOption} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.SocketOption.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        description: jspb.Message.getFieldWithDefault(msg, 1, ''),
        level: jspb.Message.getFieldWithDefault(msg, 2, 0),
        name: jspb.Message.getFieldWithDefault(msg, 3, 0),
        intValue: jspb.Message.getFieldWithDefault(msg, 4, 0),
        bufValue: msg.getBufValue_asB64(),
        state: jspb.Message.getFieldWithDefault(msg, 6, 0)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.SocketOption}
 */
proto.envoy.config.core.v3.SocketOption.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.SocketOption();
  return proto.envoy.config.core.v3.SocketOption.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.SocketOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.SocketOption}
 */
proto.envoy.config.core.v3.SocketOption.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setDescription(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setLevel(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setName(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setIntValue(value);
        break;
      case 5:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setBufValue(value);
        break;
      case 6:
        var value =
          /** @type {!proto.envoy.config.core.v3.SocketOption.SocketState} */ (
            reader.readEnum()
          );
        msg.setState(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.SocketOption.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.SocketOption.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.SocketOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.SocketOption.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getLevel();
  if (f !== 0) {
    writer.writeInt64(2, f);
  }
  f = message.getName();
  if (f !== 0) {
    writer.writeInt64(3, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt64(4, f);
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBytes(5, f);
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(6, f);
  }
};

/**
 * @enum {number}
 */
proto.envoy.config.core.v3.SocketOption.SocketState = {
  STATE_PREBIND: 0,
  STATE_BOUND: 1,
  STATE_LISTENING: 2
};

/**
 * optional string description = 1;
 * @return {string}
 */
proto.envoy.config.core.v3.SocketOption.prototype.getDescription = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.SocketOption} returns this
 */
proto.envoy.config.core.v3.SocketOption.prototype.setDescription = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional int64 level = 2;
 * @return {number}
 */
proto.envoy.config.core.v3.SocketOption.prototype.getLevel = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.envoy.config.core.v3.SocketOption} returns this
 */
proto.envoy.config.core.v3.SocketOption.prototype.setLevel = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional int64 name = 3;
 * @return {number}
 */
proto.envoy.config.core.v3.SocketOption.prototype.getName = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.envoy.config.core.v3.SocketOption} returns this
 */
proto.envoy.config.core.v3.SocketOption.prototype.setName = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional int64 int_value = 4;
 * @return {number}
 */
proto.envoy.config.core.v3.SocketOption.prototype.getIntValue = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {number} value
 * @return {!proto.envoy.config.core.v3.SocketOption} returns this
 */
proto.envoy.config.core.v3.SocketOption.prototype.setIntValue = function (
  value
) {
  return jspb.Message.setOneofField(
    this,
    4,
    proto.envoy.config.core.v3.SocketOption.oneofGroups_[0],
    value
  );
};

/**
 * Clears the field making it undefined.
 * @return {!proto.envoy.config.core.v3.SocketOption} returns this
 */
proto.envoy.config.core.v3.SocketOption.prototype.clearIntValue = function () {
  return jspb.Message.setOneofField(
    this,
    4,
    proto.envoy.config.core.v3.SocketOption.oneofGroups_[0],
    undefined
  );
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.SocketOption.prototype.hasIntValue = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional bytes buf_value = 5;
 * @return {string}
 */
proto.envoy.config.core.v3.SocketOption.prototype.getBufValue = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};

/**
 * optional bytes buf_value = 5;
 * This is a type-conversion wrapper around `getBufValue()`
 * @return {string}
 */
proto.envoy.config.core.v3.SocketOption.prototype.getBufValue_asB64 =
  function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getBufValue()));
  };

/**
 * optional bytes buf_value = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getBufValue()`
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.SocketOption.prototype.getBufValue_asU8 =
  function () {
    return /** @type {!Uint8Array} */ (
      jspb.Message.bytesAsU8(this.getBufValue())
    );
  };

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.envoy.config.core.v3.SocketOption} returns this
 */
proto.envoy.config.core.v3.SocketOption.prototype.setBufValue = function (
  value
) {
  return jspb.Message.setOneofField(
    this,
    5,
    proto.envoy.config.core.v3.SocketOption.oneofGroups_[0],
    value
  );
};

/**
 * Clears the field making it undefined.
 * @return {!proto.envoy.config.core.v3.SocketOption} returns this
 */
proto.envoy.config.core.v3.SocketOption.prototype.clearBufValue = function () {
  return jspb.Message.setOneofField(
    this,
    5,
    proto.envoy.config.core.v3.SocketOption.oneofGroups_[0],
    undefined
  );
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.SocketOption.prototype.hasBufValue = function () {
  return jspb.Message.getField(this, 5) != null;
};

/**
 * optional SocketState state = 6;
 * @return {!proto.envoy.config.core.v3.SocketOption.SocketState}
 */
proto.envoy.config.core.v3.SocketOption.prototype.getState = function () {
  return /** @type {!proto.envoy.config.core.v3.SocketOption.SocketState} */ (
    jspb.Message.getFieldWithDefault(this, 6, 0)
  );
};

/**
 * @param {!proto.envoy.config.core.v3.SocketOption.SocketState} value
 * @return {!proto.envoy.config.core.v3.SocketOption} returns this
 */
proto.envoy.config.core.v3.SocketOption.prototype.setState = function (value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};

goog.object.extend(exports, proto.envoy.config.core.v3);
