import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { ClientReadableStream, RpcError as GrpcWebError } from 'grpc-web';
import React, { FC, useEffect, useState } from 'react';

import { pushError } from '../../../slices/notifications';
import { getErrorMessage } from '../../../utils/errors';

import { PomeriumSessionServiceClient } from 'src/pb/UsersServiceClientPb';
import {
  ImpersonateRequest,
  ListPomeriumSessionsRequest,
  ListPomeriumSessionsResponse,
  PomeriumSession
} from 'src/pb/users_pb';
import { useDispatch, useSelector } from 'src/store';
import { getClient, getMetadata } from 'src/utils/grpc';

type Option = {
  label: string;
  value: string;
};
function mapOptions(
  currentUserID: string,
  sessions: PomeriumSession[]
): Option[] {
  const otherUserSessions = sessions?.filter(
    (s) => s?.getUser()?.getId() !== currentUserID
  );

  return (
    otherUserSessions?.map((s) => ({
      label: s?.getUser() ? s?.getUser()?.getName() : s.getId(),
      value: s.getId()
    })) || []
  );
}

const client = getClient(PomeriumSessionServiceClient);

type SelectSessionState = {
  stream: ClientReadableStream<ListPomeriumSessionsResponse>;
  query: string;
  error: string;
  options: Option[];
  loading: boolean;
  selected: Option;
};
type SelectSessionProps = {
  request: ImpersonateRequest;
  setRequest(request: ImpersonateRequest): void;
};
const SelectSession: FC<SelectSessionProps> = ({ request, setRequest }) => {
  const { session } = useSelector((state) => state);
  const [state, setState] = useState<SelectSessionState>({
    stream: null,
    query: '',
    error: '',
    options: [],
    loading: false,
    selected: null
  });

  useEffect(() => {
    return () => state.stream?.cancel();
  }, []);

  useEffect(() => {
    state.stream?.cancel();
    const stream = client.listPomeriumSessions(
      new ListPomeriumSessionsRequest().setQuery(state.query).setLimit(10),
      getMetadata(session),
      (err: GrpcWebError, res: ListPomeriumSessionsResponse) => {
        setState({
          ...state,
          stream: null,
          query: state.query,
          error: err ? err.message : '',
          options: mapOptions(
            session?.userInfo?.getId(),
            res?.getSessionsList()
          ),
          loading: false
        });
      }
    );
    setState({ ...state, stream: stream, error: '', loading: true });
  }, [state.query]);

  function handleFocus() {
    setState({
      ...state,
      query: '',
      selected: null
    });
  }
  function handleChange(evt: React.ChangeEvent<unknown>, option: Option) {
    setState({
      ...state,
      selected: option
    });
    setRequest(request.clone().setSessionId(option?.value || ''));
  }
  function handleInputChange(
    _: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    value: string
  ) {
    setState({ ...state, query: value });
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Autocomplete<Option>
          options={state.options}
          getOptionLabel={({ label }): string => label || ''}
          isOptionEqualToValue={(option, selected): boolean =>
            option.value === selected.value
          }
          filterOptions={(options) => options}
          value={state.selected}
          onChange={handleChange}
          inputValue={state.query}
          onInputChange={handleInputChange}
          onFocus={handleFocus}
          renderInput={(params): JSX.Element => (
            <TextField {...params} label="Session" />
          )}
        />
      </Grid>
    </Grid>
  );
};

type ImpersonateFormDialogProps = DialogProps;
const ImpersonateFormDialog: FC<ImpersonateFormDialogProps> = (props) => {
  const dispatch = useDispatch();
  const { session } = useSelector((state) => state);
  const [request, setRequest] = useState(new ImpersonateRequest());

  function handleClickCancel(evt: React.MouseEvent) {
    evt.preventDefault();
    handleClose({}, 'backdropClick');
  }

  function handleClickImpersonate(evt: React.MouseEvent) {
    evt.preventDefault();
    client.impersonate(request, getMetadata(session), (err): void => {
      if (err) {
        dispatch(pushError(getErrorMessage(err)));
      } else {
        location.reload();
      }
    });
  }

  function handleClose(
    evt: unknown,
    reason: 'backdropClick' | 'escapeKeyDown'
  ) {
    setRequest(new ImpersonateRequest());
    props?.onClose?.(evt, reason);
  }

  return (
    <Dialog {...props} onClose={handleClose}>
      <DialogTitle>
        <Typography variant="h4">Impersonate</Typography>
        <IconButton color="primary" onClick={handleClickCancel} size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent
        sx={{
          minHeight: '200px',
          minWidth: '500px'
        }}
      >
        <SelectSession request={request} setRequest={setRequest} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClickCancel} variant={'contained'}>
          Cancel
        </Button>
        <Button
          disabled={!request?.getSessionId()}
          color="primary"
          onClick={handleClickImpersonate}
          variant={'contained'}
        >
          Impersonate
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ImpersonateFormDialog;
