/* eslint-disable react/no-array-index-key */

/* eslint-disable react/display-name */
import React, { lazy, Suspense, Fragment, FC } from 'react';
import { Switch, Route } from 'react-router-dom';

import GuestGuard from './components/GuestGuard';
import useNotifier from './hooks/useNotifier';

import AuthGuard from 'src/components/AuthGuard';
import LoadingScreen from 'src/components/LoadingScreen';
import DashboardLayout from 'src/layouts/DashboardLayout';

type RouteDefs = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: RouteDefs;
}[];

const routesConfig = [
  {
    guard: AuthGuard,
    exact: true,
    path: '/',
    component: lazy(() => import('src/views/pages/Index'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/pages/Login'))
  },
  {
    path: '/ui',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: false,
        path: '/ui/',
        component: lazy(() => import('src/views/pages/Branding'))
      }
    ]
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: false,
        path: '/app/management/policies',
        component: lazy(() => import('src/views/pages/Policies'))
      },
      {
        exact: false,
        path: '/app/management/routes',
        component: lazy(() => import('src/views/pages/Routes'))
      },
      {
        exact: false,
        path: '/app/management/service-accounts',
        component: lazy(() => import('src/views/pages/ServiceAccounts'))
      },
      {
        exact: false,
        path: '/app/management/sessions',
        component: lazy(() => import('src/views/pages/Sessions'))
      },
      {
        exact: false,
        path: '/app/management/tls-certs',
        component: lazy(() => import('src/views/pages/KeyChain'))
      },
      {
        exact: false,
        path: '/app/management/settings',
        component: lazy(() => import('src/views/pages/Settings'))
      },
      {
        exact: false,
        path: '/app/management/deployment-history',
        component: lazy(() => import('src/views/pages/DeploymentHistory'))
      },
      {
        exact: false,
        path: '/app/management/namespaces',
        component: lazy(() => import('src/views/pages/Namespaces'))
      },
      {
        exact: false,
        path: '/app/reports/traffic',
        component: lazy(() => import('src/views/pages/TrafficDashboard'))
      },
      {
        exact: false,
        path: `/app/reports/runtime`,
        component: lazy(() => import(`src/views/pages/RuntimeDashboard`))
      },
      {
        exact: false,
        path: `/app/reports/events`,
        component: lazy(() => import(`src/views/pages/Events`))
      },
      {
        exact: false,
        path: `/app/management/devices`,
        component: lazy(() => import(`src/views/pages/Devices`))
      },
      {
        component: lazy(() => import('src/views/errors/NotFoundView'))
      }
    ]
  },
  {
    component: lazy(() => import('src/views/errors/NotFoundView'))
  }
];

const renderRoutes = (routes: RouteDefs = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component || null;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props): JSX.Element => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const Routes: FC = () => {
  useNotifier();
  return <>{renderRoutes(routesConfig)}</>;
};

export default Routes;
