import React, { FC, useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { getNamespaceTree, setCurrentNamespace } from 'src/slices/namespaces';
import { useDispatch, useSelector } from 'src/store';
import useQuery from 'src/utils/useQuery';

const AuthGuard: FC = ({ children }) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const location = useLocation();
  const { session } = useSelector((state) => ({
    session: state.session
  }));

  if (!session.userInfo) {
    return (
      <Redirect
        to={'/login?redirect=' + encodeURIComponent(location.pathname)}
      />
    );
  }

  // for the first time we'll load the namespace tree to set the appropriate namespace id
  const [ready, setReady] = useState(false);
  useEffect(() => {
    (async () => {
      const tree = await dispatch(getNamespaceTree());

      const namespaceID =
        query.get('namespace') ||
        localStorage.getItem('mostRecentlyUsedNamespace') ||
        tree.firstAccessible()?.id;

      const namespaceNode = tree.get(namespaceID);
      if (namespaceNode) {
        await dispatch(setCurrentNamespace(namespaceNode));
      }

      setReady(true);
    })();
  }, []);
  if (!ready) {
    return <></>;
  }

  return <>{children}</>;
};

export default AuthGuard;
