import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector
} from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';

import history from './history';
import rootReducer from './rootReducer';

import { ENABLE_REDUX_DEV_TOOLS } from 'src/constants';

const store = configureStore({
  reducer: rootReducer,
  devTools: ENABLE_REDUX_DEV_TOOLS,
  middleware: getDefaultMiddleware({
    immutableCheck: false, // disabled because protobuf types are modified on `getX`
    serializableCheck: false // disabled because protobuf types aren't serializable
  }).concat(routerMiddleware(history))
});

type RootState = ReturnType<typeof store.getState>;

type AppDispatch = typeof store.dispatch;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export const useDispatch = (): AppDispatch => useReduxDispatch<AppDispatch>();

export default store;
