// source: envoy/config/core/v3/grpc_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var envoy_config_core_v3_base_pb = require('../../../../envoy/config/core/v3/base_pb.js');
goog.object.extend(proto, envoy_config_core_v3_base_pb);
var google_protobuf_any_pb = require('google-protobuf/google/protobuf/any_pb.js');
goog.object.extend(proto, google_protobuf_any_pb);
var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js');
goog.object.extend(proto, google_protobuf_duration_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_struct_pb = require('google-protobuf/google/protobuf/struct_pb.js');
goog.object.extend(proto, google_protobuf_struct_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var udpa_annotations_sensitive_pb = require('../../../../udpa/annotations/sensitive_pb.js');
goog.object.extend(proto, udpa_annotations_sensitive_pb);
var udpa_annotations_status_pb = require('../../../../udpa/annotations/status_pb.js');
goog.object.extend(proto, udpa_annotations_status_pb);
var udpa_annotations_versioning_pb = require('../../../../udpa/annotations/versioning_pb.js');
goog.object.extend(proto, udpa_annotations_versioning_pb);
var validate_validate_pb = require('../../../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.envoy.config.core.v3.GrpcService', null, global);
goog.exportSymbol(
  'proto.envoy.config.core.v3.GrpcService.EnvoyGrpc',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.core.v3.GrpcService.GoogleGrpc',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.CredentialSpecifierCase',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.GoogleIAMCredentials',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin.ConfigTypeCase',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.ServiceAccountJWTAccessCredentials',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value.ValueSpecifierCase',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials.CredentialSpecifierCase',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.core.v3.GrpcService.GoogleGrpc.GoogleLocalCredentials',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.core.v3.GrpcService.TargetSpecifierCase',
  null,
  global
);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.GrpcService = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.envoy.config.core.v3.GrpcService.repeatedFields_,
    proto.envoy.config.core.v3.GrpcService.oneofGroups_
  );
};
goog.inherits(proto.envoy.config.core.v3.GrpcService, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.GrpcService.displayName =
    'proto.envoy.config.core.v3.GrpcService';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.GrpcService.EnvoyGrpc = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.envoy.config.core.v3.GrpcService.EnvoyGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.GrpcService.EnvoyGrpc.displayName =
    'proto.envoy.config.core.v3.GrpcService.EnvoyGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.envoy.config.core.v3.GrpcService.GoogleGrpc.repeatedFields_,
    null
  );
};
goog.inherits(proto.envoy.config.core.v3.GrpcService.GoogleGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.displayName =
    'proto.envoy.config.core.v3.GrpcService.GoogleGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials = function (
  opt_data
) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials.displayName =
    'proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.GoogleLocalCredentials =
  function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  };
goog.inherits(
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.GoogleLocalCredentials,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.GoogleLocalCredentials.displayName =
    'proto.envoy.config.core.v3.GrpcService.GoogleGrpc.GoogleLocalCredentials';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials =
  function (opt_data) {
    jspb.Message.initialize(
      this,
      opt_data,
      0,
      -1,
      null,
      proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials
        .oneofGroups_
    );
  };
goog.inherits(
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials.displayName =
    'proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials = function (
  opt_data
) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    null,
    proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials
      .oneofGroups_
  );
};
goog.inherits(
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.displayName =
    'proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.ServiceAccountJWTAccessCredentials =
  function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  };
goog.inherits(
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials
    .ServiceAccountJWTAccessCredentials,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.ServiceAccountJWTAccessCredentials.displayName =
    'proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.ServiceAccountJWTAccessCredentials';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.GoogleIAMCredentials =
  function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  };
goog.inherits(
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials
    .GoogleIAMCredentials,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.GoogleIAMCredentials.displayName =
    'proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.GoogleIAMCredentials';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin =
  function (opt_data) {
    jspb.Message.initialize(
      this,
      opt_data,
      0,
      -1,
      null,
      proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials
        .MetadataCredentialsFromPlugin.oneofGroups_
    );
  };
goog.inherits(
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials
    .MetadataCredentialsFromPlugin,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin.displayName =
    'proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService =
  function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  };
goog.inherits(
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService.displayName =
    'proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs = function (
  opt_data
) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.displayName =
    'proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value = function (
  opt_data
) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    null,
    proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value
      .oneofGroups_
  );
};
goog.inherits(
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value.displayName =
    'proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.envoy.config.core.v3.GrpcService.repeatedFields_ = [5];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.envoy.config.core.v3.GrpcService.oneofGroups_ = [[1, 2]];

/**
 * @enum {number}
 */
proto.envoy.config.core.v3.GrpcService.TargetSpecifierCase = {
  TARGET_SPECIFIER_NOT_SET: 0,
  ENVOY_GRPC: 1,
  GOOGLE_GRPC: 2
};

/**
 * @return {proto.envoy.config.core.v3.GrpcService.TargetSpecifierCase}
 */
proto.envoy.config.core.v3.GrpcService.prototype.getTargetSpecifierCase =
  function () {
    return /** @type {proto.envoy.config.core.v3.GrpcService.TargetSpecifierCase} */ (
      jspb.Message.computeOneofCase(
        this,
        proto.envoy.config.core.v3.GrpcService.oneofGroups_[0]
      )
    );
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.GrpcService.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.config.core.v3.GrpcService.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.GrpcService} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.GrpcService.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        envoyGrpc:
          (f = msg.getEnvoyGrpc()) &&
          proto.envoy.config.core.v3.GrpcService.EnvoyGrpc.toObject(
            includeInstance,
            f
          ),
        googleGrpc:
          (f = msg.getGoogleGrpc()) &&
          proto.envoy.config.core.v3.GrpcService.GoogleGrpc.toObject(
            includeInstance,
            f
          ),
        timeout:
          (f = msg.getTimeout()) &&
          google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
        initialMetadataList: jspb.Message.toObjectList(
          msg.getInitialMetadataList(),
          envoy_config_core_v3_base_pb.HeaderValue.toObject,
          includeInstance
        )
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.GrpcService}
 */
proto.envoy.config.core.v3.GrpcService.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.GrpcService();
  return proto.envoy.config.core.v3.GrpcService.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.GrpcService} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.GrpcService}
 */
proto.envoy.config.core.v3.GrpcService.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.envoy.config.core.v3.GrpcService.EnvoyGrpc();
        reader.readMessage(
          value,
          proto.envoy.config.core.v3.GrpcService.EnvoyGrpc
            .deserializeBinaryFromReader
        );
        msg.setEnvoyGrpc(value);
        break;
      case 2:
        var value = new proto.envoy.config.core.v3.GrpcService.GoogleGrpc();
        reader.readMessage(
          value,
          proto.envoy.config.core.v3.GrpcService.GoogleGrpc
            .deserializeBinaryFromReader
        );
        msg.setGoogleGrpc(value);
        break;
      case 3:
        var value = new google_protobuf_duration_pb.Duration();
        reader.readMessage(
          value,
          google_protobuf_duration_pb.Duration.deserializeBinaryFromReader
        );
        msg.setTimeout(value);
        break;
      case 5:
        var value = new envoy_config_core_v3_base_pb.HeaderValue();
        reader.readMessage(
          value,
          envoy_config_core_v3_base_pb.HeaderValue.deserializeBinaryFromReader
        );
        msg.addInitialMetadata(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.GrpcService.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.envoy.config.core.v3.GrpcService.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.GrpcService} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.GrpcService.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getEnvoyGrpc();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.envoy.config.core.v3.GrpcService.EnvoyGrpc.serializeBinaryToWriter
    );
  }
  f = message.getGoogleGrpc();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.envoy.config.core.v3.GrpcService.GoogleGrpc.serializeBinaryToWriter
    );
  }
  f = message.getTimeout();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getInitialMetadataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      envoy_config_core_v3_base_pb.HeaderValue.serializeBinaryToWriter
    );
  }
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.GrpcService.EnvoyGrpc.prototype.toObject =
    function (opt_includeInstance) {
      return proto.envoy.config.core.v3.GrpcService.EnvoyGrpc.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.GrpcService.EnvoyGrpc} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.GrpcService.EnvoyGrpc.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        clusterName: jspb.Message.getFieldWithDefault(msg, 1, ''),
        authority: jspb.Message.getFieldWithDefault(msg, 2, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.GrpcService.EnvoyGrpc}
 */
proto.envoy.config.core.v3.GrpcService.EnvoyGrpc.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.GrpcService.EnvoyGrpc();
  return proto.envoy.config.core.v3.GrpcService.EnvoyGrpc.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.GrpcService.EnvoyGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.GrpcService.EnvoyGrpc}
 */
proto.envoy.config.core.v3.GrpcService.EnvoyGrpc.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setClusterName(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setAuthority(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.GrpcService.EnvoyGrpc.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.GrpcService.EnvoyGrpc.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.GrpcService.EnvoyGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.GrpcService.EnvoyGrpc.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getClusterName();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
    f = message.getAuthority();
    if (f.length > 0) {
      writer.writeString(2, f);
    }
  };

/**
 * optional string cluster_name = 1;
 * @return {string}
 */
proto.envoy.config.core.v3.GrpcService.EnvoyGrpc.prototype.getClusterName =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.GrpcService.EnvoyGrpc} returns this
 */
proto.envoy.config.core.v3.GrpcService.EnvoyGrpc.prototype.setClusterName =
  function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
  };

/**
 * optional string authority = 2;
 * @return {string}
 */
proto.envoy.config.core.v3.GrpcService.EnvoyGrpc.prototype.getAuthority =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 2, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.GrpcService.EnvoyGrpc} returns this
 */
proto.envoy.config.core.v3.GrpcService.EnvoyGrpc.prototype.setAuthority =
  function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
  };

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.repeatedFields_ = [3];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.prototype.toObject =
    function (opt_includeInstance) {
      return proto.envoy.config.core.v3.GrpcService.GoogleGrpc.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        targetUri: jspb.Message.getFieldWithDefault(msg, 1, ''),
        channelCredentials:
          (f = msg.getChannelCredentials()) &&
          proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials.toObject(
            includeInstance,
            f
          ),
        callCredentialsList: jspb.Message.toObjectList(
          msg.getCallCredentialsList(),
          proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials
            .toObject,
          includeInstance
        ),
        statPrefix: jspb.Message.getFieldWithDefault(msg, 4, ''),
        credentialsFactoryName: jspb.Message.getFieldWithDefault(msg, 5, ''),
        config:
          (f = msg.getConfig()) &&
          google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
        perStreamBufferLimitBytes:
          (f = msg.getPerStreamBufferLimitBytes()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
        channelArgs:
          (f = msg.getChannelArgs()) &&
          proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.toObject(
            includeInstance,
            f
          )
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.GrpcService.GoogleGrpc();
  return proto.envoy.config.core.v3.GrpcService.GoogleGrpc.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setTargetUri(value);
          break;
        case 2:
          var value =
            new proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials();
          reader.readMessage(
            value,
            proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials
              .deserializeBinaryFromReader
          );
          msg.setChannelCredentials(value);
          break;
        case 3:
          var value =
            new proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials();
          reader.readMessage(
            value,
            proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials
              .deserializeBinaryFromReader
          );
          msg.addCallCredentials(value);
          break;
        case 4:
          var value = /** @type {string} */ (reader.readString());
          msg.setStatPrefix(value);
          break;
        case 5:
          var value = /** @type {string} */ (reader.readString());
          msg.setCredentialsFactoryName(value);
          break;
        case 6:
          var value = new google_protobuf_struct_pb.Struct();
          reader.readMessage(
            value,
            google_protobuf_struct_pb.Struct.deserializeBinaryFromReader
          );
          msg.setConfig(value);
          break;
        case 7:
          var value = new google_protobuf_wrappers_pb.UInt32Value();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
          );
          msg.setPerStreamBufferLimitBytes(value);
          break;
        case 8:
          var value =
            new proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs();
          reader.readMessage(
            value,
            proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs
              .deserializeBinaryFromReader
          );
          msg.setChannelArgs(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.GrpcService.GoogleGrpc.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getTargetUri();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
    f = message.getChannelCredentials();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials
          .serializeBinaryToWriter
      );
    }
    f = message.getCallCredentialsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        3,
        f,
        proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials
          .serializeBinaryToWriter
      );
    }
    f = message.getStatPrefix();
    if (f.length > 0) {
      writer.writeString(4, f);
    }
    f = message.getCredentialsFactoryName();
    if (f.length > 0) {
      writer.writeString(5, f);
    }
    f = message.getConfig();
    if (f != null) {
      writer.writeMessage(
        6,
        f,
        google_protobuf_struct_pb.Struct.serializeBinaryToWriter
      );
    }
    f = message.getPerStreamBufferLimitBytes();
    if (f != null) {
      writer.writeMessage(
        7,
        f,
        google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
      );
    }
    f = message.getChannelArgs();
    if (f != null) {
      writer.writeMessage(
        8,
        f,
        proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs
          .serializeBinaryToWriter
      );
    }
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials.prototype.toObject =
    function (opt_includeInstance) {
      return proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials.toObject =
    function (includeInstance, msg) {
      var f,
        obj = {
          rootCerts:
            (f = msg.getRootCerts()) &&
            envoy_config_core_v3_base_pb.DataSource.toObject(
              includeInstance,
              f
            ),
          privateKey:
            (f = msg.getPrivateKey()) &&
            envoy_config_core_v3_base_pb.DataSource.toObject(
              includeInstance,
              f
            ),
          certChain:
            (f = msg.getCertChain()) &&
            envoy_config_core_v3_base_pb.DataSource.toObject(includeInstance, f)
        };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }
      return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg =
      new proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials();
    return proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new envoy_config_core_v3_base_pb.DataSource();
          reader.readMessage(
            value,
            envoy_config_core_v3_base_pb.DataSource.deserializeBinaryFromReader
          );
          msg.setRootCerts(value);
          break;
        case 2:
          var value = new envoy_config_core_v3_base_pb.DataSource();
          reader.readMessage(
            value,
            envoy_config_core_v3_base_pb.DataSource.deserializeBinaryFromReader
          );
          msg.setPrivateKey(value);
          break;
        case 3:
          var value = new envoy_config_core_v3_base_pb.DataSource();
          reader.readMessage(
            value,
            envoy_config_core_v3_base_pb.DataSource.deserializeBinaryFromReader
          );
          msg.setCertChain(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getRootCerts();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        envoy_config_core_v3_base_pb.DataSource.serializeBinaryToWriter
      );
    }
    f = message.getPrivateKey();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        envoy_config_core_v3_base_pb.DataSource.serializeBinaryToWriter
      );
    }
    f = message.getCertChain();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        envoy_config_core_v3_base_pb.DataSource.serializeBinaryToWriter
      );
    }
  };

/**
 * optional DataSource root_certs = 1;
 * @return {?proto.envoy.config.core.v3.DataSource}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials.prototype.getRootCerts =
  function () {
    return /** @type{?proto.envoy.config.core.v3.DataSource} */ (
      jspb.Message.getWrapperField(
        this,
        envoy_config_core_v3_base_pb.DataSource,
        1
      )
    );
  };

/**
 * @param {?proto.envoy.config.core.v3.DataSource|undefined} value
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials.prototype.setRootCerts =
  function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials.prototype.clearRootCerts =
  function () {
    return this.setRootCerts(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials.prototype.hasRootCerts =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

/**
 * optional DataSource private_key = 2;
 * @return {?proto.envoy.config.core.v3.DataSource}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials.prototype.getPrivateKey =
  function () {
    return /** @type{?proto.envoy.config.core.v3.DataSource} */ (
      jspb.Message.getWrapperField(
        this,
        envoy_config_core_v3_base_pb.DataSource,
        2
      )
    );
  };

/**
 * @param {?proto.envoy.config.core.v3.DataSource|undefined} value
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials.prototype.setPrivateKey =
  function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials.prototype.clearPrivateKey =
  function () {
    return this.setPrivateKey(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials.prototype.hasPrivateKey =
  function () {
    return jspb.Message.getField(this, 2) != null;
  };

/**
 * optional DataSource cert_chain = 3;
 * @return {?proto.envoy.config.core.v3.DataSource}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials.prototype.getCertChain =
  function () {
    return /** @type{?proto.envoy.config.core.v3.DataSource} */ (
      jspb.Message.getWrapperField(
        this,
        envoy_config_core_v3_base_pb.DataSource,
        3
      )
    );
  };

/**
 * @param {?proto.envoy.config.core.v3.DataSource|undefined} value
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials.prototype.setCertChain =
  function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials.prototype.clearCertChain =
  function () {
    return this.setCertChain(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials.prototype.hasCertChain =
  function () {
    return jspb.Message.getField(this, 3) != null;
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.GoogleLocalCredentials.prototype.toObject =
    function (opt_includeInstance) {
      return proto.envoy.config.core.v3.GrpcService.GoogleGrpc.GoogleLocalCredentials.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.GoogleLocalCredentials} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.GoogleLocalCredentials.toObject =
    function (includeInstance, msg) {
      var f,
        obj = {};

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }
      return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.GoogleLocalCredentials}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.GoogleLocalCredentials.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg =
      new proto.envoy.config.core.v3.GrpcService.GoogleGrpc.GoogleLocalCredentials();
    return proto.envoy.config.core.v3.GrpcService.GoogleGrpc.GoogleLocalCredentials.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.GoogleLocalCredentials} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.GoogleLocalCredentials}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.GoogleLocalCredentials.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.GoogleLocalCredentials.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.GrpcService.GoogleGrpc.GoogleLocalCredentials.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.GoogleLocalCredentials} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.GoogleLocalCredentials.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
  };

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials.oneofGroups_ =
  [[1, 2, 3]];

/**
 * @enum {number}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials.CredentialSpecifierCase =
  {
    CREDENTIAL_SPECIFIER_NOT_SET: 0,
    SSL_CREDENTIALS: 1,
    GOOGLE_DEFAULT: 2,
    LOCAL_CREDENTIALS: 3
  };

/**
 * @return {proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials.CredentialSpecifierCase}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials.prototype.getCredentialSpecifierCase =
  function () {
    return /** @type {proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials.CredentialSpecifierCase} */ (
      jspb.Message.computeOneofCase(
        this,
        proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials
          .oneofGroups_[0]
      )
    );
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials.prototype.toObject =
    function (opt_includeInstance) {
      return proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials.toObject =
    function (includeInstance, msg) {
      var f,
        obj = {
          sslCredentials:
            (f = msg.getSslCredentials()) &&
            proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials.toObject(
              includeInstance,
              f
            ),
          googleDefault:
            (f = msg.getGoogleDefault()) &&
            google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
          localCredentials:
            (f = msg.getLocalCredentials()) &&
            proto.envoy.config.core.v3.GrpcService.GoogleGrpc.GoogleLocalCredentials.toObject(
              includeInstance,
              f
            )
        };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }
      return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg =
      new proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials();
    return proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value =
            new proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials();
          reader.readMessage(
            value,
            proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials
              .deserializeBinaryFromReader
          );
          msg.setSslCredentials(value);
          break;
        case 2:
          var value = new google_protobuf_empty_pb.Empty();
          reader.readMessage(
            value,
            google_protobuf_empty_pb.Empty.deserializeBinaryFromReader
          );
          msg.setGoogleDefault(value);
          break;
        case 3:
          var value =
            new proto.envoy.config.core.v3.GrpcService.GoogleGrpc.GoogleLocalCredentials();
          reader.readMessage(
            value,
            proto.envoy.config.core.v3.GrpcService.GoogleGrpc
              .GoogleLocalCredentials.deserializeBinaryFromReader
          );
          msg.setLocalCredentials(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getSslCredentials();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials
          .serializeBinaryToWriter
      );
    }
    f = message.getGoogleDefault();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        google_protobuf_empty_pb.Empty.serializeBinaryToWriter
      );
    }
    f = message.getLocalCredentials();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        proto.envoy.config.core.v3.GrpcService.GoogleGrpc.GoogleLocalCredentials
          .serializeBinaryToWriter
      );
    }
  };

/**
 * optional SslCredentials ssl_credentials = 1;
 * @return {?proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials.prototype.getSslCredentials =
  function () {
    return /** @type{?proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials} */ (
      jspb.Message.getWrapperField(
        this,
        proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials,
        1
      )
    );
  };

/**
 * @param {?proto.envoy.config.core.v3.GrpcService.GoogleGrpc.SslCredentials|undefined} value
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials.prototype.setSslCredentials =
  function (value) {
    return jspb.Message.setOneofWrapperField(
      this,
      1,
      proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials
        .oneofGroups_[0],
      value
    );
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials.prototype.clearSslCredentials =
  function () {
    return this.setSslCredentials(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials.prototype.hasSslCredentials =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

/**
 * optional google.protobuf.Empty google_default = 2;
 * @return {?proto.google.protobuf.Empty}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials.prototype.getGoogleDefault =
  function () {
    return /** @type{?proto.google.protobuf.Empty} */ (
      jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 2)
    );
  };

/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials.prototype.setGoogleDefault =
  function (value) {
    return jspb.Message.setOneofWrapperField(
      this,
      2,
      proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials
        .oneofGroups_[0],
      value
    );
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials.prototype.clearGoogleDefault =
  function () {
    return this.setGoogleDefault(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials.prototype.hasGoogleDefault =
  function () {
    return jspb.Message.getField(this, 2) != null;
  };

/**
 * optional GoogleLocalCredentials local_credentials = 3;
 * @return {?proto.envoy.config.core.v3.GrpcService.GoogleGrpc.GoogleLocalCredentials}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials.prototype.getLocalCredentials =
  function () {
    return /** @type{?proto.envoy.config.core.v3.GrpcService.GoogleGrpc.GoogleLocalCredentials} */ (
      jspb.Message.getWrapperField(
        this,
        proto.envoy.config.core.v3.GrpcService.GoogleGrpc
          .GoogleLocalCredentials,
        3
      )
    );
  };

/**
 * @param {?proto.envoy.config.core.v3.GrpcService.GoogleGrpc.GoogleLocalCredentials|undefined} value
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials.prototype.setLocalCredentials =
  function (value) {
    return jspb.Message.setOneofWrapperField(
      this,
      3,
      proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials
        .oneofGroups_[0],
      value
    );
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials.prototype.clearLocalCredentials =
  function () {
    return this.setLocalCredentials(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials.prototype.hasLocalCredentials =
  function () {
    return jspb.Message.getField(this, 3) != null;
  };

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.oneofGroups_ =
  [[1, 2, 3, 4, 5, 6, 7]];

/**
 * @enum {number}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.CredentialSpecifierCase =
  {
    CREDENTIAL_SPECIFIER_NOT_SET: 0,
    ACCESS_TOKEN: 1,
    GOOGLE_COMPUTE_ENGINE: 2,
    GOOGLE_REFRESH_TOKEN: 3,
    SERVICE_ACCOUNT_JWT_ACCESS: 4,
    GOOGLE_IAM: 5,
    FROM_PLUGIN: 6,
    STS_SERVICE: 7
  };

/**
 * @return {proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.CredentialSpecifierCase}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.prototype.getCredentialSpecifierCase =
  function () {
    return /** @type {proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.CredentialSpecifierCase} */ (
      jspb.Message.computeOneofCase(
        this,
        proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials
          .oneofGroups_[0]
      )
    );
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.prototype.toObject =
    function (opt_includeInstance) {
      return proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.toObject =
    function (includeInstance, msg) {
      var f,
        obj = {
          accessToken: jspb.Message.getFieldWithDefault(msg, 1, ''),
          googleComputeEngine:
            (f = msg.getGoogleComputeEngine()) &&
            google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
          googleRefreshToken: jspb.Message.getFieldWithDefault(msg, 3, ''),
          serviceAccountJwtAccess:
            (f = msg.getServiceAccountJwtAccess()) &&
            proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.ServiceAccountJWTAccessCredentials.toObject(
              includeInstance,
              f
            ),
          googleIam:
            (f = msg.getGoogleIam()) &&
            proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.GoogleIAMCredentials.toObject(
              includeInstance,
              f
            ),
          fromPlugin:
            (f = msg.getFromPlugin()) &&
            proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin.toObject(
              includeInstance,
              f
            ),
          stsService:
            (f = msg.getStsService()) &&
            proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService.toObject(
              includeInstance,
              f
            )
        };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }
      return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg =
      new proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials();
    return proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setAccessToken(value);
          break;
        case 2:
          var value = new google_protobuf_empty_pb.Empty();
          reader.readMessage(
            value,
            google_protobuf_empty_pb.Empty.deserializeBinaryFromReader
          );
          msg.setGoogleComputeEngine(value);
          break;
        case 3:
          var value = /** @type {string} */ (reader.readString());
          msg.setGoogleRefreshToken(value);
          break;
        case 4:
          var value =
            new proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.ServiceAccountJWTAccessCredentials();
          reader.readMessage(
            value,
            proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials
              .ServiceAccountJWTAccessCredentials.deserializeBinaryFromReader
          );
          msg.setServiceAccountJwtAccess(value);
          break;
        case 5:
          var value =
            new proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.GoogleIAMCredentials();
          reader.readMessage(
            value,
            proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials
              .GoogleIAMCredentials.deserializeBinaryFromReader
          );
          msg.setGoogleIam(value);
          break;
        case 6:
          var value =
            new proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin();
          reader.readMessage(
            value,
            proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials
              .MetadataCredentialsFromPlugin.deserializeBinaryFromReader
          );
          msg.setFromPlugin(value);
          break;
        case 7:
          var value =
            new proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService();
          reader.readMessage(
            value,
            proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials
              .StsService.deserializeBinaryFromReader
          );
          msg.setStsService(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = /** @type {string} */ (jspb.Message.getField(message, 1));
    if (f != null) {
      writer.writeString(1, f);
    }
    f = message.getGoogleComputeEngine();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        google_protobuf_empty_pb.Empty.serializeBinaryToWriter
      );
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 3));
    if (f != null) {
      writer.writeString(3, f);
    }
    f = message.getServiceAccountJwtAccess();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials
          .ServiceAccountJWTAccessCredentials.serializeBinaryToWriter
      );
    }
    f = message.getGoogleIam();
    if (f != null) {
      writer.writeMessage(
        5,
        f,
        proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials
          .GoogleIAMCredentials.serializeBinaryToWriter
      );
    }
    f = message.getFromPlugin();
    if (f != null) {
      writer.writeMessage(
        6,
        f,
        proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials
          .MetadataCredentialsFromPlugin.serializeBinaryToWriter
      );
    }
    f = message.getStsService();
    if (f != null) {
      writer.writeMessage(
        7,
        f,
        proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials
          .StsService.serializeBinaryToWriter
      );
    }
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.ServiceAccountJWTAccessCredentials.prototype.toObject =
    function (opt_includeInstance) {
      return proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.ServiceAccountJWTAccessCredentials.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.ServiceAccountJWTAccessCredentials} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.ServiceAccountJWTAccessCredentials.toObject =
    function (includeInstance, msg) {
      var f,
        obj = {
          jsonKey: jspb.Message.getFieldWithDefault(msg, 1, ''),
          tokenLifetimeSeconds: jspb.Message.getFieldWithDefault(msg, 2, 0)
        };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }
      return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.ServiceAccountJWTAccessCredentials}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.ServiceAccountJWTAccessCredentials.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg =
      new proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.ServiceAccountJWTAccessCredentials();
    return proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.ServiceAccountJWTAccessCredentials.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.ServiceAccountJWTAccessCredentials} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.ServiceAccountJWTAccessCredentials}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.ServiceAccountJWTAccessCredentials.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setJsonKey(value);
          break;
        case 2:
          var value = /** @type {number} */ (reader.readUint64());
          msg.setTokenLifetimeSeconds(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.ServiceAccountJWTAccessCredentials.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.ServiceAccountJWTAccessCredentials.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.ServiceAccountJWTAccessCredentials} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.ServiceAccountJWTAccessCredentials.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getJsonKey();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
    f = message.getTokenLifetimeSeconds();
    if (f !== 0) {
      writer.writeUint64(2, f);
    }
  };

/**
 * optional string json_key = 1;
 * @return {string}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.ServiceAccountJWTAccessCredentials.prototype.getJsonKey =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.ServiceAccountJWTAccessCredentials} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.ServiceAccountJWTAccessCredentials.prototype.setJsonKey =
  function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
  };

/**
 * optional uint64 token_lifetime_seconds = 2;
 * @return {number}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.ServiceAccountJWTAccessCredentials.prototype.getTokenLifetimeSeconds =
  function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };

/**
 * @param {number} value
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.ServiceAccountJWTAccessCredentials} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.ServiceAccountJWTAccessCredentials.prototype.setTokenLifetimeSeconds =
  function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.GoogleIAMCredentials.prototype.toObject =
    function (opt_includeInstance) {
      return proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.GoogleIAMCredentials.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.GoogleIAMCredentials} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.GoogleIAMCredentials.toObject =
    function (includeInstance, msg) {
      var f,
        obj = {
          authorizationToken: jspb.Message.getFieldWithDefault(msg, 1, ''),
          authoritySelector: jspb.Message.getFieldWithDefault(msg, 2, '')
        };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }
      return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.GoogleIAMCredentials}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.GoogleIAMCredentials.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg =
      new proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.GoogleIAMCredentials();
    return proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.GoogleIAMCredentials.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.GoogleIAMCredentials} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.GoogleIAMCredentials}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.GoogleIAMCredentials.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setAuthorizationToken(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setAuthoritySelector(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.GoogleIAMCredentials.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.GoogleIAMCredentials.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.GoogleIAMCredentials} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.GoogleIAMCredentials.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getAuthorizationToken();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
    f = message.getAuthoritySelector();
    if (f.length > 0) {
      writer.writeString(2, f);
    }
  };

/**
 * optional string authorization_token = 1;
 * @return {string}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.GoogleIAMCredentials.prototype.getAuthorizationToken =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.GoogleIAMCredentials} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.GoogleIAMCredentials.prototype.setAuthorizationToken =
  function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
  };

/**
 * optional string authority_selector = 2;
 * @return {string}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.GoogleIAMCredentials.prototype.getAuthoritySelector =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 2, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.GoogleIAMCredentials} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.GoogleIAMCredentials.prototype.setAuthoritySelector =
  function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
  };

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin.oneofGroups_ =
  [[3]];

/**
 * @enum {number}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin.ConfigTypeCase =
  {
    CONFIG_TYPE_NOT_SET: 0,
    TYPED_CONFIG: 3
  };

/**
 * @return {proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin.ConfigTypeCase}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin.prototype.getConfigTypeCase =
  function () {
    return /** @type {proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin.ConfigTypeCase} */ (
      jspb.Message.computeOneofCase(
        this,
        proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials
          .MetadataCredentialsFromPlugin.oneofGroups_[0]
      )
    );
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin.prototype.toObject =
    function (opt_includeInstance) {
      return proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin.toObject =
    function (includeInstance, msg) {
      var f,
        obj = {
          name: jspb.Message.getFieldWithDefault(msg, 1, ''),
          typedConfig:
            (f = msg.getTypedConfig()) &&
            google_protobuf_any_pb.Any.toObject(includeInstance, f)
        };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }
      return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg =
      new proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin();
    return proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setName(value);
          break;
        case 3:
          var value = new google_protobuf_any_pb.Any();
          reader.readMessage(
            value,
            google_protobuf_any_pb.Any.deserializeBinaryFromReader
          );
          msg.setTypedConfig(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
    f = message.getTypedConfig();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        google_protobuf_any_pb.Any.serializeBinaryToWriter
      );
    }
  };

/**
 * optional string name = 1;
 * @return {string}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin.prototype.getName =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin.prototype.setName =
  function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
  };

/**
 * optional google.protobuf.Any typed_config = 3;
 * @return {?proto.google.protobuf.Any}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin.prototype.getTypedConfig =
  function () {
    return /** @type{?proto.google.protobuf.Any} */ (
      jspb.Message.getWrapperField(this, google_protobuf_any_pb.Any, 3)
    );
  };

/**
 * @param {?proto.google.protobuf.Any|undefined} value
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin.prototype.setTypedConfig =
  function (value) {
    return jspb.Message.setOneofWrapperField(
      this,
      3,
      proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials
        .MetadataCredentialsFromPlugin.oneofGroups_[0],
      value
    );
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin.prototype.clearTypedConfig =
  function () {
    return this.setTypedConfig(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin.prototype.hasTypedConfig =
  function () {
    return jspb.Message.getField(this, 3) != null;
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService.prototype.toObject =
    function (opt_includeInstance) {
      return proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService.toObject =
    function (includeInstance, msg) {
      var f,
        obj = {
          tokenExchangeServiceUri: jspb.Message.getFieldWithDefault(msg, 1, ''),
          resource: jspb.Message.getFieldWithDefault(msg, 2, ''),
          audience: jspb.Message.getFieldWithDefault(msg, 3, ''),
          scope: jspb.Message.getFieldWithDefault(msg, 4, ''),
          requestedTokenType: jspb.Message.getFieldWithDefault(msg, 5, ''),
          subjectTokenPath: jspb.Message.getFieldWithDefault(msg, 6, ''),
          subjectTokenType: jspb.Message.getFieldWithDefault(msg, 7, ''),
          actorTokenPath: jspb.Message.getFieldWithDefault(msg, 8, ''),
          actorTokenType: jspb.Message.getFieldWithDefault(msg, 9, '')
        };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }
      return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg =
      new proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService();
    return proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setTokenExchangeServiceUri(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setResource(value);
          break;
        case 3:
          var value = /** @type {string} */ (reader.readString());
          msg.setAudience(value);
          break;
        case 4:
          var value = /** @type {string} */ (reader.readString());
          msg.setScope(value);
          break;
        case 5:
          var value = /** @type {string} */ (reader.readString());
          msg.setRequestedTokenType(value);
          break;
        case 6:
          var value = /** @type {string} */ (reader.readString());
          msg.setSubjectTokenPath(value);
          break;
        case 7:
          var value = /** @type {string} */ (reader.readString());
          msg.setSubjectTokenType(value);
          break;
        case 8:
          var value = /** @type {string} */ (reader.readString());
          msg.setActorTokenPath(value);
          break;
        case 9:
          var value = /** @type {string} */ (reader.readString());
          msg.setActorTokenType(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getTokenExchangeServiceUri();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
    f = message.getResource();
    if (f.length > 0) {
      writer.writeString(2, f);
    }
    f = message.getAudience();
    if (f.length > 0) {
      writer.writeString(3, f);
    }
    f = message.getScope();
    if (f.length > 0) {
      writer.writeString(4, f);
    }
    f = message.getRequestedTokenType();
    if (f.length > 0) {
      writer.writeString(5, f);
    }
    f = message.getSubjectTokenPath();
    if (f.length > 0) {
      writer.writeString(6, f);
    }
    f = message.getSubjectTokenType();
    if (f.length > 0) {
      writer.writeString(7, f);
    }
    f = message.getActorTokenPath();
    if (f.length > 0) {
      writer.writeString(8, f);
    }
    f = message.getActorTokenType();
    if (f.length > 0) {
      writer.writeString(9, f);
    }
  };

/**
 * optional string token_exchange_service_uri = 1;
 * @return {string}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService.prototype.getTokenExchangeServiceUri =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService.prototype.setTokenExchangeServiceUri =
  function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
  };

/**
 * optional string resource = 2;
 * @return {string}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService.prototype.getResource =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 2, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService.prototype.setResource =
  function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
  };

/**
 * optional string audience = 3;
 * @return {string}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService.prototype.getAudience =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 3, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService.prototype.setAudience =
  function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
  };

/**
 * optional string scope = 4;
 * @return {string}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService.prototype.getScope =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 4, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService.prototype.setScope =
  function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
  };

/**
 * optional string requested_token_type = 5;
 * @return {string}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService.prototype.getRequestedTokenType =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 5, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService.prototype.setRequestedTokenType =
  function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
  };

/**
 * optional string subject_token_path = 6;
 * @return {string}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService.prototype.getSubjectTokenPath =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 6, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService.prototype.setSubjectTokenPath =
  function (value) {
    return jspb.Message.setProto3StringField(this, 6, value);
  };

/**
 * optional string subject_token_type = 7;
 * @return {string}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService.prototype.getSubjectTokenType =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 7, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService.prototype.setSubjectTokenType =
  function (value) {
    return jspb.Message.setProto3StringField(this, 7, value);
  };

/**
 * optional string actor_token_path = 8;
 * @return {string}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService.prototype.getActorTokenPath =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 8, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService.prototype.setActorTokenPath =
  function (value) {
    return jspb.Message.setProto3StringField(this, 8, value);
  };

/**
 * optional string actor_token_type = 9;
 * @return {string}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService.prototype.getActorTokenType =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 9, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService.prototype.setActorTokenType =
  function (value) {
    return jspb.Message.setProto3StringField(this, 9, value);
  };

/**
 * optional string access_token = 1;
 * @return {string}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.prototype.getAccessToken =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.prototype.setAccessToken =
  function (value) {
    return jspb.Message.setOneofField(
      this,
      1,
      proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials
        .oneofGroups_[0],
      value
    );
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.prototype.clearAccessToken =
  function () {
    return jspb.Message.setOneofField(
      this,
      1,
      proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials
        .oneofGroups_[0],
      undefined
    );
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.prototype.hasAccessToken =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

/**
 * optional google.protobuf.Empty google_compute_engine = 2;
 * @return {?proto.google.protobuf.Empty}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.prototype.getGoogleComputeEngine =
  function () {
    return /** @type{?proto.google.protobuf.Empty} */ (
      jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 2)
    );
  };

/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.prototype.setGoogleComputeEngine =
  function (value) {
    return jspb.Message.setOneofWrapperField(
      this,
      2,
      proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials
        .oneofGroups_[0],
      value
    );
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.prototype.clearGoogleComputeEngine =
  function () {
    return this.setGoogleComputeEngine(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.prototype.hasGoogleComputeEngine =
  function () {
    return jspb.Message.getField(this, 2) != null;
  };

/**
 * optional string google_refresh_token = 3;
 * @return {string}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.prototype.getGoogleRefreshToken =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 3, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.prototype.setGoogleRefreshToken =
  function (value) {
    return jspb.Message.setOneofField(
      this,
      3,
      proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials
        .oneofGroups_[0],
      value
    );
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.prototype.clearGoogleRefreshToken =
  function () {
    return jspb.Message.setOneofField(
      this,
      3,
      proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials
        .oneofGroups_[0],
      undefined
    );
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.prototype.hasGoogleRefreshToken =
  function () {
    return jspb.Message.getField(this, 3) != null;
  };

/**
 * optional ServiceAccountJWTAccessCredentials service_account_jwt_access = 4;
 * @return {?proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.ServiceAccountJWTAccessCredentials}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.prototype.getServiceAccountJwtAccess =
  function () {
    return /** @type{?proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.ServiceAccountJWTAccessCredentials} */ (
      jspb.Message.getWrapperField(
        this,
        proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials
          .ServiceAccountJWTAccessCredentials,
        4
      )
    );
  };

/**
 * @param {?proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.ServiceAccountJWTAccessCredentials|undefined} value
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.prototype.setServiceAccountJwtAccess =
  function (value) {
    return jspb.Message.setOneofWrapperField(
      this,
      4,
      proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials
        .oneofGroups_[0],
      value
    );
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.prototype.clearServiceAccountJwtAccess =
  function () {
    return this.setServiceAccountJwtAccess(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.prototype.hasServiceAccountJwtAccess =
  function () {
    return jspb.Message.getField(this, 4) != null;
  };

/**
 * optional GoogleIAMCredentials google_iam = 5;
 * @return {?proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.GoogleIAMCredentials}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.prototype.getGoogleIam =
  function () {
    return /** @type{?proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.GoogleIAMCredentials} */ (
      jspb.Message.getWrapperField(
        this,
        proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials
          .GoogleIAMCredentials,
        5
      )
    );
  };

/**
 * @param {?proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.GoogleIAMCredentials|undefined} value
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.prototype.setGoogleIam =
  function (value) {
    return jspb.Message.setOneofWrapperField(
      this,
      5,
      proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials
        .oneofGroups_[0],
      value
    );
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.prototype.clearGoogleIam =
  function () {
    return this.setGoogleIam(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.prototype.hasGoogleIam =
  function () {
    return jspb.Message.getField(this, 5) != null;
  };

/**
 * optional MetadataCredentialsFromPlugin from_plugin = 6;
 * @return {?proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.prototype.getFromPlugin =
  function () {
    return /** @type{?proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin} */ (
      jspb.Message.getWrapperField(
        this,
        proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials
          .MetadataCredentialsFromPlugin,
        6
      )
    );
  };

/**
 * @param {?proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.MetadataCredentialsFromPlugin|undefined} value
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.prototype.setFromPlugin =
  function (value) {
    return jspb.Message.setOneofWrapperField(
      this,
      6,
      proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials
        .oneofGroups_[0],
      value
    );
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.prototype.clearFromPlugin =
  function () {
    return this.setFromPlugin(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.prototype.hasFromPlugin =
  function () {
    return jspb.Message.getField(this, 6) != null;
  };

/**
 * optional StsService sts_service = 7;
 * @return {?proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.prototype.getStsService =
  function () {
    return /** @type{?proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService} */ (
      jspb.Message.getWrapperField(
        this,
        proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials
          .StsService,
        7
      )
    );
  };

/**
 * @param {?proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.StsService|undefined} value
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.prototype.setStsService =
  function (value) {
    return jspb.Message.setOneofWrapperField(
      this,
      7,
      proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials
        .oneofGroups_[0],
      value
    );
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.prototype.clearStsService =
  function () {
    return this.setStsService(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials.prototype.hasStsService =
  function () {
    return jspb.Message.getField(this, 7) != null;
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.prototype.toObject =
    function (opt_includeInstance) {
      return proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.toObject =
    function (includeInstance, msg) {
      var f,
        obj = {
          argsMap: (f = msg.getArgsMap())
            ? f.toObject(
                includeInstance,
                proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs
                  .Value.toObject
              )
            : []
        };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }
      return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg =
      new proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs();
    return proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = msg.getArgsMap();
          reader.readMessage(value, function (message, reader) {
            jspb.Map.deserializeBinary(
              message,
              reader,
              jspb.BinaryReader.prototype.readString,
              jspb.BinaryReader.prototype.readMessage,
              proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs
                .Value.deserializeBinaryFromReader,
              '',
              new proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value()
            );
          });
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getArgsMap(true);
    if (f && f.getLength() > 0) {
      f.serializeBinary(
        1,
        writer,
        jspb.BinaryWriter.prototype.writeString,
        jspb.BinaryWriter.prototype.writeMessage,
        proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value
          .serializeBinaryToWriter
      );
    }
  };

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value.oneofGroups_ =
  [[1, 2]];

/**
 * @enum {number}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value.ValueSpecifierCase =
  {
    VALUE_SPECIFIER_NOT_SET: 0,
    STRING_VALUE: 1,
    INT_VALUE: 2
  };

/**
 * @return {proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value.ValueSpecifierCase}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value.prototype.getValueSpecifierCase =
  function () {
    return /** @type {proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value.ValueSpecifierCase} */ (
      jspb.Message.computeOneofCase(
        this,
        proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value
          .oneofGroups_[0]
      )
    );
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value.prototype.toObject =
    function (opt_includeInstance) {
      return proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value.toObject =
    function (includeInstance, msg) {
      var f,
        obj = {
          stringValue: jspb.Message.getFieldWithDefault(msg, 1, ''),
          intValue: jspb.Message.getFieldWithDefault(msg, 2, 0)
        };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }
      return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg =
      new proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value();
    return proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setStringValue(value);
          break;
        case 2:
          var value = /** @type {number} */ (reader.readInt64());
          msg.setIntValue(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = /** @type {string} */ (jspb.Message.getField(message, 1));
    if (f != null) {
      writer.writeString(1, f);
    }
    f = /** @type {number} */ (jspb.Message.getField(message, 2));
    if (f != null) {
      writer.writeInt64(2, f);
    }
  };

/**
 * optional string string_value = 1;
 * @return {string}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value.prototype.getStringValue =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value.prototype.setStringValue =
  function (value) {
    return jspb.Message.setOneofField(
      this,
      1,
      proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value
        .oneofGroups_[0],
      value
    );
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value.prototype.clearStringValue =
  function () {
    return jspb.Message.setOneofField(
      this,
      1,
      proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value
        .oneofGroups_[0],
      undefined
    );
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value.prototype.hasStringValue =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

/**
 * optional int64 int_value = 2;
 * @return {number}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value.prototype.getIntValue =
  function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };

/**
 * @param {number} value
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value.prototype.setIntValue =
  function (value) {
    return jspb.Message.setOneofField(
      this,
      2,
      proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value
        .oneofGroups_[0],
      value
    );
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value.prototype.clearIntValue =
  function () {
    return jspb.Message.setOneofField(
      this,
      2,
      proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value
        .oneofGroups_[0],
      undefined
    );
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value.prototype.hasIntValue =
  function () {
    return jspb.Message.getField(this, 2) != null;
  };

/**
 * map<string, Value> args = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value>}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.prototype.getArgsMap =
  function (opt_noLazyCreate) {
    return /** @type {!jspb.Map<string,!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value>} */ (
      jspb.Message.getMapField(
        this,
        1,
        opt_noLazyCreate,
        proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.Value
      )
    );
  };

/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs.prototype.clearArgsMap =
  function () {
    this.getArgsMap().clear();
    return this;
  };

/**
 * optional string target_uri = 1;
 * @return {string}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.prototype.getTargetUri =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.prototype.setTargetUri =
  function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
  };

/**
 * optional ChannelCredentials channel_credentials = 2;
 * @return {?proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.prototype.getChannelCredentials =
  function () {
    return /** @type{?proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials} */ (
      jspb.Message.getWrapperField(
        this,
        proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials,
        2
      )
    );
  };

/**
 * @param {?proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelCredentials|undefined} value
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.prototype.setChannelCredentials =
  function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.prototype.clearChannelCredentials =
  function () {
    return this.setChannelCredentials(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.prototype.hasChannelCredentials =
  function () {
    return jspb.Message.getField(this, 2) != null;
  };

/**
 * repeated CallCredentials call_credentials = 3;
 * @return {!Array<!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials>}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.prototype.getCallCredentialsList =
  function () {
    return /** @type{!Array<!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials>} */ (
      jspb.Message.getRepeatedWrapperField(
        this,
        proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials,
        3
      )
    );
  };

/**
 * @param {!Array<!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials>} value
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.prototype.setCallCredentialsList =
  function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 3, value);
  };

/**
 * @param {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials=} opt_value
 * @param {number=} opt_index
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.prototype.addCallCredentials =
  function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(
      this,
      3,
      opt_value,
      proto.envoy.config.core.v3.GrpcService.GoogleGrpc.CallCredentials,
      opt_index
    );
  };

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.prototype.clearCallCredentialsList =
  function () {
    return this.setCallCredentialsList([]);
  };

/**
 * optional string stat_prefix = 4;
 * @return {string}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.prototype.getStatPrefix =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 4, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.prototype.setStatPrefix =
  function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
  };

/**
 * optional string credentials_factory_name = 5;
 * @return {string}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.prototype.getCredentialsFactoryName =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 5, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.prototype.setCredentialsFactoryName =
  function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
  };

/**
 * optional google.protobuf.Struct config = 6;
 * @return {?proto.google.protobuf.Struct}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.prototype.getConfig =
  function () {
    return /** @type{?proto.google.protobuf.Struct} */ (
      jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 6)
    );
  };

/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.prototype.setConfig =
  function (value) {
    return jspb.Message.setWrapperField(this, 6, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.prototype.clearConfig =
  function () {
    return this.setConfig(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.prototype.hasConfig =
  function () {
    return jspb.Message.getField(this, 6) != null;
  };

/**
 * optional google.protobuf.UInt32Value per_stream_buffer_limit_bytes = 7;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.prototype.getPerStreamBufferLimitBytes =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        7
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.prototype.setPerStreamBufferLimitBytes =
  function (value) {
    return jspb.Message.setWrapperField(this, 7, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.prototype.clearPerStreamBufferLimitBytes =
  function () {
    return this.setPerStreamBufferLimitBytes(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.prototype.hasPerStreamBufferLimitBytes =
  function () {
    return jspb.Message.getField(this, 7) != null;
  };

/**
 * optional ChannelArgs channel_args = 8;
 * @return {?proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.prototype.getChannelArgs =
  function () {
    return /** @type{?proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs} */ (
      jspb.Message.getWrapperField(
        this,
        proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs,
        8
      )
    );
  };

/**
 * @param {?proto.envoy.config.core.v3.GrpcService.GoogleGrpc.ChannelArgs|undefined} value
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.prototype.setChannelArgs =
  function (value) {
    return jspb.Message.setWrapperField(this, 8, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.GrpcService.GoogleGrpc} returns this
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.prototype.clearChannelArgs =
  function () {
    return this.setChannelArgs(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.GrpcService.GoogleGrpc.prototype.hasChannelArgs =
  function () {
    return jspb.Message.getField(this, 8) != null;
  };

/**
 * optional EnvoyGrpc envoy_grpc = 1;
 * @return {?proto.envoy.config.core.v3.GrpcService.EnvoyGrpc}
 */
proto.envoy.config.core.v3.GrpcService.prototype.getEnvoyGrpc = function () {
  return /** @type{?proto.envoy.config.core.v3.GrpcService.EnvoyGrpc} */ (
    jspb.Message.getWrapperField(
      this,
      proto.envoy.config.core.v3.GrpcService.EnvoyGrpc,
      1
    )
  );
};

/**
 * @param {?proto.envoy.config.core.v3.GrpcService.EnvoyGrpc|undefined} value
 * @return {!proto.envoy.config.core.v3.GrpcService} returns this
 */
proto.envoy.config.core.v3.GrpcService.prototype.setEnvoyGrpc = function (
  value
) {
  return jspb.Message.setOneofWrapperField(
    this,
    1,
    proto.envoy.config.core.v3.GrpcService.oneofGroups_[0],
    value
  );
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.GrpcService} returns this
 */
proto.envoy.config.core.v3.GrpcService.prototype.clearEnvoyGrpc = function () {
  return this.setEnvoyGrpc(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.GrpcService.prototype.hasEnvoyGrpc = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional GoogleGrpc google_grpc = 2;
 * @return {?proto.envoy.config.core.v3.GrpcService.GoogleGrpc}
 */
proto.envoy.config.core.v3.GrpcService.prototype.getGoogleGrpc = function () {
  return /** @type{?proto.envoy.config.core.v3.GrpcService.GoogleGrpc} */ (
    jspb.Message.getWrapperField(
      this,
      proto.envoy.config.core.v3.GrpcService.GoogleGrpc,
      2
    )
  );
};

/**
 * @param {?proto.envoy.config.core.v3.GrpcService.GoogleGrpc|undefined} value
 * @return {!proto.envoy.config.core.v3.GrpcService} returns this
 */
proto.envoy.config.core.v3.GrpcService.prototype.setGoogleGrpc = function (
  value
) {
  return jspb.Message.setOneofWrapperField(
    this,
    2,
    proto.envoy.config.core.v3.GrpcService.oneofGroups_[0],
    value
  );
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.GrpcService} returns this
 */
proto.envoy.config.core.v3.GrpcService.prototype.clearGoogleGrpc = function () {
  return this.setGoogleGrpc(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.GrpcService.prototype.hasGoogleGrpc = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional google.protobuf.Duration timeout = 3;
 * @return {?proto.google.protobuf.Duration}
 */
proto.envoy.config.core.v3.GrpcService.prototype.getTimeout = function () {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 3)
  );
};

/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.envoy.config.core.v3.GrpcService} returns this
 */
proto.envoy.config.core.v3.GrpcService.prototype.setTimeout = function (value) {
  return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.GrpcService} returns this
 */
proto.envoy.config.core.v3.GrpcService.prototype.clearTimeout = function () {
  return this.setTimeout(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.GrpcService.prototype.hasTimeout = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * repeated HeaderValue initial_metadata = 5;
 * @return {!Array<!proto.envoy.config.core.v3.HeaderValue>}
 */
proto.envoy.config.core.v3.GrpcService.prototype.getInitialMetadataList =
  function () {
    return /** @type{!Array<!proto.envoy.config.core.v3.HeaderValue>} */ (
      jspb.Message.getRepeatedWrapperField(
        this,
        envoy_config_core_v3_base_pb.HeaderValue,
        5
      )
    );
  };

/**
 * @param {!Array<!proto.envoy.config.core.v3.HeaderValue>} value
 * @return {!proto.envoy.config.core.v3.GrpcService} returns this
 */
proto.envoy.config.core.v3.GrpcService.prototype.setInitialMetadataList =
  function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 5, value);
  };

/**
 * @param {!proto.envoy.config.core.v3.HeaderValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.envoy.config.core.v3.HeaderValue}
 */
proto.envoy.config.core.v3.GrpcService.prototype.addInitialMetadata = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    5,
    opt_value,
    proto.envoy.config.core.v3.HeaderValue,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.envoy.config.core.v3.GrpcService} returns this
 */
proto.envoy.config.core.v3.GrpcService.prototype.clearInitialMetadataList =
  function () {
    return this.setInitialMetadataList([]);
  };

goog.object.extend(exports, proto.envoy.config.core.v3);
