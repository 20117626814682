// source: envoy/type/metadata/v3/metadata.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var udpa_annotations_status_pb = require('../../../../udpa/annotations/status_pb.js');
goog.object.extend(proto, udpa_annotations_status_pb);
var udpa_annotations_versioning_pb = require('../../../../udpa/annotations/versioning_pb.js');
goog.object.extend(proto, udpa_annotations_versioning_pb);
var validate_validate_pb = require('../../../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.envoy.type.metadata.v3.MetadataKey', null, global);
goog.exportSymbol(
  'proto.envoy.type.metadata.v3.MetadataKey.PathSegment',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.type.metadata.v3.MetadataKey.PathSegment.SegmentCase',
  null,
  global
);
goog.exportSymbol('proto.envoy.type.metadata.v3.MetadataKind', null, global);
goog.exportSymbol(
  'proto.envoy.type.metadata.v3.MetadataKind.Cluster',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.type.metadata.v3.MetadataKind.Host',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.type.metadata.v3.MetadataKind.KindCase',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.type.metadata.v3.MetadataKind.Request',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.type.metadata.v3.MetadataKind.Route',
  null,
  global
);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.type.metadata.v3.MetadataKey = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.envoy.type.metadata.v3.MetadataKey.repeatedFields_,
    null
  );
};
goog.inherits(proto.envoy.type.metadata.v3.MetadataKey, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.type.metadata.v3.MetadataKey.displayName =
    'proto.envoy.type.metadata.v3.MetadataKey';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.type.metadata.v3.MetadataKey.PathSegment = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    null,
    proto.envoy.type.metadata.v3.MetadataKey.PathSegment.oneofGroups_
  );
};
goog.inherits(
  proto.envoy.type.metadata.v3.MetadataKey.PathSegment,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.type.metadata.v3.MetadataKey.PathSegment.displayName =
    'proto.envoy.type.metadata.v3.MetadataKey.PathSegment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.type.metadata.v3.MetadataKind = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    null,
    proto.envoy.type.metadata.v3.MetadataKind.oneofGroups_
  );
};
goog.inherits(proto.envoy.type.metadata.v3.MetadataKind, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.type.metadata.v3.MetadataKind.displayName =
    'proto.envoy.type.metadata.v3.MetadataKind';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.type.metadata.v3.MetadataKind.Request = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.envoy.type.metadata.v3.MetadataKind.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.type.metadata.v3.MetadataKind.Request.displayName =
    'proto.envoy.type.metadata.v3.MetadataKind.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.type.metadata.v3.MetadataKind.Route = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.envoy.type.metadata.v3.MetadataKind.Route, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.type.metadata.v3.MetadataKind.Route.displayName =
    'proto.envoy.type.metadata.v3.MetadataKind.Route';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.type.metadata.v3.MetadataKind.Cluster = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.envoy.type.metadata.v3.MetadataKind.Cluster, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.type.metadata.v3.MetadataKind.Cluster.displayName =
    'proto.envoy.type.metadata.v3.MetadataKind.Cluster';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.type.metadata.v3.MetadataKind.Host = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.envoy.type.metadata.v3.MetadataKind.Host, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.type.metadata.v3.MetadataKind.Host.displayName =
    'proto.envoy.type.metadata.v3.MetadataKind.Host';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.envoy.type.metadata.v3.MetadataKey.repeatedFields_ = [2];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.type.metadata.v3.MetadataKey.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.type.metadata.v3.MetadataKey.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.type.metadata.v3.MetadataKey} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.type.metadata.v3.MetadataKey.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        key: jspb.Message.getFieldWithDefault(msg, 1, ''),
        pathList: jspb.Message.toObjectList(
          msg.getPathList(),
          proto.envoy.type.metadata.v3.MetadataKey.PathSegment.toObject,
          includeInstance
        )
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.type.metadata.v3.MetadataKey}
 */
proto.envoy.type.metadata.v3.MetadataKey.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.type.metadata.v3.MetadataKey();
  return proto.envoy.type.metadata.v3.MetadataKey.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.type.metadata.v3.MetadataKey} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.type.metadata.v3.MetadataKey}
 */
proto.envoy.type.metadata.v3.MetadataKey.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setKey(value);
          break;
        case 2:
          var value =
            new proto.envoy.type.metadata.v3.MetadataKey.PathSegment();
          reader.readMessage(
            value,
            proto.envoy.type.metadata.v3.MetadataKey.PathSegment
              .deserializeBinaryFromReader
          );
          msg.addPath(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.type.metadata.v3.MetadataKey.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.type.metadata.v3.MetadataKey.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.type.metadata.v3.MetadataKey} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.type.metadata.v3.MetadataKey.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getPathList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.envoy.type.metadata.v3.MetadataKey.PathSegment
        .serializeBinaryToWriter
    );
  }
};

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.envoy.type.metadata.v3.MetadataKey.PathSegment.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.envoy.type.metadata.v3.MetadataKey.PathSegment.SegmentCase = {
  SEGMENT_NOT_SET: 0,
  KEY: 1
};

/**
 * @return {proto.envoy.type.metadata.v3.MetadataKey.PathSegment.SegmentCase}
 */
proto.envoy.type.metadata.v3.MetadataKey.PathSegment.prototype.getSegmentCase =
  function () {
    return /** @type {proto.envoy.type.metadata.v3.MetadataKey.PathSegment.SegmentCase} */ (
      jspb.Message.computeOneofCase(
        this,
        proto.envoy.type.metadata.v3.MetadataKey.PathSegment.oneofGroups_[0]
      )
    );
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.type.metadata.v3.MetadataKey.PathSegment.prototype.toObject =
    function (opt_includeInstance) {
      return proto.envoy.type.metadata.v3.MetadataKey.PathSegment.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.type.metadata.v3.MetadataKey.PathSegment} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.type.metadata.v3.MetadataKey.PathSegment.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        key: jspb.Message.getFieldWithDefault(msg, 1, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.type.metadata.v3.MetadataKey.PathSegment}
 */
proto.envoy.type.metadata.v3.MetadataKey.PathSegment.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.envoy.type.metadata.v3.MetadataKey.PathSegment();
    return proto.envoy.type.metadata.v3.MetadataKey.PathSegment.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.type.metadata.v3.MetadataKey.PathSegment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.type.metadata.v3.MetadataKey.PathSegment}
 */
proto.envoy.type.metadata.v3.MetadataKey.PathSegment.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setKey(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.type.metadata.v3.MetadataKey.PathSegment.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.type.metadata.v3.MetadataKey.PathSegment.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.type.metadata.v3.MetadataKey.PathSegment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.type.metadata.v3.MetadataKey.PathSegment.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = /** @type {string} */ (jspb.Message.getField(message, 1));
    if (f != null) {
      writer.writeString(1, f);
    }
  };

/**
 * optional string key = 1;
 * @return {string}
 */
proto.envoy.type.metadata.v3.MetadataKey.PathSegment.prototype.getKey =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.type.metadata.v3.MetadataKey.PathSegment} returns this
 */
proto.envoy.type.metadata.v3.MetadataKey.PathSegment.prototype.setKey =
  function (value) {
    return jspb.Message.setOneofField(
      this,
      1,
      proto.envoy.type.metadata.v3.MetadataKey.PathSegment.oneofGroups_[0],
      value
    );
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.envoy.type.metadata.v3.MetadataKey.PathSegment} returns this
 */
proto.envoy.type.metadata.v3.MetadataKey.PathSegment.prototype.clearKey =
  function () {
    return jspb.Message.setOneofField(
      this,
      1,
      proto.envoy.type.metadata.v3.MetadataKey.PathSegment.oneofGroups_[0],
      undefined
    );
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.type.metadata.v3.MetadataKey.PathSegment.prototype.hasKey =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

/**
 * optional string key = 1;
 * @return {string}
 */
proto.envoy.type.metadata.v3.MetadataKey.prototype.getKey = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.envoy.type.metadata.v3.MetadataKey} returns this
 */
proto.envoy.type.metadata.v3.MetadataKey.prototype.setKey = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * repeated PathSegment path = 2;
 * @return {!Array<!proto.envoy.type.metadata.v3.MetadataKey.PathSegment>}
 */
proto.envoy.type.metadata.v3.MetadataKey.prototype.getPathList = function () {
  return /** @type{!Array<!proto.envoy.type.metadata.v3.MetadataKey.PathSegment>} */ (
    jspb.Message.getRepeatedWrapperField(
      this,
      proto.envoy.type.metadata.v3.MetadataKey.PathSegment,
      2
    )
  );
};

/**
 * @param {!Array<!proto.envoy.type.metadata.v3.MetadataKey.PathSegment>} value
 * @return {!proto.envoy.type.metadata.v3.MetadataKey} returns this
 */
proto.envoy.type.metadata.v3.MetadataKey.prototype.setPathList = function (
  value
) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};

/**
 * @param {!proto.envoy.type.metadata.v3.MetadataKey.PathSegment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.envoy.type.metadata.v3.MetadataKey.PathSegment}
 */
proto.envoy.type.metadata.v3.MetadataKey.prototype.addPath = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    2,
    opt_value,
    proto.envoy.type.metadata.v3.MetadataKey.PathSegment,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.envoy.type.metadata.v3.MetadataKey} returns this
 */
proto.envoy.type.metadata.v3.MetadataKey.prototype.clearPathList = function () {
  return this.setPathList([]);
};

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.envoy.type.metadata.v3.MetadataKind.oneofGroups_ = [[1, 2, 3, 4]];

/**
 * @enum {number}
 */
proto.envoy.type.metadata.v3.MetadataKind.KindCase = {
  KIND_NOT_SET: 0,
  REQUEST: 1,
  ROUTE: 2,
  CLUSTER: 3,
  HOST: 4
};

/**
 * @return {proto.envoy.type.metadata.v3.MetadataKind.KindCase}
 */
proto.envoy.type.metadata.v3.MetadataKind.prototype.getKindCase = function () {
  return /** @type {proto.envoy.type.metadata.v3.MetadataKind.KindCase} */ (
    jspb.Message.computeOneofCase(
      this,
      proto.envoy.type.metadata.v3.MetadataKind.oneofGroups_[0]
    )
  );
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.type.metadata.v3.MetadataKind.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.type.metadata.v3.MetadataKind.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.type.metadata.v3.MetadataKind} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.type.metadata.v3.MetadataKind.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        request:
          (f = msg.getRequest()) &&
          proto.envoy.type.metadata.v3.MetadataKind.Request.toObject(
            includeInstance,
            f
          ),
        route:
          (f = msg.getRoute()) &&
          proto.envoy.type.metadata.v3.MetadataKind.Route.toObject(
            includeInstance,
            f
          ),
        cluster:
          (f = msg.getCluster()) &&
          proto.envoy.type.metadata.v3.MetadataKind.Cluster.toObject(
            includeInstance,
            f
          ),
        host:
          (f = msg.getHost()) &&
          proto.envoy.type.metadata.v3.MetadataKind.Host.toObject(
            includeInstance,
            f
          )
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.type.metadata.v3.MetadataKind}
 */
proto.envoy.type.metadata.v3.MetadataKind.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.type.metadata.v3.MetadataKind();
  return proto.envoy.type.metadata.v3.MetadataKind.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.type.metadata.v3.MetadataKind} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.type.metadata.v3.MetadataKind}
 */
proto.envoy.type.metadata.v3.MetadataKind.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.envoy.type.metadata.v3.MetadataKind.Request();
          reader.readMessage(
            value,
            proto.envoy.type.metadata.v3.MetadataKind.Request
              .deserializeBinaryFromReader
          );
          msg.setRequest(value);
          break;
        case 2:
          var value = new proto.envoy.type.metadata.v3.MetadataKind.Route();
          reader.readMessage(
            value,
            proto.envoy.type.metadata.v3.MetadataKind.Route
              .deserializeBinaryFromReader
          );
          msg.setRoute(value);
          break;
        case 3:
          var value = new proto.envoy.type.metadata.v3.MetadataKind.Cluster();
          reader.readMessage(
            value,
            proto.envoy.type.metadata.v3.MetadataKind.Cluster
              .deserializeBinaryFromReader
          );
          msg.setCluster(value);
          break;
        case 4:
          var value = new proto.envoy.type.metadata.v3.MetadataKind.Host();
          reader.readMessage(
            value,
            proto.envoy.type.metadata.v3.MetadataKind.Host
              .deserializeBinaryFromReader
          );
          msg.setHost(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.type.metadata.v3.MetadataKind.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.type.metadata.v3.MetadataKind.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.type.metadata.v3.MetadataKind} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.type.metadata.v3.MetadataKind.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.envoy.type.metadata.v3.MetadataKind.Request.serializeBinaryToWriter
    );
  }
  f = message.getRoute();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.envoy.type.metadata.v3.MetadataKind.Route.serializeBinaryToWriter
    );
  }
  f = message.getCluster();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.envoy.type.metadata.v3.MetadataKind.Cluster.serializeBinaryToWriter
    );
  }
  f = message.getHost();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.envoy.type.metadata.v3.MetadataKind.Host.serializeBinaryToWriter
    );
  }
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.type.metadata.v3.MetadataKind.Request.prototype.toObject =
    function (opt_includeInstance) {
      return proto.envoy.type.metadata.v3.MetadataKind.Request.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.type.metadata.v3.MetadataKind.Request} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.type.metadata.v3.MetadataKind.Request.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {};

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.type.metadata.v3.MetadataKind.Request}
 */
proto.envoy.type.metadata.v3.MetadataKind.Request.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.type.metadata.v3.MetadataKind.Request();
  return proto.envoy.type.metadata.v3.MetadataKind.Request.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.type.metadata.v3.MetadataKind.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.type.metadata.v3.MetadataKind.Request}
 */
proto.envoy.type.metadata.v3.MetadataKind.Request.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.type.metadata.v3.MetadataKind.Request.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.type.metadata.v3.MetadataKind.Request.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.type.metadata.v3.MetadataKind.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.type.metadata.v3.MetadataKind.Request.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.type.metadata.v3.MetadataKind.Route.prototype.toObject =
    function (opt_includeInstance) {
      return proto.envoy.type.metadata.v3.MetadataKind.Route.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.type.metadata.v3.MetadataKind.Route} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.type.metadata.v3.MetadataKind.Route.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {};

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.type.metadata.v3.MetadataKind.Route}
 */
proto.envoy.type.metadata.v3.MetadataKind.Route.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.type.metadata.v3.MetadataKind.Route();
  return proto.envoy.type.metadata.v3.MetadataKind.Route.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.type.metadata.v3.MetadataKind.Route} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.type.metadata.v3.MetadataKind.Route}
 */
proto.envoy.type.metadata.v3.MetadataKind.Route.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.type.metadata.v3.MetadataKind.Route.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.type.metadata.v3.MetadataKind.Route.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.type.metadata.v3.MetadataKind.Route} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.type.metadata.v3.MetadataKind.Route.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.type.metadata.v3.MetadataKind.Cluster.prototype.toObject =
    function (opt_includeInstance) {
      return proto.envoy.type.metadata.v3.MetadataKind.Cluster.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.type.metadata.v3.MetadataKind.Cluster} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.type.metadata.v3.MetadataKind.Cluster.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {};

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.type.metadata.v3.MetadataKind.Cluster}
 */
proto.envoy.type.metadata.v3.MetadataKind.Cluster.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.type.metadata.v3.MetadataKind.Cluster();
  return proto.envoy.type.metadata.v3.MetadataKind.Cluster.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.type.metadata.v3.MetadataKind.Cluster} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.type.metadata.v3.MetadataKind.Cluster}
 */
proto.envoy.type.metadata.v3.MetadataKind.Cluster.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.type.metadata.v3.MetadataKind.Cluster.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.type.metadata.v3.MetadataKind.Cluster.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.type.metadata.v3.MetadataKind.Cluster} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.type.metadata.v3.MetadataKind.Cluster.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.type.metadata.v3.MetadataKind.Host.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.type.metadata.v3.MetadataKind.Host.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.type.metadata.v3.MetadataKind.Host} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.type.metadata.v3.MetadataKind.Host.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {};

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.type.metadata.v3.MetadataKind.Host}
 */
proto.envoy.type.metadata.v3.MetadataKind.Host.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.type.metadata.v3.MetadataKind.Host();
  return proto.envoy.type.metadata.v3.MetadataKind.Host.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.type.metadata.v3.MetadataKind.Host} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.type.metadata.v3.MetadataKind.Host}
 */
proto.envoy.type.metadata.v3.MetadataKind.Host.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.type.metadata.v3.MetadataKind.Host.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.type.metadata.v3.MetadataKind.Host.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.type.metadata.v3.MetadataKind.Host} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.type.metadata.v3.MetadataKind.Host.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
  };

/**
 * optional Request request = 1;
 * @return {?proto.envoy.type.metadata.v3.MetadataKind.Request}
 */
proto.envoy.type.metadata.v3.MetadataKind.prototype.getRequest = function () {
  return /** @type{?proto.envoy.type.metadata.v3.MetadataKind.Request} */ (
    jspb.Message.getWrapperField(
      this,
      proto.envoy.type.metadata.v3.MetadataKind.Request,
      1
    )
  );
};

/**
 * @param {?proto.envoy.type.metadata.v3.MetadataKind.Request|undefined} value
 * @return {!proto.envoy.type.metadata.v3.MetadataKind} returns this
 */
proto.envoy.type.metadata.v3.MetadataKind.prototype.setRequest = function (
  value
) {
  return jspb.Message.setOneofWrapperField(
    this,
    1,
    proto.envoy.type.metadata.v3.MetadataKind.oneofGroups_[0],
    value
  );
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.type.metadata.v3.MetadataKind} returns this
 */
proto.envoy.type.metadata.v3.MetadataKind.prototype.clearRequest = function () {
  return this.setRequest(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.type.metadata.v3.MetadataKind.prototype.hasRequest = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional Route route = 2;
 * @return {?proto.envoy.type.metadata.v3.MetadataKind.Route}
 */
proto.envoy.type.metadata.v3.MetadataKind.prototype.getRoute = function () {
  return /** @type{?proto.envoy.type.metadata.v3.MetadataKind.Route} */ (
    jspb.Message.getWrapperField(
      this,
      proto.envoy.type.metadata.v3.MetadataKind.Route,
      2
    )
  );
};

/**
 * @param {?proto.envoy.type.metadata.v3.MetadataKind.Route|undefined} value
 * @return {!proto.envoy.type.metadata.v3.MetadataKind} returns this
 */
proto.envoy.type.metadata.v3.MetadataKind.prototype.setRoute = function (
  value
) {
  return jspb.Message.setOneofWrapperField(
    this,
    2,
    proto.envoy.type.metadata.v3.MetadataKind.oneofGroups_[0],
    value
  );
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.type.metadata.v3.MetadataKind} returns this
 */
proto.envoy.type.metadata.v3.MetadataKind.prototype.clearRoute = function () {
  return this.setRoute(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.type.metadata.v3.MetadataKind.prototype.hasRoute = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional Cluster cluster = 3;
 * @return {?proto.envoy.type.metadata.v3.MetadataKind.Cluster}
 */
proto.envoy.type.metadata.v3.MetadataKind.prototype.getCluster = function () {
  return /** @type{?proto.envoy.type.metadata.v3.MetadataKind.Cluster} */ (
    jspb.Message.getWrapperField(
      this,
      proto.envoy.type.metadata.v3.MetadataKind.Cluster,
      3
    )
  );
};

/**
 * @param {?proto.envoy.type.metadata.v3.MetadataKind.Cluster|undefined} value
 * @return {!proto.envoy.type.metadata.v3.MetadataKind} returns this
 */
proto.envoy.type.metadata.v3.MetadataKind.prototype.setCluster = function (
  value
) {
  return jspb.Message.setOneofWrapperField(
    this,
    3,
    proto.envoy.type.metadata.v3.MetadataKind.oneofGroups_[0],
    value
  );
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.type.metadata.v3.MetadataKind} returns this
 */
proto.envoy.type.metadata.v3.MetadataKind.prototype.clearCluster = function () {
  return this.setCluster(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.type.metadata.v3.MetadataKind.prototype.hasCluster = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional Host host = 4;
 * @return {?proto.envoy.type.metadata.v3.MetadataKind.Host}
 */
proto.envoy.type.metadata.v3.MetadataKind.prototype.getHost = function () {
  return /** @type{?proto.envoy.type.metadata.v3.MetadataKind.Host} */ (
    jspb.Message.getWrapperField(
      this,
      proto.envoy.type.metadata.v3.MetadataKind.Host,
      4
    )
  );
};

/**
 * @param {?proto.envoy.type.metadata.v3.MetadataKind.Host|undefined} value
 * @return {!proto.envoy.type.metadata.v3.MetadataKind} returns this
 */
proto.envoy.type.metadata.v3.MetadataKind.prototype.setHost = function (value) {
  return jspb.Message.setOneofWrapperField(
    this,
    4,
    proto.envoy.type.metadata.v3.MetadataKind.oneofGroups_[0],
    value
  );
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.type.metadata.v3.MetadataKind} returns this
 */
proto.envoy.type.metadata.v3.MetadataKind.prototype.clearHost = function () {
  return this.setHost(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.type.metadata.v3.MetadataKind.prototype.hasHost = function () {
  return jspb.Message.getField(this, 4) != null;
};

goog.object.extend(exports, proto.envoy.type.metadata.v3);
