// source: activity_log.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.pomerium.dashboard.ActivityLogEntry', null, global);
goog.exportSymbol(
  'proto.pomerium.dashboard.ActivityLogEntry.DiffSummary',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.GetActivityLogEntryRequest',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.GetActivityLogEntryResponse',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.ListActivityLogEntriesRequest',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.ListActivityLogEntriesRequest.DateFilter',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.ListActivityLogEntriesRequest.Entity',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.ListActivityLogEntriesRequest.Sort',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.ListActivityLogEntriesRequest.StringFilter',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.ListActivityLogEntriesResponse',
  null,
  global
);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.ActivityLogEntry = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.pomerium.dashboard.ActivityLogEntry.repeatedFields_,
    null
  );
};
goog.inherits(proto.pomerium.dashboard.ActivityLogEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.ActivityLogEntry.displayName =
    'proto.pomerium.dashboard.ActivityLogEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.ActivityLogEntry.DiffSummary = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(
  proto.pomerium.dashboard.ActivityLogEntry.DiffSummary,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.ActivityLogEntry.DiffSummary.displayName =
    'proto.pomerium.dashboard.ActivityLogEntry.DiffSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.GetActivityLogEntryRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(
  proto.pomerium.dashboard.GetActivityLogEntryRequest,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.GetActivityLogEntryRequest.displayName =
    'proto.pomerium.dashboard.GetActivityLogEntryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.GetActivityLogEntryResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(
  proto.pomerium.dashboard.GetActivityLogEntryResponse,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.GetActivityLogEntryResponse.displayName =
    'proto.pomerium.dashboard.GetActivityLogEntryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.pomerium.dashboard.ListActivityLogEntriesRequest.repeatedFields_,
    null
  );
};
goog.inherits(
  proto.pomerium.dashboard.ListActivityLogEntriesRequest,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.ListActivityLogEntriesRequest.displayName =
    'proto.pomerium.dashboard.ListActivityLogEntriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.Entity = function (
  opt_data
) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(
  proto.pomerium.dashboard.ListActivityLogEntriesRequest.Entity,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.ListActivityLogEntriesRequest.Entity.displayName =
    'proto.pomerium.dashboard.ListActivityLogEntriesRequest.Entity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.Sort = function (
  opt_data
) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(
  proto.pomerium.dashboard.ListActivityLogEntriesRequest.Sort,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.ListActivityLogEntriesRequest.Sort.displayName =
    'proto.pomerium.dashboard.ListActivityLogEntriesRequest.Sort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.DateFilter = function (
  opt_data
) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(
  proto.pomerium.dashboard.ListActivityLogEntriesRequest.DateFilter,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.ListActivityLogEntriesRequest.DateFilter.displayName =
    'proto.pomerium.dashboard.ListActivityLogEntriesRequest.DateFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.StringFilter = function (
  opt_data
) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(
  proto.pomerium.dashboard.ListActivityLogEntriesRequest.StringFilter,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.ListActivityLogEntriesRequest.StringFilter.displayName =
    'proto.pomerium.dashboard.ListActivityLogEntriesRequest.StringFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.ListActivityLogEntriesResponse = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.pomerium.dashboard.ListActivityLogEntriesResponse.repeatedFields_,
    null
  );
};
goog.inherits(
  proto.pomerium.dashboard.ListActivityLogEntriesResponse,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.ListActivityLogEntriesResponse.displayName =
    'proto.pomerium.dashboard.ListActivityLogEntriesResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pomerium.dashboard.ActivityLogEntry.repeatedFields_ = [19];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.ActivityLogEntry.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.ActivityLogEntry.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.ActivityLogEntry} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.ActivityLogEntry.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, ''),
        activityType: jspb.Message.getFieldWithDefault(msg, 2, ''),
        createdAt:
          (f = msg.getCreatedAt()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        namespaceId: jspb.Message.getFieldWithDefault(msg, 4, ''),
        namespaceName: jspb.Message.getFieldWithDefault(msg, 5, ''),
        userId: jspb.Message.getFieldWithDefault(msg, 6, ''),
        userName: jspb.Message.getFieldWithDefault(msg, 7, ''),
        userEmail: jspb.Message.getFieldWithDefault(msg, 8, ''),
        entityType: jspb.Message.getFieldWithDefault(msg, 9, ''),
        entityId: jspb.Message.getFieldWithDefault(msg, 10, ''),
        entityData: jspb.Message.getFieldWithDefault(msg, 11, ''),
        diffSummary:
          (f = msg.getDiffSummary()) &&
          proto.pomerium.dashboard.ActivityLogEntry.DiffSummary.toObject(
            includeInstance,
            f
          ),
        dbVersion: jspb.Message.getFieldWithDefault(msg, 13, 0),
        sessionId: jspb.Message.getFieldWithDefault(msg, 14, ''),
        serviceAccountId: jspb.Message.getFieldWithDefault(msg, 15, ''),
        impersonateUserId: jspb.Message.getFieldWithDefault(msg, 16, ''),
        impersonateUserName: jspb.Message.getFieldWithDefault(msg, 17, ''),
        impersonateUserEmail: jspb.Message.getFieldWithDefault(msg, 18, ''),
        impersonateUserGroupsList:
          (f = jspb.Message.getRepeatedField(msg, 19)) == null ? undefined : f
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.ActivityLogEntry}
 */
proto.pomerium.dashboard.ActivityLogEntry.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.ActivityLogEntry();
  return proto.pomerium.dashboard.ActivityLogEntry.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.ActivityLogEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.ActivityLogEntry}
 */
proto.pomerium.dashboard.ActivityLogEntry.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setId(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setActivityType(value);
          break;
        case 3:
          var value = new google_protobuf_timestamp_pb.Timestamp();
          reader.readMessage(
            value,
            google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
          );
          msg.setCreatedAt(value);
          break;
        case 4:
          var value = /** @type {string} */ (reader.readString());
          msg.setNamespaceId(value);
          break;
        case 5:
          var value = /** @type {string} */ (reader.readString());
          msg.setNamespaceName(value);
          break;
        case 6:
          var value = /** @type {string} */ (reader.readString());
          msg.setUserId(value);
          break;
        case 7:
          var value = /** @type {string} */ (reader.readString());
          msg.setUserName(value);
          break;
        case 8:
          var value = /** @type {string} */ (reader.readString());
          msg.setUserEmail(value);
          break;
        case 9:
          var value = /** @type {string} */ (reader.readString());
          msg.setEntityType(value);
          break;
        case 10:
          var value = /** @type {string} */ (reader.readString());
          msg.setEntityId(value);
          break;
        case 11:
          var value = /** @type {string} */ (reader.readString());
          msg.setEntityData(value);
          break;
        case 12:
          var value =
            new proto.pomerium.dashboard.ActivityLogEntry.DiffSummary();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.ActivityLogEntry.DiffSummary
              .deserializeBinaryFromReader
          );
          msg.setDiffSummary(value);
          break;
        case 13:
          var value = /** @type {number} */ (reader.readUint64());
          msg.setDbVersion(value);
          break;
        case 14:
          var value = /** @type {string} */ (reader.readString());
          msg.setSessionId(value);
          break;
        case 15:
          var value = /** @type {string} */ (reader.readString());
          msg.setServiceAccountId(value);
          break;
        case 16:
          var value = /** @type {string} */ (reader.readString());
          msg.setImpersonateUserId(value);
          break;
        case 17:
          var value = /** @type {string} */ (reader.readString());
          msg.setImpersonateUserName(value);
          break;
        case 18:
          var value = /** @type {string} */ (reader.readString());
          msg.setImpersonateUserEmail(value);
          break;
        case 19:
          var value = /** @type {string} */ (reader.readString());
          msg.addImpersonateUserGroups(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.ActivityLogEntry.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.ActivityLogEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.ActivityLogEntry.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getActivityType();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getNamespaceId();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
  f = message.getNamespaceName();
  if (f.length > 0) {
    writer.writeString(5, f);
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(6, f);
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(7, f);
  }
  f = message.getUserEmail();
  if (f.length > 0) {
    writer.writeString(8, f);
  }
  f = message.getEntityType();
  if (f.length > 0) {
    writer.writeString(9, f);
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(10, f);
  }
  f = message.getEntityData();
  if (f.length > 0) {
    writer.writeString(11, f);
  }
  f = message.getDiffSummary();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.pomerium.dashboard.ActivityLogEntry.DiffSummary
        .serializeBinaryToWriter
    );
  }
  f = message.getDbVersion();
  if (f !== 0) {
    writer.writeUint64(13, f);
  }
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(14, f);
  }
  f = message.getServiceAccountId();
  if (f.length > 0) {
    writer.writeString(15, f);
  }
  f = message.getImpersonateUserId();
  if (f.length > 0) {
    writer.writeString(16, f);
  }
  f = message.getImpersonateUserName();
  if (f.length > 0) {
    writer.writeString(17, f);
  }
  f = message.getImpersonateUserEmail();
  if (f.length > 0) {
    writer.writeString(18, f);
  }
  f = message.getImpersonateUserGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedString(19, f);
  }
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.ActivityLogEntry.DiffSummary.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.ActivityLogEntry.DiffSummary.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.ActivityLogEntry.DiffSummary} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.ActivityLogEntry.DiffSummary.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        added: jspb.Message.getFieldWithDefault(msg, 1, 0),
        removed: jspb.Message.getFieldWithDefault(msg, 2, 0)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.ActivityLogEntry.DiffSummary}
 */
proto.pomerium.dashboard.ActivityLogEntry.DiffSummary.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pomerium.dashboard.ActivityLogEntry.DiffSummary();
    return proto.pomerium.dashboard.ActivityLogEntry.DiffSummary.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.ActivityLogEntry.DiffSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.ActivityLogEntry.DiffSummary}
 */
proto.pomerium.dashboard.ActivityLogEntry.DiffSummary.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {number} */ (reader.readInt64());
          msg.setAdded(value);
          break;
        case 2:
          var value = /** @type {number} */ (reader.readInt64());
          msg.setRemoved(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.ActivityLogEntry.DiffSummary.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.ActivityLogEntry.DiffSummary.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.ActivityLogEntry.DiffSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.ActivityLogEntry.DiffSummary.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getAdded();
    if (f !== 0) {
      writer.writeInt64(1, f);
    }
    f = message.getRemoved();
    if (f !== 0) {
      writer.writeInt64(2, f);
    }
  };

/**
 * optional int64 added = 1;
 * @return {number}
 */
proto.pomerium.dashboard.ActivityLogEntry.DiffSummary.prototype.getAdded =
  function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };

/**
 * @param {number} value
 * @return {!proto.pomerium.dashboard.ActivityLogEntry.DiffSummary} returns this
 */
proto.pomerium.dashboard.ActivityLogEntry.DiffSummary.prototype.setAdded =
  function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
  };

/**
 * optional int64 removed = 2;
 * @return {number}
 */
proto.pomerium.dashboard.ActivityLogEntry.DiffSummary.prototype.getRemoved =
  function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };

/**
 * @param {number} value
 * @return {!proto.pomerium.dashboard.ActivityLogEntry.DiffSummary} returns this
 */
proto.pomerium.dashboard.ActivityLogEntry.DiffSummary.prototype.setRemoved =
  function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
  };

/**
 * optional string id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ActivityLogEntry} returns this
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string activity_type = 2;
 * @return {string}
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.getActivityType =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 2, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ActivityLogEntry} returns this
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.setActivityType = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional google.protobuf.Timestamp created_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.getCreatedAt = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_timestamp_pb.Timestamp,
      3
    )
  );
};

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.pomerium.dashboard.ActivityLogEntry} returns this
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.setCreatedAt = function (
  value
) {
  return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.ActivityLogEntry} returns this
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.clearCreatedAt =
  function () {
    return this.setCreatedAt(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.hasCreatedAt = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional string namespace_id = 4;
 * @return {string}
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.getNamespaceId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 4, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ActivityLogEntry} returns this
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.setNamespaceId = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * optional string namespace_name = 5;
 * @return {string}
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.getNamespaceName =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 5, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ActivityLogEntry} returns this
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.setNamespaceName =
  function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
  };

/**
 * optional string user_id = 6;
 * @return {string}
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.getUserId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ActivityLogEntry} returns this
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.setUserId = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 6, value);
};

/**
 * optional string user_name = 7;
 * @return {string}
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.getUserName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ActivityLogEntry} returns this
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.setUserName = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 7, value);
};

/**
 * optional string user_email = 8;
 * @return {string}
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.getUserEmail = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ActivityLogEntry} returns this
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.setUserEmail = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 8, value);
};

/**
 * optional string entity_type = 9;
 * @return {string}
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.getEntityType =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 9, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ActivityLogEntry} returns this
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.setEntityType = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 9, value);
};

/**
 * optional string entity_id = 10;
 * @return {string}
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.getEntityId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ActivityLogEntry} returns this
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.setEntityId = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 10, value);
};

/**
 * optional string entity_data = 11;
 * @return {string}
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.getEntityData =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 11, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ActivityLogEntry} returns this
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.setEntityData = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 11, value);
};

/**
 * optional DiffSummary diff_summary = 12;
 * @return {?proto.pomerium.dashboard.ActivityLogEntry.DiffSummary}
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.getDiffSummary =
  function () {
    return /** @type{?proto.pomerium.dashboard.ActivityLogEntry.DiffSummary} */ (
      jspb.Message.getWrapperField(
        this,
        proto.pomerium.dashboard.ActivityLogEntry.DiffSummary,
        12
      )
    );
  };

/**
 * @param {?proto.pomerium.dashboard.ActivityLogEntry.DiffSummary|undefined} value
 * @return {!proto.pomerium.dashboard.ActivityLogEntry} returns this
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.setDiffSummary = function (
  value
) {
  return jspb.Message.setWrapperField(this, 12, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.ActivityLogEntry} returns this
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.clearDiffSummary =
  function () {
    return this.setDiffSummary(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.hasDiffSummary =
  function () {
    return jspb.Message.getField(this, 12) != null;
  };

/**
 * optional uint64 db_version = 13;
 * @return {number}
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.getDbVersion = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};

/**
 * @param {number} value
 * @return {!proto.pomerium.dashboard.ActivityLogEntry} returns this
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.setDbVersion = function (
  value
) {
  return jspb.Message.setProto3IntField(this, 13, value);
};

/**
 * optional string session_id = 14;
 * @return {string}
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.getSessionId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ActivityLogEntry} returns this
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.setSessionId = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 14, value);
};

/**
 * optional string service_account_id = 15;
 * @return {string}
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.getServiceAccountId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 15, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ActivityLogEntry} returns this
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.setServiceAccountId =
  function (value) {
    return jspb.Message.setProto3StringField(this, 15, value);
  };

/**
 * optional string impersonate_user_id = 16;
 * @return {string}
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.getImpersonateUserId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 16, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ActivityLogEntry} returns this
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.setImpersonateUserId =
  function (value) {
    return jspb.Message.setProto3StringField(this, 16, value);
  };

/**
 * optional string impersonate_user_name = 17;
 * @return {string}
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.getImpersonateUserName =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 17, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ActivityLogEntry} returns this
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.setImpersonateUserName =
  function (value) {
    return jspb.Message.setProto3StringField(this, 17, value);
  };

/**
 * optional string impersonate_user_email = 18;
 * @return {string}
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.getImpersonateUserEmail =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 18, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ActivityLogEntry} returns this
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.setImpersonateUserEmail =
  function (value) {
    return jspb.Message.setProto3StringField(this, 18, value);
  };

/**
 * repeated string impersonate_user_groups = 19;
 * @return {!Array<string>}
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.getImpersonateUserGroupsList =
  function () {
    return /** @type {!Array<string>} */ (
      jspb.Message.getRepeatedField(this, 19)
    );
  };

/**
 * @param {!Array<string>} value
 * @return {!proto.pomerium.dashboard.ActivityLogEntry} returns this
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.setImpersonateUserGroupsList =
  function (value) {
    return jspb.Message.setField(this, 19, value || []);
  };

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.ActivityLogEntry} returns this
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.addImpersonateUserGroups =
  function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 19, value, opt_index);
  };

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.ActivityLogEntry} returns this
 */
proto.pomerium.dashboard.ActivityLogEntry.prototype.clearImpersonateUserGroupsList =
  function () {
    return this.setImpersonateUserGroupsList([]);
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.GetActivityLogEntryRequest.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.GetActivityLogEntryRequest.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.GetActivityLogEntryRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.GetActivityLogEntryRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.GetActivityLogEntryRequest}
 */
proto.pomerium.dashboard.GetActivityLogEntryRequest.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pomerium.dashboard.GetActivityLogEntryRequest();
    return proto.pomerium.dashboard.GetActivityLogEntryRequest.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.GetActivityLogEntryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.GetActivityLogEntryRequest}
 */
proto.pomerium.dashboard.GetActivityLogEntryRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setId(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.GetActivityLogEntryRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.GetActivityLogEntryRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.GetActivityLogEntryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.GetActivityLogEntryRequest.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getId();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
  };

/**
 * optional string id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.GetActivityLogEntryRequest.prototype.getId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.GetActivityLogEntryRequest} returns this
 */
proto.pomerium.dashboard.GetActivityLogEntryRequest.prototype.setId = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.GetActivityLogEntryResponse.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.GetActivityLogEntryResponse.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.GetActivityLogEntryResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.GetActivityLogEntryResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        entry:
          (f = msg.getEntry()) &&
          proto.pomerium.dashboard.ActivityLogEntry.toObject(
            includeInstance,
            f
          ),
        previousEntryId: jspb.Message.getFieldWithDefault(msg, 2, ''),
        nextEntryId: jspb.Message.getFieldWithDefault(msg, 3, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.GetActivityLogEntryResponse}
 */
proto.pomerium.dashboard.GetActivityLogEntryResponse.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pomerium.dashboard.GetActivityLogEntryResponse();
    return proto.pomerium.dashboard.GetActivityLogEntryResponse.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.GetActivityLogEntryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.GetActivityLogEntryResponse}
 */
proto.pomerium.dashboard.GetActivityLogEntryResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.ActivityLogEntry();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.ActivityLogEntry
              .deserializeBinaryFromReader
          );
          msg.setEntry(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setPreviousEntryId(value);
          break;
        case 3:
          var value = /** @type {string} */ (reader.readString());
          msg.setNextEntryId(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.GetActivityLogEntryResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.GetActivityLogEntryResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.GetActivityLogEntryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.GetActivityLogEntryResponse.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getEntry();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        proto.pomerium.dashboard.ActivityLogEntry.serializeBinaryToWriter
      );
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 2));
    if (f != null) {
      writer.writeString(2, f);
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 3));
    if (f != null) {
      writer.writeString(3, f);
    }
  };

/**
 * optional ActivityLogEntry entry = 1;
 * @return {?proto.pomerium.dashboard.ActivityLogEntry}
 */
proto.pomerium.dashboard.GetActivityLogEntryResponse.prototype.getEntry =
  function () {
    return /** @type{?proto.pomerium.dashboard.ActivityLogEntry} */ (
      jspb.Message.getWrapperField(
        this,
        proto.pomerium.dashboard.ActivityLogEntry,
        1
      )
    );
  };

/**
 * @param {?proto.pomerium.dashboard.ActivityLogEntry|undefined} value
 * @return {!proto.pomerium.dashboard.GetActivityLogEntryResponse} returns this
 */
proto.pomerium.dashboard.GetActivityLogEntryResponse.prototype.setEntry =
  function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.GetActivityLogEntryResponse} returns this
 */
proto.pomerium.dashboard.GetActivityLogEntryResponse.prototype.clearEntry =
  function () {
    return this.setEntry(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.GetActivityLogEntryResponse.prototype.hasEntry =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

/**
 * optional string previous_entry_id = 2;
 * @return {string}
 */
proto.pomerium.dashboard.GetActivityLogEntryResponse.prototype.getPreviousEntryId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 2, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.GetActivityLogEntryResponse} returns this
 */
proto.pomerium.dashboard.GetActivityLogEntryResponse.prototype.setPreviousEntryId =
  function (value) {
    return jspb.Message.setField(this, 2, value);
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.GetActivityLogEntryResponse} returns this
 */
proto.pomerium.dashboard.GetActivityLogEntryResponse.prototype.clearPreviousEntryId =
  function () {
    return jspb.Message.setField(this, 2, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.GetActivityLogEntryResponse.prototype.hasPreviousEntryId =
  function () {
    return jspb.Message.getField(this, 2) != null;
  };

/**
 * optional string next_entry_id = 3;
 * @return {string}
 */
proto.pomerium.dashboard.GetActivityLogEntryResponse.prototype.getNextEntryId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 3, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.GetActivityLogEntryResponse} returns this
 */
proto.pomerium.dashboard.GetActivityLogEntryResponse.prototype.setNextEntryId =
  function (value) {
    return jspb.Message.setField(this, 3, value);
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.GetActivityLogEntryResponse} returns this
 */
proto.pomerium.dashboard.GetActivityLogEntryResponse.prototype.clearNextEntryId =
  function () {
    return jspb.Message.setField(this, 3, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.GetActivityLogEntryResponse.prototype.hasNextEntryId =
  function () {
    return jspb.Message.getField(this, 3) != null;
  };

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.repeatedFields_ = [
  9, 10
];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.ListActivityLogEntriesRequest.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.ListActivityLogEntriesRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.ListActivityLogEntriesRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        activityType: jspb.Message.getFieldWithDefault(msg, 1, ''),
        namespaceId: jspb.Message.getFieldWithDefault(msg, 2, ''),
        userId: jspb.Message.getFieldWithDefault(msg, 3, ''),
        entityType: jspb.Message.getFieldWithDefault(msg, 4, ''),
        entityId: jspb.Message.getFieldWithDefault(msg, 5, ''),
        query: jspb.Message.getFieldWithDefault(msg, 6, ''),
        offset: jspb.Message.getFieldWithDefault(msg, 7, 0),
        limit: jspb.Message.getFieldWithDefault(msg, 8, 0),
        dbVersionsList:
          (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
        recurseNamespace: jspb.Message.getBooleanFieldWithDefault(
          msg,
          11,
          false
        ),
        entitiesList: jspb.Message.toObjectList(
          msg.getEntitiesList(),
          proto.pomerium.dashboard.ListActivityLogEntriesRequest.Entity
            .toObject,
          includeInstance
        ),
        sort:
          (f = msg.getSort()) &&
          proto.pomerium.dashboard.ListActivityLogEntriesRequest.Sort.toObject(
            includeInstance,
            f
          ),
        dateFilter:
          (f = msg.getDateFilter()) &&
          proto.pomerium.dashboard.ListActivityLogEntriesRequest.DateFilter.toObject(
            includeInstance,
            f
          ),
        stringFilter:
          (f = msg.getStringFilter()) &&
          proto.pomerium.dashboard.ListActivityLogEntriesRequest.StringFilter.toObject(
            includeInstance,
            f
          )
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pomerium.dashboard.ListActivityLogEntriesRequest();
    return proto.pomerium.dashboard.ListActivityLogEntriesRequest.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.ListActivityLogEntriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setActivityType(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setNamespaceId(value);
          break;
        case 3:
          var value = /** @type {string} */ (reader.readString());
          msg.setUserId(value);
          break;
        case 4:
          var value = /** @type {string} */ (reader.readString());
          msg.setEntityType(value);
          break;
        case 5:
          var value = /** @type {string} */ (reader.readString());
          msg.setEntityId(value);
          break;
        case 6:
          var value = /** @type {string} */ (reader.readString());
          msg.setQuery(value);
          break;
        case 7:
          var value = /** @type {number} */ (reader.readInt64());
          msg.setOffset(value);
          break;
        case 8:
          var value = /** @type {number} */ (reader.readInt64());
          msg.setLimit(value);
          break;
        case 9:
          var values = /** @type {!Array<number>} */ (
            reader.isDelimited()
              ? reader.readPackedUint64()
              : [reader.readUint64()]
          );
          for (var i = 0; i < values.length; i++) {
            msg.addDbVersions(values[i]);
          }
          break;
        case 11:
          var value = /** @type {boolean} */ (reader.readBool());
          msg.setRecurseNamespace(value);
          break;
        case 10:
          var value =
            new proto.pomerium.dashboard.ListActivityLogEntriesRequest.Entity();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.ListActivityLogEntriesRequest.Entity
              .deserializeBinaryFromReader
          );
          msg.addEntities(value);
          break;
        case 12:
          var value =
            new proto.pomerium.dashboard.ListActivityLogEntriesRequest.Sort();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.ListActivityLogEntriesRequest.Sort
              .deserializeBinaryFromReader
          );
          msg.setSort(value);
          break;
        case 13:
          var value =
            new proto.pomerium.dashboard.ListActivityLogEntriesRequest.DateFilter();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.ListActivityLogEntriesRequest.DateFilter
              .deserializeBinaryFromReader
          );
          msg.setDateFilter(value);
          break;
        case 14:
          var value =
            new proto.pomerium.dashboard.ListActivityLogEntriesRequest.StringFilter();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.ListActivityLogEntriesRequest.StringFilter
              .deserializeBinaryFromReader
          );
          msg.setStringFilter(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.ListActivityLogEntriesRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.ListActivityLogEntriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = /** @type {string} */ (jspb.Message.getField(message, 1));
    if (f != null) {
      writer.writeString(1, f);
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 2));
    if (f != null) {
      writer.writeString(2, f);
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 3));
    if (f != null) {
      writer.writeString(3, f);
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 4));
    if (f != null) {
      writer.writeString(4, f);
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 5));
    if (f != null) {
      writer.writeString(5, f);
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 6));
    if (f != null) {
      writer.writeString(6, f);
    }
    f = /** @type {number} */ (jspb.Message.getField(message, 7));
    if (f != null) {
      writer.writeInt64(7, f);
    }
    f = /** @type {number} */ (jspb.Message.getField(message, 8));
    if (f != null) {
      writer.writeInt64(8, f);
    }
    f = message.getDbVersionsList();
    if (f.length > 0) {
      writer.writePackedUint64(9, f);
    }
    f = /** @type {boolean} */ (jspb.Message.getField(message, 11));
    if (f != null) {
      writer.writeBool(11, f);
    }
    f = message.getEntitiesList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        10,
        f,
        proto.pomerium.dashboard.ListActivityLogEntriesRequest.Entity
          .serializeBinaryToWriter
      );
    }
    f = message.getSort();
    if (f != null) {
      writer.writeMessage(
        12,
        f,
        proto.pomerium.dashboard.ListActivityLogEntriesRequest.Sort
          .serializeBinaryToWriter
      );
    }
    f = message.getDateFilter();
    if (f != null) {
      writer.writeMessage(
        13,
        f,
        proto.pomerium.dashboard.ListActivityLogEntriesRequest.DateFilter
          .serializeBinaryToWriter
      );
    }
    f = message.getStringFilter();
    if (f != null) {
      writer.writeMessage(
        14,
        f,
        proto.pomerium.dashboard.ListActivityLogEntriesRequest.StringFilter
          .serializeBinaryToWriter
      );
    }
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.ListActivityLogEntriesRequest.Entity.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.ListActivityLogEntriesRequest.Entity.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.ListActivityLogEntriesRequest.Entity} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.ListActivityLogEntriesRequest.Entity.toObject =
    function (includeInstance, msg) {
      var f,
        obj = {
          type: jspb.Message.getFieldWithDefault(msg, 1, ''),
          id: jspb.Message.getFieldWithDefault(msg, 2, '')
        };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }
      return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest.Entity}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.Entity.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg =
      new proto.pomerium.dashboard.ListActivityLogEntriesRequest.Entity();
    return proto.pomerium.dashboard.ListActivityLogEntriesRequest.Entity.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.ListActivityLogEntriesRequest.Entity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest.Entity}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.Entity.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setType(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setId(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.Entity.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.ListActivityLogEntriesRequest.Entity.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.ListActivityLogEntriesRequest.Entity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.Entity.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getType();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
    f = message.getId();
    if (f.length > 0) {
      writer.writeString(2, f);
    }
  };

/**
 * optional string type = 1;
 * @return {string}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.Entity.prototype.getType =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest.Entity} returns this
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.Entity.prototype.setType =
  function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
  };

/**
 * optional string id = 2;
 * @return {string}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.Entity.prototype.getId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 2, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest.Entity} returns this
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.Entity.prototype.setId =
  function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.ListActivityLogEntriesRequest.Sort.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.ListActivityLogEntriesRequest.Sort.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.ListActivityLogEntriesRequest.Sort} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.ListActivityLogEntriesRequest.Sort.toObject =
    function (includeInstance, msg) {
      var f,
        obj = {
          column: jspb.Message.getFieldWithDefault(msg, 1, ''),
          direction: jspb.Message.getFieldWithDefault(msg, 2, '')
        };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }
      return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest.Sort}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.Sort.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pomerium.dashboard.ListActivityLogEntriesRequest.Sort();
    return proto.pomerium.dashboard.ListActivityLogEntriesRequest.Sort.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.ListActivityLogEntriesRequest.Sort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest.Sort}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.Sort.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setColumn(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setDirection(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.Sort.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.ListActivityLogEntriesRequest.Sort.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.ListActivityLogEntriesRequest.Sort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.Sort.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getColumn();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
    f = message.getDirection();
    if (f.length > 0) {
      writer.writeString(2, f);
    }
  };

/**
 * optional string column = 1;
 * @return {string}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.Sort.prototype.getColumn =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest.Sort} returns this
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.Sort.prototype.setColumn =
  function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
  };

/**
 * optional string direction = 2;
 * @return {string}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.Sort.prototype.getDirection =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 2, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest.Sort} returns this
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.Sort.prototype.setDirection =
  function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.ListActivityLogEntriesRequest.DateFilter.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.ListActivityLogEntriesRequest.DateFilter.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.ListActivityLogEntriesRequest.DateFilter} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.ListActivityLogEntriesRequest.DateFilter.toObject =
    function (includeInstance, msg) {
      var f,
        obj = {
          operator: jspb.Message.getFieldWithDefault(msg, 1, ''),
          date:
            (f = msg.getDate()) &&
            google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
        };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }
      return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest.DateFilter}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.DateFilter.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg =
      new proto.pomerium.dashboard.ListActivityLogEntriesRequest.DateFilter();
    return proto.pomerium.dashboard.ListActivityLogEntriesRequest.DateFilter.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.ListActivityLogEntriesRequest.DateFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest.DateFilter}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.DateFilter.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setOperator(value);
          break;
        case 2:
          var value = new google_protobuf_timestamp_pb.Timestamp();
          reader.readMessage(
            value,
            google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
          );
          msg.setDate(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.DateFilter.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.ListActivityLogEntriesRequest.DateFilter.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.ListActivityLogEntriesRequest.DateFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.DateFilter.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getOperator();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
    f = message.getDate();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
      );
    }
  };

/**
 * optional string operator = 1;
 * @return {string}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.DateFilter.prototype.getOperator =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest.DateFilter} returns this
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.DateFilter.prototype.setOperator =
  function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
  };

/**
 * optional google.protobuf.Timestamp date = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.DateFilter.prototype.getDate =
  function () {
    return /** @type{?proto.google.protobuf.Timestamp} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_timestamp_pb.Timestamp,
        2
      )
    );
  };

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest.DateFilter} returns this
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.DateFilter.prototype.setDate =
  function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest.DateFilter} returns this
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.DateFilter.prototype.clearDate =
  function () {
    return this.setDate(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.DateFilter.prototype.hasDate =
  function () {
    return jspb.Message.getField(this, 2) != null;
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.ListActivityLogEntriesRequest.StringFilter.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.ListActivityLogEntriesRequest.StringFilter.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.ListActivityLogEntriesRequest.StringFilter} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.ListActivityLogEntriesRequest.StringFilter.toObject =
    function (includeInstance, msg) {
      var f,
        obj = {
          fieldname: jspb.Message.getFieldWithDefault(msg, 1, ''),
          operator: jspb.Message.getFieldWithDefault(msg, 2, ''),
          value: jspb.Message.getFieldWithDefault(msg, 3, '')
        };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }
      return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest.StringFilter}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.StringFilter.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg =
      new proto.pomerium.dashboard.ListActivityLogEntriesRequest.StringFilter();
    return proto.pomerium.dashboard.ListActivityLogEntriesRequest.StringFilter.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.ListActivityLogEntriesRequest.StringFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest.StringFilter}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.StringFilter.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setFieldname(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setOperator(value);
          break;
        case 3:
          var value = /** @type {string} */ (reader.readString());
          msg.setValue(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.StringFilter.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.ListActivityLogEntriesRequest.StringFilter.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.ListActivityLogEntriesRequest.StringFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.StringFilter.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getFieldname();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
    f = message.getOperator();
    if (f.length > 0) {
      writer.writeString(2, f);
    }
    f = message.getValue();
    if (f.length > 0) {
      writer.writeString(3, f);
    }
  };

/**
 * optional string fieldName = 1;
 * @return {string}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.StringFilter.prototype.getFieldname =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest.StringFilter} returns this
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.StringFilter.prototype.setFieldname =
  function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
  };

/**
 * optional string operator = 2;
 * @return {string}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.StringFilter.prototype.getOperator =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 2, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest.StringFilter} returns this
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.StringFilter.prototype.setOperator =
  function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
  };

/**
 * optional string value = 3;
 * @return {string}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.StringFilter.prototype.getValue =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 3, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest.StringFilter} returns this
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.StringFilter.prototype.setValue =
  function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
  };

/**
 * optional string activity_type = 1;
 * @return {string}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.getActivityType =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest} returns this
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.setActivityType =
  function (value) {
    return jspb.Message.setField(this, 1, value);
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest} returns this
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.clearActivityType =
  function () {
    return jspb.Message.setField(this, 1, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.hasActivityType =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

/**
 * optional string namespace_id = 2;
 * @return {string}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.getNamespaceId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 2, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest} returns this
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.setNamespaceId =
  function (value) {
    return jspb.Message.setField(this, 2, value);
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest} returns this
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.clearNamespaceId =
  function () {
    return jspb.Message.setField(this, 2, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.hasNamespaceId =
  function () {
    return jspb.Message.getField(this, 2) != null;
  };

/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.getUserId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 3, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest} returns this
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.setUserId =
  function (value) {
    return jspb.Message.setField(this, 3, value);
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest} returns this
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.clearUserId =
  function () {
    return jspb.Message.setField(this, 3, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.hasUserId =
  function () {
    return jspb.Message.getField(this, 3) != null;
  };

/**
 * optional string entity_type = 4;
 * @return {string}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.getEntityType =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 4, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest} returns this
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.setEntityType =
  function (value) {
    return jspb.Message.setField(this, 4, value);
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest} returns this
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.clearEntityType =
  function () {
    return jspb.Message.setField(this, 4, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.hasEntityType =
  function () {
    return jspb.Message.getField(this, 4) != null;
  };

/**
 * optional string entity_id = 5;
 * @return {string}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.getEntityId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 5, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest} returns this
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.setEntityId =
  function (value) {
    return jspb.Message.setField(this, 5, value);
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest} returns this
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.clearEntityId =
  function () {
    return jspb.Message.setField(this, 5, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.hasEntityId =
  function () {
    return jspb.Message.getField(this, 5) != null;
  };

/**
 * optional string query = 6;
 * @return {string}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.getQuery =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 6, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest} returns this
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.setQuery =
  function (value) {
    return jspb.Message.setField(this, 6, value);
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest} returns this
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.clearQuery =
  function () {
    return jspb.Message.setField(this, 6, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.hasQuery =
  function () {
    return jspb.Message.getField(this, 6) != null;
  };

/**
 * optional int64 offset = 7;
 * @return {number}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.getOffset =
  function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
  };

/**
 * @param {number} value
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest} returns this
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.setOffset =
  function (value) {
    return jspb.Message.setField(this, 7, value);
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest} returns this
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.clearOffset =
  function () {
    return jspb.Message.setField(this, 7, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.hasOffset =
  function () {
    return jspb.Message.getField(this, 7) != null;
  };

/**
 * optional int64 limit = 8;
 * @return {number}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.getLimit =
  function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
  };

/**
 * @param {number} value
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest} returns this
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.setLimit =
  function (value) {
    return jspb.Message.setField(this, 8, value);
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest} returns this
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.clearLimit =
  function () {
    return jspb.Message.setField(this, 8, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.hasLimit =
  function () {
    return jspb.Message.getField(this, 8) != null;
  };

/**
 * repeated uint64 db_versions = 9;
 * @return {!Array<number>}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.getDbVersionsList =
  function () {
    return /** @type {!Array<number>} */ (
      jspb.Message.getRepeatedField(this, 9)
    );
  };

/**
 * @param {!Array<number>} value
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest} returns this
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.setDbVersionsList =
  function (value) {
    return jspb.Message.setField(this, 9, value || []);
  };

/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest} returns this
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.addDbVersions =
  function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
  };

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest} returns this
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.clearDbVersionsList =
  function () {
    return this.setDbVersionsList([]);
  };

/**
 * optional bool recurse_namespace = 11;
 * @return {boolean}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.getRecurseNamespace =
  function () {
    return /** @type {boolean} */ (
      jspb.Message.getBooleanFieldWithDefault(this, 11, false)
    );
  };

/**
 * @param {boolean} value
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest} returns this
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.setRecurseNamespace =
  function (value) {
    return jspb.Message.setField(this, 11, value);
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest} returns this
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.clearRecurseNamespace =
  function () {
    return jspb.Message.setField(this, 11, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.hasRecurseNamespace =
  function () {
    return jspb.Message.getField(this, 11) != null;
  };

/**
 * repeated Entity entities = 10;
 * @return {!Array<!proto.pomerium.dashboard.ListActivityLogEntriesRequest.Entity>}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.getEntitiesList =
  function () {
    return /** @type{!Array<!proto.pomerium.dashboard.ListActivityLogEntriesRequest.Entity>} */ (
      jspb.Message.getRepeatedWrapperField(
        this,
        proto.pomerium.dashboard.ListActivityLogEntriesRequest.Entity,
        10
      )
    );
  };

/**
 * @param {!Array<!proto.pomerium.dashboard.ListActivityLogEntriesRequest.Entity>} value
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest} returns this
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.setEntitiesList =
  function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 10, value);
  };

/**
 * @param {!proto.pomerium.dashboard.ListActivityLogEntriesRequest.Entity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest.Entity}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.addEntities =
  function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(
      this,
      10,
      opt_value,
      proto.pomerium.dashboard.ListActivityLogEntriesRequest.Entity,
      opt_index
    );
  };

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest} returns this
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.clearEntitiesList =
  function () {
    return this.setEntitiesList([]);
  };

/**
 * optional Sort sort = 12;
 * @return {?proto.pomerium.dashboard.ListActivityLogEntriesRequest.Sort}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.getSort =
  function () {
    return /** @type{?proto.pomerium.dashboard.ListActivityLogEntriesRequest.Sort} */ (
      jspb.Message.getWrapperField(
        this,
        proto.pomerium.dashboard.ListActivityLogEntriesRequest.Sort,
        12
      )
    );
  };

/**
 * @param {?proto.pomerium.dashboard.ListActivityLogEntriesRequest.Sort|undefined} value
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest} returns this
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.setSort =
  function (value) {
    return jspb.Message.setWrapperField(this, 12, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest} returns this
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.clearSort =
  function () {
    return this.setSort(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.hasSort =
  function () {
    return jspb.Message.getField(this, 12) != null;
  };

/**
 * optional DateFilter date_filter = 13;
 * @return {?proto.pomerium.dashboard.ListActivityLogEntriesRequest.DateFilter}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.getDateFilter =
  function () {
    return /** @type{?proto.pomerium.dashboard.ListActivityLogEntriesRequest.DateFilter} */ (
      jspb.Message.getWrapperField(
        this,
        proto.pomerium.dashboard.ListActivityLogEntriesRequest.DateFilter,
        13
      )
    );
  };

/**
 * @param {?proto.pomerium.dashboard.ListActivityLogEntriesRequest.DateFilter|undefined} value
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest} returns this
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.setDateFilter =
  function (value) {
    return jspb.Message.setWrapperField(this, 13, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest} returns this
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.clearDateFilter =
  function () {
    return this.setDateFilter(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.hasDateFilter =
  function () {
    return jspb.Message.getField(this, 13) != null;
  };

/**
 * optional StringFilter string_filter = 14;
 * @return {?proto.pomerium.dashboard.ListActivityLogEntriesRequest.StringFilter}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.getStringFilter =
  function () {
    return /** @type{?proto.pomerium.dashboard.ListActivityLogEntriesRequest.StringFilter} */ (
      jspb.Message.getWrapperField(
        this,
        proto.pomerium.dashboard.ListActivityLogEntriesRequest.StringFilter,
        14
      )
    );
  };

/**
 * @param {?proto.pomerium.dashboard.ListActivityLogEntriesRequest.StringFilter|undefined} value
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest} returns this
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.setStringFilter =
  function (value) {
    return jspb.Message.setWrapperField(this, 14, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesRequest} returns this
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.clearStringFilter =
  function () {
    return this.setStringFilter(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.ListActivityLogEntriesRequest.prototype.hasStringFilter =
  function () {
    return jspb.Message.getField(this, 14) != null;
  };

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pomerium.dashboard.ListActivityLogEntriesResponse.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.ListActivityLogEntriesResponse.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.ListActivityLogEntriesResponse.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.ListActivityLogEntriesResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.ListActivityLogEntriesResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        entriesList: jspb.Message.toObjectList(
          msg.getEntriesList(),
          proto.pomerium.dashboard.ActivityLogEntry.toObject,
          includeInstance
        ),
        totalCount: jspb.Message.getFieldWithDefault(msg, 2, 0)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesResponse}
 */
proto.pomerium.dashboard.ListActivityLogEntriesResponse.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pomerium.dashboard.ListActivityLogEntriesResponse();
    return proto.pomerium.dashboard.ListActivityLogEntriesResponse.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.ListActivityLogEntriesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesResponse}
 */
proto.pomerium.dashboard.ListActivityLogEntriesResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.ActivityLogEntry();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.ActivityLogEntry
              .deserializeBinaryFromReader
          );
          msg.addEntries(value);
          break;
        case 2:
          var value = /** @type {number} */ (reader.readInt64());
          msg.setTotalCount(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.ListActivityLogEntriesResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.ListActivityLogEntriesResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.ListActivityLogEntriesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.ListActivityLogEntriesResponse.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getEntriesList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        proto.pomerium.dashboard.ActivityLogEntry.serializeBinaryToWriter
      );
    }
    f = message.getTotalCount();
    if (f !== 0) {
      writer.writeInt64(2, f);
    }
  };

/**
 * repeated ActivityLogEntry entries = 1;
 * @return {!Array<!proto.pomerium.dashboard.ActivityLogEntry>}
 */
proto.pomerium.dashboard.ListActivityLogEntriesResponse.prototype.getEntriesList =
  function () {
    return /** @type{!Array<!proto.pomerium.dashboard.ActivityLogEntry>} */ (
      jspb.Message.getRepeatedWrapperField(
        this,
        proto.pomerium.dashboard.ActivityLogEntry,
        1
      )
    );
  };

/**
 * @param {!Array<!proto.pomerium.dashboard.ActivityLogEntry>} value
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesResponse} returns this
 */
proto.pomerium.dashboard.ListActivityLogEntriesResponse.prototype.setEntriesList =
  function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
  };

/**
 * @param {!proto.pomerium.dashboard.ActivityLogEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.ActivityLogEntry}
 */
proto.pomerium.dashboard.ListActivityLogEntriesResponse.prototype.addEntries =
  function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(
      this,
      1,
      opt_value,
      proto.pomerium.dashboard.ActivityLogEntry,
      opt_index
    );
  };

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesResponse} returns this
 */
proto.pomerium.dashboard.ListActivityLogEntriesResponse.prototype.clearEntriesList =
  function () {
    return this.setEntriesList([]);
  };

/**
 * optional int64 total_count = 2;
 * @return {number}
 */
proto.pomerium.dashboard.ListActivityLogEntriesResponse.prototype.getTotalCount =
  function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };

/**
 * @param {number} value
 * @return {!proto.pomerium.dashboard.ListActivityLogEntriesResponse} returns this
 */
proto.pomerium.dashboard.ListActivityLogEntriesResponse.prototype.setTotalCount =
  function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
  };

goog.object.extend(exports, proto.pomerium.dashboard);
