import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { pushError, pushSuccess } from './notifications';

import {
  NamespaceServiceClient,
  NamespacePermissionServiceClient
} from 'src/pb/NamespacesServiceClientPb';
import { UserServiceClient } from 'src/pb/UsersServiceClientPb';
import {
  ListNamespacePermissionGroupsRequest,
  ListNamespacePermissionGroupsResponse,
  ListNamespacePermissionsResponse,
  ListNamespacePermissionUsersResponse,
  ListNamespacesRequest,
  ListNamespacePermissionsRequest,
  ListNamespacePermissionUsersRequest,
  SetNamespacePermissionRequest,
  DeleteNamespacePermissionRequest,
  NamespacePermission,
  SetNamespaceRequest,
  Namespace,
  DeleteNamespaceRequest
} from 'src/pb/namespaces_pb';
import { GetUserInfoRequest } from 'src/pb/users_pb';
import history from 'src/store/history';
import { AppThunk, NamespaceState } from 'src/store/state';
import { NamespaceTree, NamespaceTreeNode } from 'src/types/namespace_tree';
import { getErrorMessage } from 'src/utils/errors';
import { getClient, getMetadata } from 'src/utils/grpc';
import { replace } from 'src/utils/namespaceHelper';

const initialTree = new NamespaceTree([]);

const initialState: NamespaceState = {
  currentNamespace: initialTree.root,
  tree: initialTree,
  permissions: null,
  userPermissions: null,
  groupPermissions: null,
  saving: false,
  enableSelector: true
};

const slice = createSlice({
  name: 'namespaces',
  initialState,
  reducers: {
    getNamespaceTree(
      state: NamespaceState,
      action: PayloadAction<NamespaceTree>
    ): void {
      const currentId = state?.currentNamespace?.id;
      state.currentNamespace =
        action.payload.get(currentId) || action.payload.root;
      state.tree = action.payload;
    },

    setCurrentNamespace(
      state: NamespaceState,
      action: PayloadAction<NamespaceTreeNode>
    ): void {
      state.currentNamespace = action.payload;
    },

    listPermissions(
      state: NamespaceState,
      action: PayloadAction<ListNamespacePermissionsResponse>
    ): void {
      state.permissions = action?.payload?.getNamespacePermissionsList();
    },

    listGroupPermissions(
      state: NamespaceState,
      action: PayloadAction<ListNamespacePermissionGroupsResponse>
    ): void {
      state.groupPermissions = action?.payload?.getGroupsList();
    },

    listUserPermissions(
      state: NamespaceState,
      action: PayloadAction<ListNamespacePermissionUsersResponse>
    ): void {
      state.userPermissions = action?.payload?.getUsersList();
    },

    setSaving(state: NamespaceState, action: PayloadAction<boolean>): void {
      state.saving = action.payload;
    },

    setEnableSelector(
      state: NamespaceState,
      action: PayloadAction<boolean>
    ): void {
      state.enableSelector = action.payload;
    }
  }
});

export const { reducer } = slice;

const namespaceClient = getClient(NamespaceServiceClient);
const namespacePermissionClient = getClient(NamespacePermissionServiceClient);
const userClient = getClient(UserServiceClient);

export const getNamespaceTree =
  (): AppThunk =>
  async (dispatch, getState): Promise<NamespaceTree> => {
    const req = new ListNamespacesRequest();
    const userReq = new GetUserInfoRequest();
    const [res, userRes] = await Promise.all([
      namespaceClient.listNamespaces(req, getMetadata(getState().session)),
      userClient.getUserInfo(userReq, getMetadata(getState().session))
    ]);
    const tree = new NamespaceTree(res.getNamespacesList());
    tree.forEach((n): void => {
      n.permission = userRes.getUserInfo().getNamespaceRolesMap().get(n.id);
    });
    const action = dispatch(slice.actions.getNamespaceTree(tree));
    return action?.payload;
  };

export const deleteNamespace =
  (id: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const req = new DeleteNamespaceRequest();
    req.setId(id);
    try {
      await namespaceClient.deleteNamespace(
        req,
        getMetadata(getState().session)
      );
      dispatch(pushSuccess('Namespace ' + id + ' Deleted!'));
    } catch (e) {
      dispatch(pushError(getErrorMessage(e)));
      return;
    }
    dispatch(getNamespaceTree());
  };

export const setNamespace =
  (namespace: Namespace): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(slice.actions.setSaving(true));
    const req = new SetNamespaceRequest().setNamespace(namespace);
    try {
      await namespaceClient.setNamespace(req, getMetadata(getState().session));
    } catch (e) {
      dispatch(pushError(getErrorMessage(e)));
      return;
    } finally {
      dispatch(slice.actions.setSaving(false));
    }
    dispatch(getNamespaceTree());
  };

export const setCurrentNamespace =
  (treeNode: NamespaceTreeNode): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(replace(history.location, treeNode?.id));
    dispatch(slice.actions.setCurrentNamespace(treeNode));
    localStorage.setItem('mostRecentlyUsedNamespace', treeNode.id);
  };

export const listPermissions =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const req = new ListNamespacePermissionsRequest();
    const res = await namespacePermissionClient.listNamespacePermissions(
      req,
      getMetadata(getState().session)
    );
    dispatch(slice.actions.listPermissions(res));
  };

export const listGroupPermissions =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const req = new ListNamespacePermissionGroupsRequest();
    req.setNamespaceId(getState().namespaces.currentNamespace.id);
    try {
      const res = await namespacePermissionClient.listNamespacePermissionGroups(
        req,
        getMetadata(getState().session)
      );
      dispatch(slice.actions.listGroupPermissions(res));
    } catch (e) {
      dispatch(pushError(getErrorMessage(e)));
    }
  };

export const listUserPermissions =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const req = new ListNamespacePermissionUsersRequest();
    req.setNamespaceId(getState().namespaces.currentNamespace.id);
    try {
      const res = await namespacePermissionClient.listNamespacePermissionUsers(
        req,
        getMetadata(getState().session)
      );
      dispatch(slice.actions.listUserPermissions(res));
    } catch (e) {
      dispatch(pushError(getErrorMessage(e)));
    }
  };

export const setPermissions =
  (permission: NamespacePermission): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(slice.actions.listPermissions(null));
    const req = new SetNamespacePermissionRequest();
    req.setNamespacePermission(permission);
    try {
      await namespacePermissionClient.setNamespacePermission(
        req,
        getMetadata(getState().session)
      );
    } catch (e) {
      dispatch(pushError(getErrorMessage(e)));
    } finally {
      const greq = new ListNamespacePermissionsRequest();
      const gres = await namespacePermissionClient.listNamespacePermissions(
        greq,
        getMetadata(getState().session)
      );
      dispatch(slice.actions.listPermissions(gres));
    }
  };

export const deletePermissions =
  (subjectIds: Iterable<string>): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    for (const subjectId of subjectIds) {
      const req = new DeleteNamespacePermissionRequest();
      const permission = getState().namespaces.permissions.find(
        (permission) => permission.getSubjectId() === subjectId
      );
      req.setId(permission.getId());
      try {
        await namespacePermissionClient.deleteNamespacePermission(
          req,
          getMetadata(getState().session)
        );
      } catch (e) {
        dispatch(pushError(getErrorMessage(e)));
      }
    }

    try {
      const listUserReq = new ListNamespacePermissionUsersRequest();
      listUserReq.setNamespaceId(getState().namespaces.currentNamespace.id);
      const listUserRes =
        await namespacePermissionClient.listNamespacePermissionUsers(
          listUserReq,
          getMetadata(getState().session)
        );
      dispatch(slice.actions.listUserPermissions(listUserRes));
      const listGroupReq = new ListNamespacePermissionGroupsRequest();
      listGroupReq.setNamespaceId(getState().namespaces.currentNamespace.id);
      const listGroupRes =
        await namespacePermissionClient.listNamespacePermissionGroups(
          listGroupReq,
          getMetadata(getState().session)
        );
      dispatch(slice.actions.listGroupPermissions(listGroupRes));
    } catch (e) {
      dispatch(pushError(getErrorMessage(e)));
    } finally {
      const getAllReq = new ListNamespacePermissionsRequest();
      const getAllRes =
        await namespacePermissionClient.listNamespacePermissions(
          getAllReq,
          getMetadata(getState().session)
        );
      dispatch(slice.actions.listPermissions(getAllRes));
    }
  };

export const enableNamespaceSelector =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setEnableSelector(true));
  };

export const disableNamespaceSelector =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setEnableSelector(false));
  };
