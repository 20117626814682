import * as Sentry from '@sentry/react';
import { enableES5 } from 'immer';
import 'nprogress/nprogress.css';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Provider } from 'react-redux';

import { getUserInfo } from './slices/session';

import App from 'src/App';
import { SettingsProvider } from 'src/context/SettingsContext';
import store from 'src/store';

enableES5();

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://06f1509397004cd0937a60c27e081f41@o845499.ingest.sentry.io/5814581'
  });
}

(async (): Promise<void> => {
  let err = '';
  try {
    await store.dispatch(getUserInfo());
  } catch (e) {
    err = `${e?.message || e}`;
  }

  // this happens if the user isn't logged in
  // so we will display the sign in form
  if (err === 'session ID not found') {
    err = null;
  }

  ReactDOM.render(
    <Provider store={store}>
      <SettingsProvider>
        <Sentry.ErrorBoundary>
          <App error={err} />
        </Sentry.ErrorBoundary>
      </SettingsProvider>
    </Provider>,
    document.getElementById('root')
  );
})();
