import MuiLink from '@mui/material/Link';
import { LinkProps as MuiLinkProps } from '@mui/material/Link/Link';
import { GridCellValue } from '@mui/x-data-grid/models/gridCell';
import { GridValueGetterParams } from '@mui/x-data-grid/models/params/gridCellParams';
import {
  CallHistoryMethodAction,
  push as routerPush,
  replace as routerReplace
} from 'connected-react-router';
import { LocationDescriptorObject, LocationState } from 'history';
import _ from 'lodash';
import React from 'react';
import {
  Redirect as RouterRedirect,
  RedirectProps as RouterRedirectProps
} from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import store, { useSelector } from 'src/store';
import history from 'src/store/history';
import {
  NamespaceTree,
  NamespaceTreeNode,
  ROOT_NAMESPACE_ID,
  ROOT_NAMESPACE_NAME
} from 'src/types/namespace_tree';

export const getNamespaceName = ({
  namespaceId,
  namespaceName
}: {
  namespaceId?: string;
  namespaceName?: string;
}): string => {
  return namespaceId === ROOT_NAMESPACE_ID
    ? ROOT_NAMESPACE_NAME
    : namespaceName;
};

export const getNamespaceNameFromNamespaceId = (
  namespaceId: string
): string => {
  const tree = store.getState().namespaces.tree;
  return namespaceId === ROOT_NAMESPACE_ID
    ? ROOT_NAMESPACE_NAME
    : tree.get(namespaceId)?.name;
};

export function GridNamespaceValue(
  params: GridValueGetterParams
): GridCellValue {
  return getNamespaceNameFromNamespaceId(params.row.namespaceId);
}

type Location = LocationDescriptorObject<LocationState>;

export function appendNamespace(
  location: Location,
  namespaceId?: string
): Location {
  if (!namespaceId)
    namespaceId = new URLSearchParams(history.location.search).get('namespace');

  const obj = { ...location };
  if (namespaceId) {
    const params = new URLSearchParams(location?.search);
    params.set('namespace', namespaceId);
    obj.search = '?' + params.toString();
  }
  return obj;
}

export const push = (
  location: Location,
  namespaceId?: string
): CallHistoryMethodAction<[Location]> => {
  return routerPush(appendNamespace(location, namespaceId));
};

export const replace = (
  location: Location,
  namespaceId?: string
): CallHistoryMethodAction<[Location]> => {
  return routerReplace(appendNamespace(location, namespaceId));
};

interface CurrentNamespaceSelector {
  currentNamespace: NamespaceTreeNode;
  push: (location: Location) => CallHistoryMethodAction<[Location]>;
  replace: (location: Location) => CallHistoryMethodAction<[Location]>;
}
export const useCurrentNamespaceSelector = (): CurrentNamespaceSelector => {
  const { currentNamespace } = useSelector((state) => state.namespaces);
  return {
    currentNamespace: currentNamespace,
    push(location: Location): CallHistoryMethodAction<[Location]> {
      return routerPush(appendNamespace(location, currentNamespace?.id));
    },
    replace(location: Location): CallHistoryMethodAction<[Location]> {
      return routerReplace(appendNamespace(location, currentNamespace?.id));
    }
  };
};

type RedirectProps = RouterRedirectProps & { to: Location };
export const Redirect = ({ to, ...props }: RedirectProps): JSX.Element => {
  const { currentNamespace } = useSelector((state) => state.namespaces);
  return (
    <RouterRedirect {...props} to={appendNamespace(to, currentNamespace?.id)} />
  );
};

type LinkProps = MuiLinkProps & { to: Location };
export const Link = ({ to, ...props }: LinkProps): JSX.Element => {
  const { currentNamespace } = useSelector((state) => state.namespaces);
  return (
    <MuiLink
      {...props}
      component={RouterLink}
      to={appendNamespace(to, currentNamespace?.id)}
    />
  );
};

export interface NamespaceListOption {
  id: string;
  name: string;
  path: [];
}

export const generateNamespaceListOptions = (
  tree: NamespaceTree
): Array<NamespaceListOption> => {
  const options = [];
  const trim = (node: NamespaceTreeNode): void => {
    const path = [node.name].concat(tree.ancestors(node).map((n) => n.name));
    options.push({
      id: node.id,
      name: node.name,
      path: path.reverse() || []
    } as NamespaceListOption);
  };
  tree.forEach(trim);
  return _.sortBy(options, (o) => o.name.toLowerCase());
};
