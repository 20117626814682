// source: key_chain.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.pomerium.dashboard.CertificateInfo', null, global);
goog.exportSymbol(
  'proto.pomerium.dashboard.CreateKeyPairRequest',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.CreateKeyPairResponse',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.DeleteKeyPairRequest',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.DeleteKeyPairResponse',
  null,
  global
);
goog.exportSymbol('proto.pomerium.dashboard.Format', null, global);
goog.exportSymbol('proto.pomerium.dashboard.GetKeyPairRequest', null, global);
goog.exportSymbol('proto.pomerium.dashboard.GetKeyPairResponse', null, global);
goog.exportSymbol('proto.pomerium.dashboard.KeyPair', null, global);
goog.exportSymbol('proto.pomerium.dashboard.KeyPairRecord', null, global);
goog.exportSymbol('proto.pomerium.dashboard.KeyUsage', null, global);
goog.exportSymbol('proto.pomerium.dashboard.ListKeyPairsRequest', null, global);
goog.exportSymbol(
  'proto.pomerium.dashboard.ListKeyPairsResponse',
  null,
  global
);
goog.exportSymbol('proto.pomerium.dashboard.Name', null, global);
goog.exportSymbol('proto.pomerium.dashboard.PublicKeyAlgorithm', null, global);
goog.exportSymbol(
  'proto.pomerium.dashboard.UpdateKeyPairRequest',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.UpdateKeyPairResponse',
  null,
  global
);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.KeyPair = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.KeyPair, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.KeyPair.displayName =
    'proto.pomerium.dashboard.KeyPair';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.KeyUsage = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.KeyUsage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.KeyUsage.displayName =
    'proto.pomerium.dashboard.KeyUsage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.Name = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.pomerium.dashboard.Name.repeatedFields_,
    null
  );
};
goog.inherits(proto.pomerium.dashboard.Name, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.Name.displayName = 'proto.pomerium.dashboard.Name';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.CertificateInfo = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.pomerium.dashboard.CertificateInfo.repeatedFields_,
    null
  );
};
goog.inherits(proto.pomerium.dashboard.CertificateInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.CertificateInfo.displayName =
    'proto.pomerium.dashboard.CertificateInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.KeyPairRecord = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.KeyPairRecord, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.KeyPairRecord.displayName =
    'proto.pomerium.dashboard.KeyPairRecord';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.DeleteKeyPairRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.DeleteKeyPairRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.DeleteKeyPairRequest.displayName =
    'proto.pomerium.dashboard.DeleteKeyPairRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.DeleteKeyPairResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.DeleteKeyPairResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.DeleteKeyPairResponse.displayName =
    'proto.pomerium.dashboard.DeleteKeyPairResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.GetKeyPairRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.GetKeyPairRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.GetKeyPairRequest.displayName =
    'proto.pomerium.dashboard.GetKeyPairRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.GetKeyPairResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.GetKeyPairResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.GetKeyPairResponse.displayName =
    'proto.pomerium.dashboard.GetKeyPairResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.ListKeyPairsRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.ListKeyPairsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.ListKeyPairsRequest.displayName =
    'proto.pomerium.dashboard.ListKeyPairsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.ListKeyPairsResponse = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.pomerium.dashboard.ListKeyPairsResponse.repeatedFields_,
    null
  );
};
goog.inherits(proto.pomerium.dashboard.ListKeyPairsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.ListKeyPairsResponse.displayName =
    'proto.pomerium.dashboard.ListKeyPairsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.CreateKeyPairRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.CreateKeyPairRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.CreateKeyPairRequest.displayName =
    'proto.pomerium.dashboard.CreateKeyPairRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.CreateKeyPairResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.CreateKeyPairResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.CreateKeyPairResponse.displayName =
    'proto.pomerium.dashboard.CreateKeyPairResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.UpdateKeyPairRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.UpdateKeyPairRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.UpdateKeyPairRequest.displayName =
    'proto.pomerium.dashboard.UpdateKeyPairRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.UpdateKeyPairResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.UpdateKeyPairResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.UpdateKeyPairResponse.displayName =
    'proto.pomerium.dashboard.UpdateKeyPairResponse';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.KeyPair.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.KeyPair.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.KeyPair} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.KeyPair.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, ''),
        name: jspb.Message.getFieldWithDefault(msg, 2, ''),
        namespaceId: jspb.Message.getFieldWithDefault(msg, 3, ''),
        createdAt:
          (f = msg.getCreatedAt()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        modifiedAt:
          (f = msg.getModifiedAt()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        certificate: msg.getCertificate_asB64(),
        key: msg.getKey_asB64()
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.KeyPair}
 */
proto.pomerium.dashboard.KeyPair.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.KeyPair();
  return proto.pomerium.dashboard.KeyPair.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.KeyPair} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.KeyPair}
 */
proto.pomerium.dashboard.KeyPair.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setNamespaceId(value);
        break;
      case 4:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        );
        msg.setCreatedAt(value);
        break;
      case 5:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        );
        msg.setModifiedAt(value);
        break;
      case 7:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setCertificate(value);
        break;
      case 8:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setKey(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.KeyPair.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.pomerium.dashboard.KeyPair.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.KeyPair} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.KeyPair.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getNamespaceId();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getModifiedAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCertificate_asU8();
  if (f.length > 0) {
    writer.writeBytes(7, f);
  }
  f = message.getKey_asU8();
  if (f.length > 0) {
    writer.writeBytes(8, f);
  }
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.KeyPair.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.KeyPair} returns this
 */
proto.pomerium.dashboard.KeyPair.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string name = 2;
 * @return {string}
 */
proto.pomerium.dashboard.KeyPair.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.KeyPair} returns this
 */
proto.pomerium.dashboard.KeyPair.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string namespace_id = 3;
 * @return {string}
 */
proto.pomerium.dashboard.KeyPair.prototype.getNamespaceId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.KeyPair} returns this
 */
proto.pomerium.dashboard.KeyPair.prototype.setNamespaceId = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional google.protobuf.Timestamp created_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pomerium.dashboard.KeyPair.prototype.getCreatedAt = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_timestamp_pb.Timestamp,
      4
    )
  );
};

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.pomerium.dashboard.KeyPair} returns this
 */
proto.pomerium.dashboard.KeyPair.prototype.setCreatedAt = function (value) {
  return jspb.Message.setWrapperField(this, 4, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.KeyPair} returns this
 */
proto.pomerium.dashboard.KeyPair.prototype.clearCreatedAt = function () {
  return this.setCreatedAt(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.KeyPair.prototype.hasCreatedAt = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional google.protobuf.Timestamp modified_at = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pomerium.dashboard.KeyPair.prototype.getModifiedAt = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_timestamp_pb.Timestamp,
      5
    )
  );
};

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.pomerium.dashboard.KeyPair} returns this
 */
proto.pomerium.dashboard.KeyPair.prototype.setModifiedAt = function (value) {
  return jspb.Message.setWrapperField(this, 5, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.KeyPair} returns this
 */
proto.pomerium.dashboard.KeyPair.prototype.clearModifiedAt = function () {
  return this.setModifiedAt(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.KeyPair.prototype.hasModifiedAt = function () {
  return jspb.Message.getField(this, 5) != null;
};

/**
 * optional bytes certificate = 7;
 * @return {string}
 */
proto.pomerium.dashboard.KeyPair.prototype.getCertificate = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''));
};

/**
 * optional bytes certificate = 7;
 * This is a type-conversion wrapper around `getCertificate()`
 * @return {string}
 */
proto.pomerium.dashboard.KeyPair.prototype.getCertificate_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(this.getCertificate()));
};

/**
 * optional bytes certificate = 7;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCertificate()`
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.KeyPair.prototype.getCertificate_asU8 = function () {
  return /** @type {!Uint8Array} */ (
    jspb.Message.bytesAsU8(this.getCertificate())
  );
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.pomerium.dashboard.KeyPair} returns this
 */
proto.pomerium.dashboard.KeyPair.prototype.setCertificate = function (value) {
  return jspb.Message.setProto3BytesField(this, 7, value);
};

/**
 * optional bytes key = 8;
 * @return {string}
 */
proto.pomerium.dashboard.KeyPair.prototype.getKey = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ''));
};

/**
 * optional bytes key = 8;
 * This is a type-conversion wrapper around `getKey()`
 * @return {string}
 */
proto.pomerium.dashboard.KeyPair.prototype.getKey_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(this.getKey()));
};

/**
 * optional bytes key = 8;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getKey()`
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.KeyPair.prototype.getKey_asU8 = function () {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getKey()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.pomerium.dashboard.KeyPair} returns this
 */
proto.pomerium.dashboard.KeyPair.prototype.setKey = function (value) {
  return jspb.Message.setProto3BytesField(this, 8, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.KeyUsage.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.KeyUsage.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.KeyUsage} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.KeyUsage.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        digitalSignature: jspb.Message.getBooleanFieldWithDefault(
          msg,
          1,
          false
        ),
        contentCommitment: jspb.Message.getBooleanFieldWithDefault(
          msg,
          2,
          false
        ),
        keyEncipherment: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
        dataEncipherment: jspb.Message.getBooleanFieldWithDefault(
          msg,
          4,
          false
        ),
        keyAgreement: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
        certSign: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
        crlSign: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
        encipherOnly: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
        decipherOnly: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
        serverAuth: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
        clientAuth: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.KeyUsage}
 */
proto.pomerium.dashboard.KeyUsage.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.KeyUsage();
  return proto.pomerium.dashboard.KeyUsage.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.KeyUsage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.KeyUsage}
 */
proto.pomerium.dashboard.KeyUsage.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setDigitalSignature(value);
        break;
      case 2:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setContentCommitment(value);
        break;
      case 3:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setKeyEncipherment(value);
        break;
      case 4:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setDataEncipherment(value);
        break;
      case 5:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setKeyAgreement(value);
        break;
      case 6:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setCertSign(value);
        break;
      case 7:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setCrlSign(value);
        break;
      case 8:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setEncipherOnly(value);
        break;
      case 9:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setDecipherOnly(value);
        break;
      case 10:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setServerAuth(value);
        break;
      case 11:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setClientAuth(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.KeyUsage.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.pomerium.dashboard.KeyUsage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.KeyUsage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.KeyUsage.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getDigitalSignature();
  if (f) {
    writer.writeBool(1, f);
  }
  f = message.getContentCommitment();
  if (f) {
    writer.writeBool(2, f);
  }
  f = message.getKeyEncipherment();
  if (f) {
    writer.writeBool(3, f);
  }
  f = message.getDataEncipherment();
  if (f) {
    writer.writeBool(4, f);
  }
  f = message.getKeyAgreement();
  if (f) {
    writer.writeBool(5, f);
  }
  f = message.getCertSign();
  if (f) {
    writer.writeBool(6, f);
  }
  f = message.getCrlSign();
  if (f) {
    writer.writeBool(7, f);
  }
  f = message.getEncipherOnly();
  if (f) {
    writer.writeBool(8, f);
  }
  f = message.getDecipherOnly();
  if (f) {
    writer.writeBool(9, f);
  }
  f = message.getServerAuth();
  if (f) {
    writer.writeBool(10, f);
  }
  f = message.getClientAuth();
  if (f) {
    writer.writeBool(11, f);
  }
};

/**
 * optional bool digital_signature = 1;
 * @return {boolean}
 */
proto.pomerium.dashboard.KeyUsage.prototype.getDigitalSignature = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 1, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.pomerium.dashboard.KeyUsage} returns this
 */
proto.pomerium.dashboard.KeyUsage.prototype.setDigitalSignature = function (
  value
) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};

/**
 * optional bool content_commitment = 2;
 * @return {boolean}
 */
proto.pomerium.dashboard.KeyUsage.prototype.getContentCommitment = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 2, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.pomerium.dashboard.KeyUsage} returns this
 */
proto.pomerium.dashboard.KeyUsage.prototype.setContentCommitment = function (
  value
) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};

/**
 * optional bool key_encipherment = 3;
 * @return {boolean}
 */
proto.pomerium.dashboard.KeyUsage.prototype.getKeyEncipherment = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 3, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.pomerium.dashboard.KeyUsage} returns this
 */
proto.pomerium.dashboard.KeyUsage.prototype.setKeyEncipherment = function (
  value
) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};

/**
 * optional bool data_encipherment = 4;
 * @return {boolean}
 */
proto.pomerium.dashboard.KeyUsage.prototype.getDataEncipherment = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 4, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.pomerium.dashboard.KeyUsage} returns this
 */
proto.pomerium.dashboard.KeyUsage.prototype.setDataEncipherment = function (
  value
) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};

/**
 * optional bool key_agreement = 5;
 * @return {boolean}
 */
proto.pomerium.dashboard.KeyUsage.prototype.getKeyAgreement = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 5, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.pomerium.dashboard.KeyUsage} returns this
 */
proto.pomerium.dashboard.KeyUsage.prototype.setKeyAgreement = function (value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};

/**
 * optional bool cert_sign = 6;
 * @return {boolean}
 */
proto.pomerium.dashboard.KeyUsage.prototype.getCertSign = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 6, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.pomerium.dashboard.KeyUsage} returns this
 */
proto.pomerium.dashboard.KeyUsage.prototype.setCertSign = function (value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};

/**
 * optional bool crl_sign = 7;
 * @return {boolean}
 */
proto.pomerium.dashboard.KeyUsage.prototype.getCrlSign = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 7, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.pomerium.dashboard.KeyUsage} returns this
 */
proto.pomerium.dashboard.KeyUsage.prototype.setCrlSign = function (value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};

/**
 * optional bool encipher_only = 8;
 * @return {boolean}
 */
proto.pomerium.dashboard.KeyUsage.prototype.getEncipherOnly = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 8, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.pomerium.dashboard.KeyUsage} returns this
 */
proto.pomerium.dashboard.KeyUsage.prototype.setEncipherOnly = function (value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};

/**
 * optional bool decipher_only = 9;
 * @return {boolean}
 */
proto.pomerium.dashboard.KeyUsage.prototype.getDecipherOnly = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 9, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.pomerium.dashboard.KeyUsage} returns this
 */
proto.pomerium.dashboard.KeyUsage.prototype.setDecipherOnly = function (value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};

/**
 * optional bool server_auth = 10;
 * @return {boolean}
 */
proto.pomerium.dashboard.KeyUsage.prototype.getServerAuth = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 10, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.pomerium.dashboard.KeyUsage} returns this
 */
proto.pomerium.dashboard.KeyUsage.prototype.setServerAuth = function (value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};

/**
 * optional bool client_auth = 11;
 * @return {boolean}
 */
proto.pomerium.dashboard.KeyUsage.prototype.getClientAuth = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 11, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.pomerium.dashboard.KeyUsage} returns this
 */
proto.pomerium.dashboard.KeyUsage.prototype.setClientAuth = function (value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pomerium.dashboard.Name.repeatedFields_ = [1, 2, 3, 4, 5, 6, 7];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.Name.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.Name.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.Name} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.Name.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        countryList:
          (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
        organizationList:
          (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
        organizationalUnitList:
          (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
        localityList:
          (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
        provinceList:
          (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
        streetAddressList:
          (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
        postalCodeList:
          (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
        serialNumber: jspb.Message.getFieldWithDefault(msg, 8, ''),
        commonName: jspb.Message.getFieldWithDefault(msg, 9, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.Name}
 */
proto.pomerium.dashboard.Name.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.Name();
  return proto.pomerium.dashboard.Name.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.Name} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.Name}
 */
proto.pomerium.dashboard.Name.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.addCountry(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.addOrganization(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.addOrganizationalUnit(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.addLocality(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.addProvince(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.addStreetAddress(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.addPostalCode(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setSerialNumber(value);
        break;
      case 9:
        var value = /** @type {string} */ (reader.readString());
        msg.setCommonName(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.Name.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.pomerium.dashboard.Name.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.Name} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.Name.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getCountryList();
  if (f.length > 0) {
    writer.writeRepeatedString(1, f);
  }
  f = message.getOrganizationList();
  if (f.length > 0) {
    writer.writeRepeatedString(2, f);
  }
  f = message.getOrganizationalUnitList();
  if (f.length > 0) {
    writer.writeRepeatedString(3, f);
  }
  f = message.getLocalityList();
  if (f.length > 0) {
    writer.writeRepeatedString(4, f);
  }
  f = message.getProvinceList();
  if (f.length > 0) {
    writer.writeRepeatedString(5, f);
  }
  f = message.getStreetAddressList();
  if (f.length > 0) {
    writer.writeRepeatedString(6, f);
  }
  f = message.getPostalCodeList();
  if (f.length > 0) {
    writer.writeRepeatedString(7, f);
  }
  f = message.getSerialNumber();
  if (f.length > 0) {
    writer.writeString(8, f);
  }
  f = message.getCommonName();
  if (f.length > 0) {
    writer.writeString(9, f);
  }
};

/**
 * repeated string country = 1;
 * @return {!Array<string>}
 */
proto.pomerium.dashboard.Name.prototype.getCountryList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.pomerium.dashboard.Name} returns this
 */
proto.pomerium.dashboard.Name.prototype.setCountryList = function (value) {
  return jspb.Message.setField(this, 1, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.Name} returns this
 */
proto.pomerium.dashboard.Name.prototype.addCountry = function (
  value,
  opt_index
) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.Name} returns this
 */
proto.pomerium.dashboard.Name.prototype.clearCountryList = function () {
  return this.setCountryList([]);
};

/**
 * repeated string organization = 2;
 * @return {!Array<string>}
 */
proto.pomerium.dashboard.Name.prototype.getOrganizationList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.pomerium.dashboard.Name} returns this
 */
proto.pomerium.dashboard.Name.prototype.setOrganizationList = function (value) {
  return jspb.Message.setField(this, 2, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.Name} returns this
 */
proto.pomerium.dashboard.Name.prototype.addOrganization = function (
  value,
  opt_index
) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.Name} returns this
 */
proto.pomerium.dashboard.Name.prototype.clearOrganizationList = function () {
  return this.setOrganizationList([]);
};

/**
 * repeated string organizational_unit = 3;
 * @return {!Array<string>}
 */
proto.pomerium.dashboard.Name.prototype.getOrganizationalUnitList =
  function () {
    return /** @type {!Array<string>} */ (
      jspb.Message.getRepeatedField(this, 3)
    );
  };

/**
 * @param {!Array<string>} value
 * @return {!proto.pomerium.dashboard.Name} returns this
 */
proto.pomerium.dashboard.Name.prototype.setOrganizationalUnitList = function (
  value
) {
  return jspb.Message.setField(this, 3, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.Name} returns this
 */
proto.pomerium.dashboard.Name.prototype.addOrganizationalUnit = function (
  value,
  opt_index
) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.Name} returns this
 */
proto.pomerium.dashboard.Name.prototype.clearOrganizationalUnitList =
  function () {
    return this.setOrganizationalUnitList([]);
  };

/**
 * repeated string locality = 4;
 * @return {!Array<string>}
 */
proto.pomerium.dashboard.Name.prototype.getLocalityList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.pomerium.dashboard.Name} returns this
 */
proto.pomerium.dashboard.Name.prototype.setLocalityList = function (value) {
  return jspb.Message.setField(this, 4, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.Name} returns this
 */
proto.pomerium.dashboard.Name.prototype.addLocality = function (
  value,
  opt_index
) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.Name} returns this
 */
proto.pomerium.dashboard.Name.prototype.clearLocalityList = function () {
  return this.setLocalityList([]);
};

/**
 * repeated string province = 5;
 * @return {!Array<string>}
 */
proto.pomerium.dashboard.Name.prototype.getProvinceList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.pomerium.dashboard.Name} returns this
 */
proto.pomerium.dashboard.Name.prototype.setProvinceList = function (value) {
  return jspb.Message.setField(this, 5, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.Name} returns this
 */
proto.pomerium.dashboard.Name.prototype.addProvince = function (
  value,
  opt_index
) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.Name} returns this
 */
proto.pomerium.dashboard.Name.prototype.clearProvinceList = function () {
  return this.setProvinceList([]);
};

/**
 * repeated string street_address = 6;
 * @return {!Array<string>}
 */
proto.pomerium.dashboard.Name.prototype.getStreetAddressList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.pomerium.dashboard.Name} returns this
 */
proto.pomerium.dashboard.Name.prototype.setStreetAddressList = function (
  value
) {
  return jspb.Message.setField(this, 6, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.Name} returns this
 */
proto.pomerium.dashboard.Name.prototype.addStreetAddress = function (
  value,
  opt_index
) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.Name} returns this
 */
proto.pomerium.dashboard.Name.prototype.clearStreetAddressList = function () {
  return this.setStreetAddressList([]);
};

/**
 * repeated string postal_code = 7;
 * @return {!Array<string>}
 */
proto.pomerium.dashboard.Name.prototype.getPostalCodeList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.pomerium.dashboard.Name} returns this
 */
proto.pomerium.dashboard.Name.prototype.setPostalCodeList = function (value) {
  return jspb.Message.setField(this, 7, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.Name} returns this
 */
proto.pomerium.dashboard.Name.prototype.addPostalCode = function (
  value,
  opt_index
) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.Name} returns this
 */
proto.pomerium.dashboard.Name.prototype.clearPostalCodeList = function () {
  return this.setPostalCodeList([]);
};

/**
 * optional string serial_number = 8;
 * @return {string}
 */
proto.pomerium.dashboard.Name.prototype.getSerialNumber = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Name} returns this
 */
proto.pomerium.dashboard.Name.prototype.setSerialNumber = function (value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};

/**
 * optional string common_name = 9;
 * @return {string}
 */
proto.pomerium.dashboard.Name.prototype.getCommonName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Name} returns this
 */
proto.pomerium.dashboard.Name.prototype.setCommonName = function (value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pomerium.dashboard.CertificateInfo.repeatedFields_ = [
  10, 11, 12, 13, 15, 16, 17, 18, 19, 20, 21, 22
];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.CertificateInfo.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.CertificateInfo.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.CertificateInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.CertificateInfo.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        version: jspb.Message.getFieldWithDefault(msg, 1, 0),
        serial: jspb.Message.getFieldWithDefault(msg, 2, ''),
        issuer:
          (f = msg.getIssuer()) &&
          proto.pomerium.dashboard.Name.toObject(includeInstance, f),
        subject:
          (f = msg.getSubject()) &&
          proto.pomerium.dashboard.Name.toObject(includeInstance, f),
        notBefore:
          (f = msg.getNotBefore()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        notAfter:
          (f = msg.getNotAfter()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        keyUsage:
          (f = msg.getKeyUsage()) &&
          proto.pomerium.dashboard.KeyUsage.toObject(includeInstance, f),
        dnsNamesList:
          (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
        emailAddressesList:
          (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
        ipAddressesList:
          (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
        urisList:
          (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f,
        permittedDnsDomainsCritical: jspb.Message.getBooleanFieldWithDefault(
          msg,
          14,
          false
        ),
        permittedDnsDomainsList:
          (f = jspb.Message.getRepeatedField(msg, 15)) == null ? undefined : f,
        excludedDnsDomainsList:
          (f = jspb.Message.getRepeatedField(msg, 16)) == null ? undefined : f,
        permittedIpRangesList:
          (f = jspb.Message.getRepeatedField(msg, 17)) == null ? undefined : f,
        excludedIpRangesList:
          (f = jspb.Message.getRepeatedField(msg, 18)) == null ? undefined : f,
        permittedEmailAddressesList:
          (f = jspb.Message.getRepeatedField(msg, 19)) == null ? undefined : f,
        excludedEmailAddressesList:
          (f = jspb.Message.getRepeatedField(msg, 20)) == null ? undefined : f,
        permittedUriDomainsList:
          (f = jspb.Message.getRepeatedField(msg, 21)) == null ? undefined : f,
        excludedUriDomainsList:
          (f = jspb.Message.getRepeatedField(msg, 22)) == null ? undefined : f
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.CertificateInfo}
 */
proto.pomerium.dashboard.CertificateInfo.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.CertificateInfo();
  return proto.pomerium.dashboard.CertificateInfo.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.CertificateInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.CertificateInfo}
 */
proto.pomerium.dashboard.CertificateInfo.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {number} */ (reader.readInt64());
          msg.setVersion(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setSerial(value);
          break;
        case 3:
          var value = new proto.pomerium.dashboard.Name();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.Name.deserializeBinaryFromReader
          );
          msg.setIssuer(value);
          break;
        case 4:
          var value = new proto.pomerium.dashboard.Name();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.Name.deserializeBinaryFromReader
          );
          msg.setSubject(value);
          break;
        case 5:
          var value = new google_protobuf_timestamp_pb.Timestamp();
          reader.readMessage(
            value,
            google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
          );
          msg.setNotBefore(value);
          break;
        case 6:
          var value = new google_protobuf_timestamp_pb.Timestamp();
          reader.readMessage(
            value,
            google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
          );
          msg.setNotAfter(value);
          break;
        case 7:
          var value = new proto.pomerium.dashboard.KeyUsage();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.KeyUsage.deserializeBinaryFromReader
          );
          msg.setKeyUsage(value);
          break;
        case 10:
          var value = /** @type {string} */ (reader.readString());
          msg.addDnsNames(value);
          break;
        case 11:
          var value = /** @type {string} */ (reader.readString());
          msg.addEmailAddresses(value);
          break;
        case 12:
          var value = /** @type {string} */ (reader.readString());
          msg.addIpAddresses(value);
          break;
        case 13:
          var value = /** @type {string} */ (reader.readString());
          msg.addUris(value);
          break;
        case 14:
          var value = /** @type {boolean} */ (reader.readBool());
          msg.setPermittedDnsDomainsCritical(value);
          break;
        case 15:
          var value = /** @type {string} */ (reader.readString());
          msg.addPermittedDnsDomains(value);
          break;
        case 16:
          var value = /** @type {string} */ (reader.readString());
          msg.addExcludedDnsDomains(value);
          break;
        case 17:
          var value = /** @type {string} */ (reader.readString());
          msg.addPermittedIpRanges(value);
          break;
        case 18:
          var value = /** @type {string} */ (reader.readString());
          msg.addExcludedIpRanges(value);
          break;
        case 19:
          var value = /** @type {string} */ (reader.readString());
          msg.addPermittedEmailAddresses(value);
          break;
        case 20:
          var value = /** @type {string} */ (reader.readString());
          msg.addExcludedEmailAddresses(value);
          break;
        case 21:
          var value = /** @type {string} */ (reader.readString());
          msg.addPermittedUriDomains(value);
          break;
        case 22:
          var value = /** @type {string} */ (reader.readString());
          msg.addExcludedUriDomains(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.CertificateInfo.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.CertificateInfo.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.CertificateInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.CertificateInfo.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt64(1, f);
  }
  f = message.getSerial();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getIssuer();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pomerium.dashboard.Name.serializeBinaryToWriter
    );
  }
  f = message.getSubject();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.pomerium.dashboard.Name.serializeBinaryToWriter
    );
  }
  f = message.getNotBefore();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getNotAfter();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getKeyUsage();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.pomerium.dashboard.KeyUsage.serializeBinaryToWriter
    );
  }
  f = message.getDnsNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(10, f);
  }
  f = message.getEmailAddressesList();
  if (f.length > 0) {
    writer.writeRepeatedString(11, f);
  }
  f = message.getIpAddressesList();
  if (f.length > 0) {
    writer.writeRepeatedString(12, f);
  }
  f = message.getUrisList();
  if (f.length > 0) {
    writer.writeRepeatedString(13, f);
  }
  f = message.getPermittedDnsDomainsCritical();
  if (f) {
    writer.writeBool(14, f);
  }
  f = message.getPermittedDnsDomainsList();
  if (f.length > 0) {
    writer.writeRepeatedString(15, f);
  }
  f = message.getExcludedDnsDomainsList();
  if (f.length > 0) {
    writer.writeRepeatedString(16, f);
  }
  f = message.getPermittedIpRangesList();
  if (f.length > 0) {
    writer.writeRepeatedString(17, f);
  }
  f = message.getExcludedIpRangesList();
  if (f.length > 0) {
    writer.writeRepeatedString(18, f);
  }
  f = message.getPermittedEmailAddressesList();
  if (f.length > 0) {
    writer.writeRepeatedString(19, f);
  }
  f = message.getExcludedEmailAddressesList();
  if (f.length > 0) {
    writer.writeRepeatedString(20, f);
  }
  f = message.getPermittedUriDomainsList();
  if (f.length > 0) {
    writer.writeRepeatedString(21, f);
  }
  f = message.getExcludedUriDomainsList();
  if (f.length > 0) {
    writer.writeRepeatedString(22, f);
  }
};

/**
 * optional int64 version = 1;
 * @return {number}
 */
proto.pomerium.dashboard.CertificateInfo.prototype.getVersion = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.setVersion = function (
  value
) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional string serial = 2;
 * @return {string}
 */
proto.pomerium.dashboard.CertificateInfo.prototype.getSerial = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.setSerial = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional Name issuer = 3;
 * @return {?proto.pomerium.dashboard.Name}
 */
proto.pomerium.dashboard.CertificateInfo.prototype.getIssuer = function () {
  return /** @type{?proto.pomerium.dashboard.Name} */ (
    jspb.Message.getWrapperField(this, proto.pomerium.dashboard.Name, 3)
  );
};

/**
 * @param {?proto.pomerium.dashboard.Name|undefined} value
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.setIssuer = function (
  value
) {
  return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.clearIssuer = function () {
  return this.setIssuer(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.CertificateInfo.prototype.hasIssuer = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional Name subject = 4;
 * @return {?proto.pomerium.dashboard.Name}
 */
proto.pomerium.dashboard.CertificateInfo.prototype.getSubject = function () {
  return /** @type{?proto.pomerium.dashboard.Name} */ (
    jspb.Message.getWrapperField(this, proto.pomerium.dashboard.Name, 4)
  );
};

/**
 * @param {?proto.pomerium.dashboard.Name|undefined} value
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.setSubject = function (
  value
) {
  return jspb.Message.setWrapperField(this, 4, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.clearSubject = function () {
  return this.setSubject(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.CertificateInfo.prototype.hasSubject = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional google.protobuf.Timestamp not_before = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pomerium.dashboard.CertificateInfo.prototype.getNotBefore = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_timestamp_pb.Timestamp,
      5
    )
  );
};

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.setNotBefore = function (
  value
) {
  return jspb.Message.setWrapperField(this, 5, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.clearNotBefore =
  function () {
    return this.setNotBefore(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.CertificateInfo.prototype.hasNotBefore = function () {
  return jspb.Message.getField(this, 5) != null;
};

/**
 * optional google.protobuf.Timestamp not_after = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pomerium.dashboard.CertificateInfo.prototype.getNotAfter = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_timestamp_pb.Timestamp,
      6
    )
  );
};

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.setNotAfter = function (
  value
) {
  return jspb.Message.setWrapperField(this, 6, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.clearNotAfter = function () {
  return this.setNotAfter(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.CertificateInfo.prototype.hasNotAfter = function () {
  return jspb.Message.getField(this, 6) != null;
};

/**
 * optional KeyUsage key_usage = 7;
 * @return {?proto.pomerium.dashboard.KeyUsage}
 */
proto.pomerium.dashboard.CertificateInfo.prototype.getKeyUsage = function () {
  return /** @type{?proto.pomerium.dashboard.KeyUsage} */ (
    jspb.Message.getWrapperField(this, proto.pomerium.dashboard.KeyUsage, 7)
  );
};

/**
 * @param {?proto.pomerium.dashboard.KeyUsage|undefined} value
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.setKeyUsage = function (
  value
) {
  return jspb.Message.setWrapperField(this, 7, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.clearKeyUsage = function () {
  return this.setKeyUsage(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.CertificateInfo.prototype.hasKeyUsage = function () {
  return jspb.Message.getField(this, 7) != null;
};

/**
 * repeated string dns_names = 10;
 * @return {!Array<string>}
 */
proto.pomerium.dashboard.CertificateInfo.prototype.getDnsNamesList =
  function () {
    return /** @type {!Array<string>} */ (
      jspb.Message.getRepeatedField(this, 10)
    );
  };

/**
 * @param {!Array<string>} value
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.setDnsNamesList = function (
  value
) {
  return jspb.Message.setField(this, 10, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.addDnsNames = function (
  value,
  opt_index
) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.clearDnsNamesList =
  function () {
    return this.setDnsNamesList([]);
  };

/**
 * repeated string email_addresses = 11;
 * @return {!Array<string>}
 */
proto.pomerium.dashboard.CertificateInfo.prototype.getEmailAddressesList =
  function () {
    return /** @type {!Array<string>} */ (
      jspb.Message.getRepeatedField(this, 11)
    );
  };

/**
 * @param {!Array<string>} value
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.setEmailAddressesList =
  function (value) {
    return jspb.Message.setField(this, 11, value || []);
  };

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.addEmailAddresses =
  function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
  };

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.clearEmailAddressesList =
  function () {
    return this.setEmailAddressesList([]);
  };

/**
 * repeated string ip_addresses = 12;
 * @return {!Array<string>}
 */
proto.pomerium.dashboard.CertificateInfo.prototype.getIpAddressesList =
  function () {
    return /** @type {!Array<string>} */ (
      jspb.Message.getRepeatedField(this, 12)
    );
  };

/**
 * @param {!Array<string>} value
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.setIpAddressesList =
  function (value) {
    return jspb.Message.setField(this, 12, value || []);
  };

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.addIpAddresses = function (
  value,
  opt_index
) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.clearIpAddressesList =
  function () {
    return this.setIpAddressesList([]);
  };

/**
 * repeated string uris = 13;
 * @return {!Array<string>}
 */
proto.pomerium.dashboard.CertificateInfo.prototype.getUrisList = function () {
  return /** @type {!Array<string>} */ (
    jspb.Message.getRepeatedField(this, 13)
  );
};

/**
 * @param {!Array<string>} value
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.setUrisList = function (
  value
) {
  return jspb.Message.setField(this, 13, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.addUris = function (
  value,
  opt_index
) {
  return jspb.Message.addToRepeatedField(this, 13, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.clearUrisList = function () {
  return this.setUrisList([]);
};

/**
 * optional bool permitted_dns_domains_critical = 14;
 * @return {boolean}
 */
proto.pomerium.dashboard.CertificateInfo.prototype.getPermittedDnsDomainsCritical =
  function () {
    return /** @type {boolean} */ (
      jspb.Message.getBooleanFieldWithDefault(this, 14, false)
    );
  };

/**
 * @param {boolean} value
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.setPermittedDnsDomainsCritical =
  function (value) {
    return jspb.Message.setProto3BooleanField(this, 14, value);
  };

/**
 * repeated string permitted_dns_domains = 15;
 * @return {!Array<string>}
 */
proto.pomerium.dashboard.CertificateInfo.prototype.getPermittedDnsDomainsList =
  function () {
    return /** @type {!Array<string>} */ (
      jspb.Message.getRepeatedField(this, 15)
    );
  };

/**
 * @param {!Array<string>} value
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.setPermittedDnsDomainsList =
  function (value) {
    return jspb.Message.setField(this, 15, value || []);
  };

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.addPermittedDnsDomains =
  function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
  };

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.clearPermittedDnsDomainsList =
  function () {
    return this.setPermittedDnsDomainsList([]);
  };

/**
 * repeated string excluded_dns_domains = 16;
 * @return {!Array<string>}
 */
proto.pomerium.dashboard.CertificateInfo.prototype.getExcludedDnsDomainsList =
  function () {
    return /** @type {!Array<string>} */ (
      jspb.Message.getRepeatedField(this, 16)
    );
  };

/**
 * @param {!Array<string>} value
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.setExcludedDnsDomainsList =
  function (value) {
    return jspb.Message.setField(this, 16, value || []);
  };

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.addExcludedDnsDomains =
  function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 16, value, opt_index);
  };

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.clearExcludedDnsDomainsList =
  function () {
    return this.setExcludedDnsDomainsList([]);
  };

/**
 * repeated string permitted_ip_ranges = 17;
 * @return {!Array<string>}
 */
proto.pomerium.dashboard.CertificateInfo.prototype.getPermittedIpRangesList =
  function () {
    return /** @type {!Array<string>} */ (
      jspb.Message.getRepeatedField(this, 17)
    );
  };

/**
 * @param {!Array<string>} value
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.setPermittedIpRangesList =
  function (value) {
    return jspb.Message.setField(this, 17, value || []);
  };

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.addPermittedIpRanges =
  function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 17, value, opt_index);
  };

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.clearPermittedIpRangesList =
  function () {
    return this.setPermittedIpRangesList([]);
  };

/**
 * repeated string excluded_ip_ranges = 18;
 * @return {!Array<string>}
 */
proto.pomerium.dashboard.CertificateInfo.prototype.getExcludedIpRangesList =
  function () {
    return /** @type {!Array<string>} */ (
      jspb.Message.getRepeatedField(this, 18)
    );
  };

/**
 * @param {!Array<string>} value
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.setExcludedIpRangesList =
  function (value) {
    return jspb.Message.setField(this, 18, value || []);
  };

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.addExcludedIpRanges =
  function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 18, value, opt_index);
  };

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.clearExcludedIpRangesList =
  function () {
    return this.setExcludedIpRangesList([]);
  };

/**
 * repeated string permitted_email_addresses = 19;
 * @return {!Array<string>}
 */
proto.pomerium.dashboard.CertificateInfo.prototype.getPermittedEmailAddressesList =
  function () {
    return /** @type {!Array<string>} */ (
      jspb.Message.getRepeatedField(this, 19)
    );
  };

/**
 * @param {!Array<string>} value
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.setPermittedEmailAddressesList =
  function (value) {
    return jspb.Message.setField(this, 19, value || []);
  };

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.addPermittedEmailAddresses =
  function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 19, value, opt_index);
  };

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.clearPermittedEmailAddressesList =
  function () {
    return this.setPermittedEmailAddressesList([]);
  };

/**
 * repeated string excluded_email_addresses = 20;
 * @return {!Array<string>}
 */
proto.pomerium.dashboard.CertificateInfo.prototype.getExcludedEmailAddressesList =
  function () {
    return /** @type {!Array<string>} */ (
      jspb.Message.getRepeatedField(this, 20)
    );
  };

/**
 * @param {!Array<string>} value
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.setExcludedEmailAddressesList =
  function (value) {
    return jspb.Message.setField(this, 20, value || []);
  };

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.addExcludedEmailAddresses =
  function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 20, value, opt_index);
  };

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.clearExcludedEmailAddressesList =
  function () {
    return this.setExcludedEmailAddressesList([]);
  };

/**
 * repeated string permitted_uri_domains = 21;
 * @return {!Array<string>}
 */
proto.pomerium.dashboard.CertificateInfo.prototype.getPermittedUriDomainsList =
  function () {
    return /** @type {!Array<string>} */ (
      jspb.Message.getRepeatedField(this, 21)
    );
  };

/**
 * @param {!Array<string>} value
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.setPermittedUriDomainsList =
  function (value) {
    return jspb.Message.setField(this, 21, value || []);
  };

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.addPermittedUriDomains =
  function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 21, value, opt_index);
  };

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.clearPermittedUriDomainsList =
  function () {
    return this.setPermittedUriDomainsList([]);
  };

/**
 * repeated string excluded_uri_domains = 22;
 * @return {!Array<string>}
 */
proto.pomerium.dashboard.CertificateInfo.prototype.getExcludedUriDomainsList =
  function () {
    return /** @type {!Array<string>} */ (
      jspb.Message.getRepeatedField(this, 22)
    );
  };

/**
 * @param {!Array<string>} value
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.setExcludedUriDomainsList =
  function (value) {
    return jspb.Message.setField(this, 22, value || []);
  };

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.addExcludedUriDomains =
  function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 22, value, opt_index);
  };

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.CertificateInfo} returns this
 */
proto.pomerium.dashboard.CertificateInfo.prototype.clearExcludedUriDomainsList =
  function () {
    return this.setExcludedUriDomainsList([]);
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.KeyPairRecord.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.KeyPairRecord.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.KeyPairRecord} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.KeyPairRecord.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, ''),
        name: jspb.Message.getFieldWithDefault(msg, 2, ''),
        namespaceId: jspb.Message.getFieldWithDefault(msg, 3, ''),
        createdAt:
          (f = msg.getCreatedAt()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        modifiedAt:
          (f = msg.getModifiedAt()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        certInfo:
          (f = msg.getCertInfo()) &&
          proto.pomerium.dashboard.CertificateInfo.toObject(includeInstance, f),
        hasPrivateKey: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.KeyPairRecord}
 */
proto.pomerium.dashboard.KeyPairRecord.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.KeyPairRecord();
  return proto.pomerium.dashboard.KeyPairRecord.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.KeyPairRecord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.KeyPairRecord}
 */
proto.pomerium.dashboard.KeyPairRecord.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setNamespaceId(value);
        break;
      case 4:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        );
        msg.setCreatedAt(value);
        break;
      case 5:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        );
        msg.setModifiedAt(value);
        break;
      case 7:
        var value = new proto.pomerium.dashboard.CertificateInfo();
        reader.readMessage(
          value,
          proto.pomerium.dashboard.CertificateInfo.deserializeBinaryFromReader
        );
        msg.setCertInfo(value);
        break;
      case 8:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setHasPrivateKey(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.KeyPairRecord.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.pomerium.dashboard.KeyPairRecord.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.KeyPairRecord} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.KeyPairRecord.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getNamespaceId();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getModifiedAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCertInfo();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.pomerium.dashboard.CertificateInfo.serializeBinaryToWriter
    );
  }
  f = message.getHasPrivateKey();
  if (f) {
    writer.writeBool(8, f);
  }
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.KeyPairRecord.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.KeyPairRecord} returns this
 */
proto.pomerium.dashboard.KeyPairRecord.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string name = 2;
 * @return {string}
 */
proto.pomerium.dashboard.KeyPairRecord.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.KeyPairRecord} returns this
 */
proto.pomerium.dashboard.KeyPairRecord.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string namespace_id = 3;
 * @return {string}
 */
proto.pomerium.dashboard.KeyPairRecord.prototype.getNamespaceId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.KeyPairRecord} returns this
 */
proto.pomerium.dashboard.KeyPairRecord.prototype.setNamespaceId = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional google.protobuf.Timestamp created_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pomerium.dashboard.KeyPairRecord.prototype.getCreatedAt = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_timestamp_pb.Timestamp,
      4
    )
  );
};

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.pomerium.dashboard.KeyPairRecord} returns this
 */
proto.pomerium.dashboard.KeyPairRecord.prototype.setCreatedAt = function (
  value
) {
  return jspb.Message.setWrapperField(this, 4, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.KeyPairRecord} returns this
 */
proto.pomerium.dashboard.KeyPairRecord.prototype.clearCreatedAt = function () {
  return this.setCreatedAt(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.KeyPairRecord.prototype.hasCreatedAt = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional google.protobuf.Timestamp modified_at = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pomerium.dashboard.KeyPairRecord.prototype.getModifiedAt = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_timestamp_pb.Timestamp,
      5
    )
  );
};

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.pomerium.dashboard.KeyPairRecord} returns this
 */
proto.pomerium.dashboard.KeyPairRecord.prototype.setModifiedAt = function (
  value
) {
  return jspb.Message.setWrapperField(this, 5, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.KeyPairRecord} returns this
 */
proto.pomerium.dashboard.KeyPairRecord.prototype.clearModifiedAt = function () {
  return this.setModifiedAt(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.KeyPairRecord.prototype.hasModifiedAt = function () {
  return jspb.Message.getField(this, 5) != null;
};

/**
 * optional CertificateInfo cert_info = 7;
 * @return {?proto.pomerium.dashboard.CertificateInfo}
 */
proto.pomerium.dashboard.KeyPairRecord.prototype.getCertInfo = function () {
  return /** @type{?proto.pomerium.dashboard.CertificateInfo} */ (
    jspb.Message.getWrapperField(
      this,
      proto.pomerium.dashboard.CertificateInfo,
      7
    )
  );
};

/**
 * @param {?proto.pomerium.dashboard.CertificateInfo|undefined} value
 * @return {!proto.pomerium.dashboard.KeyPairRecord} returns this
 */
proto.pomerium.dashboard.KeyPairRecord.prototype.setCertInfo = function (
  value
) {
  return jspb.Message.setWrapperField(this, 7, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.KeyPairRecord} returns this
 */
proto.pomerium.dashboard.KeyPairRecord.prototype.clearCertInfo = function () {
  return this.setCertInfo(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.KeyPairRecord.prototype.hasCertInfo = function () {
  return jspb.Message.getField(this, 7) != null;
};

/**
 * optional bool has_private_key = 8;
 * @return {boolean}
 */
proto.pomerium.dashboard.KeyPairRecord.prototype.getHasPrivateKey =
  function () {
    return /** @type {boolean} */ (
      jspb.Message.getBooleanFieldWithDefault(this, 8, false)
    );
  };

/**
 * @param {boolean} value
 * @return {!proto.pomerium.dashboard.KeyPairRecord} returns this
 */
proto.pomerium.dashboard.KeyPairRecord.prototype.setHasPrivateKey = function (
  value
) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.DeleteKeyPairRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.DeleteKeyPairRequest.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.DeleteKeyPairRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.DeleteKeyPairRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.DeleteKeyPairRequest}
 */
proto.pomerium.dashboard.DeleteKeyPairRequest.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.DeleteKeyPairRequest();
  return proto.pomerium.dashboard.DeleteKeyPairRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.DeleteKeyPairRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.DeleteKeyPairRequest}
 */
proto.pomerium.dashboard.DeleteKeyPairRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setId(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.DeleteKeyPairRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.DeleteKeyPairRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.DeleteKeyPairRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.DeleteKeyPairRequest.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getId();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
  };

/**
 * optional string id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.DeleteKeyPairRequest.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.DeleteKeyPairRequest} returns this
 */
proto.pomerium.dashboard.DeleteKeyPairRequest.prototype.setId = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.DeleteKeyPairResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.DeleteKeyPairResponse.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.DeleteKeyPairResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.DeleteKeyPairResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {};

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.DeleteKeyPairResponse}
 */
proto.pomerium.dashboard.DeleteKeyPairResponse.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.DeleteKeyPairResponse();
  return proto.pomerium.dashboard.DeleteKeyPairResponse.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.DeleteKeyPairResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.DeleteKeyPairResponse}
 */
proto.pomerium.dashboard.DeleteKeyPairResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.DeleteKeyPairResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.DeleteKeyPairResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.DeleteKeyPairResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.DeleteKeyPairResponse.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.GetKeyPairRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.GetKeyPairRequest.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.GetKeyPairRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.GetKeyPairRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.GetKeyPairRequest}
 */
proto.pomerium.dashboard.GetKeyPairRequest.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.GetKeyPairRequest();
  return proto.pomerium.dashboard.GetKeyPairRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.GetKeyPairRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.GetKeyPairRequest}
 */
proto.pomerium.dashboard.GetKeyPairRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setId(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.GetKeyPairRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.GetKeyPairRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.GetKeyPairRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.GetKeyPairRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.GetKeyPairRequest.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.GetKeyPairRequest} returns this
 */
proto.pomerium.dashboard.GetKeyPairRequest.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.GetKeyPairResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.GetKeyPairResponse.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.GetKeyPairResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.GetKeyPairResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        keyPair:
          (f = msg.getKeyPair()) &&
          proto.pomerium.dashboard.KeyPairRecord.toObject(includeInstance, f)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.GetKeyPairResponse}
 */
proto.pomerium.dashboard.GetKeyPairResponse.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.GetKeyPairResponse();
  return proto.pomerium.dashboard.GetKeyPairResponse.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.GetKeyPairResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.GetKeyPairResponse}
 */
proto.pomerium.dashboard.GetKeyPairResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.KeyPairRecord();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.KeyPairRecord.deserializeBinaryFromReader
          );
          msg.setKeyPair(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.GetKeyPairResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.GetKeyPairResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.GetKeyPairResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.GetKeyPairResponse.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getKeyPair();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pomerium.dashboard.KeyPairRecord.serializeBinaryToWriter
    );
  }
};

/**
 * optional KeyPairRecord key_pair = 1;
 * @return {?proto.pomerium.dashboard.KeyPairRecord}
 */
proto.pomerium.dashboard.GetKeyPairResponse.prototype.getKeyPair = function () {
  return /** @type{?proto.pomerium.dashboard.KeyPairRecord} */ (
    jspb.Message.getWrapperField(
      this,
      proto.pomerium.dashboard.KeyPairRecord,
      1
    )
  );
};

/**
 * @param {?proto.pomerium.dashboard.KeyPairRecord|undefined} value
 * @return {!proto.pomerium.dashboard.GetKeyPairResponse} returns this
 */
proto.pomerium.dashboard.GetKeyPairResponse.prototype.setKeyPair = function (
  value
) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.GetKeyPairResponse} returns this
 */
proto.pomerium.dashboard.GetKeyPairResponse.prototype.clearKeyPair =
  function () {
    return this.setKeyPair(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.GetKeyPairResponse.prototype.hasKeyPair = function () {
  return jspb.Message.getField(this, 1) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.ListKeyPairsRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.ListKeyPairsRequest.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.ListKeyPairsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.ListKeyPairsRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        namespaceId: jspb.Message.getFieldWithDefault(msg, 1, ''),
        query: jspb.Message.getFieldWithDefault(msg, 2, ''),
        offset: jspb.Message.getFieldWithDefault(msg, 3, 0),
        limit: jspb.Message.getFieldWithDefault(msg, 4, 0),
        orderBy: jspb.Message.getFieldWithDefault(msg, 5, ''),
        domain: jspb.Message.getFieldWithDefault(msg, 6, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.ListKeyPairsRequest}
 */
proto.pomerium.dashboard.ListKeyPairsRequest.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.ListKeyPairsRequest();
  return proto.pomerium.dashboard.ListKeyPairsRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.ListKeyPairsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.ListKeyPairsRequest}
 */
proto.pomerium.dashboard.ListKeyPairsRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setNamespaceId(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setQuery(value);
          break;
        case 3:
          var value = /** @type {number} */ (reader.readInt64());
          msg.setOffset(value);
          break;
        case 4:
          var value = /** @type {number} */ (reader.readInt64());
          msg.setLimit(value);
          break;
        case 5:
          var value = /** @type {string} */ (reader.readString());
          msg.setOrderBy(value);
          break;
        case 6:
          var value = /** @type {string} */ (reader.readString());
          msg.setDomain(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.ListKeyPairsRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.ListKeyPairsRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.ListKeyPairsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.ListKeyPairsRequest.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getNamespaceId();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 2));
    if (f != null) {
      writer.writeString(2, f);
    }
    f = /** @type {number} */ (jspb.Message.getField(message, 3));
    if (f != null) {
      writer.writeInt64(3, f);
    }
    f = /** @type {number} */ (jspb.Message.getField(message, 4));
    if (f != null) {
      writer.writeInt64(4, f);
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 5));
    if (f != null) {
      writer.writeString(5, f);
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 6));
    if (f != null) {
      writer.writeString(6, f);
    }
  };

/**
 * optional string namespace_id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.ListKeyPairsRequest.prototype.getNamespaceId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ListKeyPairsRequest} returns this
 */
proto.pomerium.dashboard.ListKeyPairsRequest.prototype.setNamespaceId =
  function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
  };

/**
 * optional string query = 2;
 * @return {string}
 */
proto.pomerium.dashboard.ListKeyPairsRequest.prototype.getQuery = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ListKeyPairsRequest} returns this
 */
proto.pomerium.dashboard.ListKeyPairsRequest.prototype.setQuery = function (
  value
) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.ListKeyPairsRequest} returns this
 */
proto.pomerium.dashboard.ListKeyPairsRequest.prototype.clearQuery =
  function () {
    return jspb.Message.setField(this, 2, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.ListKeyPairsRequest.prototype.hasQuery = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional int64 offset = 3;
 * @return {number}
 */
proto.pomerium.dashboard.ListKeyPairsRequest.prototype.getOffset = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.pomerium.dashboard.ListKeyPairsRequest} returns this
 */
proto.pomerium.dashboard.ListKeyPairsRequest.prototype.setOffset = function (
  value
) {
  return jspb.Message.setField(this, 3, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.ListKeyPairsRequest} returns this
 */
proto.pomerium.dashboard.ListKeyPairsRequest.prototype.clearOffset =
  function () {
    return jspb.Message.setField(this, 3, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.ListKeyPairsRequest.prototype.hasOffset = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional int64 limit = 4;
 * @return {number}
 */
proto.pomerium.dashboard.ListKeyPairsRequest.prototype.getLimit = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {number} value
 * @return {!proto.pomerium.dashboard.ListKeyPairsRequest} returns this
 */
proto.pomerium.dashboard.ListKeyPairsRequest.prototype.setLimit = function (
  value
) {
  return jspb.Message.setField(this, 4, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.ListKeyPairsRequest} returns this
 */
proto.pomerium.dashboard.ListKeyPairsRequest.prototype.clearLimit =
  function () {
    return jspb.Message.setField(this, 4, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.ListKeyPairsRequest.prototype.hasLimit = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional string order_by = 5;
 * @return {string}
 */
proto.pomerium.dashboard.ListKeyPairsRequest.prototype.getOrderBy =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 5, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ListKeyPairsRequest} returns this
 */
proto.pomerium.dashboard.ListKeyPairsRequest.prototype.setOrderBy = function (
  value
) {
  return jspb.Message.setField(this, 5, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.ListKeyPairsRequest} returns this
 */
proto.pomerium.dashboard.ListKeyPairsRequest.prototype.clearOrderBy =
  function () {
    return jspb.Message.setField(this, 5, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.ListKeyPairsRequest.prototype.hasOrderBy =
  function () {
    return jspb.Message.getField(this, 5) != null;
  };

/**
 * optional string domain = 6;
 * @return {string}
 */
proto.pomerium.dashboard.ListKeyPairsRequest.prototype.getDomain = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ListKeyPairsRequest} returns this
 */
proto.pomerium.dashboard.ListKeyPairsRequest.prototype.setDomain = function (
  value
) {
  return jspb.Message.setField(this, 6, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.ListKeyPairsRequest} returns this
 */
proto.pomerium.dashboard.ListKeyPairsRequest.prototype.clearDomain =
  function () {
    return jspb.Message.setField(this, 6, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.ListKeyPairsRequest.prototype.hasDomain = function () {
  return jspb.Message.getField(this, 6) != null;
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pomerium.dashboard.ListKeyPairsResponse.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.ListKeyPairsResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.ListKeyPairsResponse.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.ListKeyPairsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.ListKeyPairsResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        keyPairsList: jspb.Message.toObjectList(
          msg.getKeyPairsList(),
          proto.pomerium.dashboard.KeyPairRecord.toObject,
          includeInstance
        ),
        totalCount: jspb.Message.getFieldWithDefault(msg, 2, 0)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.ListKeyPairsResponse}
 */
proto.pomerium.dashboard.ListKeyPairsResponse.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.ListKeyPairsResponse();
  return proto.pomerium.dashboard.ListKeyPairsResponse.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.ListKeyPairsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.ListKeyPairsResponse}
 */
proto.pomerium.dashboard.ListKeyPairsResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.KeyPairRecord();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.KeyPairRecord.deserializeBinaryFromReader
          );
          msg.addKeyPairs(value);
          break;
        case 2:
          var value = /** @type {number} */ (reader.readInt64());
          msg.setTotalCount(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.ListKeyPairsResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.ListKeyPairsResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.ListKeyPairsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.ListKeyPairsResponse.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getKeyPairsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        proto.pomerium.dashboard.KeyPairRecord.serializeBinaryToWriter
      );
    }
    f = message.getTotalCount();
    if (f !== 0) {
      writer.writeInt64(2, f);
    }
  };

/**
 * repeated KeyPairRecord key_pairs = 1;
 * @return {!Array<!proto.pomerium.dashboard.KeyPairRecord>}
 */
proto.pomerium.dashboard.ListKeyPairsResponse.prototype.getKeyPairsList =
  function () {
    return /** @type{!Array<!proto.pomerium.dashboard.KeyPairRecord>} */ (
      jspb.Message.getRepeatedWrapperField(
        this,
        proto.pomerium.dashboard.KeyPairRecord,
        1
      )
    );
  };

/**
 * @param {!Array<!proto.pomerium.dashboard.KeyPairRecord>} value
 * @return {!proto.pomerium.dashboard.ListKeyPairsResponse} returns this
 */
proto.pomerium.dashboard.ListKeyPairsResponse.prototype.setKeyPairsList =
  function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
  };

/**
 * @param {!proto.pomerium.dashboard.KeyPairRecord=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.KeyPairRecord}
 */
proto.pomerium.dashboard.ListKeyPairsResponse.prototype.addKeyPairs = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.pomerium.dashboard.KeyPairRecord,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.ListKeyPairsResponse} returns this
 */
proto.pomerium.dashboard.ListKeyPairsResponse.prototype.clearKeyPairsList =
  function () {
    return this.setKeyPairsList([]);
  };

/**
 * optional int64 total_count = 2;
 * @return {number}
 */
proto.pomerium.dashboard.ListKeyPairsResponse.prototype.getTotalCount =
  function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };

/**
 * @param {number} value
 * @return {!proto.pomerium.dashboard.ListKeyPairsResponse} returns this
 */
proto.pomerium.dashboard.ListKeyPairsResponse.prototype.setTotalCount =
  function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.CreateKeyPairRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.CreateKeyPairRequest.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.CreateKeyPairRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.CreateKeyPairRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        name: jspb.Message.getFieldWithDefault(msg, 1, ''),
        namespaceId: jspb.Message.getFieldWithDefault(msg, 2, ''),
        format: jspb.Message.getFieldWithDefault(msg, 3, 0),
        certificate: msg.getCertificate_asB64(),
        key: msg.getKey_asB64()
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.CreateKeyPairRequest}
 */
proto.pomerium.dashboard.CreateKeyPairRequest.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.CreateKeyPairRequest();
  return proto.pomerium.dashboard.CreateKeyPairRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.CreateKeyPairRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.CreateKeyPairRequest}
 */
proto.pomerium.dashboard.CreateKeyPairRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setName(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setNamespaceId(value);
          break;
        case 3:
          var value = /** @type {!proto.pomerium.dashboard.Format} */ (
            reader.readEnum()
          );
          msg.setFormat(value);
          break;
        case 4:
          var value = /** @type {!Uint8Array} */ (reader.readBytes());
          msg.setCertificate(value);
          break;
        case 5:
          var value = /** @type {!Uint8Array} */ (reader.readBytes());
          msg.setKey(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.CreateKeyPairRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.CreateKeyPairRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.CreateKeyPairRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.CreateKeyPairRequest.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
    f = message.getNamespaceId();
    if (f.length > 0) {
      writer.writeString(2, f);
    }
    f = message.getFormat();
    if (f !== 0.0) {
      writer.writeEnum(3, f);
    }
    f = message.getCertificate_asU8();
    if (f.length > 0) {
      writer.writeBytes(4, f);
    }
    f = message.getKey_asU8();
    if (f.length > 0) {
      writer.writeBytes(5, f);
    }
  };

/**
 * optional string name = 1;
 * @return {string}
 */
proto.pomerium.dashboard.CreateKeyPairRequest.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.CreateKeyPairRequest} returns this
 */
proto.pomerium.dashboard.CreateKeyPairRequest.prototype.setName = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string namespace_id = 2;
 * @return {string}
 */
proto.pomerium.dashboard.CreateKeyPairRequest.prototype.getNamespaceId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 2, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.CreateKeyPairRequest} returns this
 */
proto.pomerium.dashboard.CreateKeyPairRequest.prototype.setNamespaceId =
  function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
  };

/**
 * optional Format format = 3;
 * @return {!proto.pomerium.dashboard.Format}
 */
proto.pomerium.dashboard.CreateKeyPairRequest.prototype.getFormat =
  function () {
    return /** @type {!proto.pomerium.dashboard.Format} */ (
      jspb.Message.getFieldWithDefault(this, 3, 0)
    );
  };

/**
 * @param {!proto.pomerium.dashboard.Format} value
 * @return {!proto.pomerium.dashboard.CreateKeyPairRequest} returns this
 */
proto.pomerium.dashboard.CreateKeyPairRequest.prototype.setFormat = function (
  value
) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};

/**
 * optional bytes certificate = 4;
 * @return {string}
 */
proto.pomerium.dashboard.CreateKeyPairRequest.prototype.getCertificate =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 4, '')
    );
  };

/**
 * optional bytes certificate = 4;
 * This is a type-conversion wrapper around `getCertificate()`
 * @return {string}
 */
proto.pomerium.dashboard.CreateKeyPairRequest.prototype.getCertificate_asB64 =
  function () {
    return /** @type {string} */ (
      jspb.Message.bytesAsB64(this.getCertificate())
    );
  };

/**
 * optional bytes certificate = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCertificate()`
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.CreateKeyPairRequest.prototype.getCertificate_asU8 =
  function () {
    return /** @type {!Uint8Array} */ (
      jspb.Message.bytesAsU8(this.getCertificate())
    );
  };

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.pomerium.dashboard.CreateKeyPairRequest} returns this
 */
proto.pomerium.dashboard.CreateKeyPairRequest.prototype.setCertificate =
  function (value) {
    return jspb.Message.setProto3BytesField(this, 4, value);
  };

/**
 * optional bytes key = 5;
 * @return {string}
 */
proto.pomerium.dashboard.CreateKeyPairRequest.prototype.getKey = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};

/**
 * optional bytes key = 5;
 * This is a type-conversion wrapper around `getKey()`
 * @return {string}
 */
proto.pomerium.dashboard.CreateKeyPairRequest.prototype.getKey_asB64 =
  function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getKey()));
  };

/**
 * optional bytes key = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getKey()`
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.CreateKeyPairRequest.prototype.getKey_asU8 =
  function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getKey()));
  };

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.pomerium.dashboard.CreateKeyPairRequest} returns this
 */
proto.pomerium.dashboard.CreateKeyPairRequest.prototype.setKey = function (
  value
) {
  return jspb.Message.setProto3BytesField(this, 5, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.CreateKeyPairResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.CreateKeyPairResponse.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.CreateKeyPairResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.CreateKeyPairResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        keyPair:
          (f = msg.getKeyPair()) &&
          proto.pomerium.dashboard.KeyPairRecord.toObject(includeInstance, f)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.CreateKeyPairResponse}
 */
proto.pomerium.dashboard.CreateKeyPairResponse.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.CreateKeyPairResponse();
  return proto.pomerium.dashboard.CreateKeyPairResponse.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.CreateKeyPairResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.CreateKeyPairResponse}
 */
proto.pomerium.dashboard.CreateKeyPairResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.KeyPairRecord();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.KeyPairRecord.deserializeBinaryFromReader
          );
          msg.setKeyPair(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.CreateKeyPairResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.CreateKeyPairResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.CreateKeyPairResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.CreateKeyPairResponse.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getKeyPair();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        proto.pomerium.dashboard.KeyPairRecord.serializeBinaryToWriter
      );
    }
  };

/**
 * optional KeyPairRecord key_pair = 1;
 * @return {?proto.pomerium.dashboard.KeyPairRecord}
 */
proto.pomerium.dashboard.CreateKeyPairResponse.prototype.getKeyPair =
  function () {
    return /** @type{?proto.pomerium.dashboard.KeyPairRecord} */ (
      jspb.Message.getWrapperField(
        this,
        proto.pomerium.dashboard.KeyPairRecord,
        1
      )
    );
  };

/**
 * @param {?proto.pomerium.dashboard.KeyPairRecord|undefined} value
 * @return {!proto.pomerium.dashboard.CreateKeyPairResponse} returns this
 */
proto.pomerium.dashboard.CreateKeyPairResponse.prototype.setKeyPair = function (
  value
) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.CreateKeyPairResponse} returns this
 */
proto.pomerium.dashboard.CreateKeyPairResponse.prototype.clearKeyPair =
  function () {
    return this.setKeyPair(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.CreateKeyPairResponse.prototype.hasKeyPair =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.UpdateKeyPairRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.UpdateKeyPairRequest.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.UpdateKeyPairRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.UpdateKeyPairRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, ''),
        format: jspb.Message.getFieldWithDefault(msg, 3, 0),
        certificate: msg.getCertificate_asB64(),
        key: msg.getKey_asB64()
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.UpdateKeyPairRequest}
 */
proto.pomerium.dashboard.UpdateKeyPairRequest.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.UpdateKeyPairRequest();
  return proto.pomerium.dashboard.UpdateKeyPairRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.UpdateKeyPairRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.UpdateKeyPairRequest}
 */
proto.pomerium.dashboard.UpdateKeyPairRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setId(value);
          break;
        case 3:
          var value = /** @type {!proto.pomerium.dashboard.Format} */ (
            reader.readEnum()
          );
          msg.setFormat(value);
          break;
        case 4:
          var value = /** @type {!Uint8Array} */ (reader.readBytes());
          msg.setCertificate(value);
          break;
        case 5:
          var value = /** @type {!Uint8Array} */ (reader.readBytes());
          msg.setKey(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.UpdateKeyPairRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.UpdateKeyPairRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.UpdateKeyPairRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.UpdateKeyPairRequest.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getId();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
    f = message.getFormat();
    if (f !== 0.0) {
      writer.writeEnum(3, f);
    }
    f = message.getCertificate_asU8();
    if (f.length > 0) {
      writer.writeBytes(4, f);
    }
    f = message.getKey_asU8();
    if (f.length > 0) {
      writer.writeBytes(5, f);
    }
  };

/**
 * optional string id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.UpdateKeyPairRequest.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.UpdateKeyPairRequest} returns this
 */
proto.pomerium.dashboard.UpdateKeyPairRequest.prototype.setId = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional Format format = 3;
 * @return {!proto.pomerium.dashboard.Format}
 */
proto.pomerium.dashboard.UpdateKeyPairRequest.prototype.getFormat =
  function () {
    return /** @type {!proto.pomerium.dashboard.Format} */ (
      jspb.Message.getFieldWithDefault(this, 3, 0)
    );
  };

/**
 * @param {!proto.pomerium.dashboard.Format} value
 * @return {!proto.pomerium.dashboard.UpdateKeyPairRequest} returns this
 */
proto.pomerium.dashboard.UpdateKeyPairRequest.prototype.setFormat = function (
  value
) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};

/**
 * optional bytes certificate = 4;
 * @return {string}
 */
proto.pomerium.dashboard.UpdateKeyPairRequest.prototype.getCertificate =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 4, '')
    );
  };

/**
 * optional bytes certificate = 4;
 * This is a type-conversion wrapper around `getCertificate()`
 * @return {string}
 */
proto.pomerium.dashboard.UpdateKeyPairRequest.prototype.getCertificate_asB64 =
  function () {
    return /** @type {string} */ (
      jspb.Message.bytesAsB64(this.getCertificate())
    );
  };

/**
 * optional bytes certificate = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCertificate()`
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.UpdateKeyPairRequest.prototype.getCertificate_asU8 =
  function () {
    return /** @type {!Uint8Array} */ (
      jspb.Message.bytesAsU8(this.getCertificate())
    );
  };

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.pomerium.dashboard.UpdateKeyPairRequest} returns this
 */
proto.pomerium.dashboard.UpdateKeyPairRequest.prototype.setCertificate =
  function (value) {
    return jspb.Message.setProto3BytesField(this, 4, value);
  };

/**
 * optional bytes key = 5;
 * @return {string}
 */
proto.pomerium.dashboard.UpdateKeyPairRequest.prototype.getKey = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};

/**
 * optional bytes key = 5;
 * This is a type-conversion wrapper around `getKey()`
 * @return {string}
 */
proto.pomerium.dashboard.UpdateKeyPairRequest.prototype.getKey_asB64 =
  function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getKey()));
  };

/**
 * optional bytes key = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getKey()`
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.UpdateKeyPairRequest.prototype.getKey_asU8 =
  function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getKey()));
  };

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.pomerium.dashboard.UpdateKeyPairRequest} returns this
 */
proto.pomerium.dashboard.UpdateKeyPairRequest.prototype.setKey = function (
  value
) {
  return jspb.Message.setProto3BytesField(this, 5, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.UpdateKeyPairResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.UpdateKeyPairResponse.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.UpdateKeyPairResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.UpdateKeyPairResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        keyPair:
          (f = msg.getKeyPair()) &&
          proto.pomerium.dashboard.KeyPairRecord.toObject(includeInstance, f)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.UpdateKeyPairResponse}
 */
proto.pomerium.dashboard.UpdateKeyPairResponse.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.UpdateKeyPairResponse();
  return proto.pomerium.dashboard.UpdateKeyPairResponse.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.UpdateKeyPairResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.UpdateKeyPairResponse}
 */
proto.pomerium.dashboard.UpdateKeyPairResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.KeyPairRecord();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.KeyPairRecord.deserializeBinaryFromReader
          );
          msg.setKeyPair(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.UpdateKeyPairResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.UpdateKeyPairResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.UpdateKeyPairResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.UpdateKeyPairResponse.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getKeyPair();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        proto.pomerium.dashboard.KeyPairRecord.serializeBinaryToWriter
      );
    }
  };

/**
 * optional KeyPairRecord key_pair = 1;
 * @return {?proto.pomerium.dashboard.KeyPairRecord}
 */
proto.pomerium.dashboard.UpdateKeyPairResponse.prototype.getKeyPair =
  function () {
    return /** @type{?proto.pomerium.dashboard.KeyPairRecord} */ (
      jspb.Message.getWrapperField(
        this,
        proto.pomerium.dashboard.KeyPairRecord,
        1
      )
    );
  };

/**
 * @param {?proto.pomerium.dashboard.KeyPairRecord|undefined} value
 * @return {!proto.pomerium.dashboard.UpdateKeyPairResponse} returns this
 */
proto.pomerium.dashboard.UpdateKeyPairResponse.prototype.setKeyPair = function (
  value
) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.UpdateKeyPairResponse} returns this
 */
proto.pomerium.dashboard.UpdateKeyPairResponse.prototype.clearKeyPair =
  function () {
    return this.setKeyPair(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.UpdateKeyPairResponse.prototype.hasKeyPair =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

/**
 * @enum {number}
 */
proto.pomerium.dashboard.PublicKeyAlgorithm = {
  PKA_UNKNOWN_DO_NOT_USE: 0,
  RSA: 1,
  DSA: 2,
  ECDSA: 3,
  ED25519: 4
};

/**
 * @enum {number}
 */
proto.pomerium.dashboard.Format = {
  FORMAT_UNDEFINED_DO_NOT_USE: 0,
  PEM: 1
};

goog.object.extend(exports, proto.pomerium.dashboard);
