import { RpcError as GrpcError } from 'grpc-web';

export const getErrorMessage = (e: unknown): string => {
  if (!e) {
    return '';
  }

  const ge = e as GrpcError;
  if (ge) {
    return `${ge.message}`;
  }
  return `${e}`;
};
