// source: envoy/config/core/v3/base.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var envoy_config_core_v3_address_pb = require('../../../../envoy/config/core/v3/address_pb.js');
goog.object.extend(proto, envoy_config_core_v3_address_pb);
var envoy_config_core_v3_backoff_pb = require('../../../../envoy/config/core/v3/backoff_pb.js');
goog.object.extend(proto, envoy_config_core_v3_backoff_pb);
var envoy_config_core_v3_http_uri_pb = require('../../../../envoy/config/core/v3/http_uri_pb.js');
goog.object.extend(proto, envoy_config_core_v3_http_uri_pb);
var envoy_type_v3_percent_pb = require('../../../../envoy/type/v3/percent_pb.js');
goog.object.extend(proto, envoy_type_v3_percent_pb);
var envoy_type_v3_semantic_version_pb = require('../../../../envoy/type/v3/semantic_version_pb.js');
goog.object.extend(proto, envoy_type_v3_semantic_version_pb);
var google_protobuf_any_pb = require('google-protobuf/google/protobuf/any_pb.js');
goog.object.extend(proto, google_protobuf_any_pb);
var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js');
goog.object.extend(proto, google_protobuf_duration_pb);
var google_protobuf_struct_pb = require('google-protobuf/google/protobuf/struct_pb.js');
goog.object.extend(proto, google_protobuf_struct_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var udpa_annotations_migrate_pb = require('../../../../udpa/annotations/migrate_pb.js');
goog.object.extend(proto, udpa_annotations_migrate_pb);
var udpa_annotations_status_pb = require('../../../../udpa/annotations/status_pb.js');
goog.object.extend(proto, udpa_annotations_status_pb);
var udpa_annotations_versioning_pb = require('../../../../udpa/annotations/versioning_pb.js');
goog.object.extend(proto, udpa_annotations_versioning_pb);
var validate_validate_pb = require('../../../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.envoy.config.core.v3.AsyncDataSource', null, global);
goog.exportSymbol(
  'proto.envoy.config.core.v3.AsyncDataSource.SpecifierCase',
  null,
  global
);
goog.exportSymbol('proto.envoy.config.core.v3.BuildVersion', null, global);
goog.exportSymbol('proto.envoy.config.core.v3.ControlPlane', null, global);
goog.exportSymbol('proto.envoy.config.core.v3.DataSource', null, global);
goog.exportSymbol(
  'proto.envoy.config.core.v3.DataSource.SpecifierCase',
  null,
  global
);
goog.exportSymbol('proto.envoy.config.core.v3.Extension', null, global);
goog.exportSymbol('proto.envoy.config.core.v3.HeaderMap', null, global);
goog.exportSymbol('proto.envoy.config.core.v3.HeaderValue', null, global);
goog.exportSymbol('proto.envoy.config.core.v3.HeaderValueOption', null, global);
goog.exportSymbol('proto.envoy.config.core.v3.Locality', null, global);
goog.exportSymbol('proto.envoy.config.core.v3.Metadata', null, global);
goog.exportSymbol('proto.envoy.config.core.v3.Node', null, global);
goog.exportSymbol(
  'proto.envoy.config.core.v3.Node.UserAgentVersionTypeCase',
  null,
  global
);
goog.exportSymbol('proto.envoy.config.core.v3.RemoteDataSource', null, global);
goog.exportSymbol('proto.envoy.config.core.v3.RequestMethod', null, global);
goog.exportSymbol('proto.envoy.config.core.v3.RetryPolicy', null, global);
goog.exportSymbol('proto.envoy.config.core.v3.RoutingPriority', null, global);
goog.exportSymbol('proto.envoy.config.core.v3.RuntimeDouble', null, global);
goog.exportSymbol(
  'proto.envoy.config.core.v3.RuntimeFeatureFlag',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.core.v3.RuntimeFractionalPercent',
  null,
  global
);
goog.exportSymbol('proto.envoy.config.core.v3.RuntimePercent', null, global);
goog.exportSymbol('proto.envoy.config.core.v3.RuntimeUInt32', null, global);
goog.exportSymbol('proto.envoy.config.core.v3.TrafficDirection', null, global);
goog.exportSymbol('proto.envoy.config.core.v3.TransportSocket', null, global);
goog.exportSymbol(
  'proto.envoy.config.core.v3.TransportSocket.ConfigTypeCase',
  null,
  global
);
goog.exportSymbol('proto.envoy.config.core.v3.WatchedDirectory', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.Locality = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.envoy.config.core.v3.Locality, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.Locality.displayName =
    'proto.envoy.config.core.v3.Locality';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.BuildVersion = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.envoy.config.core.v3.BuildVersion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.BuildVersion.displayName =
    'proto.envoy.config.core.v3.BuildVersion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.Extension = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.envoy.config.core.v3.Extension, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.Extension.displayName =
    'proto.envoy.config.core.v3.Extension';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.Node = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.envoy.config.core.v3.Node.repeatedFields_,
    proto.envoy.config.core.v3.Node.oneofGroups_
  );
};
goog.inherits(proto.envoy.config.core.v3.Node, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.Node.displayName =
    'proto.envoy.config.core.v3.Node';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.Metadata = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.envoy.config.core.v3.Metadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.Metadata.displayName =
    'proto.envoy.config.core.v3.Metadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.RuntimeUInt32 = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.envoy.config.core.v3.RuntimeUInt32, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.RuntimeUInt32.displayName =
    'proto.envoy.config.core.v3.RuntimeUInt32';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.RuntimePercent = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.envoy.config.core.v3.RuntimePercent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.RuntimePercent.displayName =
    'proto.envoy.config.core.v3.RuntimePercent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.RuntimeDouble = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.envoy.config.core.v3.RuntimeDouble, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.RuntimeDouble.displayName =
    'proto.envoy.config.core.v3.RuntimeDouble';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.RuntimeFeatureFlag = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.envoy.config.core.v3.RuntimeFeatureFlag, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.RuntimeFeatureFlag.displayName =
    'proto.envoy.config.core.v3.RuntimeFeatureFlag';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.HeaderValue = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.envoy.config.core.v3.HeaderValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.HeaderValue.displayName =
    'proto.envoy.config.core.v3.HeaderValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.HeaderValueOption = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.envoy.config.core.v3.HeaderValueOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.HeaderValueOption.displayName =
    'proto.envoy.config.core.v3.HeaderValueOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.HeaderMap = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.envoy.config.core.v3.HeaderMap.repeatedFields_,
    null
  );
};
goog.inherits(proto.envoy.config.core.v3.HeaderMap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.HeaderMap.displayName =
    'proto.envoy.config.core.v3.HeaderMap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.WatchedDirectory = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.envoy.config.core.v3.WatchedDirectory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.WatchedDirectory.displayName =
    'proto.envoy.config.core.v3.WatchedDirectory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.DataSource = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    null,
    proto.envoy.config.core.v3.DataSource.oneofGroups_
  );
};
goog.inherits(proto.envoy.config.core.v3.DataSource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.DataSource.displayName =
    'proto.envoy.config.core.v3.DataSource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.RetryPolicy = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.envoy.config.core.v3.RetryPolicy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.RetryPolicy.displayName =
    'proto.envoy.config.core.v3.RetryPolicy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.RemoteDataSource = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.envoy.config.core.v3.RemoteDataSource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.RemoteDataSource.displayName =
    'proto.envoy.config.core.v3.RemoteDataSource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.AsyncDataSource = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    null,
    proto.envoy.config.core.v3.AsyncDataSource.oneofGroups_
  );
};
goog.inherits(proto.envoy.config.core.v3.AsyncDataSource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.AsyncDataSource.displayName =
    'proto.envoy.config.core.v3.AsyncDataSource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.TransportSocket = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    null,
    proto.envoy.config.core.v3.TransportSocket.oneofGroups_
  );
};
goog.inherits(proto.envoy.config.core.v3.TransportSocket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.TransportSocket.displayName =
    'proto.envoy.config.core.v3.TransportSocket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.RuntimeFractionalPercent = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(
  proto.envoy.config.core.v3.RuntimeFractionalPercent,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.RuntimeFractionalPercent.displayName =
    'proto.envoy.config.core.v3.RuntimeFractionalPercent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.ControlPlane = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.envoy.config.core.v3.ControlPlane, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.ControlPlane.displayName =
    'proto.envoy.config.core.v3.ControlPlane';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.Locality.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.config.core.v3.Locality.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.Locality} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.Locality.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        region: jspb.Message.getFieldWithDefault(msg, 1, ''),
        zone: jspb.Message.getFieldWithDefault(msg, 2, ''),
        subZone: jspb.Message.getFieldWithDefault(msg, 3, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.Locality}
 */
proto.envoy.config.core.v3.Locality.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.Locality();
  return proto.envoy.config.core.v3.Locality.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.Locality} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.Locality}
 */
proto.envoy.config.core.v3.Locality.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setRegion(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setZone(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setSubZone(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.Locality.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.envoy.config.core.v3.Locality.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.Locality} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.Locality.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getRegion();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getZone();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getSubZone();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
};

/**
 * optional string region = 1;
 * @return {string}
 */
proto.envoy.config.core.v3.Locality.prototype.getRegion = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.Locality} returns this
 */
proto.envoy.config.core.v3.Locality.prototype.setRegion = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string zone = 2;
 * @return {string}
 */
proto.envoy.config.core.v3.Locality.prototype.getZone = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.Locality} returns this
 */
proto.envoy.config.core.v3.Locality.prototype.setZone = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string sub_zone = 3;
 * @return {string}
 */
proto.envoy.config.core.v3.Locality.prototype.getSubZone = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.Locality} returns this
 */
proto.envoy.config.core.v3.Locality.prototype.setSubZone = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.BuildVersion.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.config.core.v3.BuildVersion.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.BuildVersion} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.BuildVersion.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        version:
          (f = msg.getVersion()) &&
          envoy_type_v3_semantic_version_pb.SemanticVersion.toObject(
            includeInstance,
            f
          ),
        metadata:
          (f = msg.getMetadata()) &&
          google_protobuf_struct_pb.Struct.toObject(includeInstance, f)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.BuildVersion}
 */
proto.envoy.config.core.v3.BuildVersion.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.BuildVersion();
  return proto.envoy.config.core.v3.BuildVersion.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.BuildVersion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.BuildVersion}
 */
proto.envoy.config.core.v3.BuildVersion.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new envoy_type_v3_semantic_version_pb.SemanticVersion();
        reader.readMessage(
          value,
          envoy_type_v3_semantic_version_pb.SemanticVersion
            .deserializeBinaryFromReader
        );
        msg.setVersion(value);
        break;
      case 2:
        var value = new google_protobuf_struct_pb.Struct();
        reader.readMessage(
          value,
          google_protobuf_struct_pb.Struct.deserializeBinaryFromReader
        );
        msg.setMetadata(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.BuildVersion.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.BuildVersion.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.BuildVersion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.BuildVersion.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getVersion();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      envoy_type_v3_semantic_version_pb.SemanticVersion.serializeBinaryToWriter
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
};

/**
 * optional envoy.type.v3.SemanticVersion version = 1;
 * @return {?proto.envoy.type.v3.SemanticVersion}
 */
proto.envoy.config.core.v3.BuildVersion.prototype.getVersion = function () {
  return /** @type{?proto.envoy.type.v3.SemanticVersion} */ (
    jspb.Message.getWrapperField(
      this,
      envoy_type_v3_semantic_version_pb.SemanticVersion,
      1
    )
  );
};

/**
 * @param {?proto.envoy.type.v3.SemanticVersion|undefined} value
 * @return {!proto.envoy.config.core.v3.BuildVersion} returns this
 */
proto.envoy.config.core.v3.BuildVersion.prototype.setVersion = function (
  value
) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.BuildVersion} returns this
 */
proto.envoy.config.core.v3.BuildVersion.prototype.clearVersion = function () {
  return this.setVersion(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.BuildVersion.prototype.hasVersion = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional google.protobuf.Struct metadata = 2;
 * @return {?proto.google.protobuf.Struct}
 */
proto.envoy.config.core.v3.BuildVersion.prototype.getMetadata = function () {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 2)
  );
};

/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.envoy.config.core.v3.BuildVersion} returns this
 */
proto.envoy.config.core.v3.BuildVersion.prototype.setMetadata = function (
  value
) {
  return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.BuildVersion} returns this
 */
proto.envoy.config.core.v3.BuildVersion.prototype.clearMetadata = function () {
  return this.setMetadata(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.BuildVersion.prototype.hasMetadata = function () {
  return jspb.Message.getField(this, 2) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.Extension.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.config.core.v3.Extension.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.Extension} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.Extension.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        name: jspb.Message.getFieldWithDefault(msg, 1, ''),
        category: jspb.Message.getFieldWithDefault(msg, 2, ''),
        typeDescriptor: jspb.Message.getFieldWithDefault(msg, 3, ''),
        version:
          (f = msg.getVersion()) &&
          proto.envoy.config.core.v3.BuildVersion.toObject(includeInstance, f),
        disabled: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.Extension}
 */
proto.envoy.config.core.v3.Extension.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.Extension();
  return proto.envoy.config.core.v3.Extension.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.Extension} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.Extension}
 */
proto.envoy.config.core.v3.Extension.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setCategory(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setTypeDescriptor(value);
        break;
      case 4:
        var value = new proto.envoy.config.core.v3.BuildVersion();
        reader.readMessage(
          value,
          proto.envoy.config.core.v3.BuildVersion.deserializeBinaryFromReader
        );
        msg.setVersion(value);
        break;
      case 5:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setDisabled(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.Extension.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.envoy.config.core.v3.Extension.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.Extension} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.Extension.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getTypeDescriptor();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
  f = message.getVersion();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.envoy.config.core.v3.BuildVersion.serializeBinaryToWriter
    );
  }
  f = message.getDisabled();
  if (f) {
    writer.writeBool(5, f);
  }
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.envoy.config.core.v3.Extension.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.Extension} returns this
 */
proto.envoy.config.core.v3.Extension.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string category = 2;
 * @return {string}
 */
proto.envoy.config.core.v3.Extension.prototype.getCategory = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.Extension} returns this
 */
proto.envoy.config.core.v3.Extension.prototype.setCategory = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string type_descriptor = 3;
 * @return {string}
 */
proto.envoy.config.core.v3.Extension.prototype.getTypeDescriptor = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.Extension} returns this
 */
proto.envoy.config.core.v3.Extension.prototype.setTypeDescriptor = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional BuildVersion version = 4;
 * @return {?proto.envoy.config.core.v3.BuildVersion}
 */
proto.envoy.config.core.v3.Extension.prototype.getVersion = function () {
  return /** @type{?proto.envoy.config.core.v3.BuildVersion} */ (
    jspb.Message.getWrapperField(
      this,
      proto.envoy.config.core.v3.BuildVersion,
      4
    )
  );
};

/**
 * @param {?proto.envoy.config.core.v3.BuildVersion|undefined} value
 * @return {!proto.envoy.config.core.v3.Extension} returns this
 */
proto.envoy.config.core.v3.Extension.prototype.setVersion = function (value) {
  return jspb.Message.setWrapperField(this, 4, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.Extension} returns this
 */
proto.envoy.config.core.v3.Extension.prototype.clearVersion = function () {
  return this.setVersion(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.Extension.prototype.hasVersion = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional bool disabled = 5;
 * @return {boolean}
 */
proto.envoy.config.core.v3.Extension.prototype.getDisabled = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 5, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.envoy.config.core.v3.Extension} returns this
 */
proto.envoy.config.core.v3.Extension.prototype.setDisabled = function (value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.envoy.config.core.v3.Node.repeatedFields_ = [9, 10, 11];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.envoy.config.core.v3.Node.oneofGroups_ = [[7, 8]];

/**
 * @enum {number}
 */
proto.envoy.config.core.v3.Node.UserAgentVersionTypeCase = {
  USER_AGENT_VERSION_TYPE_NOT_SET: 0,
  USER_AGENT_VERSION: 7,
  USER_AGENT_BUILD_VERSION: 8
};

/**
 * @return {proto.envoy.config.core.v3.Node.UserAgentVersionTypeCase}
 */
proto.envoy.config.core.v3.Node.prototype.getUserAgentVersionTypeCase =
  function () {
    return /** @type {proto.envoy.config.core.v3.Node.UserAgentVersionTypeCase} */ (
      jspb.Message.computeOneofCase(
        this,
        proto.envoy.config.core.v3.Node.oneofGroups_[0]
      )
    );
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.Node.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.config.core.v3.Node.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.Node} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.Node.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, ''),
        cluster: jspb.Message.getFieldWithDefault(msg, 2, ''),
        metadata:
          (f = msg.getMetadata()) &&
          google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
        locality:
          (f = msg.getLocality()) &&
          proto.envoy.config.core.v3.Locality.toObject(includeInstance, f),
        userAgentName: jspb.Message.getFieldWithDefault(msg, 6, ''),
        userAgentVersion: jspb.Message.getFieldWithDefault(msg, 7, ''),
        userAgentBuildVersion:
          (f = msg.getUserAgentBuildVersion()) &&
          proto.envoy.config.core.v3.BuildVersion.toObject(includeInstance, f),
        extensionsList: jspb.Message.toObjectList(
          msg.getExtensionsList(),
          proto.envoy.config.core.v3.Extension.toObject,
          includeInstance
        ),
        clientFeaturesList:
          (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
        listeningAddressesList: jspb.Message.toObjectList(
          msg.getListeningAddressesList(),
          envoy_config_core_v3_address_pb.Address.toObject,
          includeInstance
        )
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.Node}
 */
proto.envoy.config.core.v3.Node.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.Node();
  return proto.envoy.config.core.v3.Node.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.Node} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.Node}
 */
proto.envoy.config.core.v3.Node.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setCluster(value);
        break;
      case 3:
        var value = new google_protobuf_struct_pb.Struct();
        reader.readMessage(
          value,
          google_protobuf_struct_pb.Struct.deserializeBinaryFromReader
        );
        msg.setMetadata(value);
        break;
      case 4:
        var value = new proto.envoy.config.core.v3.Locality();
        reader.readMessage(
          value,
          proto.envoy.config.core.v3.Locality.deserializeBinaryFromReader
        );
        msg.setLocality(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setUserAgentName(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setUserAgentVersion(value);
        break;
      case 8:
        var value = new proto.envoy.config.core.v3.BuildVersion();
        reader.readMessage(
          value,
          proto.envoy.config.core.v3.BuildVersion.deserializeBinaryFromReader
        );
        msg.setUserAgentBuildVersion(value);
        break;
      case 9:
        var value = new proto.envoy.config.core.v3.Extension();
        reader.readMessage(
          value,
          proto.envoy.config.core.v3.Extension.deserializeBinaryFromReader
        );
        msg.addExtensions(value);
        break;
      case 10:
        var value = /** @type {string} */ (reader.readString());
        msg.addClientFeatures(value);
        break;
      case 11:
        var value = new envoy_config_core_v3_address_pb.Address();
        reader.readMessage(
          value,
          envoy_config_core_v3_address_pb.Address.deserializeBinaryFromReader
        );
        msg.addListeningAddresses(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.Node.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.envoy.config.core.v3.Node.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.Node} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.Node.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getCluster();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getLocality();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.envoy.config.core.v3.Locality.serializeBinaryToWriter
    );
  }
  f = message.getUserAgentName();
  if (f.length > 0) {
    writer.writeString(6, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(7, f);
  }
  f = message.getUserAgentBuildVersion();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.envoy.config.core.v3.BuildVersion.serializeBinaryToWriter
    );
  }
  f = message.getExtensionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.envoy.config.core.v3.Extension.serializeBinaryToWriter
    );
  }
  f = message.getClientFeaturesList();
  if (f.length > 0) {
    writer.writeRepeatedString(10, f);
  }
  f = message.getListeningAddressesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      envoy_config_core_v3_address_pb.Address.serializeBinaryToWriter
    );
  }
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.envoy.config.core.v3.Node.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.Node} returns this
 */
proto.envoy.config.core.v3.Node.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string cluster = 2;
 * @return {string}
 */
proto.envoy.config.core.v3.Node.prototype.getCluster = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.Node} returns this
 */
proto.envoy.config.core.v3.Node.prototype.setCluster = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional google.protobuf.Struct metadata = 3;
 * @return {?proto.google.protobuf.Struct}
 */
proto.envoy.config.core.v3.Node.prototype.getMetadata = function () {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 3)
  );
};

/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.envoy.config.core.v3.Node} returns this
 */
proto.envoy.config.core.v3.Node.prototype.setMetadata = function (value) {
  return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.Node} returns this
 */
proto.envoy.config.core.v3.Node.prototype.clearMetadata = function () {
  return this.setMetadata(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.Node.prototype.hasMetadata = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional Locality locality = 4;
 * @return {?proto.envoy.config.core.v3.Locality}
 */
proto.envoy.config.core.v3.Node.prototype.getLocality = function () {
  return /** @type{?proto.envoy.config.core.v3.Locality} */ (
    jspb.Message.getWrapperField(this, proto.envoy.config.core.v3.Locality, 4)
  );
};

/**
 * @param {?proto.envoy.config.core.v3.Locality|undefined} value
 * @return {!proto.envoy.config.core.v3.Node} returns this
 */
proto.envoy.config.core.v3.Node.prototype.setLocality = function (value) {
  return jspb.Message.setWrapperField(this, 4, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.Node} returns this
 */
proto.envoy.config.core.v3.Node.prototype.clearLocality = function () {
  return this.setLocality(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.Node.prototype.hasLocality = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional string user_agent_name = 6;
 * @return {string}
 */
proto.envoy.config.core.v3.Node.prototype.getUserAgentName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.Node} returns this
 */
proto.envoy.config.core.v3.Node.prototype.setUserAgentName = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};

/**
 * optional string user_agent_version = 7;
 * @return {string}
 */
proto.envoy.config.core.v3.Node.prototype.getUserAgentVersion = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''));
};

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.Node} returns this
 */
proto.envoy.config.core.v3.Node.prototype.setUserAgentVersion = function (
  value
) {
  return jspb.Message.setOneofField(
    this,
    7,
    proto.envoy.config.core.v3.Node.oneofGroups_[0],
    value
  );
};

/**
 * Clears the field making it undefined.
 * @return {!proto.envoy.config.core.v3.Node} returns this
 */
proto.envoy.config.core.v3.Node.prototype.clearUserAgentVersion = function () {
  return jspb.Message.setOneofField(
    this,
    7,
    proto.envoy.config.core.v3.Node.oneofGroups_[0],
    undefined
  );
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.Node.prototype.hasUserAgentVersion = function () {
  return jspb.Message.getField(this, 7) != null;
};

/**
 * optional BuildVersion user_agent_build_version = 8;
 * @return {?proto.envoy.config.core.v3.BuildVersion}
 */
proto.envoy.config.core.v3.Node.prototype.getUserAgentBuildVersion =
  function () {
    return /** @type{?proto.envoy.config.core.v3.BuildVersion} */ (
      jspb.Message.getWrapperField(
        this,
        proto.envoy.config.core.v3.BuildVersion,
        8
      )
    );
  };

/**
 * @param {?proto.envoy.config.core.v3.BuildVersion|undefined} value
 * @return {!proto.envoy.config.core.v3.Node} returns this
 */
proto.envoy.config.core.v3.Node.prototype.setUserAgentBuildVersion = function (
  value
) {
  return jspb.Message.setOneofWrapperField(
    this,
    8,
    proto.envoy.config.core.v3.Node.oneofGroups_[0],
    value
  );
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.Node} returns this
 */
proto.envoy.config.core.v3.Node.prototype.clearUserAgentBuildVersion =
  function () {
    return this.setUserAgentBuildVersion(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.Node.prototype.hasUserAgentBuildVersion =
  function () {
    return jspb.Message.getField(this, 8) != null;
  };

/**
 * repeated Extension extensions = 9;
 * @return {!Array<!proto.envoy.config.core.v3.Extension>}
 */
proto.envoy.config.core.v3.Node.prototype.getExtensionsList = function () {
  return /** @type{!Array<!proto.envoy.config.core.v3.Extension>} */ (
    jspb.Message.getRepeatedWrapperField(
      this,
      proto.envoy.config.core.v3.Extension,
      9
    )
  );
};

/**
 * @param {!Array<!proto.envoy.config.core.v3.Extension>} value
 * @return {!proto.envoy.config.core.v3.Node} returns this
 */
proto.envoy.config.core.v3.Node.prototype.setExtensionsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};

/**
 * @param {!proto.envoy.config.core.v3.Extension=} opt_value
 * @param {number=} opt_index
 * @return {!proto.envoy.config.core.v3.Extension}
 */
proto.envoy.config.core.v3.Node.prototype.addExtensions = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    9,
    opt_value,
    proto.envoy.config.core.v3.Extension,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.envoy.config.core.v3.Node} returns this
 */
proto.envoy.config.core.v3.Node.prototype.clearExtensionsList = function () {
  return this.setExtensionsList([]);
};

/**
 * repeated string client_features = 10;
 * @return {!Array<string>}
 */
proto.envoy.config.core.v3.Node.prototype.getClientFeaturesList = function () {
  return /** @type {!Array<string>} */ (
    jspb.Message.getRepeatedField(this, 10)
  );
};

/**
 * @param {!Array<string>} value
 * @return {!proto.envoy.config.core.v3.Node} returns this
 */
proto.envoy.config.core.v3.Node.prototype.setClientFeaturesList = function (
  value
) {
  return jspb.Message.setField(this, 10, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.envoy.config.core.v3.Node} returns this
 */
proto.envoy.config.core.v3.Node.prototype.addClientFeatures = function (
  value,
  opt_index
) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.envoy.config.core.v3.Node} returns this
 */
proto.envoy.config.core.v3.Node.prototype.clearClientFeaturesList =
  function () {
    return this.setClientFeaturesList([]);
  };

/**
 * repeated Address listening_addresses = 11;
 * @return {!Array<!proto.envoy.config.core.v3.Address>}
 */
proto.envoy.config.core.v3.Node.prototype.getListeningAddressesList =
  function () {
    return /** @type{!Array<!proto.envoy.config.core.v3.Address>} */ (
      jspb.Message.getRepeatedWrapperField(
        this,
        envoy_config_core_v3_address_pb.Address,
        11
      )
    );
  };

/**
 * @param {!Array<!proto.envoy.config.core.v3.Address>} value
 * @return {!proto.envoy.config.core.v3.Node} returns this
 */
proto.envoy.config.core.v3.Node.prototype.setListeningAddressesList = function (
  value
) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};

/**
 * @param {!proto.envoy.config.core.v3.Address=} opt_value
 * @param {number=} opt_index
 * @return {!proto.envoy.config.core.v3.Address}
 */
proto.envoy.config.core.v3.Node.prototype.addListeningAddresses = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    11,
    opt_value,
    proto.envoy.config.core.v3.Address,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.envoy.config.core.v3.Node} returns this
 */
proto.envoy.config.core.v3.Node.prototype.clearListeningAddressesList =
  function () {
    return this.setListeningAddressesList([]);
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.Metadata.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.config.core.v3.Metadata.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.Metadata} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.Metadata.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        filterMetadataMap: (f = msg.getFilterMetadataMap())
          ? f.toObject(includeInstance, proto.google.protobuf.Struct.toObject)
          : []
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.Metadata}
 */
proto.envoy.config.core.v3.Metadata.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.Metadata();
  return proto.envoy.config.core.v3.Metadata.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.Metadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.Metadata}
 */
proto.envoy.config.core.v3.Metadata.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = msg.getFilterMetadataMap();
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(
            message,
            reader,
            jspb.BinaryReader.prototype.readString,
            jspb.BinaryReader.prototype.readMessage,
            proto.google.protobuf.Struct.deserializeBinaryFromReader,
            '',
            new proto.google.protobuf.Struct()
          );
        });
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.Metadata.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.envoy.config.core.v3.Metadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.Metadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.Metadata.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getFilterMetadataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(
      1,
      writer,
      jspb.BinaryWriter.prototype.writeString,
      jspb.BinaryWriter.prototype.writeMessage,
      proto.google.protobuf.Struct.serializeBinaryToWriter
    );
  }
};

/**
 * map<string, google.protobuf.Struct> filter_metadata = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.google.protobuf.Struct>}
 */
proto.envoy.config.core.v3.Metadata.prototype.getFilterMetadataMap = function (
  opt_noLazyCreate
) {
  return /** @type {!jspb.Map<string,!proto.google.protobuf.Struct>} */ (
    jspb.Message.getMapField(
      this,
      1,
      opt_noLazyCreate,
      proto.google.protobuf.Struct
    )
  );
};

/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.envoy.config.core.v3.Metadata} returns this
 */
proto.envoy.config.core.v3.Metadata.prototype.clearFilterMetadataMap =
  function () {
    this.getFilterMetadataMap().clear();
    return this;
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.RuntimeUInt32.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.config.core.v3.RuntimeUInt32.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.RuntimeUInt32} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.RuntimeUInt32.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        defaultValue: jspb.Message.getFieldWithDefault(msg, 2, 0),
        runtimeKey: jspb.Message.getFieldWithDefault(msg, 3, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.RuntimeUInt32}
 */
proto.envoy.config.core.v3.RuntimeUInt32.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.RuntimeUInt32();
  return proto.envoy.config.core.v3.RuntimeUInt32.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.RuntimeUInt32} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.RuntimeUInt32}
 */
proto.envoy.config.core.v3.RuntimeUInt32.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 2:
          var value = /** @type {number} */ (reader.readUint32());
          msg.setDefaultValue(value);
          break;
        case 3:
          var value = /** @type {string} */ (reader.readString());
          msg.setRuntimeKey(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.RuntimeUInt32.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.RuntimeUInt32.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.RuntimeUInt32} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.RuntimeUInt32.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getDefaultValue();
  if (f !== 0) {
    writer.writeUint32(2, f);
  }
  f = message.getRuntimeKey();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
};

/**
 * optional uint32 default_value = 2;
 * @return {number}
 */
proto.envoy.config.core.v3.RuntimeUInt32.prototype.getDefaultValue =
  function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };

/**
 * @param {number} value
 * @return {!proto.envoy.config.core.v3.RuntimeUInt32} returns this
 */
proto.envoy.config.core.v3.RuntimeUInt32.prototype.setDefaultValue = function (
  value
) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional string runtime_key = 3;
 * @return {string}
 */
proto.envoy.config.core.v3.RuntimeUInt32.prototype.getRuntimeKey = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.RuntimeUInt32} returns this
 */
proto.envoy.config.core.v3.RuntimeUInt32.prototype.setRuntimeKey = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.RuntimePercent.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.config.core.v3.RuntimePercent.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.RuntimePercent} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.RuntimePercent.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        defaultValue:
          (f = msg.getDefaultValue()) &&
          envoy_type_v3_percent_pb.Percent.toObject(includeInstance, f),
        runtimeKey: jspb.Message.getFieldWithDefault(msg, 2, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.RuntimePercent}
 */
proto.envoy.config.core.v3.RuntimePercent.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.RuntimePercent();
  return proto.envoy.config.core.v3.RuntimePercent.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.RuntimePercent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.RuntimePercent}
 */
proto.envoy.config.core.v3.RuntimePercent.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new envoy_type_v3_percent_pb.Percent();
          reader.readMessage(
            value,
            envoy_type_v3_percent_pb.Percent.deserializeBinaryFromReader
          );
          msg.setDefaultValue(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setRuntimeKey(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.RuntimePercent.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.RuntimePercent.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.RuntimePercent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.RuntimePercent.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getDefaultValue();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      envoy_type_v3_percent_pb.Percent.serializeBinaryToWriter
    );
  }
  f = message.getRuntimeKey();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
};

/**
 * optional envoy.type.v3.Percent default_value = 1;
 * @return {?proto.envoy.type.v3.Percent}
 */
proto.envoy.config.core.v3.RuntimePercent.prototype.getDefaultValue =
  function () {
    return /** @type{?proto.envoy.type.v3.Percent} */ (
      jspb.Message.getWrapperField(this, envoy_type_v3_percent_pb.Percent, 1)
    );
  };

/**
 * @param {?proto.envoy.type.v3.Percent|undefined} value
 * @return {!proto.envoy.config.core.v3.RuntimePercent} returns this
 */
proto.envoy.config.core.v3.RuntimePercent.prototype.setDefaultValue = function (
  value
) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.RuntimePercent} returns this
 */
proto.envoy.config.core.v3.RuntimePercent.prototype.clearDefaultValue =
  function () {
    return this.setDefaultValue(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.RuntimePercent.prototype.hasDefaultValue =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

/**
 * optional string runtime_key = 2;
 * @return {string}
 */
proto.envoy.config.core.v3.RuntimePercent.prototype.getRuntimeKey =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 2, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.RuntimePercent} returns this
 */
proto.envoy.config.core.v3.RuntimePercent.prototype.setRuntimeKey = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.RuntimeDouble.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.config.core.v3.RuntimeDouble.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.RuntimeDouble} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.RuntimeDouble.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        defaultValue: jspb.Message.getFloatingPointFieldWithDefault(
          msg,
          1,
          0.0
        ),
        runtimeKey: jspb.Message.getFieldWithDefault(msg, 2, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.RuntimeDouble}
 */
proto.envoy.config.core.v3.RuntimeDouble.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.RuntimeDouble();
  return proto.envoy.config.core.v3.RuntimeDouble.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.RuntimeDouble} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.RuntimeDouble}
 */
proto.envoy.config.core.v3.RuntimeDouble.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {number} */ (reader.readDouble());
          msg.setDefaultValue(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setRuntimeKey(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.RuntimeDouble.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.RuntimeDouble.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.RuntimeDouble} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.RuntimeDouble.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getDefaultValue();
  if (f !== 0.0) {
    writer.writeDouble(1, f);
  }
  f = message.getRuntimeKey();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
};

/**
 * optional double default_value = 1;
 * @return {number}
 */
proto.envoy.config.core.v3.RuntimeDouble.prototype.getDefaultValue =
  function () {
    return /** @type {number} */ (
      jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0)
    );
  };

/**
 * @param {number} value
 * @return {!proto.envoy.config.core.v3.RuntimeDouble} returns this
 */
proto.envoy.config.core.v3.RuntimeDouble.prototype.setDefaultValue = function (
  value
) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};

/**
 * optional string runtime_key = 2;
 * @return {string}
 */
proto.envoy.config.core.v3.RuntimeDouble.prototype.getRuntimeKey = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.RuntimeDouble} returns this
 */
proto.envoy.config.core.v3.RuntimeDouble.prototype.setRuntimeKey = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.RuntimeFeatureFlag.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.config.core.v3.RuntimeFeatureFlag.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.RuntimeFeatureFlag} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.RuntimeFeatureFlag.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        defaultValue:
          (f = msg.getDefaultValue()) &&
          google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
        runtimeKey: jspb.Message.getFieldWithDefault(msg, 2, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.RuntimeFeatureFlag}
 */
proto.envoy.config.core.v3.RuntimeFeatureFlag.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.RuntimeFeatureFlag();
  return proto.envoy.config.core.v3.RuntimeFeatureFlag.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.RuntimeFeatureFlag} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.RuntimeFeatureFlag}
 */
proto.envoy.config.core.v3.RuntimeFeatureFlag.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new google_protobuf_wrappers_pb.BoolValue();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader
          );
          msg.setDefaultValue(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setRuntimeKey(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.RuntimeFeatureFlag.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.RuntimeFeatureFlag.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.RuntimeFeatureFlag} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.RuntimeFeatureFlag.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getDefaultValue();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
      );
    }
    f = message.getRuntimeKey();
    if (f.length > 0) {
      writer.writeString(2, f);
    }
  };

/**
 * optional google.protobuf.BoolValue default_value = 1;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.envoy.config.core.v3.RuntimeFeatureFlag.prototype.getDefaultValue =
  function () {
    return /** @type{?proto.google.protobuf.BoolValue} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.BoolValue,
        1
      )
    );
  };

/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.envoy.config.core.v3.RuntimeFeatureFlag} returns this
 */
proto.envoy.config.core.v3.RuntimeFeatureFlag.prototype.setDefaultValue =
  function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.RuntimeFeatureFlag} returns this
 */
proto.envoy.config.core.v3.RuntimeFeatureFlag.prototype.clearDefaultValue =
  function () {
    return this.setDefaultValue(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.RuntimeFeatureFlag.prototype.hasDefaultValue =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

/**
 * optional string runtime_key = 2;
 * @return {string}
 */
proto.envoy.config.core.v3.RuntimeFeatureFlag.prototype.getRuntimeKey =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 2, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.RuntimeFeatureFlag} returns this
 */
proto.envoy.config.core.v3.RuntimeFeatureFlag.prototype.setRuntimeKey =
  function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.HeaderValue.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.config.core.v3.HeaderValue.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.HeaderValue} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.HeaderValue.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        key: jspb.Message.getFieldWithDefault(msg, 1, ''),
        value: jspb.Message.getFieldWithDefault(msg, 2, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.HeaderValue}
 */
proto.envoy.config.core.v3.HeaderValue.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.HeaderValue();
  return proto.envoy.config.core.v3.HeaderValue.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.HeaderValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.HeaderValue}
 */
proto.envoy.config.core.v3.HeaderValue.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setKey(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setValue(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.HeaderValue.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.envoy.config.core.v3.HeaderValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.HeaderValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.HeaderValue.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
};

/**
 * optional string key = 1;
 * @return {string}
 */
proto.envoy.config.core.v3.HeaderValue.prototype.getKey = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.HeaderValue} returns this
 */
proto.envoy.config.core.v3.HeaderValue.prototype.setKey = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string value = 2;
 * @return {string}
 */
proto.envoy.config.core.v3.HeaderValue.prototype.getValue = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.HeaderValue} returns this
 */
proto.envoy.config.core.v3.HeaderValue.prototype.setValue = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.HeaderValueOption.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.config.core.v3.HeaderValueOption.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.HeaderValueOption} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.HeaderValueOption.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        header:
          (f = msg.getHeader()) &&
          proto.envoy.config.core.v3.HeaderValue.toObject(includeInstance, f),
        append:
          (f = msg.getAppend()) &&
          google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.HeaderValueOption}
 */
proto.envoy.config.core.v3.HeaderValueOption.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.HeaderValueOption();
  return proto.envoy.config.core.v3.HeaderValueOption.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.HeaderValueOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.HeaderValueOption}
 */
proto.envoy.config.core.v3.HeaderValueOption.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.envoy.config.core.v3.HeaderValue();
          reader.readMessage(
            value,
            proto.envoy.config.core.v3.HeaderValue.deserializeBinaryFromReader
          );
          msg.setHeader(value);
          break;
        case 2:
          var value = new google_protobuf_wrappers_pb.BoolValue();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader
          );
          msg.setAppend(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.HeaderValueOption.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.HeaderValueOption.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.HeaderValueOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.HeaderValueOption.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getHeader();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        proto.envoy.config.core.v3.HeaderValue.serializeBinaryToWriter
      );
    }
    f = message.getAppend();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
      );
    }
  };

/**
 * optional HeaderValue header = 1;
 * @return {?proto.envoy.config.core.v3.HeaderValue}
 */
proto.envoy.config.core.v3.HeaderValueOption.prototype.getHeader = function () {
  return /** @type{?proto.envoy.config.core.v3.HeaderValue} */ (
    jspb.Message.getWrapperField(
      this,
      proto.envoy.config.core.v3.HeaderValue,
      1
    )
  );
};

/**
 * @param {?proto.envoy.config.core.v3.HeaderValue|undefined} value
 * @return {!proto.envoy.config.core.v3.HeaderValueOption} returns this
 */
proto.envoy.config.core.v3.HeaderValueOption.prototype.setHeader = function (
  value
) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.HeaderValueOption} returns this
 */
proto.envoy.config.core.v3.HeaderValueOption.prototype.clearHeader =
  function () {
    return this.setHeader(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.HeaderValueOption.prototype.hasHeader = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional google.protobuf.BoolValue append = 2;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.envoy.config.core.v3.HeaderValueOption.prototype.getAppend = function () {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 2)
  );
};

/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.envoy.config.core.v3.HeaderValueOption} returns this
 */
proto.envoy.config.core.v3.HeaderValueOption.prototype.setAppend = function (
  value
) {
  return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.HeaderValueOption} returns this
 */
proto.envoy.config.core.v3.HeaderValueOption.prototype.clearAppend =
  function () {
    return this.setAppend(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.HeaderValueOption.prototype.hasAppend = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.envoy.config.core.v3.HeaderMap.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.HeaderMap.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.config.core.v3.HeaderMap.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.HeaderMap} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.HeaderMap.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        headersList: jspb.Message.toObjectList(
          msg.getHeadersList(),
          proto.envoy.config.core.v3.HeaderValue.toObject,
          includeInstance
        )
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.HeaderMap}
 */
proto.envoy.config.core.v3.HeaderMap.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.HeaderMap();
  return proto.envoy.config.core.v3.HeaderMap.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.HeaderMap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.HeaderMap}
 */
proto.envoy.config.core.v3.HeaderMap.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.envoy.config.core.v3.HeaderValue();
        reader.readMessage(
          value,
          proto.envoy.config.core.v3.HeaderValue.deserializeBinaryFromReader
        );
        msg.addHeaders(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.HeaderMap.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.envoy.config.core.v3.HeaderMap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.HeaderMap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.HeaderMap.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getHeadersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.envoy.config.core.v3.HeaderValue.serializeBinaryToWriter
    );
  }
};

/**
 * repeated HeaderValue headers = 1;
 * @return {!Array<!proto.envoy.config.core.v3.HeaderValue>}
 */
proto.envoy.config.core.v3.HeaderMap.prototype.getHeadersList = function () {
  return /** @type{!Array<!proto.envoy.config.core.v3.HeaderValue>} */ (
    jspb.Message.getRepeatedWrapperField(
      this,
      proto.envoy.config.core.v3.HeaderValue,
      1
    )
  );
};

/**
 * @param {!Array<!proto.envoy.config.core.v3.HeaderValue>} value
 * @return {!proto.envoy.config.core.v3.HeaderMap} returns this
 */
proto.envoy.config.core.v3.HeaderMap.prototype.setHeadersList = function (
  value
) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.envoy.config.core.v3.HeaderValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.envoy.config.core.v3.HeaderValue}
 */
proto.envoy.config.core.v3.HeaderMap.prototype.addHeaders = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.envoy.config.core.v3.HeaderValue,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.envoy.config.core.v3.HeaderMap} returns this
 */
proto.envoy.config.core.v3.HeaderMap.prototype.clearHeadersList = function () {
  return this.setHeadersList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.WatchedDirectory.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.config.core.v3.WatchedDirectory.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.WatchedDirectory} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.WatchedDirectory.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        path: jspb.Message.getFieldWithDefault(msg, 1, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.WatchedDirectory}
 */
proto.envoy.config.core.v3.WatchedDirectory.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.WatchedDirectory();
  return proto.envoy.config.core.v3.WatchedDirectory.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.WatchedDirectory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.WatchedDirectory}
 */
proto.envoy.config.core.v3.WatchedDirectory.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setPath(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.WatchedDirectory.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.WatchedDirectory.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.WatchedDirectory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.WatchedDirectory.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
};

/**
 * optional string path = 1;
 * @return {string}
 */
proto.envoy.config.core.v3.WatchedDirectory.prototype.getPath = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.WatchedDirectory} returns this
 */
proto.envoy.config.core.v3.WatchedDirectory.prototype.setPath = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.envoy.config.core.v3.DataSource.oneofGroups_ = [[1, 2, 3]];

/**
 * @enum {number}
 */
proto.envoy.config.core.v3.DataSource.SpecifierCase = {
  SPECIFIER_NOT_SET: 0,
  FILENAME: 1,
  INLINE_BYTES: 2,
  INLINE_STRING: 3
};

/**
 * @return {proto.envoy.config.core.v3.DataSource.SpecifierCase}
 */
proto.envoy.config.core.v3.DataSource.prototype.getSpecifierCase = function () {
  return /** @type {proto.envoy.config.core.v3.DataSource.SpecifierCase} */ (
    jspb.Message.computeOneofCase(
      this,
      proto.envoy.config.core.v3.DataSource.oneofGroups_[0]
    )
  );
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.DataSource.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.config.core.v3.DataSource.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.DataSource} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.DataSource.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        filename: jspb.Message.getFieldWithDefault(msg, 1, ''),
        inlineBytes: msg.getInlineBytes_asB64(),
        inlineString: jspb.Message.getFieldWithDefault(msg, 3, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.DataSource}
 */
proto.envoy.config.core.v3.DataSource.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.DataSource();
  return proto.envoy.config.core.v3.DataSource.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.DataSource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.DataSource}
 */
proto.envoy.config.core.v3.DataSource.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setFilename(value);
        break;
      case 2:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setInlineBytes(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setInlineString(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.DataSource.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.envoy.config.core.v3.DataSource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.DataSource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.DataSource.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(1, f);
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(2, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(3, f);
  }
};

/**
 * optional string filename = 1;
 * @return {string}
 */
proto.envoy.config.core.v3.DataSource.prototype.getFilename = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.DataSource} returns this
 */
proto.envoy.config.core.v3.DataSource.prototype.setFilename = function (value) {
  return jspb.Message.setOneofField(
    this,
    1,
    proto.envoy.config.core.v3.DataSource.oneofGroups_[0],
    value
  );
};

/**
 * Clears the field making it undefined.
 * @return {!proto.envoy.config.core.v3.DataSource} returns this
 */
proto.envoy.config.core.v3.DataSource.prototype.clearFilename = function () {
  return jspb.Message.setOneofField(
    this,
    1,
    proto.envoy.config.core.v3.DataSource.oneofGroups_[0],
    undefined
  );
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.DataSource.prototype.hasFilename = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional bytes inline_bytes = 2;
 * @return {string}
 */
proto.envoy.config.core.v3.DataSource.prototype.getInlineBytes = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * optional bytes inline_bytes = 2;
 * This is a type-conversion wrapper around `getInlineBytes()`
 * @return {string}
 */
proto.envoy.config.core.v3.DataSource.prototype.getInlineBytes_asB64 =
  function () {
    return /** @type {string} */ (
      jspb.Message.bytesAsB64(this.getInlineBytes())
    );
  };

/**
 * optional bytes inline_bytes = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getInlineBytes()`
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.DataSource.prototype.getInlineBytes_asU8 =
  function () {
    return /** @type {!Uint8Array} */ (
      jspb.Message.bytesAsU8(this.getInlineBytes())
    );
  };

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.envoy.config.core.v3.DataSource} returns this
 */
proto.envoy.config.core.v3.DataSource.prototype.setInlineBytes = function (
  value
) {
  return jspb.Message.setOneofField(
    this,
    2,
    proto.envoy.config.core.v3.DataSource.oneofGroups_[0],
    value
  );
};

/**
 * Clears the field making it undefined.
 * @return {!proto.envoy.config.core.v3.DataSource} returns this
 */
proto.envoy.config.core.v3.DataSource.prototype.clearInlineBytes = function () {
  return jspb.Message.setOneofField(
    this,
    2,
    proto.envoy.config.core.v3.DataSource.oneofGroups_[0],
    undefined
  );
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.DataSource.prototype.hasInlineBytes = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional string inline_string = 3;
 * @return {string}
 */
proto.envoy.config.core.v3.DataSource.prototype.getInlineString = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.DataSource} returns this
 */
proto.envoy.config.core.v3.DataSource.prototype.setInlineString = function (
  value
) {
  return jspb.Message.setOneofField(
    this,
    3,
    proto.envoy.config.core.v3.DataSource.oneofGroups_[0],
    value
  );
};

/**
 * Clears the field making it undefined.
 * @return {!proto.envoy.config.core.v3.DataSource} returns this
 */
proto.envoy.config.core.v3.DataSource.prototype.clearInlineString =
  function () {
    return jspb.Message.setOneofField(
      this,
      3,
      proto.envoy.config.core.v3.DataSource.oneofGroups_[0],
      undefined
    );
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.DataSource.prototype.hasInlineString = function () {
  return jspb.Message.getField(this, 3) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.RetryPolicy.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.config.core.v3.RetryPolicy.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.RetryPolicy} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.RetryPolicy.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        retryBackOff:
          (f = msg.getRetryBackOff()) &&
          envoy_config_core_v3_backoff_pb.BackoffStrategy.toObject(
            includeInstance,
            f
          ),
        numRetries:
          (f = msg.getNumRetries()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.RetryPolicy}
 */
proto.envoy.config.core.v3.RetryPolicy.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.RetryPolicy();
  return proto.envoy.config.core.v3.RetryPolicy.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.RetryPolicy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.RetryPolicy}
 */
proto.envoy.config.core.v3.RetryPolicy.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new envoy_config_core_v3_backoff_pb.BackoffStrategy();
        reader.readMessage(
          value,
          envoy_config_core_v3_backoff_pb.BackoffStrategy
            .deserializeBinaryFromReader
        );
        msg.setRetryBackOff(value);
        break;
      case 2:
        var value = new google_protobuf_wrappers_pb.UInt32Value();
        reader.readMessage(
          value,
          google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
        );
        msg.setNumRetries(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.RetryPolicy.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.envoy.config.core.v3.RetryPolicy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.RetryPolicy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.RetryPolicy.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getRetryBackOff();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      envoy_config_core_v3_backoff_pb.BackoffStrategy.serializeBinaryToWriter
    );
  }
  f = message.getNumRetries();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
    );
  }
};

/**
 * optional BackoffStrategy retry_back_off = 1;
 * @return {?proto.envoy.config.core.v3.BackoffStrategy}
 */
proto.envoy.config.core.v3.RetryPolicy.prototype.getRetryBackOff = function () {
  return /** @type{?proto.envoy.config.core.v3.BackoffStrategy} */ (
    jspb.Message.getWrapperField(
      this,
      envoy_config_core_v3_backoff_pb.BackoffStrategy,
      1
    )
  );
};

/**
 * @param {?proto.envoy.config.core.v3.BackoffStrategy|undefined} value
 * @return {!proto.envoy.config.core.v3.RetryPolicy} returns this
 */
proto.envoy.config.core.v3.RetryPolicy.prototype.setRetryBackOff = function (
  value
) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.RetryPolicy} returns this
 */
proto.envoy.config.core.v3.RetryPolicy.prototype.clearRetryBackOff =
  function () {
    return this.setRetryBackOff(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.RetryPolicy.prototype.hasRetryBackOff = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional google.protobuf.UInt32Value num_retries = 2;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.core.v3.RetryPolicy.prototype.getNumRetries = function () {
  return /** @type{?proto.google.protobuf.UInt32Value} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_wrappers_pb.UInt32Value,
      2
    )
  );
};

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.core.v3.RetryPolicy} returns this
 */
proto.envoy.config.core.v3.RetryPolicy.prototype.setNumRetries = function (
  value
) {
  return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.RetryPolicy} returns this
 */
proto.envoy.config.core.v3.RetryPolicy.prototype.clearNumRetries = function () {
  return this.setNumRetries(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.RetryPolicy.prototype.hasNumRetries = function () {
  return jspb.Message.getField(this, 2) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.RemoteDataSource.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.config.core.v3.RemoteDataSource.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.RemoteDataSource} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.RemoteDataSource.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        httpUri:
          (f = msg.getHttpUri()) &&
          envoy_config_core_v3_http_uri_pb.HttpUri.toObject(includeInstance, f),
        sha256: jspb.Message.getFieldWithDefault(msg, 2, ''),
        retryPolicy:
          (f = msg.getRetryPolicy()) &&
          proto.envoy.config.core.v3.RetryPolicy.toObject(includeInstance, f)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.RemoteDataSource}
 */
proto.envoy.config.core.v3.RemoteDataSource.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.RemoteDataSource();
  return proto.envoy.config.core.v3.RemoteDataSource.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.RemoteDataSource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.RemoteDataSource}
 */
proto.envoy.config.core.v3.RemoteDataSource.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new envoy_config_core_v3_http_uri_pb.HttpUri();
          reader.readMessage(
            value,
            envoy_config_core_v3_http_uri_pb.HttpUri.deserializeBinaryFromReader
          );
          msg.setHttpUri(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setSha256(value);
          break;
        case 3:
          var value = new proto.envoy.config.core.v3.RetryPolicy();
          reader.readMessage(
            value,
            proto.envoy.config.core.v3.RetryPolicy.deserializeBinaryFromReader
          );
          msg.setRetryPolicy(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.RemoteDataSource.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.RemoteDataSource.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.RemoteDataSource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.RemoteDataSource.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getHttpUri();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      envoy_config_core_v3_http_uri_pb.HttpUri.serializeBinaryToWriter
    );
  }
  f = message.getSha256();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getRetryPolicy();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.envoy.config.core.v3.RetryPolicy.serializeBinaryToWriter
    );
  }
};

/**
 * optional HttpUri http_uri = 1;
 * @return {?proto.envoy.config.core.v3.HttpUri}
 */
proto.envoy.config.core.v3.RemoteDataSource.prototype.getHttpUri = function () {
  return /** @type{?proto.envoy.config.core.v3.HttpUri} */ (
    jspb.Message.getWrapperField(
      this,
      envoy_config_core_v3_http_uri_pb.HttpUri,
      1
    )
  );
};

/**
 * @param {?proto.envoy.config.core.v3.HttpUri|undefined} value
 * @return {!proto.envoy.config.core.v3.RemoteDataSource} returns this
 */
proto.envoy.config.core.v3.RemoteDataSource.prototype.setHttpUri = function (
  value
) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.RemoteDataSource} returns this
 */
proto.envoy.config.core.v3.RemoteDataSource.prototype.clearHttpUri =
  function () {
    return this.setHttpUri(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.RemoteDataSource.prototype.hasHttpUri = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional string sha256 = 2;
 * @return {string}
 */
proto.envoy.config.core.v3.RemoteDataSource.prototype.getSha256 = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.RemoteDataSource} returns this
 */
proto.envoy.config.core.v3.RemoteDataSource.prototype.setSha256 = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional RetryPolicy retry_policy = 3;
 * @return {?proto.envoy.config.core.v3.RetryPolicy}
 */
proto.envoy.config.core.v3.RemoteDataSource.prototype.getRetryPolicy =
  function () {
    return /** @type{?proto.envoy.config.core.v3.RetryPolicy} */ (
      jspb.Message.getWrapperField(
        this,
        proto.envoy.config.core.v3.RetryPolicy,
        3
      )
    );
  };

/**
 * @param {?proto.envoy.config.core.v3.RetryPolicy|undefined} value
 * @return {!proto.envoy.config.core.v3.RemoteDataSource} returns this
 */
proto.envoy.config.core.v3.RemoteDataSource.prototype.setRetryPolicy =
  function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.RemoteDataSource} returns this
 */
proto.envoy.config.core.v3.RemoteDataSource.prototype.clearRetryPolicy =
  function () {
    return this.setRetryPolicy(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.RemoteDataSource.prototype.hasRetryPolicy =
  function () {
    return jspb.Message.getField(this, 3) != null;
  };

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.envoy.config.core.v3.AsyncDataSource.oneofGroups_ = [[1, 2]];

/**
 * @enum {number}
 */
proto.envoy.config.core.v3.AsyncDataSource.SpecifierCase = {
  SPECIFIER_NOT_SET: 0,
  LOCAL: 1,
  REMOTE: 2
};

/**
 * @return {proto.envoy.config.core.v3.AsyncDataSource.SpecifierCase}
 */
proto.envoy.config.core.v3.AsyncDataSource.prototype.getSpecifierCase =
  function () {
    return /** @type {proto.envoy.config.core.v3.AsyncDataSource.SpecifierCase} */ (
      jspb.Message.computeOneofCase(
        this,
        proto.envoy.config.core.v3.AsyncDataSource.oneofGroups_[0]
      )
    );
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.AsyncDataSource.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.config.core.v3.AsyncDataSource.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.AsyncDataSource} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.AsyncDataSource.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        local:
          (f = msg.getLocal()) &&
          proto.envoy.config.core.v3.DataSource.toObject(includeInstance, f),
        remote:
          (f = msg.getRemote()) &&
          proto.envoy.config.core.v3.RemoteDataSource.toObject(
            includeInstance,
            f
          )
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.AsyncDataSource}
 */
proto.envoy.config.core.v3.AsyncDataSource.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.AsyncDataSource();
  return proto.envoy.config.core.v3.AsyncDataSource.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.AsyncDataSource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.AsyncDataSource}
 */
proto.envoy.config.core.v3.AsyncDataSource.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.envoy.config.core.v3.DataSource();
          reader.readMessage(
            value,
            proto.envoy.config.core.v3.DataSource.deserializeBinaryFromReader
          );
          msg.setLocal(value);
          break;
        case 2:
          var value = new proto.envoy.config.core.v3.RemoteDataSource();
          reader.readMessage(
            value,
            proto.envoy.config.core.v3.RemoteDataSource
              .deserializeBinaryFromReader
          );
          msg.setRemote(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.AsyncDataSource.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.AsyncDataSource.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.AsyncDataSource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.AsyncDataSource.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getLocal();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.envoy.config.core.v3.DataSource.serializeBinaryToWriter
    );
  }
  f = message.getRemote();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.envoy.config.core.v3.RemoteDataSource.serializeBinaryToWriter
    );
  }
};

/**
 * optional DataSource local = 1;
 * @return {?proto.envoy.config.core.v3.DataSource}
 */
proto.envoy.config.core.v3.AsyncDataSource.prototype.getLocal = function () {
  return /** @type{?proto.envoy.config.core.v3.DataSource} */ (
    jspb.Message.getWrapperField(this, proto.envoy.config.core.v3.DataSource, 1)
  );
};

/**
 * @param {?proto.envoy.config.core.v3.DataSource|undefined} value
 * @return {!proto.envoy.config.core.v3.AsyncDataSource} returns this
 */
proto.envoy.config.core.v3.AsyncDataSource.prototype.setLocal = function (
  value
) {
  return jspb.Message.setOneofWrapperField(
    this,
    1,
    proto.envoy.config.core.v3.AsyncDataSource.oneofGroups_[0],
    value
  );
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.AsyncDataSource} returns this
 */
proto.envoy.config.core.v3.AsyncDataSource.prototype.clearLocal = function () {
  return this.setLocal(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.AsyncDataSource.prototype.hasLocal = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional RemoteDataSource remote = 2;
 * @return {?proto.envoy.config.core.v3.RemoteDataSource}
 */
proto.envoy.config.core.v3.AsyncDataSource.prototype.getRemote = function () {
  return /** @type{?proto.envoy.config.core.v3.RemoteDataSource} */ (
    jspb.Message.getWrapperField(
      this,
      proto.envoy.config.core.v3.RemoteDataSource,
      2
    )
  );
};

/**
 * @param {?proto.envoy.config.core.v3.RemoteDataSource|undefined} value
 * @return {!proto.envoy.config.core.v3.AsyncDataSource} returns this
 */
proto.envoy.config.core.v3.AsyncDataSource.prototype.setRemote = function (
  value
) {
  return jspb.Message.setOneofWrapperField(
    this,
    2,
    proto.envoy.config.core.v3.AsyncDataSource.oneofGroups_[0],
    value
  );
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.AsyncDataSource} returns this
 */
proto.envoy.config.core.v3.AsyncDataSource.prototype.clearRemote = function () {
  return this.setRemote(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.AsyncDataSource.prototype.hasRemote = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.envoy.config.core.v3.TransportSocket.oneofGroups_ = [[3]];

/**
 * @enum {number}
 */
proto.envoy.config.core.v3.TransportSocket.ConfigTypeCase = {
  CONFIG_TYPE_NOT_SET: 0,
  TYPED_CONFIG: 3
};

/**
 * @return {proto.envoy.config.core.v3.TransportSocket.ConfigTypeCase}
 */
proto.envoy.config.core.v3.TransportSocket.prototype.getConfigTypeCase =
  function () {
    return /** @type {proto.envoy.config.core.v3.TransportSocket.ConfigTypeCase} */ (
      jspb.Message.computeOneofCase(
        this,
        proto.envoy.config.core.v3.TransportSocket.oneofGroups_[0]
      )
    );
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.TransportSocket.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.config.core.v3.TransportSocket.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.TransportSocket} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.TransportSocket.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        name: jspb.Message.getFieldWithDefault(msg, 1, ''),
        typedConfig:
          (f = msg.getTypedConfig()) &&
          google_protobuf_any_pb.Any.toObject(includeInstance, f)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.TransportSocket}
 */
proto.envoy.config.core.v3.TransportSocket.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.TransportSocket();
  return proto.envoy.config.core.v3.TransportSocket.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.TransportSocket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.TransportSocket}
 */
proto.envoy.config.core.v3.TransportSocket.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setName(value);
          break;
        case 3:
          var value = new google_protobuf_any_pb.Any();
          reader.readMessage(
            value,
            google_protobuf_any_pb.Any.deserializeBinaryFromReader
          );
          msg.setTypedConfig(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.TransportSocket.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.TransportSocket.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.TransportSocket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.TransportSocket.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getTypedConfig();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_any_pb.Any.serializeBinaryToWriter
    );
  }
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.envoy.config.core.v3.TransportSocket.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.TransportSocket} returns this
 */
proto.envoy.config.core.v3.TransportSocket.prototype.setName = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional google.protobuf.Any typed_config = 3;
 * @return {?proto.google.protobuf.Any}
 */
proto.envoy.config.core.v3.TransportSocket.prototype.getTypedConfig =
  function () {
    return /** @type{?proto.google.protobuf.Any} */ (
      jspb.Message.getWrapperField(this, google_protobuf_any_pb.Any, 3)
    );
  };

/**
 * @param {?proto.google.protobuf.Any|undefined} value
 * @return {!proto.envoy.config.core.v3.TransportSocket} returns this
 */
proto.envoy.config.core.v3.TransportSocket.prototype.setTypedConfig = function (
  value
) {
  return jspb.Message.setOneofWrapperField(
    this,
    3,
    proto.envoy.config.core.v3.TransportSocket.oneofGroups_[0],
    value
  );
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.TransportSocket} returns this
 */
proto.envoy.config.core.v3.TransportSocket.prototype.clearTypedConfig =
  function () {
    return this.setTypedConfig(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.TransportSocket.prototype.hasTypedConfig =
  function () {
    return jspb.Message.getField(this, 3) != null;
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.RuntimeFractionalPercent.prototype.toObject =
    function (opt_includeInstance) {
      return proto.envoy.config.core.v3.RuntimeFractionalPercent.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.RuntimeFractionalPercent} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.RuntimeFractionalPercent.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        defaultValue:
          (f = msg.getDefaultValue()) &&
          envoy_type_v3_percent_pb.FractionalPercent.toObject(
            includeInstance,
            f
          ),
        runtimeKey: jspb.Message.getFieldWithDefault(msg, 2, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.RuntimeFractionalPercent}
 */
proto.envoy.config.core.v3.RuntimeFractionalPercent.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.envoy.config.core.v3.RuntimeFractionalPercent();
    return proto.envoy.config.core.v3.RuntimeFractionalPercent.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.RuntimeFractionalPercent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.RuntimeFractionalPercent}
 */
proto.envoy.config.core.v3.RuntimeFractionalPercent.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new envoy_type_v3_percent_pb.FractionalPercent();
          reader.readMessage(
            value,
            envoy_type_v3_percent_pb.FractionalPercent
              .deserializeBinaryFromReader
          );
          msg.setDefaultValue(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setRuntimeKey(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.RuntimeFractionalPercent.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.RuntimeFractionalPercent.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.RuntimeFractionalPercent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.RuntimeFractionalPercent.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getDefaultValue();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        envoy_type_v3_percent_pb.FractionalPercent.serializeBinaryToWriter
      );
    }
    f = message.getRuntimeKey();
    if (f.length > 0) {
      writer.writeString(2, f);
    }
  };

/**
 * optional envoy.type.v3.FractionalPercent default_value = 1;
 * @return {?proto.envoy.type.v3.FractionalPercent}
 */
proto.envoy.config.core.v3.RuntimeFractionalPercent.prototype.getDefaultValue =
  function () {
    return /** @type{?proto.envoy.type.v3.FractionalPercent} */ (
      jspb.Message.getWrapperField(
        this,
        envoy_type_v3_percent_pb.FractionalPercent,
        1
      )
    );
  };

/**
 * @param {?proto.envoy.type.v3.FractionalPercent|undefined} value
 * @return {!proto.envoy.config.core.v3.RuntimeFractionalPercent} returns this
 */
proto.envoy.config.core.v3.RuntimeFractionalPercent.prototype.setDefaultValue =
  function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.RuntimeFractionalPercent} returns this
 */
proto.envoy.config.core.v3.RuntimeFractionalPercent.prototype.clearDefaultValue =
  function () {
    return this.setDefaultValue(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.RuntimeFractionalPercent.prototype.hasDefaultValue =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

/**
 * optional string runtime_key = 2;
 * @return {string}
 */
proto.envoy.config.core.v3.RuntimeFractionalPercent.prototype.getRuntimeKey =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 2, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.RuntimeFractionalPercent} returns this
 */
proto.envoy.config.core.v3.RuntimeFractionalPercent.prototype.setRuntimeKey =
  function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.ControlPlane.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.config.core.v3.ControlPlane.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.ControlPlane} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.ControlPlane.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        identifier: jspb.Message.getFieldWithDefault(msg, 1, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.ControlPlane}
 */
proto.envoy.config.core.v3.ControlPlane.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.ControlPlane();
  return proto.envoy.config.core.v3.ControlPlane.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.ControlPlane} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.ControlPlane}
 */
proto.envoy.config.core.v3.ControlPlane.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setIdentifier(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.ControlPlane.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.ControlPlane.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.ControlPlane} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.ControlPlane.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getIdentifier();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
};

/**
 * optional string identifier = 1;
 * @return {string}
 */
proto.envoy.config.core.v3.ControlPlane.prototype.getIdentifier = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.ControlPlane} returns this
 */
proto.envoy.config.core.v3.ControlPlane.prototype.setIdentifier = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * @enum {number}
 */
proto.envoy.config.core.v3.RoutingPriority = {
  DEFAULT: 0,
  HIGH: 1
};

/**
 * @enum {number}
 */
proto.envoy.config.core.v3.RequestMethod = {
  METHOD_UNSPECIFIED: 0,
  GET: 1,
  HEAD: 2,
  POST: 3,
  PUT: 4,
  DELETE: 5,
  CONNECT: 6,
  OPTIONS: 7,
  TRACE: 8,
  PATCH: 9
};

/**
 * @enum {number}
 */
proto.envoy.config.core.v3.TrafficDirection = {
  UNSPECIFIED: 0,
  INBOUND: 1,
  OUTBOUND: 2
};

goog.object.extend(exports, proto.envoy.config.core.v3);
