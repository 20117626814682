// source: envoy/config/cluster/v3/circuit_breaker.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var envoy_config_core_v3_base_pb = require('../../../../envoy/config/core/v3/base_pb.js');
goog.object.extend(proto, envoy_config_core_v3_base_pb);
var envoy_type_v3_percent_pb = require('../../../../envoy/type/v3/percent_pb.js');
goog.object.extend(proto, envoy_type_v3_percent_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var udpa_annotations_status_pb = require('../../../../udpa/annotations/status_pb.js');
goog.object.extend(proto, udpa_annotations_status_pb);
var udpa_annotations_versioning_pb = require('../../../../udpa/annotations/versioning_pb.js');
goog.object.extend(proto, udpa_annotations_versioning_pb);
var validate_validate_pb = require('../../../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol(
  'proto.envoy.config.cluster.v3.CircuitBreakers',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.RetryBudget',
  null,
  global
);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.cluster.v3.CircuitBreakers = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.envoy.config.cluster.v3.CircuitBreakers.repeatedFields_,
    null
  );
};
goog.inherits(proto.envoy.config.cluster.v3.CircuitBreakers, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.cluster.v3.CircuitBreakers.displayName =
    'proto.envoy.config.cluster.v3.CircuitBreakers';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(
  proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.displayName =
    'proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.RetryBudget =
  function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  };
goog.inherits(
  proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.RetryBudget,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.RetryBudget.displayName =
    'proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.RetryBudget';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.envoy.config.cluster.v3.CircuitBreakers.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.cluster.v3.CircuitBreakers.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.config.cluster.v3.CircuitBreakers.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.cluster.v3.CircuitBreakers} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.cluster.v3.CircuitBreakers.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        thresholdsList: jspb.Message.toObjectList(
          msg.getThresholdsList(),
          proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.toObject,
          includeInstance
        )
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.cluster.v3.CircuitBreakers}
 */
proto.envoy.config.cluster.v3.CircuitBreakers.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.cluster.v3.CircuitBreakers();
  return proto.envoy.config.cluster.v3.CircuitBreakers.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.cluster.v3.CircuitBreakers} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.cluster.v3.CircuitBreakers}
 */
proto.envoy.config.cluster.v3.CircuitBreakers.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value =
            new proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds();
          reader.readMessage(
            value,
            proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds
              .deserializeBinaryFromReader
          );
          msg.addThresholds(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.cluster.v3.CircuitBreakers.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.cluster.v3.CircuitBreakers.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.cluster.v3.CircuitBreakers} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.cluster.v3.CircuitBreakers.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getThresholdsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds
          .serializeBinaryToWriter
      );
    }
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.prototype.toObject =
    function (opt_includeInstance) {
      return proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        priority: jspb.Message.getFieldWithDefault(msg, 1, 0),
        maxConnections:
          (f = msg.getMaxConnections()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
        maxPendingRequests:
          (f = msg.getMaxPendingRequests()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
        maxRequests:
          (f = msg.getMaxRequests()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
        maxRetries:
          (f = msg.getMaxRetries()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
        retryBudget:
          (f = msg.getRetryBudget()) &&
          proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.RetryBudget.toObject(
            includeInstance,
            f
          ),
        trackRemaining: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
        maxConnectionPools:
          (f = msg.getMaxConnectionPools()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds}
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds();
    return proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds}
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value =
            /** @type {!proto.envoy.config.core.v3.RoutingPriority} */ (
              reader.readEnum()
            );
          msg.setPriority(value);
          break;
        case 2:
          var value = new google_protobuf_wrappers_pb.UInt32Value();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
          );
          msg.setMaxConnections(value);
          break;
        case 3:
          var value = new google_protobuf_wrappers_pb.UInt32Value();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
          );
          msg.setMaxPendingRequests(value);
          break;
        case 4:
          var value = new google_protobuf_wrappers_pb.UInt32Value();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
          );
          msg.setMaxRequests(value);
          break;
        case 5:
          var value = new google_protobuf_wrappers_pb.UInt32Value();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
          );
          msg.setMaxRetries(value);
          break;
        case 8:
          var value =
            new proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.RetryBudget();
          reader.readMessage(
            value,
            proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.RetryBudget
              .deserializeBinaryFromReader
          );
          msg.setRetryBudget(value);
          break;
        case 6:
          var value = /** @type {boolean} */ (reader.readBool());
          msg.setTrackRemaining(value);
          break;
        case 7:
          var value = new google_protobuf_wrappers_pb.UInt32Value();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
          );
          msg.setMaxConnectionPools(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getPriority();
    if (f !== 0.0) {
      writer.writeEnum(1, f);
    }
    f = message.getMaxConnections();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
      );
    }
    f = message.getMaxPendingRequests();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
      );
    }
    f = message.getMaxRequests();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
      );
    }
    f = message.getMaxRetries();
    if (f != null) {
      writer.writeMessage(
        5,
        f,
        google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
      );
    }
    f = message.getRetryBudget();
    if (f != null) {
      writer.writeMessage(
        8,
        f,
        proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.RetryBudget
          .serializeBinaryToWriter
      );
    }
    f = message.getTrackRemaining();
    if (f) {
      writer.writeBool(6, f);
    }
    f = message.getMaxConnectionPools();
    if (f != null) {
      writer.writeMessage(
        7,
        f,
        google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
      );
    }
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.RetryBudget.prototype.toObject =
    function (opt_includeInstance) {
      return proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.RetryBudget.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.RetryBudget} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.RetryBudget.toObject =
    function (includeInstance, msg) {
      var f,
        obj = {
          budgetPercent:
            (f = msg.getBudgetPercent()) &&
            envoy_type_v3_percent_pb.Percent.toObject(includeInstance, f),
          minRetryConcurrency:
            (f = msg.getMinRetryConcurrency()) &&
            google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f)
        };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }
      return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.RetryBudget}
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.RetryBudget.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg =
      new proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.RetryBudget();
    return proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.RetryBudget.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.RetryBudget} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.RetryBudget}
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.RetryBudget.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new envoy_type_v3_percent_pb.Percent();
          reader.readMessage(
            value,
            envoy_type_v3_percent_pb.Percent.deserializeBinaryFromReader
          );
          msg.setBudgetPercent(value);
          break;
        case 2:
          var value = new google_protobuf_wrappers_pb.UInt32Value();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
          );
          msg.setMinRetryConcurrency(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.RetryBudget.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.RetryBudget.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.RetryBudget} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.RetryBudget.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getBudgetPercent();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        envoy_type_v3_percent_pb.Percent.serializeBinaryToWriter
      );
    }
    f = message.getMinRetryConcurrency();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
      );
    }
  };

/**
 * optional envoy.type.v3.Percent budget_percent = 1;
 * @return {?proto.envoy.type.v3.Percent}
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.RetryBudget.prototype.getBudgetPercent =
  function () {
    return /** @type{?proto.envoy.type.v3.Percent} */ (
      jspb.Message.getWrapperField(this, envoy_type_v3_percent_pb.Percent, 1)
    );
  };

/**
 * @param {?proto.envoy.type.v3.Percent|undefined} value
 * @return {!proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.RetryBudget} returns this
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.RetryBudget.prototype.setBudgetPercent =
  function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.RetryBudget} returns this
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.RetryBudget.prototype.clearBudgetPercent =
  function () {
    return this.setBudgetPercent(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.RetryBudget.prototype.hasBudgetPercent =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

/**
 * optional google.protobuf.UInt32Value min_retry_concurrency = 2;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.RetryBudget.prototype.getMinRetryConcurrency =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        2
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.RetryBudget} returns this
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.RetryBudget.prototype.setMinRetryConcurrency =
  function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.RetryBudget} returns this
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.RetryBudget.prototype.clearMinRetryConcurrency =
  function () {
    return this.setMinRetryConcurrency(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.RetryBudget.prototype.hasMinRetryConcurrency =
  function () {
    return jspb.Message.getField(this, 2) != null;
  };

/**
 * optional envoy.config.core.v3.RoutingPriority priority = 1;
 * @return {!proto.envoy.config.core.v3.RoutingPriority}
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.prototype.getPriority =
  function () {
    return /** @type {!proto.envoy.config.core.v3.RoutingPriority} */ (
      jspb.Message.getFieldWithDefault(this, 1, 0)
    );
  };

/**
 * @param {!proto.envoy.config.core.v3.RoutingPriority} value
 * @return {!proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds} returns this
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.prototype.setPriority =
  function (value) {
    return jspb.Message.setProto3EnumField(this, 1, value);
  };

/**
 * optional google.protobuf.UInt32Value max_connections = 2;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.prototype.getMaxConnections =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        2
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds} returns this
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.prototype.setMaxConnections =
  function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds} returns this
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.prototype.clearMaxConnections =
  function () {
    return this.setMaxConnections(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.prototype.hasMaxConnections =
  function () {
    return jspb.Message.getField(this, 2) != null;
  };

/**
 * optional google.protobuf.UInt32Value max_pending_requests = 3;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.prototype.getMaxPendingRequests =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        3
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds} returns this
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.prototype.setMaxPendingRequests =
  function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds} returns this
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.prototype.clearMaxPendingRequests =
  function () {
    return this.setMaxPendingRequests(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.prototype.hasMaxPendingRequests =
  function () {
    return jspb.Message.getField(this, 3) != null;
  };

/**
 * optional google.protobuf.UInt32Value max_requests = 4;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.prototype.getMaxRequests =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        4
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds} returns this
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.prototype.setMaxRequests =
  function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds} returns this
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.prototype.clearMaxRequests =
  function () {
    return this.setMaxRequests(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.prototype.hasMaxRequests =
  function () {
    return jspb.Message.getField(this, 4) != null;
  };

/**
 * optional google.protobuf.UInt32Value max_retries = 5;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.prototype.getMaxRetries =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        5
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds} returns this
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.prototype.setMaxRetries =
  function (value) {
    return jspb.Message.setWrapperField(this, 5, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds} returns this
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.prototype.clearMaxRetries =
  function () {
    return this.setMaxRetries(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.prototype.hasMaxRetries =
  function () {
    return jspb.Message.getField(this, 5) != null;
  };

/**
 * optional RetryBudget retry_budget = 8;
 * @return {?proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.RetryBudget}
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.prototype.getRetryBudget =
  function () {
    return /** @type{?proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.RetryBudget} */ (
      jspb.Message.getWrapperField(
        this,
        proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.RetryBudget,
        8
      )
    );
  };

/**
 * @param {?proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.RetryBudget|undefined} value
 * @return {!proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds} returns this
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.prototype.setRetryBudget =
  function (value) {
    return jspb.Message.setWrapperField(this, 8, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds} returns this
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.prototype.clearRetryBudget =
  function () {
    return this.setRetryBudget(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.prototype.hasRetryBudget =
  function () {
    return jspb.Message.getField(this, 8) != null;
  };

/**
 * optional bool track_remaining = 6;
 * @return {boolean}
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.prototype.getTrackRemaining =
  function () {
    return /** @type {boolean} */ (
      jspb.Message.getBooleanFieldWithDefault(this, 6, false)
    );
  };

/**
 * @param {boolean} value
 * @return {!proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds} returns this
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.prototype.setTrackRemaining =
  function (value) {
    return jspb.Message.setProto3BooleanField(this, 6, value);
  };

/**
 * optional google.protobuf.UInt32Value max_connection_pools = 7;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.prototype.getMaxConnectionPools =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        7
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds} returns this
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.prototype.setMaxConnectionPools =
  function (value) {
    return jspb.Message.setWrapperField(this, 7, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds} returns this
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.prototype.clearMaxConnectionPools =
  function () {
    return this.setMaxConnectionPools(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds.prototype.hasMaxConnectionPools =
  function () {
    return jspb.Message.getField(this, 7) != null;
  };

/**
 * repeated Thresholds thresholds = 1;
 * @return {!Array<!proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds>}
 */
proto.envoy.config.cluster.v3.CircuitBreakers.prototype.getThresholdsList =
  function () {
    return /** @type{!Array<!proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds>} */ (
      jspb.Message.getRepeatedWrapperField(
        this,
        proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds,
        1
      )
    );
  };

/**
 * @param {!Array<!proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds>} value
 * @return {!proto.envoy.config.cluster.v3.CircuitBreakers} returns this
 */
proto.envoy.config.cluster.v3.CircuitBreakers.prototype.setThresholdsList =
  function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
  };

/**
 * @param {!proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds=} opt_value
 * @param {number=} opt_index
 * @return {!proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds}
 */
proto.envoy.config.cluster.v3.CircuitBreakers.prototype.addThresholds =
  function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(
      this,
      1,
      opt_value,
      proto.envoy.config.cluster.v3.CircuitBreakers.Thresholds,
      opt_index
    );
  };

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.envoy.config.cluster.v3.CircuitBreakers} returns this
 */
proto.envoy.config.cluster.v3.CircuitBreakers.prototype.clearThresholdsList =
  function () {
    return this.setThresholdsList([]);
  };

goog.object.extend(exports, proto.envoy.config.cluster.v3);
