export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK'
};
export const TABLE_ROW_COUNT_OPTIONS = [5, 10, 25, 50];

export const APP_VERSION = '0.0.1';
export const API_BASE_URL = '/api';
export const ENABLE_REDUX_LOGGER = false;
export const ENABLE_REDUX_DEV_TOOLS = true;
