// source: namespaces.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol(
  'proto.pomerium.dashboard.DeleteNamespacePermissionRequest',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.DeleteNamespacePermissionResponse',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.DeleteNamespaceRequest',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.DeleteNamespaceResponse',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.GetNamespacePermissionRequest',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.GetNamespacePermissionResponse',
  null,
  global
);
goog.exportSymbol('proto.pomerium.dashboard.GetNamespaceRequest', null, global);
goog.exportSymbol(
  'proto.pomerium.dashboard.GetNamespaceResponse',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.ListNamespacePermissionGroupsRequest',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.ListNamespacePermissionGroupsResponse',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.ListNamespacePermissionUsersRequest',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.ListNamespacePermissionUsersResponse',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.ListNamespacePermissionsRequest',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.ListNamespacePermissionsResponse',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.ListNamespacesRequest',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.ListNamespacesResponse',
  null,
  global
);
goog.exportSymbol('proto.pomerium.dashboard.Namespace', null, global);
goog.exportSymbol('proto.pomerium.dashboard.NamespacePermission', null, global);
goog.exportSymbol(
  'proto.pomerium.dashboard.NamespacePermissionGroup',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.NamespacePermissionUser',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.SetNamespacePermissionRequest',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.SetNamespacePermissionResponse',
  null,
  global
);
goog.exportSymbol('proto.pomerium.dashboard.SetNamespaceRequest', null, global);
goog.exportSymbol(
  'proto.pomerium.dashboard.SetNamespaceResponse',
  null,
  global
);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.Namespace = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.Namespace, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.Namespace.displayName =
    'proto.pomerium.dashboard.Namespace';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.DeleteNamespaceRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.DeleteNamespaceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.DeleteNamespaceRequest.displayName =
    'proto.pomerium.dashboard.DeleteNamespaceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.DeleteNamespaceResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.DeleteNamespaceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.DeleteNamespaceResponse.displayName =
    'proto.pomerium.dashboard.DeleteNamespaceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.GetNamespaceRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.GetNamespaceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.GetNamespaceRequest.displayName =
    'proto.pomerium.dashboard.GetNamespaceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.GetNamespaceResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.GetNamespaceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.GetNamespaceResponse.displayName =
    'proto.pomerium.dashboard.GetNamespaceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.ListNamespacesRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.ListNamespacesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.ListNamespacesRequest.displayName =
    'proto.pomerium.dashboard.ListNamespacesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.ListNamespacesResponse = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.pomerium.dashboard.ListNamespacesResponse.repeatedFields_,
    null
  );
};
goog.inherits(proto.pomerium.dashboard.ListNamespacesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.ListNamespacesResponse.displayName =
    'proto.pomerium.dashboard.ListNamespacesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.SetNamespaceRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.SetNamespaceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.SetNamespaceRequest.displayName =
    'proto.pomerium.dashboard.SetNamespaceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.SetNamespaceResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.SetNamespaceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.SetNamespaceResponse.displayName =
    'proto.pomerium.dashboard.SetNamespaceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.NamespacePermission = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.NamespacePermission, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.NamespacePermission.displayName =
    'proto.pomerium.dashboard.NamespacePermission';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.NamespacePermissionGroup = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.NamespacePermissionGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.NamespacePermissionGroup.displayName =
    'proto.pomerium.dashboard.NamespacePermissionGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.NamespacePermissionUser = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.pomerium.dashboard.NamespacePermissionUser.repeatedFields_,
    null
  );
};
goog.inherits(proto.pomerium.dashboard.NamespacePermissionUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.NamespacePermissionUser.displayName =
    'proto.pomerium.dashboard.NamespacePermissionUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.DeleteNamespacePermissionRequest = function (
  opt_data
) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(
  proto.pomerium.dashboard.DeleteNamespacePermissionRequest,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.DeleteNamespacePermissionRequest.displayName =
    'proto.pomerium.dashboard.DeleteNamespacePermissionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.DeleteNamespacePermissionResponse = function (
  opt_data
) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(
  proto.pomerium.dashboard.DeleteNamespacePermissionResponse,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.DeleteNamespacePermissionResponse.displayName =
    'proto.pomerium.dashboard.DeleteNamespacePermissionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.GetNamespacePermissionRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(
  proto.pomerium.dashboard.GetNamespacePermissionRequest,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.GetNamespacePermissionRequest.displayName =
    'proto.pomerium.dashboard.GetNamespacePermissionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.GetNamespacePermissionResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(
  proto.pomerium.dashboard.GetNamespacePermissionResponse,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.GetNamespacePermissionResponse.displayName =
    'proto.pomerium.dashboard.GetNamespacePermissionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.ListNamespacePermissionsRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(
  proto.pomerium.dashboard.ListNamespacePermissionsRequest,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.ListNamespacePermissionsRequest.displayName =
    'proto.pomerium.dashboard.ListNamespacePermissionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.ListNamespacePermissionsResponse = function (
  opt_data
) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.pomerium.dashboard.ListNamespacePermissionsResponse.repeatedFields_,
    null
  );
};
goog.inherits(
  proto.pomerium.dashboard.ListNamespacePermissionsResponse,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.ListNamespacePermissionsResponse.displayName =
    'proto.pomerium.dashboard.ListNamespacePermissionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.ListNamespacePermissionGroupsRequest = function (
  opt_data
) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(
  proto.pomerium.dashboard.ListNamespacePermissionGroupsRequest,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.ListNamespacePermissionGroupsRequest.displayName =
    'proto.pomerium.dashboard.ListNamespacePermissionGroupsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.ListNamespacePermissionGroupsResponse = function (
  opt_data
) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.pomerium.dashboard.ListNamespacePermissionGroupsResponse
      .repeatedFields_,
    null
  );
};
goog.inherits(
  proto.pomerium.dashboard.ListNamespacePermissionGroupsResponse,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.ListNamespacePermissionGroupsResponse.displayName =
    'proto.pomerium.dashboard.ListNamespacePermissionGroupsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.ListNamespacePermissionUsersRequest = function (
  opt_data
) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(
  proto.pomerium.dashboard.ListNamespacePermissionUsersRequest,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.ListNamespacePermissionUsersRequest.displayName =
    'proto.pomerium.dashboard.ListNamespacePermissionUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.ListNamespacePermissionUsersResponse = function (
  opt_data
) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.pomerium.dashboard.ListNamespacePermissionUsersResponse
      .repeatedFields_,
    null
  );
};
goog.inherits(
  proto.pomerium.dashboard.ListNamespacePermissionUsersResponse,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.ListNamespacePermissionUsersResponse.displayName =
    'proto.pomerium.dashboard.ListNamespacePermissionUsersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.SetNamespacePermissionRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(
  proto.pomerium.dashboard.SetNamespacePermissionRequest,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.SetNamespacePermissionRequest.displayName =
    'proto.pomerium.dashboard.SetNamespacePermissionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.SetNamespacePermissionResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(
  proto.pomerium.dashboard.SetNamespacePermissionResponse,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.SetNamespacePermissionResponse.displayName =
    'proto.pomerium.dashboard.SetNamespacePermissionResponse';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.Namespace.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.Namespace.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.Namespace} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.Namespace.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, ''),
        parentId: jspb.Message.getFieldWithDefault(msg, 2, ''),
        createdAt:
          (f = msg.getCreatedAt()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        modifiedAt:
          (f = msg.getModifiedAt()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        deletedAt:
          (f = msg.getDeletedAt()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        name: jspb.Message.getFieldWithDefault(msg, 6, ''),
        routeCount: jspb.Message.getFieldWithDefault(msg, 7, 0),
        policyCount: jspb.Message.getFieldWithDefault(msg, 8, 0)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.Namespace}
 */
proto.pomerium.dashboard.Namespace.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.Namespace();
  return proto.pomerium.dashboard.Namespace.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.Namespace} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.Namespace}
 */
proto.pomerium.dashboard.Namespace.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setParentId(value);
        break;
      case 3:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        );
        msg.setCreatedAt(value);
        break;
      case 4:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        );
        msg.setModifiedAt(value);
        break;
      case 5:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        );
        msg.setDeletedAt(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 7:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setRouteCount(value);
        break;
      case 8:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setPolicyCount(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.Namespace.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.pomerium.dashboard.Namespace.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.Namespace} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.Namespace.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getParentId();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getModifiedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeletedAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(6, f);
  }
  f = message.getRouteCount();
  if (f !== 0) {
    writer.writeInt64(7, f);
  }
  f = message.getPolicyCount();
  if (f !== 0) {
    writer.writeInt64(8, f);
  }
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.Namespace.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Namespace} returns this
 */
proto.pomerium.dashboard.Namespace.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string parent_id = 2;
 * @return {string}
 */
proto.pomerium.dashboard.Namespace.prototype.getParentId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Namespace} returns this
 */
proto.pomerium.dashboard.Namespace.prototype.setParentId = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional google.protobuf.Timestamp created_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pomerium.dashboard.Namespace.prototype.getCreatedAt = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_timestamp_pb.Timestamp,
      3
    )
  );
};

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.pomerium.dashboard.Namespace} returns this
 */
proto.pomerium.dashboard.Namespace.prototype.setCreatedAt = function (value) {
  return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.Namespace} returns this
 */
proto.pomerium.dashboard.Namespace.prototype.clearCreatedAt = function () {
  return this.setCreatedAt(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Namespace.prototype.hasCreatedAt = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional google.protobuf.Timestamp modified_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pomerium.dashboard.Namespace.prototype.getModifiedAt = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_timestamp_pb.Timestamp,
      4
    )
  );
};

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.pomerium.dashboard.Namespace} returns this
 */
proto.pomerium.dashboard.Namespace.prototype.setModifiedAt = function (value) {
  return jspb.Message.setWrapperField(this, 4, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.Namespace} returns this
 */
proto.pomerium.dashboard.Namespace.prototype.clearModifiedAt = function () {
  return this.setModifiedAt(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Namespace.prototype.hasModifiedAt = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional google.protobuf.Timestamp deleted_at = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pomerium.dashboard.Namespace.prototype.getDeletedAt = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_timestamp_pb.Timestamp,
      5
    )
  );
};

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.pomerium.dashboard.Namespace} returns this
 */
proto.pomerium.dashboard.Namespace.prototype.setDeletedAt = function (value) {
  return jspb.Message.setWrapperField(this, 5, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.Namespace} returns this
 */
proto.pomerium.dashboard.Namespace.prototype.clearDeletedAt = function () {
  return this.setDeletedAt(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Namespace.prototype.hasDeletedAt = function () {
  return jspb.Message.getField(this, 5) != null;
};

/**
 * optional string name = 6;
 * @return {string}
 */
proto.pomerium.dashboard.Namespace.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Namespace} returns this
 */
proto.pomerium.dashboard.Namespace.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};

/**
 * optional int64 route_count = 7;
 * @return {number}
 */
proto.pomerium.dashboard.Namespace.prototype.getRouteCount = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};

/**
 * @param {number} value
 * @return {!proto.pomerium.dashboard.Namespace} returns this
 */
proto.pomerium.dashboard.Namespace.prototype.setRouteCount = function (value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};

/**
 * optional int64 policy_count = 8;
 * @return {number}
 */
proto.pomerium.dashboard.Namespace.prototype.getPolicyCount = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};

/**
 * @param {number} value
 * @return {!proto.pomerium.dashboard.Namespace} returns this
 */
proto.pomerium.dashboard.Namespace.prototype.setPolicyCount = function (value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.DeleteNamespaceRequest.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.DeleteNamespaceRequest.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.DeleteNamespaceRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.DeleteNamespaceRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.DeleteNamespaceRequest}
 */
proto.pomerium.dashboard.DeleteNamespaceRequest.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.DeleteNamespaceRequest();
  return proto.pomerium.dashboard.DeleteNamespaceRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.DeleteNamespaceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.DeleteNamespaceRequest}
 */
proto.pomerium.dashboard.DeleteNamespaceRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setId(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.DeleteNamespaceRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.DeleteNamespaceRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.DeleteNamespaceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.DeleteNamespaceRequest.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getId();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
  };

/**
 * optional string id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.DeleteNamespaceRequest.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.DeleteNamespaceRequest} returns this
 */
proto.pomerium.dashboard.DeleteNamespaceRequest.prototype.setId = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.DeleteNamespaceResponse.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.DeleteNamespaceResponse.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.DeleteNamespaceResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.DeleteNamespaceResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {};

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.DeleteNamespaceResponse}
 */
proto.pomerium.dashboard.DeleteNamespaceResponse.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.DeleteNamespaceResponse();
  return proto.pomerium.dashboard.DeleteNamespaceResponse.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.DeleteNamespaceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.DeleteNamespaceResponse}
 */
proto.pomerium.dashboard.DeleteNamespaceResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.DeleteNamespaceResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.DeleteNamespaceResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.DeleteNamespaceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.DeleteNamespaceResponse.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.GetNamespaceRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.GetNamespaceRequest.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.GetNamespaceRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.GetNamespaceRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.GetNamespaceRequest}
 */
proto.pomerium.dashboard.GetNamespaceRequest.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.GetNamespaceRequest();
  return proto.pomerium.dashboard.GetNamespaceRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.GetNamespaceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.GetNamespaceRequest}
 */
proto.pomerium.dashboard.GetNamespaceRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setId(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.GetNamespaceRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.GetNamespaceRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.GetNamespaceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.GetNamespaceRequest.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getId();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
  };

/**
 * optional string id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.GetNamespaceRequest.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.GetNamespaceRequest} returns this
 */
proto.pomerium.dashboard.GetNamespaceRequest.prototype.setId = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.GetNamespaceResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.GetNamespaceResponse.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.GetNamespaceResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.GetNamespaceResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        namespace:
          (f = msg.getNamespace()) &&
          proto.pomerium.dashboard.Namespace.toObject(includeInstance, f)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.GetNamespaceResponse}
 */
proto.pomerium.dashboard.GetNamespaceResponse.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.GetNamespaceResponse();
  return proto.pomerium.dashboard.GetNamespaceResponse.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.GetNamespaceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.GetNamespaceResponse}
 */
proto.pomerium.dashboard.GetNamespaceResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.Namespace();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.Namespace.deserializeBinaryFromReader
          );
          msg.setNamespace(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.GetNamespaceResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.GetNamespaceResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.GetNamespaceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.GetNamespaceResponse.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getNamespace();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        proto.pomerium.dashboard.Namespace.serializeBinaryToWriter
      );
    }
  };

/**
 * optional Namespace namespace = 1;
 * @return {?proto.pomerium.dashboard.Namespace}
 */
proto.pomerium.dashboard.GetNamespaceResponse.prototype.getNamespace =
  function () {
    return /** @type{?proto.pomerium.dashboard.Namespace} */ (
      jspb.Message.getWrapperField(this, proto.pomerium.dashboard.Namespace, 1)
    );
  };

/**
 * @param {?proto.pomerium.dashboard.Namespace|undefined} value
 * @return {!proto.pomerium.dashboard.GetNamespaceResponse} returns this
 */
proto.pomerium.dashboard.GetNamespaceResponse.prototype.setNamespace =
  function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.GetNamespaceResponse} returns this
 */
proto.pomerium.dashboard.GetNamespaceResponse.prototype.clearNamespace =
  function () {
    return this.setNamespace(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.GetNamespaceResponse.prototype.hasNamespace =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.ListNamespacesRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.ListNamespacesRequest.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.ListNamespacesRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.ListNamespacesRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {};

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.ListNamespacesRequest}
 */
proto.pomerium.dashboard.ListNamespacesRequest.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.ListNamespacesRequest();
  return proto.pomerium.dashboard.ListNamespacesRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.ListNamespacesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.ListNamespacesRequest}
 */
proto.pomerium.dashboard.ListNamespacesRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.ListNamespacesRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.ListNamespacesRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.ListNamespacesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.ListNamespacesRequest.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
  };

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pomerium.dashboard.ListNamespacesResponse.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.ListNamespacesResponse.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.ListNamespacesResponse.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.ListNamespacesResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.ListNamespacesResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        namespacesList: jspb.Message.toObjectList(
          msg.getNamespacesList(),
          proto.pomerium.dashboard.Namespace.toObject,
          includeInstance
        )
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.ListNamespacesResponse}
 */
proto.pomerium.dashboard.ListNamespacesResponse.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.ListNamespacesResponse();
  return proto.pomerium.dashboard.ListNamespacesResponse.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.ListNamespacesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.ListNamespacesResponse}
 */
proto.pomerium.dashboard.ListNamespacesResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.Namespace();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.Namespace.deserializeBinaryFromReader
          );
          msg.addNamespaces(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.ListNamespacesResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.ListNamespacesResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.ListNamespacesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.ListNamespacesResponse.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getNamespacesList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        proto.pomerium.dashboard.Namespace.serializeBinaryToWriter
      );
    }
  };

/**
 * repeated Namespace namespaces = 1;
 * @return {!Array<!proto.pomerium.dashboard.Namespace>}
 */
proto.pomerium.dashboard.ListNamespacesResponse.prototype.getNamespacesList =
  function () {
    return /** @type{!Array<!proto.pomerium.dashboard.Namespace>} */ (
      jspb.Message.getRepeatedWrapperField(
        this,
        proto.pomerium.dashboard.Namespace,
        1
      )
    );
  };

/**
 * @param {!Array<!proto.pomerium.dashboard.Namespace>} value
 * @return {!proto.pomerium.dashboard.ListNamespacesResponse} returns this
 */
proto.pomerium.dashboard.ListNamespacesResponse.prototype.setNamespacesList =
  function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
  };

/**
 * @param {!proto.pomerium.dashboard.Namespace=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.Namespace}
 */
proto.pomerium.dashboard.ListNamespacesResponse.prototype.addNamespaces =
  function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(
      this,
      1,
      opt_value,
      proto.pomerium.dashboard.Namespace,
      opt_index
    );
  };

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.ListNamespacesResponse} returns this
 */
proto.pomerium.dashboard.ListNamespacesResponse.prototype.clearNamespacesList =
  function () {
    return this.setNamespacesList([]);
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.SetNamespaceRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.SetNamespaceRequest.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.SetNamespaceRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.SetNamespaceRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        namespace:
          (f = msg.getNamespace()) &&
          proto.pomerium.dashboard.Namespace.toObject(includeInstance, f)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.SetNamespaceRequest}
 */
proto.pomerium.dashboard.SetNamespaceRequest.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.SetNamespaceRequest();
  return proto.pomerium.dashboard.SetNamespaceRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.SetNamespaceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.SetNamespaceRequest}
 */
proto.pomerium.dashboard.SetNamespaceRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.Namespace();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.Namespace.deserializeBinaryFromReader
          );
          msg.setNamespace(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.SetNamespaceRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.SetNamespaceRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.SetNamespaceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.SetNamespaceRequest.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getNamespace();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        proto.pomerium.dashboard.Namespace.serializeBinaryToWriter
      );
    }
  };

/**
 * optional Namespace namespace = 1;
 * @return {?proto.pomerium.dashboard.Namespace}
 */
proto.pomerium.dashboard.SetNamespaceRequest.prototype.getNamespace =
  function () {
    return /** @type{?proto.pomerium.dashboard.Namespace} */ (
      jspb.Message.getWrapperField(this, proto.pomerium.dashboard.Namespace, 1)
    );
  };

/**
 * @param {?proto.pomerium.dashboard.Namespace|undefined} value
 * @return {!proto.pomerium.dashboard.SetNamespaceRequest} returns this
 */
proto.pomerium.dashboard.SetNamespaceRequest.prototype.setNamespace = function (
  value
) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.SetNamespaceRequest} returns this
 */
proto.pomerium.dashboard.SetNamespaceRequest.prototype.clearNamespace =
  function () {
    return this.setNamespace(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.SetNamespaceRequest.prototype.hasNamespace =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.SetNamespaceResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.SetNamespaceResponse.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.SetNamespaceResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.SetNamespaceResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        namespace:
          (f = msg.getNamespace()) &&
          proto.pomerium.dashboard.Namespace.toObject(includeInstance, f)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.SetNamespaceResponse}
 */
proto.pomerium.dashboard.SetNamespaceResponse.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.SetNamespaceResponse();
  return proto.pomerium.dashboard.SetNamespaceResponse.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.SetNamespaceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.SetNamespaceResponse}
 */
proto.pomerium.dashboard.SetNamespaceResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.Namespace();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.Namespace.deserializeBinaryFromReader
          );
          msg.setNamespace(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.SetNamespaceResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.SetNamespaceResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.SetNamespaceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.SetNamespaceResponse.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getNamespace();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        proto.pomerium.dashboard.Namespace.serializeBinaryToWriter
      );
    }
  };

/**
 * optional Namespace namespace = 1;
 * @return {?proto.pomerium.dashboard.Namespace}
 */
proto.pomerium.dashboard.SetNamespaceResponse.prototype.getNamespace =
  function () {
    return /** @type{?proto.pomerium.dashboard.Namespace} */ (
      jspb.Message.getWrapperField(this, proto.pomerium.dashboard.Namespace, 1)
    );
  };

/**
 * @param {?proto.pomerium.dashboard.Namespace|undefined} value
 * @return {!proto.pomerium.dashboard.SetNamespaceResponse} returns this
 */
proto.pomerium.dashboard.SetNamespaceResponse.prototype.setNamespace =
  function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.SetNamespaceResponse} returns this
 */
proto.pomerium.dashboard.SetNamespaceResponse.prototype.clearNamespace =
  function () {
    return this.setNamespace(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.SetNamespaceResponse.prototype.hasNamespace =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.NamespacePermission.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.NamespacePermission.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.NamespacePermission} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.NamespacePermission.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, ''),
        createdAt:
          (f = msg.getCreatedAt()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        modifiedAt:
          (f = msg.getModifiedAt()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        namespaceId: jspb.Message.getFieldWithDefault(msg, 4, ''),
        namespaceName: jspb.Message.getFieldWithDefault(msg, 8, ''),
        subjectType: jspb.Message.getFieldWithDefault(msg, 5, ''),
        subjectId: jspb.Message.getFieldWithDefault(msg, 6, ''),
        role: jspb.Message.getFieldWithDefault(msg, 7, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.NamespacePermission}
 */
proto.pomerium.dashboard.NamespacePermission.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.NamespacePermission();
  return proto.pomerium.dashboard.NamespacePermission.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.NamespacePermission} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.NamespacePermission}
 */
proto.pomerium.dashboard.NamespacePermission.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setId(value);
          break;
        case 2:
          var value = new google_protobuf_timestamp_pb.Timestamp();
          reader.readMessage(
            value,
            google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
          );
          msg.setCreatedAt(value);
          break;
        case 3:
          var value = new google_protobuf_timestamp_pb.Timestamp();
          reader.readMessage(
            value,
            google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
          );
          msg.setModifiedAt(value);
          break;
        case 4:
          var value = /** @type {string} */ (reader.readString());
          msg.setNamespaceId(value);
          break;
        case 8:
          var value = /** @type {string} */ (reader.readString());
          msg.setNamespaceName(value);
          break;
        case 5:
          var value = /** @type {string} */ (reader.readString());
          msg.setSubjectType(value);
          break;
        case 6:
          var value = /** @type {string} */ (reader.readString());
          msg.setSubjectId(value);
          break;
        case 7:
          var value = /** @type {string} */ (reader.readString());
          msg.setRole(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.NamespacePermission.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.NamespacePermission.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.NamespacePermission} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.NamespacePermission.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getId();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
    f = message.getCreatedAt();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
      );
    }
    f = message.getModifiedAt();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
      );
    }
    f = message.getNamespaceId();
    if (f.length > 0) {
      writer.writeString(4, f);
    }
    f = message.getNamespaceName();
    if (f.length > 0) {
      writer.writeString(8, f);
    }
    f = message.getSubjectType();
    if (f.length > 0) {
      writer.writeString(5, f);
    }
    f = message.getSubjectId();
    if (f.length > 0) {
      writer.writeString(6, f);
    }
    f = message.getRole();
    if (f.length > 0) {
      writer.writeString(7, f);
    }
  };

/**
 * optional string id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.NamespacePermission.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.NamespacePermission} returns this
 */
proto.pomerium.dashboard.NamespacePermission.prototype.setId = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional google.protobuf.Timestamp created_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pomerium.dashboard.NamespacePermission.prototype.getCreatedAt =
  function () {
    return /** @type{?proto.google.protobuf.Timestamp} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_timestamp_pb.Timestamp,
        2
      )
    );
  };

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.pomerium.dashboard.NamespacePermission} returns this
 */
proto.pomerium.dashboard.NamespacePermission.prototype.setCreatedAt = function (
  value
) {
  return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.NamespacePermission} returns this
 */
proto.pomerium.dashboard.NamespacePermission.prototype.clearCreatedAt =
  function () {
    return this.setCreatedAt(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.NamespacePermission.prototype.hasCreatedAt =
  function () {
    return jspb.Message.getField(this, 2) != null;
  };

/**
 * optional google.protobuf.Timestamp modified_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pomerium.dashboard.NamespacePermission.prototype.getModifiedAt =
  function () {
    return /** @type{?proto.google.protobuf.Timestamp} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_timestamp_pb.Timestamp,
        3
      )
    );
  };

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.pomerium.dashboard.NamespacePermission} returns this
 */
proto.pomerium.dashboard.NamespacePermission.prototype.setModifiedAt =
  function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.NamespacePermission} returns this
 */
proto.pomerium.dashboard.NamespacePermission.prototype.clearModifiedAt =
  function () {
    return this.setModifiedAt(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.NamespacePermission.prototype.hasModifiedAt =
  function () {
    return jspb.Message.getField(this, 3) != null;
  };

/**
 * optional string namespace_id = 4;
 * @return {string}
 */
proto.pomerium.dashboard.NamespacePermission.prototype.getNamespaceId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 4, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.NamespacePermission} returns this
 */
proto.pomerium.dashboard.NamespacePermission.prototype.setNamespaceId =
  function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
  };

/**
 * optional string namespace_name = 8;
 * @return {string}
 */
proto.pomerium.dashboard.NamespacePermission.prototype.getNamespaceName =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 8, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.NamespacePermission} returns this
 */
proto.pomerium.dashboard.NamespacePermission.prototype.setNamespaceName =
  function (value) {
    return jspb.Message.setProto3StringField(this, 8, value);
  };

/**
 * optional string subject_type = 5;
 * @return {string}
 */
proto.pomerium.dashboard.NamespacePermission.prototype.getSubjectType =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 5, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.NamespacePermission} returns this
 */
proto.pomerium.dashboard.NamespacePermission.prototype.setSubjectType =
  function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
  };

/**
 * optional string subject_id = 6;
 * @return {string}
 */
proto.pomerium.dashboard.NamespacePermission.prototype.getSubjectId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 6, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.NamespacePermission} returns this
 */
proto.pomerium.dashboard.NamespacePermission.prototype.setSubjectId = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 6, value);
};

/**
 * optional string role = 7;
 * @return {string}
 */
proto.pomerium.dashboard.NamespacePermission.prototype.getRole = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.NamespacePermission} returns this
 */
proto.pomerium.dashboard.NamespacePermission.prototype.setRole = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 7, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.NamespacePermissionGroup.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.NamespacePermissionGroup.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.NamespacePermissionGroup} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.NamespacePermissionGroup.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        groupId: jspb.Message.getFieldWithDefault(msg, 1, ''),
        groupName: jspb.Message.getFieldWithDefault(msg, 2, ''),
        groupEmail: jspb.Message.getFieldWithDefault(msg, 3, ''),
        namespaceId: jspb.Message.getFieldWithDefault(msg, 4, ''),
        namespaceName: jspb.Message.getFieldWithDefault(msg, 5, ''),
        role: jspb.Message.getFieldWithDefault(msg, 6, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.NamespacePermissionGroup}
 */
proto.pomerium.dashboard.NamespacePermissionGroup.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.NamespacePermissionGroup();
  return proto.pomerium.dashboard.NamespacePermissionGroup.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.NamespacePermissionGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.NamespacePermissionGroup}
 */
proto.pomerium.dashboard.NamespacePermissionGroup.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setGroupId(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setGroupName(value);
          break;
        case 3:
          var value = /** @type {string} */ (reader.readString());
          msg.setGroupEmail(value);
          break;
        case 4:
          var value = /** @type {string} */ (reader.readString());
          msg.setNamespaceId(value);
          break;
        case 5:
          var value = /** @type {string} */ (reader.readString());
          msg.setNamespaceName(value);
          break;
        case 6:
          var value = /** @type {string} */ (reader.readString());
          msg.setRole(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.NamespacePermissionGroup.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.NamespacePermissionGroup.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.NamespacePermissionGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.NamespacePermissionGroup.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getGroupId();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
    f = message.getGroupName();
    if (f.length > 0) {
      writer.writeString(2, f);
    }
    f = message.getGroupEmail();
    if (f.length > 0) {
      writer.writeString(3, f);
    }
    f = message.getNamespaceId();
    if (f.length > 0) {
      writer.writeString(4, f);
    }
    f = message.getNamespaceName();
    if (f.length > 0) {
      writer.writeString(5, f);
    }
    f = message.getRole();
    if (f.length > 0) {
      writer.writeString(6, f);
    }
  };

/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.NamespacePermissionGroup.prototype.getGroupId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.NamespacePermissionGroup} returns this
 */
proto.pomerium.dashboard.NamespacePermissionGroup.prototype.setGroupId =
  function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
  };

/**
 * optional string group_name = 2;
 * @return {string}
 */
proto.pomerium.dashboard.NamespacePermissionGroup.prototype.getGroupName =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 2, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.NamespacePermissionGroup} returns this
 */
proto.pomerium.dashboard.NamespacePermissionGroup.prototype.setGroupName =
  function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
  };

/**
 * optional string group_email = 3;
 * @return {string}
 */
proto.pomerium.dashboard.NamespacePermissionGroup.prototype.getGroupEmail =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 3, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.NamespacePermissionGroup} returns this
 */
proto.pomerium.dashboard.NamespacePermissionGroup.prototype.setGroupEmail =
  function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
  };

/**
 * optional string namespace_id = 4;
 * @return {string}
 */
proto.pomerium.dashboard.NamespacePermissionGroup.prototype.getNamespaceId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 4, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.NamespacePermissionGroup} returns this
 */
proto.pomerium.dashboard.NamespacePermissionGroup.prototype.setNamespaceId =
  function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
  };

/**
 * optional string namespace_name = 5;
 * @return {string}
 */
proto.pomerium.dashboard.NamespacePermissionGroup.prototype.getNamespaceName =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 5, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.NamespacePermissionGroup} returns this
 */
proto.pomerium.dashboard.NamespacePermissionGroup.prototype.setNamespaceName =
  function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
  };

/**
 * optional string role = 6;
 * @return {string}
 */
proto.pomerium.dashboard.NamespacePermissionGroup.prototype.getRole =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 6, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.NamespacePermissionGroup} returns this
 */
proto.pomerium.dashboard.NamespacePermissionGroup.prototype.setRole = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 6, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pomerium.dashboard.NamespacePermissionUser.repeatedFields_ = [4];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.NamespacePermissionUser.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.NamespacePermissionUser.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.NamespacePermissionUser} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.NamespacePermissionUser.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        userId: jspb.Message.getFieldWithDefault(msg, 1, ''),
        userName: jspb.Message.getFieldWithDefault(msg, 2, ''),
        userEmail: jspb.Message.getFieldWithDefault(msg, 3, ''),
        groupIdsList:
          (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
        namespaceId: jspb.Message.getFieldWithDefault(msg, 5, ''),
        namespaceName: jspb.Message.getFieldWithDefault(msg, 7, ''),
        role: jspb.Message.getFieldWithDefault(msg, 6, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.NamespacePermissionUser}
 */
proto.pomerium.dashboard.NamespacePermissionUser.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.NamespacePermissionUser();
  return proto.pomerium.dashboard.NamespacePermissionUser.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.NamespacePermissionUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.NamespacePermissionUser}
 */
proto.pomerium.dashboard.NamespacePermissionUser.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setUserId(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setUserName(value);
          break;
        case 3:
          var value = /** @type {string} */ (reader.readString());
          msg.setUserEmail(value);
          break;
        case 4:
          var value = /** @type {string} */ (reader.readString());
          msg.addGroupIds(value);
          break;
        case 5:
          var value = /** @type {string} */ (reader.readString());
          msg.setNamespaceId(value);
          break;
        case 7:
          var value = /** @type {string} */ (reader.readString());
          msg.setNamespaceName(value);
          break;
        case 6:
          var value = /** @type {string} */ (reader.readString());
          msg.setRole(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.NamespacePermissionUser.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.NamespacePermissionUser.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.NamespacePermissionUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.NamespacePermissionUser.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
    f = message.getUserName();
    if (f.length > 0) {
      writer.writeString(2, f);
    }
    f = message.getUserEmail();
    if (f.length > 0) {
      writer.writeString(3, f);
    }
    f = message.getGroupIdsList();
    if (f.length > 0) {
      writer.writeRepeatedString(4, f);
    }
    f = message.getNamespaceId();
    if (f.length > 0) {
      writer.writeString(5, f);
    }
    f = message.getNamespaceName();
    if (f.length > 0) {
      writer.writeString(7, f);
    }
    f = message.getRole();
    if (f.length > 0) {
      writer.writeString(6, f);
    }
  };

/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.NamespacePermissionUser.prototype.getUserId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.NamespacePermissionUser} returns this
 */
proto.pomerium.dashboard.NamespacePermissionUser.prototype.setUserId =
  function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
  };

/**
 * optional string user_name = 2;
 * @return {string}
 */
proto.pomerium.dashboard.NamespacePermissionUser.prototype.getUserName =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 2, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.NamespacePermissionUser} returns this
 */
proto.pomerium.dashboard.NamespacePermissionUser.prototype.setUserName =
  function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
  };

/**
 * optional string user_email = 3;
 * @return {string}
 */
proto.pomerium.dashboard.NamespacePermissionUser.prototype.getUserEmail =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 3, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.NamespacePermissionUser} returns this
 */
proto.pomerium.dashboard.NamespacePermissionUser.prototype.setUserEmail =
  function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
  };

/**
 * repeated string group_ids = 4;
 * @return {!Array<string>}
 */
proto.pomerium.dashboard.NamespacePermissionUser.prototype.getGroupIdsList =
  function () {
    return /** @type {!Array<string>} */ (
      jspb.Message.getRepeatedField(this, 4)
    );
  };

/**
 * @param {!Array<string>} value
 * @return {!proto.pomerium.dashboard.NamespacePermissionUser} returns this
 */
proto.pomerium.dashboard.NamespacePermissionUser.prototype.setGroupIdsList =
  function (value) {
    return jspb.Message.setField(this, 4, value || []);
  };

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.NamespacePermissionUser} returns this
 */
proto.pomerium.dashboard.NamespacePermissionUser.prototype.addGroupIds =
  function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
  };

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.NamespacePermissionUser} returns this
 */
proto.pomerium.dashboard.NamespacePermissionUser.prototype.clearGroupIdsList =
  function () {
    return this.setGroupIdsList([]);
  };

/**
 * optional string namespace_id = 5;
 * @return {string}
 */
proto.pomerium.dashboard.NamespacePermissionUser.prototype.getNamespaceId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 5, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.NamespacePermissionUser} returns this
 */
proto.pomerium.dashboard.NamespacePermissionUser.prototype.setNamespaceId =
  function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
  };

/**
 * optional string namespace_name = 7;
 * @return {string}
 */
proto.pomerium.dashboard.NamespacePermissionUser.prototype.getNamespaceName =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 7, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.NamespacePermissionUser} returns this
 */
proto.pomerium.dashboard.NamespacePermissionUser.prototype.setNamespaceName =
  function (value) {
    return jspb.Message.setProto3StringField(this, 7, value);
  };

/**
 * optional string role = 6;
 * @return {string}
 */
proto.pomerium.dashboard.NamespacePermissionUser.prototype.getRole =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 6, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.NamespacePermissionUser} returns this
 */
proto.pomerium.dashboard.NamespacePermissionUser.prototype.setRole = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 6, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.DeleteNamespacePermissionRequest.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.DeleteNamespacePermissionRequest.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.DeleteNamespacePermissionRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.DeleteNamespacePermissionRequest.toObject =
    function (includeInstance, msg) {
      var f,
        obj = {
          id: jspb.Message.getFieldWithDefault(msg, 1, '')
        };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }
      return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.DeleteNamespacePermissionRequest}
 */
proto.pomerium.dashboard.DeleteNamespacePermissionRequest.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pomerium.dashboard.DeleteNamespacePermissionRequest();
    return proto.pomerium.dashboard.DeleteNamespacePermissionRequest.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.DeleteNamespacePermissionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.DeleteNamespacePermissionRequest}
 */
proto.pomerium.dashboard.DeleteNamespacePermissionRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setId(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.DeleteNamespacePermissionRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.DeleteNamespacePermissionRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.DeleteNamespacePermissionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.DeleteNamespacePermissionRequest.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getId();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
  };

/**
 * optional string id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.DeleteNamespacePermissionRequest.prototype.getId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.DeleteNamespacePermissionRequest} returns this
 */
proto.pomerium.dashboard.DeleteNamespacePermissionRequest.prototype.setId =
  function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.DeleteNamespacePermissionResponse.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.DeleteNamespacePermissionResponse.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.DeleteNamespacePermissionResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.DeleteNamespacePermissionResponse.toObject =
    function (includeInstance, msg) {
      var f,
        obj = {};

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }
      return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.DeleteNamespacePermissionResponse}
 */
proto.pomerium.dashboard.DeleteNamespacePermissionResponse.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pomerium.dashboard.DeleteNamespacePermissionResponse();
    return proto.pomerium.dashboard.DeleteNamespacePermissionResponse.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.DeleteNamespacePermissionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.DeleteNamespacePermissionResponse}
 */
proto.pomerium.dashboard.DeleteNamespacePermissionResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.DeleteNamespacePermissionResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.DeleteNamespacePermissionResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.DeleteNamespacePermissionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.DeleteNamespacePermissionResponse.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.GetNamespacePermissionRequest.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.GetNamespacePermissionRequest.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.GetNamespacePermissionRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.GetNamespacePermissionRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.GetNamespacePermissionRequest}
 */
proto.pomerium.dashboard.GetNamespacePermissionRequest.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pomerium.dashboard.GetNamespacePermissionRequest();
    return proto.pomerium.dashboard.GetNamespacePermissionRequest.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.GetNamespacePermissionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.GetNamespacePermissionRequest}
 */
proto.pomerium.dashboard.GetNamespacePermissionRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setId(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.GetNamespacePermissionRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.GetNamespacePermissionRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.GetNamespacePermissionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.GetNamespacePermissionRequest.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getId();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
  };

/**
 * optional string id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.GetNamespacePermissionRequest.prototype.getId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.GetNamespacePermissionRequest} returns this
 */
proto.pomerium.dashboard.GetNamespacePermissionRequest.prototype.setId =
  function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.GetNamespacePermissionResponse.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.GetNamespacePermissionResponse.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.GetNamespacePermissionResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.GetNamespacePermissionResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        namespacePermission:
          (f = msg.getNamespacePermission()) &&
          proto.pomerium.dashboard.NamespacePermission.toObject(
            includeInstance,
            f
          )
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.GetNamespacePermissionResponse}
 */
proto.pomerium.dashboard.GetNamespacePermissionResponse.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pomerium.dashboard.GetNamespacePermissionResponse();
    return proto.pomerium.dashboard.GetNamespacePermissionResponse.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.GetNamespacePermissionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.GetNamespacePermissionResponse}
 */
proto.pomerium.dashboard.GetNamespacePermissionResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.NamespacePermission();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.NamespacePermission
              .deserializeBinaryFromReader
          );
          msg.setNamespacePermission(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.GetNamespacePermissionResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.GetNamespacePermissionResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.GetNamespacePermissionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.GetNamespacePermissionResponse.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getNamespacePermission();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        proto.pomerium.dashboard.NamespacePermission.serializeBinaryToWriter
      );
    }
  };

/**
 * optional NamespacePermission namespace_permission = 1;
 * @return {?proto.pomerium.dashboard.NamespacePermission}
 */
proto.pomerium.dashboard.GetNamespacePermissionResponse.prototype.getNamespacePermission =
  function () {
    return /** @type{?proto.pomerium.dashboard.NamespacePermission} */ (
      jspb.Message.getWrapperField(
        this,
        proto.pomerium.dashboard.NamespacePermission,
        1
      )
    );
  };

/**
 * @param {?proto.pomerium.dashboard.NamespacePermission|undefined} value
 * @return {!proto.pomerium.dashboard.GetNamespacePermissionResponse} returns this
 */
proto.pomerium.dashboard.GetNamespacePermissionResponse.prototype.setNamespacePermission =
  function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.GetNamespacePermissionResponse} returns this
 */
proto.pomerium.dashboard.GetNamespacePermissionResponse.prototype.clearNamespacePermission =
  function () {
    return this.setNamespacePermission(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.GetNamespacePermissionResponse.prototype.hasNamespacePermission =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.ListNamespacePermissionsRequest.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.ListNamespacePermissionsRequest.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.ListNamespacePermissionsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.ListNamespacePermissionsRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {};

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.ListNamespacePermissionsRequest}
 */
proto.pomerium.dashboard.ListNamespacePermissionsRequest.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pomerium.dashboard.ListNamespacePermissionsRequest();
    return proto.pomerium.dashboard.ListNamespacePermissionsRequest.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.ListNamespacePermissionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.ListNamespacePermissionsRequest}
 */
proto.pomerium.dashboard.ListNamespacePermissionsRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.ListNamespacePermissionsRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.ListNamespacePermissionsRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.ListNamespacePermissionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.ListNamespacePermissionsRequest.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
  };

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pomerium.dashboard.ListNamespacePermissionsResponse.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.ListNamespacePermissionsResponse.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.ListNamespacePermissionsResponse.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.ListNamespacePermissionsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.ListNamespacePermissionsResponse.toObject =
    function (includeInstance, msg) {
      var f,
        obj = {
          namespacePermissionsList: jspb.Message.toObjectList(
            msg.getNamespacePermissionsList(),
            proto.pomerium.dashboard.NamespacePermission.toObject,
            includeInstance
          )
        };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }
      return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.ListNamespacePermissionsResponse}
 */
proto.pomerium.dashboard.ListNamespacePermissionsResponse.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pomerium.dashboard.ListNamespacePermissionsResponse();
    return proto.pomerium.dashboard.ListNamespacePermissionsResponse.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.ListNamespacePermissionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.ListNamespacePermissionsResponse}
 */
proto.pomerium.dashboard.ListNamespacePermissionsResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.NamespacePermission();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.NamespacePermission
              .deserializeBinaryFromReader
          );
          msg.addNamespacePermissions(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.ListNamespacePermissionsResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.ListNamespacePermissionsResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.ListNamespacePermissionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.ListNamespacePermissionsResponse.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getNamespacePermissionsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        proto.pomerium.dashboard.NamespacePermission.serializeBinaryToWriter
      );
    }
  };

/**
 * repeated NamespacePermission namespace_permissions = 1;
 * @return {!Array<!proto.pomerium.dashboard.NamespacePermission>}
 */
proto.pomerium.dashboard.ListNamespacePermissionsResponse.prototype.getNamespacePermissionsList =
  function () {
    return /** @type{!Array<!proto.pomerium.dashboard.NamespacePermission>} */ (
      jspb.Message.getRepeatedWrapperField(
        this,
        proto.pomerium.dashboard.NamespacePermission,
        1
      )
    );
  };

/**
 * @param {!Array<!proto.pomerium.dashboard.NamespacePermission>} value
 * @return {!proto.pomerium.dashboard.ListNamespacePermissionsResponse} returns this
 */
proto.pomerium.dashboard.ListNamespacePermissionsResponse.prototype.setNamespacePermissionsList =
  function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
  };

/**
 * @param {!proto.pomerium.dashboard.NamespacePermission=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.NamespacePermission}
 */
proto.pomerium.dashboard.ListNamespacePermissionsResponse.prototype.addNamespacePermissions =
  function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(
      this,
      1,
      opt_value,
      proto.pomerium.dashboard.NamespacePermission,
      opt_index
    );
  };

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.ListNamespacePermissionsResponse} returns this
 */
proto.pomerium.dashboard.ListNamespacePermissionsResponse.prototype.clearNamespacePermissionsList =
  function () {
    return this.setNamespacePermissionsList([]);
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.ListNamespacePermissionGroupsRequest.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.ListNamespacePermissionGroupsRequest.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.ListNamespacePermissionGroupsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.ListNamespacePermissionGroupsRequest.toObject =
    function (includeInstance, msg) {
      var f,
        obj = {
          namespaceId: jspb.Message.getFieldWithDefault(msg, 1, '')
        };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }
      return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.ListNamespacePermissionGroupsRequest}
 */
proto.pomerium.dashboard.ListNamespacePermissionGroupsRequest.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg =
      new proto.pomerium.dashboard.ListNamespacePermissionGroupsRequest();
    return proto.pomerium.dashboard.ListNamespacePermissionGroupsRequest.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.ListNamespacePermissionGroupsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.ListNamespacePermissionGroupsRequest}
 */
proto.pomerium.dashboard.ListNamespacePermissionGroupsRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setNamespaceId(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.ListNamespacePermissionGroupsRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.ListNamespacePermissionGroupsRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.ListNamespacePermissionGroupsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.ListNamespacePermissionGroupsRequest.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getNamespaceId();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
  };

/**
 * optional string namespace_id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.ListNamespacePermissionGroupsRequest.prototype.getNamespaceId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ListNamespacePermissionGroupsRequest} returns this
 */
proto.pomerium.dashboard.ListNamespacePermissionGroupsRequest.prototype.setNamespaceId =
  function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
  };

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pomerium.dashboard.ListNamespacePermissionGroupsResponse.repeatedFields_ =
  [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.ListNamespacePermissionGroupsResponse.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.ListNamespacePermissionGroupsResponse.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.ListNamespacePermissionGroupsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.ListNamespacePermissionGroupsResponse.toObject =
    function (includeInstance, msg) {
      var f,
        obj = {
          groupsList: jspb.Message.toObjectList(
            msg.getGroupsList(),
            proto.pomerium.dashboard.NamespacePermissionGroup.toObject,
            includeInstance
          )
        };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }
      return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.ListNamespacePermissionGroupsResponse}
 */
proto.pomerium.dashboard.ListNamespacePermissionGroupsResponse.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg =
      new proto.pomerium.dashboard.ListNamespacePermissionGroupsResponse();
    return proto.pomerium.dashboard.ListNamespacePermissionGroupsResponse.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.ListNamespacePermissionGroupsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.ListNamespacePermissionGroupsResponse}
 */
proto.pomerium.dashboard.ListNamespacePermissionGroupsResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.NamespacePermissionGroup();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.NamespacePermissionGroup
              .deserializeBinaryFromReader
          );
          msg.addGroups(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.ListNamespacePermissionGroupsResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.ListNamespacePermissionGroupsResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.ListNamespacePermissionGroupsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.ListNamespacePermissionGroupsResponse.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getGroupsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        proto.pomerium.dashboard.NamespacePermissionGroup
          .serializeBinaryToWriter
      );
    }
  };

/**
 * repeated NamespacePermissionGroup groups = 1;
 * @return {!Array<!proto.pomerium.dashboard.NamespacePermissionGroup>}
 */
proto.pomerium.dashboard.ListNamespacePermissionGroupsResponse.prototype.getGroupsList =
  function () {
    return /** @type{!Array<!proto.pomerium.dashboard.NamespacePermissionGroup>} */ (
      jspb.Message.getRepeatedWrapperField(
        this,
        proto.pomerium.dashboard.NamespacePermissionGroup,
        1
      )
    );
  };

/**
 * @param {!Array<!proto.pomerium.dashboard.NamespacePermissionGroup>} value
 * @return {!proto.pomerium.dashboard.ListNamespacePermissionGroupsResponse} returns this
 */
proto.pomerium.dashboard.ListNamespacePermissionGroupsResponse.prototype.setGroupsList =
  function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
  };

/**
 * @param {!proto.pomerium.dashboard.NamespacePermissionGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.NamespacePermissionGroup}
 */
proto.pomerium.dashboard.ListNamespacePermissionGroupsResponse.prototype.addGroups =
  function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(
      this,
      1,
      opt_value,
      proto.pomerium.dashboard.NamespacePermissionGroup,
      opt_index
    );
  };

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.ListNamespacePermissionGroupsResponse} returns this
 */
proto.pomerium.dashboard.ListNamespacePermissionGroupsResponse.prototype.clearGroupsList =
  function () {
    return this.setGroupsList([]);
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.ListNamespacePermissionUsersRequest.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.ListNamespacePermissionUsersRequest.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.ListNamespacePermissionUsersRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.ListNamespacePermissionUsersRequest.toObject =
    function (includeInstance, msg) {
      var f,
        obj = {
          namespaceId: jspb.Message.getFieldWithDefault(msg, 1, '')
        };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }
      return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.ListNamespacePermissionUsersRequest}
 */
proto.pomerium.dashboard.ListNamespacePermissionUsersRequest.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg =
      new proto.pomerium.dashboard.ListNamespacePermissionUsersRequest();
    return proto.pomerium.dashboard.ListNamespacePermissionUsersRequest.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.ListNamespacePermissionUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.ListNamespacePermissionUsersRequest}
 */
proto.pomerium.dashboard.ListNamespacePermissionUsersRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setNamespaceId(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.ListNamespacePermissionUsersRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.ListNamespacePermissionUsersRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.ListNamespacePermissionUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.ListNamespacePermissionUsersRequest.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getNamespaceId();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
  };

/**
 * optional string namespace_id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.ListNamespacePermissionUsersRequest.prototype.getNamespaceId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ListNamespacePermissionUsersRequest} returns this
 */
proto.pomerium.dashboard.ListNamespacePermissionUsersRequest.prototype.setNamespaceId =
  function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
  };

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pomerium.dashboard.ListNamespacePermissionUsersResponse.repeatedFields_ =
  [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.ListNamespacePermissionUsersResponse.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.ListNamespacePermissionUsersResponse.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.ListNamespacePermissionUsersResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.ListNamespacePermissionUsersResponse.toObject =
    function (includeInstance, msg) {
      var f,
        obj = {
          usersList: jspb.Message.toObjectList(
            msg.getUsersList(),
            proto.pomerium.dashboard.NamespacePermissionUser.toObject,
            includeInstance
          )
        };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }
      return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.ListNamespacePermissionUsersResponse}
 */
proto.pomerium.dashboard.ListNamespacePermissionUsersResponse.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg =
      new proto.pomerium.dashboard.ListNamespacePermissionUsersResponse();
    return proto.pomerium.dashboard.ListNamespacePermissionUsersResponse.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.ListNamespacePermissionUsersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.ListNamespacePermissionUsersResponse}
 */
proto.pomerium.dashboard.ListNamespacePermissionUsersResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.NamespacePermissionUser();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.NamespacePermissionUser
              .deserializeBinaryFromReader
          );
          msg.addUsers(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.ListNamespacePermissionUsersResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.ListNamespacePermissionUsersResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.ListNamespacePermissionUsersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.ListNamespacePermissionUsersResponse.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getUsersList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        proto.pomerium.dashboard.NamespacePermissionUser.serializeBinaryToWriter
      );
    }
  };

/**
 * repeated NamespacePermissionUser users = 1;
 * @return {!Array<!proto.pomerium.dashboard.NamespacePermissionUser>}
 */
proto.pomerium.dashboard.ListNamespacePermissionUsersResponse.prototype.getUsersList =
  function () {
    return /** @type{!Array<!proto.pomerium.dashboard.NamespacePermissionUser>} */ (
      jspb.Message.getRepeatedWrapperField(
        this,
        proto.pomerium.dashboard.NamespacePermissionUser,
        1
      )
    );
  };

/**
 * @param {!Array<!proto.pomerium.dashboard.NamespacePermissionUser>} value
 * @return {!proto.pomerium.dashboard.ListNamespacePermissionUsersResponse} returns this
 */
proto.pomerium.dashboard.ListNamespacePermissionUsersResponse.prototype.setUsersList =
  function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
  };

/**
 * @param {!proto.pomerium.dashboard.NamespacePermissionUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.NamespacePermissionUser}
 */
proto.pomerium.dashboard.ListNamespacePermissionUsersResponse.prototype.addUsers =
  function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(
      this,
      1,
      opt_value,
      proto.pomerium.dashboard.NamespacePermissionUser,
      opt_index
    );
  };

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.ListNamespacePermissionUsersResponse} returns this
 */
proto.pomerium.dashboard.ListNamespacePermissionUsersResponse.prototype.clearUsersList =
  function () {
    return this.setUsersList([]);
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.SetNamespacePermissionRequest.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.SetNamespacePermissionRequest.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.SetNamespacePermissionRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.SetNamespacePermissionRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        namespacePermission:
          (f = msg.getNamespacePermission()) &&
          proto.pomerium.dashboard.NamespacePermission.toObject(
            includeInstance,
            f
          )
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.SetNamespacePermissionRequest}
 */
proto.pomerium.dashboard.SetNamespacePermissionRequest.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pomerium.dashboard.SetNamespacePermissionRequest();
    return proto.pomerium.dashboard.SetNamespacePermissionRequest.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.SetNamespacePermissionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.SetNamespacePermissionRequest}
 */
proto.pomerium.dashboard.SetNamespacePermissionRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.NamespacePermission();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.NamespacePermission
              .deserializeBinaryFromReader
          );
          msg.setNamespacePermission(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.SetNamespacePermissionRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.SetNamespacePermissionRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.SetNamespacePermissionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.SetNamespacePermissionRequest.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getNamespacePermission();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        proto.pomerium.dashboard.NamespacePermission.serializeBinaryToWriter
      );
    }
  };

/**
 * optional NamespacePermission namespace_permission = 1;
 * @return {?proto.pomerium.dashboard.NamespacePermission}
 */
proto.pomerium.dashboard.SetNamespacePermissionRequest.prototype.getNamespacePermission =
  function () {
    return /** @type{?proto.pomerium.dashboard.NamespacePermission} */ (
      jspb.Message.getWrapperField(
        this,
        proto.pomerium.dashboard.NamespacePermission,
        1
      )
    );
  };

/**
 * @param {?proto.pomerium.dashboard.NamespacePermission|undefined} value
 * @return {!proto.pomerium.dashboard.SetNamespacePermissionRequest} returns this
 */
proto.pomerium.dashboard.SetNamespacePermissionRequest.prototype.setNamespacePermission =
  function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.SetNamespacePermissionRequest} returns this
 */
proto.pomerium.dashboard.SetNamespacePermissionRequest.prototype.clearNamespacePermission =
  function () {
    return this.setNamespacePermission(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.SetNamespacePermissionRequest.prototype.hasNamespacePermission =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.SetNamespacePermissionResponse.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.SetNamespacePermissionResponse.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.SetNamespacePermissionResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.SetNamespacePermissionResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        namespacePermission:
          (f = msg.getNamespacePermission()) &&
          proto.pomerium.dashboard.NamespacePermission.toObject(
            includeInstance,
            f
          )
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.SetNamespacePermissionResponse}
 */
proto.pomerium.dashboard.SetNamespacePermissionResponse.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pomerium.dashboard.SetNamespacePermissionResponse();
    return proto.pomerium.dashboard.SetNamespacePermissionResponse.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.SetNamespacePermissionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.SetNamespacePermissionResponse}
 */
proto.pomerium.dashboard.SetNamespacePermissionResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.NamespacePermission();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.NamespacePermission
              .deserializeBinaryFromReader
          );
          msg.setNamespacePermission(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.SetNamespacePermissionResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.SetNamespacePermissionResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.SetNamespacePermissionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.SetNamespacePermissionResponse.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getNamespacePermission();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        proto.pomerium.dashboard.NamespacePermission.serializeBinaryToWriter
      );
    }
  };

/**
 * optional NamespacePermission namespace_permission = 1;
 * @return {?proto.pomerium.dashboard.NamespacePermission}
 */
proto.pomerium.dashboard.SetNamespacePermissionResponse.prototype.getNamespacePermission =
  function () {
    return /** @type{?proto.pomerium.dashboard.NamespacePermission} */ (
      jspb.Message.getWrapperField(
        this,
        proto.pomerium.dashboard.NamespacePermission,
        1
      )
    );
  };

/**
 * @param {?proto.pomerium.dashboard.NamespacePermission|undefined} value
 * @return {!proto.pomerium.dashboard.SetNamespacePermissionResponse} returns this
 */
proto.pomerium.dashboard.SetNamespacePermissionResponse.prototype.setNamespacePermission =
  function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.SetNamespacePermissionResponse} returns this
 */
proto.pomerium.dashboard.SetNamespacePermissionResponse.prototype.clearNamespacePermission =
  function () {
    return this.setNamespacePermission(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.SetNamespacePermissionResponse.prototype.hasNamespacePermission =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

goog.object.extend(exports, proto.pomerium.dashboard);
