import { Box } from '@mui/material';
import { useTheme } from '@mui/styles';
import React, { useEffect } from 'react';
import type { HTMLProps, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import track from 'src/utils/analytics';

interface PageProps extends HTMLProps<HTMLDivElement> {
  children?: ReactNode;
  title?: string;
}

const Page = ({ children, title }: PageProps): JSX.Element => {
  const location = useLocation();
  const theme = useTheme();

  useEffect(() => {
    document.title = title;
  }, [title]);
  useEffect(() => {
    track.pageview({
      page_path: location.pathname
    });
  }, [location]);

  return (
    <Box
      height={'calc(100vh - 64px)'}
      sx={{
        padding: theme.spacing(3),
        marginTop: theme.mixins.toolbar.height,
        alignItems: 'center'
      }}
    >
      {children}
    </Box>
  );
};
export default Page;
