// source: udpa/annotations/security.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var udpa_annotations_status_pb = require('../../udpa/annotations/status_pb.js');
goog.object.extend(proto, udpa_annotations_status_pb);
var google_protobuf_any_pb = require('google-protobuf/google/protobuf/any_pb.js');
goog.object.extend(proto, google_protobuf_any_pb);
var google_protobuf_descriptor_pb = require('google-protobuf/google/protobuf/descriptor_pb.js');
goog.object.extend(proto, google_protobuf_descriptor_pb);
var validate_validate_pb = require('../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol(
  'proto.udpa.annotations.FieldSecurityAnnotation',
  null,
  global
);
goog.exportSymbol('proto.udpa.annotations.security', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.udpa.annotations.FieldSecurityAnnotation = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.udpa.annotations.FieldSecurityAnnotation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.udpa.annotations.FieldSecurityAnnotation.displayName =
    'proto.udpa.annotations.FieldSecurityAnnotation';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.udpa.annotations.FieldSecurityAnnotation.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.udpa.annotations.FieldSecurityAnnotation.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.udpa.annotations.FieldSecurityAnnotation} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.udpa.annotations.FieldSecurityAnnotation.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        configureForUntrustedDownstream:
          jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
        configureForUntrustedUpstream: jspb.Message.getBooleanFieldWithDefault(
          msg,
          2,
          false
        )
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.udpa.annotations.FieldSecurityAnnotation}
 */
proto.udpa.annotations.FieldSecurityAnnotation.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.udpa.annotations.FieldSecurityAnnotation();
  return proto.udpa.annotations.FieldSecurityAnnotation.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.udpa.annotations.FieldSecurityAnnotation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.udpa.annotations.FieldSecurityAnnotation}
 */
proto.udpa.annotations.FieldSecurityAnnotation.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {boolean} */ (reader.readBool());
          msg.setConfigureForUntrustedDownstream(value);
          break;
        case 2:
          var value = /** @type {boolean} */ (reader.readBool());
          msg.setConfigureForUntrustedUpstream(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.udpa.annotations.FieldSecurityAnnotation.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.udpa.annotations.FieldSecurityAnnotation.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.udpa.annotations.FieldSecurityAnnotation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.udpa.annotations.FieldSecurityAnnotation.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getConfigureForUntrustedDownstream();
    if (f) {
      writer.writeBool(1, f);
    }
    f = message.getConfigureForUntrustedUpstream();
    if (f) {
      writer.writeBool(2, f);
    }
  };

/**
 * optional bool configure_for_untrusted_downstream = 1;
 * @return {boolean}
 */
proto.udpa.annotations.FieldSecurityAnnotation.prototype.getConfigureForUntrustedDownstream =
  function () {
    return /** @type {boolean} */ (
      jspb.Message.getBooleanFieldWithDefault(this, 1, false)
    );
  };

/**
 * @param {boolean} value
 * @return {!proto.udpa.annotations.FieldSecurityAnnotation} returns this
 */
proto.udpa.annotations.FieldSecurityAnnotation.prototype.setConfigureForUntrustedDownstream =
  function (value) {
    return jspb.Message.setProto3BooleanField(this, 1, value);
  };

/**
 * optional bool configure_for_untrusted_upstream = 2;
 * @return {boolean}
 */
proto.udpa.annotations.FieldSecurityAnnotation.prototype.getConfigureForUntrustedUpstream =
  function () {
    return /** @type {boolean} */ (
      jspb.Message.getBooleanFieldWithDefault(this, 2, false)
    );
  };

/**
 * @param {boolean} value
 * @return {!proto.udpa.annotations.FieldSecurityAnnotation} returns this
 */
proto.udpa.annotations.FieldSecurityAnnotation.prototype.setConfigureForUntrustedUpstream =
  function (value) {
    return jspb.Message.setProto3BooleanField(this, 2, value);
  };

/**
 * A tuple of {field number, class constructor} for the extension
 * field named `security`.
 * @type {!jspb.ExtensionFieldInfo<!proto.udpa.annotations.FieldSecurityAnnotation>}
 */
proto.udpa.annotations.security = new jspb.ExtensionFieldInfo(
  11122993,
  { security: 0 },
  proto.udpa.annotations.FieldSecurityAnnotation,
  /** @type {?function((boolean|undefined),!jspb.Message=): !Object} */ (
    proto.udpa.annotations.FieldSecurityAnnotation.toObject
  ),
  0
);

google_protobuf_descriptor_pb.FieldOptions.extensionsBinary[11122993] =
  new jspb.ExtensionFieldBinaryInfo(
    proto.udpa.annotations.security,
    jspb.BinaryReader.prototype.readMessage,
    jspb.BinaryWriter.prototype.writeMessage,
    proto.udpa.annotations.FieldSecurityAnnotation.serializeBinaryToWriter,
    proto.udpa.annotations.FieldSecurityAnnotation.deserializeBinaryFromReader,
    false
  );
// This registers the extension field with the extended class, so that
// toObject() will function correctly.
google_protobuf_descriptor_pb.FieldOptions.extensions[11122993] =
  proto.udpa.annotations.security;

goog.object.extend(exports, proto.udpa.annotations);
