// source: settings.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js');
goog.object.extend(proto, google_protobuf_duration_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.pomerium.dashboard.GetSettingsRequest', null, global);
goog.exportSymbol('proto.pomerium.dashboard.GetSettingsResponse', null, global);
goog.exportSymbol('proto.pomerium.dashboard.SetSettingsRequest', null, global);
goog.exportSymbol('proto.pomerium.dashboard.SetSettingsResponse', null, global);
goog.exportSymbol('proto.pomerium.dashboard.Settings', null, global);
goog.exportSymbol(
  'proto.pomerium.dashboard.Settings.Certificate',
  null,
  global
);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.Settings = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.pomerium.dashboard.Settings.repeatedFields_,
    null
  );
};
goog.inherits(proto.pomerium.dashboard.Settings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.Settings.displayName =
    'proto.pomerium.dashboard.Settings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.Settings.Certificate = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.Settings.Certificate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.Settings.Certificate.displayName =
    'proto.pomerium.dashboard.Settings.Certificate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.GetSettingsRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.GetSettingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.GetSettingsRequest.displayName =
    'proto.pomerium.dashboard.GetSettingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.GetSettingsResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.GetSettingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.GetSettingsResponse.displayName =
    'proto.pomerium.dashboard.GetSettingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.SetSettingsRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.SetSettingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.SetSettingsRequest.displayName =
    'proto.pomerium.dashboard.SetSettingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.SetSettingsResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.SetSettingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.SetSettingsResponse.displayName =
    'proto.pomerium.dashboard.SetSettingsResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pomerium.dashboard.Settings.repeatedFields_ = [9, 26];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.Settings.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.Settings.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.Settings} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.Settings.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        modifiedAt:
          (f = msg.getModifiedAt()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        installationId: jspb.Message.getFieldWithDefault(msg, 68, ''),
        debug: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
        logLevel: jspb.Message.getFieldWithDefault(msg, 3, ''),
        proxyLogLevel: jspb.Message.getFieldWithDefault(msg, 4, ''),
        sharedSecret: jspb.Message.getFieldWithDefault(msg, 5, ''),
        services: jspb.Message.getFieldWithDefault(msg, 6, ''),
        address: jspb.Message.getFieldWithDefault(msg, 7, ''),
        insecureServer: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
        dnsLookupFamily: jspb.Message.getFieldWithDefault(msg, 60, ''),
        certificatesList: jspb.Message.toObjectList(
          msg.getCertificatesList(),
          proto.pomerium.dashboard.Settings.Certificate.toObject,
          includeInstance
        ),
        httpRedirectAddr: jspb.Message.getFieldWithDefault(msg, 10, ''),
        timeoutRead:
          (f = msg.getTimeoutRead()) &&
          google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
        timeoutWrite:
          (f = msg.getTimeoutWrite()) &&
          google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
        timeoutIdle:
          (f = msg.getTimeoutIdle()) &&
          google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
        authenticateServiceUrl: jspb.Message.getFieldWithDefault(msg, 14, ''),
        authenticateCallbackPath: jspb.Message.getFieldWithDefault(msg, 15, ''),
        cookieName: jspb.Message.getFieldWithDefault(msg, 16, ''),
        cookieSecret: jspb.Message.getFieldWithDefault(msg, 17, ''),
        cookieDomain: jspb.Message.getFieldWithDefault(msg, 18, ''),
        cookieSecure: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
        cookieHttpOnly: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
        cookieExpire:
          (f = msg.getCookieExpire()) &&
          google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
        idpClientId: jspb.Message.getFieldWithDefault(msg, 22, ''),
        idpClientSecret: jspb.Message.getFieldWithDefault(msg, 23, ''),
        idpProvider: jspb.Message.getFieldWithDefault(msg, 24, ''),
        idpProviderUrl: jspb.Message.getFieldWithDefault(msg, 25, ''),
        scopesList:
          (f = jspb.Message.getRepeatedField(msg, 26)) == null ? undefined : f,
        idpServiceAccount: jspb.Message.getFieldWithDefault(msg, 27, ''),
        idpRefreshDirectoryTimeout:
          (f = msg.getIdpRefreshDirectoryTimeout()) &&
          google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
        idpRefreshDirectoryInterval:
          (f = msg.getIdpRefreshDirectoryInterval()) &&
          google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
        requestParamsMap: (f = msg.getRequestParamsMap())
          ? f.toObject(includeInstance, undefined)
          : [],
        authorizeServiceUrl: jspb.Message.getFieldWithDefault(msg, 32, ''),
        certificateAuthority: jspb.Message.getFieldWithDefault(msg, 34, ''),
        certificateAuthorityFile: jspb.Message.getFieldWithDefault(msg, 35, ''),
        certificateAuthorityKeyPairId: jspb.Message.getFieldWithDefault(
          msg,
          64,
          ''
        ),
        setResponseHeadersMap: (f = msg.getSetResponseHeadersMap())
          ? f.toObject(includeInstance, undefined)
          : [],
        jwtClaimsHeadersMap: (f = msg.getJwtClaimsHeadersMap())
          ? f.toObject(includeInstance, undefined)
          : [],
        defaultUpstreamTimeout:
          (f = msg.getDefaultUpstreamTimeout()) &&
          google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
        metricsAddress: jspb.Message.getFieldWithDefault(msg, 40, ''),
        tracingProvider: jspb.Message.getFieldWithDefault(msg, 41, ''),
        tracingSampleRate: jspb.Message.getFloatingPointFieldWithDefault(
          msg,
          42,
          0.0
        ),
        tracingJaegerCollectorEndpoint: jspb.Message.getFieldWithDefault(
          msg,
          43,
          ''
        ),
        tracingJaegerAgentEndpoint: jspb.Message.getFieldWithDefault(
          msg,
          44,
          ''
        ),
        tracingZipkinEndpoint: jspb.Message.getFieldWithDefault(msg, 45, ''),
        grpcAddress: jspb.Message.getFieldWithDefault(msg, 46, ''),
        grpcInsecure: jspb.Message.getBooleanFieldWithDefault(msg, 47, false),
        cacheServiceUrl: jspb.Message.getFieldWithDefault(msg, 51, ''),
        databrokerServiceUrl: jspb.Message.getFieldWithDefault(msg, 52, ''),
        clientCa: jspb.Message.getFieldWithDefault(msg, 53, ''),
        clientCaFile: jspb.Message.getFieldWithDefault(msg, 54, ''),
        clientCaKeyPairId: jspb.Message.getFieldWithDefault(msg, 65, ''),
        googleCloudServerlessAuthenticationServiceAccount:
          jspb.Message.getFieldWithDefault(msg, 55, ''),
        autocert: jspb.Message.getBooleanFieldWithDefault(msg, 56, false),
        autocertUseStaging: jspb.Message.getBooleanFieldWithDefault(
          msg,
          57,
          false
        ),
        autocertMustStaple: jspb.Message.getBooleanFieldWithDefault(
          msg,
          58,
          false
        ),
        autocertDir: jspb.Message.getFieldWithDefault(msg, 59, ''),
        skipXffAppend: jspb.Message.getBooleanFieldWithDefault(msg, 63, false)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.Settings}
 */
proto.pomerium.dashboard.Settings.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.Settings();
  return proto.pomerium.dashboard.Settings.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.Settings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.Settings}
 */
proto.pomerium.dashboard.Settings.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        );
        msg.setModifiedAt(value);
        break;
      case 68:
        var value = /** @type {string} */ (reader.readString());
        msg.setInstallationId(value);
        break;
      case 2:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setDebug(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setLogLevel(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setProxyLogLevel(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setSharedSecret(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setServices(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setAddress(value);
        break;
      case 8:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setInsecureServer(value);
        break;
      case 60:
        var value = /** @type {string} */ (reader.readString());
        msg.setDnsLookupFamily(value);
        break;
      case 9:
        var value = new proto.pomerium.dashboard.Settings.Certificate();
        reader.readMessage(
          value,
          proto.pomerium.dashboard.Settings.Certificate
            .deserializeBinaryFromReader
        );
        msg.addCertificates(value);
        break;
      case 10:
        var value = /** @type {string} */ (reader.readString());
        msg.setHttpRedirectAddr(value);
        break;
      case 11:
        var value = new google_protobuf_duration_pb.Duration();
        reader.readMessage(
          value,
          google_protobuf_duration_pb.Duration.deserializeBinaryFromReader
        );
        msg.setTimeoutRead(value);
        break;
      case 12:
        var value = new google_protobuf_duration_pb.Duration();
        reader.readMessage(
          value,
          google_protobuf_duration_pb.Duration.deserializeBinaryFromReader
        );
        msg.setTimeoutWrite(value);
        break;
      case 13:
        var value = new google_protobuf_duration_pb.Duration();
        reader.readMessage(
          value,
          google_protobuf_duration_pb.Duration.deserializeBinaryFromReader
        );
        msg.setTimeoutIdle(value);
        break;
      case 14:
        var value = /** @type {string} */ (reader.readString());
        msg.setAuthenticateServiceUrl(value);
        break;
      case 15:
        var value = /** @type {string} */ (reader.readString());
        msg.setAuthenticateCallbackPath(value);
        break;
      case 16:
        var value = /** @type {string} */ (reader.readString());
        msg.setCookieName(value);
        break;
      case 17:
        var value = /** @type {string} */ (reader.readString());
        msg.setCookieSecret(value);
        break;
      case 18:
        var value = /** @type {string} */ (reader.readString());
        msg.setCookieDomain(value);
        break;
      case 19:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setCookieSecure(value);
        break;
      case 20:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setCookieHttpOnly(value);
        break;
      case 21:
        var value = new google_protobuf_duration_pb.Duration();
        reader.readMessage(
          value,
          google_protobuf_duration_pb.Duration.deserializeBinaryFromReader
        );
        msg.setCookieExpire(value);
        break;
      case 22:
        var value = /** @type {string} */ (reader.readString());
        msg.setIdpClientId(value);
        break;
      case 23:
        var value = /** @type {string} */ (reader.readString());
        msg.setIdpClientSecret(value);
        break;
      case 24:
        var value = /** @type {string} */ (reader.readString());
        msg.setIdpProvider(value);
        break;
      case 25:
        var value = /** @type {string} */ (reader.readString());
        msg.setIdpProviderUrl(value);
        break;
      case 26:
        var value = /** @type {string} */ (reader.readString());
        msg.addScopes(value);
        break;
      case 27:
        var value = /** @type {string} */ (reader.readString());
        msg.setIdpServiceAccount(value);
        break;
      case 28:
        var value = new google_protobuf_duration_pb.Duration();
        reader.readMessage(
          value,
          google_protobuf_duration_pb.Duration.deserializeBinaryFromReader
        );
        msg.setIdpRefreshDirectoryTimeout(value);
        break;
      case 29:
        var value = new google_protobuf_duration_pb.Duration();
        reader.readMessage(
          value,
          google_protobuf_duration_pb.Duration.deserializeBinaryFromReader
        );
        msg.setIdpRefreshDirectoryInterval(value);
        break;
      case 30:
        var value = msg.getRequestParamsMap();
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(
            message,
            reader,
            jspb.BinaryReader.prototype.readString,
            jspb.BinaryReader.prototype.readString,
            null,
            '',
            ''
          );
        });
        break;
      case 32:
        var value = /** @type {string} */ (reader.readString());
        msg.setAuthorizeServiceUrl(value);
        break;
      case 34:
        var value = /** @type {string} */ (reader.readString());
        msg.setCertificateAuthority(value);
        break;
      case 35:
        var value = /** @type {string} */ (reader.readString());
        msg.setCertificateAuthorityFile(value);
        break;
      case 64:
        var value = /** @type {string} */ (reader.readString());
        msg.setCertificateAuthorityKeyPairId(value);
        break;
      case 67:
        var value = msg.getSetResponseHeadersMap();
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(
            message,
            reader,
            jspb.BinaryReader.prototype.readString,
            jspb.BinaryReader.prototype.readString,
            null,
            '',
            ''
          );
        });
        break;
      case 66:
        var value = msg.getJwtClaimsHeadersMap();
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(
            message,
            reader,
            jspb.BinaryReader.prototype.readString,
            jspb.BinaryReader.prototype.readString,
            null,
            '',
            ''
          );
        });
        break;
      case 39:
        var value = new google_protobuf_duration_pb.Duration();
        reader.readMessage(
          value,
          google_protobuf_duration_pb.Duration.deserializeBinaryFromReader
        );
        msg.setDefaultUpstreamTimeout(value);
        break;
      case 40:
        var value = /** @type {string} */ (reader.readString());
        msg.setMetricsAddress(value);
        break;
      case 41:
        var value = /** @type {string} */ (reader.readString());
        msg.setTracingProvider(value);
        break;
      case 42:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setTracingSampleRate(value);
        break;
      case 43:
        var value = /** @type {string} */ (reader.readString());
        msg.setTracingJaegerCollectorEndpoint(value);
        break;
      case 44:
        var value = /** @type {string} */ (reader.readString());
        msg.setTracingJaegerAgentEndpoint(value);
        break;
      case 45:
        var value = /** @type {string} */ (reader.readString());
        msg.setTracingZipkinEndpoint(value);
        break;
      case 46:
        var value = /** @type {string} */ (reader.readString());
        msg.setGrpcAddress(value);
        break;
      case 47:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setGrpcInsecure(value);
        break;
      case 51:
        var value = /** @type {string} */ (reader.readString());
        msg.setCacheServiceUrl(value);
        break;
      case 52:
        var value = /** @type {string} */ (reader.readString());
        msg.setDatabrokerServiceUrl(value);
        break;
      case 53:
        var value = /** @type {string} */ (reader.readString());
        msg.setClientCa(value);
        break;
      case 54:
        var value = /** @type {string} */ (reader.readString());
        msg.setClientCaFile(value);
        break;
      case 65:
        var value = /** @type {string} */ (reader.readString());
        msg.setClientCaKeyPairId(value);
        break;
      case 55:
        var value = /** @type {string} */ (reader.readString());
        msg.setGoogleCloudServerlessAuthenticationServiceAccount(value);
        break;
      case 56:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setAutocert(value);
        break;
      case 57:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setAutocertUseStaging(value);
        break;
      case 58:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setAutocertMustStaple(value);
        break;
      case 59:
        var value = /** @type {string} */ (reader.readString());
        msg.setAutocertDir(value);
        break;
      case 63:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setSkipXffAppend(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.Settings.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.pomerium.dashboard.Settings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.Settings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.Settings.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getModifiedAt();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 68));
  if (f != null) {
    writer.writeString(68, f);
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(2, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(3, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(4, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(5, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(6, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(7, f);
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBool(8, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 60));
  if (f != null) {
    writer.writeString(60, f);
  }
  f = message.getCertificatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.pomerium.dashboard.Settings.Certificate.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeString(10, f);
  }
  f = message.getTimeoutRead();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getTimeoutWrite();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getTimeoutIdle();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeString(14, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeString(15, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 16));
  if (f != null) {
    writer.writeString(16, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 17));
  if (f != null) {
    writer.writeString(17, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 18));
  if (f != null) {
    writer.writeString(18, f);
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 19));
  if (f != null) {
    writer.writeBool(19, f);
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 20));
  if (f != null) {
    writer.writeBool(20, f);
  }
  f = message.getCookieExpire();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 22));
  if (f != null) {
    writer.writeString(22, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 23));
  if (f != null) {
    writer.writeString(23, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 24));
  if (f != null) {
    writer.writeString(24, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 25));
  if (f != null) {
    writer.writeString(25, f);
  }
  f = message.getScopesList();
  if (f.length > 0) {
    writer.writeRepeatedString(26, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 27));
  if (f != null) {
    writer.writeString(27, f);
  }
  f = message.getIdpRefreshDirectoryTimeout();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getIdpRefreshDirectoryInterval();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getRequestParamsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(
      30,
      writer,
      jspb.BinaryWriter.prototype.writeString,
      jspb.BinaryWriter.prototype.writeString
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 32));
  if (f != null) {
    writer.writeString(32, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 34));
  if (f != null) {
    writer.writeString(34, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 35));
  if (f != null) {
    writer.writeString(35, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 64));
  if (f != null) {
    writer.writeString(64, f);
  }
  f = message.getSetResponseHeadersMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(
      67,
      writer,
      jspb.BinaryWriter.prototype.writeString,
      jspb.BinaryWriter.prototype.writeString
    );
  }
  f = message.getJwtClaimsHeadersMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(
      66,
      writer,
      jspb.BinaryWriter.prototype.writeString,
      jspb.BinaryWriter.prototype.writeString
    );
  }
  f = message.getDefaultUpstreamTimeout();
  if (f != null) {
    writer.writeMessage(
      39,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 40));
  if (f != null) {
    writer.writeString(40, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 41));
  if (f != null) {
    writer.writeString(41, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 42));
  if (f != null) {
    writer.writeDouble(42, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 43));
  if (f != null) {
    writer.writeString(43, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 44));
  if (f != null) {
    writer.writeString(44, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 45));
  if (f != null) {
    writer.writeString(45, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 46));
  if (f != null) {
    writer.writeString(46, f);
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 47));
  if (f != null) {
    writer.writeBool(47, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 51));
  if (f != null) {
    writer.writeString(51, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 52));
  if (f != null) {
    writer.writeString(52, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 53));
  if (f != null) {
    writer.writeString(53, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 54));
  if (f != null) {
    writer.writeString(54, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 65));
  if (f != null) {
    writer.writeString(65, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 55));
  if (f != null) {
    writer.writeString(55, f);
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 56));
  if (f != null) {
    writer.writeBool(56, f);
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 57));
  if (f != null) {
    writer.writeBool(57, f);
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 58));
  if (f != null) {
    writer.writeBool(58, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 59));
  if (f != null) {
    writer.writeString(59, f);
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 63));
  if (f != null) {
    writer.writeBool(63, f);
  }
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.Settings.Certificate.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.Settings.Certificate.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.Settings.Certificate} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.Settings.Certificate.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        certBytes: msg.getCertBytes_asB64(),
        keyBytes: msg.getKeyBytes_asB64(),
        keyPairId: jspb.Message.getFieldWithDefault(msg, 5, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.Settings.Certificate}
 */
proto.pomerium.dashboard.Settings.Certificate.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.Settings.Certificate();
  return proto.pomerium.dashboard.Settings.Certificate.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.Settings.Certificate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.Settings.Certificate}
 */
proto.pomerium.dashboard.Settings.Certificate.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 3:
          var value = /** @type {!Uint8Array} */ (reader.readBytes());
          msg.setCertBytes(value);
          break;
        case 4:
          var value = /** @type {!Uint8Array} */ (reader.readBytes());
          msg.setKeyBytes(value);
          break;
        case 5:
          var value = /** @type {string} */ (reader.readString());
          msg.setKeyPairId(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.Settings.Certificate.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.Settings.Certificate.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.Settings.Certificate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.Settings.Certificate.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getCertBytes_asU8();
    if (f.length > 0) {
      writer.writeBytes(3, f);
    }
    f = message.getKeyBytes_asU8();
    if (f.length > 0) {
      writer.writeBytes(4, f);
    }
    f = message.getKeyPairId();
    if (f.length > 0) {
      writer.writeString(5, f);
    }
  };

/**
 * optional bytes cert_bytes = 3;
 * @return {string}
 */
proto.pomerium.dashboard.Settings.Certificate.prototype.getCertBytes =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 3, '')
    );
  };

/**
 * optional bytes cert_bytes = 3;
 * This is a type-conversion wrapper around `getCertBytes()`
 * @return {string}
 */
proto.pomerium.dashboard.Settings.Certificate.prototype.getCertBytes_asB64 =
  function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getCertBytes()));
  };

/**
 * optional bytes cert_bytes = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCertBytes()`
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.Settings.Certificate.prototype.getCertBytes_asU8 =
  function () {
    return /** @type {!Uint8Array} */ (
      jspb.Message.bytesAsU8(this.getCertBytes())
    );
  };

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.pomerium.dashboard.Settings.Certificate} returns this
 */
proto.pomerium.dashboard.Settings.Certificate.prototype.setCertBytes =
  function (value) {
    return jspb.Message.setProto3BytesField(this, 3, value);
  };

/**
 * optional bytes key_bytes = 4;
 * @return {string}
 */
proto.pomerium.dashboard.Settings.Certificate.prototype.getKeyBytes =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 4, '')
    );
  };

/**
 * optional bytes key_bytes = 4;
 * This is a type-conversion wrapper around `getKeyBytes()`
 * @return {string}
 */
proto.pomerium.dashboard.Settings.Certificate.prototype.getKeyBytes_asB64 =
  function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getKeyBytes()));
  };

/**
 * optional bytes key_bytes = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getKeyBytes()`
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.Settings.Certificate.prototype.getKeyBytes_asU8 =
  function () {
    return /** @type {!Uint8Array} */ (
      jspb.Message.bytesAsU8(this.getKeyBytes())
    );
  };

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.pomerium.dashboard.Settings.Certificate} returns this
 */
proto.pomerium.dashboard.Settings.Certificate.prototype.setKeyBytes = function (
  value
) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};

/**
 * optional string key_pair_id = 5;
 * @return {string}
 */
proto.pomerium.dashboard.Settings.Certificate.prototype.getKeyPairId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 5, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Settings.Certificate} returns this
 */
proto.pomerium.dashboard.Settings.Certificate.prototype.setKeyPairId =
  function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
  };

/**
 * optional google.protobuf.Timestamp modified_at = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pomerium.dashboard.Settings.prototype.getModifiedAt = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_timestamp_pb.Timestamp,
      1
    )
  );
};

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setModifiedAt = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearModifiedAt = function () {
  return this.setModifiedAt(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasModifiedAt = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional string installation_id = 68;
 * @return {string}
 */
proto.pomerium.dashboard.Settings.prototype.getInstallationId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 68, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setInstallationId = function (
  value
) {
  return jspb.Message.setField(this, 68, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearInstallationId = function () {
  return jspb.Message.setField(this, 68, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasInstallationId = function () {
  return jspb.Message.getField(this, 68) != null;
};

/**
 * optional bool debug = 2;
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.getDebug = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 2, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setDebug = function (value) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearDebug = function () {
  return jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasDebug = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional string log_level = 3;
 * @return {string}
 */
proto.pomerium.dashboard.Settings.prototype.getLogLevel = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setLogLevel = function (value) {
  return jspb.Message.setField(this, 3, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearLogLevel = function () {
  return jspb.Message.setField(this, 3, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasLogLevel = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional string proxy_log_level = 4;
 * @return {string}
 */
proto.pomerium.dashboard.Settings.prototype.getProxyLogLevel = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setProxyLogLevel = function (
  value
) {
  return jspb.Message.setField(this, 4, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearProxyLogLevel = function () {
  return jspb.Message.setField(this, 4, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasProxyLogLevel = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional string shared_secret = 5;
 * @return {string}
 */
proto.pomerium.dashboard.Settings.prototype.getSharedSecret = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setSharedSecret = function (value) {
  return jspb.Message.setField(this, 5, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearSharedSecret = function () {
  return jspb.Message.setField(this, 5, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasSharedSecret = function () {
  return jspb.Message.getField(this, 5) != null;
};

/**
 * optional string services = 6;
 * @return {string}
 */
proto.pomerium.dashboard.Settings.prototype.getServices = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setServices = function (value) {
  return jspb.Message.setField(this, 6, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearServices = function () {
  return jspb.Message.setField(this, 6, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasServices = function () {
  return jspb.Message.getField(this, 6) != null;
};

/**
 * optional string address = 7;
 * @return {string}
 */
proto.pomerium.dashboard.Settings.prototype.getAddress = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setAddress = function (value) {
  return jspb.Message.setField(this, 7, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearAddress = function () {
  return jspb.Message.setField(this, 7, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasAddress = function () {
  return jspb.Message.getField(this, 7) != null;
};

/**
 * optional bool insecure_server = 8;
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.getInsecureServer = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 8, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setInsecureServer = function (
  value
) {
  return jspb.Message.setField(this, 8, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearInsecureServer = function () {
  return jspb.Message.setField(this, 8, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasInsecureServer = function () {
  return jspb.Message.getField(this, 8) != null;
};

/**
 * optional string dns_lookup_family = 60;
 * @return {string}
 */
proto.pomerium.dashboard.Settings.prototype.getDnsLookupFamily = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 60, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setDnsLookupFamily = function (
  value
) {
  return jspb.Message.setField(this, 60, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearDnsLookupFamily = function () {
  return jspb.Message.setField(this, 60, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasDnsLookupFamily = function () {
  return jspb.Message.getField(this, 60) != null;
};

/**
 * repeated Certificate certificates = 9;
 * @return {!Array<!proto.pomerium.dashboard.Settings.Certificate>}
 */
proto.pomerium.dashboard.Settings.prototype.getCertificatesList = function () {
  return /** @type{!Array<!proto.pomerium.dashboard.Settings.Certificate>} */ (
    jspb.Message.getRepeatedWrapperField(
      this,
      proto.pomerium.dashboard.Settings.Certificate,
      9
    )
  );
};

/**
 * @param {!Array<!proto.pomerium.dashboard.Settings.Certificate>} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setCertificatesList = function (
  value
) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};

/**
 * @param {!proto.pomerium.dashboard.Settings.Certificate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.Settings.Certificate}
 */
proto.pomerium.dashboard.Settings.prototype.addCertificates = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    9,
    opt_value,
    proto.pomerium.dashboard.Settings.Certificate,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearCertificatesList =
  function () {
    return this.setCertificatesList([]);
  };

/**
 * optional string http_redirect_addr = 10;
 * @return {string}
 */
proto.pomerium.dashboard.Settings.prototype.getHttpRedirectAddr = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setHttpRedirectAddr = function (
  value
) {
  return jspb.Message.setField(this, 10, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearHttpRedirectAddr =
  function () {
    return jspb.Message.setField(this, 10, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasHttpRedirectAddr = function () {
  return jspb.Message.getField(this, 10) != null;
};

/**
 * optional google.protobuf.Duration timeout_read = 11;
 * @return {?proto.google.protobuf.Duration}
 */
proto.pomerium.dashboard.Settings.prototype.getTimeoutRead = function () {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 11)
  );
};

/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setTimeoutRead = function (value) {
  return jspb.Message.setWrapperField(this, 11, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearTimeoutRead = function () {
  return this.setTimeoutRead(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasTimeoutRead = function () {
  return jspb.Message.getField(this, 11) != null;
};

/**
 * optional google.protobuf.Duration timeout_write = 12;
 * @return {?proto.google.protobuf.Duration}
 */
proto.pomerium.dashboard.Settings.prototype.getTimeoutWrite = function () {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 12)
  );
};

/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setTimeoutWrite = function (value) {
  return jspb.Message.setWrapperField(this, 12, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearTimeoutWrite = function () {
  return this.setTimeoutWrite(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasTimeoutWrite = function () {
  return jspb.Message.getField(this, 12) != null;
};

/**
 * optional google.protobuf.Duration timeout_idle = 13;
 * @return {?proto.google.protobuf.Duration}
 */
proto.pomerium.dashboard.Settings.prototype.getTimeoutIdle = function () {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 13)
  );
};

/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setTimeoutIdle = function (value) {
  return jspb.Message.setWrapperField(this, 13, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearTimeoutIdle = function () {
  return this.setTimeoutIdle(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasTimeoutIdle = function () {
  return jspb.Message.getField(this, 13) != null;
};

/**
 * optional string authenticate_service_url = 14;
 * @return {string}
 */
proto.pomerium.dashboard.Settings.prototype.getAuthenticateServiceUrl =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 14, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setAuthenticateServiceUrl =
  function (value) {
    return jspb.Message.setField(this, 14, value);
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearAuthenticateServiceUrl =
  function () {
    return jspb.Message.setField(this, 14, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasAuthenticateServiceUrl =
  function () {
    return jspb.Message.getField(this, 14) != null;
  };

/**
 * optional string authenticate_callback_path = 15;
 * @return {string}
 */
proto.pomerium.dashboard.Settings.prototype.getAuthenticateCallbackPath =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 15, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setAuthenticateCallbackPath =
  function (value) {
    return jspb.Message.setField(this, 15, value);
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearAuthenticateCallbackPath =
  function () {
    return jspb.Message.setField(this, 15, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasAuthenticateCallbackPath =
  function () {
    return jspb.Message.getField(this, 15) != null;
  };

/**
 * optional string cookie_name = 16;
 * @return {string}
 */
proto.pomerium.dashboard.Settings.prototype.getCookieName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setCookieName = function (value) {
  return jspb.Message.setField(this, 16, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearCookieName = function () {
  return jspb.Message.setField(this, 16, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasCookieName = function () {
  return jspb.Message.getField(this, 16) != null;
};

/**
 * optional string cookie_secret = 17;
 * @return {string}
 */
proto.pomerium.dashboard.Settings.prototype.getCookieSecret = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setCookieSecret = function (value) {
  return jspb.Message.setField(this, 17, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearCookieSecret = function () {
  return jspb.Message.setField(this, 17, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasCookieSecret = function () {
  return jspb.Message.getField(this, 17) != null;
};

/**
 * optional string cookie_domain = 18;
 * @return {string}
 */
proto.pomerium.dashboard.Settings.prototype.getCookieDomain = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setCookieDomain = function (value) {
  return jspb.Message.setField(this, 18, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearCookieDomain = function () {
  return jspb.Message.setField(this, 18, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasCookieDomain = function () {
  return jspb.Message.getField(this, 18) != null;
};

/**
 * optional bool cookie_secure = 19;
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.getCookieSecure = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 19, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setCookieSecure = function (value) {
  return jspb.Message.setField(this, 19, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearCookieSecure = function () {
  return jspb.Message.setField(this, 19, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasCookieSecure = function () {
  return jspb.Message.getField(this, 19) != null;
};

/**
 * optional bool cookie_http_only = 20;
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.getCookieHttpOnly = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 20, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setCookieHttpOnly = function (
  value
) {
  return jspb.Message.setField(this, 20, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearCookieHttpOnly = function () {
  return jspb.Message.setField(this, 20, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasCookieHttpOnly = function () {
  return jspb.Message.getField(this, 20) != null;
};

/**
 * optional google.protobuf.Duration cookie_expire = 21;
 * @return {?proto.google.protobuf.Duration}
 */
proto.pomerium.dashboard.Settings.prototype.getCookieExpire = function () {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 21)
  );
};

/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setCookieExpire = function (value) {
  return jspb.Message.setWrapperField(this, 21, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearCookieExpire = function () {
  return this.setCookieExpire(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasCookieExpire = function () {
  return jspb.Message.getField(this, 21) != null;
};

/**
 * optional string idp_client_id = 22;
 * @return {string}
 */
proto.pomerium.dashboard.Settings.prototype.getIdpClientId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setIdpClientId = function (value) {
  return jspb.Message.setField(this, 22, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearIdpClientId = function () {
  return jspb.Message.setField(this, 22, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasIdpClientId = function () {
  return jspb.Message.getField(this, 22) != null;
};

/**
 * optional string idp_client_secret = 23;
 * @return {string}
 */
proto.pomerium.dashboard.Settings.prototype.getIdpClientSecret = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setIdpClientSecret = function (
  value
) {
  return jspb.Message.setField(this, 23, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearIdpClientSecret = function () {
  return jspb.Message.setField(this, 23, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasIdpClientSecret = function () {
  return jspb.Message.getField(this, 23) != null;
};

/**
 * optional string idp_provider = 24;
 * @return {string}
 */
proto.pomerium.dashboard.Settings.prototype.getIdpProvider = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setIdpProvider = function (value) {
  return jspb.Message.setField(this, 24, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearIdpProvider = function () {
  return jspb.Message.setField(this, 24, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasIdpProvider = function () {
  return jspb.Message.getField(this, 24) != null;
};

/**
 * optional string idp_provider_url = 25;
 * @return {string}
 */
proto.pomerium.dashboard.Settings.prototype.getIdpProviderUrl = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setIdpProviderUrl = function (
  value
) {
  return jspb.Message.setField(this, 25, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearIdpProviderUrl = function () {
  return jspb.Message.setField(this, 25, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasIdpProviderUrl = function () {
  return jspb.Message.getField(this, 25) != null;
};

/**
 * repeated string scopes = 26;
 * @return {!Array<string>}
 */
proto.pomerium.dashboard.Settings.prototype.getScopesList = function () {
  return /** @type {!Array<string>} */ (
    jspb.Message.getRepeatedField(this, 26)
  );
};

/**
 * @param {!Array<string>} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setScopesList = function (value) {
  return jspb.Message.setField(this, 26, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.addScopes = function (
  value,
  opt_index
) {
  return jspb.Message.addToRepeatedField(this, 26, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearScopesList = function () {
  return this.setScopesList([]);
};

/**
 * optional string idp_service_account = 27;
 * @return {string}
 */
proto.pomerium.dashboard.Settings.prototype.getIdpServiceAccount = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setIdpServiceAccount = function (
  value
) {
  return jspb.Message.setField(this, 27, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearIdpServiceAccount =
  function () {
    return jspb.Message.setField(this, 27, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasIdpServiceAccount = function () {
  return jspb.Message.getField(this, 27) != null;
};

/**
 * optional google.protobuf.Duration idp_refresh_directory_timeout = 28;
 * @return {?proto.google.protobuf.Duration}
 */
proto.pomerium.dashboard.Settings.prototype.getIdpRefreshDirectoryTimeout =
  function () {
    return /** @type{?proto.google.protobuf.Duration} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_duration_pb.Duration,
        28
      )
    );
  };

/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setIdpRefreshDirectoryTimeout =
  function (value) {
    return jspb.Message.setWrapperField(this, 28, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearIdpRefreshDirectoryTimeout =
  function () {
    return this.setIdpRefreshDirectoryTimeout(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasIdpRefreshDirectoryTimeout =
  function () {
    return jspb.Message.getField(this, 28) != null;
  };

/**
 * optional google.protobuf.Duration idp_refresh_directory_interval = 29;
 * @return {?proto.google.protobuf.Duration}
 */
proto.pomerium.dashboard.Settings.prototype.getIdpRefreshDirectoryInterval =
  function () {
    return /** @type{?proto.google.protobuf.Duration} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_duration_pb.Duration,
        29
      )
    );
  };

/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setIdpRefreshDirectoryInterval =
  function (value) {
    return jspb.Message.setWrapperField(this, 29, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearIdpRefreshDirectoryInterval =
  function () {
    return this.setIdpRefreshDirectoryInterval(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasIdpRefreshDirectoryInterval =
  function () {
    return jspb.Message.getField(this, 29) != null;
  };

/**
 * map<string, string> request_params = 30;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.pomerium.dashboard.Settings.prototype.getRequestParamsMap = function (
  opt_noLazyCreate
) {
  return /** @type {!jspb.Map<string,string>} */ (
    jspb.Message.getMapField(this, 30, opt_noLazyCreate, null)
  );
};

/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearRequestParamsMap =
  function () {
    this.getRequestParamsMap().clear();
    return this;
  };

/**
 * optional string authorize_service_url = 32;
 * @return {string}
 */
proto.pomerium.dashboard.Settings.prototype.getAuthorizeServiceUrl =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 32, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setAuthorizeServiceUrl = function (
  value
) {
  return jspb.Message.setField(this, 32, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearAuthorizeServiceUrl =
  function () {
    return jspb.Message.setField(this, 32, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasAuthorizeServiceUrl =
  function () {
    return jspb.Message.getField(this, 32) != null;
  };

/**
 * optional string certificate_authority = 34;
 * @return {string}
 */
proto.pomerium.dashboard.Settings.prototype.getCertificateAuthority =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 34, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setCertificateAuthority = function (
  value
) {
  return jspb.Message.setField(this, 34, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearCertificateAuthority =
  function () {
    return jspb.Message.setField(this, 34, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasCertificateAuthority =
  function () {
    return jspb.Message.getField(this, 34) != null;
  };

/**
 * optional string certificate_authority_file = 35;
 * @return {string}
 */
proto.pomerium.dashboard.Settings.prototype.getCertificateAuthorityFile =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 35, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setCertificateAuthorityFile =
  function (value) {
    return jspb.Message.setField(this, 35, value);
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearCertificateAuthorityFile =
  function () {
    return jspb.Message.setField(this, 35, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasCertificateAuthorityFile =
  function () {
    return jspb.Message.getField(this, 35) != null;
  };

/**
 * optional string certificate_authority_key_pair_id = 64;
 * @return {string}
 */
proto.pomerium.dashboard.Settings.prototype.getCertificateAuthorityKeyPairId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 64, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setCertificateAuthorityKeyPairId =
  function (value) {
    return jspb.Message.setField(this, 64, value);
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearCertificateAuthorityKeyPairId =
  function () {
    return jspb.Message.setField(this, 64, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasCertificateAuthorityKeyPairId =
  function () {
    return jspb.Message.getField(this, 64) != null;
  };

/**
 * map<string, string> set_response_headers = 67;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.pomerium.dashboard.Settings.prototype.getSetResponseHeadersMap =
  function (opt_noLazyCreate) {
    return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 67, opt_noLazyCreate, null)
    );
  };

/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearSetResponseHeadersMap =
  function () {
    this.getSetResponseHeadersMap().clear();
    return this;
  };

/**
 * map<string, string> jwt_claims_headers = 66;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.pomerium.dashboard.Settings.prototype.getJwtClaimsHeadersMap = function (
  opt_noLazyCreate
) {
  return /** @type {!jspb.Map<string,string>} */ (
    jspb.Message.getMapField(this, 66, opt_noLazyCreate, null)
  );
};

/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearJwtClaimsHeadersMap =
  function () {
    this.getJwtClaimsHeadersMap().clear();
    return this;
  };

/**
 * optional google.protobuf.Duration default_upstream_timeout = 39;
 * @return {?proto.google.protobuf.Duration}
 */
proto.pomerium.dashboard.Settings.prototype.getDefaultUpstreamTimeout =
  function () {
    return /** @type{?proto.google.protobuf.Duration} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_duration_pb.Duration,
        39
      )
    );
  };

/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setDefaultUpstreamTimeout =
  function (value) {
    return jspb.Message.setWrapperField(this, 39, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearDefaultUpstreamTimeout =
  function () {
    return this.setDefaultUpstreamTimeout(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasDefaultUpstreamTimeout =
  function () {
    return jspb.Message.getField(this, 39) != null;
  };

/**
 * optional string metrics_address = 40;
 * @return {string}
 */
proto.pomerium.dashboard.Settings.prototype.getMetricsAddress = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setMetricsAddress = function (
  value
) {
  return jspb.Message.setField(this, 40, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearMetricsAddress = function () {
  return jspb.Message.setField(this, 40, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasMetricsAddress = function () {
  return jspb.Message.getField(this, 40) != null;
};

/**
 * optional string tracing_provider = 41;
 * @return {string}
 */
proto.pomerium.dashboard.Settings.prototype.getTracingProvider = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 41, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setTracingProvider = function (
  value
) {
  return jspb.Message.setField(this, 41, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearTracingProvider = function () {
  return jspb.Message.setField(this, 41, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasTracingProvider = function () {
  return jspb.Message.getField(this, 41) != null;
};

/**
 * optional double tracing_sample_rate = 42;
 * @return {number}
 */
proto.pomerium.dashboard.Settings.prototype.getTracingSampleRate = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 42, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setTracingSampleRate = function (
  value
) {
  return jspb.Message.setField(this, 42, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearTracingSampleRate =
  function () {
    return jspb.Message.setField(this, 42, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasTracingSampleRate = function () {
  return jspb.Message.getField(this, 42) != null;
};

/**
 * optional string tracing_jaeger_collector_endpoint = 43;
 * @return {string}
 */
proto.pomerium.dashboard.Settings.prototype.getTracingJaegerCollectorEndpoint =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 43, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setTracingJaegerCollectorEndpoint =
  function (value) {
    return jspb.Message.setField(this, 43, value);
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearTracingJaegerCollectorEndpoint =
  function () {
    return jspb.Message.setField(this, 43, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasTracingJaegerCollectorEndpoint =
  function () {
    return jspb.Message.getField(this, 43) != null;
  };

/**
 * optional string tracing_jaeger_agent_endpoint = 44;
 * @return {string}
 */
proto.pomerium.dashboard.Settings.prototype.getTracingJaegerAgentEndpoint =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 44, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setTracingJaegerAgentEndpoint =
  function (value) {
    return jspb.Message.setField(this, 44, value);
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearTracingJaegerAgentEndpoint =
  function () {
    return jspb.Message.setField(this, 44, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasTracingJaegerAgentEndpoint =
  function () {
    return jspb.Message.getField(this, 44) != null;
  };

/**
 * optional string tracing_zipkin_endpoint = 45;
 * @return {string}
 */
proto.pomerium.dashboard.Settings.prototype.getTracingZipkinEndpoint =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 45, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setTracingZipkinEndpoint =
  function (value) {
    return jspb.Message.setField(this, 45, value);
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearTracingZipkinEndpoint =
  function () {
    return jspb.Message.setField(this, 45, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasTracingZipkinEndpoint =
  function () {
    return jspb.Message.getField(this, 45) != null;
  };

/**
 * optional string grpc_address = 46;
 * @return {string}
 */
proto.pomerium.dashboard.Settings.prototype.getGrpcAddress = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 46, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setGrpcAddress = function (value) {
  return jspb.Message.setField(this, 46, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearGrpcAddress = function () {
  return jspb.Message.setField(this, 46, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasGrpcAddress = function () {
  return jspb.Message.getField(this, 46) != null;
};

/**
 * optional bool grpc_insecure = 47;
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.getGrpcInsecure = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 47, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setGrpcInsecure = function (value) {
  return jspb.Message.setField(this, 47, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearGrpcInsecure = function () {
  return jspb.Message.setField(this, 47, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasGrpcInsecure = function () {
  return jspb.Message.getField(this, 47) != null;
};

/**
 * optional string cache_service_url = 51;
 * @return {string}
 */
proto.pomerium.dashboard.Settings.prototype.getCacheServiceUrl = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 51, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setCacheServiceUrl = function (
  value
) {
  return jspb.Message.setField(this, 51, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearCacheServiceUrl = function () {
  return jspb.Message.setField(this, 51, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasCacheServiceUrl = function () {
  return jspb.Message.getField(this, 51) != null;
};

/**
 * optional string databroker_service_url = 52;
 * @return {string}
 */
proto.pomerium.dashboard.Settings.prototype.getDatabrokerServiceUrl =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 52, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setDatabrokerServiceUrl = function (
  value
) {
  return jspb.Message.setField(this, 52, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearDatabrokerServiceUrl =
  function () {
    return jspb.Message.setField(this, 52, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasDatabrokerServiceUrl =
  function () {
    return jspb.Message.getField(this, 52) != null;
  };

/**
 * optional string client_ca = 53;
 * @return {string}
 */
proto.pomerium.dashboard.Settings.prototype.getClientCa = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 53, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setClientCa = function (value) {
  return jspb.Message.setField(this, 53, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearClientCa = function () {
  return jspb.Message.setField(this, 53, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasClientCa = function () {
  return jspb.Message.getField(this, 53) != null;
};

/**
 * optional string client_ca_file = 54;
 * @return {string}
 */
proto.pomerium.dashboard.Settings.prototype.getClientCaFile = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 54, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setClientCaFile = function (value) {
  return jspb.Message.setField(this, 54, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearClientCaFile = function () {
  return jspb.Message.setField(this, 54, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasClientCaFile = function () {
  return jspb.Message.getField(this, 54) != null;
};

/**
 * optional string client_ca_key_pair_id = 65;
 * @return {string}
 */
proto.pomerium.dashboard.Settings.prototype.getClientCaKeyPairId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 65, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setClientCaKeyPairId = function (
  value
) {
  return jspb.Message.setField(this, 65, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearClientCaKeyPairId =
  function () {
    return jspb.Message.setField(this, 65, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasClientCaKeyPairId = function () {
  return jspb.Message.getField(this, 65) != null;
};

/**
 * optional string google_cloud_serverless_authentication_service_account = 55;
 * @return {string}
 */
proto.pomerium.dashboard.Settings.prototype.getGoogleCloudServerlessAuthenticationServiceAccount =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 55, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setGoogleCloudServerlessAuthenticationServiceAccount =
  function (value) {
    return jspb.Message.setField(this, 55, value);
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearGoogleCloudServerlessAuthenticationServiceAccount =
  function () {
    return jspb.Message.setField(this, 55, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasGoogleCloudServerlessAuthenticationServiceAccount =
  function () {
    return jspb.Message.getField(this, 55) != null;
  };

/**
 * optional bool autocert = 56;
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.getAutocert = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 56, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setAutocert = function (value) {
  return jspb.Message.setField(this, 56, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearAutocert = function () {
  return jspb.Message.setField(this, 56, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasAutocert = function () {
  return jspb.Message.getField(this, 56) != null;
};

/**
 * optional bool autocert_use_staging = 57;
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.getAutocertUseStaging =
  function () {
    return /** @type {boolean} */ (
      jspb.Message.getBooleanFieldWithDefault(this, 57, false)
    );
  };

/**
 * @param {boolean} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setAutocertUseStaging = function (
  value
) {
  return jspb.Message.setField(this, 57, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearAutocertUseStaging =
  function () {
    return jspb.Message.setField(this, 57, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasAutocertUseStaging =
  function () {
    return jspb.Message.getField(this, 57) != null;
  };

/**
 * optional bool autocert_must_staple = 58;
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.getAutocertMustStaple =
  function () {
    return /** @type {boolean} */ (
      jspb.Message.getBooleanFieldWithDefault(this, 58, false)
    );
  };

/**
 * @param {boolean} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setAutocertMustStaple = function (
  value
) {
  return jspb.Message.setField(this, 58, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearAutocertMustStaple =
  function () {
    return jspb.Message.setField(this, 58, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasAutocertMustStaple =
  function () {
    return jspb.Message.getField(this, 58) != null;
  };

/**
 * optional string autocert_dir = 59;
 * @return {string}
 */
proto.pomerium.dashboard.Settings.prototype.getAutocertDir = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 59, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setAutocertDir = function (value) {
  return jspb.Message.setField(this, 59, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearAutocertDir = function () {
  return jspb.Message.setField(this, 59, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasAutocertDir = function () {
  return jspb.Message.getField(this, 59) != null;
};

/**
 * optional bool skip_xff_append = 63;
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.getSkipXffAppend = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 63, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.setSkipXffAppend = function (
  value
) {
  return jspb.Message.setField(this, 63, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Settings} returns this
 */
proto.pomerium.dashboard.Settings.prototype.clearSkipXffAppend = function () {
  return jspb.Message.setField(this, 63, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Settings.prototype.hasSkipXffAppend = function () {
  return jspb.Message.getField(this, 63) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.GetSettingsRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.GetSettingsRequest.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.GetSettingsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.GetSettingsRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {};

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.GetSettingsRequest}
 */
proto.pomerium.dashboard.GetSettingsRequest.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.GetSettingsRequest();
  return proto.pomerium.dashboard.GetSettingsRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.GetSettingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.GetSettingsRequest}
 */
proto.pomerium.dashboard.GetSettingsRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.GetSettingsRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.GetSettingsRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.GetSettingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.GetSettingsRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.GetSettingsResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.GetSettingsResponse.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.GetSettingsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.GetSettingsResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        settings:
          (f = msg.getSettings()) &&
          proto.pomerium.dashboard.Settings.toObject(includeInstance, f)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.GetSettingsResponse}
 */
proto.pomerium.dashboard.GetSettingsResponse.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.GetSettingsResponse();
  return proto.pomerium.dashboard.GetSettingsResponse.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.GetSettingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.GetSettingsResponse}
 */
proto.pomerium.dashboard.GetSettingsResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.Settings();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.Settings.deserializeBinaryFromReader
          );
          msg.setSettings(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.GetSettingsResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.GetSettingsResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.GetSettingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.GetSettingsResponse.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getSettings();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        proto.pomerium.dashboard.Settings.serializeBinaryToWriter
      );
    }
  };

/**
 * optional Settings settings = 1;
 * @return {?proto.pomerium.dashboard.Settings}
 */
proto.pomerium.dashboard.GetSettingsResponse.prototype.getSettings =
  function () {
    return /** @type{?proto.pomerium.dashboard.Settings} */ (
      jspb.Message.getWrapperField(this, proto.pomerium.dashboard.Settings, 1)
    );
  };

/**
 * @param {?proto.pomerium.dashboard.Settings|undefined} value
 * @return {!proto.pomerium.dashboard.GetSettingsResponse} returns this
 */
proto.pomerium.dashboard.GetSettingsResponse.prototype.setSettings = function (
  value
) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.GetSettingsResponse} returns this
 */
proto.pomerium.dashboard.GetSettingsResponse.prototype.clearSettings =
  function () {
    return this.setSettings(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.GetSettingsResponse.prototype.hasSettings =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.SetSettingsRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.SetSettingsRequest.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.SetSettingsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.SetSettingsRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        settings:
          (f = msg.getSettings()) &&
          proto.pomerium.dashboard.Settings.toObject(includeInstance, f)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.SetSettingsRequest}
 */
proto.pomerium.dashboard.SetSettingsRequest.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.SetSettingsRequest();
  return proto.pomerium.dashboard.SetSettingsRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.SetSettingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.SetSettingsRequest}
 */
proto.pomerium.dashboard.SetSettingsRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.Settings();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.Settings.deserializeBinaryFromReader
          );
          msg.setSettings(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.SetSettingsRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.SetSettingsRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.SetSettingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.SetSettingsRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getSettings();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pomerium.dashboard.Settings.serializeBinaryToWriter
    );
  }
};

/**
 * optional Settings settings = 1;
 * @return {?proto.pomerium.dashboard.Settings}
 */
proto.pomerium.dashboard.SetSettingsRequest.prototype.getSettings =
  function () {
    return /** @type{?proto.pomerium.dashboard.Settings} */ (
      jspb.Message.getWrapperField(this, proto.pomerium.dashboard.Settings, 1)
    );
  };

/**
 * @param {?proto.pomerium.dashboard.Settings|undefined} value
 * @return {!proto.pomerium.dashboard.SetSettingsRequest} returns this
 */
proto.pomerium.dashboard.SetSettingsRequest.prototype.setSettings = function (
  value
) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.SetSettingsRequest} returns this
 */
proto.pomerium.dashboard.SetSettingsRequest.prototype.clearSettings =
  function () {
    return this.setSettings(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.SetSettingsRequest.prototype.hasSettings =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.SetSettingsResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.SetSettingsResponse.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.SetSettingsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.SetSettingsResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        settings:
          (f = msg.getSettings()) &&
          proto.pomerium.dashboard.Settings.toObject(includeInstance, f)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.SetSettingsResponse}
 */
proto.pomerium.dashboard.SetSettingsResponse.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.SetSettingsResponse();
  return proto.pomerium.dashboard.SetSettingsResponse.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.SetSettingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.SetSettingsResponse}
 */
proto.pomerium.dashboard.SetSettingsResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.Settings();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.Settings.deserializeBinaryFromReader
          );
          msg.setSettings(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.SetSettingsResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.SetSettingsResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.SetSettingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.SetSettingsResponse.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getSettings();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        proto.pomerium.dashboard.Settings.serializeBinaryToWriter
      );
    }
  };

/**
 * optional Settings settings = 1;
 * @return {?proto.pomerium.dashboard.Settings}
 */
proto.pomerium.dashboard.SetSettingsResponse.prototype.getSettings =
  function () {
    return /** @type{?proto.pomerium.dashboard.Settings} */ (
      jspb.Message.getWrapperField(this, proto.pomerium.dashboard.Settings, 1)
    );
  };

/**
 * @param {?proto.pomerium.dashboard.Settings|undefined} value
 * @return {!proto.pomerium.dashboard.SetSettingsResponse} returns this
 */
proto.pomerium.dashboard.SetSettingsResponse.prototype.setSettings = function (
  value
) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.SetSettingsResponse} returns this
 */
proto.pomerium.dashboard.SetSettingsResponse.prototype.clearSettings =
  function () {
    return this.setSettings(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.SetSettingsResponse.prototype.hasSettings =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

goog.object.extend(exports, proto.pomerium.dashboard);
