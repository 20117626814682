// source: envoy/config/core/v3/protocol.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var envoy_type_v3_percent_pb = require('../../../../envoy/type/v3/percent_pb.js');
goog.object.extend(proto, envoy_type_v3_percent_pb);
var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js');
goog.object.extend(proto, google_protobuf_duration_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var udpa_annotations_status_pb = require('../../../../udpa/annotations/status_pb.js');
goog.object.extend(proto, udpa_annotations_status_pb);
var udpa_annotations_versioning_pb = require('../../../../udpa/annotations/versioning_pb.js');
goog.object.extend(proto, udpa_annotations_versioning_pb);
var validate_validate_pb = require('../../../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol(
  'proto.envoy.config.core.v3.GrpcProtocolOptions',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.core.v3.Http1ProtocolOptions',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.HeaderFormatCase',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.ProperCaseWords',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.core.v3.Http2ProtocolOptions',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.core.v3.HttpProtocolOptions',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.core.v3.HttpProtocolOptions.HeadersWithUnderscoresAction',
  null,
  global
);
goog.exportSymbol('proto.envoy.config.core.v3.KeepaliveSettings', null, global);
goog.exportSymbol(
  'proto.envoy.config.core.v3.TcpProtocolOptions',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.core.v3.UpstreamHttpProtocolOptions',
  null,
  global
);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.TcpProtocolOptions = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.envoy.config.core.v3.TcpProtocolOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.TcpProtocolOptions.displayName =
    'proto.envoy.config.core.v3.TcpProtocolOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.UpstreamHttpProtocolOptions = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(
  proto.envoy.config.core.v3.UpstreamHttpProtocolOptions,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.UpstreamHttpProtocolOptions.displayName =
    'proto.envoy.config.core.v3.UpstreamHttpProtocolOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.HttpProtocolOptions = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.envoy.config.core.v3.HttpProtocolOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.HttpProtocolOptions.displayName =
    'proto.envoy.config.core.v3.HttpProtocolOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.Http1ProtocolOptions = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.envoy.config.core.v3.Http1ProtocolOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.Http1ProtocolOptions.displayName =
    'proto.envoy.config.core.v3.Http1ProtocolOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat = function (
  opt_data
) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    null,
    proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.oneofGroups_
  );
};
goog.inherits(
  proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.displayName =
    'proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.ProperCaseWords =
  function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  };
goog.inherits(
  proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat
    .ProperCaseWords,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.ProperCaseWords.displayName =
    'proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.ProperCaseWords';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.KeepaliveSettings = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.envoy.config.core.v3.KeepaliveSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.KeepaliveSettings.displayName =
    'proto.envoy.config.core.v3.KeepaliveSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.Http2ProtocolOptions = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.envoy.config.core.v3.Http2ProtocolOptions.repeatedFields_,
    null
  );
};
goog.inherits(proto.envoy.config.core.v3.Http2ProtocolOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.Http2ProtocolOptions.displayName =
    'proto.envoy.config.core.v3.Http2ProtocolOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter = function (
  opt_data
) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(
  proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter.displayName =
    'proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.GrpcProtocolOptions = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.envoy.config.core.v3.GrpcProtocolOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.GrpcProtocolOptions.displayName =
    'proto.envoy.config.core.v3.GrpcProtocolOptions';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.TcpProtocolOptions.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.config.core.v3.TcpProtocolOptions.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.TcpProtocolOptions} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.TcpProtocolOptions.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {};

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.TcpProtocolOptions}
 */
proto.envoy.config.core.v3.TcpProtocolOptions.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.TcpProtocolOptions();
  return proto.envoy.config.core.v3.TcpProtocolOptions.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.TcpProtocolOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.TcpProtocolOptions}
 */
proto.envoy.config.core.v3.TcpProtocolOptions.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.TcpProtocolOptions.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.TcpProtocolOptions.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.TcpProtocolOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.TcpProtocolOptions.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.UpstreamHttpProtocolOptions.prototype.toObject =
    function (opt_includeInstance) {
      return proto.envoy.config.core.v3.UpstreamHttpProtocolOptions.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.UpstreamHttpProtocolOptions} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.UpstreamHttpProtocolOptions.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        autoSni: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
        autoSanValidation: jspb.Message.getBooleanFieldWithDefault(
          msg,
          2,
          false
        )
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.UpstreamHttpProtocolOptions}
 */
proto.envoy.config.core.v3.UpstreamHttpProtocolOptions.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.envoy.config.core.v3.UpstreamHttpProtocolOptions();
    return proto.envoy.config.core.v3.UpstreamHttpProtocolOptions.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.UpstreamHttpProtocolOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.UpstreamHttpProtocolOptions}
 */
proto.envoy.config.core.v3.UpstreamHttpProtocolOptions.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {boolean} */ (reader.readBool());
          msg.setAutoSni(value);
          break;
        case 2:
          var value = /** @type {boolean} */ (reader.readBool());
          msg.setAutoSanValidation(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.UpstreamHttpProtocolOptions.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.UpstreamHttpProtocolOptions.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.UpstreamHttpProtocolOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.UpstreamHttpProtocolOptions.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getAutoSni();
    if (f) {
      writer.writeBool(1, f);
    }
    f = message.getAutoSanValidation();
    if (f) {
      writer.writeBool(2, f);
    }
  };

/**
 * optional bool auto_sni = 1;
 * @return {boolean}
 */
proto.envoy.config.core.v3.UpstreamHttpProtocolOptions.prototype.getAutoSni =
  function () {
    return /** @type {boolean} */ (
      jspb.Message.getBooleanFieldWithDefault(this, 1, false)
    );
  };

/**
 * @param {boolean} value
 * @return {!proto.envoy.config.core.v3.UpstreamHttpProtocolOptions} returns this
 */
proto.envoy.config.core.v3.UpstreamHttpProtocolOptions.prototype.setAutoSni =
  function (value) {
    return jspb.Message.setProto3BooleanField(this, 1, value);
  };

/**
 * optional bool auto_san_validation = 2;
 * @return {boolean}
 */
proto.envoy.config.core.v3.UpstreamHttpProtocolOptions.prototype.getAutoSanValidation =
  function () {
    return /** @type {boolean} */ (
      jspb.Message.getBooleanFieldWithDefault(this, 2, false)
    );
  };

/**
 * @param {boolean} value
 * @return {!proto.envoy.config.core.v3.UpstreamHttpProtocolOptions} returns this
 */
proto.envoy.config.core.v3.UpstreamHttpProtocolOptions.prototype.setAutoSanValidation =
  function (value) {
    return jspb.Message.setProto3BooleanField(this, 2, value);
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.HttpProtocolOptions.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.config.core.v3.HttpProtocolOptions.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.HttpProtocolOptions} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.HttpProtocolOptions.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        idleTimeout:
          (f = msg.getIdleTimeout()) &&
          google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
        maxConnectionDuration:
          (f = msg.getMaxConnectionDuration()) &&
          google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
        maxHeadersCount:
          (f = msg.getMaxHeadersCount()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
        maxStreamDuration:
          (f = msg.getMaxStreamDuration()) &&
          google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
        headersWithUnderscoresAction: jspb.Message.getFieldWithDefault(
          msg,
          5,
          0
        )
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.HttpProtocolOptions}
 */
proto.envoy.config.core.v3.HttpProtocolOptions.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.HttpProtocolOptions();
  return proto.envoy.config.core.v3.HttpProtocolOptions.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.HttpProtocolOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.HttpProtocolOptions}
 */
proto.envoy.config.core.v3.HttpProtocolOptions.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new google_protobuf_duration_pb.Duration();
          reader.readMessage(
            value,
            google_protobuf_duration_pb.Duration.deserializeBinaryFromReader
          );
          msg.setIdleTimeout(value);
          break;
        case 3:
          var value = new google_protobuf_duration_pb.Duration();
          reader.readMessage(
            value,
            google_protobuf_duration_pb.Duration.deserializeBinaryFromReader
          );
          msg.setMaxConnectionDuration(value);
          break;
        case 2:
          var value = new google_protobuf_wrappers_pb.UInt32Value();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
          );
          msg.setMaxHeadersCount(value);
          break;
        case 4:
          var value = new google_protobuf_duration_pb.Duration();
          reader.readMessage(
            value,
            google_protobuf_duration_pb.Duration.deserializeBinaryFromReader
          );
          msg.setMaxStreamDuration(value);
          break;
        case 5:
          var value =
            /** @type {!proto.envoy.config.core.v3.HttpProtocolOptions.HeadersWithUnderscoresAction} */ (
              reader.readEnum()
            );
          msg.setHeadersWithUnderscoresAction(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.HttpProtocolOptions.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.HttpProtocolOptions.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.HttpProtocolOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.HttpProtocolOptions.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getIdleTimeout();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        google_protobuf_duration_pb.Duration.serializeBinaryToWriter
      );
    }
    f = message.getMaxConnectionDuration();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        google_protobuf_duration_pb.Duration.serializeBinaryToWriter
      );
    }
    f = message.getMaxHeadersCount();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
      );
    }
    f = message.getMaxStreamDuration();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        google_protobuf_duration_pb.Duration.serializeBinaryToWriter
      );
    }
    f = message.getHeadersWithUnderscoresAction();
    if (f !== 0.0) {
      writer.writeEnum(5, f);
    }
  };

/**
 * @enum {number}
 */
proto.envoy.config.core.v3.HttpProtocolOptions.HeadersWithUnderscoresAction = {
  ALLOW: 0,
  REJECT_REQUEST: 1,
  DROP_HEADER: 2
};

/**
 * optional google.protobuf.Duration idle_timeout = 1;
 * @return {?proto.google.protobuf.Duration}
 */
proto.envoy.config.core.v3.HttpProtocolOptions.prototype.getIdleTimeout =
  function () {
    return /** @type{?proto.google.protobuf.Duration} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_duration_pb.Duration,
        1
      )
    );
  };

/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.envoy.config.core.v3.HttpProtocolOptions} returns this
 */
proto.envoy.config.core.v3.HttpProtocolOptions.prototype.setIdleTimeout =
  function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.HttpProtocolOptions} returns this
 */
proto.envoy.config.core.v3.HttpProtocolOptions.prototype.clearIdleTimeout =
  function () {
    return this.setIdleTimeout(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.HttpProtocolOptions.prototype.hasIdleTimeout =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

/**
 * optional google.protobuf.Duration max_connection_duration = 3;
 * @return {?proto.google.protobuf.Duration}
 */
proto.envoy.config.core.v3.HttpProtocolOptions.prototype.getMaxConnectionDuration =
  function () {
    return /** @type{?proto.google.protobuf.Duration} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_duration_pb.Duration,
        3
      )
    );
  };

/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.envoy.config.core.v3.HttpProtocolOptions} returns this
 */
proto.envoy.config.core.v3.HttpProtocolOptions.prototype.setMaxConnectionDuration =
  function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.HttpProtocolOptions} returns this
 */
proto.envoy.config.core.v3.HttpProtocolOptions.prototype.clearMaxConnectionDuration =
  function () {
    return this.setMaxConnectionDuration(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.HttpProtocolOptions.prototype.hasMaxConnectionDuration =
  function () {
    return jspb.Message.getField(this, 3) != null;
  };

/**
 * optional google.protobuf.UInt32Value max_headers_count = 2;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.core.v3.HttpProtocolOptions.prototype.getMaxHeadersCount =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        2
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.core.v3.HttpProtocolOptions} returns this
 */
proto.envoy.config.core.v3.HttpProtocolOptions.prototype.setMaxHeadersCount =
  function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.HttpProtocolOptions} returns this
 */
proto.envoy.config.core.v3.HttpProtocolOptions.prototype.clearMaxHeadersCount =
  function () {
    return this.setMaxHeadersCount(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.HttpProtocolOptions.prototype.hasMaxHeadersCount =
  function () {
    return jspb.Message.getField(this, 2) != null;
  };

/**
 * optional google.protobuf.Duration max_stream_duration = 4;
 * @return {?proto.google.protobuf.Duration}
 */
proto.envoy.config.core.v3.HttpProtocolOptions.prototype.getMaxStreamDuration =
  function () {
    return /** @type{?proto.google.protobuf.Duration} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_duration_pb.Duration,
        4
      )
    );
  };

/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.envoy.config.core.v3.HttpProtocolOptions} returns this
 */
proto.envoy.config.core.v3.HttpProtocolOptions.prototype.setMaxStreamDuration =
  function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.HttpProtocolOptions} returns this
 */
proto.envoy.config.core.v3.HttpProtocolOptions.prototype.clearMaxStreamDuration =
  function () {
    return this.setMaxStreamDuration(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.HttpProtocolOptions.prototype.hasMaxStreamDuration =
  function () {
    return jspb.Message.getField(this, 4) != null;
  };

/**
 * optional HeadersWithUnderscoresAction headers_with_underscores_action = 5;
 * @return {!proto.envoy.config.core.v3.HttpProtocolOptions.HeadersWithUnderscoresAction}
 */
proto.envoy.config.core.v3.HttpProtocolOptions.prototype.getHeadersWithUnderscoresAction =
  function () {
    return /** @type {!proto.envoy.config.core.v3.HttpProtocolOptions.HeadersWithUnderscoresAction} */ (
      jspb.Message.getFieldWithDefault(this, 5, 0)
    );
  };

/**
 * @param {!proto.envoy.config.core.v3.HttpProtocolOptions.HeadersWithUnderscoresAction} value
 * @return {!proto.envoy.config.core.v3.HttpProtocolOptions} returns this
 */
proto.envoy.config.core.v3.HttpProtocolOptions.prototype.setHeadersWithUnderscoresAction =
  function (value) {
    return jspb.Message.setProto3EnumField(this, 5, value);
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.Http1ProtocolOptions.prototype.toObject =
    function (opt_includeInstance) {
      return proto.envoy.config.core.v3.Http1ProtocolOptions.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.Http1ProtocolOptions} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.Http1ProtocolOptions.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        allowAbsoluteUrl:
          (f = msg.getAllowAbsoluteUrl()) &&
          google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
        acceptHttp10: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
        defaultHostForHttp10: jspb.Message.getFieldWithDefault(msg, 3, ''),
        headerKeyFormat:
          (f = msg.getHeaderKeyFormat()) &&
          proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.toObject(
            includeInstance,
            f
          ),
        enableTrailers: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
        allowChunkedLength: jspb.Message.getBooleanFieldWithDefault(
          msg,
          6,
          false
        ),
        overrideStreamErrorOnInvalidHttpMessage:
          (f = msg.getOverrideStreamErrorOnInvalidHttpMessage()) &&
          google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.Http1ProtocolOptions}
 */
proto.envoy.config.core.v3.Http1ProtocolOptions.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.Http1ProtocolOptions();
  return proto.envoy.config.core.v3.Http1ProtocolOptions.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.Http1ProtocolOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.Http1ProtocolOptions}
 */
proto.envoy.config.core.v3.Http1ProtocolOptions.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new google_protobuf_wrappers_pb.BoolValue();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader
          );
          msg.setAllowAbsoluteUrl(value);
          break;
        case 2:
          var value = /** @type {boolean} */ (reader.readBool());
          msg.setAcceptHttp10(value);
          break;
        case 3:
          var value = /** @type {string} */ (reader.readString());
          msg.setDefaultHostForHttp10(value);
          break;
        case 4:
          var value =
            new proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat();
          reader.readMessage(
            value,
            proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat
              .deserializeBinaryFromReader
          );
          msg.setHeaderKeyFormat(value);
          break;
        case 5:
          var value = /** @type {boolean} */ (reader.readBool());
          msg.setEnableTrailers(value);
          break;
        case 6:
          var value = /** @type {boolean} */ (reader.readBool());
          msg.setAllowChunkedLength(value);
          break;
        case 7:
          var value = new google_protobuf_wrappers_pb.BoolValue();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader
          );
          msg.setOverrideStreamErrorOnInvalidHttpMessage(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.Http1ProtocolOptions.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.Http1ProtocolOptions.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.Http1ProtocolOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.Http1ProtocolOptions.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getAllowAbsoluteUrl();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
      );
    }
    f = message.getAcceptHttp10();
    if (f) {
      writer.writeBool(2, f);
    }
    f = message.getDefaultHostForHttp10();
    if (f.length > 0) {
      writer.writeString(3, f);
    }
    f = message.getHeaderKeyFormat();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat
          .serializeBinaryToWriter
      );
    }
    f = message.getEnableTrailers();
    if (f) {
      writer.writeBool(5, f);
    }
    f = message.getAllowChunkedLength();
    if (f) {
      writer.writeBool(6, f);
    }
    f = message.getOverrideStreamErrorOnInvalidHttpMessage();
    if (f != null) {
      writer.writeMessage(
        7,
        f,
        google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
      );
    }
  };

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.oneofGroups_ = [
  [1]
];

/**
 * @enum {number}
 */
proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.HeaderFormatCase =
  {
    HEADER_FORMAT_NOT_SET: 0,
    PROPER_CASE_WORDS: 1
  };

/**
 * @return {proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.HeaderFormatCase}
 */
proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.prototype.getHeaderFormatCase =
  function () {
    return /** @type {proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.HeaderFormatCase} */ (
      jspb.Message.computeOneofCase(
        this,
        proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat
          .oneofGroups_[0]
      )
    );
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.prototype.toObject =
    function (opt_includeInstance) {
      return proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.toObject =
    function (includeInstance, msg) {
      var f,
        obj = {
          properCaseWords:
            (f = msg.getProperCaseWords()) &&
            proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.ProperCaseWords.toObject(
              includeInstance,
              f
            )
        };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }
      return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat}
 */
proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg =
      new proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat();
    return proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat}
 */
proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value =
            new proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.ProperCaseWords();
          reader.readMessage(
            value,
            proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat
              .ProperCaseWords.deserializeBinaryFromReader
          );
          msg.setProperCaseWords(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getProperCaseWords();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat
          .ProperCaseWords.serializeBinaryToWriter
      );
    }
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.ProperCaseWords.prototype.toObject =
    function (opt_includeInstance) {
      return proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.ProperCaseWords.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.ProperCaseWords} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.ProperCaseWords.toObject =
    function (includeInstance, msg) {
      var f,
        obj = {};

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }
      return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.ProperCaseWords}
 */
proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.ProperCaseWords.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg =
      new proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.ProperCaseWords();
    return proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.ProperCaseWords.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.ProperCaseWords} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.ProperCaseWords}
 */
proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.ProperCaseWords.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.ProperCaseWords.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.ProperCaseWords.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.ProperCaseWords} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.ProperCaseWords.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
  };

/**
 * optional ProperCaseWords proper_case_words = 1;
 * @return {?proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.ProperCaseWords}
 */
proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.prototype.getProperCaseWords =
  function () {
    return /** @type{?proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.ProperCaseWords} */ (
      jspb.Message.getWrapperField(
        this,
        proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat
          .ProperCaseWords,
        1
      )
    );
  };

/**
 * @param {?proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.ProperCaseWords|undefined} value
 * @return {!proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat} returns this
 */
proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.prototype.setProperCaseWords =
  function (value) {
    return jspb.Message.setOneofWrapperField(
      this,
      1,
      proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat
        .oneofGroups_[0],
      value
    );
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat} returns this
 */
proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.prototype.clearProperCaseWords =
  function () {
    return this.setProperCaseWords(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat.prototype.hasProperCaseWords =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

/**
 * optional google.protobuf.BoolValue allow_absolute_url = 1;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.envoy.config.core.v3.Http1ProtocolOptions.prototype.getAllowAbsoluteUrl =
  function () {
    return /** @type{?proto.google.protobuf.BoolValue} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.BoolValue,
        1
      )
    );
  };

/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.envoy.config.core.v3.Http1ProtocolOptions} returns this
 */
proto.envoy.config.core.v3.Http1ProtocolOptions.prototype.setAllowAbsoluteUrl =
  function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.Http1ProtocolOptions} returns this
 */
proto.envoy.config.core.v3.Http1ProtocolOptions.prototype.clearAllowAbsoluteUrl =
  function () {
    return this.setAllowAbsoluteUrl(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.Http1ProtocolOptions.prototype.hasAllowAbsoluteUrl =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

/**
 * optional bool accept_http_10 = 2;
 * @return {boolean}
 */
proto.envoy.config.core.v3.Http1ProtocolOptions.prototype.getAcceptHttp10 =
  function () {
    return /** @type {boolean} */ (
      jspb.Message.getBooleanFieldWithDefault(this, 2, false)
    );
  };

/**
 * @param {boolean} value
 * @return {!proto.envoy.config.core.v3.Http1ProtocolOptions} returns this
 */
proto.envoy.config.core.v3.Http1ProtocolOptions.prototype.setAcceptHttp10 =
  function (value) {
    return jspb.Message.setProto3BooleanField(this, 2, value);
  };

/**
 * optional string default_host_for_http_10 = 3;
 * @return {string}
 */
proto.envoy.config.core.v3.Http1ProtocolOptions.prototype.getDefaultHostForHttp10 =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 3, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.Http1ProtocolOptions} returns this
 */
proto.envoy.config.core.v3.Http1ProtocolOptions.prototype.setDefaultHostForHttp10 =
  function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
  };

/**
 * optional HeaderKeyFormat header_key_format = 4;
 * @return {?proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat}
 */
proto.envoy.config.core.v3.Http1ProtocolOptions.prototype.getHeaderKeyFormat =
  function () {
    return /** @type{?proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat} */ (
      jspb.Message.getWrapperField(
        this,
        proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat,
        4
      )
    );
  };

/**
 * @param {?proto.envoy.config.core.v3.Http1ProtocolOptions.HeaderKeyFormat|undefined} value
 * @return {!proto.envoy.config.core.v3.Http1ProtocolOptions} returns this
 */
proto.envoy.config.core.v3.Http1ProtocolOptions.prototype.setHeaderKeyFormat =
  function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.Http1ProtocolOptions} returns this
 */
proto.envoy.config.core.v3.Http1ProtocolOptions.prototype.clearHeaderKeyFormat =
  function () {
    return this.setHeaderKeyFormat(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.Http1ProtocolOptions.prototype.hasHeaderKeyFormat =
  function () {
    return jspb.Message.getField(this, 4) != null;
  };

/**
 * optional bool enable_trailers = 5;
 * @return {boolean}
 */
proto.envoy.config.core.v3.Http1ProtocolOptions.prototype.getEnableTrailers =
  function () {
    return /** @type {boolean} */ (
      jspb.Message.getBooleanFieldWithDefault(this, 5, false)
    );
  };

/**
 * @param {boolean} value
 * @return {!proto.envoy.config.core.v3.Http1ProtocolOptions} returns this
 */
proto.envoy.config.core.v3.Http1ProtocolOptions.prototype.setEnableTrailers =
  function (value) {
    return jspb.Message.setProto3BooleanField(this, 5, value);
  };

/**
 * optional bool allow_chunked_length = 6;
 * @return {boolean}
 */
proto.envoy.config.core.v3.Http1ProtocolOptions.prototype.getAllowChunkedLength =
  function () {
    return /** @type {boolean} */ (
      jspb.Message.getBooleanFieldWithDefault(this, 6, false)
    );
  };

/**
 * @param {boolean} value
 * @return {!proto.envoy.config.core.v3.Http1ProtocolOptions} returns this
 */
proto.envoy.config.core.v3.Http1ProtocolOptions.prototype.setAllowChunkedLength =
  function (value) {
    return jspb.Message.setProto3BooleanField(this, 6, value);
  };

/**
 * optional google.protobuf.BoolValue override_stream_error_on_invalid_http_message = 7;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.envoy.config.core.v3.Http1ProtocolOptions.prototype.getOverrideStreamErrorOnInvalidHttpMessage =
  function () {
    return /** @type{?proto.google.protobuf.BoolValue} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.BoolValue,
        7
      )
    );
  };

/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.envoy.config.core.v3.Http1ProtocolOptions} returns this
 */
proto.envoy.config.core.v3.Http1ProtocolOptions.prototype.setOverrideStreamErrorOnInvalidHttpMessage =
  function (value) {
    return jspb.Message.setWrapperField(this, 7, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.Http1ProtocolOptions} returns this
 */
proto.envoy.config.core.v3.Http1ProtocolOptions.prototype.clearOverrideStreamErrorOnInvalidHttpMessage =
  function () {
    return this.setOverrideStreamErrorOnInvalidHttpMessage(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.Http1ProtocolOptions.prototype.hasOverrideStreamErrorOnInvalidHttpMessage =
  function () {
    return jspb.Message.getField(this, 7) != null;
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.KeepaliveSettings.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.config.core.v3.KeepaliveSettings.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.KeepaliveSettings} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.KeepaliveSettings.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        interval:
          (f = msg.getInterval()) &&
          google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
        timeout:
          (f = msg.getTimeout()) &&
          google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
        intervalJitter:
          (f = msg.getIntervalJitter()) &&
          envoy_type_v3_percent_pb.Percent.toObject(includeInstance, f)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.KeepaliveSettings}
 */
proto.envoy.config.core.v3.KeepaliveSettings.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.KeepaliveSettings();
  return proto.envoy.config.core.v3.KeepaliveSettings.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.KeepaliveSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.KeepaliveSettings}
 */
proto.envoy.config.core.v3.KeepaliveSettings.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new google_protobuf_duration_pb.Duration();
          reader.readMessage(
            value,
            google_protobuf_duration_pb.Duration.deserializeBinaryFromReader
          );
          msg.setInterval(value);
          break;
        case 2:
          var value = new google_protobuf_duration_pb.Duration();
          reader.readMessage(
            value,
            google_protobuf_duration_pb.Duration.deserializeBinaryFromReader
          );
          msg.setTimeout(value);
          break;
        case 3:
          var value = new envoy_type_v3_percent_pb.Percent();
          reader.readMessage(
            value,
            envoy_type_v3_percent_pb.Percent.deserializeBinaryFromReader
          );
          msg.setIntervalJitter(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.KeepaliveSettings.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.KeepaliveSettings.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.KeepaliveSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.KeepaliveSettings.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getInterval();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        google_protobuf_duration_pb.Duration.serializeBinaryToWriter
      );
    }
    f = message.getTimeout();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        google_protobuf_duration_pb.Duration.serializeBinaryToWriter
      );
    }
    f = message.getIntervalJitter();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        envoy_type_v3_percent_pb.Percent.serializeBinaryToWriter
      );
    }
  };

/**
 * optional google.protobuf.Duration interval = 1;
 * @return {?proto.google.protobuf.Duration}
 */
proto.envoy.config.core.v3.KeepaliveSettings.prototype.getInterval =
  function () {
    return /** @type{?proto.google.protobuf.Duration} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_duration_pb.Duration,
        1
      )
    );
  };

/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.envoy.config.core.v3.KeepaliveSettings} returns this
 */
proto.envoy.config.core.v3.KeepaliveSettings.prototype.setInterval = function (
  value
) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.KeepaliveSettings} returns this
 */
proto.envoy.config.core.v3.KeepaliveSettings.prototype.clearInterval =
  function () {
    return this.setInterval(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.KeepaliveSettings.prototype.hasInterval =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

/**
 * optional google.protobuf.Duration timeout = 2;
 * @return {?proto.google.protobuf.Duration}
 */
proto.envoy.config.core.v3.KeepaliveSettings.prototype.getTimeout =
  function () {
    return /** @type{?proto.google.protobuf.Duration} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_duration_pb.Duration,
        2
      )
    );
  };

/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.envoy.config.core.v3.KeepaliveSettings} returns this
 */
proto.envoy.config.core.v3.KeepaliveSettings.prototype.setTimeout = function (
  value
) {
  return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.KeepaliveSettings} returns this
 */
proto.envoy.config.core.v3.KeepaliveSettings.prototype.clearTimeout =
  function () {
    return this.setTimeout(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.KeepaliveSettings.prototype.hasTimeout =
  function () {
    return jspb.Message.getField(this, 2) != null;
  };

/**
 * optional envoy.type.v3.Percent interval_jitter = 3;
 * @return {?proto.envoy.type.v3.Percent}
 */
proto.envoy.config.core.v3.KeepaliveSettings.prototype.getIntervalJitter =
  function () {
    return /** @type{?proto.envoy.type.v3.Percent} */ (
      jspb.Message.getWrapperField(this, envoy_type_v3_percent_pb.Percent, 3)
    );
  };

/**
 * @param {?proto.envoy.type.v3.Percent|undefined} value
 * @return {!proto.envoy.config.core.v3.KeepaliveSettings} returns this
 */
proto.envoy.config.core.v3.KeepaliveSettings.prototype.setIntervalJitter =
  function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.KeepaliveSettings} returns this
 */
proto.envoy.config.core.v3.KeepaliveSettings.prototype.clearIntervalJitter =
  function () {
    return this.setIntervalJitter(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.KeepaliveSettings.prototype.hasIntervalJitter =
  function () {
    return jspb.Message.getField(this, 3) != null;
  };

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.repeatedFields_ = [13];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.toObject =
    function (opt_includeInstance) {
      return proto.envoy.config.core.v3.Http2ProtocolOptions.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.Http2ProtocolOptions} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.Http2ProtocolOptions.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        hpackTableSize:
          (f = msg.getHpackTableSize()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
        maxConcurrentStreams:
          (f = msg.getMaxConcurrentStreams()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
        initialStreamWindowSize:
          (f = msg.getInitialStreamWindowSize()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
        initialConnectionWindowSize:
          (f = msg.getInitialConnectionWindowSize()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
        allowConnect: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
        allowMetadata: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
        maxOutboundFrames:
          (f = msg.getMaxOutboundFrames()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
        maxOutboundControlFrames:
          (f = msg.getMaxOutboundControlFrames()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
        maxConsecutiveInboundFramesWithEmptyPayload:
          (f = msg.getMaxConsecutiveInboundFramesWithEmptyPayload()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
        maxInboundPriorityFramesPerStream:
          (f = msg.getMaxInboundPriorityFramesPerStream()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
        maxInboundWindowUpdateFramesPerDataFrameSent:
          (f = msg.getMaxInboundWindowUpdateFramesPerDataFrameSent()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
        streamErrorOnInvalidHttpMessaging:
          jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
        overrideStreamErrorOnInvalidHttpMessage:
          (f = msg.getOverrideStreamErrorOnInvalidHttpMessage()) &&
          google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
        customSettingsParametersList: jspb.Message.toObjectList(
          msg.getCustomSettingsParametersList(),
          proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter
            .toObject,
          includeInstance
        ),
        connectionKeepalive:
          (f = msg.getConnectionKeepalive()) &&
          proto.envoy.config.core.v3.KeepaliveSettings.toObject(
            includeInstance,
            f
          )
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.Http2ProtocolOptions}
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.Http2ProtocolOptions();
  return proto.envoy.config.core.v3.Http2ProtocolOptions.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.Http2ProtocolOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.Http2ProtocolOptions}
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new google_protobuf_wrappers_pb.UInt32Value();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
          );
          msg.setHpackTableSize(value);
          break;
        case 2:
          var value = new google_protobuf_wrappers_pb.UInt32Value();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
          );
          msg.setMaxConcurrentStreams(value);
          break;
        case 3:
          var value = new google_protobuf_wrappers_pb.UInt32Value();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
          );
          msg.setInitialStreamWindowSize(value);
          break;
        case 4:
          var value = new google_protobuf_wrappers_pb.UInt32Value();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
          );
          msg.setInitialConnectionWindowSize(value);
          break;
        case 5:
          var value = /** @type {boolean} */ (reader.readBool());
          msg.setAllowConnect(value);
          break;
        case 6:
          var value = /** @type {boolean} */ (reader.readBool());
          msg.setAllowMetadata(value);
          break;
        case 7:
          var value = new google_protobuf_wrappers_pb.UInt32Value();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
          );
          msg.setMaxOutboundFrames(value);
          break;
        case 8:
          var value = new google_protobuf_wrappers_pb.UInt32Value();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
          );
          msg.setMaxOutboundControlFrames(value);
          break;
        case 9:
          var value = new google_protobuf_wrappers_pb.UInt32Value();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
          );
          msg.setMaxConsecutiveInboundFramesWithEmptyPayload(value);
          break;
        case 10:
          var value = new google_protobuf_wrappers_pb.UInt32Value();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
          );
          msg.setMaxInboundPriorityFramesPerStream(value);
          break;
        case 11:
          var value = new google_protobuf_wrappers_pb.UInt32Value();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
          );
          msg.setMaxInboundWindowUpdateFramesPerDataFrameSent(value);
          break;
        case 12:
          var value = /** @type {boolean} */ (reader.readBool());
          msg.setStreamErrorOnInvalidHttpMessaging(value);
          break;
        case 14:
          var value = new google_protobuf_wrappers_pb.BoolValue();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader
          );
          msg.setOverrideStreamErrorOnInvalidHttpMessage(value);
          break;
        case 13:
          var value =
            new proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter();
          reader.readMessage(
            value,
            proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter
              .deserializeBinaryFromReader
          );
          msg.addCustomSettingsParameters(value);
          break;
        case 15:
          var value = new proto.envoy.config.core.v3.KeepaliveSettings();
          reader.readMessage(
            value,
            proto.envoy.config.core.v3.KeepaliveSettings
              .deserializeBinaryFromReader
          );
          msg.setConnectionKeepalive(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.Http2ProtocolOptions.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.Http2ProtocolOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getHpackTableSize();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
      );
    }
    f = message.getMaxConcurrentStreams();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
      );
    }
    f = message.getInitialStreamWindowSize();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
      );
    }
    f = message.getInitialConnectionWindowSize();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
      );
    }
    f = message.getAllowConnect();
    if (f) {
      writer.writeBool(5, f);
    }
    f = message.getAllowMetadata();
    if (f) {
      writer.writeBool(6, f);
    }
    f = message.getMaxOutboundFrames();
    if (f != null) {
      writer.writeMessage(
        7,
        f,
        google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
      );
    }
    f = message.getMaxOutboundControlFrames();
    if (f != null) {
      writer.writeMessage(
        8,
        f,
        google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
      );
    }
    f = message.getMaxConsecutiveInboundFramesWithEmptyPayload();
    if (f != null) {
      writer.writeMessage(
        9,
        f,
        google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
      );
    }
    f = message.getMaxInboundPriorityFramesPerStream();
    if (f != null) {
      writer.writeMessage(
        10,
        f,
        google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
      );
    }
    f = message.getMaxInboundWindowUpdateFramesPerDataFrameSent();
    if (f != null) {
      writer.writeMessage(
        11,
        f,
        google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
      );
    }
    f = message.getStreamErrorOnInvalidHttpMessaging();
    if (f) {
      writer.writeBool(12, f);
    }
    f = message.getOverrideStreamErrorOnInvalidHttpMessage();
    if (f != null) {
      writer.writeMessage(
        14,
        f,
        google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
      );
    }
    f = message.getCustomSettingsParametersList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        13,
        f,
        proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter
          .serializeBinaryToWriter
      );
    }
    f = message.getConnectionKeepalive();
    if (f != null) {
      writer.writeMessage(
        15,
        f,
        proto.envoy.config.core.v3.KeepaliveSettings.serializeBinaryToWriter
      );
    }
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter.prototype.toObject =
    function (opt_includeInstance) {
      return proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter.toObject =
    function (includeInstance, msg) {
      var f,
        obj = {
          identifier:
            (f = msg.getIdentifier()) &&
            google_protobuf_wrappers_pb.UInt32Value.toObject(
              includeInstance,
              f
            ),
          value:
            (f = msg.getValue()) &&
            google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f)
        };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }
      return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter}
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg =
      new proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter();
    return proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter}
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new google_protobuf_wrappers_pb.UInt32Value();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
          );
          msg.setIdentifier(value);
          break;
        case 2:
          var value = new google_protobuf_wrappers_pb.UInt32Value();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
          );
          msg.setValue(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getIdentifier();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
      );
    }
    f = message.getValue();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
      );
    }
  };

/**
 * optional google.protobuf.UInt32Value identifier = 1;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter.prototype.getIdentifier =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        1
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter} returns this
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter.prototype.setIdentifier =
  function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter} returns this
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter.prototype.clearIdentifier =
  function () {
    return this.setIdentifier(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter.prototype.hasIdentifier =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

/**
 * optional google.protobuf.UInt32Value value = 2;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter.prototype.getValue =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        2
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter} returns this
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter.prototype.setValue =
  function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter} returns this
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter.prototype.clearValue =
  function () {
    return this.setValue(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter.prototype.hasValue =
  function () {
    return jspb.Message.getField(this, 2) != null;
  };

/**
 * optional google.protobuf.UInt32Value hpack_table_size = 1;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.getHpackTableSize =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        1
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.core.v3.Http2ProtocolOptions} returns this
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.setHpackTableSize =
  function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.Http2ProtocolOptions} returns this
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.clearHpackTableSize =
  function () {
    return this.setHpackTableSize(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.hasHpackTableSize =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

/**
 * optional google.protobuf.UInt32Value max_concurrent_streams = 2;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.getMaxConcurrentStreams =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        2
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.core.v3.Http2ProtocolOptions} returns this
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.setMaxConcurrentStreams =
  function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.Http2ProtocolOptions} returns this
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.clearMaxConcurrentStreams =
  function () {
    return this.setMaxConcurrentStreams(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.hasMaxConcurrentStreams =
  function () {
    return jspb.Message.getField(this, 2) != null;
  };

/**
 * optional google.protobuf.UInt32Value initial_stream_window_size = 3;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.getInitialStreamWindowSize =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        3
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.core.v3.Http2ProtocolOptions} returns this
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.setInitialStreamWindowSize =
  function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.Http2ProtocolOptions} returns this
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.clearInitialStreamWindowSize =
  function () {
    return this.setInitialStreamWindowSize(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.hasInitialStreamWindowSize =
  function () {
    return jspb.Message.getField(this, 3) != null;
  };

/**
 * optional google.protobuf.UInt32Value initial_connection_window_size = 4;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.getInitialConnectionWindowSize =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        4
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.core.v3.Http2ProtocolOptions} returns this
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.setInitialConnectionWindowSize =
  function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.Http2ProtocolOptions} returns this
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.clearInitialConnectionWindowSize =
  function () {
    return this.setInitialConnectionWindowSize(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.hasInitialConnectionWindowSize =
  function () {
    return jspb.Message.getField(this, 4) != null;
  };

/**
 * optional bool allow_connect = 5;
 * @return {boolean}
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.getAllowConnect =
  function () {
    return /** @type {boolean} */ (
      jspb.Message.getBooleanFieldWithDefault(this, 5, false)
    );
  };

/**
 * @param {boolean} value
 * @return {!proto.envoy.config.core.v3.Http2ProtocolOptions} returns this
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.setAllowConnect =
  function (value) {
    return jspb.Message.setProto3BooleanField(this, 5, value);
  };

/**
 * optional bool allow_metadata = 6;
 * @return {boolean}
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.getAllowMetadata =
  function () {
    return /** @type {boolean} */ (
      jspb.Message.getBooleanFieldWithDefault(this, 6, false)
    );
  };

/**
 * @param {boolean} value
 * @return {!proto.envoy.config.core.v3.Http2ProtocolOptions} returns this
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.setAllowMetadata =
  function (value) {
    return jspb.Message.setProto3BooleanField(this, 6, value);
  };

/**
 * optional google.protobuf.UInt32Value max_outbound_frames = 7;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.getMaxOutboundFrames =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        7
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.core.v3.Http2ProtocolOptions} returns this
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.setMaxOutboundFrames =
  function (value) {
    return jspb.Message.setWrapperField(this, 7, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.Http2ProtocolOptions} returns this
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.clearMaxOutboundFrames =
  function () {
    return this.setMaxOutboundFrames(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.hasMaxOutboundFrames =
  function () {
    return jspb.Message.getField(this, 7) != null;
  };

/**
 * optional google.protobuf.UInt32Value max_outbound_control_frames = 8;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.getMaxOutboundControlFrames =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        8
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.core.v3.Http2ProtocolOptions} returns this
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.setMaxOutboundControlFrames =
  function (value) {
    return jspb.Message.setWrapperField(this, 8, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.Http2ProtocolOptions} returns this
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.clearMaxOutboundControlFrames =
  function () {
    return this.setMaxOutboundControlFrames(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.hasMaxOutboundControlFrames =
  function () {
    return jspb.Message.getField(this, 8) != null;
  };

/**
 * optional google.protobuf.UInt32Value max_consecutive_inbound_frames_with_empty_payload = 9;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.getMaxConsecutiveInboundFramesWithEmptyPayload =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        9
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.core.v3.Http2ProtocolOptions} returns this
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.setMaxConsecutiveInboundFramesWithEmptyPayload =
  function (value) {
    return jspb.Message.setWrapperField(this, 9, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.Http2ProtocolOptions} returns this
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.clearMaxConsecutiveInboundFramesWithEmptyPayload =
  function () {
    return this.setMaxConsecutiveInboundFramesWithEmptyPayload(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.hasMaxConsecutiveInboundFramesWithEmptyPayload =
  function () {
    return jspb.Message.getField(this, 9) != null;
  };

/**
 * optional google.protobuf.UInt32Value max_inbound_priority_frames_per_stream = 10;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.getMaxInboundPriorityFramesPerStream =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        10
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.core.v3.Http2ProtocolOptions} returns this
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.setMaxInboundPriorityFramesPerStream =
  function (value) {
    return jspb.Message.setWrapperField(this, 10, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.Http2ProtocolOptions} returns this
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.clearMaxInboundPriorityFramesPerStream =
  function () {
    return this.setMaxInboundPriorityFramesPerStream(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.hasMaxInboundPriorityFramesPerStream =
  function () {
    return jspb.Message.getField(this, 10) != null;
  };

/**
 * optional google.protobuf.UInt32Value max_inbound_window_update_frames_per_data_frame_sent = 11;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.getMaxInboundWindowUpdateFramesPerDataFrameSent =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        11
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.core.v3.Http2ProtocolOptions} returns this
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.setMaxInboundWindowUpdateFramesPerDataFrameSent =
  function (value) {
    return jspb.Message.setWrapperField(this, 11, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.Http2ProtocolOptions} returns this
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.clearMaxInboundWindowUpdateFramesPerDataFrameSent =
  function () {
    return this.setMaxInboundWindowUpdateFramesPerDataFrameSent(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.hasMaxInboundWindowUpdateFramesPerDataFrameSent =
  function () {
    return jspb.Message.getField(this, 11) != null;
  };

/**
 * optional bool stream_error_on_invalid_http_messaging = 12;
 * @return {boolean}
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.getStreamErrorOnInvalidHttpMessaging =
  function () {
    return /** @type {boolean} */ (
      jspb.Message.getBooleanFieldWithDefault(this, 12, false)
    );
  };

/**
 * @param {boolean} value
 * @return {!proto.envoy.config.core.v3.Http2ProtocolOptions} returns this
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.setStreamErrorOnInvalidHttpMessaging =
  function (value) {
    return jspb.Message.setProto3BooleanField(this, 12, value);
  };

/**
 * optional google.protobuf.BoolValue override_stream_error_on_invalid_http_message = 14;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.getOverrideStreamErrorOnInvalidHttpMessage =
  function () {
    return /** @type{?proto.google.protobuf.BoolValue} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.BoolValue,
        14
      )
    );
  };

/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.envoy.config.core.v3.Http2ProtocolOptions} returns this
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.setOverrideStreamErrorOnInvalidHttpMessage =
  function (value) {
    return jspb.Message.setWrapperField(this, 14, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.Http2ProtocolOptions} returns this
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.clearOverrideStreamErrorOnInvalidHttpMessage =
  function () {
    return this.setOverrideStreamErrorOnInvalidHttpMessage(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.hasOverrideStreamErrorOnInvalidHttpMessage =
  function () {
    return jspb.Message.getField(this, 14) != null;
  };

/**
 * repeated SettingsParameter custom_settings_parameters = 13;
 * @return {!Array<!proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter>}
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.getCustomSettingsParametersList =
  function () {
    return /** @type{!Array<!proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter>} */ (
      jspb.Message.getRepeatedWrapperField(
        this,
        proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter,
        13
      )
    );
  };

/**
 * @param {!Array<!proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter>} value
 * @return {!proto.envoy.config.core.v3.Http2ProtocolOptions} returns this
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.setCustomSettingsParametersList =
  function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 13, value);
  };

/**
 * @param {!proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter}
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.addCustomSettingsParameters =
  function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(
      this,
      13,
      opt_value,
      proto.envoy.config.core.v3.Http2ProtocolOptions.SettingsParameter,
      opt_index
    );
  };

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.envoy.config.core.v3.Http2ProtocolOptions} returns this
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.clearCustomSettingsParametersList =
  function () {
    return this.setCustomSettingsParametersList([]);
  };

/**
 * optional KeepaliveSettings connection_keepalive = 15;
 * @return {?proto.envoy.config.core.v3.KeepaliveSettings}
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.getConnectionKeepalive =
  function () {
    return /** @type{?proto.envoy.config.core.v3.KeepaliveSettings} */ (
      jspb.Message.getWrapperField(
        this,
        proto.envoy.config.core.v3.KeepaliveSettings,
        15
      )
    );
  };

/**
 * @param {?proto.envoy.config.core.v3.KeepaliveSettings|undefined} value
 * @return {!proto.envoy.config.core.v3.Http2ProtocolOptions} returns this
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.setConnectionKeepalive =
  function (value) {
    return jspb.Message.setWrapperField(this, 15, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.Http2ProtocolOptions} returns this
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.clearConnectionKeepalive =
  function () {
    return this.setConnectionKeepalive(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.Http2ProtocolOptions.prototype.hasConnectionKeepalive =
  function () {
    return jspb.Message.getField(this, 15) != null;
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.GrpcProtocolOptions.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.config.core.v3.GrpcProtocolOptions.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.GrpcProtocolOptions} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.GrpcProtocolOptions.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        http2ProtocolOptions:
          (f = msg.getHttp2ProtocolOptions()) &&
          proto.envoy.config.core.v3.Http2ProtocolOptions.toObject(
            includeInstance,
            f
          )
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.GrpcProtocolOptions}
 */
proto.envoy.config.core.v3.GrpcProtocolOptions.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.GrpcProtocolOptions();
  return proto.envoy.config.core.v3.GrpcProtocolOptions.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.GrpcProtocolOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.GrpcProtocolOptions}
 */
proto.envoy.config.core.v3.GrpcProtocolOptions.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.envoy.config.core.v3.Http2ProtocolOptions();
          reader.readMessage(
            value,
            proto.envoy.config.core.v3.Http2ProtocolOptions
              .deserializeBinaryFromReader
          );
          msg.setHttp2ProtocolOptions(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.GrpcProtocolOptions.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.GrpcProtocolOptions.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.GrpcProtocolOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.GrpcProtocolOptions.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getHttp2ProtocolOptions();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        proto.envoy.config.core.v3.Http2ProtocolOptions.serializeBinaryToWriter
      );
    }
  };

/**
 * optional Http2ProtocolOptions http2_protocol_options = 1;
 * @return {?proto.envoy.config.core.v3.Http2ProtocolOptions}
 */
proto.envoy.config.core.v3.GrpcProtocolOptions.prototype.getHttp2ProtocolOptions =
  function () {
    return /** @type{?proto.envoy.config.core.v3.Http2ProtocolOptions} */ (
      jspb.Message.getWrapperField(
        this,
        proto.envoy.config.core.v3.Http2ProtocolOptions,
        1
      )
    );
  };

/**
 * @param {?proto.envoy.config.core.v3.Http2ProtocolOptions|undefined} value
 * @return {!proto.envoy.config.core.v3.GrpcProtocolOptions} returns this
 */
proto.envoy.config.core.v3.GrpcProtocolOptions.prototype.setHttp2ProtocolOptions =
  function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.GrpcProtocolOptions} returns this
 */
proto.envoy.config.core.v3.GrpcProtocolOptions.prototype.clearHttp2ProtocolOptions =
  function () {
    return this.setHttp2ProtocolOptions(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.GrpcProtocolOptions.prototype.hasHttp2ProtocolOptions =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

goog.object.extend(exports, proto.envoy.config.core.v3);
