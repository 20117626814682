import { Devices } from '@mui/icons-material';
import { ReactNode } from 'react';

import {
  DeploymentHistoryIcon,
  DNSIcon,
  EventsIcon,
  FilledFolderIcon,
  FilledSettingsIcon,
  HTTPIcon,
  HTTPSIcon,
  PolicyIcon,
  SessionIcon,
  TrafficIcon
} from 'src/components/icons';
import featureFlags, {
  LogView,
  ReportsTraffic,
  RuntimeInfo
} from 'src/types/featureFlags';
import { PermissionNumericComparator } from 'src/types/permission';

export interface Item {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: Item[];
  title: string;
  disabled?: boolean;
  beta?: boolean;
}

interface Section {
  items: Item[];
  subheader: string;
}

export const mapPermissionsToSections = (
  permission: PermissionNumericComparator,
  id: string
): Section[] => {
  const out: Section[] = [];

  const reportItems: Item[] = [];
  if (featureFlags.get(ReportsTraffic)) {
    reportItems.push({
      title: `Traffic`,
      icon: TrafficIcon,
      href: `/app/reports/traffic`,
      disabled: permission < PermissionNumericComparator.Viewer
    });
  }
  if (featureFlags.get(RuntimeInfo)) {
    reportItems.push({
      title: `Runtime`,
      icon: DNSIcon,
      href: `/app/reports/runtime`,
      disabled: permission < PermissionNumericComparator.Admin
    });
  }
  reportItems.push({
    title: `Sessions`,
    icon: SessionIcon,
    href: `/app/management/sessions?namespace=${id}`,
    disabled: permission < PermissionNumericComparator.Admin
  });
  if (featureFlags.get(LogView)) {
    reportItems.push({
      title: `Events`,
      icon: EventsIcon,
      href: `/app/reports/events`,
      disabled: permission < PermissionNumericComparator.Admin
    });
  }
  reportItems.push({
    title: `Deployments`,
    icon: DeploymentHistoryIcon,
    href: `/app/management/deployment-history?namespace=${id}`,
    disabled: permission < PermissionNumericComparator.Viewer
  });

  if (reportItems.length > 0) {
    out.push({
      subheader: `Reports`,
      items: reportItems
    });
  }

  out.push({
    subheader: `Manage`,
    items: [
      {
        title: `Routes`,
        icon: HTTPIcon,
        href: `/app/management/routes?namespace=${id}`,
        disabled: permission < PermissionNumericComparator.Viewer
      },
      {
        title: `Policies`,
        icon: PolicyIcon,
        href: `/app/management/policies?namespace=${id}`,
        disabled: permission < PermissionNumericComparator.Viewer
      },
      {
        title: `Certificates`,
        icon: HTTPSIcon,
        href: `/app/management/tls-certs?namespace=${id}`,
        disabled: permission < PermissionNumericComparator.Viewer
      },
      {
        title: 'Devices',
        icon: Devices,
        href: `/app/management/devices`,
        disabled: permission < PermissionNumericComparator.Admin,
        beta: true
      }
    ]
  });

  out.push({
    subheader: 'Configure',
    items: [
      {
        title: `Settings`,
        icon: FilledSettingsIcon,
        href: `/app/management/settings?namespace=${id}`,
        disabled: permission < PermissionNumericComparator.Admin
      },
      {
        title: `Service Accounts`,
        icon: DNSIcon,
        href: `/app/management/service-accounts?namespace=${id}`,
        disabled: permission < PermissionNumericComparator.Admin
      },
      {
        title: 'Namespaces',
        icon: FilledFolderIcon,
        href: `/app/management/namespaces?namespace=${id}`,
        disabled: false
      }
    ]
  });

  return out;
};

export const filterSections = (sections: Section[]): Section[] => {
  sections.map((section: Section) => {
    const enabled_items = section.items.filter(
      (item: Item) => item.disabled == false
    );
    section.items = enabled_items;
    return section;
  });

  const updatedSections = sections.filter(
    (section: Section) => section.items.length > 0
  );

  return updatedSections;
};
