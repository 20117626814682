// source: routes.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js');
goog.object.extend(proto, google_protobuf_duration_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var envoy_config_cluster_v3_cluster_pb = require('./envoy/config/cluster/v3/cluster_pb.js');
goog.object.extend(proto, envoy_config_cluster_v3_cluster_pb);
var envoy_config_route_v3_route_components_pb = require('./envoy/config/route/v3/route_components_pb.js');
goog.object.extend(proto, envoy_config_route_v3_route_components_pb);
var policy_pb = require('./policy_pb.js');
goog.object.extend(proto, policy_pb);
goog.exportSymbol('proto.pomerium.dashboard.DeleteRouteRequest', null, global);
goog.exportSymbol('proto.pomerium.dashboard.DeleteRouteResponse', null, global);
goog.exportSymbol('proto.pomerium.dashboard.GetRouteRequest', null, global);
goog.exportSymbol('proto.pomerium.dashboard.GetRouteResponse', null, global);
goog.exportSymbol('proto.pomerium.dashboard.ListRoutesRequest', null, global);
goog.exportSymbol('proto.pomerium.dashboard.ListRoutesResponse', null, global);
goog.exportSymbol('proto.pomerium.dashboard.LoadRoutesRequest', null, global);
goog.exportSymbol('proto.pomerium.dashboard.LoadRoutesResponse', null, global);
goog.exportSymbol('proto.pomerium.dashboard.MoveRoutesRequest', null, global);
goog.exportSymbol('proto.pomerium.dashboard.MoveRoutesResponse', null, global);
goog.exportSymbol('proto.pomerium.dashboard.Route', null, global);
goog.exportSymbol(
  'proto.pomerium.dashboard.Route.AuthorizationHeaderMode',
  null,
  global
);
goog.exportSymbol('proto.pomerium.dashboard.RouteRewriteHeader', null, global);
goog.exportSymbol(
  'proto.pomerium.dashboard.RouteRewriteHeader.MatcherCase',
  null,
  global
);
goog.exportSymbol('proto.pomerium.dashboard.RouteWithPolicies', null, global);
goog.exportSymbol('proto.pomerium.dashboard.SetRouteRequest', null, global);
goog.exportSymbol('proto.pomerium.dashboard.SetRouteResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.RouteRewriteHeader = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    null,
    proto.pomerium.dashboard.RouteRewriteHeader.oneofGroups_
  );
};
goog.inherits(proto.pomerium.dashboard.RouteRewriteHeader, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.RouteRewriteHeader.displayName =
    'proto.pomerium.dashboard.RouteRewriteHeader';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.Route = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.pomerium.dashboard.Route.repeatedFields_,
    null
  );
};
goog.inherits(proto.pomerium.dashboard.Route, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.Route.displayName = 'proto.pomerium.dashboard.Route';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.RouteWithPolicies = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.pomerium.dashboard.RouteWithPolicies.repeatedFields_,
    null
  );
};
goog.inherits(proto.pomerium.dashboard.RouteWithPolicies, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.RouteWithPolicies.displayName =
    'proto.pomerium.dashboard.RouteWithPolicies';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.DeleteRouteRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.DeleteRouteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.DeleteRouteRequest.displayName =
    'proto.pomerium.dashboard.DeleteRouteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.DeleteRouteResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.DeleteRouteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.DeleteRouteResponse.displayName =
    'proto.pomerium.dashboard.DeleteRouteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.GetRouteRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.GetRouteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.GetRouteRequest.displayName =
    'proto.pomerium.dashboard.GetRouteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.GetRouteResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.GetRouteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.GetRouteResponse.displayName =
    'proto.pomerium.dashboard.GetRouteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.ListRoutesRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.ListRoutesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.ListRoutesRequest.displayName =
    'proto.pomerium.dashboard.ListRoutesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.ListRoutesResponse = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.pomerium.dashboard.ListRoutesResponse.repeatedFields_,
    null
  );
};
goog.inherits(proto.pomerium.dashboard.ListRoutesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.ListRoutesResponse.displayName =
    'proto.pomerium.dashboard.ListRoutesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.LoadRoutesRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.LoadRoutesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.LoadRoutesRequest.displayName =
    'proto.pomerium.dashboard.LoadRoutesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.LoadRoutesResponse = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.pomerium.dashboard.LoadRoutesResponse.repeatedFields_,
    null
  );
};
goog.inherits(proto.pomerium.dashboard.LoadRoutesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.LoadRoutesResponse.displayName =
    'proto.pomerium.dashboard.LoadRoutesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.SetRouteRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.SetRouteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.SetRouteRequest.displayName =
    'proto.pomerium.dashboard.SetRouteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.SetRouteResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.SetRouteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.SetRouteResponse.displayName =
    'proto.pomerium.dashboard.SetRouteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.MoveRoutesRequest = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.pomerium.dashboard.MoveRoutesRequest.repeatedFields_,
    null
  );
};
goog.inherits(proto.pomerium.dashboard.MoveRoutesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.MoveRoutesRequest.displayName =
    'proto.pomerium.dashboard.MoveRoutesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.MoveRoutesResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.MoveRoutesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.MoveRoutesResponse.displayName =
    'proto.pomerium.dashboard.MoveRoutesResponse';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.pomerium.dashboard.RouteRewriteHeader.oneofGroups_ = [[3]];

/**
 * @enum {number}
 */
proto.pomerium.dashboard.RouteRewriteHeader.MatcherCase = {
  MATCHER_NOT_SET: 0,
  PREFIX: 3
};

/**
 * @return {proto.pomerium.dashboard.RouteRewriteHeader.MatcherCase}
 */
proto.pomerium.dashboard.RouteRewriteHeader.prototype.getMatcherCase =
  function () {
    return /** @type {proto.pomerium.dashboard.RouteRewriteHeader.MatcherCase} */ (
      jspb.Message.computeOneofCase(
        this,
        proto.pomerium.dashboard.RouteRewriteHeader.oneofGroups_[0]
      )
    );
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.RouteRewriteHeader.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.RouteRewriteHeader.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.RouteRewriteHeader} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.RouteRewriteHeader.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        header: jspb.Message.getFieldWithDefault(msg, 1, ''),
        prefix: jspb.Message.getFieldWithDefault(msg, 3, ''),
        value: jspb.Message.getFieldWithDefault(msg, 2, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.RouteRewriteHeader}
 */
proto.pomerium.dashboard.RouteRewriteHeader.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.RouteRewriteHeader();
  return proto.pomerium.dashboard.RouteRewriteHeader.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.RouteRewriteHeader} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.RouteRewriteHeader}
 */
proto.pomerium.dashboard.RouteRewriteHeader.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setHeader(value);
          break;
        case 3:
          var value = /** @type {string} */ (reader.readString());
          msg.setPrefix(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setValue(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.RouteRewriteHeader.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.RouteRewriteHeader.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.RouteRewriteHeader} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.RouteRewriteHeader.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getHeader();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(3, f);
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
};

/**
 * optional string header = 1;
 * @return {string}
 */
proto.pomerium.dashboard.RouteRewriteHeader.prototype.getHeader = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.RouteRewriteHeader} returns this
 */
proto.pomerium.dashboard.RouteRewriteHeader.prototype.setHeader = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string prefix = 3;
 * @return {string}
 */
proto.pomerium.dashboard.RouteRewriteHeader.prototype.getPrefix = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.RouteRewriteHeader} returns this
 */
proto.pomerium.dashboard.RouteRewriteHeader.prototype.setPrefix = function (
  value
) {
  return jspb.Message.setOneofField(
    this,
    3,
    proto.pomerium.dashboard.RouteRewriteHeader.oneofGroups_[0],
    value
  );
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.RouteRewriteHeader} returns this
 */
proto.pomerium.dashboard.RouteRewriteHeader.prototype.clearPrefix =
  function () {
    return jspb.Message.setOneofField(
      this,
      3,
      proto.pomerium.dashboard.RouteRewriteHeader.oneofGroups_[0],
      undefined
    );
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.RouteRewriteHeader.prototype.hasPrefix = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional string value = 2;
 * @return {string}
 */
proto.pomerium.dashboard.RouteRewriteHeader.prototype.getValue = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.RouteRewriteHeader} returns this
 */
proto.pomerium.dashboard.RouteRewriteHeader.prototype.setValue = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pomerium.dashboard.Route.repeatedFields_ = [7, 24, 44, 28, 34];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.Route.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.Route.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.Route} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.Route.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, ''),
        namespaceId: jspb.Message.getFieldWithDefault(msg, 29, ''),
        createdAt:
          (f = msg.getCreatedAt()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        modifiedAt:
          (f = msg.getModifiedAt()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        deletedAt:
          (f = msg.getDeletedAt()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        name: jspb.Message.getFieldWithDefault(msg, 5, ''),
        statName: jspb.Message.getFieldWithDefault(msg, 47, ''),
        from: jspb.Message.getFieldWithDefault(msg, 6, ''),
        toList:
          (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
        prefix: jspb.Message.getFieldWithDefault(msg, 8, ''),
        path: jspb.Message.getFieldWithDefault(msg, 9, ''),
        regex: jspb.Message.getFieldWithDefault(msg, 10, ''),
        prefixRewrite: jspb.Message.getFieldWithDefault(msg, 36, ''),
        regexRewritePattern: jspb.Message.getFieldWithDefault(msg, 37, ''),
        regexRewriteSubstitution: jspb.Message.getFieldWithDefault(msg, 38, ''),
        hostRewrite: jspb.Message.getFieldWithDefault(msg, 30, ''),
        hostRewriteHeader: jspb.Message.getFieldWithDefault(msg, 31, ''),
        hostPathRegexRewritePattern: jspb.Message.getFieldWithDefault(
          msg,
          32,
          ''
        ),
        hostPathRegexRewriteSubstitution: jspb.Message.getFieldWithDefault(
          msg,
          33,
          ''
        ),
        regexPriorityOrder: jspb.Message.getFieldWithDefault(msg, 45, 0),
        timeout:
          (f = msg.getTimeout()) &&
          google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
        idleTimeout:
          (f = msg.getIdleTimeout()) &&
          google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
        allowWebsockets: jspb.Message.getBooleanFieldWithDefault(
          msg,
          14,
          false
        ),
        allowSpdy: jspb.Message.getBooleanFieldWithDefault(msg, 49, false),
        tlsSkipVerify: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
        tlsServerName: jspb.Message.getFieldWithDefault(msg, 16, ''),
        tlsCustomCaKeyPairId: jspb.Message.getFieldWithDefault(msg, 41, ''),
        tlsClientKeyPairId: jspb.Message.getFieldWithDefault(msg, 42, ''),
        tlsDownstreamClientCaKeyPairId: jspb.Message.getFieldWithDefault(
          msg,
          43,
          ''
        ),
        setRequestHeadersMap: (f = msg.getSetRequestHeadersMap())
          ? f.toObject(includeInstance, undefined)
          : [],
        removeRequestHeadersList:
          (f = jspb.Message.getRepeatedField(msg, 24)) == null ? undefined : f,
        rewriteResponseHeadersList: jspb.Message.toObjectList(
          msg.getRewriteResponseHeadersList(),
          proto.pomerium.dashboard.RouteRewriteHeader.toObject,
          includeInstance
        ),
        setAuthorizationHeader: jspb.Message.getFieldWithDefault(msg, 50, 0),
        preserveHostHeader: jspb.Message.getBooleanFieldWithDefault(
          msg,
          25,
          false
        ),
        passIdentityHeaders: jspb.Message.getBooleanFieldWithDefault(
          msg,
          26,
          false
        ),
        kubernetesServiceAccountToken: jspb.Message.getFieldWithDefault(
          msg,
          27,
          ''
        ),
        envoyOpts:
          (f = msg.getEnvoyOpts()) &&
          envoy_config_cluster_v3_cluster_pb.Cluster.toObject(
            includeInstance,
            f
          ),
        redirect:
          (f = msg.getRedirect()) &&
          envoy_config_route_v3_route_components_pb.RedirectAction.toObject(
            includeInstance,
            f
          ),
        enableGoogleCloudServerlessAuthentication:
          jspb.Message.getBooleanFieldWithDefault(msg, 46, false),
        policyIdsList:
          (f = jspb.Message.getRepeatedField(msg, 28)) == null ? undefined : f,
        policyNamesList:
          (f = jspb.Message.getRepeatedField(msg, 34)) == null ? undefined : f,
        namespaceName: jspb.Message.getFieldWithDefault(msg, 35, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.Route}
 */
proto.pomerium.dashboard.Route.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.Route();
  return proto.pomerium.dashboard.Route.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.Route} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.Route}
 */
proto.pomerium.dashboard.Route.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 29:
        var value = /** @type {string} */ (reader.readString());
        msg.setNamespaceId(value);
        break;
      case 2:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        );
        msg.setCreatedAt(value);
        break;
      case 3:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        );
        msg.setModifiedAt(value);
        break;
      case 4:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        );
        msg.setDeletedAt(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 47:
        var value = /** @type {string} */ (reader.readString());
        msg.setStatName(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setFrom(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.addTo(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setPrefix(value);
        break;
      case 9:
        var value = /** @type {string} */ (reader.readString());
        msg.setPath(value);
        break;
      case 10:
        var value = /** @type {string} */ (reader.readString());
        msg.setRegex(value);
        break;
      case 36:
        var value = /** @type {string} */ (reader.readString());
        msg.setPrefixRewrite(value);
        break;
      case 37:
        var value = /** @type {string} */ (reader.readString());
        msg.setRegexRewritePattern(value);
        break;
      case 38:
        var value = /** @type {string} */ (reader.readString());
        msg.setRegexRewriteSubstitution(value);
        break;
      case 30:
        var value = /** @type {string} */ (reader.readString());
        msg.setHostRewrite(value);
        break;
      case 31:
        var value = /** @type {string} */ (reader.readString());
        msg.setHostRewriteHeader(value);
        break;
      case 32:
        var value = /** @type {string} */ (reader.readString());
        msg.setHostPathRegexRewritePattern(value);
        break;
      case 33:
        var value = /** @type {string} */ (reader.readString());
        msg.setHostPathRegexRewriteSubstitution(value);
        break;
      case 45:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setRegexPriorityOrder(value);
        break;
      case 13:
        var value = new google_protobuf_duration_pb.Duration();
        reader.readMessage(
          value,
          google_protobuf_duration_pb.Duration.deserializeBinaryFromReader
        );
        msg.setTimeout(value);
        break;
      case 48:
        var value = new google_protobuf_duration_pb.Duration();
        reader.readMessage(
          value,
          google_protobuf_duration_pb.Duration.deserializeBinaryFromReader
        );
        msg.setIdleTimeout(value);
        break;
      case 14:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setAllowWebsockets(value);
        break;
      case 49:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setAllowSpdy(value);
        break;
      case 15:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setTlsSkipVerify(value);
        break;
      case 16:
        var value = /** @type {string} */ (reader.readString());
        msg.setTlsServerName(value);
        break;
      case 41:
        var value = /** @type {string} */ (reader.readString());
        msg.setTlsCustomCaKeyPairId(value);
        break;
      case 42:
        var value = /** @type {string} */ (reader.readString());
        msg.setTlsClientKeyPairId(value);
        break;
      case 43:
        var value = /** @type {string} */ (reader.readString());
        msg.setTlsDownstreamClientCaKeyPairId(value);
        break;
      case 23:
        var value = msg.getSetRequestHeadersMap();
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(
            message,
            reader,
            jspb.BinaryReader.prototype.readString,
            jspb.BinaryReader.prototype.readString,
            null,
            '',
            ''
          );
        });
        break;
      case 24:
        var value = /** @type {string} */ (reader.readString());
        msg.addRemoveRequestHeaders(value);
        break;
      case 44:
        var value = new proto.pomerium.dashboard.RouteRewriteHeader();
        reader.readMessage(
          value,
          proto.pomerium.dashboard.RouteRewriteHeader
            .deserializeBinaryFromReader
        );
        msg.addRewriteResponseHeaders(value);
        break;
      case 50:
        var value =
          /** @type {!proto.pomerium.dashboard.Route.AuthorizationHeaderMode} */ (
            reader.readEnum()
          );
        msg.setSetAuthorizationHeader(value);
        break;
      case 25:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setPreserveHostHeader(value);
        break;
      case 26:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setPassIdentityHeaders(value);
        break;
      case 27:
        var value = /** @type {string} */ (reader.readString());
        msg.setKubernetesServiceAccountToken(value);
        break;
      case 39:
        var value = new envoy_config_cluster_v3_cluster_pb.Cluster();
        reader.readMessage(
          value,
          envoy_config_cluster_v3_cluster_pb.Cluster.deserializeBinaryFromReader
        );
        msg.setEnvoyOpts(value);
        break;
      case 40:
        var value =
          new envoy_config_route_v3_route_components_pb.RedirectAction();
        reader.readMessage(
          value,
          envoy_config_route_v3_route_components_pb.RedirectAction
            .deserializeBinaryFromReader
        );
        msg.setRedirect(value);
        break;
      case 46:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setEnableGoogleCloudServerlessAuthentication(value);
        break;
      case 28:
        var value = /** @type {string} */ (reader.readString());
        msg.addPolicyIds(value);
        break;
      case 34:
        var value = /** @type {string} */ (reader.readString());
        msg.addPolicyNames(value);
        break;
      case 35:
        var value = /** @type {string} */ (reader.readString());
        msg.setNamespaceName(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.Route.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.pomerium.dashboard.Route.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.Route} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.Route.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getNamespaceId();
  if (f.length > 0) {
    writer.writeString(29, f);
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getModifiedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeletedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(5, f);
  }
  f = message.getStatName();
  if (f.length > 0) {
    writer.writeString(47, f);
  }
  f = message.getFrom();
  if (f.length > 0) {
    writer.writeString(6, f);
  }
  f = message.getToList();
  if (f.length > 0) {
    writer.writeRepeatedString(7, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(8, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(9, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeString(10, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 36));
  if (f != null) {
    writer.writeString(36, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 37));
  if (f != null) {
    writer.writeString(37, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 38));
  if (f != null) {
    writer.writeString(38, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 30));
  if (f != null) {
    writer.writeString(30, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 31));
  if (f != null) {
    writer.writeString(31, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 32));
  if (f != null) {
    writer.writeString(32, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 33));
  if (f != null) {
    writer.writeString(33, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 45));
  if (f != null) {
    writer.writeInt64(45, f);
  }
  f = message.getTimeout();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getIdleTimeout();
  if (f != null) {
    writer.writeMessage(
      48,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeBool(14, f);
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 49));
  if (f != null) {
    writer.writeBool(49, f);
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeBool(15, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 16));
  if (f != null) {
    writer.writeString(16, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 41));
  if (f != null) {
    writer.writeString(41, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 42));
  if (f != null) {
    writer.writeString(42, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 43));
  if (f != null) {
    writer.writeString(43, f);
  }
  f = message.getSetRequestHeadersMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(
      23,
      writer,
      jspb.BinaryWriter.prototype.writeString,
      jspb.BinaryWriter.prototype.writeString
    );
  }
  f = message.getRemoveRequestHeadersList();
  if (f.length > 0) {
    writer.writeRepeatedString(24, f);
  }
  f = message.getRewriteResponseHeadersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      44,
      f,
      proto.pomerium.dashboard.RouteRewriteHeader.serializeBinaryToWriter
    );
  }
  f = message.getSetAuthorizationHeader();
  if (f !== 0.0) {
    writer.writeEnum(50, f);
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 25));
  if (f != null) {
    writer.writeBool(25, f);
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 26));
  if (f != null) {
    writer.writeBool(26, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 27));
  if (f != null) {
    writer.writeString(27, f);
  }
  f = message.getEnvoyOpts();
  if (f != null) {
    writer.writeMessage(
      39,
      f,
      envoy_config_cluster_v3_cluster_pb.Cluster.serializeBinaryToWriter
    );
  }
  f = message.getRedirect();
  if (f != null) {
    writer.writeMessage(
      40,
      f,
      envoy_config_route_v3_route_components_pb.RedirectAction
        .serializeBinaryToWriter
    );
  }
  f = message.getEnableGoogleCloudServerlessAuthentication();
  if (f) {
    writer.writeBool(46, f);
  }
  f = message.getPolicyIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(28, f);
  }
  f = message.getPolicyNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(34, f);
  }
  f = message.getNamespaceName();
  if (f.length > 0) {
    writer.writeString(35, f);
  }
};

/**
 * @enum {number}
 */
proto.pomerium.dashboard.Route.AuthorizationHeaderMode = {
  PASS_THROUGH: 0,
  ACCESS_TOKEN: 1,
  ID_TOKEN: 2
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.Route.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string namespace_id = 29;
 * @return {string}
 */
proto.pomerium.dashboard.Route.prototype.getNamespaceId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.setNamespaceId = function (value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};

/**
 * optional google.protobuf.Timestamp created_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pomerium.dashboard.Route.prototype.getCreatedAt = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_timestamp_pb.Timestamp,
      2
    )
  );
};

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.setCreatedAt = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.clearCreatedAt = function () {
  return this.setCreatedAt(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Route.prototype.hasCreatedAt = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional google.protobuf.Timestamp modified_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pomerium.dashboard.Route.prototype.getModifiedAt = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_timestamp_pb.Timestamp,
      3
    )
  );
};

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.setModifiedAt = function (value) {
  return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.clearModifiedAt = function () {
  return this.setModifiedAt(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Route.prototype.hasModifiedAt = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional google.protobuf.Timestamp deleted_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pomerium.dashboard.Route.prototype.getDeletedAt = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_timestamp_pb.Timestamp,
      4
    )
  );
};

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.setDeletedAt = function (value) {
  return jspb.Message.setWrapperField(this, 4, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.clearDeletedAt = function () {
  return this.setDeletedAt(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Route.prototype.hasDeletedAt = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional string name = 5;
 * @return {string}
 */
proto.pomerium.dashboard.Route.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};

/**
 * optional string stat_name = 47;
 * @return {string}
 */
proto.pomerium.dashboard.Route.prototype.getStatName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 47, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.setStatName = function (value) {
  return jspb.Message.setProto3StringField(this, 47, value);
};

/**
 * optional string from = 6;
 * @return {string}
 */
proto.pomerium.dashboard.Route.prototype.getFrom = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.setFrom = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};

/**
 * repeated string to = 7;
 * @return {!Array<string>}
 */
proto.pomerium.dashboard.Route.prototype.getToList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.setToList = function (value) {
  return jspb.Message.setField(this, 7, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.addTo = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.clearToList = function () {
  return this.setToList([]);
};

/**
 * optional string prefix = 8;
 * @return {string}
 */
proto.pomerium.dashboard.Route.prototype.getPrefix = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.setPrefix = function (value) {
  return jspb.Message.setField(this, 8, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.clearPrefix = function () {
  return jspb.Message.setField(this, 8, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Route.prototype.hasPrefix = function () {
  return jspb.Message.getField(this, 8) != null;
};

/**
 * optional string path = 9;
 * @return {string}
 */
proto.pomerium.dashboard.Route.prototype.getPath = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.setPath = function (value) {
  return jspb.Message.setField(this, 9, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.clearPath = function () {
  return jspb.Message.setField(this, 9, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Route.prototype.hasPath = function () {
  return jspb.Message.getField(this, 9) != null;
};

/**
 * optional string regex = 10;
 * @return {string}
 */
proto.pomerium.dashboard.Route.prototype.getRegex = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.setRegex = function (value) {
  return jspb.Message.setField(this, 10, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.clearRegex = function () {
  return jspb.Message.setField(this, 10, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Route.prototype.hasRegex = function () {
  return jspb.Message.getField(this, 10) != null;
};

/**
 * optional string prefix_rewrite = 36;
 * @return {string}
 */
proto.pomerium.dashboard.Route.prototype.getPrefixRewrite = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.setPrefixRewrite = function (value) {
  return jspb.Message.setField(this, 36, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.clearPrefixRewrite = function () {
  return jspb.Message.setField(this, 36, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Route.prototype.hasPrefixRewrite = function () {
  return jspb.Message.getField(this, 36) != null;
};

/**
 * optional string regex_rewrite_pattern = 37;
 * @return {string}
 */
proto.pomerium.dashboard.Route.prototype.getRegexRewritePattern = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.setRegexRewritePattern = function (
  value
) {
  return jspb.Message.setField(this, 37, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.clearRegexRewritePattern =
  function () {
    return jspb.Message.setField(this, 37, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Route.prototype.hasRegexRewritePattern = function () {
  return jspb.Message.getField(this, 37) != null;
};

/**
 * optional string regex_rewrite_substitution = 38;
 * @return {string}
 */
proto.pomerium.dashboard.Route.prototype.getRegexRewriteSubstitution =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 38, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.setRegexRewriteSubstitution =
  function (value) {
    return jspb.Message.setField(this, 38, value);
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.clearRegexRewriteSubstitution =
  function () {
    return jspb.Message.setField(this, 38, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Route.prototype.hasRegexRewriteSubstitution =
  function () {
    return jspb.Message.getField(this, 38) != null;
  };

/**
 * optional string host_rewrite = 30;
 * @return {string}
 */
proto.pomerium.dashboard.Route.prototype.getHostRewrite = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.setHostRewrite = function (value) {
  return jspb.Message.setField(this, 30, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.clearHostRewrite = function () {
  return jspb.Message.setField(this, 30, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Route.prototype.hasHostRewrite = function () {
  return jspb.Message.getField(this, 30) != null;
};

/**
 * optional string host_rewrite_header = 31;
 * @return {string}
 */
proto.pomerium.dashboard.Route.prototype.getHostRewriteHeader = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.setHostRewriteHeader = function (
  value
) {
  return jspb.Message.setField(this, 31, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.clearHostRewriteHeader = function () {
  return jspb.Message.setField(this, 31, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Route.prototype.hasHostRewriteHeader = function () {
  return jspb.Message.getField(this, 31) != null;
};

/**
 * optional string host_path_regex_rewrite_pattern = 32;
 * @return {string}
 */
proto.pomerium.dashboard.Route.prototype.getHostPathRegexRewritePattern =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 32, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.setHostPathRegexRewritePattern =
  function (value) {
    return jspb.Message.setField(this, 32, value);
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.clearHostPathRegexRewritePattern =
  function () {
    return jspb.Message.setField(this, 32, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Route.prototype.hasHostPathRegexRewritePattern =
  function () {
    return jspb.Message.getField(this, 32) != null;
  };

/**
 * optional string host_path_regex_rewrite_substitution = 33;
 * @return {string}
 */
proto.pomerium.dashboard.Route.prototype.getHostPathRegexRewriteSubstitution =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 33, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.setHostPathRegexRewriteSubstitution =
  function (value) {
    return jspb.Message.setField(this, 33, value);
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.clearHostPathRegexRewriteSubstitution =
  function () {
    return jspb.Message.setField(this, 33, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Route.prototype.hasHostPathRegexRewriteSubstitution =
  function () {
    return jspb.Message.getField(this, 33) != null;
  };

/**
 * optional int64 regex_priority_order = 45;
 * @return {number}
 */
proto.pomerium.dashboard.Route.prototype.getRegexPriorityOrder = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 45, 0));
};

/**
 * @param {number} value
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.setRegexPriorityOrder = function (
  value
) {
  return jspb.Message.setField(this, 45, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.clearRegexPriorityOrder = function () {
  return jspb.Message.setField(this, 45, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Route.prototype.hasRegexPriorityOrder = function () {
  return jspb.Message.getField(this, 45) != null;
};

/**
 * optional google.protobuf.Duration timeout = 13;
 * @return {?proto.google.protobuf.Duration}
 */
proto.pomerium.dashboard.Route.prototype.getTimeout = function () {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 13)
  );
};

/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.setTimeout = function (value) {
  return jspb.Message.setWrapperField(this, 13, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.clearTimeout = function () {
  return this.setTimeout(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Route.prototype.hasTimeout = function () {
  return jspb.Message.getField(this, 13) != null;
};

/**
 * optional google.protobuf.Duration idle_timeout = 48;
 * @return {?proto.google.protobuf.Duration}
 */
proto.pomerium.dashboard.Route.prototype.getIdleTimeout = function () {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 48)
  );
};

/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.setIdleTimeout = function (value) {
  return jspb.Message.setWrapperField(this, 48, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.clearIdleTimeout = function () {
  return this.setIdleTimeout(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Route.prototype.hasIdleTimeout = function () {
  return jspb.Message.getField(this, 48) != null;
};

/**
 * optional bool allow_websockets = 14;
 * @return {boolean}
 */
proto.pomerium.dashboard.Route.prototype.getAllowWebsockets = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 14, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.setAllowWebsockets = function (value) {
  return jspb.Message.setField(this, 14, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.clearAllowWebsockets = function () {
  return jspb.Message.setField(this, 14, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Route.prototype.hasAllowWebsockets = function () {
  return jspb.Message.getField(this, 14) != null;
};

/**
 * optional bool allow_spdy = 49;
 * @return {boolean}
 */
proto.pomerium.dashboard.Route.prototype.getAllowSpdy = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 49, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.setAllowSpdy = function (value) {
  return jspb.Message.setField(this, 49, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.clearAllowSpdy = function () {
  return jspb.Message.setField(this, 49, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Route.prototype.hasAllowSpdy = function () {
  return jspb.Message.getField(this, 49) != null;
};

/**
 * optional bool tls_skip_verify = 15;
 * @return {boolean}
 */
proto.pomerium.dashboard.Route.prototype.getTlsSkipVerify = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 15, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.setTlsSkipVerify = function (value) {
  return jspb.Message.setField(this, 15, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.clearTlsSkipVerify = function () {
  return jspb.Message.setField(this, 15, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Route.prototype.hasTlsSkipVerify = function () {
  return jspb.Message.getField(this, 15) != null;
};

/**
 * optional string tls_server_name = 16;
 * @return {string}
 */
proto.pomerium.dashboard.Route.prototype.getTlsServerName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.setTlsServerName = function (value) {
  return jspb.Message.setField(this, 16, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.clearTlsServerName = function () {
  return jspb.Message.setField(this, 16, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Route.prototype.hasTlsServerName = function () {
  return jspb.Message.getField(this, 16) != null;
};

/**
 * optional string tls_custom_ca_key_pair_id = 41;
 * @return {string}
 */
proto.pomerium.dashboard.Route.prototype.getTlsCustomCaKeyPairId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 41, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.setTlsCustomCaKeyPairId = function (
  value
) {
  return jspb.Message.setField(this, 41, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.clearTlsCustomCaKeyPairId =
  function () {
    return jspb.Message.setField(this, 41, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Route.prototype.hasTlsCustomCaKeyPairId = function () {
  return jspb.Message.getField(this, 41) != null;
};

/**
 * optional string tls_client_key_pair_id = 42;
 * @return {string}
 */
proto.pomerium.dashboard.Route.prototype.getTlsClientKeyPairId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 42, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.setTlsClientKeyPairId = function (
  value
) {
  return jspb.Message.setField(this, 42, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.clearTlsClientKeyPairId = function () {
  return jspb.Message.setField(this, 42, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Route.prototype.hasTlsClientKeyPairId = function () {
  return jspb.Message.getField(this, 42) != null;
};

/**
 * optional string tls_downstream_client_ca_key_pair_id = 43;
 * @return {string}
 */
proto.pomerium.dashboard.Route.prototype.getTlsDownstreamClientCaKeyPairId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 43, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.setTlsDownstreamClientCaKeyPairId =
  function (value) {
    return jspb.Message.setField(this, 43, value);
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.clearTlsDownstreamClientCaKeyPairId =
  function () {
    return jspb.Message.setField(this, 43, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Route.prototype.hasTlsDownstreamClientCaKeyPairId =
  function () {
    return jspb.Message.getField(this, 43) != null;
  };

/**
 * map<string, string> set_request_headers = 23;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.pomerium.dashboard.Route.prototype.getSetRequestHeadersMap = function (
  opt_noLazyCreate
) {
  return /** @type {!jspb.Map<string,string>} */ (
    jspb.Message.getMapField(this, 23, opt_noLazyCreate, null)
  );
};

/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.clearSetRequestHeadersMap =
  function () {
    this.getSetRequestHeadersMap().clear();
    return this;
  };

/**
 * repeated string remove_request_headers = 24;
 * @return {!Array<string>}
 */
proto.pomerium.dashboard.Route.prototype.getRemoveRequestHeadersList =
  function () {
    return /** @type {!Array<string>} */ (
      jspb.Message.getRepeatedField(this, 24)
    );
  };

/**
 * @param {!Array<string>} value
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.setRemoveRequestHeadersList =
  function (value) {
    return jspb.Message.setField(this, 24, value || []);
  };

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.addRemoveRequestHeaders = function (
  value,
  opt_index
) {
  return jspb.Message.addToRepeatedField(this, 24, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.clearRemoveRequestHeadersList =
  function () {
    return this.setRemoveRequestHeadersList([]);
  };

/**
 * repeated RouteRewriteHeader rewrite_response_headers = 44;
 * @return {!Array<!proto.pomerium.dashboard.RouteRewriteHeader>}
 */
proto.pomerium.dashboard.Route.prototype.getRewriteResponseHeadersList =
  function () {
    return /** @type{!Array<!proto.pomerium.dashboard.RouteRewriteHeader>} */ (
      jspb.Message.getRepeatedWrapperField(
        this,
        proto.pomerium.dashboard.RouteRewriteHeader,
        44
      )
    );
  };

/**
 * @param {!Array<!proto.pomerium.dashboard.RouteRewriteHeader>} value
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.setRewriteResponseHeadersList =
  function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 44, value);
  };

/**
 * @param {!proto.pomerium.dashboard.RouteRewriteHeader=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.RouteRewriteHeader}
 */
proto.pomerium.dashboard.Route.prototype.addRewriteResponseHeaders = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    44,
    opt_value,
    proto.pomerium.dashboard.RouteRewriteHeader,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.clearRewriteResponseHeadersList =
  function () {
    return this.setRewriteResponseHeadersList([]);
  };

/**
 * optional AuthorizationHeaderMode set_authorization_header = 50;
 * @return {!proto.pomerium.dashboard.Route.AuthorizationHeaderMode}
 */
proto.pomerium.dashboard.Route.prototype.getSetAuthorizationHeader =
  function () {
    return /** @type {!proto.pomerium.dashboard.Route.AuthorizationHeaderMode} */ (
      jspb.Message.getFieldWithDefault(this, 50, 0)
    );
  };

/**
 * @param {!proto.pomerium.dashboard.Route.AuthorizationHeaderMode} value
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.setSetAuthorizationHeader = function (
  value
) {
  return jspb.Message.setProto3EnumField(this, 50, value);
};

/**
 * optional bool preserve_host_header = 25;
 * @return {boolean}
 */
proto.pomerium.dashboard.Route.prototype.getPreserveHostHeader = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 25, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.setPreserveHostHeader = function (
  value
) {
  return jspb.Message.setField(this, 25, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.clearPreserveHostHeader = function () {
  return jspb.Message.setField(this, 25, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Route.prototype.hasPreserveHostHeader = function () {
  return jspb.Message.getField(this, 25) != null;
};

/**
 * optional bool pass_identity_headers = 26;
 * @return {boolean}
 */
proto.pomerium.dashboard.Route.prototype.getPassIdentityHeaders = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 26, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.setPassIdentityHeaders = function (
  value
) {
  return jspb.Message.setField(this, 26, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.clearPassIdentityHeaders =
  function () {
    return jspb.Message.setField(this, 26, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Route.prototype.hasPassIdentityHeaders = function () {
  return jspb.Message.getField(this, 26) != null;
};

/**
 * optional string kubernetes_service_account_token = 27;
 * @return {string}
 */
proto.pomerium.dashboard.Route.prototype.getKubernetesServiceAccountToken =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 27, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.setKubernetesServiceAccountToken =
  function (value) {
    return jspb.Message.setField(this, 27, value);
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.clearKubernetesServiceAccountToken =
  function () {
    return jspb.Message.setField(this, 27, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Route.prototype.hasKubernetesServiceAccountToken =
  function () {
    return jspb.Message.getField(this, 27) != null;
  };

/**
 * optional envoy.config.cluster.v3.Cluster envoy_opts = 39;
 * @return {?proto.envoy.config.cluster.v3.Cluster}
 */
proto.pomerium.dashboard.Route.prototype.getEnvoyOpts = function () {
  return /** @type{?proto.envoy.config.cluster.v3.Cluster} */ (
    jspb.Message.getWrapperField(
      this,
      envoy_config_cluster_v3_cluster_pb.Cluster,
      39
    )
  );
};

/**
 * @param {?proto.envoy.config.cluster.v3.Cluster|undefined} value
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.setEnvoyOpts = function (value) {
  return jspb.Message.setWrapperField(this, 39, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.clearEnvoyOpts = function () {
  return this.setEnvoyOpts(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Route.prototype.hasEnvoyOpts = function () {
  return jspb.Message.getField(this, 39) != null;
};

/**
 * optional envoy.config.route.v3.RedirectAction redirect = 40;
 * @return {?proto.envoy.config.route.v3.RedirectAction}
 */
proto.pomerium.dashboard.Route.prototype.getRedirect = function () {
  return /** @type{?proto.envoy.config.route.v3.RedirectAction} */ (
    jspb.Message.getWrapperField(
      this,
      envoy_config_route_v3_route_components_pb.RedirectAction,
      40
    )
  );
};

/**
 * @param {?proto.envoy.config.route.v3.RedirectAction|undefined} value
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.setRedirect = function (value) {
  return jspb.Message.setWrapperField(this, 40, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.clearRedirect = function () {
  return this.setRedirect(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.Route.prototype.hasRedirect = function () {
  return jspb.Message.getField(this, 40) != null;
};

/**
 * optional bool enable_google_cloud_serverless_authentication = 46;
 * @return {boolean}
 */
proto.pomerium.dashboard.Route.prototype.getEnableGoogleCloudServerlessAuthentication =
  function () {
    return /** @type {boolean} */ (
      jspb.Message.getBooleanFieldWithDefault(this, 46, false)
    );
  };

/**
 * @param {boolean} value
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.setEnableGoogleCloudServerlessAuthentication =
  function (value) {
    return jspb.Message.setProto3BooleanField(this, 46, value);
  };

/**
 * repeated string policy_ids = 28;
 * @return {!Array<string>}
 */
proto.pomerium.dashboard.Route.prototype.getPolicyIdsList = function () {
  return /** @type {!Array<string>} */ (
    jspb.Message.getRepeatedField(this, 28)
  );
};

/**
 * @param {!Array<string>} value
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.setPolicyIdsList = function (value) {
  return jspb.Message.setField(this, 28, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.addPolicyIds = function (
  value,
  opt_index
) {
  return jspb.Message.addToRepeatedField(this, 28, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.clearPolicyIdsList = function () {
  return this.setPolicyIdsList([]);
};

/**
 * repeated string policy_names = 34;
 * @return {!Array<string>}
 */
proto.pomerium.dashboard.Route.prototype.getPolicyNamesList = function () {
  return /** @type {!Array<string>} */ (
    jspb.Message.getRepeatedField(this, 34)
  );
};

/**
 * @param {!Array<string>} value
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.setPolicyNamesList = function (value) {
  return jspb.Message.setField(this, 34, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.addPolicyNames = function (
  value,
  opt_index
) {
  return jspb.Message.addToRepeatedField(this, 34, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.clearPolicyNamesList = function () {
  return this.setPolicyNamesList([]);
};

/**
 * optional string namespace_name = 35;
 * @return {string}
 */
proto.pomerium.dashboard.Route.prototype.getNamespaceName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.Route} returns this
 */
proto.pomerium.dashboard.Route.prototype.setNamespaceName = function (value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pomerium.dashboard.RouteWithPolicies.repeatedFields_ = [2];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.RouteWithPolicies.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.RouteWithPolicies.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.RouteWithPolicies} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.RouteWithPolicies.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        route:
          (f = msg.getRoute()) &&
          proto.pomerium.dashboard.Route.toObject(includeInstance, f),
        policiesList: jspb.Message.toObjectList(
          msg.getPoliciesList(),
          policy_pb.Policy.toObject,
          includeInstance
        )
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.RouteWithPolicies}
 */
proto.pomerium.dashboard.RouteWithPolicies.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.RouteWithPolicies();
  return proto.pomerium.dashboard.RouteWithPolicies.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.RouteWithPolicies} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.RouteWithPolicies}
 */
proto.pomerium.dashboard.RouteWithPolicies.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.Route();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.Route.deserializeBinaryFromReader
          );
          msg.setRoute(value);
          break;
        case 2:
          var value = new policy_pb.Policy();
          reader.readMessage(
            value,
            policy_pb.Policy.deserializeBinaryFromReader
          );
          msg.addPolicies(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.RouteWithPolicies.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.RouteWithPolicies.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.RouteWithPolicies} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.RouteWithPolicies.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getRoute();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pomerium.dashboard.Route.serializeBinaryToWriter
    );
  }
  f = message.getPoliciesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(2, f, policy_pb.Policy.serializeBinaryToWriter);
  }
};

/**
 * optional Route route = 1;
 * @return {?proto.pomerium.dashboard.Route}
 */
proto.pomerium.dashboard.RouteWithPolicies.prototype.getRoute = function () {
  return /** @type{?proto.pomerium.dashboard.Route} */ (
    jspb.Message.getWrapperField(this, proto.pomerium.dashboard.Route, 1)
  );
};

/**
 * @param {?proto.pomerium.dashboard.Route|undefined} value
 * @return {!proto.pomerium.dashboard.RouteWithPolicies} returns this
 */
proto.pomerium.dashboard.RouteWithPolicies.prototype.setRoute = function (
  value
) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.RouteWithPolicies} returns this
 */
proto.pomerium.dashboard.RouteWithPolicies.prototype.clearRoute = function () {
  return this.setRoute(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.RouteWithPolicies.prototype.hasRoute = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * repeated Policy policies = 2;
 * @return {!Array<!proto.pomerium.dashboard.Policy>}
 */
proto.pomerium.dashboard.RouteWithPolicies.prototype.getPoliciesList =
  function () {
    return /** @type{!Array<!proto.pomerium.dashboard.Policy>} */ (
      jspb.Message.getRepeatedWrapperField(this, policy_pb.Policy, 2)
    );
  };

/**
 * @param {!Array<!proto.pomerium.dashboard.Policy>} value
 * @return {!proto.pomerium.dashboard.RouteWithPolicies} returns this
 */
proto.pomerium.dashboard.RouteWithPolicies.prototype.setPoliciesList =
  function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 2, value);
  };

/**
 * @param {!proto.pomerium.dashboard.Policy=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.Policy}
 */
proto.pomerium.dashboard.RouteWithPolicies.prototype.addPolicies = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    2,
    opt_value,
    proto.pomerium.dashboard.Policy,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.RouteWithPolicies} returns this
 */
proto.pomerium.dashboard.RouteWithPolicies.prototype.clearPoliciesList =
  function () {
    return this.setPoliciesList([]);
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.DeleteRouteRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.DeleteRouteRequest.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.DeleteRouteRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.DeleteRouteRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.DeleteRouteRequest}
 */
proto.pomerium.dashboard.DeleteRouteRequest.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.DeleteRouteRequest();
  return proto.pomerium.dashboard.DeleteRouteRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.DeleteRouteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.DeleteRouteRequest}
 */
proto.pomerium.dashboard.DeleteRouteRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setId(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.DeleteRouteRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.DeleteRouteRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.DeleteRouteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.DeleteRouteRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.DeleteRouteRequest.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.DeleteRouteRequest} returns this
 */
proto.pomerium.dashboard.DeleteRouteRequest.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.DeleteRouteResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.DeleteRouteResponse.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.DeleteRouteResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.DeleteRouteResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {};

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.DeleteRouteResponse}
 */
proto.pomerium.dashboard.DeleteRouteResponse.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.DeleteRouteResponse();
  return proto.pomerium.dashboard.DeleteRouteResponse.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.DeleteRouteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.DeleteRouteResponse}
 */
proto.pomerium.dashboard.DeleteRouteResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.DeleteRouteResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.DeleteRouteResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.DeleteRouteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.DeleteRouteResponse.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.GetRouteRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.GetRouteRequest.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.GetRouteRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.GetRouteRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.GetRouteRequest}
 */
proto.pomerium.dashboard.GetRouteRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.GetRouteRequest();
  return proto.pomerium.dashboard.GetRouteRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.GetRouteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.GetRouteRequest}
 */
proto.pomerium.dashboard.GetRouteRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setId(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.GetRouteRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.GetRouteRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.GetRouteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.GetRouteRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.GetRouteRequest.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.GetRouteRequest} returns this
 */
proto.pomerium.dashboard.GetRouteRequest.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.GetRouteResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.GetRouteResponse.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.GetRouteResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.GetRouteResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        route:
          (f = msg.getRoute()) &&
          proto.pomerium.dashboard.Route.toObject(includeInstance, f)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.GetRouteResponse}
 */
proto.pomerium.dashboard.GetRouteResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.GetRouteResponse();
  return proto.pomerium.dashboard.GetRouteResponse.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.GetRouteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.GetRouteResponse}
 */
proto.pomerium.dashboard.GetRouteResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.Route();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.Route.deserializeBinaryFromReader
          );
          msg.setRoute(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.GetRouteResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.GetRouteResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.GetRouteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.GetRouteResponse.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getRoute();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pomerium.dashboard.Route.serializeBinaryToWriter
    );
  }
};

/**
 * optional Route route = 1;
 * @return {?proto.pomerium.dashboard.Route}
 */
proto.pomerium.dashboard.GetRouteResponse.prototype.getRoute = function () {
  return /** @type{?proto.pomerium.dashboard.Route} */ (
    jspb.Message.getWrapperField(this, proto.pomerium.dashboard.Route, 1)
  );
};

/**
 * @param {?proto.pomerium.dashboard.Route|undefined} value
 * @return {!proto.pomerium.dashboard.GetRouteResponse} returns this
 */
proto.pomerium.dashboard.GetRouteResponse.prototype.setRoute = function (
  value
) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.GetRouteResponse} returns this
 */
proto.pomerium.dashboard.GetRouteResponse.prototype.clearRoute = function () {
  return this.setRoute(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.GetRouteResponse.prototype.hasRoute = function () {
  return jspb.Message.getField(this, 1) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.ListRoutesRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.ListRoutesRequest.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.ListRoutesRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.ListRoutesRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        namespace: jspb.Message.getFieldWithDefault(msg, 1, ''),
        query: jspb.Message.getFieldWithDefault(msg, 2, ''),
        offset: jspb.Message.getFieldWithDefault(msg, 3, 0),
        limit: jspb.Message.getFieldWithDefault(msg, 4, 0),
        orderBy: jspb.Message.getFieldWithDefault(msg, 5, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.ListRoutesRequest}
 */
proto.pomerium.dashboard.ListRoutesRequest.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.ListRoutesRequest();
  return proto.pomerium.dashboard.ListRoutesRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.ListRoutesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.ListRoutesRequest}
 */
proto.pomerium.dashboard.ListRoutesRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setNamespace(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setQuery(value);
          break;
        case 3:
          var value = /** @type {number} */ (reader.readInt64());
          msg.setOffset(value);
          break;
        case 4:
          var value = /** @type {number} */ (reader.readInt64());
          msg.setLimit(value);
          break;
        case 5:
          var value = /** @type {string} */ (reader.readString());
          msg.setOrderBy(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.ListRoutesRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.ListRoutesRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.ListRoutesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.ListRoutesRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getNamespace();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(2, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt64(3, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt64(4, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(5, f);
  }
};

/**
 * optional string namespace = 1;
 * @return {string}
 */
proto.pomerium.dashboard.ListRoutesRequest.prototype.getNamespace =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ListRoutesRequest} returns this
 */
proto.pomerium.dashboard.ListRoutesRequest.prototype.setNamespace = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string query = 2;
 * @return {string}
 */
proto.pomerium.dashboard.ListRoutesRequest.prototype.getQuery = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ListRoutesRequest} returns this
 */
proto.pomerium.dashboard.ListRoutesRequest.prototype.setQuery = function (
  value
) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.ListRoutesRequest} returns this
 */
proto.pomerium.dashboard.ListRoutesRequest.prototype.clearQuery = function () {
  return jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.ListRoutesRequest.prototype.hasQuery = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional int64 offset = 3;
 * @return {number}
 */
proto.pomerium.dashboard.ListRoutesRequest.prototype.getOffset = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.pomerium.dashboard.ListRoutesRequest} returns this
 */
proto.pomerium.dashboard.ListRoutesRequest.prototype.setOffset = function (
  value
) {
  return jspb.Message.setField(this, 3, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.ListRoutesRequest} returns this
 */
proto.pomerium.dashboard.ListRoutesRequest.prototype.clearOffset = function () {
  return jspb.Message.setField(this, 3, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.ListRoutesRequest.prototype.hasOffset = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional int64 limit = 4;
 * @return {number}
 */
proto.pomerium.dashboard.ListRoutesRequest.prototype.getLimit = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {number} value
 * @return {!proto.pomerium.dashboard.ListRoutesRequest} returns this
 */
proto.pomerium.dashboard.ListRoutesRequest.prototype.setLimit = function (
  value
) {
  return jspb.Message.setField(this, 4, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.ListRoutesRequest} returns this
 */
proto.pomerium.dashboard.ListRoutesRequest.prototype.clearLimit = function () {
  return jspb.Message.setField(this, 4, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.ListRoutesRequest.prototype.hasLimit = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional string order_by = 5;
 * @return {string}
 */
proto.pomerium.dashboard.ListRoutesRequest.prototype.getOrderBy = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ListRoutesRequest} returns this
 */
proto.pomerium.dashboard.ListRoutesRequest.prototype.setOrderBy = function (
  value
) {
  return jspb.Message.setField(this, 5, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.ListRoutesRequest} returns this
 */
proto.pomerium.dashboard.ListRoutesRequest.prototype.clearOrderBy =
  function () {
    return jspb.Message.setField(this, 5, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.ListRoutesRequest.prototype.hasOrderBy = function () {
  return jspb.Message.getField(this, 5) != null;
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pomerium.dashboard.ListRoutesResponse.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.ListRoutesResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.ListRoutesResponse.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.ListRoutesResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.ListRoutesResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        routesList: jspb.Message.toObjectList(
          msg.getRoutesList(),
          proto.pomerium.dashboard.Route.toObject,
          includeInstance
        ),
        totalCount: jspb.Message.getFieldWithDefault(msg, 2, 0)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.ListRoutesResponse}
 */
proto.pomerium.dashboard.ListRoutesResponse.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.ListRoutesResponse();
  return proto.pomerium.dashboard.ListRoutesResponse.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.ListRoutesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.ListRoutesResponse}
 */
proto.pomerium.dashboard.ListRoutesResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.Route();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.Route.deserializeBinaryFromReader
          );
          msg.addRoutes(value);
          break;
        case 2:
          var value = /** @type {number} */ (reader.readInt64());
          msg.setTotalCount(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.ListRoutesResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.ListRoutesResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.ListRoutesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.ListRoutesResponse.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getRoutesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pomerium.dashboard.Route.serializeBinaryToWriter
    );
  }
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt64(2, f);
  }
};

/**
 * repeated Route routes = 1;
 * @return {!Array<!proto.pomerium.dashboard.Route>}
 */
proto.pomerium.dashboard.ListRoutesResponse.prototype.getRoutesList =
  function () {
    return /** @type{!Array<!proto.pomerium.dashboard.Route>} */ (
      jspb.Message.getRepeatedWrapperField(
        this,
        proto.pomerium.dashboard.Route,
        1
      )
    );
  };

/**
 * @param {!Array<!proto.pomerium.dashboard.Route>} value
 * @return {!proto.pomerium.dashboard.ListRoutesResponse} returns this
 */
proto.pomerium.dashboard.ListRoutesResponse.prototype.setRoutesList = function (
  value
) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.pomerium.dashboard.Route=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.Route}
 */
proto.pomerium.dashboard.ListRoutesResponse.prototype.addRoutes = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.pomerium.dashboard.Route,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.ListRoutesResponse} returns this
 */
proto.pomerium.dashboard.ListRoutesResponse.prototype.clearRoutesList =
  function () {
    return this.setRoutesList([]);
  };

/**
 * optional int64 total_count = 2;
 * @return {number}
 */
proto.pomerium.dashboard.ListRoutesResponse.prototype.getTotalCount =
  function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };

/**
 * @param {number} value
 * @return {!proto.pomerium.dashboard.ListRoutesResponse} returns this
 */
proto.pomerium.dashboard.ListRoutesResponse.prototype.setTotalCount = function (
  value
) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.LoadRoutesRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.LoadRoutesRequest.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.LoadRoutesRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.LoadRoutesRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        name: jspb.Message.getFieldWithDefault(msg, 1, ''),
        contents: msg.getContents_asB64()
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.LoadRoutesRequest}
 */
proto.pomerium.dashboard.LoadRoutesRequest.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.LoadRoutesRequest();
  return proto.pomerium.dashboard.LoadRoutesRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.LoadRoutesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.LoadRoutesRequest}
 */
proto.pomerium.dashboard.LoadRoutesRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setName(value);
          break;
        case 2:
          var value = /** @type {!Uint8Array} */ (reader.readBytes());
          msg.setContents(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.LoadRoutesRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.LoadRoutesRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.LoadRoutesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.LoadRoutesRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getContents_asU8();
  if (f.length > 0) {
    writer.writeBytes(2, f);
  }
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.pomerium.dashboard.LoadRoutesRequest.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.LoadRoutesRequest} returns this
 */
proto.pomerium.dashboard.LoadRoutesRequest.prototype.setName = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional bytes contents = 2;
 * @return {string}
 */
proto.pomerium.dashboard.LoadRoutesRequest.prototype.getContents = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * optional bytes contents = 2;
 * This is a type-conversion wrapper around `getContents()`
 * @return {string}
 */
proto.pomerium.dashboard.LoadRoutesRequest.prototype.getContents_asB64 =
  function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getContents()));
  };

/**
 * optional bytes contents = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContents()`
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.LoadRoutesRequest.prototype.getContents_asU8 =
  function () {
    return /** @type {!Uint8Array} */ (
      jspb.Message.bytesAsU8(this.getContents())
    );
  };

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.pomerium.dashboard.LoadRoutesRequest} returns this
 */
proto.pomerium.dashboard.LoadRoutesRequest.prototype.setContents = function (
  value
) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pomerium.dashboard.LoadRoutesResponse.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.LoadRoutesResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.LoadRoutesResponse.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.LoadRoutesResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.LoadRoutesResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        routesList: jspb.Message.toObjectList(
          msg.getRoutesList(),
          proto.pomerium.dashboard.RouteWithPolicies.toObject,
          includeInstance
        )
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.LoadRoutesResponse}
 */
proto.pomerium.dashboard.LoadRoutesResponse.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.LoadRoutesResponse();
  return proto.pomerium.dashboard.LoadRoutesResponse.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.LoadRoutesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.LoadRoutesResponse}
 */
proto.pomerium.dashboard.LoadRoutesResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.RouteWithPolicies();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.RouteWithPolicies
              .deserializeBinaryFromReader
          );
          msg.addRoutes(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.LoadRoutesResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.LoadRoutesResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.LoadRoutesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.LoadRoutesResponse.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getRoutesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pomerium.dashboard.RouteWithPolicies.serializeBinaryToWriter
    );
  }
};

/**
 * repeated RouteWithPolicies routes = 1;
 * @return {!Array<!proto.pomerium.dashboard.RouteWithPolicies>}
 */
proto.pomerium.dashboard.LoadRoutesResponse.prototype.getRoutesList =
  function () {
    return /** @type{!Array<!proto.pomerium.dashboard.RouteWithPolicies>} */ (
      jspb.Message.getRepeatedWrapperField(
        this,
        proto.pomerium.dashboard.RouteWithPolicies,
        1
      )
    );
  };

/**
 * @param {!Array<!proto.pomerium.dashboard.RouteWithPolicies>} value
 * @return {!proto.pomerium.dashboard.LoadRoutesResponse} returns this
 */
proto.pomerium.dashboard.LoadRoutesResponse.prototype.setRoutesList = function (
  value
) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.pomerium.dashboard.RouteWithPolicies=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.RouteWithPolicies}
 */
proto.pomerium.dashboard.LoadRoutesResponse.prototype.addRoutes = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.pomerium.dashboard.RouteWithPolicies,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.LoadRoutesResponse} returns this
 */
proto.pomerium.dashboard.LoadRoutesResponse.prototype.clearRoutesList =
  function () {
    return this.setRoutesList([]);
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.SetRouteRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.SetRouteRequest.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.SetRouteRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.SetRouteRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        route:
          (f = msg.getRoute()) &&
          proto.pomerium.dashboard.Route.toObject(includeInstance, f)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.SetRouteRequest}
 */
proto.pomerium.dashboard.SetRouteRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.SetRouteRequest();
  return proto.pomerium.dashboard.SetRouteRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.SetRouteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.SetRouteRequest}
 */
proto.pomerium.dashboard.SetRouteRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.Route();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.Route.deserializeBinaryFromReader
          );
          msg.setRoute(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.SetRouteRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.SetRouteRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.SetRouteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.SetRouteRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getRoute();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pomerium.dashboard.Route.serializeBinaryToWriter
    );
  }
};

/**
 * optional Route route = 1;
 * @return {?proto.pomerium.dashboard.Route}
 */
proto.pomerium.dashboard.SetRouteRequest.prototype.getRoute = function () {
  return /** @type{?proto.pomerium.dashboard.Route} */ (
    jspb.Message.getWrapperField(this, proto.pomerium.dashboard.Route, 1)
  );
};

/**
 * @param {?proto.pomerium.dashboard.Route|undefined} value
 * @return {!proto.pomerium.dashboard.SetRouteRequest} returns this
 */
proto.pomerium.dashboard.SetRouteRequest.prototype.setRoute = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.SetRouteRequest} returns this
 */
proto.pomerium.dashboard.SetRouteRequest.prototype.clearRoute = function () {
  return this.setRoute(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.SetRouteRequest.prototype.hasRoute = function () {
  return jspb.Message.getField(this, 1) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.SetRouteResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.SetRouteResponse.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.SetRouteResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.SetRouteResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        route:
          (f = msg.getRoute()) &&
          proto.pomerium.dashboard.Route.toObject(includeInstance, f)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.SetRouteResponse}
 */
proto.pomerium.dashboard.SetRouteResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.SetRouteResponse();
  return proto.pomerium.dashboard.SetRouteResponse.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.SetRouteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.SetRouteResponse}
 */
proto.pomerium.dashboard.SetRouteResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.Route();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.Route.deserializeBinaryFromReader
          );
          msg.setRoute(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.SetRouteResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.SetRouteResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.SetRouteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.SetRouteResponse.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getRoute();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pomerium.dashboard.Route.serializeBinaryToWriter
    );
  }
};

/**
 * optional Route route = 1;
 * @return {?proto.pomerium.dashboard.Route}
 */
proto.pomerium.dashboard.SetRouteResponse.prototype.getRoute = function () {
  return /** @type{?proto.pomerium.dashboard.Route} */ (
    jspb.Message.getWrapperField(this, proto.pomerium.dashboard.Route, 1)
  );
};

/**
 * @param {?proto.pomerium.dashboard.Route|undefined} value
 * @return {!proto.pomerium.dashboard.SetRouteResponse} returns this
 */
proto.pomerium.dashboard.SetRouteResponse.prototype.setRoute = function (
  value
) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.SetRouteResponse} returns this
 */
proto.pomerium.dashboard.SetRouteResponse.prototype.clearRoute = function () {
  return this.setRoute(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.SetRouteResponse.prototype.hasRoute = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pomerium.dashboard.MoveRoutesRequest.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.MoveRoutesRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.MoveRoutesRequest.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.MoveRoutesRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.MoveRoutesRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        routeIdsList:
          (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
        newNamespaceId: jspb.Message.getFieldWithDefault(msg, 2, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.MoveRoutesRequest}
 */
proto.pomerium.dashboard.MoveRoutesRequest.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.MoveRoutesRequest();
  return proto.pomerium.dashboard.MoveRoutesRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.MoveRoutesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.MoveRoutesRequest}
 */
proto.pomerium.dashboard.MoveRoutesRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.addRouteIds(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setNewNamespaceId(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.MoveRoutesRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.MoveRoutesRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.MoveRoutesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.MoveRoutesRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getRouteIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(1, f);
  }
  f = message.getNewNamespaceId();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
};

/**
 * repeated string route_ids = 1;
 * @return {!Array<string>}
 */
proto.pomerium.dashboard.MoveRoutesRequest.prototype.getRouteIdsList =
  function () {
    return /** @type {!Array<string>} */ (
      jspb.Message.getRepeatedField(this, 1)
    );
  };

/**
 * @param {!Array<string>} value
 * @return {!proto.pomerium.dashboard.MoveRoutesRequest} returns this
 */
proto.pomerium.dashboard.MoveRoutesRequest.prototype.setRouteIdsList =
  function (value) {
    return jspb.Message.setField(this, 1, value || []);
  };

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.MoveRoutesRequest} returns this
 */
proto.pomerium.dashboard.MoveRoutesRequest.prototype.addRouteIds = function (
  value,
  opt_index
) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.MoveRoutesRequest} returns this
 */
proto.pomerium.dashboard.MoveRoutesRequest.prototype.clearRouteIdsList =
  function () {
    return this.setRouteIdsList([]);
  };

/**
 * optional string new_namespace_id = 2;
 * @return {string}
 */
proto.pomerium.dashboard.MoveRoutesRequest.prototype.getNewNamespaceId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 2, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.MoveRoutesRequest} returns this
 */
proto.pomerium.dashboard.MoveRoutesRequest.prototype.setNewNamespaceId =
  function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.MoveRoutesResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.MoveRoutesResponse.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.MoveRoutesResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.MoveRoutesResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {};

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.MoveRoutesResponse}
 */
proto.pomerium.dashboard.MoveRoutesResponse.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.MoveRoutesResponse();
  return proto.pomerium.dashboard.MoveRoutesResponse.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.MoveRoutesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.MoveRoutesResponse}
 */
proto.pomerium.dashboard.MoveRoutesResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.MoveRoutesResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.MoveRoutesResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.MoveRoutesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.MoveRoutesResponse.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
};

goog.object.extend(exports, proto.pomerium.dashboard);
