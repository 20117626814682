import { Message } from 'google-protobuf';
import {
  ClientReadableStream,
  RpcError,
  Metadata,
  Request,
  StreamInterceptor
} from 'grpc-web';

interface HasDevTools {
  __GRPCWEB_DEVTOOLS__: (clients: any[]) => void;
}

export const enableDevTools = (client: unknown): void => {
  const obj = window as unknown as HasDevTools;
  if (obj && obj.__GRPCWEB_DEVTOOLS__) {
    obj.__GRPCWEB_DEVTOOLS__([client]);
  }
};

interface GRPCInfo {
  jwt?: string;
}

export const getMetadata = ({ jwt }: GRPCInfo): Metadata => {
  const md = {};
  if (jwt) {
    md['x-pomerium-recovery-token'] = jwt;
  }
  return md;
};

export type WithMetadata<T> = {
  metadata: Metadata;
  response: T;
};

type ReadableStreamMethod<TRequest, TResponse> = (
  request: TRequest,
  metadata: Metadata,
  callback: (err: RpcError, response: TResponse) => void
) => ClientReadableStream<TResponse>;

type UnaryCallMethod<TRequest, TResult> = (
  request: TRequest,
  metadata: Metadata
) => Promise<TResult>;

export const withMetadata = <TRequest extends Message, TResponse>(
  method: ReadableStreamMethod<TRequest, TResponse>
): UnaryCallMethod<TRequest, WithMetadata<TResponse>> => {
  return (
    request: TRequest,
    metadata: Metadata
  ): Promise<WithMetadata<TResponse>> => {
    return new Promise<WithMetadata<TResponse>>((resolve, reject): void => {
      let responseMetadata = {};
      const call = method(request, metadata, (err, response): void => {
        if (err) {
          reject(err);
        } else {
          resolve({ response, metadata: responseMetadata });
        }
      });
      call.on('metadata', (status: Metadata): void => {
        responseMetadata = status;
      });
    });
  };
};

interface ClientType<T> {
  new (
    hostname: string,
    credentials?: null | { [index: string]: string },
    options?: null | { [index: string]: any }
  ): T;
}

let reloadOnUnauthenticatedTimeout;

class StreamDetectLogoutInterceptor<TReq, TRes>
  implements StreamInterceptor<TReq, TRes>
{
  intercept(
    request: Request<TReq, TRes>,
    invoker: (request: Request<TReq, TRes>) => ClientReadableStream<TRes>
  ): ClientReadableStream<TRes> {
    const stream = invoker(request);
    stream.on('error', (err: RpcError): void => {
      const unauthenticatedCode = 16;
      // this happens if a session is no longer valid, so we reload the page to force
      // the login flow
      if (err.code === unauthenticatedCode) {
        clearTimeout(reloadOnUnauthenticatedTimeout);
        reloadOnUnauthenticatedTimeout = setTimeout(() => {
          if (location.pathname !== '/' && location.pathname != '/login') {
            location.reload();
          }
        }, 3000);
      }
    });
    return stream;
  }
}

// getClient returns a grpc-web client, enables the dev tools, and adds interceptors.
export const getClient = <T>(type: ClientType<T>): T => {
  const client = new type(location.origin, null, {
    streamInterceptors: [new StreamDetectLogoutInterceptor()]
  });
  enableDevTools(client);
  return client;
};
