import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { pushError, pushSuccess } from './notifications';

import { SettingsServiceClient } from 'src/pb/SettingsServiceClientPb';
import {
  GetSettingsRequest,
  Settings,
  SetSettingsRequest
} from 'src/pb/settings_pb';
import { AppThunk, SettingsState, Tuple, fieldName } from 'src/store/state';
import { getErrorMessage } from 'src/utils/errors';
import { getClient, getMetadata } from 'src/utils/grpc';

export const REQUEST_PARAMS = 'RequestParams';
export const SET_RESPONSE_HEADERS = 'SetResponseHeaders';
export const JWT_CLAIMS_HEADERS = 'JwtClaimsHeaders';

const initialState: SettingsState = {
  settings: new Settings(),
  mapsAsArrays: {},
  saving: false,
  disabled: true
};

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    getSettings(state: SettingsState, action: PayloadAction<Settings>): void {
      state.settings = action.payload;
      [REQUEST_PARAMS, SET_RESPONSE_HEADERS, JWT_CLAIMS_HEADERS].map((key) => {
        state.mapsAsArrays[key] = action.payload['get' + key + 'Map']()
          .toArray()
          .map((entry) => {
            return { key: entry[0], value: entry[1] };
          });
      });
      state.saving = false;
    },

    setMapAsArray(
      state: SettingsState,
      action: PayloadAction<{ fieldName: fieldName; arr: Tuple[] }>
    ): void {
      state.mapsAsArrays[action.payload.fieldName] = action.payload.arr;
    },

    setSaving(state: SettingsState, action: PayloadAction<boolean>): void {
      state.saving = action.payload;
    },

    setDisabled(state: SettingsState, action: PayloadAction<boolean>): void {
      state.disabled = action.payload;
    }
  }
});

export const { reducer } = slice;

const client = getClient(SettingsServiceClient);

export const getSettings =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const req = new GetSettingsRequest();
    const res = await client.getSettings(req, getMetadata(getState().session));
    dispatch(slice.actions.getSettings(res.getSettings()));
  };

export const setMapAsArray =
  (fieldName: fieldName, arr: Tuple[]): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setMapAsArray({ fieldName, arr }));
  };

export const setSettings =
  (settings: Settings): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(slice.actions.setSaving(true));
    const req = new SetSettingsRequest();

    [REQUEST_PARAMS, SET_RESPONSE_HEADERS, JWT_CLAIMS_HEADERS].map((key) => {
      settings['clear' + key + 'Map']();
      const asArray = getState().settings.mapsAsArrays[key];
      if (asArray?.length) {
        asArray.forEach((entry) =>
          settings['get' + key + 'Map']().set(entry.key, entry.value)
        );
      }
    });

    req.setSettings(settings);
    try {
      const res = await client.setSettings(
        req,
        getMetadata(getState().session)
      );
      dispatch(slice.actions.getSettings(res.getSettings()));
      dispatch(pushSuccess('Settings Saved!'));
    } catch (e) {
      dispatch(pushError(getErrorMessage(e)));
    } finally {
      dispatch(slice.actions.setSaving(false));
    }
  };
