import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';

import history from './history';

import { reducer as activityLogReducer } from 'src/slices/activity_log';
import { reducer as devicesReducer } from 'src/slices/devices';
import { reducer as directoryReducer } from 'src/slices/directory';
import { reducer as keyChainReducer } from 'src/slices/key_chain';
import { reducer as namespacesReducer } from 'src/slices/namespaces';
import { reducer as notificationsReducer } from 'src/slices/notifications';
import { reducer as policiesReducer } from 'src/slices/policies';
import { reducer as pomeriumServiceAccountsReducer } from 'src/slices/pomerium_service_accounts';
import { reducer as pomeriumSessionsReducer } from 'src/slices/pomerium_sessions';
import { reducer as routesReducer } from 'src/slices/routes';
import { reducer as sessionReducer } from 'src/slices/session';
import { reducer as settingsReducer } from 'src/slices/settings';

const rootReducer = combineReducers({
  router: connectRouter(history),
  session: sessionReducer,
  form: formReducer,
  activityLog: activityLogReducer,
  directory: directoryReducer,
  settings: settingsReducer,
  routes: routesReducer,
  policies: policiesReducer,
  namespaces: namespacesReducer,
  pomeriumServiceAccounts: pomeriumServiceAccountsReducer,
  pomeriumSessions: pomeriumSessionsReducer,
  keyChains: keyChainReducer,
  devices: devicesReducer,
  notifications: notificationsReducer
});

export default rootReducer;
