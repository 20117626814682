import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import Chip from '@mui/material/Chip';
import Collapse from '@mui/material/Collapse';
import React, { useEffect, useState } from 'react';
import { FC, ReactNode } from 'react';
import {
  matchPath,
  NavLink as RouterLink,
  useLocation
} from 'react-router-dom';

interface NavItemProps {
  children?: ReactNode;
  className?: string;
  depth: number;
  href?: string;
  icon?: any;
  info?: any;
  title: string;
  disabled: boolean;
  beta?: boolean;
}

const NavItem: FC<NavItemProps> = ({
  children,
  depth,
  href,
  icon: Icon,
  info: Info,
  title,
  disabled,
  beta,
  ...rest
}) => {
  const { pathname } = useLocation();
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    const match = matchPath(pathname, {
      path: href.split('?')[0],
      exact: false,
      strict: false
    });
    setOpen(match !== null);
  }, [pathname]);

  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = { paddingLeft };

  if (children) {
    return (
      <>
        <ListItemButton
          disableGutters
          key={title}
          {...rest}
          selected={open}
          sx={{
            color: 'inherit',
            padding: '6px 8px',
            justifyContent: 'flex-start',
            textTransform: 'none',
            width: '100%',
            ...style
          }}
          onClick={handleToggle}
        >
          {Icon && (
            <ListItemIcon>
              <Icon
                sx={{
                  fontSize: '0.95rem',
                  fontWeight: 500
                }}
                size="20"
              />
            </ListItemIcon>
          )}
          <ListItemText primary={title} />
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItemButton>
        <Collapse in={open}>{children}</Collapse>
      </>
    );
  }

  return (
    <ListItemButton
      disableGutters
      key={title}
      component={RouterLink}
      exact
      to={href}
      disabled={disabled}
      {...rest}
      selected={open}
      sx={{
        padding: '6px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        width: '100%'
      }}
      onClick={handleToggle}
    >
      {Icon && (
        <ListItemIcon>
          <Icon
            color={open ? 'secondary' : 'primary'}
            sx={{
              fontSize: '0.95rem',
              fontWeight: 500
            }}
            size="20"
          />
        </ListItemIcon>
      )}
      <ListItemText
        primary={title}
        sx={{
          color: open ? 'text.secondary' : 'text.primary'
        }}
      />
      {Info && <Info />}
      {beta && (
        <Chip
          label="BETA"
          sx={{
            backgroundColor: 'primary.main',
            color: 'secondary.main'
          }}
          size="small"
        />
      )}
    </ListItemButton>
  );
};

export default NavItem;
