export enum Permission {
  Unknown = 'Unknown',
  Viewer = 'Viewer',
  Manager = 'Manager',
  Admin = 'Admin'
}

export enum PermissionNumericComparator {
  Unknown = 0,
  Viewer = 1,
  Manager = 2,
  Admin = 3
}

export function getPermission(str: string): Permission {
  switch (str?.toLowerCase()) {
    case 'admin':
      return Permission.Admin;
    case 'manager':
      return Permission.Manager;
    case 'viewer':
      return Permission.Viewer;
    default:
      return Permission.Unknown;
  }
}

export function getPermissionAsNumeric(
  str: string
): PermissionNumericComparator {
  switch (str?.toLowerCase()) {
    case 'admin':
      return PermissionNumericComparator.Admin;
    case 'manager':
      return PermissionNumericComparator.Manager;
    case 'viewer':
      return PermissionNumericComparator.Viewer;
    default:
      return PermissionNumericComparator.Unknown;
  }
}
