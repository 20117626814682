import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  CssBaseline
} from '@mui/material';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { ConnectedRouter } from 'connected-react-router';
import { SnackbarProvider } from 'notistack';
import React, { FC } from 'react';

import SnackbarCloseButton from './components/SnackbarCloseButton';
import history from './store/history';
import { FatalErrorView } from './views/errors/FatalErrorView';

import Routes from 'src/Routes';
import ScrollReset from 'src/components/ScrollReset';
import useSettings from 'src/hooks/useSettings';
import { xgrid_key } from 'src/licenses/xgrid_key';
import { createCustomTheme } from 'src/theme';

//X-Grid License Key Installation for Material-Ui
//https://material-ui.com/components/data-grid/getting-started/#license-key-installation
LicenseInfo.setLicenseKey(xgrid_key);

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const App: FC<{ error?: string }> = ({ error }) => {
  const { settings } = useSettings();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={createCustomTheme(settings)}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SnackbarProvider
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            maxSnack={3}
            action={(snackbarKey) => (
              <SnackbarCloseButton snackbarKey={snackbarKey} />
            )}
          >
            <ConnectedRouter history={history}>
              <ScrollReset />
              {error ? <FatalErrorView error={error} /> : <Routes />}
            </ConnectedRouter>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
