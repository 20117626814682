import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import PropTypes from 'prop-types';
import React, { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import NavBarFooter from './NavBarFooter';
import NavItem from './NavItem';
import { Item, mapPermissionsToSections, filterSections } from './helpers';

import { useSelector } from 'src/store';
import { LEFT_NAV_WIDTH } from 'src/theme/constants';
import { getPermissionAsNumeric } from 'src/types/permission';

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

function renderNavItems({
  items,
  depth = 0
}: {
  items: Item[];
  depth?: number;
}): JSX.Element {
  return (
    <List disablePadding>
      {items.reduce((acc, item) => reduceChildRoutes({ acc, item, depth }), [])}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  item,
  depth
}: {
  acc: any[];
  item: Item;
  depth: number;
}): JSX.Element[] {
  const key = item.title + depth;

  if (item.items) {
    acc.push(
      // Renders if nested and needs a click to expand.
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
        disabled={item.disabled}
        beta={!!item.beta}
      >
        {renderNavItems({
          depth: depth + 1,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      // Renders if no children and needs not to be expanded
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
        disabled={item.disabled}
        beta={!!item.beta}
      />
    );
  }

  return acc;
}

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const { currentNamespace } = useSelector((state) => state.namespaces);
  const availableNavItems = mapPermissionsToSections(
    getPermissionAsNumeric(currentNamespace[`permission`]),
    currentNamespace.id
  );

  const enabledNavItems = filterSections(availableNavItems);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      sx={{
        marginTop: 8
      }}
    >
      <Box p={2} flexGrow={1}>
        {enabledNavItems.map((section) => (
          <List
            key={section.subheader}
            subheader={
              <ListSubheader
                disableGutters
                disableSticky
                sx={{
                  textTransform: 'uppercase',
                  letterSpacing: '0.65px',
                  fontWeight: 650,
                  fontSize: '.75rem'
                }}
              >
                {section.subheader}
              </ListSubheader>
            }
          >
            {renderNavItems({
              items: section.items
            })}
          </List>
        ))}
      </Box>
      <Divider />
      <NavBarFooter />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          sx={{
            paper: {
              width: LEFT_NAV_WIDTH
            }
          }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          sx={{
            paper: {
              width: LEFT_NAV_WIDTH,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
