import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { pushError } from './notifications';

import { UserServiceClient } from 'src/pb/UsersServiceClientPb';
import {
  GetUserInfoRequest,
  QueryGroupsRequest,
  QueryGroupsResponse,
  QueryUsersRequest,
  QueryUsersResponse,
  UserInfo
} from 'src/pb/users_pb';
import { AppThunk, DirectoryState } from 'src/store/state';
import { getErrorMessage } from 'src/utils/errors';
import { getClient, getMetadata } from 'src/utils/grpc';

const initialState: DirectoryState = {
  usersById: {},
  allGroupsById: {},
  users: null,
  groups: null,
  loading: false
};

const slice = createSlice({
  name: 'directory',
  initialState,
  reducers: {
    setGroups(
      state: DirectoryState,
      action: PayloadAction<QueryGroupsResponse>
    ): void {
      if (!action.payload) {
        state.groups = null;
        state.loading = true;
      } else {
        state.groups = {
          entries: action.payload.getGroupsList(),
          total: action.payload.getTotalCount()
        };
        state.loading = false;
      }
    },

    setAllGroups(
      state: DirectoryState,
      action: PayloadAction<QueryGroupsResponse>
    ): void {
      action.payload.getGroupsList().map((group) => {
        state.allGroupsById[group.getId()] = group.toObject();
      });
    },

    setUsersById(
      state: DirectoryState,
      action: PayloadAction<UserInfo.AsObject[]>
    ): void {
      if (action.payload) {
        action.payload.map((userInfo) => {
          state.usersById[userInfo.id] = userInfo;
        });
      }
    },

    setUsers(
      state: DirectoryState,
      action: PayloadAction<QueryUsersResponse>
    ): void {
      if (!action.payload) {
        state.users = null;
        state.loading = true;
      } else {
        state.users = {
          entries: action.payload.getUsersList(),
          total: action.payload.getTotalCount()
        };
        state.loading = false;
      }
    }
  }
});

export const { reducer } = slice;

const userClient = getClient(UserServiceClient);

export const queryGroups =
  (query: string, offset: number, limit: number): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(slice.actions.setGroups(null));
    const req = new QueryGroupsRequest();
    req.setQuery(query);
    req.setOffset(offset);
    req.setLimit(limit);
    try {
      const res = await userClient.queryGroups(
        req,
        getMetadata(getState().session)
      );
      dispatch(slice.actions.setGroups(res));
    } catch (e) {
      dispatch(pushError(getErrorMessage(e)));
    }
  };

export const queryAllGroupsById =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const req = new QueryGroupsRequest();
    req.setLimit(100000);
    try {
      const res = await userClient.queryGroups(
        req,
        getMetadata(getState().session)
      );
      dispatch(slice.actions.setAllGroups(res));
    } catch (e) {
      dispatch(pushError(getErrorMessage(e)));
    }
  };

export const queryUsersByIds =
  (userIds: string[]): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    try {
      const users: UserInfo.AsObject[] = [];
      const req = new GetUserInfoRequest();
      await Promise.all(
        userIds.map(async (userId) => {
          req.setUserId(userId);
          const res = await userClient.getUserInfo(
            req,
            getMetadata(getState().session)
          );
          const user = await res.getUserInfo().toObject();
          users.push(user);
        })
      );
      dispatch(slice.actions.setUsersById(users));
    } catch (e) {
      dispatch(pushError(getErrorMessage(e)));
    }
  };

export const queryUsers =
  (query: string, offset: number, limit: number): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(slice.actions.setUsers(null));
    const req = new QueryUsersRequest();
    req.setQuery(query);
    req.setOffset(offset);
    req.setLimit(limit);
    try {
      const res = await userClient.queryUsers(
        req,
        getMetadata(getState().session)
      );
      dispatch(slice.actions.setUsers(res));
    } catch (e) {
      dispatch(pushError(getErrorMessage(e)));
    }
  };
