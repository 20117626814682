import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { pushError } from './notifications';

import { PomeriumSessionServiceClient } from 'src/pb/UsersServiceClientPb';
import {
  GetPomeriumSessionRequest,
  GetPomeriumSessionResponse,
  DeletePomeriumSessionRequest,
  ListPomeriumSessionsRequest,
  ListPomeriumSessionsResponse
} from 'src/pb/users_pb';
import { AppThunk, PomeriumSessionState } from 'src/store/state';
import { getErrorMessage } from 'src/utils/errors';
import { getClient, getMetadata } from 'src/utils/grpc';

const initialState: PomeriumSessionState = {
  associatedSessions: [],
  records: [],
  record: null,
  total: 0,
  loading: false,
  saving: false
};

const slice = createSlice({
  name: 'sessions',
  initialState,
  reducers: {
    getPomeriumSession(
      state: PomeriumSessionState,
      action: PayloadAction<GetPomeriumSessionResponse>
    ): void {
      state.record = action.payload?.getSession();
      state.loading = !action.payload;
      state.associatedSessions = action.payload?.getAssociatedSessionsList();
    },

    listPomeriumSessions(
      state: PomeriumSessionState,
      action: PayloadAction<ListPomeriumSessionsResponse>
    ): void {
      state.records = action?.payload?.getSessionsList();
      state.total = action?.payload?.getTotalCount() || 0;
      state.loading = !action.payload;
    }
  }
});

export const { reducer } = slice;

const client = getClient(PomeriumSessionServiceClient);

export const getPomeriumSession =
  (id: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(slice.actions.getPomeriumSession(null));
    if (id) {
      const req = new GetPomeriumSessionRequest();
      req.setId(id);
      try {
        const res = await client.getPomeriumSession(
          req,
          getMetadata(getState().session)
        );
        dispatch(slice.actions.getPomeriumSession(res));
      } catch (e) {
        dispatch(pushError(getErrorMessage(e)));
      }
    }
  };

export const listPomeriumSessions =
  (req?: ListPomeriumSessionsRequest): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    if (!req) {
      req = new ListPomeriumSessionsRequest();
    }
    try {
      const res = await client.listPomeriumSessions(
        req,
        getMetadata(getState().session)
      );
      dispatch(slice.actions.listPomeriumSessions(res));
    } catch (e) {
      dispatch(pushError(getErrorMessage(e)));
    }
  };

export const deletePomeriumSession =
  (id: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const req = new DeletePomeriumSessionRequest();
    req.setId(id);
    await client.deletePomeriumSession(req, getMetadata(getState().session));
  };

export const deletePomeriumSessions =
  (ids: string[], listReq?: ListPomeriumSessionsRequest): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    try {
      for (const id of ids) {
        const req = new DeletePomeriumSessionRequest();
        req.setId(id);
        await client.deletePomeriumSession(
          req,
          getMetadata(getState().session)
        );
      }
    } finally {
      dispatch(
        listPomeriumSessions(listReq || new ListPomeriumSessionsRequest())
      );
    }
  };
