// source: envoy/config/cluster/v3/outlier_detection.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js');
goog.object.extend(proto, google_protobuf_duration_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var udpa_annotations_status_pb = require('../../../../udpa/annotations/status_pb.js');
goog.object.extend(proto, udpa_annotations_status_pb);
var udpa_annotations_versioning_pb = require('../../../../udpa/annotations/versioning_pb.js');
goog.object.extend(proto, udpa_annotations_versioning_pb);
var validate_validate_pb = require('../../../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol(
  'proto.envoy.config.cluster.v3.OutlierDetection',
  null,
  global
);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.cluster.v3.OutlierDetection = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.envoy.config.cluster.v3.OutlierDetection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.cluster.v3.OutlierDetection.displayName =
    'proto.envoy.config.cluster.v3.OutlierDetection';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.cluster.v3.OutlierDetection.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.config.cluster.v3.OutlierDetection.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.cluster.v3.OutlierDetection} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.cluster.v3.OutlierDetection.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        consecutive5xx:
          (f = msg.getConsecutive5xx()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
        interval:
          (f = msg.getInterval()) &&
          google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
        baseEjectionTime:
          (f = msg.getBaseEjectionTime()) &&
          google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
        maxEjectionPercent:
          (f = msg.getMaxEjectionPercent()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
        enforcingConsecutive5xx:
          (f = msg.getEnforcingConsecutive5xx()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
        enforcingSuccessRate:
          (f = msg.getEnforcingSuccessRate()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
        successRateMinimumHosts:
          (f = msg.getSuccessRateMinimumHosts()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
        successRateRequestVolume:
          (f = msg.getSuccessRateRequestVolume()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
        successRateStdevFactor:
          (f = msg.getSuccessRateStdevFactor()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
        consecutiveGatewayFailure:
          (f = msg.getConsecutiveGatewayFailure()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
        enforcingConsecutiveGatewayFailure:
          (f = msg.getEnforcingConsecutiveGatewayFailure()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
        splitExternalLocalOriginErrors: jspb.Message.getBooleanFieldWithDefault(
          msg,
          12,
          false
        ),
        consecutiveLocalOriginFailure:
          (f = msg.getConsecutiveLocalOriginFailure()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
        enforcingConsecutiveLocalOriginFailure:
          (f = msg.getEnforcingConsecutiveLocalOriginFailure()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
        enforcingLocalOriginSuccessRate:
          (f = msg.getEnforcingLocalOriginSuccessRate()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
        failurePercentageThreshold:
          (f = msg.getFailurePercentageThreshold()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
        enforcingFailurePercentage:
          (f = msg.getEnforcingFailurePercentage()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
        enforcingFailurePercentageLocalOrigin:
          (f = msg.getEnforcingFailurePercentageLocalOrigin()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
        failurePercentageMinimumHosts:
          (f = msg.getFailurePercentageMinimumHosts()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
        failurePercentageRequestVolume:
          (f = msg.getFailurePercentageRequestVolume()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
        maxEjectionTime:
          (f = msg.getMaxEjectionTime()) &&
          google_protobuf_duration_pb.Duration.toObject(includeInstance, f)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection}
 */
proto.envoy.config.cluster.v3.OutlierDetection.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.cluster.v3.OutlierDetection();
  return proto.envoy.config.cluster.v3.OutlierDetection.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.cluster.v3.OutlierDetection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection}
 */
proto.envoy.config.cluster.v3.OutlierDetection.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new google_protobuf_wrappers_pb.UInt32Value();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
          );
          msg.setConsecutive5xx(value);
          break;
        case 2:
          var value = new google_protobuf_duration_pb.Duration();
          reader.readMessage(
            value,
            google_protobuf_duration_pb.Duration.deserializeBinaryFromReader
          );
          msg.setInterval(value);
          break;
        case 3:
          var value = new google_protobuf_duration_pb.Duration();
          reader.readMessage(
            value,
            google_protobuf_duration_pb.Duration.deserializeBinaryFromReader
          );
          msg.setBaseEjectionTime(value);
          break;
        case 4:
          var value = new google_protobuf_wrappers_pb.UInt32Value();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
          );
          msg.setMaxEjectionPercent(value);
          break;
        case 5:
          var value = new google_protobuf_wrappers_pb.UInt32Value();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
          );
          msg.setEnforcingConsecutive5xx(value);
          break;
        case 6:
          var value = new google_protobuf_wrappers_pb.UInt32Value();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
          );
          msg.setEnforcingSuccessRate(value);
          break;
        case 7:
          var value = new google_protobuf_wrappers_pb.UInt32Value();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
          );
          msg.setSuccessRateMinimumHosts(value);
          break;
        case 8:
          var value = new google_protobuf_wrappers_pb.UInt32Value();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
          );
          msg.setSuccessRateRequestVolume(value);
          break;
        case 9:
          var value = new google_protobuf_wrappers_pb.UInt32Value();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
          );
          msg.setSuccessRateStdevFactor(value);
          break;
        case 10:
          var value = new google_protobuf_wrappers_pb.UInt32Value();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
          );
          msg.setConsecutiveGatewayFailure(value);
          break;
        case 11:
          var value = new google_protobuf_wrappers_pb.UInt32Value();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
          );
          msg.setEnforcingConsecutiveGatewayFailure(value);
          break;
        case 12:
          var value = /** @type {boolean} */ (reader.readBool());
          msg.setSplitExternalLocalOriginErrors(value);
          break;
        case 13:
          var value = new google_protobuf_wrappers_pb.UInt32Value();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
          );
          msg.setConsecutiveLocalOriginFailure(value);
          break;
        case 14:
          var value = new google_protobuf_wrappers_pb.UInt32Value();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
          );
          msg.setEnforcingConsecutiveLocalOriginFailure(value);
          break;
        case 15:
          var value = new google_protobuf_wrappers_pb.UInt32Value();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
          );
          msg.setEnforcingLocalOriginSuccessRate(value);
          break;
        case 16:
          var value = new google_protobuf_wrappers_pb.UInt32Value();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
          );
          msg.setFailurePercentageThreshold(value);
          break;
        case 17:
          var value = new google_protobuf_wrappers_pb.UInt32Value();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
          );
          msg.setEnforcingFailurePercentage(value);
          break;
        case 18:
          var value = new google_protobuf_wrappers_pb.UInt32Value();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
          );
          msg.setEnforcingFailurePercentageLocalOrigin(value);
          break;
        case 19:
          var value = new google_protobuf_wrappers_pb.UInt32Value();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
          );
          msg.setFailurePercentageMinimumHosts(value);
          break;
        case 20:
          var value = new google_protobuf_wrappers_pb.UInt32Value();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
          );
          msg.setFailurePercentageRequestVolume(value);
          break;
        case 21:
          var value = new google_protobuf_duration_pb.Duration();
          reader.readMessage(
            value,
            google_protobuf_duration_pb.Duration.deserializeBinaryFromReader
          );
          msg.setMaxEjectionTime(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.cluster.v3.OutlierDetection.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.cluster.v3.OutlierDetection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.cluster.v3.OutlierDetection.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getConsecutive5xx();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
      );
    }
    f = message.getInterval();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        google_protobuf_duration_pb.Duration.serializeBinaryToWriter
      );
    }
    f = message.getBaseEjectionTime();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        google_protobuf_duration_pb.Duration.serializeBinaryToWriter
      );
    }
    f = message.getMaxEjectionPercent();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
      );
    }
    f = message.getEnforcingConsecutive5xx();
    if (f != null) {
      writer.writeMessage(
        5,
        f,
        google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
      );
    }
    f = message.getEnforcingSuccessRate();
    if (f != null) {
      writer.writeMessage(
        6,
        f,
        google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
      );
    }
    f = message.getSuccessRateMinimumHosts();
    if (f != null) {
      writer.writeMessage(
        7,
        f,
        google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
      );
    }
    f = message.getSuccessRateRequestVolume();
    if (f != null) {
      writer.writeMessage(
        8,
        f,
        google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
      );
    }
    f = message.getSuccessRateStdevFactor();
    if (f != null) {
      writer.writeMessage(
        9,
        f,
        google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
      );
    }
    f = message.getConsecutiveGatewayFailure();
    if (f != null) {
      writer.writeMessage(
        10,
        f,
        google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
      );
    }
    f = message.getEnforcingConsecutiveGatewayFailure();
    if (f != null) {
      writer.writeMessage(
        11,
        f,
        google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
      );
    }
    f = message.getSplitExternalLocalOriginErrors();
    if (f) {
      writer.writeBool(12, f);
    }
    f = message.getConsecutiveLocalOriginFailure();
    if (f != null) {
      writer.writeMessage(
        13,
        f,
        google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
      );
    }
    f = message.getEnforcingConsecutiveLocalOriginFailure();
    if (f != null) {
      writer.writeMessage(
        14,
        f,
        google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
      );
    }
    f = message.getEnforcingLocalOriginSuccessRate();
    if (f != null) {
      writer.writeMessage(
        15,
        f,
        google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
      );
    }
    f = message.getFailurePercentageThreshold();
    if (f != null) {
      writer.writeMessage(
        16,
        f,
        google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
      );
    }
    f = message.getEnforcingFailurePercentage();
    if (f != null) {
      writer.writeMessage(
        17,
        f,
        google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
      );
    }
    f = message.getEnforcingFailurePercentageLocalOrigin();
    if (f != null) {
      writer.writeMessage(
        18,
        f,
        google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
      );
    }
    f = message.getFailurePercentageMinimumHosts();
    if (f != null) {
      writer.writeMessage(
        19,
        f,
        google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
      );
    }
    f = message.getFailurePercentageRequestVolume();
    if (f != null) {
      writer.writeMessage(
        20,
        f,
        google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
      );
    }
    f = message.getMaxEjectionTime();
    if (f != null) {
      writer.writeMessage(
        21,
        f,
        google_protobuf_duration_pb.Duration.serializeBinaryToWriter
      );
    }
  };

/**
 * optional google.protobuf.UInt32Value consecutive_5xx = 1;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.getConsecutive5xx =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        1
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection} returns this
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.setConsecutive5xx =
  function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection} returns this
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.clearConsecutive5xx =
  function () {
    return this.setConsecutive5xx(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.hasConsecutive5xx =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

/**
 * optional google.protobuf.Duration interval = 2;
 * @return {?proto.google.protobuf.Duration}
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.getInterval =
  function () {
    return /** @type{?proto.google.protobuf.Duration} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_duration_pb.Duration,
        2
      )
    );
  };

/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection} returns this
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.setInterval =
  function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection} returns this
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.clearInterval =
  function () {
    return this.setInterval(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.hasInterval =
  function () {
    return jspb.Message.getField(this, 2) != null;
  };

/**
 * optional google.protobuf.Duration base_ejection_time = 3;
 * @return {?proto.google.protobuf.Duration}
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.getBaseEjectionTime =
  function () {
    return /** @type{?proto.google.protobuf.Duration} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_duration_pb.Duration,
        3
      )
    );
  };

/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection} returns this
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.setBaseEjectionTime =
  function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection} returns this
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.clearBaseEjectionTime =
  function () {
    return this.setBaseEjectionTime(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.hasBaseEjectionTime =
  function () {
    return jspb.Message.getField(this, 3) != null;
  };

/**
 * optional google.protobuf.UInt32Value max_ejection_percent = 4;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.getMaxEjectionPercent =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        4
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection} returns this
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.setMaxEjectionPercent =
  function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection} returns this
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.clearMaxEjectionPercent =
  function () {
    return this.setMaxEjectionPercent(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.hasMaxEjectionPercent =
  function () {
    return jspb.Message.getField(this, 4) != null;
  };

/**
 * optional google.protobuf.UInt32Value enforcing_consecutive_5xx = 5;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.getEnforcingConsecutive5xx =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        5
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection} returns this
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.setEnforcingConsecutive5xx =
  function (value) {
    return jspb.Message.setWrapperField(this, 5, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection} returns this
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.clearEnforcingConsecutive5xx =
  function () {
    return this.setEnforcingConsecutive5xx(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.hasEnforcingConsecutive5xx =
  function () {
    return jspb.Message.getField(this, 5) != null;
  };

/**
 * optional google.protobuf.UInt32Value enforcing_success_rate = 6;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.getEnforcingSuccessRate =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        6
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection} returns this
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.setEnforcingSuccessRate =
  function (value) {
    return jspb.Message.setWrapperField(this, 6, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection} returns this
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.clearEnforcingSuccessRate =
  function () {
    return this.setEnforcingSuccessRate(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.hasEnforcingSuccessRate =
  function () {
    return jspb.Message.getField(this, 6) != null;
  };

/**
 * optional google.protobuf.UInt32Value success_rate_minimum_hosts = 7;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.getSuccessRateMinimumHosts =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        7
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection} returns this
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.setSuccessRateMinimumHosts =
  function (value) {
    return jspb.Message.setWrapperField(this, 7, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection} returns this
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.clearSuccessRateMinimumHosts =
  function () {
    return this.setSuccessRateMinimumHosts(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.hasSuccessRateMinimumHosts =
  function () {
    return jspb.Message.getField(this, 7) != null;
  };

/**
 * optional google.protobuf.UInt32Value success_rate_request_volume = 8;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.getSuccessRateRequestVolume =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        8
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection} returns this
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.setSuccessRateRequestVolume =
  function (value) {
    return jspb.Message.setWrapperField(this, 8, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection} returns this
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.clearSuccessRateRequestVolume =
  function () {
    return this.setSuccessRateRequestVolume(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.hasSuccessRateRequestVolume =
  function () {
    return jspb.Message.getField(this, 8) != null;
  };

/**
 * optional google.protobuf.UInt32Value success_rate_stdev_factor = 9;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.getSuccessRateStdevFactor =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        9
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection} returns this
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.setSuccessRateStdevFactor =
  function (value) {
    return jspb.Message.setWrapperField(this, 9, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection} returns this
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.clearSuccessRateStdevFactor =
  function () {
    return this.setSuccessRateStdevFactor(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.hasSuccessRateStdevFactor =
  function () {
    return jspb.Message.getField(this, 9) != null;
  };

/**
 * optional google.protobuf.UInt32Value consecutive_gateway_failure = 10;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.getConsecutiveGatewayFailure =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        10
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection} returns this
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.setConsecutiveGatewayFailure =
  function (value) {
    return jspb.Message.setWrapperField(this, 10, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection} returns this
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.clearConsecutiveGatewayFailure =
  function () {
    return this.setConsecutiveGatewayFailure(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.hasConsecutiveGatewayFailure =
  function () {
    return jspb.Message.getField(this, 10) != null;
  };

/**
 * optional google.protobuf.UInt32Value enforcing_consecutive_gateway_failure = 11;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.getEnforcingConsecutiveGatewayFailure =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        11
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection} returns this
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.setEnforcingConsecutiveGatewayFailure =
  function (value) {
    return jspb.Message.setWrapperField(this, 11, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection} returns this
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.clearEnforcingConsecutiveGatewayFailure =
  function () {
    return this.setEnforcingConsecutiveGatewayFailure(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.hasEnforcingConsecutiveGatewayFailure =
  function () {
    return jspb.Message.getField(this, 11) != null;
  };

/**
 * optional bool split_external_local_origin_errors = 12;
 * @return {boolean}
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.getSplitExternalLocalOriginErrors =
  function () {
    return /** @type {boolean} */ (
      jspb.Message.getBooleanFieldWithDefault(this, 12, false)
    );
  };

/**
 * @param {boolean} value
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection} returns this
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.setSplitExternalLocalOriginErrors =
  function (value) {
    return jspb.Message.setProto3BooleanField(this, 12, value);
  };

/**
 * optional google.protobuf.UInt32Value consecutive_local_origin_failure = 13;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.getConsecutiveLocalOriginFailure =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        13
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection} returns this
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.setConsecutiveLocalOriginFailure =
  function (value) {
    return jspb.Message.setWrapperField(this, 13, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection} returns this
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.clearConsecutiveLocalOriginFailure =
  function () {
    return this.setConsecutiveLocalOriginFailure(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.hasConsecutiveLocalOriginFailure =
  function () {
    return jspb.Message.getField(this, 13) != null;
  };

/**
 * optional google.protobuf.UInt32Value enforcing_consecutive_local_origin_failure = 14;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.getEnforcingConsecutiveLocalOriginFailure =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        14
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection} returns this
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.setEnforcingConsecutiveLocalOriginFailure =
  function (value) {
    return jspb.Message.setWrapperField(this, 14, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection} returns this
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.clearEnforcingConsecutiveLocalOriginFailure =
  function () {
    return this.setEnforcingConsecutiveLocalOriginFailure(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.hasEnforcingConsecutiveLocalOriginFailure =
  function () {
    return jspb.Message.getField(this, 14) != null;
  };

/**
 * optional google.protobuf.UInt32Value enforcing_local_origin_success_rate = 15;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.getEnforcingLocalOriginSuccessRate =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        15
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection} returns this
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.setEnforcingLocalOriginSuccessRate =
  function (value) {
    return jspb.Message.setWrapperField(this, 15, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection} returns this
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.clearEnforcingLocalOriginSuccessRate =
  function () {
    return this.setEnforcingLocalOriginSuccessRate(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.hasEnforcingLocalOriginSuccessRate =
  function () {
    return jspb.Message.getField(this, 15) != null;
  };

/**
 * optional google.protobuf.UInt32Value failure_percentage_threshold = 16;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.getFailurePercentageThreshold =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        16
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection} returns this
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.setFailurePercentageThreshold =
  function (value) {
    return jspb.Message.setWrapperField(this, 16, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection} returns this
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.clearFailurePercentageThreshold =
  function () {
    return this.setFailurePercentageThreshold(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.hasFailurePercentageThreshold =
  function () {
    return jspb.Message.getField(this, 16) != null;
  };

/**
 * optional google.protobuf.UInt32Value enforcing_failure_percentage = 17;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.getEnforcingFailurePercentage =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        17
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection} returns this
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.setEnforcingFailurePercentage =
  function (value) {
    return jspb.Message.setWrapperField(this, 17, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection} returns this
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.clearEnforcingFailurePercentage =
  function () {
    return this.setEnforcingFailurePercentage(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.hasEnforcingFailurePercentage =
  function () {
    return jspb.Message.getField(this, 17) != null;
  };

/**
 * optional google.protobuf.UInt32Value enforcing_failure_percentage_local_origin = 18;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.getEnforcingFailurePercentageLocalOrigin =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        18
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection} returns this
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.setEnforcingFailurePercentageLocalOrigin =
  function (value) {
    return jspb.Message.setWrapperField(this, 18, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection} returns this
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.clearEnforcingFailurePercentageLocalOrigin =
  function () {
    return this.setEnforcingFailurePercentageLocalOrigin(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.hasEnforcingFailurePercentageLocalOrigin =
  function () {
    return jspb.Message.getField(this, 18) != null;
  };

/**
 * optional google.protobuf.UInt32Value failure_percentage_minimum_hosts = 19;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.getFailurePercentageMinimumHosts =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        19
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection} returns this
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.setFailurePercentageMinimumHosts =
  function (value) {
    return jspb.Message.setWrapperField(this, 19, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection} returns this
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.clearFailurePercentageMinimumHosts =
  function () {
    return this.setFailurePercentageMinimumHosts(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.hasFailurePercentageMinimumHosts =
  function () {
    return jspb.Message.getField(this, 19) != null;
  };

/**
 * optional google.protobuf.UInt32Value failure_percentage_request_volume = 20;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.getFailurePercentageRequestVolume =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        20
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection} returns this
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.setFailurePercentageRequestVolume =
  function (value) {
    return jspb.Message.setWrapperField(this, 20, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection} returns this
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.clearFailurePercentageRequestVolume =
  function () {
    return this.setFailurePercentageRequestVolume(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.hasFailurePercentageRequestVolume =
  function () {
    return jspb.Message.getField(this, 20) != null;
  };

/**
 * optional google.protobuf.Duration max_ejection_time = 21;
 * @return {?proto.google.protobuf.Duration}
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.getMaxEjectionTime =
  function () {
    return /** @type{?proto.google.protobuf.Duration} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_duration_pb.Duration,
        21
      )
    );
  };

/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection} returns this
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.setMaxEjectionTime =
  function (value) {
    return jspb.Message.setWrapperField(this, 21, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.cluster.v3.OutlierDetection} returns this
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.clearMaxEjectionTime =
  function () {
    return this.setMaxEjectionTime(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.cluster.v3.OutlierDetection.prototype.hasMaxEjectionTime =
  function () {
    return jspb.Message.getField(this, 21) != null;
  };

goog.object.extend(exports, proto.envoy.config.cluster.v3);
