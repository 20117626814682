import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useState } from 'react';
import { FC, ReactNode } from 'react';

import NavBar from './NavBar';
import TopBar from './TopBar';

import { LEFT_NAV_WIDTH } from 'src/theme/constants';

interface DashboardLayoutProps {
  children?: ReactNode;
}

const DashboardLayout: FC<DashboardLayoutProps> = ({ children }) => {
  const [isMobileNavOpen, setMobileNavOpen] = useState<boolean>(false);
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));
  const bg = theme.palette.background.default;
  return (
    <div
      style={{
        backgroundColor: bg,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%'
      }}
    >
      <TopBar onMobileNavOpen={(): void => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={(): void => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div
        style={{
          display: 'flex',
          flex: '1 1 auto',
          overflow: 'hidden',
          paddingTop: 64,
          paddingLeft: isLarge ? LEFT_NAV_WIDTH : 0
        }}
      >
        <div
          style={{
            display: 'flex',
            flex: '1 1 auto',
            overflow: 'hidden'
          }}
        >
          <div
            style={{
              flex: '1 1 auto',
              height: '100%',
              overflow: 'auto'
            }}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
