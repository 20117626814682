// source: udpa/annotations/status.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_descriptor_pb = require('google-protobuf/google/protobuf/descriptor_pb.js');
goog.object.extend(proto, google_protobuf_descriptor_pb);
goog.exportSymbol('proto.udpa.annotations.PackageVersionStatus', null, global);
goog.exportSymbol('proto.udpa.annotations.StatusAnnotation', null, global);
goog.exportSymbol('proto.udpa.annotations.fileStatus', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.udpa.annotations.StatusAnnotation = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.udpa.annotations.StatusAnnotation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.udpa.annotations.StatusAnnotation.displayName =
    'proto.udpa.annotations.StatusAnnotation';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.udpa.annotations.StatusAnnotation.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.udpa.annotations.StatusAnnotation.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.udpa.annotations.StatusAnnotation} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.udpa.annotations.StatusAnnotation.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        workInProgress: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
        packageVersionStatus: jspb.Message.getFieldWithDefault(msg, 2, 0)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.udpa.annotations.StatusAnnotation}
 */
proto.udpa.annotations.StatusAnnotation.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.udpa.annotations.StatusAnnotation();
  return proto.udpa.annotations.StatusAnnotation.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.udpa.annotations.StatusAnnotation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.udpa.annotations.StatusAnnotation}
 */
proto.udpa.annotations.StatusAnnotation.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setWorkInProgress(value);
        break;
      case 2:
        var value =
          /** @type {!proto.udpa.annotations.PackageVersionStatus} */ (
            reader.readEnum()
          );
        msg.setPackageVersionStatus(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.udpa.annotations.StatusAnnotation.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.udpa.annotations.StatusAnnotation.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.udpa.annotations.StatusAnnotation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.udpa.annotations.StatusAnnotation.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getWorkInProgress();
  if (f) {
    writer.writeBool(1, f);
  }
  f = message.getPackageVersionStatus();
  if (f !== 0.0) {
    writer.writeEnum(2, f);
  }
};

/**
 * optional bool work_in_progress = 1;
 * @return {boolean}
 */
proto.udpa.annotations.StatusAnnotation.prototype.getWorkInProgress =
  function () {
    return /** @type {boolean} */ (
      jspb.Message.getBooleanFieldWithDefault(this, 1, false)
    );
  };

/**
 * @param {boolean} value
 * @return {!proto.udpa.annotations.StatusAnnotation} returns this
 */
proto.udpa.annotations.StatusAnnotation.prototype.setWorkInProgress = function (
  value
) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};

/**
 * optional PackageVersionStatus package_version_status = 2;
 * @return {!proto.udpa.annotations.PackageVersionStatus}
 */
proto.udpa.annotations.StatusAnnotation.prototype.getPackageVersionStatus =
  function () {
    return /** @type {!proto.udpa.annotations.PackageVersionStatus} */ (
      jspb.Message.getFieldWithDefault(this, 2, 0)
    );
  };

/**
 * @param {!proto.udpa.annotations.PackageVersionStatus} value
 * @return {!proto.udpa.annotations.StatusAnnotation} returns this
 */
proto.udpa.annotations.StatusAnnotation.prototype.setPackageVersionStatus =
  function (value) {
    return jspb.Message.setProto3EnumField(this, 2, value);
  };

/**
 * @enum {number}
 */
proto.udpa.annotations.PackageVersionStatus = {
  UNKNOWN: 0,
  FROZEN: 1,
  ACTIVE: 2,
  NEXT_MAJOR_VERSION_CANDIDATE: 3
};

/**
 * A tuple of {field number, class constructor} for the extension
 * field named `fileStatus`.
 * @type {!jspb.ExtensionFieldInfo<!proto.udpa.annotations.StatusAnnotation>}
 */
proto.udpa.annotations.fileStatus = new jspb.ExtensionFieldInfo(
  222707719,
  { fileStatus: 0 },
  proto.udpa.annotations.StatusAnnotation,
  /** @type {?function((boolean|undefined),!jspb.Message=): !Object} */ (
    proto.udpa.annotations.StatusAnnotation.toObject
  ),
  0
);

google_protobuf_descriptor_pb.FileOptions.extensionsBinary[222707719] =
  new jspb.ExtensionFieldBinaryInfo(
    proto.udpa.annotations.fileStatus,
    jspb.BinaryReader.prototype.readMessage,
    jspb.BinaryWriter.prototype.writeMessage,
    proto.udpa.annotations.StatusAnnotation.serializeBinaryToWriter,
    proto.udpa.annotations.StatusAnnotation.deserializeBinaryFromReader,
    false
  );
// This registers the extension field with the extended class, so that
// toObject() will function correctly.
google_protobuf_descriptor_pb.FileOptions.extensions[222707719] =
  proto.udpa.annotations.fileStatus;

goog.object.extend(exports, proto.udpa.annotations);
