// source: envoy/config/core/v3/extension.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var envoy_config_core_v3_config_source_pb = require('../../../../envoy/config/core/v3/config_source_pb.js');
goog.object.extend(proto, envoy_config_core_v3_config_source_pb);
var google_protobuf_any_pb = require('google-protobuf/google/protobuf/any_pb.js');
goog.object.extend(proto, google_protobuf_any_pb);
var udpa_annotations_status_pb = require('../../../../udpa/annotations/status_pb.js');
goog.object.extend(proto, udpa_annotations_status_pb);
var validate_validate_pb = require('../../../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol(
  'proto.envoy.config.core.v3.ExtensionConfigSource',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.core.v3.TypedExtensionConfig',
  null,
  global
);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.TypedExtensionConfig = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.envoy.config.core.v3.TypedExtensionConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.TypedExtensionConfig.displayName =
    'proto.envoy.config.core.v3.TypedExtensionConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.ExtensionConfigSource = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.envoy.config.core.v3.ExtensionConfigSource.repeatedFields_,
    null
  );
};
goog.inherits(proto.envoy.config.core.v3.ExtensionConfigSource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.ExtensionConfigSource.displayName =
    'proto.envoy.config.core.v3.ExtensionConfigSource';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.TypedExtensionConfig.prototype.toObject =
    function (opt_includeInstance) {
      return proto.envoy.config.core.v3.TypedExtensionConfig.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.TypedExtensionConfig} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.TypedExtensionConfig.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        name: jspb.Message.getFieldWithDefault(msg, 1, ''),
        typedConfig:
          (f = msg.getTypedConfig()) &&
          google_protobuf_any_pb.Any.toObject(includeInstance, f)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.TypedExtensionConfig}
 */
proto.envoy.config.core.v3.TypedExtensionConfig.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.TypedExtensionConfig();
  return proto.envoy.config.core.v3.TypedExtensionConfig.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.TypedExtensionConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.TypedExtensionConfig}
 */
proto.envoy.config.core.v3.TypedExtensionConfig.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setName(value);
          break;
        case 2:
          var value = new google_protobuf_any_pb.Any();
          reader.readMessage(
            value,
            google_protobuf_any_pb.Any.deserializeBinaryFromReader
          );
          msg.setTypedConfig(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.TypedExtensionConfig.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.TypedExtensionConfig.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.TypedExtensionConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.TypedExtensionConfig.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
    f = message.getTypedConfig();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        google_protobuf_any_pb.Any.serializeBinaryToWriter
      );
    }
  };

/**
 * optional string name = 1;
 * @return {string}
 */
proto.envoy.config.core.v3.TypedExtensionConfig.prototype.getName =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.TypedExtensionConfig} returns this
 */
proto.envoy.config.core.v3.TypedExtensionConfig.prototype.setName = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional google.protobuf.Any typed_config = 2;
 * @return {?proto.google.protobuf.Any}
 */
proto.envoy.config.core.v3.TypedExtensionConfig.prototype.getTypedConfig =
  function () {
    return /** @type{?proto.google.protobuf.Any} */ (
      jspb.Message.getWrapperField(this, google_protobuf_any_pb.Any, 2)
    );
  };

/**
 * @param {?proto.google.protobuf.Any|undefined} value
 * @return {!proto.envoy.config.core.v3.TypedExtensionConfig} returns this
 */
proto.envoy.config.core.v3.TypedExtensionConfig.prototype.setTypedConfig =
  function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.TypedExtensionConfig} returns this
 */
proto.envoy.config.core.v3.TypedExtensionConfig.prototype.clearTypedConfig =
  function () {
    return this.setTypedConfig(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.TypedExtensionConfig.prototype.hasTypedConfig =
  function () {
    return jspb.Message.getField(this, 2) != null;
  };

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.envoy.config.core.v3.ExtensionConfigSource.repeatedFields_ = [4];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.ExtensionConfigSource.prototype.toObject =
    function (opt_includeInstance) {
      return proto.envoy.config.core.v3.ExtensionConfigSource.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.ExtensionConfigSource} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.ExtensionConfigSource.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        configSource:
          (f = msg.getConfigSource()) &&
          envoy_config_core_v3_config_source_pb.ConfigSource.toObject(
            includeInstance,
            f
          ),
        defaultConfig:
          (f = msg.getDefaultConfig()) &&
          google_protobuf_any_pb.Any.toObject(includeInstance, f),
        applyDefaultConfigWithoutWarming:
          jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
        typeUrlsList:
          (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.ExtensionConfigSource}
 */
proto.envoy.config.core.v3.ExtensionConfigSource.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.ExtensionConfigSource();
  return proto.envoy.config.core.v3.ExtensionConfigSource.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.ExtensionConfigSource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.ExtensionConfigSource}
 */
proto.envoy.config.core.v3.ExtensionConfigSource.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new envoy_config_core_v3_config_source_pb.ConfigSource();
          reader.readMessage(
            value,
            envoy_config_core_v3_config_source_pb.ConfigSource
              .deserializeBinaryFromReader
          );
          msg.setConfigSource(value);
          break;
        case 2:
          var value = new google_protobuf_any_pb.Any();
          reader.readMessage(
            value,
            google_protobuf_any_pb.Any.deserializeBinaryFromReader
          );
          msg.setDefaultConfig(value);
          break;
        case 3:
          var value = /** @type {boolean} */ (reader.readBool());
          msg.setApplyDefaultConfigWithoutWarming(value);
          break;
        case 4:
          var value = /** @type {string} */ (reader.readString());
          msg.addTypeUrls(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.ExtensionConfigSource.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.ExtensionConfigSource.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.ExtensionConfigSource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.ExtensionConfigSource.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getConfigSource();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        envoy_config_core_v3_config_source_pb.ConfigSource
          .serializeBinaryToWriter
      );
    }
    f = message.getDefaultConfig();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        google_protobuf_any_pb.Any.serializeBinaryToWriter
      );
    }
    f = message.getApplyDefaultConfigWithoutWarming();
    if (f) {
      writer.writeBool(3, f);
    }
    f = message.getTypeUrlsList();
    if (f.length > 0) {
      writer.writeRepeatedString(4, f);
    }
  };

/**
 * optional ConfigSource config_source = 1;
 * @return {?proto.envoy.config.core.v3.ConfigSource}
 */
proto.envoy.config.core.v3.ExtensionConfigSource.prototype.getConfigSource =
  function () {
    return /** @type{?proto.envoy.config.core.v3.ConfigSource} */ (
      jspb.Message.getWrapperField(
        this,
        envoy_config_core_v3_config_source_pb.ConfigSource,
        1
      )
    );
  };

/**
 * @param {?proto.envoy.config.core.v3.ConfigSource|undefined} value
 * @return {!proto.envoy.config.core.v3.ExtensionConfigSource} returns this
 */
proto.envoy.config.core.v3.ExtensionConfigSource.prototype.setConfigSource =
  function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.ExtensionConfigSource} returns this
 */
proto.envoy.config.core.v3.ExtensionConfigSource.prototype.clearConfigSource =
  function () {
    return this.setConfigSource(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.ExtensionConfigSource.prototype.hasConfigSource =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

/**
 * optional google.protobuf.Any default_config = 2;
 * @return {?proto.google.protobuf.Any}
 */
proto.envoy.config.core.v3.ExtensionConfigSource.prototype.getDefaultConfig =
  function () {
    return /** @type{?proto.google.protobuf.Any} */ (
      jspb.Message.getWrapperField(this, google_protobuf_any_pb.Any, 2)
    );
  };

/**
 * @param {?proto.google.protobuf.Any|undefined} value
 * @return {!proto.envoy.config.core.v3.ExtensionConfigSource} returns this
 */
proto.envoy.config.core.v3.ExtensionConfigSource.prototype.setDefaultConfig =
  function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.ExtensionConfigSource} returns this
 */
proto.envoy.config.core.v3.ExtensionConfigSource.prototype.clearDefaultConfig =
  function () {
    return this.setDefaultConfig(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.ExtensionConfigSource.prototype.hasDefaultConfig =
  function () {
    return jspb.Message.getField(this, 2) != null;
  };

/**
 * optional bool apply_default_config_without_warming = 3;
 * @return {boolean}
 */
proto.envoy.config.core.v3.ExtensionConfigSource.prototype.getApplyDefaultConfigWithoutWarming =
  function () {
    return /** @type {boolean} */ (
      jspb.Message.getBooleanFieldWithDefault(this, 3, false)
    );
  };

/**
 * @param {boolean} value
 * @return {!proto.envoy.config.core.v3.ExtensionConfigSource} returns this
 */
proto.envoy.config.core.v3.ExtensionConfigSource.prototype.setApplyDefaultConfigWithoutWarming =
  function (value) {
    return jspb.Message.setProto3BooleanField(this, 3, value);
  };

/**
 * repeated string type_urls = 4;
 * @return {!Array<string>}
 */
proto.envoy.config.core.v3.ExtensionConfigSource.prototype.getTypeUrlsList =
  function () {
    return /** @type {!Array<string>} */ (
      jspb.Message.getRepeatedField(this, 4)
    );
  };

/**
 * @param {!Array<string>} value
 * @return {!proto.envoy.config.core.v3.ExtensionConfigSource} returns this
 */
proto.envoy.config.core.v3.ExtensionConfigSource.prototype.setTypeUrlsList =
  function (value) {
    return jspb.Message.setField(this, 4, value || []);
  };

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.envoy.config.core.v3.ExtensionConfigSource} returns this
 */
proto.envoy.config.core.v3.ExtensionConfigSource.prototype.addTypeUrls =
  function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
  };

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.envoy.config.core.v3.ExtensionConfigSource} returns this
 */
proto.envoy.config.core.v3.ExtensionConfigSource.prototype.clearTypeUrlsList =
  function () {
    return this.setTypeUrlsList([]);
  };

goog.object.extend(exports, proto.envoy.config.core.v3);
