import { Stack } from '@mui/material';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import SvgIcon from '@mui/material/SvgIcon';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { capitalCase } from 'change-case';
import React, { useState, useRef } from 'react';
import { FC } from 'react';

import { FilledSettingsIcon } from 'src/components/icons';
import { TABLE_ROW_COUNT_OPTIONS, THEMES } from 'src/constants';
import useSettings from 'src/hooks/useSettings';

const Settings: FC = () => {
  const ref = useRef<any>(null);
  const { settings, saveSettings } = useSettings();
  const [isOpen, setOpen] = useState<boolean>(false);
  const [values, setValues] = useState({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
    rowCount: settings.rowCount
  });

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleChange = (field, value): void => {
    setValues({
      ...values,
      [field]: value
    });
  };

  const handleSave = (): void => {
    saveSettings(values);
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Settings">
        <Badge
          color="secondary"
          sx={{
            badge: {
              height: 10,
              width: 10,
              marginTop: 10,
              marginRight: 5
            }
          }}
        >
          <IconButton
            color="inherit"
            onClick={handleOpen}
            ref={ref}
            size="large"
          >
            <SvgIcon fontSize="small">
              <FilledSettingsIcon />
            </SvgIcon>
          </IconButton>
        </Badge>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Stack sx={{ p: 1.5 }}>
          <Typography variant="h4" color="textPrimary">
            Settings
          </Typography>
          <Box mt={2} px={1}>
            <FormControlLabel
              control={
                <Switch
                  checked={values.responsiveFontSizes}
                  edge="start"
                  color="primary"
                  name="direction"
                  onChange={(event): void =>
                    handleChange('responsiveFontSizes', event.target.checked)
                  }
                />
              }
              label="Responsive font sizes"
            />
          </Box>
          <Box mt={2}>
            <TextField
              fullWidth
              label="Theme"
              name="theme"
              onChange={(event): void =>
                handleChange('theme', event.target.value)
              }
              select
              SelectProps={{ native: true }}
              value={values.theme}
              variant="outlined"
            >
              {Object.keys(THEMES).map((theme) => (
                <option key={theme} value={theme}>
                  {capitalCase(theme)}
                </option>
              ))}
            </TextField>
          </Box>
          <Box mt={2}>
            <TextField
              fullWidth
              label="Number of Table Rows Shown"
              name="rowCount"
              onChange={(event): void =>
                handleChange('rowCount', event.target.value)
              }
              select
              SelectProps={{ native: true }}
              value={values.rowCount}
              variant="outlined"
            >
              {TABLE_ROW_COUNT_OPTIONS.map((numRows) => (
                <option key={numRows} value={numRows}>
                  {numRows}
                </option>
              ))}
            </TextField>
          </Box>
          <Box mt={2}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={handleSave}
            >
              Save Settings
            </Button>
          </Box>
        </Stack>
      </Popover>
    </>
  );
};

export default Settings;
