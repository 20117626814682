// source: envoy/config/core/v3/address.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var envoy_config_core_v3_socket_option_pb = require('../../../../envoy/config/core/v3/socket_option_pb.js');
goog.object.extend(proto, envoy_config_core_v3_socket_option_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var udpa_annotations_status_pb = require('../../../../udpa/annotations/status_pb.js');
goog.object.extend(proto, udpa_annotations_status_pb);
var udpa_annotations_versioning_pb = require('../../../../udpa/annotations/versioning_pb.js');
goog.object.extend(proto, udpa_annotations_versioning_pb);
var validate_validate_pb = require('../../../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.envoy.config.core.v3.Address', null, global);
goog.exportSymbol(
  'proto.envoy.config.core.v3.Address.AddressCase',
  null,
  global
);
goog.exportSymbol('proto.envoy.config.core.v3.BindConfig', null, global);
goog.exportSymbol('proto.envoy.config.core.v3.CidrRange', null, global);
goog.exportSymbol(
  'proto.envoy.config.core.v3.EnvoyInternalAddress',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.core.v3.EnvoyInternalAddress.AddressNameSpecifierCase',
  null,
  global
);
goog.exportSymbol('proto.envoy.config.core.v3.Pipe', null, global);
goog.exportSymbol('proto.envoy.config.core.v3.SocketAddress', null, global);
goog.exportSymbol(
  'proto.envoy.config.core.v3.SocketAddress.PortSpecifierCase',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.core.v3.SocketAddress.Protocol',
  null,
  global
);
goog.exportSymbol('proto.envoy.config.core.v3.TcpKeepalive', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.Pipe = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.envoy.config.core.v3.Pipe, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.Pipe.displayName =
    'proto.envoy.config.core.v3.Pipe';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.EnvoyInternalAddress = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    null,
    proto.envoy.config.core.v3.EnvoyInternalAddress.oneofGroups_
  );
};
goog.inherits(proto.envoy.config.core.v3.EnvoyInternalAddress, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.EnvoyInternalAddress.displayName =
    'proto.envoy.config.core.v3.EnvoyInternalAddress';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.SocketAddress = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    null,
    proto.envoy.config.core.v3.SocketAddress.oneofGroups_
  );
};
goog.inherits(proto.envoy.config.core.v3.SocketAddress, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.SocketAddress.displayName =
    'proto.envoy.config.core.v3.SocketAddress';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.TcpKeepalive = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.envoy.config.core.v3.TcpKeepalive, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.TcpKeepalive.displayName =
    'proto.envoy.config.core.v3.TcpKeepalive';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.BindConfig = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.envoy.config.core.v3.BindConfig.repeatedFields_,
    null
  );
};
goog.inherits(proto.envoy.config.core.v3.BindConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.BindConfig.displayName =
    'proto.envoy.config.core.v3.BindConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.Address = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    null,
    proto.envoy.config.core.v3.Address.oneofGroups_
  );
};
goog.inherits(proto.envoy.config.core.v3.Address, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.Address.displayName =
    'proto.envoy.config.core.v3.Address';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.CidrRange = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.envoy.config.core.v3.CidrRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.CidrRange.displayName =
    'proto.envoy.config.core.v3.CidrRange';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.Pipe.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.config.core.v3.Pipe.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.Pipe} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.Pipe.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        path: jspb.Message.getFieldWithDefault(msg, 1, ''),
        mode: jspb.Message.getFieldWithDefault(msg, 2, 0)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.Pipe}
 */
proto.envoy.config.core.v3.Pipe.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.Pipe();
  return proto.envoy.config.core.v3.Pipe.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.Pipe} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.Pipe}
 */
proto.envoy.config.core.v3.Pipe.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setPath(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setMode(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.Pipe.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.envoy.config.core.v3.Pipe.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.Pipe} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.Pipe.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getMode();
  if (f !== 0) {
    writer.writeUint32(2, f);
  }
};

/**
 * optional string path = 1;
 * @return {string}
 */
proto.envoy.config.core.v3.Pipe.prototype.getPath = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.Pipe} returns this
 */
proto.envoy.config.core.v3.Pipe.prototype.setPath = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional uint32 mode = 2;
 * @return {number}
 */
proto.envoy.config.core.v3.Pipe.prototype.getMode = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.envoy.config.core.v3.Pipe} returns this
 */
proto.envoy.config.core.v3.Pipe.prototype.setMode = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.envoy.config.core.v3.EnvoyInternalAddress.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.envoy.config.core.v3.EnvoyInternalAddress.AddressNameSpecifierCase = {
  ADDRESS_NAME_SPECIFIER_NOT_SET: 0,
  SERVER_LISTENER_NAME: 1
};

/**
 * @return {proto.envoy.config.core.v3.EnvoyInternalAddress.AddressNameSpecifierCase}
 */
proto.envoy.config.core.v3.EnvoyInternalAddress.prototype.getAddressNameSpecifierCase =
  function () {
    return /** @type {proto.envoy.config.core.v3.EnvoyInternalAddress.AddressNameSpecifierCase} */ (
      jspb.Message.computeOneofCase(
        this,
        proto.envoy.config.core.v3.EnvoyInternalAddress.oneofGroups_[0]
      )
    );
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.EnvoyInternalAddress.prototype.toObject =
    function (opt_includeInstance) {
      return proto.envoy.config.core.v3.EnvoyInternalAddress.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.EnvoyInternalAddress} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.EnvoyInternalAddress.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        serverListenerName: jspb.Message.getFieldWithDefault(msg, 1, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.EnvoyInternalAddress}
 */
proto.envoy.config.core.v3.EnvoyInternalAddress.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.EnvoyInternalAddress();
  return proto.envoy.config.core.v3.EnvoyInternalAddress.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.EnvoyInternalAddress} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.EnvoyInternalAddress}
 */
proto.envoy.config.core.v3.EnvoyInternalAddress.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setServerListenerName(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.EnvoyInternalAddress.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.EnvoyInternalAddress.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.EnvoyInternalAddress} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.EnvoyInternalAddress.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = /** @type {string} */ (jspb.Message.getField(message, 1));
    if (f != null) {
      writer.writeString(1, f);
    }
  };

/**
 * optional string server_listener_name = 1;
 * @return {string}
 */
proto.envoy.config.core.v3.EnvoyInternalAddress.prototype.getServerListenerName =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.EnvoyInternalAddress} returns this
 */
proto.envoy.config.core.v3.EnvoyInternalAddress.prototype.setServerListenerName =
  function (value) {
    return jspb.Message.setOneofField(
      this,
      1,
      proto.envoy.config.core.v3.EnvoyInternalAddress.oneofGroups_[0],
      value
    );
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.envoy.config.core.v3.EnvoyInternalAddress} returns this
 */
proto.envoy.config.core.v3.EnvoyInternalAddress.prototype.clearServerListenerName =
  function () {
    return jspb.Message.setOneofField(
      this,
      1,
      proto.envoy.config.core.v3.EnvoyInternalAddress.oneofGroups_[0],
      undefined
    );
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.EnvoyInternalAddress.prototype.hasServerListenerName =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.envoy.config.core.v3.SocketAddress.oneofGroups_ = [[3, 4]];

/**
 * @enum {number}
 */
proto.envoy.config.core.v3.SocketAddress.PortSpecifierCase = {
  PORT_SPECIFIER_NOT_SET: 0,
  PORT_VALUE: 3,
  NAMED_PORT: 4
};

/**
 * @return {proto.envoy.config.core.v3.SocketAddress.PortSpecifierCase}
 */
proto.envoy.config.core.v3.SocketAddress.prototype.getPortSpecifierCase =
  function () {
    return /** @type {proto.envoy.config.core.v3.SocketAddress.PortSpecifierCase} */ (
      jspb.Message.computeOneofCase(
        this,
        proto.envoy.config.core.v3.SocketAddress.oneofGroups_[0]
      )
    );
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.SocketAddress.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.config.core.v3.SocketAddress.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.SocketAddress} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.SocketAddress.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        protocol: jspb.Message.getFieldWithDefault(msg, 1, 0),
        address: jspb.Message.getFieldWithDefault(msg, 2, ''),
        portValue: jspb.Message.getFieldWithDefault(msg, 3, 0),
        namedPort: jspb.Message.getFieldWithDefault(msg, 4, ''),
        resolverName: jspb.Message.getFieldWithDefault(msg, 5, ''),
        ipv4Compat: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.SocketAddress}
 */
proto.envoy.config.core.v3.SocketAddress.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.SocketAddress();
  return proto.envoy.config.core.v3.SocketAddress.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.SocketAddress} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.SocketAddress}
 */
proto.envoy.config.core.v3.SocketAddress.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value =
            /** @type {!proto.envoy.config.core.v3.SocketAddress.Protocol} */ (
              reader.readEnum()
            );
          msg.setProtocol(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setAddress(value);
          break;
        case 3:
          var value = /** @type {number} */ (reader.readUint32());
          msg.setPortValue(value);
          break;
        case 4:
          var value = /** @type {string} */ (reader.readString());
          msg.setNamedPort(value);
          break;
        case 5:
          var value = /** @type {string} */ (reader.readString());
          msg.setResolverName(value);
          break;
        case 6:
          var value = /** @type {boolean} */ (reader.readBool());
          msg.setIpv4Compat(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.SocketAddress.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.SocketAddress.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.SocketAddress} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.SocketAddress.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getProtocol();
  if (f !== 0.0) {
    writer.writeEnum(1, f);
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint32(3, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(4, f);
  }
  f = message.getResolverName();
  if (f.length > 0) {
    writer.writeString(5, f);
  }
  f = message.getIpv4Compat();
  if (f) {
    writer.writeBool(6, f);
  }
};

/**
 * @enum {number}
 */
proto.envoy.config.core.v3.SocketAddress.Protocol = {
  TCP: 0,
  UDP: 1
};

/**
 * optional Protocol protocol = 1;
 * @return {!proto.envoy.config.core.v3.SocketAddress.Protocol}
 */
proto.envoy.config.core.v3.SocketAddress.prototype.getProtocol = function () {
  return /** @type {!proto.envoy.config.core.v3.SocketAddress.Protocol} */ (
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};

/**
 * @param {!proto.envoy.config.core.v3.SocketAddress.Protocol} value
 * @return {!proto.envoy.config.core.v3.SocketAddress} returns this
 */
proto.envoy.config.core.v3.SocketAddress.prototype.setProtocol = function (
  value
) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};

/**
 * optional string address = 2;
 * @return {string}
 */
proto.envoy.config.core.v3.SocketAddress.prototype.getAddress = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.SocketAddress} returns this
 */
proto.envoy.config.core.v3.SocketAddress.prototype.setAddress = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional uint32 port_value = 3;
 * @return {number}
 */
proto.envoy.config.core.v3.SocketAddress.prototype.getPortValue = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.envoy.config.core.v3.SocketAddress} returns this
 */
proto.envoy.config.core.v3.SocketAddress.prototype.setPortValue = function (
  value
) {
  return jspb.Message.setOneofField(
    this,
    3,
    proto.envoy.config.core.v3.SocketAddress.oneofGroups_[0],
    value
  );
};

/**
 * Clears the field making it undefined.
 * @return {!proto.envoy.config.core.v3.SocketAddress} returns this
 */
proto.envoy.config.core.v3.SocketAddress.prototype.clearPortValue =
  function () {
    return jspb.Message.setOneofField(
      this,
      3,
      proto.envoy.config.core.v3.SocketAddress.oneofGroups_[0],
      undefined
    );
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.SocketAddress.prototype.hasPortValue = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional string named_port = 4;
 * @return {string}
 */
proto.envoy.config.core.v3.SocketAddress.prototype.getNamedPort = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.SocketAddress} returns this
 */
proto.envoy.config.core.v3.SocketAddress.prototype.setNamedPort = function (
  value
) {
  return jspb.Message.setOneofField(
    this,
    4,
    proto.envoy.config.core.v3.SocketAddress.oneofGroups_[0],
    value
  );
};

/**
 * Clears the field making it undefined.
 * @return {!proto.envoy.config.core.v3.SocketAddress} returns this
 */
proto.envoy.config.core.v3.SocketAddress.prototype.clearNamedPort =
  function () {
    return jspb.Message.setOneofField(
      this,
      4,
      proto.envoy.config.core.v3.SocketAddress.oneofGroups_[0],
      undefined
    );
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.SocketAddress.prototype.hasNamedPort = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional string resolver_name = 5;
 * @return {string}
 */
proto.envoy.config.core.v3.SocketAddress.prototype.getResolverName =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 5, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.SocketAddress} returns this
 */
proto.envoy.config.core.v3.SocketAddress.prototype.setResolverName = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 5, value);
};

/**
 * optional bool ipv4_compat = 6;
 * @return {boolean}
 */
proto.envoy.config.core.v3.SocketAddress.prototype.getIpv4Compat = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 6, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.envoy.config.core.v3.SocketAddress} returns this
 */
proto.envoy.config.core.v3.SocketAddress.prototype.setIpv4Compat = function (
  value
) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.TcpKeepalive.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.config.core.v3.TcpKeepalive.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.TcpKeepalive} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.TcpKeepalive.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        keepaliveProbes:
          (f = msg.getKeepaliveProbes()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
        keepaliveTime:
          (f = msg.getKeepaliveTime()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
        keepaliveInterval:
          (f = msg.getKeepaliveInterval()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.TcpKeepalive}
 */
proto.envoy.config.core.v3.TcpKeepalive.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.TcpKeepalive();
  return proto.envoy.config.core.v3.TcpKeepalive.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.TcpKeepalive} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.TcpKeepalive}
 */
proto.envoy.config.core.v3.TcpKeepalive.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new google_protobuf_wrappers_pb.UInt32Value();
        reader.readMessage(
          value,
          google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
        );
        msg.setKeepaliveProbes(value);
        break;
      case 2:
        var value = new google_protobuf_wrappers_pb.UInt32Value();
        reader.readMessage(
          value,
          google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
        );
        msg.setKeepaliveTime(value);
        break;
      case 3:
        var value = new google_protobuf_wrappers_pb.UInt32Value();
        reader.readMessage(
          value,
          google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
        );
        msg.setKeepaliveInterval(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.TcpKeepalive.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.TcpKeepalive.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.TcpKeepalive} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.TcpKeepalive.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getKeepaliveProbes();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getKeepaliveTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getKeepaliveInterval();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
    );
  }
};

/**
 * optional google.protobuf.UInt32Value keepalive_probes = 1;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.core.v3.TcpKeepalive.prototype.getKeepaliveProbes =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        1
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.core.v3.TcpKeepalive} returns this
 */
proto.envoy.config.core.v3.TcpKeepalive.prototype.setKeepaliveProbes =
  function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.TcpKeepalive} returns this
 */
proto.envoy.config.core.v3.TcpKeepalive.prototype.clearKeepaliveProbes =
  function () {
    return this.setKeepaliveProbes(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.TcpKeepalive.prototype.hasKeepaliveProbes =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

/**
 * optional google.protobuf.UInt32Value keepalive_time = 2;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.core.v3.TcpKeepalive.prototype.getKeepaliveTime =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        2
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.core.v3.TcpKeepalive} returns this
 */
proto.envoy.config.core.v3.TcpKeepalive.prototype.setKeepaliveTime = function (
  value
) {
  return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.TcpKeepalive} returns this
 */
proto.envoy.config.core.v3.TcpKeepalive.prototype.clearKeepaliveTime =
  function () {
    return this.setKeepaliveTime(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.TcpKeepalive.prototype.hasKeepaliveTime =
  function () {
    return jspb.Message.getField(this, 2) != null;
  };

/**
 * optional google.protobuf.UInt32Value keepalive_interval = 3;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.core.v3.TcpKeepalive.prototype.getKeepaliveInterval =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        3
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.core.v3.TcpKeepalive} returns this
 */
proto.envoy.config.core.v3.TcpKeepalive.prototype.setKeepaliveInterval =
  function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.TcpKeepalive} returns this
 */
proto.envoy.config.core.v3.TcpKeepalive.prototype.clearKeepaliveInterval =
  function () {
    return this.setKeepaliveInterval(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.TcpKeepalive.prototype.hasKeepaliveInterval =
  function () {
    return jspb.Message.getField(this, 3) != null;
  };

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.envoy.config.core.v3.BindConfig.repeatedFields_ = [3];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.BindConfig.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.config.core.v3.BindConfig.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.BindConfig} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.BindConfig.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        sourceAddress:
          (f = msg.getSourceAddress()) &&
          proto.envoy.config.core.v3.SocketAddress.toObject(includeInstance, f),
        freebind:
          (f = msg.getFreebind()) &&
          google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
        socketOptionsList: jspb.Message.toObjectList(
          msg.getSocketOptionsList(),
          envoy_config_core_v3_socket_option_pb.SocketOption.toObject,
          includeInstance
        )
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.BindConfig}
 */
proto.envoy.config.core.v3.BindConfig.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.BindConfig();
  return proto.envoy.config.core.v3.BindConfig.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.BindConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.BindConfig}
 */
proto.envoy.config.core.v3.BindConfig.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.envoy.config.core.v3.SocketAddress();
        reader.readMessage(
          value,
          proto.envoy.config.core.v3.SocketAddress.deserializeBinaryFromReader
        );
        msg.setSourceAddress(value);
        break;
      case 2:
        var value = new google_protobuf_wrappers_pb.BoolValue();
        reader.readMessage(
          value,
          google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader
        );
        msg.setFreebind(value);
        break;
      case 3:
        var value = new envoy_config_core_v3_socket_option_pb.SocketOption();
        reader.readMessage(
          value,
          envoy_config_core_v3_socket_option_pb.SocketOption
            .deserializeBinaryFromReader
        );
        msg.addSocketOptions(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.BindConfig.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.envoy.config.core.v3.BindConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.BindConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.BindConfig.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getSourceAddress();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.envoy.config.core.v3.SocketAddress.serializeBinaryToWriter
    );
  }
  f = message.getFreebind();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getSocketOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      envoy_config_core_v3_socket_option_pb.SocketOption.serializeBinaryToWriter
    );
  }
};

/**
 * optional SocketAddress source_address = 1;
 * @return {?proto.envoy.config.core.v3.SocketAddress}
 */
proto.envoy.config.core.v3.BindConfig.prototype.getSourceAddress = function () {
  return /** @type{?proto.envoy.config.core.v3.SocketAddress} */ (
    jspb.Message.getWrapperField(
      this,
      proto.envoy.config.core.v3.SocketAddress,
      1
    )
  );
};

/**
 * @param {?proto.envoy.config.core.v3.SocketAddress|undefined} value
 * @return {!proto.envoy.config.core.v3.BindConfig} returns this
 */
proto.envoy.config.core.v3.BindConfig.prototype.setSourceAddress = function (
  value
) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.BindConfig} returns this
 */
proto.envoy.config.core.v3.BindConfig.prototype.clearSourceAddress =
  function () {
    return this.setSourceAddress(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.BindConfig.prototype.hasSourceAddress = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional google.protobuf.BoolValue freebind = 2;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.envoy.config.core.v3.BindConfig.prototype.getFreebind = function () {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 2)
  );
};

/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.envoy.config.core.v3.BindConfig} returns this
 */
proto.envoy.config.core.v3.BindConfig.prototype.setFreebind = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.BindConfig} returns this
 */
proto.envoy.config.core.v3.BindConfig.prototype.clearFreebind = function () {
  return this.setFreebind(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.BindConfig.prototype.hasFreebind = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * repeated SocketOption socket_options = 3;
 * @return {!Array<!proto.envoy.config.core.v3.SocketOption>}
 */
proto.envoy.config.core.v3.BindConfig.prototype.getSocketOptionsList =
  function () {
    return /** @type{!Array<!proto.envoy.config.core.v3.SocketOption>} */ (
      jspb.Message.getRepeatedWrapperField(
        this,
        envoy_config_core_v3_socket_option_pb.SocketOption,
        3
      )
    );
  };

/**
 * @param {!Array<!proto.envoy.config.core.v3.SocketOption>} value
 * @return {!proto.envoy.config.core.v3.BindConfig} returns this
 */
proto.envoy.config.core.v3.BindConfig.prototype.setSocketOptionsList =
  function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 3, value);
  };

/**
 * @param {!proto.envoy.config.core.v3.SocketOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.envoy.config.core.v3.SocketOption}
 */
proto.envoy.config.core.v3.BindConfig.prototype.addSocketOptions = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    3,
    opt_value,
    proto.envoy.config.core.v3.SocketOption,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.envoy.config.core.v3.BindConfig} returns this
 */
proto.envoy.config.core.v3.BindConfig.prototype.clearSocketOptionsList =
  function () {
    return this.setSocketOptionsList([]);
  };

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.envoy.config.core.v3.Address.oneofGroups_ = [[1, 2, 3]];

/**
 * @enum {number}
 */
proto.envoy.config.core.v3.Address.AddressCase = {
  ADDRESS_NOT_SET: 0,
  SOCKET_ADDRESS: 1,
  PIPE: 2,
  ENVOY_INTERNAL_ADDRESS: 3
};

/**
 * @return {proto.envoy.config.core.v3.Address.AddressCase}
 */
proto.envoy.config.core.v3.Address.prototype.getAddressCase = function () {
  return /** @type {proto.envoy.config.core.v3.Address.AddressCase} */ (
    jspb.Message.computeOneofCase(
      this,
      proto.envoy.config.core.v3.Address.oneofGroups_[0]
    )
  );
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.Address.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.config.core.v3.Address.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.Address} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.Address.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        socketAddress:
          (f = msg.getSocketAddress()) &&
          proto.envoy.config.core.v3.SocketAddress.toObject(includeInstance, f),
        pipe:
          (f = msg.getPipe()) &&
          proto.envoy.config.core.v3.Pipe.toObject(includeInstance, f),
        envoyInternalAddress:
          (f = msg.getEnvoyInternalAddress()) &&
          proto.envoy.config.core.v3.EnvoyInternalAddress.toObject(
            includeInstance,
            f
          )
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.Address}
 */
proto.envoy.config.core.v3.Address.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.Address();
  return proto.envoy.config.core.v3.Address.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.Address} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.Address}
 */
proto.envoy.config.core.v3.Address.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.envoy.config.core.v3.SocketAddress();
        reader.readMessage(
          value,
          proto.envoy.config.core.v3.SocketAddress.deserializeBinaryFromReader
        );
        msg.setSocketAddress(value);
        break;
      case 2:
        var value = new proto.envoy.config.core.v3.Pipe();
        reader.readMessage(
          value,
          proto.envoy.config.core.v3.Pipe.deserializeBinaryFromReader
        );
        msg.setPipe(value);
        break;
      case 3:
        var value = new proto.envoy.config.core.v3.EnvoyInternalAddress();
        reader.readMessage(
          value,
          proto.envoy.config.core.v3.EnvoyInternalAddress
            .deserializeBinaryFromReader
        );
        msg.setEnvoyInternalAddress(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.Address.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.envoy.config.core.v3.Address.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.Address} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.Address.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getSocketAddress();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.envoy.config.core.v3.SocketAddress.serializeBinaryToWriter
    );
  }
  f = message.getPipe();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.envoy.config.core.v3.Pipe.serializeBinaryToWriter
    );
  }
  f = message.getEnvoyInternalAddress();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.envoy.config.core.v3.EnvoyInternalAddress.serializeBinaryToWriter
    );
  }
};

/**
 * optional SocketAddress socket_address = 1;
 * @return {?proto.envoy.config.core.v3.SocketAddress}
 */
proto.envoy.config.core.v3.Address.prototype.getSocketAddress = function () {
  return /** @type{?proto.envoy.config.core.v3.SocketAddress} */ (
    jspb.Message.getWrapperField(
      this,
      proto.envoy.config.core.v3.SocketAddress,
      1
    )
  );
};

/**
 * @param {?proto.envoy.config.core.v3.SocketAddress|undefined} value
 * @return {!proto.envoy.config.core.v3.Address} returns this
 */
proto.envoy.config.core.v3.Address.prototype.setSocketAddress = function (
  value
) {
  return jspb.Message.setOneofWrapperField(
    this,
    1,
    proto.envoy.config.core.v3.Address.oneofGroups_[0],
    value
  );
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.Address} returns this
 */
proto.envoy.config.core.v3.Address.prototype.clearSocketAddress = function () {
  return this.setSocketAddress(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.Address.prototype.hasSocketAddress = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional Pipe pipe = 2;
 * @return {?proto.envoy.config.core.v3.Pipe}
 */
proto.envoy.config.core.v3.Address.prototype.getPipe = function () {
  return /** @type{?proto.envoy.config.core.v3.Pipe} */ (
    jspb.Message.getWrapperField(this, proto.envoy.config.core.v3.Pipe, 2)
  );
};

/**
 * @param {?proto.envoy.config.core.v3.Pipe|undefined} value
 * @return {!proto.envoy.config.core.v3.Address} returns this
 */
proto.envoy.config.core.v3.Address.prototype.setPipe = function (value) {
  return jspb.Message.setOneofWrapperField(
    this,
    2,
    proto.envoy.config.core.v3.Address.oneofGroups_[0],
    value
  );
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.Address} returns this
 */
proto.envoy.config.core.v3.Address.prototype.clearPipe = function () {
  return this.setPipe(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.Address.prototype.hasPipe = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional EnvoyInternalAddress envoy_internal_address = 3;
 * @return {?proto.envoy.config.core.v3.EnvoyInternalAddress}
 */
proto.envoy.config.core.v3.Address.prototype.getEnvoyInternalAddress =
  function () {
    return /** @type{?proto.envoy.config.core.v3.EnvoyInternalAddress} */ (
      jspb.Message.getWrapperField(
        this,
        proto.envoy.config.core.v3.EnvoyInternalAddress,
        3
      )
    );
  };

/**
 * @param {?proto.envoy.config.core.v3.EnvoyInternalAddress|undefined} value
 * @return {!proto.envoy.config.core.v3.Address} returns this
 */
proto.envoy.config.core.v3.Address.prototype.setEnvoyInternalAddress =
  function (value) {
    return jspb.Message.setOneofWrapperField(
      this,
      3,
      proto.envoy.config.core.v3.Address.oneofGroups_[0],
      value
    );
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.Address} returns this
 */
proto.envoy.config.core.v3.Address.prototype.clearEnvoyInternalAddress =
  function () {
    return this.setEnvoyInternalAddress(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.Address.prototype.hasEnvoyInternalAddress =
  function () {
    return jspb.Message.getField(this, 3) != null;
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.CidrRange.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.config.core.v3.CidrRange.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.CidrRange} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.CidrRange.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        addressPrefix: jspb.Message.getFieldWithDefault(msg, 1, ''),
        prefixLen:
          (f = msg.getPrefixLen()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.CidrRange}
 */
proto.envoy.config.core.v3.CidrRange.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.CidrRange();
  return proto.envoy.config.core.v3.CidrRange.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.CidrRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.CidrRange}
 */
proto.envoy.config.core.v3.CidrRange.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setAddressPrefix(value);
        break;
      case 2:
        var value = new google_protobuf_wrappers_pb.UInt32Value();
        reader.readMessage(
          value,
          google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
        );
        msg.setPrefixLen(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.CidrRange.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.envoy.config.core.v3.CidrRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.CidrRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.CidrRange.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getAddressPrefix();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getPrefixLen();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
    );
  }
};

/**
 * optional string address_prefix = 1;
 * @return {string}
 */
proto.envoy.config.core.v3.CidrRange.prototype.getAddressPrefix = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.CidrRange} returns this
 */
proto.envoy.config.core.v3.CidrRange.prototype.setAddressPrefix = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional google.protobuf.UInt32Value prefix_len = 2;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.core.v3.CidrRange.prototype.getPrefixLen = function () {
  return /** @type{?proto.google.protobuf.UInt32Value} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_wrappers_pb.UInt32Value,
      2
    )
  );
};

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.core.v3.CidrRange} returns this
 */
proto.envoy.config.core.v3.CidrRange.prototype.setPrefixLen = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.CidrRange} returns this
 */
proto.envoy.config.core.v3.CidrRange.prototype.clearPrefixLen = function () {
  return this.setPrefixLen(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.CidrRange.prototype.hasPrefixLen = function () {
  return jspb.Message.getField(this, 2) != null;
};

goog.object.extend(exports, proto.envoy.config.core.v3);
