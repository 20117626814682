// source: envoy/type/tracing/v3/custom_tag.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var envoy_type_metadata_v3_metadata_pb = require('../../../../envoy/type/metadata/v3/metadata_pb.js');
goog.object.extend(proto, envoy_type_metadata_v3_metadata_pb);
var udpa_annotations_status_pb = require('../../../../udpa/annotations/status_pb.js');
goog.object.extend(proto, udpa_annotations_status_pb);
var udpa_annotations_versioning_pb = require('../../../../udpa/annotations/versioning_pb.js');
goog.object.extend(proto, udpa_annotations_versioning_pb);
var validate_validate_pb = require('../../../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.envoy.type.tracing.v3.CustomTag', null, global);
goog.exportSymbol(
  'proto.envoy.type.tracing.v3.CustomTag.Environment',
  null,
  global
);
goog.exportSymbol('proto.envoy.type.tracing.v3.CustomTag.Header', null, global);
goog.exportSymbol(
  'proto.envoy.type.tracing.v3.CustomTag.Literal',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.type.tracing.v3.CustomTag.Metadata',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.type.tracing.v3.CustomTag.TypeCase',
  null,
  global
);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.type.tracing.v3.CustomTag = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    null,
    proto.envoy.type.tracing.v3.CustomTag.oneofGroups_
  );
};
goog.inherits(proto.envoy.type.tracing.v3.CustomTag, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.type.tracing.v3.CustomTag.displayName =
    'proto.envoy.type.tracing.v3.CustomTag';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.type.tracing.v3.CustomTag.Literal = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.envoy.type.tracing.v3.CustomTag.Literal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.type.tracing.v3.CustomTag.Literal.displayName =
    'proto.envoy.type.tracing.v3.CustomTag.Literal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.type.tracing.v3.CustomTag.Environment = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.envoy.type.tracing.v3.CustomTag.Environment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.type.tracing.v3.CustomTag.Environment.displayName =
    'proto.envoy.type.tracing.v3.CustomTag.Environment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.type.tracing.v3.CustomTag.Header = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.envoy.type.tracing.v3.CustomTag.Header, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.type.tracing.v3.CustomTag.Header.displayName =
    'proto.envoy.type.tracing.v3.CustomTag.Header';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.type.tracing.v3.CustomTag.Metadata = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.envoy.type.tracing.v3.CustomTag.Metadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.type.tracing.v3.CustomTag.Metadata.displayName =
    'proto.envoy.type.tracing.v3.CustomTag.Metadata';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.envoy.type.tracing.v3.CustomTag.oneofGroups_ = [[2, 3, 4, 5]];

/**
 * @enum {number}
 */
proto.envoy.type.tracing.v3.CustomTag.TypeCase = {
  TYPE_NOT_SET: 0,
  LITERAL: 2,
  ENVIRONMENT: 3,
  REQUEST_HEADER: 4,
  METADATA: 5
};

/**
 * @return {proto.envoy.type.tracing.v3.CustomTag.TypeCase}
 */
proto.envoy.type.tracing.v3.CustomTag.prototype.getTypeCase = function () {
  return /** @type {proto.envoy.type.tracing.v3.CustomTag.TypeCase} */ (
    jspb.Message.computeOneofCase(
      this,
      proto.envoy.type.tracing.v3.CustomTag.oneofGroups_[0]
    )
  );
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.type.tracing.v3.CustomTag.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.type.tracing.v3.CustomTag.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.type.tracing.v3.CustomTag} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.type.tracing.v3.CustomTag.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        tag: jspb.Message.getFieldWithDefault(msg, 1, ''),
        literal:
          (f = msg.getLiteral()) &&
          proto.envoy.type.tracing.v3.CustomTag.Literal.toObject(
            includeInstance,
            f
          ),
        environment:
          (f = msg.getEnvironment()) &&
          proto.envoy.type.tracing.v3.CustomTag.Environment.toObject(
            includeInstance,
            f
          ),
        requestHeader:
          (f = msg.getRequestHeader()) &&
          proto.envoy.type.tracing.v3.CustomTag.Header.toObject(
            includeInstance,
            f
          ),
        metadata:
          (f = msg.getMetadata()) &&
          proto.envoy.type.tracing.v3.CustomTag.Metadata.toObject(
            includeInstance,
            f
          )
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.type.tracing.v3.CustomTag}
 */
proto.envoy.type.tracing.v3.CustomTag.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.type.tracing.v3.CustomTag();
  return proto.envoy.type.tracing.v3.CustomTag.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.type.tracing.v3.CustomTag} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.type.tracing.v3.CustomTag}
 */
proto.envoy.type.tracing.v3.CustomTag.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setTag(value);
        break;
      case 2:
        var value = new proto.envoy.type.tracing.v3.CustomTag.Literal();
        reader.readMessage(
          value,
          proto.envoy.type.tracing.v3.CustomTag.Literal
            .deserializeBinaryFromReader
        );
        msg.setLiteral(value);
        break;
      case 3:
        var value = new proto.envoy.type.tracing.v3.CustomTag.Environment();
        reader.readMessage(
          value,
          proto.envoy.type.tracing.v3.CustomTag.Environment
            .deserializeBinaryFromReader
        );
        msg.setEnvironment(value);
        break;
      case 4:
        var value = new proto.envoy.type.tracing.v3.CustomTag.Header();
        reader.readMessage(
          value,
          proto.envoy.type.tracing.v3.CustomTag.Header
            .deserializeBinaryFromReader
        );
        msg.setRequestHeader(value);
        break;
      case 5:
        var value = new proto.envoy.type.tracing.v3.CustomTag.Metadata();
        reader.readMessage(
          value,
          proto.envoy.type.tracing.v3.CustomTag.Metadata
            .deserializeBinaryFromReader
        );
        msg.setMetadata(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.type.tracing.v3.CustomTag.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.envoy.type.tracing.v3.CustomTag.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.type.tracing.v3.CustomTag} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.type.tracing.v3.CustomTag.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getTag();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getLiteral();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.envoy.type.tracing.v3.CustomTag.Literal.serializeBinaryToWriter
    );
  }
  f = message.getEnvironment();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.envoy.type.tracing.v3.CustomTag.Environment.serializeBinaryToWriter
    );
  }
  f = message.getRequestHeader();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.envoy.type.tracing.v3.CustomTag.Header.serializeBinaryToWriter
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.envoy.type.tracing.v3.CustomTag.Metadata.serializeBinaryToWriter
    );
  }
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.type.tracing.v3.CustomTag.Literal.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.type.tracing.v3.CustomTag.Literal.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.type.tracing.v3.CustomTag.Literal} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.type.tracing.v3.CustomTag.Literal.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        value: jspb.Message.getFieldWithDefault(msg, 1, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.type.tracing.v3.CustomTag.Literal}
 */
proto.envoy.type.tracing.v3.CustomTag.Literal.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.type.tracing.v3.CustomTag.Literal();
  return proto.envoy.type.tracing.v3.CustomTag.Literal.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.type.tracing.v3.CustomTag.Literal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.type.tracing.v3.CustomTag.Literal}
 */
proto.envoy.type.tracing.v3.CustomTag.Literal.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setValue(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.type.tracing.v3.CustomTag.Literal.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.type.tracing.v3.CustomTag.Literal.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.type.tracing.v3.CustomTag.Literal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.type.tracing.v3.CustomTag.Literal.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getValue();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
  };

/**
 * optional string value = 1;
 * @return {string}
 */
proto.envoy.type.tracing.v3.CustomTag.Literal.prototype.getValue = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.envoy.type.tracing.v3.CustomTag.Literal} returns this
 */
proto.envoy.type.tracing.v3.CustomTag.Literal.prototype.setValue = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.type.tracing.v3.CustomTag.Environment.prototype.toObject =
    function (opt_includeInstance) {
      return proto.envoy.type.tracing.v3.CustomTag.Environment.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.type.tracing.v3.CustomTag.Environment} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.type.tracing.v3.CustomTag.Environment.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        name: jspb.Message.getFieldWithDefault(msg, 1, ''),
        defaultValue: jspb.Message.getFieldWithDefault(msg, 2, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.type.tracing.v3.CustomTag.Environment}
 */
proto.envoy.type.tracing.v3.CustomTag.Environment.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.type.tracing.v3.CustomTag.Environment();
  return proto.envoy.type.tracing.v3.CustomTag.Environment.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.type.tracing.v3.CustomTag.Environment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.type.tracing.v3.CustomTag.Environment}
 */
proto.envoy.type.tracing.v3.CustomTag.Environment.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setName(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setDefaultValue(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.type.tracing.v3.CustomTag.Environment.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.type.tracing.v3.CustomTag.Environment.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.type.tracing.v3.CustomTag.Environment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.type.tracing.v3.CustomTag.Environment.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
    f = message.getDefaultValue();
    if (f.length > 0) {
      writer.writeString(2, f);
    }
  };

/**
 * optional string name = 1;
 * @return {string}
 */
proto.envoy.type.tracing.v3.CustomTag.Environment.prototype.getName =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.type.tracing.v3.CustomTag.Environment} returns this
 */
proto.envoy.type.tracing.v3.CustomTag.Environment.prototype.setName = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string default_value = 2;
 * @return {string}
 */
proto.envoy.type.tracing.v3.CustomTag.Environment.prototype.getDefaultValue =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 2, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.type.tracing.v3.CustomTag.Environment} returns this
 */
proto.envoy.type.tracing.v3.CustomTag.Environment.prototype.setDefaultValue =
  function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.type.tracing.v3.CustomTag.Header.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.type.tracing.v3.CustomTag.Header.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.type.tracing.v3.CustomTag.Header} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.type.tracing.v3.CustomTag.Header.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        name: jspb.Message.getFieldWithDefault(msg, 1, ''),
        defaultValue: jspb.Message.getFieldWithDefault(msg, 2, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.type.tracing.v3.CustomTag.Header}
 */
proto.envoy.type.tracing.v3.CustomTag.Header.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.type.tracing.v3.CustomTag.Header();
  return proto.envoy.type.tracing.v3.CustomTag.Header.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.type.tracing.v3.CustomTag.Header} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.type.tracing.v3.CustomTag.Header}
 */
proto.envoy.type.tracing.v3.CustomTag.Header.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setName(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setDefaultValue(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.type.tracing.v3.CustomTag.Header.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.type.tracing.v3.CustomTag.Header.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.type.tracing.v3.CustomTag.Header} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.type.tracing.v3.CustomTag.Header.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
    f = message.getDefaultValue();
    if (f.length > 0) {
      writer.writeString(2, f);
    }
  };

/**
 * optional string name = 1;
 * @return {string}
 */
proto.envoy.type.tracing.v3.CustomTag.Header.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.envoy.type.tracing.v3.CustomTag.Header} returns this
 */
proto.envoy.type.tracing.v3.CustomTag.Header.prototype.setName = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string default_value = 2;
 * @return {string}
 */
proto.envoy.type.tracing.v3.CustomTag.Header.prototype.getDefaultValue =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 2, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.type.tracing.v3.CustomTag.Header} returns this
 */
proto.envoy.type.tracing.v3.CustomTag.Header.prototype.setDefaultValue =
  function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.type.tracing.v3.CustomTag.Metadata.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.type.tracing.v3.CustomTag.Metadata.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.type.tracing.v3.CustomTag.Metadata} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.type.tracing.v3.CustomTag.Metadata.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        kind:
          (f = msg.getKind()) &&
          envoy_type_metadata_v3_metadata_pb.MetadataKind.toObject(
            includeInstance,
            f
          ),
        metadataKey:
          (f = msg.getMetadataKey()) &&
          envoy_type_metadata_v3_metadata_pb.MetadataKey.toObject(
            includeInstance,
            f
          ),
        defaultValue: jspb.Message.getFieldWithDefault(msg, 3, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.type.tracing.v3.CustomTag.Metadata}
 */
proto.envoy.type.tracing.v3.CustomTag.Metadata.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.type.tracing.v3.CustomTag.Metadata();
  return proto.envoy.type.tracing.v3.CustomTag.Metadata.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.type.tracing.v3.CustomTag.Metadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.type.tracing.v3.CustomTag.Metadata}
 */
proto.envoy.type.tracing.v3.CustomTag.Metadata.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new envoy_type_metadata_v3_metadata_pb.MetadataKind();
          reader.readMessage(
            value,
            envoy_type_metadata_v3_metadata_pb.MetadataKind
              .deserializeBinaryFromReader
          );
          msg.setKind(value);
          break;
        case 2:
          var value = new envoy_type_metadata_v3_metadata_pb.MetadataKey();
          reader.readMessage(
            value,
            envoy_type_metadata_v3_metadata_pb.MetadataKey
              .deserializeBinaryFromReader
          );
          msg.setMetadataKey(value);
          break;
        case 3:
          var value = /** @type {string} */ (reader.readString());
          msg.setDefaultValue(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.type.tracing.v3.CustomTag.Metadata.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.type.tracing.v3.CustomTag.Metadata.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.type.tracing.v3.CustomTag.Metadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.type.tracing.v3.CustomTag.Metadata.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getKind();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        envoy_type_metadata_v3_metadata_pb.MetadataKind.serializeBinaryToWriter
      );
    }
    f = message.getMetadataKey();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        envoy_type_metadata_v3_metadata_pb.MetadataKey.serializeBinaryToWriter
      );
    }
    f = message.getDefaultValue();
    if (f.length > 0) {
      writer.writeString(3, f);
    }
  };

/**
 * optional envoy.type.metadata.v3.MetadataKind kind = 1;
 * @return {?proto.envoy.type.metadata.v3.MetadataKind}
 */
proto.envoy.type.tracing.v3.CustomTag.Metadata.prototype.getKind = function () {
  return /** @type{?proto.envoy.type.metadata.v3.MetadataKind} */ (
    jspb.Message.getWrapperField(
      this,
      envoy_type_metadata_v3_metadata_pb.MetadataKind,
      1
    )
  );
};

/**
 * @param {?proto.envoy.type.metadata.v3.MetadataKind|undefined} value
 * @return {!proto.envoy.type.tracing.v3.CustomTag.Metadata} returns this
 */
proto.envoy.type.tracing.v3.CustomTag.Metadata.prototype.setKind = function (
  value
) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.type.tracing.v3.CustomTag.Metadata} returns this
 */
proto.envoy.type.tracing.v3.CustomTag.Metadata.prototype.clearKind =
  function () {
    return this.setKind(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.type.tracing.v3.CustomTag.Metadata.prototype.hasKind = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional envoy.type.metadata.v3.MetadataKey metadata_key = 2;
 * @return {?proto.envoy.type.metadata.v3.MetadataKey}
 */
proto.envoy.type.tracing.v3.CustomTag.Metadata.prototype.getMetadataKey =
  function () {
    return /** @type{?proto.envoy.type.metadata.v3.MetadataKey} */ (
      jspb.Message.getWrapperField(
        this,
        envoy_type_metadata_v3_metadata_pb.MetadataKey,
        2
      )
    );
  };

/**
 * @param {?proto.envoy.type.metadata.v3.MetadataKey|undefined} value
 * @return {!proto.envoy.type.tracing.v3.CustomTag.Metadata} returns this
 */
proto.envoy.type.tracing.v3.CustomTag.Metadata.prototype.setMetadataKey =
  function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.type.tracing.v3.CustomTag.Metadata} returns this
 */
proto.envoy.type.tracing.v3.CustomTag.Metadata.prototype.clearMetadataKey =
  function () {
    return this.setMetadataKey(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.type.tracing.v3.CustomTag.Metadata.prototype.hasMetadataKey =
  function () {
    return jspb.Message.getField(this, 2) != null;
  };

/**
 * optional string default_value = 3;
 * @return {string}
 */
proto.envoy.type.tracing.v3.CustomTag.Metadata.prototype.getDefaultValue =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 3, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.type.tracing.v3.CustomTag.Metadata} returns this
 */
proto.envoy.type.tracing.v3.CustomTag.Metadata.prototype.setDefaultValue =
  function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
  };

/**
 * optional string tag = 1;
 * @return {string}
 */
proto.envoy.type.tracing.v3.CustomTag.prototype.getTag = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.envoy.type.tracing.v3.CustomTag} returns this
 */
proto.envoy.type.tracing.v3.CustomTag.prototype.setTag = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional Literal literal = 2;
 * @return {?proto.envoy.type.tracing.v3.CustomTag.Literal}
 */
proto.envoy.type.tracing.v3.CustomTag.prototype.getLiteral = function () {
  return /** @type{?proto.envoy.type.tracing.v3.CustomTag.Literal} */ (
    jspb.Message.getWrapperField(
      this,
      proto.envoy.type.tracing.v3.CustomTag.Literal,
      2
    )
  );
};

/**
 * @param {?proto.envoy.type.tracing.v3.CustomTag.Literal|undefined} value
 * @return {!proto.envoy.type.tracing.v3.CustomTag} returns this
 */
proto.envoy.type.tracing.v3.CustomTag.prototype.setLiteral = function (value) {
  return jspb.Message.setOneofWrapperField(
    this,
    2,
    proto.envoy.type.tracing.v3.CustomTag.oneofGroups_[0],
    value
  );
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.type.tracing.v3.CustomTag} returns this
 */
proto.envoy.type.tracing.v3.CustomTag.prototype.clearLiteral = function () {
  return this.setLiteral(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.type.tracing.v3.CustomTag.prototype.hasLiteral = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional Environment environment = 3;
 * @return {?proto.envoy.type.tracing.v3.CustomTag.Environment}
 */
proto.envoy.type.tracing.v3.CustomTag.prototype.getEnvironment = function () {
  return /** @type{?proto.envoy.type.tracing.v3.CustomTag.Environment} */ (
    jspb.Message.getWrapperField(
      this,
      proto.envoy.type.tracing.v3.CustomTag.Environment,
      3
    )
  );
};

/**
 * @param {?proto.envoy.type.tracing.v3.CustomTag.Environment|undefined} value
 * @return {!proto.envoy.type.tracing.v3.CustomTag} returns this
 */
proto.envoy.type.tracing.v3.CustomTag.prototype.setEnvironment = function (
  value
) {
  return jspb.Message.setOneofWrapperField(
    this,
    3,
    proto.envoy.type.tracing.v3.CustomTag.oneofGroups_[0],
    value
  );
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.type.tracing.v3.CustomTag} returns this
 */
proto.envoy.type.tracing.v3.CustomTag.prototype.clearEnvironment = function () {
  return this.setEnvironment(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.type.tracing.v3.CustomTag.prototype.hasEnvironment = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional Header request_header = 4;
 * @return {?proto.envoy.type.tracing.v3.CustomTag.Header}
 */
proto.envoy.type.tracing.v3.CustomTag.prototype.getRequestHeader = function () {
  return /** @type{?proto.envoy.type.tracing.v3.CustomTag.Header} */ (
    jspb.Message.getWrapperField(
      this,
      proto.envoy.type.tracing.v3.CustomTag.Header,
      4
    )
  );
};

/**
 * @param {?proto.envoy.type.tracing.v3.CustomTag.Header|undefined} value
 * @return {!proto.envoy.type.tracing.v3.CustomTag} returns this
 */
proto.envoy.type.tracing.v3.CustomTag.prototype.setRequestHeader = function (
  value
) {
  return jspb.Message.setOneofWrapperField(
    this,
    4,
    proto.envoy.type.tracing.v3.CustomTag.oneofGroups_[0],
    value
  );
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.type.tracing.v3.CustomTag} returns this
 */
proto.envoy.type.tracing.v3.CustomTag.prototype.clearRequestHeader =
  function () {
    return this.setRequestHeader(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.type.tracing.v3.CustomTag.prototype.hasRequestHeader = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional Metadata metadata = 5;
 * @return {?proto.envoy.type.tracing.v3.CustomTag.Metadata}
 */
proto.envoy.type.tracing.v3.CustomTag.prototype.getMetadata = function () {
  return /** @type{?proto.envoy.type.tracing.v3.CustomTag.Metadata} */ (
    jspb.Message.getWrapperField(
      this,
      proto.envoy.type.tracing.v3.CustomTag.Metadata,
      5
    )
  );
};

/**
 * @param {?proto.envoy.type.tracing.v3.CustomTag.Metadata|undefined} value
 * @return {!proto.envoy.type.tracing.v3.CustomTag} returns this
 */
proto.envoy.type.tracing.v3.CustomTag.prototype.setMetadata = function (value) {
  return jspb.Message.setOneofWrapperField(
    this,
    5,
    proto.envoy.type.tracing.v3.CustomTag.oneofGroups_[0],
    value
  );
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.type.tracing.v3.CustomTag} returns this
 */
proto.envoy.type.tracing.v3.CustomTag.prototype.clearMetadata = function () {
  return this.setMetadata(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.type.tracing.v3.CustomTag.prototype.hasMetadata = function () {
  return jspb.Message.getField(this, 5) != null;
};

goog.object.extend(exports, proto.envoy.type.tracing.v3);
