/* eslint-disable no-console */
import '@fontsource/dm-sans';
import * as colors from '@mui/material/colors';
import type {} from '@mui/x-data-grid-pro/themeAugmentation';
import {
  createTheme,
  responsiveFontSizes,
  ThemeOptions,
  Theme
} from '@mui/material/styles';
import _ from 'lodash';

import { THEMES } from 'src/constants';
import { softShadows, strongShadows } from 'src/theme/shadows';
import typography from 'src/theme/typography';

interface ThemeConfig {
  responsiveFontSizes?: boolean;
  theme?: string;
}

const baseOptions: ThemeOptions = {
  typography,
  shape: {
    borderRadius: '16px'
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          '@font-face': ['DM Sans'],
          '*': {
            boxSizing: 'border-box',
            margin: 0,
            padding: 0
          },
          html: {
            WebkitFontSmoothing: 'antialiased',
            MozOsxFontSmoothing: 'grayscale',
            height: '100%',
            width: '100%'
          },
          body: {
            height: '100%',
            width: '100%'
          },
          '#root': {
            height: '100%',
            width: '100%'
          }
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden'
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 32
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0,0,0,0.075)'
        }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(68, 56, 102, 0.8)'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 600
        }
      }
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: {
          opacity: '30%'
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: '4px'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '4px'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& .Mui-focused': {},
          '& .MuiInputBase-input': {
            borderRadius: '4px'
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px'
          },
          '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
            transform: 'translate(8px, 10px) scale(1)'
          },
          '& div.MuiOutlinedInput-underline:before': {
            borderBottom: `0px`
          },
          '& div.MuiOutlinedInput-underline:after': {
            border: `0px`
          }
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
            marginLeft: '8px',
            padding: '0, 4px, 0, 4px'
          },
          '& .MuiDataGrid-iconButtonContainer': {
            marginLeft: '10px'
          },
          '& .MuiDataGrid-cell--textLeft': {
            paddingLeft: '15px'
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: '600'
          }
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: 0
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexFlow: 'row nowrap',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '16px'
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: { padding: '16px' }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '16px',
          display: 'flex',
          flexFlow: 'row nowrap',
          justifyContent: 'flex-end'
        }
      }
    }
  }
};

const getDesignTokens = (mode: string) => {
  if (mode === THEMES.LIGHT) {
    return {
      mode: THEMES.LIGHT,
      overrides: {
        MuiTooltip: {
          tooltip: {
            fontSize: '1em',
            color: '#6F43E7',
            backgroundColor: '#ECE5FF'
          }
        },
        MuiInputBase: {
          input: {
            '&::placeholder': {
              opacity: 1,
              color: colors.blueGrey[600]
            }
          }
        }
      },
      palette: {
        mode: 'light',
        action: {
          active: '#39256C'
        },
        background: {
          default: colors.common.white,
          dark: '#FBFBFB',
          paper: colors.common.white
        },
        primary: {
          main: '#6F43E7'
        },
        secondary: {
          main: '#49AAA1'
        }
      },
      shadows: softShadows
    };
  } else {
    return {
      mode: THEMES.DARK,
      overrides: {
        MuiTooltip: {
          tooltip: {
            fontSize: '1em',
            color: '#ECE5FF',
            backgroundColor: '#6F43E7'
          }
        }
      },
      palette: {
        mode: 'dark',
        action: {
          active: '#49AAA1'
        },
        text: {
          secondary: 'rgba(255,255,255,0.9)'
        },
        background: {
          default: '#262626',
          paper: '#262626',
          dark: '#1a1a1a'
        },
        primary: {
          main: '#6F43E7',
          light: '#ECE5FF',
          dark: '#39256C'
        },

        secondary: {
          main: '#49AAA1'
        }
      },
      shadows: strongShadows
    };
  }
};

export const createCustomTheme = (config: ThemeConfig = {}): Theme => {
  const themeOptions = getDesignTokens(config.theme);
  let customTheme = createTheme(_.merge({}, baseOptions, themeOptions));

  if (config.responsiveFontSizes) {
    customTheme = responsiveFontSizes(customTheme);
  }

  return customTheme as Theme;
};
