/**
 * @fileoverview gRPC-Web generated client stub for pomerium.dashboard
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!

/* eslint-disable */
// @ts-nocheck

import * as grpcWeb from 'grpc-web';

import * as key_chain_pb from './key_chain_pb';

export class KeyChainServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string };
  options_: null | { [index: string]: any };

  constructor(
    hostname: string,
    credentials?: null | { [index: string]: string },
    options?: null | { [index: string]: any }
  ) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoDeleteKeyPair = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.KeyChainService/DeleteKeyPair',
    grpcWeb.MethodType.UNARY,
    key_chain_pb.DeleteKeyPairRequest,
    key_chain_pb.DeleteKeyPairResponse,
    (request: key_chain_pb.DeleteKeyPairRequest) => {
      return request.serializeBinary();
    },
    key_chain_pb.DeleteKeyPairResponse.deserializeBinary
  );

  deleteKeyPair(
    request: key_chain_pb.DeleteKeyPairRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<key_chain_pb.DeleteKeyPairResponse>;

  deleteKeyPair(
    request: key_chain_pb.DeleteKeyPairRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: key_chain_pb.DeleteKeyPairResponse
    ) => void
  ): grpcWeb.ClientReadableStream<key_chain_pb.DeleteKeyPairResponse>;

  deleteKeyPair(
    request: key_chain_pb.DeleteKeyPairRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: key_chain_pb.DeleteKeyPairResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + '/pomerium.dashboard.KeyChainService/DeleteKeyPair',
        request,
        metadata || {},
        this.methodInfoDeleteKeyPair,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + '/pomerium.dashboard.KeyChainService/DeleteKeyPair',
      request,
      metadata || {},
      this.methodInfoDeleteKeyPair
    );
  }

  methodInfoGetKeyPair = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.KeyChainService/GetKeyPair',
    grpcWeb.MethodType.UNARY,
    key_chain_pb.GetKeyPairRequest,
    key_chain_pb.GetKeyPairResponse,
    (request: key_chain_pb.GetKeyPairRequest) => {
      return request.serializeBinary();
    },
    key_chain_pb.GetKeyPairResponse.deserializeBinary
  );

  getKeyPair(
    request: key_chain_pb.GetKeyPairRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<key_chain_pb.GetKeyPairResponse>;

  getKeyPair(
    request: key_chain_pb.GetKeyPairRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: key_chain_pb.GetKeyPairResponse
    ) => void
  ): grpcWeb.ClientReadableStream<key_chain_pb.GetKeyPairResponse>;

  getKeyPair(
    request: key_chain_pb.GetKeyPairRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: key_chain_pb.GetKeyPairResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + '/pomerium.dashboard.KeyChainService/GetKeyPair',
        request,
        metadata || {},
        this.methodInfoGetKeyPair,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + '/pomerium.dashboard.KeyChainService/GetKeyPair',
      request,
      metadata || {},
      this.methodInfoGetKeyPair
    );
  }

  methodInfoListKeyPairs = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.KeyChainService/ListKeyPairs',
    grpcWeb.MethodType.UNARY,
    key_chain_pb.ListKeyPairsRequest,
    key_chain_pb.ListKeyPairsResponse,
    (request: key_chain_pb.ListKeyPairsRequest) => {
      return request.serializeBinary();
    },
    key_chain_pb.ListKeyPairsResponse.deserializeBinary
  );

  listKeyPairs(
    request: key_chain_pb.ListKeyPairsRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<key_chain_pb.ListKeyPairsResponse>;

  listKeyPairs(
    request: key_chain_pb.ListKeyPairsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: key_chain_pb.ListKeyPairsResponse
    ) => void
  ): grpcWeb.ClientReadableStream<key_chain_pb.ListKeyPairsResponse>;

  listKeyPairs(
    request: key_chain_pb.ListKeyPairsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: key_chain_pb.ListKeyPairsResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + '/pomerium.dashboard.KeyChainService/ListKeyPairs',
        request,
        metadata || {},
        this.methodInfoListKeyPairs,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + '/pomerium.dashboard.KeyChainService/ListKeyPairs',
      request,
      metadata || {},
      this.methodInfoListKeyPairs
    );
  }

  methodInfoCreateKeyPair = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.KeyChainService/CreateKeyPair',
    grpcWeb.MethodType.UNARY,
    key_chain_pb.CreateKeyPairRequest,
    key_chain_pb.CreateKeyPairResponse,
    (request: key_chain_pb.CreateKeyPairRequest) => {
      return request.serializeBinary();
    },
    key_chain_pb.CreateKeyPairResponse.deserializeBinary
  );

  createKeyPair(
    request: key_chain_pb.CreateKeyPairRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<key_chain_pb.CreateKeyPairResponse>;

  createKeyPair(
    request: key_chain_pb.CreateKeyPairRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: key_chain_pb.CreateKeyPairResponse
    ) => void
  ): grpcWeb.ClientReadableStream<key_chain_pb.CreateKeyPairResponse>;

  createKeyPair(
    request: key_chain_pb.CreateKeyPairRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: key_chain_pb.CreateKeyPairResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + '/pomerium.dashboard.KeyChainService/CreateKeyPair',
        request,
        metadata || {},
        this.methodInfoCreateKeyPair,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + '/pomerium.dashboard.KeyChainService/CreateKeyPair',
      request,
      metadata || {},
      this.methodInfoCreateKeyPair
    );
  }

  methodInfoUpdateKeyPair = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.KeyChainService/UpdateKeyPair',
    grpcWeb.MethodType.UNARY,
    key_chain_pb.UpdateKeyPairRequest,
    key_chain_pb.UpdateKeyPairResponse,
    (request: key_chain_pb.UpdateKeyPairRequest) => {
      return request.serializeBinary();
    },
    key_chain_pb.UpdateKeyPairResponse.deserializeBinary
  );

  updateKeyPair(
    request: key_chain_pb.UpdateKeyPairRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<key_chain_pb.UpdateKeyPairResponse>;

  updateKeyPair(
    request: key_chain_pb.UpdateKeyPairRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: key_chain_pb.UpdateKeyPairResponse
    ) => void
  ): grpcWeb.ClientReadableStream<key_chain_pb.UpdateKeyPairResponse>;

  updateKeyPair(
    request: key_chain_pb.UpdateKeyPairRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: key_chain_pb.UpdateKeyPairResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + '/pomerium.dashboard.KeyChainService/UpdateKeyPair',
        request,
        metadata || {},
        this.methodInfoUpdateKeyPair,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + '/pomerium.dashboard.KeyChainService/UpdateKeyPair',
      request,
      metadata || {},
      this.methodInfoUpdateKeyPair
    );
  }
}
