import PropTypes from 'prop-types';
import qs from 'qs';
import React from 'react';
import { FC, ReactNode } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { useSelector } from 'src/store';

interface GuestGuardProps {
  children?: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const { session } = useSelector((state) => state);
  const location = useLocation();

  let redirect = '/app/account';
  if (location.search) {
    const v = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (v && v.redirect) {
      redirect = v.redirect;
    }
  }

  if (session.userInfo) {
    return <Redirect to={redirect} />;
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
