import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';

import { Namespace } from 'src/pb/namespaces_pb';

export interface NamespaceTreeNode {
  id: string;
  parentID: string;
  name: string;
  createdAt: Timestamp;
  modifiedAt: Timestamp;
  deletedAt: Timestamp;
  routeCount: number;
  policyCount: number;
  permission: string;
  children: NamespaceTreeNode[];
  path: string[] | undefined;
}

export const ROOT_NAMESPACE_ID = '9d8dbd2c-8cce-4e66-9c1f-c490b4a07243';
export const ROOT_NAMESPACE_NAME = 'Global';

export class NamespaceTree {
  private lookup: Map<string, NamespaceTreeNode>;
  root: NamespaceTreeNode;

  constructor(namespaces: Namespace[]) {
    this.lookup = new Map<string, NamespaceTreeNode>();
    namespaces.forEach((ns): void => {
      this.lookup.set(ns.getId(), {
        id: ns.getId(),
        parentID: ns.getParentId(),
        createdAt: ns.getCreatedAt(),
        modifiedAt: ns.getModifiedAt(),
        deletedAt: ns.getDeletedAt(),
        name: ns.getName(),
        routeCount: ns.getRouteCount(),
        policyCount: ns.getPolicyCount(),
        permission: '',
        children: [],
        path: []
      });
    });

    this.root = {
      id: ROOT_NAMESPACE_ID,
      parentID: null,
      createdAt: null,
      modifiedAt: null,
      deletedAt: null,
      name: ROOT_NAMESPACE_NAME,
      routeCount: 0,
      policyCount: 0,
      permission: '',
      children: [],
      path: []
    };

    this.lookup.forEach((node) => {
      if (node.parentID) {
        this.lookup.get(node.parentID).children.push(node);
      } else {
        this.root.children.push(node);
      }
    });
    if (this.root.children.length === 1) {
      this.root = this.root.children[0];
    }
    if (!this.root.name) {
      this.root.name = ROOT_NAMESPACE_NAME;
    }
  }

  ancestors(node: NamespaceTreeNode): NamespaceTreeNode[] {
    const parent = this.lookup.get(node?.parentID);
    if (parent) {
      return [parent].concat(this.ancestors(parent));
    }
    return [];
  }

  descendants(node: NamespaceTreeNode): NamespaceTreeNode[] {
    let nodes = [];
    node?.children?.forEach((child) => {
      nodes.push(child);
      nodes = nodes.concat(this.descendants(child));
    });
    return nodes;
  }

  firstAccessible(): NamespaceTreeNode | null {
    return (
      this.descendants(this.root)
        .filter((node) => !!node.permission)
        .shift() || null
    );
  }

  allAccessible(): NamespaceTreeNode[] {
    let namespaceUserHasAccessTo = [];
    if (this.root.permission) {
      namespaceUserHasAccessTo.push(this.root);
    }
    namespaceUserHasAccessTo = namespaceUserHasAccessTo.concat(
      this.descendants(this.root).filter((node) => !!node.permission)
    );
    return namespaceUserHasAccessTo;
  }

  forEach(f: (node: NamespaceTreeNode) => void): void {
    const todo: NamespaceTreeNode[] = [];
    todo.push(this.root);

    while (todo.length > 0) {
      const node = todo.pop();
      f(node);
      node.children.forEach((el) => {
        todo.push(el);
      });
    }
  }

  get(id: string): NamespaceTreeNode {
    return this.lookup.get(id);
  }

  map<T>(f: (node: NamespaceTreeNode) => T): T[] {
    const arr: T[] = [];
    this.forEach((node) => {
      arr.push(f(node));
    });
    return arr;
  }
}
