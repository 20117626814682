import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { pushError, pushSuccess } from './notifications';

import { PolicyServiceClient } from 'src/pb/PolicyServiceClientPb';
import {
  Policy,
  ListPoliciesRequest,
  GetPolicyRequest,
  SetPolicyRequest,
  ListPoliciesResponse,
  DeletePolicyRequest
} from 'src/pb/policy_pb';
import { AppThunk, PolicyState } from 'src/store/state';
import { getErrorMessage } from 'src/utils/errors';
import { getClient, getMetadata } from 'src/utils/grpc';

const initialState: PolicyState = {
  jsonList: [],
  protoMap: {},
  saving: false
};

const slice = createSlice({
  name: 'policies',
  initialState,
  reducers: {
    getPolicy(state: PolicyState, action: PayloadAction<Policy>): void {
      const jsonIndex = state.jsonList.findIndex(
        (policy) => policy.id == action.payload.getId()
      );
      state.jsonList[jsonIndex] = action.payload.toObject();
      state.protoMap[action.payload.getId()] = action.payload;
    },
    listPolicies(
      state: PolicyState,
      action: PayloadAction<ListPoliciesResponse>
    ): void {
      state.jsonList = action.payload
        .getPoliciesList()
        .map((policy) => policy.toObject());
      action.payload.getPoliciesList().forEach((policy) => {
        state.protoMap[policy.getId()] = policy;
      });
    },
    deletePolicy(state: PolicyState, action: PayloadAction<string>): void {
      delete state.protoMap[action.payload];
      const jsonIndex = state.jsonList.findIndex(
        (policy) => policy.id == action.payload
      );
      state.jsonList.splice(jsonIndex, 1);
    },
    setSaving(state: PolicyState, action: PayloadAction<boolean>): void {
      state.saving = action.payload;
    }
  }
});

export const { reducer } = slice;

const client = getClient(PolicyServiceClient);

export const getPolicy =
  (policyId: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    if (policyId) {
      const req = new GetPolicyRequest();
      req.setId(policyId);
      try {
        const res = await client.getPolicy(
          req,
          getMetadata(getState().session)
        );
        dispatch(slice.actions.getPolicy(res.getPolicy()));
      } catch (e) {
        dispatch(pushError(getErrorMessage(e)));
      }
    }
  };

export const setPolicy =
  (policy: Policy): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const req = new SetPolicyRequest();
    req.setPolicy(policy);
    try {
      dispatch(slice.actions.setSaving(true));
      const res = await client.setPolicy(req, getMetadata(getState().session));
      dispatch(slice.actions.getPolicy(res.getPolicy()));
      dispatch(pushSuccess('Policy ' + policy.getName() + ' Saved!'));
    } catch (e) {
      dispatch(pushError(getErrorMessage(e)));
      throw e;
    } finally {
      dispatch(slice.actions.setSaving(false));
    }
  };

export const listPolicies =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const req = new ListPoliciesRequest();
    req.setLimit(100000);
    req.setNamespace(getState().namespaces.currentNamespace.id);
    try {
      const res = await client.listPolicies(
        req,
        getMetadata(getState().session)
      );
      dispatch(slice.actions.listPolicies(res));
    } catch (e) {
      dispatch(pushError(getErrorMessage(e)));
    }
  };

export const deletePolicies =
  (policyIds: Iterable<string>): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    try {
      for (const policyId of policyIds) {
        const req = new DeletePolicyRequest();
        req.setId(policyId);
        await client.deletePolicy(req, getMetadata(getState().session));
        dispatch(slice.actions.deletePolicy(policyId));
      }
    } catch (e) {
      dispatch(pushError(getErrorMessage(e)));
    }
  };
