// source: envoy/config/endpoint/v3/endpoint.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var envoy_config_endpoint_v3_endpoint_components_pb = require('../../../../envoy/config/endpoint/v3/endpoint_components_pb.js');
goog.object.extend(proto, envoy_config_endpoint_v3_endpoint_components_pb);
var envoy_type_v3_percent_pb = require('../../../../envoy/type/v3/percent_pb.js');
goog.object.extend(proto, envoy_type_v3_percent_pb);
var google_api_annotations_pb = require('../../../../google/api/annotations_pb.js');
goog.object.extend(proto, google_api_annotations_pb);
var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js');
goog.object.extend(proto, google_protobuf_duration_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var udpa_annotations_status_pb = require('../../../../udpa/annotations/status_pb.js');
goog.object.extend(proto, udpa_annotations_status_pb);
var udpa_annotations_versioning_pb = require('../../../../udpa/annotations/versioning_pb.js');
goog.object.extend(proto, udpa_annotations_versioning_pb);
var validate_validate_pb = require('../../../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol(
  'proto.envoy.config.endpoint.v3.ClusterLoadAssignment',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.DropOverload',
  null,
  global
);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.envoy.config.endpoint.v3.ClusterLoadAssignment.repeatedFields_,
    null
  );
};
goog.inherits(
  proto.envoy.config.endpoint.v3.ClusterLoadAssignment,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.endpoint.v3.ClusterLoadAssignment.displayName =
    'proto.envoy.config.endpoint.v3.ClusterLoadAssignment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy = function (
  opt_data
) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.repeatedFields_,
    null
  );
};
goog.inherits(
  proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.displayName =
    'proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.DropOverload =
  function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  };
goog.inherits(
  proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.DropOverload,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.DropOverload.displayName =
    'proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.DropOverload';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.repeatedFields_ = [2];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.endpoint.v3.ClusterLoadAssignment.prototype.toObject =
    function (opt_includeInstance) {
      return proto.envoy.config.endpoint.v3.ClusterLoadAssignment.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.endpoint.v3.ClusterLoadAssignment} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.endpoint.v3.ClusterLoadAssignment.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        clusterName: jspb.Message.getFieldWithDefault(msg, 1, ''),
        endpointsList: jspb.Message.toObjectList(
          msg.getEndpointsList(),
          envoy_config_endpoint_v3_endpoint_components_pb.LocalityLbEndpoints
            .toObject,
          includeInstance
        ),
        namedEndpointsMap: (f = msg.getNamedEndpointsMap())
          ? f.toObject(
              includeInstance,
              proto.envoy.config.endpoint.v3.Endpoint.toObject
            )
          : [],
        policy:
          (f = msg.getPolicy()) &&
          proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.toObject(
            includeInstance,
            f
          )
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.endpoint.v3.ClusterLoadAssignment}
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.envoy.config.endpoint.v3.ClusterLoadAssignment();
    return proto.envoy.config.endpoint.v3.ClusterLoadAssignment.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.endpoint.v3.ClusterLoadAssignment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.endpoint.v3.ClusterLoadAssignment}
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setClusterName(value);
          break;
        case 2:
          var value =
            new envoy_config_endpoint_v3_endpoint_components_pb.LocalityLbEndpoints();
          reader.readMessage(
            value,
            envoy_config_endpoint_v3_endpoint_components_pb.LocalityLbEndpoints
              .deserializeBinaryFromReader
          );
          msg.addEndpoints(value);
          break;
        case 5:
          var value = msg.getNamedEndpointsMap();
          reader.readMessage(value, function (message, reader) {
            jspb.Map.deserializeBinary(
              message,
              reader,
              jspb.BinaryReader.prototype.readString,
              jspb.BinaryReader.prototype.readMessage,
              proto.envoy.config.endpoint.v3.Endpoint
                .deserializeBinaryFromReader,
              '',
              new proto.envoy.config.endpoint.v3.Endpoint()
            );
          });
          break;
        case 4:
          var value =
            new proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy();
          reader.readMessage(
            value,
            proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy
              .deserializeBinaryFromReader
          );
          msg.setPolicy(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.endpoint.v3.ClusterLoadAssignment.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.endpoint.v3.ClusterLoadAssignment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getClusterName();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
    f = message.getEndpointsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        2,
        f,
        envoy_config_endpoint_v3_endpoint_components_pb.LocalityLbEndpoints
          .serializeBinaryToWriter
      );
    }
    f = message.getNamedEndpointsMap(true);
    if (f && f.getLength() > 0) {
      f.serializeBinary(
        5,
        writer,
        jspb.BinaryWriter.prototype.writeString,
        jspb.BinaryWriter.prototype.writeMessage,
        proto.envoy.config.endpoint.v3.Endpoint.serializeBinaryToWriter
      );
    }
    f = message.getPolicy();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy
          .serializeBinaryToWriter
      );
    }
  };

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.repeatedFields_ = [
  2
];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.prototype.toObject =
    function (opt_includeInstance) {
      return proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.toObject =
    function (includeInstance, msg) {
      var f,
        obj = {
          dropOverloadsList: jspb.Message.toObjectList(
            msg.getDropOverloadsList(),
            proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy
              .DropOverload.toObject,
            includeInstance
          ),
          overprovisioningFactor:
            (f = msg.getOverprovisioningFactor()) &&
            google_protobuf_wrappers_pb.UInt32Value.toObject(
              includeInstance,
              f
            ),
          endpointStaleAfter:
            (f = msg.getEndpointStaleAfter()) &&
            google_protobuf_duration_pb.Duration.toObject(includeInstance, f)
        };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }
      return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy}
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy();
    return proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy}
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 2:
          var value =
            new proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.DropOverload();
          reader.readMessage(
            value,
            proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy
              .DropOverload.deserializeBinaryFromReader
          );
          msg.addDropOverloads(value);
          break;
        case 3:
          var value = new google_protobuf_wrappers_pb.UInt32Value();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
          );
          msg.setOverprovisioningFactor(value);
          break;
        case 4:
          var value = new google_protobuf_duration_pb.Duration();
          reader.readMessage(
            value,
            google_protobuf_duration_pb.Duration.deserializeBinaryFromReader
          );
          msg.setEndpointStaleAfter(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getDropOverloadsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        2,
        f,
        proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.DropOverload
          .serializeBinaryToWriter
      );
    }
    f = message.getOverprovisioningFactor();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
      );
    }
    f = message.getEndpointStaleAfter();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        google_protobuf_duration_pb.Duration.serializeBinaryToWriter
      );
    }
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.DropOverload.prototype.toObject =
    function (opt_includeInstance) {
      return proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.DropOverload.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.DropOverload} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.DropOverload.toObject =
    function (includeInstance, msg) {
      var f,
        obj = {
          category: jspb.Message.getFieldWithDefault(msg, 1, ''),
          dropPercentage:
            (f = msg.getDropPercentage()) &&
            envoy_type_v3_percent_pb.FractionalPercent.toObject(
              includeInstance,
              f
            )
        };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }
      return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.DropOverload}
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.DropOverload.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg =
      new proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.DropOverload();
    return proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.DropOverload.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.DropOverload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.DropOverload}
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.DropOverload.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setCategory(value);
          break;
        case 2:
          var value = new envoy_type_v3_percent_pb.FractionalPercent();
          reader.readMessage(
            value,
            envoy_type_v3_percent_pb.FractionalPercent
              .deserializeBinaryFromReader
          );
          msg.setDropPercentage(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.DropOverload.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.DropOverload.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.DropOverload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.DropOverload.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getCategory();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
    f = message.getDropPercentage();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        envoy_type_v3_percent_pb.FractionalPercent.serializeBinaryToWriter
      );
    }
  };

/**
 * optional string category = 1;
 * @return {string}
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.DropOverload.prototype.getCategory =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.DropOverload} returns this
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.DropOverload.prototype.setCategory =
  function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
  };

/**
 * optional envoy.type.v3.FractionalPercent drop_percentage = 2;
 * @return {?proto.envoy.type.v3.FractionalPercent}
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.DropOverload.prototype.getDropPercentage =
  function () {
    return /** @type{?proto.envoy.type.v3.FractionalPercent} */ (
      jspb.Message.getWrapperField(
        this,
        envoy_type_v3_percent_pb.FractionalPercent,
        2
      )
    );
  };

/**
 * @param {?proto.envoy.type.v3.FractionalPercent|undefined} value
 * @return {!proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.DropOverload} returns this
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.DropOverload.prototype.setDropPercentage =
  function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.DropOverload} returns this
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.DropOverload.prototype.clearDropPercentage =
  function () {
    return this.setDropPercentage(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.DropOverload.prototype.hasDropPercentage =
  function () {
    return jspb.Message.getField(this, 2) != null;
  };

/**
 * repeated DropOverload drop_overloads = 2;
 * @return {!Array<!proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.DropOverload>}
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.prototype.getDropOverloadsList =
  function () {
    return /** @type{!Array<!proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.DropOverload>} */ (
      jspb.Message.getRepeatedWrapperField(
        this,
        proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy
          .DropOverload,
        2
      )
    );
  };

/**
 * @param {!Array<!proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.DropOverload>} value
 * @return {!proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy} returns this
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.prototype.setDropOverloadsList =
  function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 2, value);
  };

/**
 * @param {!proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.DropOverload=} opt_value
 * @param {number=} opt_index
 * @return {!proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.DropOverload}
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.prototype.addDropOverloads =
  function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(
      this,
      2,
      opt_value,
      proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.DropOverload,
      opt_index
    );
  };

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy} returns this
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.prototype.clearDropOverloadsList =
  function () {
    return this.setDropOverloadsList([]);
  };

/**
 * optional google.protobuf.UInt32Value overprovisioning_factor = 3;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.prototype.getOverprovisioningFactor =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        3
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy} returns this
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.prototype.setOverprovisioningFactor =
  function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy} returns this
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.prototype.clearOverprovisioningFactor =
  function () {
    return this.setOverprovisioningFactor(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.prototype.hasOverprovisioningFactor =
  function () {
    return jspb.Message.getField(this, 3) != null;
  };

/**
 * optional google.protobuf.Duration endpoint_stale_after = 4;
 * @return {?proto.google.protobuf.Duration}
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.prototype.getEndpointStaleAfter =
  function () {
    return /** @type{?proto.google.protobuf.Duration} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_duration_pb.Duration,
        4
      )
    );
  };

/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy} returns this
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.prototype.setEndpointStaleAfter =
  function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy} returns this
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.prototype.clearEndpointStaleAfter =
  function () {
    return this.setEndpointStaleAfter(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy.prototype.hasEndpointStaleAfter =
  function () {
    return jspb.Message.getField(this, 4) != null;
  };

/**
 * optional string cluster_name = 1;
 * @return {string}
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.prototype.getClusterName =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.config.endpoint.v3.ClusterLoadAssignment} returns this
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.prototype.setClusterName =
  function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
  };

/**
 * repeated LocalityLbEndpoints endpoints = 2;
 * @return {!Array<!proto.envoy.config.endpoint.v3.LocalityLbEndpoints>}
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.prototype.getEndpointsList =
  function () {
    return /** @type{!Array<!proto.envoy.config.endpoint.v3.LocalityLbEndpoints>} */ (
      jspb.Message.getRepeatedWrapperField(
        this,
        envoy_config_endpoint_v3_endpoint_components_pb.LocalityLbEndpoints,
        2
      )
    );
  };

/**
 * @param {!Array<!proto.envoy.config.endpoint.v3.LocalityLbEndpoints>} value
 * @return {!proto.envoy.config.endpoint.v3.ClusterLoadAssignment} returns this
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.prototype.setEndpointsList =
  function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 2, value);
  };

/**
 * @param {!proto.envoy.config.endpoint.v3.LocalityLbEndpoints=} opt_value
 * @param {number=} opt_index
 * @return {!proto.envoy.config.endpoint.v3.LocalityLbEndpoints}
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.prototype.addEndpoints =
  function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(
      this,
      2,
      opt_value,
      proto.envoy.config.endpoint.v3.LocalityLbEndpoints,
      opt_index
    );
  };

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.envoy.config.endpoint.v3.ClusterLoadAssignment} returns this
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.prototype.clearEndpointsList =
  function () {
    return this.setEndpointsList([]);
  };

/**
 * map<string, Endpoint> named_endpoints = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.envoy.config.endpoint.v3.Endpoint>}
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.prototype.getNamedEndpointsMap =
  function (opt_noLazyCreate) {
    return /** @type {!jspb.Map<string,!proto.envoy.config.endpoint.v3.Endpoint>} */ (
      jspb.Message.getMapField(
        this,
        5,
        opt_noLazyCreate,
        proto.envoy.config.endpoint.v3.Endpoint
      )
    );
  };

/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.envoy.config.endpoint.v3.ClusterLoadAssignment} returns this
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.prototype.clearNamedEndpointsMap =
  function () {
    this.getNamedEndpointsMap().clear();
    return this;
  };

/**
 * optional Policy policy = 4;
 * @return {?proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy}
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.prototype.getPolicy =
  function () {
    return /** @type{?proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy} */ (
      jspb.Message.getWrapperField(
        this,
        proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy,
        4
      )
    );
  };

/**
 * @param {?proto.envoy.config.endpoint.v3.ClusterLoadAssignment.Policy|undefined} value
 * @return {!proto.envoy.config.endpoint.v3.ClusterLoadAssignment} returns this
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.prototype.setPolicy =
  function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.endpoint.v3.ClusterLoadAssignment} returns this
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.prototype.clearPolicy =
  function () {
    return this.setPolicy(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.endpoint.v3.ClusterLoadAssignment.prototype.hasPolicy =
  function () {
    return jspb.Message.getField(this, 4) != null;
  };

goog.object.extend(exports, proto.envoy.config.endpoint.v3);
