/**
 * @fileoverview gRPC-Web generated client stub for pomerium.dashboard
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!

/* eslint-disable */
// @ts-nocheck

import * as grpcWeb from 'grpc-web';

import * as users_pb from './users_pb';

export class UserServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string };
  options_: null | { [index: string]: any };

  constructor(
    hostname: string,
    credentials?: null | { [index: string]: string },
    options?: null | { [index: string]: any }
  ) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetUserInfo = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.UserService/GetUserInfo',
    grpcWeb.MethodType.UNARY,
    users_pb.GetUserInfoRequest,
    users_pb.GetUserInfoResponse,
    (request: users_pb.GetUserInfoRequest) => {
      return request.serializeBinary();
    },
    users_pb.GetUserInfoResponse.deserializeBinary
  );

  getUserInfo(
    request: users_pb.GetUserInfoRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<users_pb.GetUserInfoResponse>;

  getUserInfo(
    request: users_pb.GetUserInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: users_pb.GetUserInfoResponse
    ) => void
  ): grpcWeb.ClientReadableStream<users_pb.GetUserInfoResponse>;

  getUserInfo(
    request: users_pb.GetUserInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: users_pb.GetUserInfoResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + '/pomerium.dashboard.UserService/GetUserInfo',
        request,
        metadata || {},
        this.methodInfoGetUserInfo,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + '/pomerium.dashboard.UserService/GetUserInfo',
      request,
      metadata || {},
      this.methodInfoGetUserInfo
    );
  }

  methodInfoQueryGroups = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.UserService/QueryGroups',
    grpcWeb.MethodType.UNARY,
    users_pb.QueryGroupsRequest,
    users_pb.QueryGroupsResponse,
    (request: users_pb.QueryGroupsRequest) => {
      return request.serializeBinary();
    },
    users_pb.QueryGroupsResponse.deserializeBinary
  );

  queryGroups(
    request: users_pb.QueryGroupsRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<users_pb.QueryGroupsResponse>;

  queryGroups(
    request: users_pb.QueryGroupsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: users_pb.QueryGroupsResponse
    ) => void
  ): grpcWeb.ClientReadableStream<users_pb.QueryGroupsResponse>;

  queryGroups(
    request: users_pb.QueryGroupsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: users_pb.QueryGroupsResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + '/pomerium.dashboard.UserService/QueryGroups',
        request,
        metadata || {},
        this.methodInfoQueryGroups,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + '/pomerium.dashboard.UserService/QueryGroups',
      request,
      metadata || {},
      this.methodInfoQueryGroups
    );
  }

  methodInfoQueryUsers = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.UserService/QueryUsers',
    grpcWeb.MethodType.UNARY,
    users_pb.QueryUsersRequest,
    users_pb.QueryUsersResponse,
    (request: users_pb.QueryUsersRequest) => {
      return request.serializeBinary();
    },
    users_pb.QueryUsersResponse.deserializeBinary
  );

  queryUsers(
    request: users_pb.QueryUsersRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<users_pb.QueryUsersResponse>;

  queryUsers(
    request: users_pb.QueryUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: users_pb.QueryUsersResponse
    ) => void
  ): grpcWeb.ClientReadableStream<users_pb.QueryUsersResponse>;

  queryUsers(
    request: users_pb.QueryUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: users_pb.QueryUsersResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + '/pomerium.dashboard.UserService/QueryUsers',
        request,
        metadata || {},
        this.methodInfoQueryUsers,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + '/pomerium.dashboard.UserService/QueryUsers',
      request,
      metadata || {},
      this.methodInfoQueryUsers
    );
  }
}

export class PomeriumServiceAccountServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string };
  options_: null | { [index: string]: any };

  constructor(
    hostname: string,
    credentials?: null | { [index: string]: string },
    options?: null | { [index: string]: any }
  ) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoAddPomeriumServiceAccount = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.PomeriumServiceAccountService/AddPomeriumServiceAccount',
    grpcWeb.MethodType.UNARY,
    users_pb.AddPomeriumServiceAccountRequest,
    users_pb.AddPomeriumServiceAccountResponse,
    (request: users_pb.AddPomeriumServiceAccountRequest) => {
      return request.serializeBinary();
    },
    users_pb.AddPomeriumServiceAccountResponse.deserializeBinary
  );

  addPomeriumServiceAccount(
    request: users_pb.AddPomeriumServiceAccountRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<users_pb.AddPomeriumServiceAccountResponse>;

  addPomeriumServiceAccount(
    request: users_pb.AddPomeriumServiceAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: users_pb.AddPomeriumServiceAccountResponse
    ) => void
  ): grpcWeb.ClientReadableStream<users_pb.AddPomeriumServiceAccountResponse>;

  addPomeriumServiceAccount(
    request: users_pb.AddPomeriumServiceAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: users_pb.AddPomeriumServiceAccountResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/pomerium.dashboard.PomeriumServiceAccountService/AddPomeriumServiceAccount',
        request,
        metadata || {},
        this.methodInfoAddPomeriumServiceAccount,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ +
        '/pomerium.dashboard.PomeriumServiceAccountService/AddPomeriumServiceAccount',
      request,
      metadata || {},
      this.methodInfoAddPomeriumServiceAccount
    );
  }

  methodInfoDeletePomeriumServiceAccount = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.PomeriumServiceAccountService/DeletePomeriumServiceAccount',
    grpcWeb.MethodType.UNARY,
    users_pb.DeletePomeriumServiceAccountRequest,
    users_pb.DeletePomeriumServiceAccountResponse,
    (request: users_pb.DeletePomeriumServiceAccountRequest) => {
      return request.serializeBinary();
    },
    users_pb.DeletePomeriumServiceAccountResponse.deserializeBinary
  );

  deletePomeriumServiceAccount(
    request: users_pb.DeletePomeriumServiceAccountRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<users_pb.DeletePomeriumServiceAccountResponse>;

  deletePomeriumServiceAccount(
    request: users_pb.DeletePomeriumServiceAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: users_pb.DeletePomeriumServiceAccountResponse
    ) => void
  ): grpcWeb.ClientReadableStream<users_pb.DeletePomeriumServiceAccountResponse>;

  deletePomeriumServiceAccount(
    request: users_pb.DeletePomeriumServiceAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: users_pb.DeletePomeriumServiceAccountResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/pomerium.dashboard.PomeriumServiceAccountService/DeletePomeriumServiceAccount',
        request,
        metadata || {},
        this.methodInfoDeletePomeriumServiceAccount,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ +
        '/pomerium.dashboard.PomeriumServiceAccountService/DeletePomeriumServiceAccount',
      request,
      metadata || {},
      this.methodInfoDeletePomeriumServiceAccount
    );
  }

  methodInfoGetPomeriumServiceAccount = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.PomeriumServiceAccountService/GetPomeriumServiceAccount',
    grpcWeb.MethodType.UNARY,
    users_pb.GetPomeriumServiceAccountRequest,
    users_pb.GetPomeriumServiceAccountResponse,
    (request: users_pb.GetPomeriumServiceAccountRequest) => {
      return request.serializeBinary();
    },
    users_pb.GetPomeriumServiceAccountResponse.deserializeBinary
  );

  getPomeriumServiceAccount(
    request: users_pb.GetPomeriumServiceAccountRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<users_pb.GetPomeriumServiceAccountResponse>;

  getPomeriumServiceAccount(
    request: users_pb.GetPomeriumServiceAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: users_pb.GetPomeriumServiceAccountResponse
    ) => void
  ): grpcWeb.ClientReadableStream<users_pb.GetPomeriumServiceAccountResponse>;

  getPomeriumServiceAccount(
    request: users_pb.GetPomeriumServiceAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: users_pb.GetPomeriumServiceAccountResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/pomerium.dashboard.PomeriumServiceAccountService/GetPomeriumServiceAccount',
        request,
        metadata || {},
        this.methodInfoGetPomeriumServiceAccount,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ +
        '/pomerium.dashboard.PomeriumServiceAccountService/GetPomeriumServiceAccount',
      request,
      metadata || {},
      this.methodInfoGetPomeriumServiceAccount
    );
  }

  methodInfoListPomeriumServiceAccounts = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.PomeriumServiceAccountService/ListPomeriumServiceAccounts',
    grpcWeb.MethodType.UNARY,
    users_pb.ListPomeriumServiceAccountsRequest,
    users_pb.ListPomeriumServiceAccountsResponse,
    (request: users_pb.ListPomeriumServiceAccountsRequest) => {
      return request.serializeBinary();
    },
    users_pb.ListPomeriumServiceAccountsResponse.deserializeBinary
  );

  listPomeriumServiceAccounts(
    request: users_pb.ListPomeriumServiceAccountsRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<users_pb.ListPomeriumServiceAccountsResponse>;

  listPomeriumServiceAccounts(
    request: users_pb.ListPomeriumServiceAccountsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: users_pb.ListPomeriumServiceAccountsResponse
    ) => void
  ): grpcWeb.ClientReadableStream<users_pb.ListPomeriumServiceAccountsResponse>;

  listPomeriumServiceAccounts(
    request: users_pb.ListPomeriumServiceAccountsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: users_pb.ListPomeriumServiceAccountsResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/pomerium.dashboard.PomeriumServiceAccountService/ListPomeriumServiceAccounts',
        request,
        metadata || {},
        this.methodInfoListPomeriumServiceAccounts,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ +
        '/pomerium.dashboard.PomeriumServiceAccountService/ListPomeriumServiceAccounts',
      request,
      metadata || {},
      this.methodInfoListPomeriumServiceAccounts
    );
  }
}

export class PomeriumSessionServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string };
  options_: null | { [index: string]: any };

  constructor(
    hostname: string,
    credentials?: null | { [index: string]: string },
    options?: null | { [index: string]: any }
  ) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoDeletePomeriumSession = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.PomeriumSessionService/DeletePomeriumSession',
    grpcWeb.MethodType.UNARY,
    users_pb.DeletePomeriumSessionRequest,
    users_pb.DeletePomeriumSessionResponse,
    (request: users_pb.DeletePomeriumSessionRequest) => {
      return request.serializeBinary();
    },
    users_pb.DeletePomeriumSessionResponse.deserializeBinary
  );

  deletePomeriumSession(
    request: users_pb.DeletePomeriumSessionRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<users_pb.DeletePomeriumSessionResponse>;

  deletePomeriumSession(
    request: users_pb.DeletePomeriumSessionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: users_pb.DeletePomeriumSessionResponse
    ) => void
  ): grpcWeb.ClientReadableStream<users_pb.DeletePomeriumSessionResponse>;

  deletePomeriumSession(
    request: users_pb.DeletePomeriumSessionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: users_pb.DeletePomeriumSessionResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/pomerium.dashboard.PomeriumSessionService/DeletePomeriumSession',
        request,
        metadata || {},
        this.methodInfoDeletePomeriumSession,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ +
        '/pomerium.dashboard.PomeriumSessionService/DeletePomeriumSession',
      request,
      metadata || {},
      this.methodInfoDeletePomeriumSession
    );
  }

  methodInfoGetPomeriumSession = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.PomeriumSessionService/GetPomeriumSession',
    grpcWeb.MethodType.UNARY,
    users_pb.GetPomeriumSessionRequest,
    users_pb.GetPomeriumSessionResponse,
    (request: users_pb.GetPomeriumSessionRequest) => {
      return request.serializeBinary();
    },
    users_pb.GetPomeriumSessionResponse.deserializeBinary
  );

  getPomeriumSession(
    request: users_pb.GetPomeriumSessionRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<users_pb.GetPomeriumSessionResponse>;

  getPomeriumSession(
    request: users_pb.GetPomeriumSessionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: users_pb.GetPomeriumSessionResponse
    ) => void
  ): grpcWeb.ClientReadableStream<users_pb.GetPomeriumSessionResponse>;

  getPomeriumSession(
    request: users_pb.GetPomeriumSessionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: users_pb.GetPomeriumSessionResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/pomerium.dashboard.PomeriumSessionService/GetPomeriumSession',
        request,
        metadata || {},
        this.methodInfoGetPomeriumSession,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ +
        '/pomerium.dashboard.PomeriumSessionService/GetPomeriumSession',
      request,
      metadata || {},
      this.methodInfoGetPomeriumSession
    );
  }

  methodInfoImpersonate = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.PomeriumSessionService/Impersonate',
    grpcWeb.MethodType.UNARY,
    users_pb.ImpersonateRequest,
    users_pb.ImpersonateResponse,
    (request: users_pb.ImpersonateRequest) => {
      return request.serializeBinary();
    },
    users_pb.ImpersonateResponse.deserializeBinary
  );

  impersonate(
    request: users_pb.ImpersonateRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<users_pb.ImpersonateResponse>;

  impersonate(
    request: users_pb.ImpersonateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: users_pb.ImpersonateResponse
    ) => void
  ): grpcWeb.ClientReadableStream<users_pb.ImpersonateResponse>;

  impersonate(
    request: users_pb.ImpersonateRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: users_pb.ImpersonateResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/pomerium.dashboard.PomeriumSessionService/Impersonate',
        request,
        metadata || {},
        this.methodInfoImpersonate,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + '/pomerium.dashboard.PomeriumSessionService/Impersonate',
      request,
      metadata || {},
      this.methodInfoImpersonate
    );
  }

  methodInfoListPomeriumSessions = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.PomeriumSessionService/ListPomeriumSessions',
    grpcWeb.MethodType.UNARY,
    users_pb.ListPomeriumSessionsRequest,
    users_pb.ListPomeriumSessionsResponse,
    (request: users_pb.ListPomeriumSessionsRequest) => {
      return request.serializeBinary();
    },
    users_pb.ListPomeriumSessionsResponse.deserializeBinary
  );

  listPomeriumSessions(
    request: users_pb.ListPomeriumSessionsRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<users_pb.ListPomeriumSessionsResponse>;

  listPomeriumSessions(
    request: users_pb.ListPomeriumSessionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: users_pb.ListPomeriumSessionsResponse
    ) => void
  ): grpcWeb.ClientReadableStream<users_pb.ListPomeriumSessionsResponse>;

  listPomeriumSessions(
    request: users_pb.ListPomeriumSessionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: users_pb.ListPomeriumSessionsResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/pomerium.dashboard.PomeriumSessionService/ListPomeriumSessions',
        request,
        metadata || {},
        this.methodInfoListPomeriumSessions,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ +
        '/pomerium.dashboard.PomeriumSessionService/ListPomeriumSessions',
      request,
      metadata || {},
      this.methodInfoListPomeriumSessions
    );
  }
}
