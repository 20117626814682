// source: devices.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol(
  'proto.pomerium.dashboard.ApproveDeviceRequest',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.ApproveDeviceRequest.IdCase',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.CreateDeviceEnrollmentRequest',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.CreateDeviceEnrollmentResponse',
  null,
  global
);
goog.exportSymbol('proto.pomerium.dashboard.DeleteDeviceRequest', null, global);
goog.exportSymbol(
  'proto.pomerium.dashboard.DeleteDeviceRequest.IdCase',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.DeleteDeviceTypeRequest',
  null,
  global
);
goog.exportSymbol('proto.pomerium.dashboard.DeviceCredential', null, global);
goog.exportSymbol(
  'proto.pomerium.dashboard.DeviceCredential.SpecifierCase',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.DeviceCredential.WebAuthn',
  null,
  global
);
goog.exportSymbol('proto.pomerium.dashboard.DeviceEnrollment', null, global);
goog.exportSymbol('proto.pomerium.dashboard.DeviceKind', null, global);
goog.exportSymbol(
  'proto.pomerium.dashboard.DeviceOwnerCredentialRecord',
  null,
  global
);
goog.exportSymbol('proto.pomerium.dashboard.DeviceType', null, global);
goog.exportSymbol(
  'proto.pomerium.dashboard.DeviceType.SpecifierCase',
  null,
  global
);
goog.exportSymbol('proto.pomerium.dashboard.DeviceType.WebAuthn', null, global);
goog.exportSymbol(
  'proto.pomerium.dashboard.ListDeviceTypesResponse',
  null,
  global
);
goog.exportSymbol('proto.pomerium.dashboard.ListDevicesRequest', null, global);
goog.exportSymbol('proto.pomerium.dashboard.ListDevicesResponse', null, global);
goog.exportSymbol(
  'proto.pomerium.dashboard.ListDevicesResponse.Device',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.SetDeviceTypeRequest',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.SetDeviceTypeResponse',
  null,
  global
);
goog.exportSymbol('proto.pomerium.dashboard.WebAuthnOptions', null, global);
goog.exportSymbol(
  'proto.pomerium.dashboard.WebAuthnOptions.AttestationConveyancePreference',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorAttachment',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.WebAuthnOptions.PublicKeyCredentialParameters',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.WebAuthnOptions.PublicKeyCredentialType',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.WebAuthnOptions.ResidentKeyRequirement',
  null,
  global
);
goog.exportSymbol(
  'proto.pomerium.dashboard.WebAuthnOptions.UserVerificationRequirement',
  null,
  global
);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.WebAuthnOptions = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.pomerium.dashboard.WebAuthnOptions.repeatedFields_,
    null
  );
};
goog.inherits(proto.pomerium.dashboard.WebAuthnOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.WebAuthnOptions.displayName =
    'proto.pomerium.dashboard.WebAuthnOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria =
  function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  };
goog.inherits(
  proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria.displayName =
    'proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.WebAuthnOptions.PublicKeyCredentialParameters =
  function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  };
goog.inherits(
  proto.pomerium.dashboard.WebAuthnOptions.PublicKeyCredentialParameters,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.WebAuthnOptions.PublicKeyCredentialParameters.displayName =
    'proto.pomerium.dashboard.WebAuthnOptions.PublicKeyCredentialParameters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.DeviceType = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    null,
    proto.pomerium.dashboard.DeviceType.oneofGroups_
  );
};
goog.inherits(proto.pomerium.dashboard.DeviceType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.DeviceType.displayName =
    'proto.pomerium.dashboard.DeviceType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.DeviceType.WebAuthn = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.DeviceType.WebAuthn, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.DeviceType.WebAuthn.displayName =
    'proto.pomerium.dashboard.DeviceType.WebAuthn';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.DeviceEnrollment = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.DeviceEnrollment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.DeviceEnrollment.displayName =
    'proto.pomerium.dashboard.DeviceEnrollment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.DeviceCredential = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    null,
    proto.pomerium.dashboard.DeviceCredential.oneofGroups_
  );
};
goog.inherits(proto.pomerium.dashboard.DeviceCredential, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.DeviceCredential.displayName =
    'proto.pomerium.dashboard.DeviceCredential';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.DeviceCredential.WebAuthn = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.pomerium.dashboard.DeviceCredential.WebAuthn.repeatedFields_,
    null
  );
};
goog.inherits(proto.pomerium.dashboard.DeviceCredential.WebAuthn, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.DeviceCredential.WebAuthn.displayName =
    'proto.pomerium.dashboard.DeviceCredential.WebAuthn';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.DeviceOwnerCredentialRecord = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(
  proto.pomerium.dashboard.DeviceOwnerCredentialRecord,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.DeviceOwnerCredentialRecord.displayName =
    'proto.pomerium.dashboard.DeviceOwnerCredentialRecord';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.ApproveDeviceRequest = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    null,
    proto.pomerium.dashboard.ApproveDeviceRequest.oneofGroups_
  );
};
goog.inherits(proto.pomerium.dashboard.ApproveDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.ApproveDeviceRequest.displayName =
    'proto.pomerium.dashboard.ApproveDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.CreateDeviceEnrollmentRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(
  proto.pomerium.dashboard.CreateDeviceEnrollmentRequest,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.CreateDeviceEnrollmentRequest.displayName =
    'proto.pomerium.dashboard.CreateDeviceEnrollmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.CreateDeviceEnrollmentResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(
  proto.pomerium.dashboard.CreateDeviceEnrollmentResponse,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.CreateDeviceEnrollmentResponse.displayName =
    'proto.pomerium.dashboard.CreateDeviceEnrollmentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.DeleteDeviceRequest = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    null,
    proto.pomerium.dashboard.DeleteDeviceRequest.oneofGroups_
  );
};
goog.inherits(proto.pomerium.dashboard.DeleteDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.DeleteDeviceRequest.displayName =
    'proto.pomerium.dashboard.DeleteDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.DeleteDeviceTypeRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.DeleteDeviceTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.DeleteDeviceTypeRequest.displayName =
    'proto.pomerium.dashboard.DeleteDeviceTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.ListDevicesRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.ListDevicesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.ListDevicesRequest.displayName =
    'proto.pomerium.dashboard.ListDevicesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.ListDevicesResponse = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.pomerium.dashboard.ListDevicesResponse.repeatedFields_,
    null
  );
};
goog.inherits(proto.pomerium.dashboard.ListDevicesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.ListDevicesResponse.displayName =
    'proto.pomerium.dashboard.ListDevicesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.ListDevicesResponse.Device = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(
  proto.pomerium.dashboard.ListDevicesResponse.Device,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.ListDevicesResponse.Device.displayName =
    'proto.pomerium.dashboard.ListDevicesResponse.Device';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.ListDeviceTypesResponse = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.pomerium.dashboard.ListDeviceTypesResponse.repeatedFields_,
    null
  );
};
goog.inherits(proto.pomerium.dashboard.ListDeviceTypesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.ListDeviceTypesResponse.displayName =
    'proto.pomerium.dashboard.ListDeviceTypesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.SetDeviceTypeRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.SetDeviceTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.SetDeviceTypeRequest.displayName =
    'proto.pomerium.dashboard.SetDeviceTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pomerium.dashboard.SetDeviceTypeResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pomerium.dashboard.SetDeviceTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pomerium.dashboard.SetDeviceTypeResponse.displayName =
    'proto.pomerium.dashboard.SetDeviceTypeResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pomerium.dashboard.WebAuthnOptions.repeatedFields_ = [3];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.WebAuthnOptions.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.WebAuthnOptions.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.WebAuthnOptions} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.WebAuthnOptions.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        attestation: jspb.Message.getFieldWithDefault(msg, 1, 0),
        authenticatorSelection:
          (f = msg.getAuthenticatorSelection()) &&
          proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria.toObject(
            includeInstance,
            f
          ),
        pubKeyCredParamsList: jspb.Message.toObjectList(
          msg.getPubKeyCredParamsList(),
          proto.pomerium.dashboard.WebAuthnOptions.PublicKeyCredentialParameters
            .toObject,
          includeInstance
        )
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.WebAuthnOptions}
 */
proto.pomerium.dashboard.WebAuthnOptions.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.WebAuthnOptions();
  return proto.pomerium.dashboard.WebAuthnOptions.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.WebAuthnOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.WebAuthnOptions}
 */
proto.pomerium.dashboard.WebAuthnOptions.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value =
            /** @type {!proto.pomerium.dashboard.WebAuthnOptions.AttestationConveyancePreference} */ (
              reader.readEnum()
            );
          msg.setAttestation(value);
          break;
        case 2:
          var value =
            new proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.WebAuthnOptions
              .AuthenticatorSelectionCriteria.deserializeBinaryFromReader
          );
          msg.setAuthenticatorSelection(value);
          break;
        case 3:
          var value =
            new proto.pomerium.dashboard.WebAuthnOptions.PublicKeyCredentialParameters();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.WebAuthnOptions
              .PublicKeyCredentialParameters.deserializeBinaryFromReader
          );
          msg.addPubKeyCredParams(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.WebAuthnOptions.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.WebAuthnOptions.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.WebAuthnOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.WebAuthnOptions.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f =
    /** @type {!proto.pomerium.dashboard.WebAuthnOptions.AttestationConveyancePreference} */ (
      jspb.Message.getField(message, 1)
    );
  if (f != null) {
    writer.writeEnum(1, f);
  }
  f = message.getAuthenticatorSelection();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria
        .serializeBinaryToWriter
    );
  }
  f = message.getPubKeyCredParamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.pomerium.dashboard.WebAuthnOptions.PublicKeyCredentialParameters
        .serializeBinaryToWriter
    );
  }
};

/**
 * @enum {number}
 */
proto.pomerium.dashboard.WebAuthnOptions.AttestationConveyancePreference = {
  NONE: 0,
  INDIRECT: 1,
  DIRECT: 2,
  ENTERPRISE: 3
};

/**
 * @enum {number}
 */
proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorAttachment = {
  PLATFORM: 0,
  CROSS_PLATFORM: 2
};

/**
 * @enum {number}
 */
proto.pomerium.dashboard.WebAuthnOptions.PublicKeyCredentialType = {
  PUBLIC_KEY: 0
};

/**
 * @enum {number}
 */
proto.pomerium.dashboard.WebAuthnOptions.ResidentKeyRequirement = {
  RESIDENT_KEY_DISCOURAGED: 0,
  RESIDENT_KEY_PREFERRED: 1,
  RESIDENT_KEY_REQUIRED: 2
};

/**
 * @enum {number}
 */
proto.pomerium.dashboard.WebAuthnOptions.UserVerificationRequirement = {
  USER_VERIFICATION_DISCOURAGED: 0,
  USER_VERIFICATION_PREFERRED: 1,
  USER_VERIFICATION_REQUIRED: 2
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria.toObject =
    function (includeInstance, msg) {
      var f,
        obj = {
          authenticatorAttachment: jspb.Message.getFieldWithDefault(msg, 1, 0),
          requireResidentKey: jspb.Message.getBooleanFieldWithDefault(
            msg,
            2,
            false
          ),
          residentKeyRequirement: jspb.Message.getFieldWithDefault(msg, 3, 0),
          userVerification: jspb.Message.getFieldWithDefault(msg, 4, 0)
        };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }
      return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria}
 */
proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg =
      new proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria();
    return proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria}
 */
proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value =
            /** @type {!proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorAttachment} */ (
              reader.readEnum()
            );
          msg.setAuthenticatorAttachment(value);
          break;
        case 2:
          var value = /** @type {boolean} */ (reader.readBool());
          msg.setRequireResidentKey(value);
          break;
        case 3:
          var value =
            /** @type {!proto.pomerium.dashboard.WebAuthnOptions.ResidentKeyRequirement} */ (
              reader.readEnum()
            );
          msg.setResidentKeyRequirement(value);
          break;
        case 4:
          var value =
            /** @type {!proto.pomerium.dashboard.WebAuthnOptions.UserVerificationRequirement} */ (
              reader.readEnum()
            );
          msg.setUserVerification(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f =
      /** @type {!proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorAttachment} */ (
        jspb.Message.getField(message, 1)
      );
    if (f != null) {
      writer.writeEnum(1, f);
    }
    f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
    if (f != null) {
      writer.writeBool(2, f);
    }
    f =
      /** @type {!proto.pomerium.dashboard.WebAuthnOptions.ResidentKeyRequirement} */ (
        jspb.Message.getField(message, 3)
      );
    if (f != null) {
      writer.writeEnum(3, f);
    }
    f =
      /** @type {!proto.pomerium.dashboard.WebAuthnOptions.UserVerificationRequirement} */ (
        jspb.Message.getField(message, 4)
      );
    if (f != null) {
      writer.writeEnum(4, f);
    }
  };

/**
 * optional AuthenticatorAttachment authenticator_attachment = 1;
 * @return {!proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorAttachment}
 */
proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria.prototype.getAuthenticatorAttachment =
  function () {
    return /** @type {!proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorAttachment} */ (
      jspb.Message.getFieldWithDefault(this, 1, 0)
    );
  };

/**
 * @param {!proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorAttachment} value
 * @return {!proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria} returns this
 */
proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria.prototype.setAuthenticatorAttachment =
  function (value) {
    return jspb.Message.setField(this, 1, value);
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria} returns this
 */
proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria.prototype.clearAuthenticatorAttachment =
  function () {
    return jspb.Message.setField(this, 1, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria.prototype.hasAuthenticatorAttachment =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

/**
 * optional bool require_resident_key = 2;
 * @return {boolean}
 */
proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria.prototype.getRequireResidentKey =
  function () {
    return /** @type {boolean} */ (
      jspb.Message.getBooleanFieldWithDefault(this, 2, false)
    );
  };

/**
 * @param {boolean} value
 * @return {!proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria} returns this
 */
proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria.prototype.setRequireResidentKey =
  function (value) {
    return jspb.Message.setField(this, 2, value);
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria} returns this
 */
proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria.prototype.clearRequireResidentKey =
  function () {
    return jspb.Message.setField(this, 2, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria.prototype.hasRequireResidentKey =
  function () {
    return jspb.Message.getField(this, 2) != null;
  };

/**
 * optional ResidentKeyRequirement resident_key_requirement = 3;
 * @return {!proto.pomerium.dashboard.WebAuthnOptions.ResidentKeyRequirement}
 */
proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria.prototype.getResidentKeyRequirement =
  function () {
    return /** @type {!proto.pomerium.dashboard.WebAuthnOptions.ResidentKeyRequirement} */ (
      jspb.Message.getFieldWithDefault(this, 3, 0)
    );
  };

/**
 * @param {!proto.pomerium.dashboard.WebAuthnOptions.ResidentKeyRequirement} value
 * @return {!proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria} returns this
 */
proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria.prototype.setResidentKeyRequirement =
  function (value) {
    return jspb.Message.setField(this, 3, value);
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria} returns this
 */
proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria.prototype.clearResidentKeyRequirement =
  function () {
    return jspb.Message.setField(this, 3, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria.prototype.hasResidentKeyRequirement =
  function () {
    return jspb.Message.getField(this, 3) != null;
  };

/**
 * optional UserVerificationRequirement user_verification = 4;
 * @return {!proto.pomerium.dashboard.WebAuthnOptions.UserVerificationRequirement}
 */
proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria.prototype.getUserVerification =
  function () {
    return /** @type {!proto.pomerium.dashboard.WebAuthnOptions.UserVerificationRequirement} */ (
      jspb.Message.getFieldWithDefault(this, 4, 0)
    );
  };

/**
 * @param {!proto.pomerium.dashboard.WebAuthnOptions.UserVerificationRequirement} value
 * @return {!proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria} returns this
 */
proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria.prototype.setUserVerification =
  function (value) {
    return jspb.Message.setField(this, 4, value);
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria} returns this
 */
proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria.prototype.clearUserVerification =
  function () {
    return jspb.Message.setField(this, 4, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria.prototype.hasUserVerification =
  function () {
    return jspb.Message.getField(this, 4) != null;
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.WebAuthnOptions.PublicKeyCredentialParameters.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.WebAuthnOptions.PublicKeyCredentialParameters.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.WebAuthnOptions.PublicKeyCredentialParameters} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.WebAuthnOptions.PublicKeyCredentialParameters.toObject =
    function (includeInstance, msg) {
      var f,
        obj = {
          alg: jspb.Message.getFieldWithDefault(msg, 1, 0),
          type: jspb.Message.getFieldWithDefault(msg, 2, 0)
        };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }
      return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.WebAuthnOptions.PublicKeyCredentialParameters}
 */
proto.pomerium.dashboard.WebAuthnOptions.PublicKeyCredentialParameters.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg =
      new proto.pomerium.dashboard.WebAuthnOptions.PublicKeyCredentialParameters();
    return proto.pomerium.dashboard.WebAuthnOptions.PublicKeyCredentialParameters.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.WebAuthnOptions.PublicKeyCredentialParameters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.WebAuthnOptions.PublicKeyCredentialParameters}
 */
proto.pomerium.dashboard.WebAuthnOptions.PublicKeyCredentialParameters.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {number} */ (reader.readInt64());
          msg.setAlg(value);
          break;
        case 2:
          var value =
            /** @type {!proto.pomerium.dashboard.WebAuthnOptions.PublicKeyCredentialType} */ (
              reader.readEnum()
            );
          msg.setType(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.WebAuthnOptions.PublicKeyCredentialParameters.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.WebAuthnOptions.PublicKeyCredentialParameters.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.WebAuthnOptions.PublicKeyCredentialParameters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.WebAuthnOptions.PublicKeyCredentialParameters.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getAlg();
    if (f !== 0) {
      writer.writeInt64(1, f);
    }
    f = message.getType();
    if (f !== 0.0) {
      writer.writeEnum(2, f);
    }
  };

/**
 * optional int64 alg = 1;
 * @return {number}
 */
proto.pomerium.dashboard.WebAuthnOptions.PublicKeyCredentialParameters.prototype.getAlg =
  function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };

/**
 * @param {number} value
 * @return {!proto.pomerium.dashboard.WebAuthnOptions.PublicKeyCredentialParameters} returns this
 */
proto.pomerium.dashboard.WebAuthnOptions.PublicKeyCredentialParameters.prototype.setAlg =
  function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
  };

/**
 * optional PublicKeyCredentialType type = 2;
 * @return {!proto.pomerium.dashboard.WebAuthnOptions.PublicKeyCredentialType}
 */
proto.pomerium.dashboard.WebAuthnOptions.PublicKeyCredentialParameters.prototype.getType =
  function () {
    return /** @type {!proto.pomerium.dashboard.WebAuthnOptions.PublicKeyCredentialType} */ (
      jspb.Message.getFieldWithDefault(this, 2, 0)
    );
  };

/**
 * @param {!proto.pomerium.dashboard.WebAuthnOptions.PublicKeyCredentialType} value
 * @return {!proto.pomerium.dashboard.WebAuthnOptions.PublicKeyCredentialParameters} returns this
 */
proto.pomerium.dashboard.WebAuthnOptions.PublicKeyCredentialParameters.prototype.setType =
  function (value) {
    return jspb.Message.setProto3EnumField(this, 2, value);
  };

/**
 * optional AttestationConveyancePreference attestation = 1;
 * @return {!proto.pomerium.dashboard.WebAuthnOptions.AttestationConveyancePreference}
 */
proto.pomerium.dashboard.WebAuthnOptions.prototype.getAttestation =
  function () {
    return /** @type {!proto.pomerium.dashboard.WebAuthnOptions.AttestationConveyancePreference} */ (
      jspb.Message.getFieldWithDefault(this, 1, 0)
    );
  };

/**
 * @param {!proto.pomerium.dashboard.WebAuthnOptions.AttestationConveyancePreference} value
 * @return {!proto.pomerium.dashboard.WebAuthnOptions} returns this
 */
proto.pomerium.dashboard.WebAuthnOptions.prototype.setAttestation = function (
  value
) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.WebAuthnOptions} returns this
 */
proto.pomerium.dashboard.WebAuthnOptions.prototype.clearAttestation =
  function () {
    return jspb.Message.setField(this, 1, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.WebAuthnOptions.prototype.hasAttestation =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

/**
 * optional AuthenticatorSelectionCriteria authenticator_selection = 2;
 * @return {?proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria}
 */
proto.pomerium.dashboard.WebAuthnOptions.prototype.getAuthenticatorSelection =
  function () {
    return /** @type{?proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria} */ (
      jspb.Message.getWrapperField(
        this,
        proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria,
        2
      )
    );
  };

/**
 * @param {?proto.pomerium.dashboard.WebAuthnOptions.AuthenticatorSelectionCriteria|undefined} value
 * @return {!proto.pomerium.dashboard.WebAuthnOptions} returns this
 */
proto.pomerium.dashboard.WebAuthnOptions.prototype.setAuthenticatorSelection =
  function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.WebAuthnOptions} returns this
 */
proto.pomerium.dashboard.WebAuthnOptions.prototype.clearAuthenticatorSelection =
  function () {
    return this.setAuthenticatorSelection(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.WebAuthnOptions.prototype.hasAuthenticatorSelection =
  function () {
    return jspb.Message.getField(this, 2) != null;
  };

/**
 * repeated PublicKeyCredentialParameters pub_key_cred_params = 3;
 * @return {!Array<!proto.pomerium.dashboard.WebAuthnOptions.PublicKeyCredentialParameters>}
 */
proto.pomerium.dashboard.WebAuthnOptions.prototype.getPubKeyCredParamsList =
  function () {
    return /** @type{!Array<!proto.pomerium.dashboard.WebAuthnOptions.PublicKeyCredentialParameters>} */ (
      jspb.Message.getRepeatedWrapperField(
        this,
        proto.pomerium.dashboard.WebAuthnOptions.PublicKeyCredentialParameters,
        3
      )
    );
  };

/**
 * @param {!Array<!proto.pomerium.dashboard.WebAuthnOptions.PublicKeyCredentialParameters>} value
 * @return {!proto.pomerium.dashboard.WebAuthnOptions} returns this
 */
proto.pomerium.dashboard.WebAuthnOptions.prototype.setPubKeyCredParamsList =
  function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 3, value);
  };

/**
 * @param {!proto.pomerium.dashboard.WebAuthnOptions.PublicKeyCredentialParameters=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.WebAuthnOptions.PublicKeyCredentialParameters}
 */
proto.pomerium.dashboard.WebAuthnOptions.prototype.addPubKeyCredParams =
  function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(
      this,
      3,
      opt_value,
      proto.pomerium.dashboard.WebAuthnOptions.PublicKeyCredentialParameters,
      opt_index
    );
  };

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.WebAuthnOptions} returns this
 */
proto.pomerium.dashboard.WebAuthnOptions.prototype.clearPubKeyCredParamsList =
  function () {
    return this.setPubKeyCredParamsList([]);
  };

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.pomerium.dashboard.DeviceType.oneofGroups_ = [[6]];

/**
 * @enum {number}
 */
proto.pomerium.dashboard.DeviceType.SpecifierCase = {
  SPECIFIER_NOT_SET: 0,
  WEBAUTHN: 6
};

/**
 * @return {proto.pomerium.dashboard.DeviceType.SpecifierCase}
 */
proto.pomerium.dashboard.DeviceType.prototype.getSpecifierCase = function () {
  return /** @type {proto.pomerium.dashboard.DeviceType.SpecifierCase} */ (
    jspb.Message.computeOneofCase(
      this,
      proto.pomerium.dashboard.DeviceType.oneofGroups_[0]
    )
  );
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.DeviceType.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.DeviceType.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.DeviceType} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.DeviceType.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, ''),
        createdAt:
          (f = msg.getCreatedAt()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        modifiedAt:
          (f = msg.getModifiedAt()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        deletedAt:
          (f = msg.getDeletedAt()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        name: jspb.Message.getFieldWithDefault(msg, 5, ''),
        webauthn:
          (f = msg.getWebauthn()) &&
          proto.pomerium.dashboard.DeviceType.WebAuthn.toObject(
            includeInstance,
            f
          )
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.DeviceType}
 */
proto.pomerium.dashboard.DeviceType.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.DeviceType();
  return proto.pomerium.dashboard.DeviceType.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.DeviceType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.DeviceType}
 */
proto.pomerium.dashboard.DeviceType.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        );
        msg.setCreatedAt(value);
        break;
      case 3:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        );
        msg.setModifiedAt(value);
        break;
      case 4:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        );
        msg.setDeletedAt(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 6:
        var value = new proto.pomerium.dashboard.DeviceType.WebAuthn();
        reader.readMessage(
          value,
          proto.pomerium.dashboard.DeviceType.WebAuthn
            .deserializeBinaryFromReader
        );
        msg.setWebauthn(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.DeviceType.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.pomerium.dashboard.DeviceType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.DeviceType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.DeviceType.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getModifiedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeletedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(5, f);
  }
  f = message.getWebauthn();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.pomerium.dashboard.DeviceType.WebAuthn.serializeBinaryToWriter
    );
  }
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.DeviceType.WebAuthn.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.DeviceType.WebAuthn.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.DeviceType.WebAuthn} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.DeviceType.WebAuthn.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        options:
          (f = msg.getOptions()) &&
          proto.pomerium.dashboard.WebAuthnOptions.toObject(includeInstance, f)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.DeviceType.WebAuthn}
 */
proto.pomerium.dashboard.DeviceType.WebAuthn.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.DeviceType.WebAuthn();
  return proto.pomerium.dashboard.DeviceType.WebAuthn.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.DeviceType.WebAuthn} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.DeviceType.WebAuthn}
 */
proto.pomerium.dashboard.DeviceType.WebAuthn.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.WebAuthnOptions();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.WebAuthnOptions.deserializeBinaryFromReader
          );
          msg.setOptions(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.DeviceType.WebAuthn.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.DeviceType.WebAuthn.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.DeviceType.WebAuthn} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.DeviceType.WebAuthn.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getOptions();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        proto.pomerium.dashboard.WebAuthnOptions.serializeBinaryToWriter
      );
    }
  };

/**
 * optional WebAuthnOptions options = 1;
 * @return {?proto.pomerium.dashboard.WebAuthnOptions}
 */
proto.pomerium.dashboard.DeviceType.WebAuthn.prototype.getOptions =
  function () {
    return /** @type{?proto.pomerium.dashboard.WebAuthnOptions} */ (
      jspb.Message.getWrapperField(
        this,
        proto.pomerium.dashboard.WebAuthnOptions,
        1
      )
    );
  };

/**
 * @param {?proto.pomerium.dashboard.WebAuthnOptions|undefined} value
 * @return {!proto.pomerium.dashboard.DeviceType.WebAuthn} returns this
 */
proto.pomerium.dashboard.DeviceType.WebAuthn.prototype.setOptions = function (
  value
) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.DeviceType.WebAuthn} returns this
 */
proto.pomerium.dashboard.DeviceType.WebAuthn.prototype.clearOptions =
  function () {
    return this.setOptions(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.DeviceType.WebAuthn.prototype.hasOptions =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

/**
 * optional string id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.DeviceType.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.DeviceType} returns this
 */
proto.pomerium.dashboard.DeviceType.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional google.protobuf.Timestamp created_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pomerium.dashboard.DeviceType.prototype.getCreatedAt = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_timestamp_pb.Timestamp,
      2
    )
  );
};

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.pomerium.dashboard.DeviceType} returns this
 */
proto.pomerium.dashboard.DeviceType.prototype.setCreatedAt = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.DeviceType} returns this
 */
proto.pomerium.dashboard.DeviceType.prototype.clearCreatedAt = function () {
  return this.setCreatedAt(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.DeviceType.prototype.hasCreatedAt = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional google.protobuf.Timestamp modified_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pomerium.dashboard.DeviceType.prototype.getModifiedAt = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_timestamp_pb.Timestamp,
      3
    )
  );
};

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.pomerium.dashboard.DeviceType} returns this
 */
proto.pomerium.dashboard.DeviceType.prototype.setModifiedAt = function (value) {
  return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.DeviceType} returns this
 */
proto.pomerium.dashboard.DeviceType.prototype.clearModifiedAt = function () {
  return this.setModifiedAt(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.DeviceType.prototype.hasModifiedAt = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional google.protobuf.Timestamp deleted_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pomerium.dashboard.DeviceType.prototype.getDeletedAt = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_timestamp_pb.Timestamp,
      4
    )
  );
};

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.pomerium.dashboard.DeviceType} returns this
 */
proto.pomerium.dashboard.DeviceType.prototype.setDeletedAt = function (value) {
  return jspb.Message.setWrapperField(this, 4, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.DeviceType} returns this
 */
proto.pomerium.dashboard.DeviceType.prototype.clearDeletedAt = function () {
  return this.setDeletedAt(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.DeviceType.prototype.hasDeletedAt = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional string name = 5;
 * @return {string}
 */
proto.pomerium.dashboard.DeviceType.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.DeviceType} returns this
 */
proto.pomerium.dashboard.DeviceType.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};

/**
 * optional WebAuthn webauthn = 6;
 * @return {?proto.pomerium.dashboard.DeviceType.WebAuthn}
 */
proto.pomerium.dashboard.DeviceType.prototype.getWebauthn = function () {
  return /** @type{?proto.pomerium.dashboard.DeviceType.WebAuthn} */ (
    jspb.Message.getWrapperField(
      this,
      proto.pomerium.dashboard.DeviceType.WebAuthn,
      6
    )
  );
};

/**
 * @param {?proto.pomerium.dashboard.DeviceType.WebAuthn|undefined} value
 * @return {!proto.pomerium.dashboard.DeviceType} returns this
 */
proto.pomerium.dashboard.DeviceType.prototype.setWebauthn = function (value) {
  return jspb.Message.setOneofWrapperField(
    this,
    6,
    proto.pomerium.dashboard.DeviceType.oneofGroups_[0],
    value
  );
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.DeviceType} returns this
 */
proto.pomerium.dashboard.DeviceType.prototype.clearWebauthn = function () {
  return this.setWebauthn(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.DeviceType.prototype.hasWebauthn = function () {
  return jspb.Message.getField(this, 6) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.DeviceEnrollment.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.DeviceEnrollment.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.DeviceEnrollment} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.DeviceEnrollment.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, ''),
        createdAt:
          (f = msg.getCreatedAt()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        modifiedAt:
          (f = msg.getModifiedAt()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        deletedAt:
          (f = msg.getDeletedAt()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        deviceTypeId: jspb.Message.getFieldWithDefault(msg, 5, ''),
        deviceCredentialId: jspb.Message.getFieldWithDefault(msg, 6, ''),
        userId: jspb.Message.getFieldWithDefault(msg, 7, ''),
        approvedAt:
          (f = msg.getApprovedAt()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        approvedByUserId: jspb.Message.getFieldWithDefault(msg, 9, ''),
        enrolledAt:
          (f = msg.getEnrolledAt()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        userAgent: jspb.Message.getFieldWithDefault(msg, 11, ''),
        ipAddress: jspb.Message.getFieldWithDefault(msg, 12, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.DeviceEnrollment}
 */
proto.pomerium.dashboard.DeviceEnrollment.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.DeviceEnrollment();
  return proto.pomerium.dashboard.DeviceEnrollment.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.DeviceEnrollment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.DeviceEnrollment}
 */
proto.pomerium.dashboard.DeviceEnrollment.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setId(value);
          break;
        case 2:
          var value = new google_protobuf_timestamp_pb.Timestamp();
          reader.readMessage(
            value,
            google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
          );
          msg.setCreatedAt(value);
          break;
        case 3:
          var value = new google_protobuf_timestamp_pb.Timestamp();
          reader.readMessage(
            value,
            google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
          );
          msg.setModifiedAt(value);
          break;
        case 4:
          var value = new google_protobuf_timestamp_pb.Timestamp();
          reader.readMessage(
            value,
            google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
          );
          msg.setDeletedAt(value);
          break;
        case 5:
          var value = /** @type {string} */ (reader.readString());
          msg.setDeviceTypeId(value);
          break;
        case 6:
          var value = /** @type {string} */ (reader.readString());
          msg.setDeviceCredentialId(value);
          break;
        case 7:
          var value = /** @type {string} */ (reader.readString());
          msg.setUserId(value);
          break;
        case 8:
          var value = new google_protobuf_timestamp_pb.Timestamp();
          reader.readMessage(
            value,
            google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
          );
          msg.setApprovedAt(value);
          break;
        case 9:
          var value = /** @type {string} */ (reader.readString());
          msg.setApprovedByUserId(value);
          break;
        case 10:
          var value = new google_protobuf_timestamp_pb.Timestamp();
          reader.readMessage(
            value,
            google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
          );
          msg.setEnrolledAt(value);
          break;
        case 11:
          var value = /** @type {string} */ (reader.readString());
          msg.setUserAgent(value);
          break;
        case 12:
          var value = /** @type {string} */ (reader.readString());
          msg.setIpAddress(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.DeviceEnrollment.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.DeviceEnrollment.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.DeviceEnrollment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.DeviceEnrollment.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getModifiedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeletedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeviceTypeId();
  if (f.length > 0) {
    writer.writeString(5, f);
  }
  f = message.getDeviceCredentialId();
  if (f.length > 0) {
    writer.writeString(6, f);
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(7, f);
  }
  f = message.getApprovedAt();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getApprovedByUserId();
  if (f.length > 0) {
    writer.writeString(9, f);
  }
  f = message.getEnrolledAt();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUserAgent();
  if (f.length > 0) {
    writer.writeString(11, f);
  }
  f = message.getIpAddress();
  if (f.length > 0) {
    writer.writeString(12, f);
  }
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.DeviceEnrollment.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.DeviceEnrollment} returns this
 */
proto.pomerium.dashboard.DeviceEnrollment.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional google.protobuf.Timestamp created_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pomerium.dashboard.DeviceEnrollment.prototype.getCreatedAt = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_timestamp_pb.Timestamp,
      2
    )
  );
};

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.pomerium.dashboard.DeviceEnrollment} returns this
 */
proto.pomerium.dashboard.DeviceEnrollment.prototype.setCreatedAt = function (
  value
) {
  return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.DeviceEnrollment} returns this
 */
proto.pomerium.dashboard.DeviceEnrollment.prototype.clearCreatedAt =
  function () {
    return this.setCreatedAt(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.DeviceEnrollment.prototype.hasCreatedAt = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional google.protobuf.Timestamp modified_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pomerium.dashboard.DeviceEnrollment.prototype.getModifiedAt =
  function () {
    return /** @type{?proto.google.protobuf.Timestamp} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_timestamp_pb.Timestamp,
        3
      )
    );
  };

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.pomerium.dashboard.DeviceEnrollment} returns this
 */
proto.pomerium.dashboard.DeviceEnrollment.prototype.setModifiedAt = function (
  value
) {
  return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.DeviceEnrollment} returns this
 */
proto.pomerium.dashboard.DeviceEnrollment.prototype.clearModifiedAt =
  function () {
    return this.setModifiedAt(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.DeviceEnrollment.prototype.hasModifiedAt =
  function () {
    return jspb.Message.getField(this, 3) != null;
  };

/**
 * optional google.protobuf.Timestamp deleted_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pomerium.dashboard.DeviceEnrollment.prototype.getDeletedAt = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_timestamp_pb.Timestamp,
      4
    )
  );
};

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.pomerium.dashboard.DeviceEnrollment} returns this
 */
proto.pomerium.dashboard.DeviceEnrollment.prototype.setDeletedAt = function (
  value
) {
  return jspb.Message.setWrapperField(this, 4, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.DeviceEnrollment} returns this
 */
proto.pomerium.dashboard.DeviceEnrollment.prototype.clearDeletedAt =
  function () {
    return this.setDeletedAt(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.DeviceEnrollment.prototype.hasDeletedAt = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional string device_type_id = 5;
 * @return {string}
 */
proto.pomerium.dashboard.DeviceEnrollment.prototype.getDeviceTypeId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 5, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.DeviceEnrollment} returns this
 */
proto.pomerium.dashboard.DeviceEnrollment.prototype.setDeviceTypeId = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 5, value);
};

/**
 * optional string device_credential_id = 6;
 * @return {string}
 */
proto.pomerium.dashboard.DeviceEnrollment.prototype.getDeviceCredentialId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 6, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.DeviceEnrollment} returns this
 */
proto.pomerium.dashboard.DeviceEnrollment.prototype.setDeviceCredentialId =
  function (value) {
    return jspb.Message.setProto3StringField(this, 6, value);
  };

/**
 * optional string user_id = 7;
 * @return {string}
 */
proto.pomerium.dashboard.DeviceEnrollment.prototype.getUserId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.DeviceEnrollment} returns this
 */
proto.pomerium.dashboard.DeviceEnrollment.prototype.setUserId = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 7, value);
};

/**
 * optional google.protobuf.Timestamp approved_at = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pomerium.dashboard.DeviceEnrollment.prototype.getApprovedAt =
  function () {
    return /** @type{?proto.google.protobuf.Timestamp} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_timestamp_pb.Timestamp,
        8
      )
    );
  };

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.pomerium.dashboard.DeviceEnrollment} returns this
 */
proto.pomerium.dashboard.DeviceEnrollment.prototype.setApprovedAt = function (
  value
) {
  return jspb.Message.setWrapperField(this, 8, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.DeviceEnrollment} returns this
 */
proto.pomerium.dashboard.DeviceEnrollment.prototype.clearApprovedAt =
  function () {
    return this.setApprovedAt(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.DeviceEnrollment.prototype.hasApprovedAt =
  function () {
    return jspb.Message.getField(this, 8) != null;
  };

/**
 * optional string approved_by_user_id = 9;
 * @return {string}
 */
proto.pomerium.dashboard.DeviceEnrollment.prototype.getApprovedByUserId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 9, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.DeviceEnrollment} returns this
 */
proto.pomerium.dashboard.DeviceEnrollment.prototype.setApprovedByUserId =
  function (value) {
    return jspb.Message.setProto3StringField(this, 9, value);
  };

/**
 * optional google.protobuf.Timestamp enrolled_at = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pomerium.dashboard.DeviceEnrollment.prototype.getEnrolledAt =
  function () {
    return /** @type{?proto.google.protobuf.Timestamp} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_timestamp_pb.Timestamp,
        10
      )
    );
  };

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.pomerium.dashboard.DeviceEnrollment} returns this
 */
proto.pomerium.dashboard.DeviceEnrollment.prototype.setEnrolledAt = function (
  value
) {
  return jspb.Message.setWrapperField(this, 10, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.DeviceEnrollment} returns this
 */
proto.pomerium.dashboard.DeviceEnrollment.prototype.clearEnrolledAt =
  function () {
    return this.setEnrolledAt(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.DeviceEnrollment.prototype.hasEnrolledAt =
  function () {
    return jspb.Message.getField(this, 10) != null;
  };

/**
 * optional string user_agent = 11;
 * @return {string}
 */
proto.pomerium.dashboard.DeviceEnrollment.prototype.getUserAgent = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.DeviceEnrollment} returns this
 */
proto.pomerium.dashboard.DeviceEnrollment.prototype.setUserAgent = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 11, value);
};

/**
 * optional string ip_address = 12;
 * @return {string}
 */
proto.pomerium.dashboard.DeviceEnrollment.prototype.getIpAddress = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.DeviceEnrollment} returns this
 */
proto.pomerium.dashboard.DeviceEnrollment.prototype.setIpAddress = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 12, value);
};

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.pomerium.dashboard.DeviceCredential.oneofGroups_ = [[8]];

/**
 * @enum {number}
 */
proto.pomerium.dashboard.DeviceCredential.SpecifierCase = {
  SPECIFIER_NOT_SET: 0,
  WEBAUTHN: 8
};

/**
 * @return {proto.pomerium.dashboard.DeviceCredential.SpecifierCase}
 */
proto.pomerium.dashboard.DeviceCredential.prototype.getSpecifierCase =
  function () {
    return /** @type {proto.pomerium.dashboard.DeviceCredential.SpecifierCase} */ (
      jspb.Message.computeOneofCase(
        this,
        proto.pomerium.dashboard.DeviceCredential.oneofGroups_[0]
      )
    );
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.DeviceCredential.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.DeviceCredential.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.DeviceCredential} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.DeviceCredential.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, ''),
        createdAt:
          (f = msg.getCreatedAt()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        modifiedAt:
          (f = msg.getModifiedAt()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        deletedAt:
          (f = msg.getDeletedAt()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        deviceTypeId: jspb.Message.getFieldWithDefault(msg, 5, ''),
        deviceEnrollmentId: jspb.Message.getFieldWithDefault(msg, 6, ''),
        userId: jspb.Message.getFieldWithDefault(msg, 7, ''),
        webauthn:
          (f = msg.getWebauthn()) &&
          proto.pomerium.dashboard.DeviceCredential.WebAuthn.toObject(
            includeInstance,
            f
          )
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.DeviceCredential}
 */
proto.pomerium.dashboard.DeviceCredential.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.DeviceCredential();
  return proto.pomerium.dashboard.DeviceCredential.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.DeviceCredential} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.DeviceCredential}
 */
proto.pomerium.dashboard.DeviceCredential.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setId(value);
          break;
        case 2:
          var value = new google_protobuf_timestamp_pb.Timestamp();
          reader.readMessage(
            value,
            google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
          );
          msg.setCreatedAt(value);
          break;
        case 3:
          var value = new google_protobuf_timestamp_pb.Timestamp();
          reader.readMessage(
            value,
            google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
          );
          msg.setModifiedAt(value);
          break;
        case 4:
          var value = new google_protobuf_timestamp_pb.Timestamp();
          reader.readMessage(
            value,
            google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
          );
          msg.setDeletedAt(value);
          break;
        case 5:
          var value = /** @type {string} */ (reader.readString());
          msg.setDeviceTypeId(value);
          break;
        case 6:
          var value = /** @type {string} */ (reader.readString());
          msg.setDeviceEnrollmentId(value);
          break;
        case 7:
          var value = /** @type {string} */ (reader.readString());
          msg.setUserId(value);
          break;
        case 8:
          var value = new proto.pomerium.dashboard.DeviceCredential.WebAuthn();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.DeviceCredential.WebAuthn
              .deserializeBinaryFromReader
          );
          msg.setWebauthn(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.DeviceCredential.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.DeviceCredential.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.DeviceCredential} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.DeviceCredential.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getModifiedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeletedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeviceTypeId();
  if (f.length > 0) {
    writer.writeString(5, f);
  }
  f = message.getDeviceEnrollmentId();
  if (f.length > 0) {
    writer.writeString(6, f);
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(7, f);
  }
  f = message.getWebauthn();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.pomerium.dashboard.DeviceCredential.WebAuthn.serializeBinaryToWriter
    );
  }
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pomerium.dashboard.DeviceCredential.WebAuthn.repeatedFields_ = [5];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.DeviceCredential.WebAuthn.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.DeviceCredential.WebAuthn.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.DeviceCredential.WebAuthn} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.DeviceCredential.WebAuthn.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        id: msg.getId_asB64(),
        publicKey: msg.getPublicKey_asB64(),
        registerOptions: msg.getRegisterOptions_asB64(),
        registerResponse: msg.getRegisterResponse_asB64(),
        authenticateResponseList: msg.getAuthenticateResponseList_asB64()
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.DeviceCredential.WebAuthn}
 */
proto.pomerium.dashboard.DeviceCredential.WebAuthn.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pomerium.dashboard.DeviceCredential.WebAuthn();
    return proto.pomerium.dashboard.DeviceCredential.WebAuthn.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.DeviceCredential.WebAuthn} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.DeviceCredential.WebAuthn}
 */
proto.pomerium.dashboard.DeviceCredential.WebAuthn.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {!Uint8Array} */ (reader.readBytes());
          msg.setId(value);
          break;
        case 2:
          var value = /** @type {!Uint8Array} */ (reader.readBytes());
          msg.setPublicKey(value);
          break;
        case 3:
          var value = /** @type {!Uint8Array} */ (reader.readBytes());
          msg.setRegisterOptions(value);
          break;
        case 4:
          var value = /** @type {!Uint8Array} */ (reader.readBytes());
          msg.setRegisterResponse(value);
          break;
        case 5:
          var value = /** @type {!Uint8Array} */ (reader.readBytes());
          msg.addAuthenticateResponse(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.DeviceCredential.WebAuthn.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.DeviceCredential.WebAuthn.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.DeviceCredential.WebAuthn} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.DeviceCredential.WebAuthn.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getId_asU8();
    if (f.length > 0) {
      writer.writeBytes(1, f);
    }
    f = message.getPublicKey_asU8();
    if (f.length > 0) {
      writer.writeBytes(2, f);
    }
    f = message.getRegisterOptions_asU8();
    if (f.length > 0) {
      writer.writeBytes(3, f);
    }
    f = message.getRegisterResponse_asU8();
    if (f.length > 0) {
      writer.writeBytes(4, f);
    }
    f = message.getAuthenticateResponseList_asU8();
    if (f.length > 0) {
      writer.writeRepeatedBytes(5, f);
    }
  };

/**
 * optional bytes id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.DeviceCredential.WebAuthn.prototype.getId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * optional bytes id = 1;
 * This is a type-conversion wrapper around `getId()`
 * @return {string}
 */
proto.pomerium.dashboard.DeviceCredential.WebAuthn.prototype.getId_asB64 =
  function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getId()));
  };

/**
 * optional bytes id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getId()`
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.DeviceCredential.WebAuthn.prototype.getId_asU8 =
  function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getId()));
  };

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.pomerium.dashboard.DeviceCredential.WebAuthn} returns this
 */
proto.pomerium.dashboard.DeviceCredential.WebAuthn.prototype.setId = function (
  value
) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};

/**
 * optional bytes public_key = 2;
 * @return {string}
 */
proto.pomerium.dashboard.DeviceCredential.WebAuthn.prototype.getPublicKey =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 2, '')
    );
  };

/**
 * optional bytes public_key = 2;
 * This is a type-conversion wrapper around `getPublicKey()`
 * @return {string}
 */
proto.pomerium.dashboard.DeviceCredential.WebAuthn.prototype.getPublicKey_asB64 =
  function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getPublicKey()));
  };

/**
 * optional bytes public_key = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPublicKey()`
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.DeviceCredential.WebAuthn.prototype.getPublicKey_asU8 =
  function () {
    return /** @type {!Uint8Array} */ (
      jspb.Message.bytesAsU8(this.getPublicKey())
    );
  };

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.pomerium.dashboard.DeviceCredential.WebAuthn} returns this
 */
proto.pomerium.dashboard.DeviceCredential.WebAuthn.prototype.setPublicKey =
  function (value) {
    return jspb.Message.setProto3BytesField(this, 2, value);
  };

/**
 * optional bytes register_options = 3;
 * @return {string}
 */
proto.pomerium.dashboard.DeviceCredential.WebAuthn.prototype.getRegisterOptions =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 3, '')
    );
  };

/**
 * optional bytes register_options = 3;
 * This is a type-conversion wrapper around `getRegisterOptions()`
 * @return {string}
 */
proto.pomerium.dashboard.DeviceCredential.WebAuthn.prototype.getRegisterOptions_asB64 =
  function () {
    return /** @type {string} */ (
      jspb.Message.bytesAsB64(this.getRegisterOptions())
    );
  };

/**
 * optional bytes register_options = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRegisterOptions()`
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.DeviceCredential.WebAuthn.prototype.getRegisterOptions_asU8 =
  function () {
    return /** @type {!Uint8Array} */ (
      jspb.Message.bytesAsU8(this.getRegisterOptions())
    );
  };

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.pomerium.dashboard.DeviceCredential.WebAuthn} returns this
 */
proto.pomerium.dashboard.DeviceCredential.WebAuthn.prototype.setRegisterOptions =
  function (value) {
    return jspb.Message.setProto3BytesField(this, 3, value);
  };

/**
 * optional bytes register_response = 4;
 * @return {string}
 */
proto.pomerium.dashboard.DeviceCredential.WebAuthn.prototype.getRegisterResponse =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 4, '')
    );
  };

/**
 * optional bytes register_response = 4;
 * This is a type-conversion wrapper around `getRegisterResponse()`
 * @return {string}
 */
proto.pomerium.dashboard.DeviceCredential.WebAuthn.prototype.getRegisterResponse_asB64 =
  function () {
    return /** @type {string} */ (
      jspb.Message.bytesAsB64(this.getRegisterResponse())
    );
  };

/**
 * optional bytes register_response = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRegisterResponse()`
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.DeviceCredential.WebAuthn.prototype.getRegisterResponse_asU8 =
  function () {
    return /** @type {!Uint8Array} */ (
      jspb.Message.bytesAsU8(this.getRegisterResponse())
    );
  };

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.pomerium.dashboard.DeviceCredential.WebAuthn} returns this
 */
proto.pomerium.dashboard.DeviceCredential.WebAuthn.prototype.setRegisterResponse =
  function (value) {
    return jspb.Message.setProto3BytesField(this, 4, value);
  };

/**
 * repeated bytes authenticate_response = 5;
 * @return {!Array<string>}
 */
proto.pomerium.dashboard.DeviceCredential.WebAuthn.prototype.getAuthenticateResponseList =
  function () {
    return /** @type {!Array<string>} */ (
      jspb.Message.getRepeatedField(this, 5)
    );
  };

/**
 * repeated bytes authenticate_response = 5;
 * This is a type-conversion wrapper around `getAuthenticateResponseList()`
 * @return {!Array<string>}
 */
proto.pomerium.dashboard.DeviceCredential.WebAuthn.prototype.getAuthenticateResponseList_asB64 =
  function () {
    return /** @type {!Array<string>} */ (
      jspb.Message.bytesListAsB64(this.getAuthenticateResponseList())
    );
  };

/**
 * repeated bytes authenticate_response = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAuthenticateResponseList()`
 * @return {!Array<!Uint8Array>}
 */
proto.pomerium.dashboard.DeviceCredential.WebAuthn.prototype.getAuthenticateResponseList_asU8 =
  function () {
    return /** @type {!Array<!Uint8Array>} */ (
      jspb.Message.bytesListAsU8(this.getAuthenticateResponseList())
    );
  };

/**
 * @param {!(Array<!Uint8Array>|Array<string>)} value
 * @return {!proto.pomerium.dashboard.DeviceCredential.WebAuthn} returns this
 */
proto.pomerium.dashboard.DeviceCredential.WebAuthn.prototype.setAuthenticateResponseList =
  function (value) {
    return jspb.Message.setField(this, 5, value || []);
  };

/**
 * @param {!(string|Uint8Array)} value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.DeviceCredential.WebAuthn} returns this
 */
proto.pomerium.dashboard.DeviceCredential.WebAuthn.prototype.addAuthenticateResponse =
  function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
  };

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.DeviceCredential.WebAuthn} returns this
 */
proto.pomerium.dashboard.DeviceCredential.WebAuthn.prototype.clearAuthenticateResponseList =
  function () {
    return this.setAuthenticateResponseList([]);
  };

/**
 * optional string id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.DeviceCredential.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.DeviceCredential} returns this
 */
proto.pomerium.dashboard.DeviceCredential.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional google.protobuf.Timestamp created_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pomerium.dashboard.DeviceCredential.prototype.getCreatedAt = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_timestamp_pb.Timestamp,
      2
    )
  );
};

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.pomerium.dashboard.DeviceCredential} returns this
 */
proto.pomerium.dashboard.DeviceCredential.prototype.setCreatedAt = function (
  value
) {
  return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.DeviceCredential} returns this
 */
proto.pomerium.dashboard.DeviceCredential.prototype.clearCreatedAt =
  function () {
    return this.setCreatedAt(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.DeviceCredential.prototype.hasCreatedAt = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional google.protobuf.Timestamp modified_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pomerium.dashboard.DeviceCredential.prototype.getModifiedAt =
  function () {
    return /** @type{?proto.google.protobuf.Timestamp} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_timestamp_pb.Timestamp,
        3
      )
    );
  };

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.pomerium.dashboard.DeviceCredential} returns this
 */
proto.pomerium.dashboard.DeviceCredential.prototype.setModifiedAt = function (
  value
) {
  return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.DeviceCredential} returns this
 */
proto.pomerium.dashboard.DeviceCredential.prototype.clearModifiedAt =
  function () {
    return this.setModifiedAt(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.DeviceCredential.prototype.hasModifiedAt =
  function () {
    return jspb.Message.getField(this, 3) != null;
  };

/**
 * optional google.protobuf.Timestamp deleted_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pomerium.dashboard.DeviceCredential.prototype.getDeletedAt = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_timestamp_pb.Timestamp,
      4
    )
  );
};

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.pomerium.dashboard.DeviceCredential} returns this
 */
proto.pomerium.dashboard.DeviceCredential.prototype.setDeletedAt = function (
  value
) {
  return jspb.Message.setWrapperField(this, 4, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.DeviceCredential} returns this
 */
proto.pomerium.dashboard.DeviceCredential.prototype.clearDeletedAt =
  function () {
    return this.setDeletedAt(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.DeviceCredential.prototype.hasDeletedAt = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional string device_type_id = 5;
 * @return {string}
 */
proto.pomerium.dashboard.DeviceCredential.prototype.getDeviceTypeId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 5, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.DeviceCredential} returns this
 */
proto.pomerium.dashboard.DeviceCredential.prototype.setDeviceTypeId = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 5, value);
};

/**
 * optional string device_enrollment_id = 6;
 * @return {string}
 */
proto.pomerium.dashboard.DeviceCredential.prototype.getDeviceEnrollmentId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 6, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.DeviceCredential} returns this
 */
proto.pomerium.dashboard.DeviceCredential.prototype.setDeviceEnrollmentId =
  function (value) {
    return jspb.Message.setProto3StringField(this, 6, value);
  };

/**
 * optional string user_id = 7;
 * @return {string}
 */
proto.pomerium.dashboard.DeviceCredential.prototype.getUserId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.DeviceCredential} returns this
 */
proto.pomerium.dashboard.DeviceCredential.prototype.setUserId = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 7, value);
};

/**
 * optional WebAuthn webauthn = 8;
 * @return {?proto.pomerium.dashboard.DeviceCredential.WebAuthn}
 */
proto.pomerium.dashboard.DeviceCredential.prototype.getWebauthn = function () {
  return /** @type{?proto.pomerium.dashboard.DeviceCredential.WebAuthn} */ (
    jspb.Message.getWrapperField(
      this,
      proto.pomerium.dashboard.DeviceCredential.WebAuthn,
      8
    )
  );
};

/**
 * @param {?proto.pomerium.dashboard.DeviceCredential.WebAuthn|undefined} value
 * @return {!proto.pomerium.dashboard.DeviceCredential} returns this
 */
proto.pomerium.dashboard.DeviceCredential.prototype.setWebauthn = function (
  value
) {
  return jspb.Message.setOneofWrapperField(
    this,
    8,
    proto.pomerium.dashboard.DeviceCredential.oneofGroups_[0],
    value
  );
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.DeviceCredential} returns this
 */
proto.pomerium.dashboard.DeviceCredential.prototype.clearWebauthn =
  function () {
    return this.setWebauthn(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.DeviceCredential.prototype.hasWebauthn = function () {
  return jspb.Message.getField(this, 8) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.DeviceOwnerCredentialRecord.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.DeviceOwnerCredentialRecord.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.DeviceOwnerCredentialRecord} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.DeviceOwnerCredentialRecord.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        id: msg.getId_asB64(),
        ownerId: msg.getOwnerId_asB64(),
        publicKey: msg.getPublicKey_asB64()
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.DeviceOwnerCredentialRecord}
 */
proto.pomerium.dashboard.DeviceOwnerCredentialRecord.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pomerium.dashboard.DeviceOwnerCredentialRecord();
    return proto.pomerium.dashboard.DeviceOwnerCredentialRecord.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.DeviceOwnerCredentialRecord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.DeviceOwnerCredentialRecord}
 */
proto.pomerium.dashboard.DeviceOwnerCredentialRecord.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {!Uint8Array} */ (reader.readBytes());
          msg.setId(value);
          break;
        case 2:
          var value = /** @type {!Uint8Array} */ (reader.readBytes());
          msg.setOwnerId(value);
          break;
        case 3:
          var value = /** @type {!Uint8Array} */ (reader.readBytes());
          msg.setPublicKey(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.DeviceOwnerCredentialRecord.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.DeviceOwnerCredentialRecord.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.DeviceOwnerCredentialRecord} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.DeviceOwnerCredentialRecord.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getId_asU8();
    if (f.length > 0) {
      writer.writeBytes(1, f);
    }
    f = message.getOwnerId_asU8();
    if (f.length > 0) {
      writer.writeBytes(2, f);
    }
    f = message.getPublicKey_asU8();
    if (f.length > 0) {
      writer.writeBytes(3, f);
    }
  };

/**
 * optional bytes id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.DeviceOwnerCredentialRecord.prototype.getId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * optional bytes id = 1;
 * This is a type-conversion wrapper around `getId()`
 * @return {string}
 */
proto.pomerium.dashboard.DeviceOwnerCredentialRecord.prototype.getId_asB64 =
  function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getId()));
  };

/**
 * optional bytes id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getId()`
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.DeviceOwnerCredentialRecord.prototype.getId_asU8 =
  function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getId()));
  };

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.pomerium.dashboard.DeviceOwnerCredentialRecord} returns this
 */
proto.pomerium.dashboard.DeviceOwnerCredentialRecord.prototype.setId =
  function (value) {
    return jspb.Message.setProto3BytesField(this, 1, value);
  };

/**
 * optional bytes owner_id = 2;
 * @return {string}
 */
proto.pomerium.dashboard.DeviceOwnerCredentialRecord.prototype.getOwnerId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 2, '')
    );
  };

/**
 * optional bytes owner_id = 2;
 * This is a type-conversion wrapper around `getOwnerId()`
 * @return {string}
 */
proto.pomerium.dashboard.DeviceOwnerCredentialRecord.prototype.getOwnerId_asB64 =
  function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getOwnerId()));
  };

/**
 * optional bytes owner_id = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOwnerId()`
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.DeviceOwnerCredentialRecord.prototype.getOwnerId_asU8 =
  function () {
    return /** @type {!Uint8Array} */ (
      jspb.Message.bytesAsU8(this.getOwnerId())
    );
  };

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.pomerium.dashboard.DeviceOwnerCredentialRecord} returns this
 */
proto.pomerium.dashboard.DeviceOwnerCredentialRecord.prototype.setOwnerId =
  function (value) {
    return jspb.Message.setProto3BytesField(this, 2, value);
  };

/**
 * optional bytes public_key = 3;
 * @return {string}
 */
proto.pomerium.dashboard.DeviceOwnerCredentialRecord.prototype.getPublicKey =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 3, '')
    );
  };

/**
 * optional bytes public_key = 3;
 * This is a type-conversion wrapper around `getPublicKey()`
 * @return {string}
 */
proto.pomerium.dashboard.DeviceOwnerCredentialRecord.prototype.getPublicKey_asB64 =
  function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getPublicKey()));
  };

/**
 * optional bytes public_key = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPublicKey()`
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.DeviceOwnerCredentialRecord.prototype.getPublicKey_asU8 =
  function () {
    return /** @type {!Uint8Array} */ (
      jspb.Message.bytesAsU8(this.getPublicKey())
    );
  };

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.pomerium.dashboard.DeviceOwnerCredentialRecord} returns this
 */
proto.pomerium.dashboard.DeviceOwnerCredentialRecord.prototype.setPublicKey =
  function (value) {
    return jspb.Message.setProto3BytesField(this, 3, value);
  };

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.pomerium.dashboard.ApproveDeviceRequest.oneofGroups_ = [[1, 2]];

/**
 * @enum {number}
 */
proto.pomerium.dashboard.ApproveDeviceRequest.IdCase = {
  ID_NOT_SET: 0,
  CREDENTIAL_ID: 1,
  ENROLLMENT_ID: 2
};

/**
 * @return {proto.pomerium.dashboard.ApproveDeviceRequest.IdCase}
 */
proto.pomerium.dashboard.ApproveDeviceRequest.prototype.getIdCase =
  function () {
    return /** @type {proto.pomerium.dashboard.ApproveDeviceRequest.IdCase} */ (
      jspb.Message.computeOneofCase(
        this,
        proto.pomerium.dashboard.ApproveDeviceRequest.oneofGroups_[0]
      )
    );
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.ApproveDeviceRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.ApproveDeviceRequest.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.ApproveDeviceRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.ApproveDeviceRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        credentialId: jspb.Message.getFieldWithDefault(msg, 1, ''),
        enrollmentId: jspb.Message.getFieldWithDefault(msg, 2, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.ApproveDeviceRequest}
 */
proto.pomerium.dashboard.ApproveDeviceRequest.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.ApproveDeviceRequest();
  return proto.pomerium.dashboard.ApproveDeviceRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.ApproveDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.ApproveDeviceRequest}
 */
proto.pomerium.dashboard.ApproveDeviceRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setCredentialId(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setEnrollmentId(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.ApproveDeviceRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.ApproveDeviceRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.ApproveDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.ApproveDeviceRequest.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = /** @type {string} */ (jspb.Message.getField(message, 1));
    if (f != null) {
      writer.writeString(1, f);
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 2));
    if (f != null) {
      writer.writeString(2, f);
    }
  };

/**
 * optional string credential_id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.ApproveDeviceRequest.prototype.getCredentialId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ApproveDeviceRequest} returns this
 */
proto.pomerium.dashboard.ApproveDeviceRequest.prototype.setCredentialId =
  function (value) {
    return jspb.Message.setOneofField(
      this,
      1,
      proto.pomerium.dashboard.ApproveDeviceRequest.oneofGroups_[0],
      value
    );
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.ApproveDeviceRequest} returns this
 */
proto.pomerium.dashboard.ApproveDeviceRequest.prototype.clearCredentialId =
  function () {
    return jspb.Message.setOneofField(
      this,
      1,
      proto.pomerium.dashboard.ApproveDeviceRequest.oneofGroups_[0],
      undefined
    );
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.ApproveDeviceRequest.prototype.hasCredentialId =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

/**
 * optional string enrollment_id = 2;
 * @return {string}
 */
proto.pomerium.dashboard.ApproveDeviceRequest.prototype.getEnrollmentId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 2, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ApproveDeviceRequest} returns this
 */
proto.pomerium.dashboard.ApproveDeviceRequest.prototype.setEnrollmentId =
  function (value) {
    return jspb.Message.setOneofField(
      this,
      2,
      proto.pomerium.dashboard.ApproveDeviceRequest.oneofGroups_[0],
      value
    );
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.ApproveDeviceRequest} returns this
 */
proto.pomerium.dashboard.ApproveDeviceRequest.prototype.clearEnrollmentId =
  function () {
    return jspb.Message.setOneofField(
      this,
      2,
      proto.pomerium.dashboard.ApproveDeviceRequest.oneofGroups_[0],
      undefined
    );
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.ApproveDeviceRequest.prototype.hasEnrollmentId =
  function () {
    return jspb.Message.getField(this, 2) != null;
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.CreateDeviceEnrollmentRequest.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.CreateDeviceEnrollmentRequest.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.CreateDeviceEnrollmentRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.CreateDeviceEnrollmentRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        enrollment:
          (f = msg.getEnrollment()) &&
          proto.pomerium.dashboard.DeviceEnrollment.toObject(
            includeInstance,
            f
          ),
        redirectUrl: jspb.Message.getFieldWithDefault(msg, 2, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.CreateDeviceEnrollmentRequest}
 */
proto.pomerium.dashboard.CreateDeviceEnrollmentRequest.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pomerium.dashboard.CreateDeviceEnrollmentRequest();
    return proto.pomerium.dashboard.CreateDeviceEnrollmentRequest.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.CreateDeviceEnrollmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.CreateDeviceEnrollmentRequest}
 */
proto.pomerium.dashboard.CreateDeviceEnrollmentRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.DeviceEnrollment();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.DeviceEnrollment
              .deserializeBinaryFromReader
          );
          msg.setEnrollment(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setRedirectUrl(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.CreateDeviceEnrollmentRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.CreateDeviceEnrollmentRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.CreateDeviceEnrollmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.CreateDeviceEnrollmentRequest.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getEnrollment();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        proto.pomerium.dashboard.DeviceEnrollment.serializeBinaryToWriter
      );
    }
    f = message.getRedirectUrl();
    if (f.length > 0) {
      writer.writeString(2, f);
    }
  };

/**
 * optional DeviceEnrollment enrollment = 1;
 * @return {?proto.pomerium.dashboard.DeviceEnrollment}
 */
proto.pomerium.dashboard.CreateDeviceEnrollmentRequest.prototype.getEnrollment =
  function () {
    return /** @type{?proto.pomerium.dashboard.DeviceEnrollment} */ (
      jspb.Message.getWrapperField(
        this,
        proto.pomerium.dashboard.DeviceEnrollment,
        1
      )
    );
  };

/**
 * @param {?proto.pomerium.dashboard.DeviceEnrollment|undefined} value
 * @return {!proto.pomerium.dashboard.CreateDeviceEnrollmentRequest} returns this
 */
proto.pomerium.dashboard.CreateDeviceEnrollmentRequest.prototype.setEnrollment =
  function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.CreateDeviceEnrollmentRequest} returns this
 */
proto.pomerium.dashboard.CreateDeviceEnrollmentRequest.prototype.clearEnrollment =
  function () {
    return this.setEnrollment(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.CreateDeviceEnrollmentRequest.prototype.hasEnrollment =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

/**
 * optional string redirect_url = 2;
 * @return {string}
 */
proto.pomerium.dashboard.CreateDeviceEnrollmentRequest.prototype.getRedirectUrl =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 2, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.CreateDeviceEnrollmentRequest} returns this
 */
proto.pomerium.dashboard.CreateDeviceEnrollmentRequest.prototype.setRedirectUrl =
  function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.CreateDeviceEnrollmentResponse.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.CreateDeviceEnrollmentResponse.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.CreateDeviceEnrollmentResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.CreateDeviceEnrollmentResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        enrollment:
          (f = msg.getEnrollment()) &&
          proto.pomerium.dashboard.DeviceEnrollment.toObject(
            includeInstance,
            f
          ),
        enrollmentUrl: jspb.Message.getFieldWithDefault(msg, 2, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.CreateDeviceEnrollmentResponse}
 */
proto.pomerium.dashboard.CreateDeviceEnrollmentResponse.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pomerium.dashboard.CreateDeviceEnrollmentResponse();
    return proto.pomerium.dashboard.CreateDeviceEnrollmentResponse.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.CreateDeviceEnrollmentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.CreateDeviceEnrollmentResponse}
 */
proto.pomerium.dashboard.CreateDeviceEnrollmentResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.DeviceEnrollment();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.DeviceEnrollment
              .deserializeBinaryFromReader
          );
          msg.setEnrollment(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setEnrollmentUrl(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.CreateDeviceEnrollmentResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.CreateDeviceEnrollmentResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.CreateDeviceEnrollmentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.CreateDeviceEnrollmentResponse.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getEnrollment();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        proto.pomerium.dashboard.DeviceEnrollment.serializeBinaryToWriter
      );
    }
    f = message.getEnrollmentUrl();
    if (f.length > 0) {
      writer.writeString(2, f);
    }
  };

/**
 * optional DeviceEnrollment enrollment = 1;
 * @return {?proto.pomerium.dashboard.DeviceEnrollment}
 */
proto.pomerium.dashboard.CreateDeviceEnrollmentResponse.prototype.getEnrollment =
  function () {
    return /** @type{?proto.pomerium.dashboard.DeviceEnrollment} */ (
      jspb.Message.getWrapperField(
        this,
        proto.pomerium.dashboard.DeviceEnrollment,
        1
      )
    );
  };

/**
 * @param {?proto.pomerium.dashboard.DeviceEnrollment|undefined} value
 * @return {!proto.pomerium.dashboard.CreateDeviceEnrollmentResponse} returns this
 */
proto.pomerium.dashboard.CreateDeviceEnrollmentResponse.prototype.setEnrollment =
  function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.CreateDeviceEnrollmentResponse} returns this
 */
proto.pomerium.dashboard.CreateDeviceEnrollmentResponse.prototype.clearEnrollment =
  function () {
    return this.setEnrollment(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.CreateDeviceEnrollmentResponse.prototype.hasEnrollment =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

/**
 * optional string enrollment_url = 2;
 * @return {string}
 */
proto.pomerium.dashboard.CreateDeviceEnrollmentResponse.prototype.getEnrollmentUrl =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 2, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.CreateDeviceEnrollmentResponse} returns this
 */
proto.pomerium.dashboard.CreateDeviceEnrollmentResponse.prototype.setEnrollmentUrl =
  function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
  };

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.pomerium.dashboard.DeleteDeviceRequest.oneofGroups_ = [[1, 2]];

/**
 * @enum {number}
 */
proto.pomerium.dashboard.DeleteDeviceRequest.IdCase = {
  ID_NOT_SET: 0,
  CREDENTIAL_ID: 1,
  ENROLLMENT_ID: 2
};

/**
 * @return {proto.pomerium.dashboard.DeleteDeviceRequest.IdCase}
 */
proto.pomerium.dashboard.DeleteDeviceRequest.prototype.getIdCase = function () {
  return /** @type {proto.pomerium.dashboard.DeleteDeviceRequest.IdCase} */ (
    jspb.Message.computeOneofCase(
      this,
      proto.pomerium.dashboard.DeleteDeviceRequest.oneofGroups_[0]
    )
  );
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.DeleteDeviceRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.DeleteDeviceRequest.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.DeleteDeviceRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.DeleteDeviceRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        credentialId: jspb.Message.getFieldWithDefault(msg, 1, ''),
        enrollmentId: jspb.Message.getFieldWithDefault(msg, 2, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.DeleteDeviceRequest}
 */
proto.pomerium.dashboard.DeleteDeviceRequest.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.DeleteDeviceRequest();
  return proto.pomerium.dashboard.DeleteDeviceRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.DeleteDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.DeleteDeviceRequest}
 */
proto.pomerium.dashboard.DeleteDeviceRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setCredentialId(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setEnrollmentId(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.DeleteDeviceRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.DeleteDeviceRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.DeleteDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.DeleteDeviceRequest.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = /** @type {string} */ (jspb.Message.getField(message, 1));
    if (f != null) {
      writer.writeString(1, f);
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 2));
    if (f != null) {
      writer.writeString(2, f);
    }
  };

/**
 * optional string credential_id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.DeleteDeviceRequest.prototype.getCredentialId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.DeleteDeviceRequest} returns this
 */
proto.pomerium.dashboard.DeleteDeviceRequest.prototype.setCredentialId =
  function (value) {
    return jspb.Message.setOneofField(
      this,
      1,
      proto.pomerium.dashboard.DeleteDeviceRequest.oneofGroups_[0],
      value
    );
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.DeleteDeviceRequest} returns this
 */
proto.pomerium.dashboard.DeleteDeviceRequest.prototype.clearCredentialId =
  function () {
    return jspb.Message.setOneofField(
      this,
      1,
      proto.pomerium.dashboard.DeleteDeviceRequest.oneofGroups_[0],
      undefined
    );
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.DeleteDeviceRequest.prototype.hasCredentialId =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

/**
 * optional string enrollment_id = 2;
 * @return {string}
 */
proto.pomerium.dashboard.DeleteDeviceRequest.prototype.getEnrollmentId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 2, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.DeleteDeviceRequest} returns this
 */
proto.pomerium.dashboard.DeleteDeviceRequest.prototype.setEnrollmentId =
  function (value) {
    return jspb.Message.setOneofField(
      this,
      2,
      proto.pomerium.dashboard.DeleteDeviceRequest.oneofGroups_[0],
      value
    );
  };

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.DeleteDeviceRequest} returns this
 */
proto.pomerium.dashboard.DeleteDeviceRequest.prototype.clearEnrollmentId =
  function () {
    return jspb.Message.setOneofField(
      this,
      2,
      proto.pomerium.dashboard.DeleteDeviceRequest.oneofGroups_[0],
      undefined
    );
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.DeleteDeviceRequest.prototype.hasEnrollmentId =
  function () {
    return jspb.Message.getField(this, 2) != null;
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.DeleteDeviceTypeRequest.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.DeleteDeviceTypeRequest.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.DeleteDeviceTypeRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.DeleteDeviceTypeRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        typeId: jspb.Message.getFieldWithDefault(msg, 1, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.DeleteDeviceTypeRequest}
 */
proto.pomerium.dashboard.DeleteDeviceTypeRequest.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.DeleteDeviceTypeRequest();
  return proto.pomerium.dashboard.DeleteDeviceTypeRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.DeleteDeviceTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.DeleteDeviceTypeRequest}
 */
proto.pomerium.dashboard.DeleteDeviceTypeRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setTypeId(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.DeleteDeviceTypeRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.DeleteDeviceTypeRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.DeleteDeviceTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.DeleteDeviceTypeRequest.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getTypeId();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
  };

/**
 * optional string type_id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.DeleteDeviceTypeRequest.prototype.getTypeId =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.DeleteDeviceTypeRequest} returns this
 */
proto.pomerium.dashboard.DeleteDeviceTypeRequest.prototype.setTypeId =
  function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.ListDevicesRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.ListDevicesRequest.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.ListDevicesRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.ListDevicesRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        typeId: jspb.Message.getFieldWithDefault(msg, 1, ''),
        userId: jspb.Message.getFieldWithDefault(msg, 2, ''),
        approvedBy: jspb.Message.getFieldWithDefault(msg, 3, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.ListDevicesRequest}
 */
proto.pomerium.dashboard.ListDevicesRequest.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.ListDevicesRequest();
  return proto.pomerium.dashboard.ListDevicesRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.ListDevicesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.ListDevicesRequest}
 */
proto.pomerium.dashboard.ListDevicesRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setTypeId(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setUserId(value);
          break;
        case 3:
          var value = /** @type {string} */ (reader.readString());
          msg.setApprovedBy(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.ListDevicesRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.ListDevicesRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.ListDevicesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.ListDevicesRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(1, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(2, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(3, f);
  }
};

/**
 * optional string type_id = 1;
 * @return {string}
 */
proto.pomerium.dashboard.ListDevicesRequest.prototype.getTypeId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ListDevicesRequest} returns this
 */
proto.pomerium.dashboard.ListDevicesRequest.prototype.setTypeId = function (
  value
) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.ListDevicesRequest} returns this
 */
proto.pomerium.dashboard.ListDevicesRequest.prototype.clearTypeId =
  function () {
    return jspb.Message.setField(this, 1, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.ListDevicesRequest.prototype.hasTypeId = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.pomerium.dashboard.ListDevicesRequest.prototype.getUserId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ListDevicesRequest} returns this
 */
proto.pomerium.dashboard.ListDevicesRequest.prototype.setUserId = function (
  value
) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.ListDevicesRequest} returns this
 */
proto.pomerium.dashboard.ListDevicesRequest.prototype.clearUserId =
  function () {
    return jspb.Message.setField(this, 2, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.ListDevicesRequest.prototype.hasUserId = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional string approved_by = 3;
 * @return {string}
 */
proto.pomerium.dashboard.ListDevicesRequest.prototype.getApprovedBy =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 3, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ListDevicesRequest} returns this
 */
proto.pomerium.dashboard.ListDevicesRequest.prototype.setApprovedBy = function (
  value
) {
  return jspb.Message.setField(this, 3, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.pomerium.dashboard.ListDevicesRequest} returns this
 */
proto.pomerium.dashboard.ListDevicesRequest.prototype.clearApprovedBy =
  function () {
    return jspb.Message.setField(this, 3, undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.ListDevicesRequest.prototype.hasApprovedBy =
  function () {
    return jspb.Message.getField(this, 3) != null;
  };

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pomerium.dashboard.ListDevicesResponse.repeatedFields_ = [4];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.ListDevicesResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.ListDevicesResponse.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.ListDevicesResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.ListDevicesResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        devicesList: jspb.Message.toObjectList(
          msg.getDevicesList(),
          proto.pomerium.dashboard.ListDevicesResponse.Device.toObject,
          includeInstance
        )
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.ListDevicesResponse}
 */
proto.pomerium.dashboard.ListDevicesResponse.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.ListDevicesResponse();
  return proto.pomerium.dashboard.ListDevicesResponse.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.ListDevicesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.ListDevicesResponse}
 */
proto.pomerium.dashboard.ListDevicesResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 4:
          var value = new proto.pomerium.dashboard.ListDevicesResponse.Device();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.ListDevicesResponse.Device
              .deserializeBinaryFromReader
          );
          msg.addDevices(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.ListDevicesResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.ListDevicesResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.ListDevicesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.ListDevicesResponse.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getDevicesList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        4,
        f,
        proto.pomerium.dashboard.ListDevicesResponse.Device
          .serializeBinaryToWriter
      );
    }
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.ListDevicesResponse.Device.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.ListDevicesResponse.Device.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.ListDevicesResponse.Device} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.ListDevicesResponse.Device.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        type:
          (f = msg.getType()) &&
          proto.pomerium.dashboard.DeviceType.toObject(includeInstance, f),
        credential:
          (f = msg.getCredential()) &&
          proto.pomerium.dashboard.DeviceCredential.toObject(
            includeInstance,
            f
          ),
        enrollment:
          (f = msg.getEnrollment()) &&
          proto.pomerium.dashboard.DeviceEnrollment.toObject(
            includeInstance,
            f
          ),
        kind: jspb.Message.getFieldWithDefault(msg, 4, 0),
        userName: jspb.Message.getFieldWithDefault(msg, 5, ''),
        approvedByUserName: jspb.Message.getFieldWithDefault(msg, 6, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.ListDevicesResponse.Device}
 */
proto.pomerium.dashboard.ListDevicesResponse.Device.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pomerium.dashboard.ListDevicesResponse.Device();
    return proto.pomerium.dashboard.ListDevicesResponse.Device.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.ListDevicesResponse.Device} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.ListDevicesResponse.Device}
 */
proto.pomerium.dashboard.ListDevicesResponse.Device.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.DeviceType();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.DeviceType.deserializeBinaryFromReader
          );
          msg.setType(value);
          break;
        case 2:
          var value = new proto.pomerium.dashboard.DeviceCredential();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.DeviceCredential
              .deserializeBinaryFromReader
          );
          msg.setCredential(value);
          break;
        case 3:
          var value = new proto.pomerium.dashboard.DeviceEnrollment();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.DeviceEnrollment
              .deserializeBinaryFromReader
          );
          msg.setEnrollment(value);
          break;
        case 4:
          var value = /** @type {!proto.pomerium.dashboard.DeviceKind} */ (
            reader.readEnum()
          );
          msg.setKind(value);
          break;
        case 5:
          var value = /** @type {string} */ (reader.readString());
          msg.setUserName(value);
          break;
        case 6:
          var value = /** @type {string} */ (reader.readString());
          msg.setApprovedByUserName(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.ListDevicesResponse.Device.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.ListDevicesResponse.Device.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.ListDevicesResponse.Device} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.ListDevicesResponse.Device.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getType();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        proto.pomerium.dashboard.DeviceType.serializeBinaryToWriter
      );
    }
    f = message.getCredential();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        proto.pomerium.dashboard.DeviceCredential.serializeBinaryToWriter
      );
    }
    f = message.getEnrollment();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        proto.pomerium.dashboard.DeviceEnrollment.serializeBinaryToWriter
      );
    }
    f = message.getKind();
    if (f !== 0.0) {
      writer.writeEnum(4, f);
    }
    f = message.getUserName();
    if (f.length > 0) {
      writer.writeString(5, f);
    }
    f = message.getApprovedByUserName();
    if (f.length > 0) {
      writer.writeString(6, f);
    }
  };

/**
 * optional DeviceType type = 1;
 * @return {?proto.pomerium.dashboard.DeviceType}
 */
proto.pomerium.dashboard.ListDevicesResponse.Device.prototype.getType =
  function () {
    return /** @type{?proto.pomerium.dashboard.DeviceType} */ (
      jspb.Message.getWrapperField(this, proto.pomerium.dashboard.DeviceType, 1)
    );
  };

/**
 * @param {?proto.pomerium.dashboard.DeviceType|undefined} value
 * @return {!proto.pomerium.dashboard.ListDevicesResponse.Device} returns this
 */
proto.pomerium.dashboard.ListDevicesResponse.Device.prototype.setType =
  function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.ListDevicesResponse.Device} returns this
 */
proto.pomerium.dashboard.ListDevicesResponse.Device.prototype.clearType =
  function () {
    return this.setType(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.ListDevicesResponse.Device.prototype.hasType =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

/**
 * optional DeviceCredential credential = 2;
 * @return {?proto.pomerium.dashboard.DeviceCredential}
 */
proto.pomerium.dashboard.ListDevicesResponse.Device.prototype.getCredential =
  function () {
    return /** @type{?proto.pomerium.dashboard.DeviceCredential} */ (
      jspb.Message.getWrapperField(
        this,
        proto.pomerium.dashboard.DeviceCredential,
        2
      )
    );
  };

/**
 * @param {?proto.pomerium.dashboard.DeviceCredential|undefined} value
 * @return {!proto.pomerium.dashboard.ListDevicesResponse.Device} returns this
 */
proto.pomerium.dashboard.ListDevicesResponse.Device.prototype.setCredential =
  function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.ListDevicesResponse.Device} returns this
 */
proto.pomerium.dashboard.ListDevicesResponse.Device.prototype.clearCredential =
  function () {
    return this.setCredential(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.ListDevicesResponse.Device.prototype.hasCredential =
  function () {
    return jspb.Message.getField(this, 2) != null;
  };

/**
 * optional DeviceEnrollment enrollment = 3;
 * @return {?proto.pomerium.dashboard.DeviceEnrollment}
 */
proto.pomerium.dashboard.ListDevicesResponse.Device.prototype.getEnrollment =
  function () {
    return /** @type{?proto.pomerium.dashboard.DeviceEnrollment} */ (
      jspb.Message.getWrapperField(
        this,
        proto.pomerium.dashboard.DeviceEnrollment,
        3
      )
    );
  };

/**
 * @param {?proto.pomerium.dashboard.DeviceEnrollment|undefined} value
 * @return {!proto.pomerium.dashboard.ListDevicesResponse.Device} returns this
 */
proto.pomerium.dashboard.ListDevicesResponse.Device.prototype.setEnrollment =
  function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.ListDevicesResponse.Device} returns this
 */
proto.pomerium.dashboard.ListDevicesResponse.Device.prototype.clearEnrollment =
  function () {
    return this.setEnrollment(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.ListDevicesResponse.Device.prototype.hasEnrollment =
  function () {
    return jspb.Message.getField(this, 3) != null;
  };

/**
 * optional DeviceKind kind = 4;
 * @return {!proto.pomerium.dashboard.DeviceKind}
 */
proto.pomerium.dashboard.ListDevicesResponse.Device.prototype.getKind =
  function () {
    return /** @type {!proto.pomerium.dashboard.DeviceKind} */ (
      jspb.Message.getFieldWithDefault(this, 4, 0)
    );
  };

/**
 * @param {!proto.pomerium.dashboard.DeviceKind} value
 * @return {!proto.pomerium.dashboard.ListDevicesResponse.Device} returns this
 */
proto.pomerium.dashboard.ListDevicesResponse.Device.prototype.setKind =
  function (value) {
    return jspb.Message.setProto3EnumField(this, 4, value);
  };

/**
 * optional string user_name = 5;
 * @return {string}
 */
proto.pomerium.dashboard.ListDevicesResponse.Device.prototype.getUserName =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 5, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ListDevicesResponse.Device} returns this
 */
proto.pomerium.dashboard.ListDevicesResponse.Device.prototype.setUserName =
  function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
  };

/**
 * optional string approved_by_user_name = 6;
 * @return {string}
 */
proto.pomerium.dashboard.ListDevicesResponse.Device.prototype.getApprovedByUserName =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 6, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.pomerium.dashboard.ListDevicesResponse.Device} returns this
 */
proto.pomerium.dashboard.ListDevicesResponse.Device.prototype.setApprovedByUserName =
  function (value) {
    return jspb.Message.setProto3StringField(this, 6, value);
  };

/**
 * repeated Device devices = 4;
 * @return {!Array<!proto.pomerium.dashboard.ListDevicesResponse.Device>}
 */
proto.pomerium.dashboard.ListDevicesResponse.prototype.getDevicesList =
  function () {
    return /** @type{!Array<!proto.pomerium.dashboard.ListDevicesResponse.Device>} */ (
      jspb.Message.getRepeatedWrapperField(
        this,
        proto.pomerium.dashboard.ListDevicesResponse.Device,
        4
      )
    );
  };

/**
 * @param {!Array<!proto.pomerium.dashboard.ListDevicesResponse.Device>} value
 * @return {!proto.pomerium.dashboard.ListDevicesResponse} returns this
 */
proto.pomerium.dashboard.ListDevicesResponse.prototype.setDevicesList =
  function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 4, value);
  };

/**
 * @param {!proto.pomerium.dashboard.ListDevicesResponse.Device=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.ListDevicesResponse.Device}
 */
proto.pomerium.dashboard.ListDevicesResponse.prototype.addDevices = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    4,
    opt_value,
    proto.pomerium.dashboard.ListDevicesResponse.Device,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.ListDevicesResponse} returns this
 */
proto.pomerium.dashboard.ListDevicesResponse.prototype.clearDevicesList =
  function () {
    return this.setDevicesList([]);
  };

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pomerium.dashboard.ListDeviceTypesResponse.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.ListDeviceTypesResponse.prototype.toObject =
    function (opt_includeInstance) {
      return proto.pomerium.dashboard.ListDeviceTypesResponse.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.ListDeviceTypesResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.ListDeviceTypesResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        typesList: jspb.Message.toObjectList(
          msg.getTypesList(),
          proto.pomerium.dashboard.DeviceType.toObject,
          includeInstance
        )
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.ListDeviceTypesResponse}
 */
proto.pomerium.dashboard.ListDeviceTypesResponse.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.ListDeviceTypesResponse();
  return proto.pomerium.dashboard.ListDeviceTypesResponse.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.ListDeviceTypesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.ListDeviceTypesResponse}
 */
proto.pomerium.dashboard.ListDeviceTypesResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.DeviceType();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.DeviceType.deserializeBinaryFromReader
          );
          msg.addTypes(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.ListDeviceTypesResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.ListDeviceTypesResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.ListDeviceTypesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.ListDeviceTypesResponse.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getTypesList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        proto.pomerium.dashboard.DeviceType.serializeBinaryToWriter
      );
    }
  };

/**
 * repeated DeviceType types = 1;
 * @return {!Array<!proto.pomerium.dashboard.DeviceType>}
 */
proto.pomerium.dashboard.ListDeviceTypesResponse.prototype.getTypesList =
  function () {
    return /** @type{!Array<!proto.pomerium.dashboard.DeviceType>} */ (
      jspb.Message.getRepeatedWrapperField(
        this,
        proto.pomerium.dashboard.DeviceType,
        1
      )
    );
  };

/**
 * @param {!Array<!proto.pomerium.dashboard.DeviceType>} value
 * @return {!proto.pomerium.dashboard.ListDeviceTypesResponse} returns this
 */
proto.pomerium.dashboard.ListDeviceTypesResponse.prototype.setTypesList =
  function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
  };

/**
 * @param {!proto.pomerium.dashboard.DeviceType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pomerium.dashboard.DeviceType}
 */
proto.pomerium.dashboard.ListDeviceTypesResponse.prototype.addTypes = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.pomerium.dashboard.DeviceType,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pomerium.dashboard.ListDeviceTypesResponse} returns this
 */
proto.pomerium.dashboard.ListDeviceTypesResponse.prototype.clearTypesList =
  function () {
    return this.setTypesList([]);
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.SetDeviceTypeRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.SetDeviceTypeRequest.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.SetDeviceTypeRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.SetDeviceTypeRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        type:
          (f = msg.getType()) &&
          proto.pomerium.dashboard.DeviceType.toObject(includeInstance, f)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.SetDeviceTypeRequest}
 */
proto.pomerium.dashboard.SetDeviceTypeRequest.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.SetDeviceTypeRequest();
  return proto.pomerium.dashboard.SetDeviceTypeRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.SetDeviceTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.SetDeviceTypeRequest}
 */
proto.pomerium.dashboard.SetDeviceTypeRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.DeviceType();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.DeviceType.deserializeBinaryFromReader
          );
          msg.setType(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.SetDeviceTypeRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.SetDeviceTypeRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.SetDeviceTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.SetDeviceTypeRequest.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getType();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        proto.pomerium.dashboard.DeviceType.serializeBinaryToWriter
      );
    }
  };

/**
 * optional DeviceType type = 1;
 * @return {?proto.pomerium.dashboard.DeviceType}
 */
proto.pomerium.dashboard.SetDeviceTypeRequest.prototype.getType = function () {
  return /** @type{?proto.pomerium.dashboard.DeviceType} */ (
    jspb.Message.getWrapperField(this, proto.pomerium.dashboard.DeviceType, 1)
  );
};

/**
 * @param {?proto.pomerium.dashboard.DeviceType|undefined} value
 * @return {!proto.pomerium.dashboard.SetDeviceTypeRequest} returns this
 */
proto.pomerium.dashboard.SetDeviceTypeRequest.prototype.setType = function (
  value
) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.SetDeviceTypeRequest} returns this
 */
proto.pomerium.dashboard.SetDeviceTypeRequest.prototype.clearType =
  function () {
    return this.setType(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.SetDeviceTypeRequest.prototype.hasType = function () {
  return jspb.Message.getField(this, 1) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.pomerium.dashboard.SetDeviceTypeResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.pomerium.dashboard.SetDeviceTypeResponse.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.pomerium.dashboard.SetDeviceTypeResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.pomerium.dashboard.SetDeviceTypeResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        type:
          (f = msg.getType()) &&
          proto.pomerium.dashboard.DeviceType.toObject(includeInstance, f)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pomerium.dashboard.SetDeviceTypeResponse}
 */
proto.pomerium.dashboard.SetDeviceTypeResponse.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pomerium.dashboard.SetDeviceTypeResponse();
  return proto.pomerium.dashboard.SetDeviceTypeResponse.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pomerium.dashboard.SetDeviceTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pomerium.dashboard.SetDeviceTypeResponse}
 */
proto.pomerium.dashboard.SetDeviceTypeResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.pomerium.dashboard.DeviceType();
          reader.readMessage(
            value,
            proto.pomerium.dashboard.DeviceType.deserializeBinaryFromReader
          );
          msg.setType(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pomerium.dashboard.SetDeviceTypeResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.pomerium.dashboard.SetDeviceTypeResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pomerium.dashboard.SetDeviceTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pomerium.dashboard.SetDeviceTypeResponse.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getType();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        proto.pomerium.dashboard.DeviceType.serializeBinaryToWriter
      );
    }
  };

/**
 * optional DeviceType type = 1;
 * @return {?proto.pomerium.dashboard.DeviceType}
 */
proto.pomerium.dashboard.SetDeviceTypeResponse.prototype.getType = function () {
  return /** @type{?proto.pomerium.dashboard.DeviceType} */ (
    jspb.Message.getWrapperField(this, proto.pomerium.dashboard.DeviceType, 1)
  );
};

/**
 * @param {?proto.pomerium.dashboard.DeviceType|undefined} value
 * @return {!proto.pomerium.dashboard.SetDeviceTypeResponse} returns this
 */
proto.pomerium.dashboard.SetDeviceTypeResponse.prototype.setType = function (
  value
) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pomerium.dashboard.SetDeviceTypeResponse} returns this
 */
proto.pomerium.dashboard.SetDeviceTypeResponse.prototype.clearType =
  function () {
    return this.setType(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pomerium.dashboard.SetDeviceTypeResponse.prototype.hasType = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * @enum {number}
 */
proto.pomerium.dashboard.DeviceKind = {
  UNKNOWN: 0,
  FIDO_U2F: 1,
  ANDROID: 2,
  APPLE: 3,
  TPM: 4,
  WINDOWS: 5
};

goog.object.extend(exports, proto.pomerium.dashboard);
