// source: xds/core/v3/resource_locator.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var udpa_annotations_status_pb = require('../../../udpa/annotations/status_pb.js');
goog.object.extend(proto, udpa_annotations_status_pb);
var xds_core_v3_context_params_pb = require('../../../xds/core/v3/context_params_pb.js');
goog.object.extend(proto, xds_core_v3_context_params_pb);
var validate_validate_pb = require('../../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.xds.core.v3.ResourceLocator', null, global);
goog.exportSymbol(
  'proto.xds.core.v3.ResourceLocator.ContextParamSpecifierCase',
  null,
  global
);
goog.exportSymbol('proto.xds.core.v3.ResourceLocator.Directive', null, global);
goog.exportSymbol(
  'proto.xds.core.v3.ResourceLocator.Directive.DirectiveCase',
  null,
  global
);
goog.exportSymbol('proto.xds.core.v3.ResourceLocator.Scheme', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.xds.core.v3.ResourceLocator = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.xds.core.v3.ResourceLocator.repeatedFields_,
    proto.xds.core.v3.ResourceLocator.oneofGroups_
  );
};
goog.inherits(proto.xds.core.v3.ResourceLocator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.xds.core.v3.ResourceLocator.displayName =
    'proto.xds.core.v3.ResourceLocator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.xds.core.v3.ResourceLocator.Directive = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    null,
    proto.xds.core.v3.ResourceLocator.Directive.oneofGroups_
  );
};
goog.inherits(proto.xds.core.v3.ResourceLocator.Directive, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.xds.core.v3.ResourceLocator.Directive.displayName =
    'proto.xds.core.v3.ResourceLocator.Directive';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.xds.core.v3.ResourceLocator.repeatedFields_ = [6];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.xds.core.v3.ResourceLocator.oneofGroups_ = [[5]];

/**
 * @enum {number}
 */
proto.xds.core.v3.ResourceLocator.ContextParamSpecifierCase = {
  CONTEXT_PARAM_SPECIFIER_NOT_SET: 0,
  EXACT_CONTEXT: 5
};

/**
 * @return {proto.xds.core.v3.ResourceLocator.ContextParamSpecifierCase}
 */
proto.xds.core.v3.ResourceLocator.prototype.getContextParamSpecifierCase =
  function () {
    return /** @type {proto.xds.core.v3.ResourceLocator.ContextParamSpecifierCase} */ (
      jspb.Message.computeOneofCase(
        this,
        proto.xds.core.v3.ResourceLocator.oneofGroups_[0]
      )
    );
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.xds.core.v3.ResourceLocator.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.xds.core.v3.ResourceLocator.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.xds.core.v3.ResourceLocator} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.xds.core.v3.ResourceLocator.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        scheme: jspb.Message.getFieldWithDefault(msg, 1, 0),
        id: jspb.Message.getFieldWithDefault(msg, 2, ''),
        authority: jspb.Message.getFieldWithDefault(msg, 3, ''),
        resourceType: jspb.Message.getFieldWithDefault(msg, 4, ''),
        exactContext:
          (f = msg.getExactContext()) &&
          xds_core_v3_context_params_pb.ContextParams.toObject(
            includeInstance,
            f
          ),
        directivesList: jspb.Message.toObjectList(
          msg.getDirectivesList(),
          proto.xds.core.v3.ResourceLocator.Directive.toObject,
          includeInstance
        )
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.xds.core.v3.ResourceLocator}
 */
proto.xds.core.v3.ResourceLocator.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.xds.core.v3.ResourceLocator();
  return proto.xds.core.v3.ResourceLocator.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.xds.core.v3.ResourceLocator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.xds.core.v3.ResourceLocator}
 */
proto.xds.core.v3.ResourceLocator.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!proto.xds.core.v3.ResourceLocator.Scheme} */ (
          reader.readEnum()
        );
        msg.setScheme(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setAuthority(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setResourceType(value);
        break;
      case 5:
        var value = new xds_core_v3_context_params_pb.ContextParams();
        reader.readMessage(
          value,
          xds_core_v3_context_params_pb.ContextParams
            .deserializeBinaryFromReader
        );
        msg.setExactContext(value);
        break;
      case 6:
        var value = new proto.xds.core.v3.ResourceLocator.Directive();
        reader.readMessage(
          value,
          proto.xds.core.v3.ResourceLocator.Directive
            .deserializeBinaryFromReader
        );
        msg.addDirectives(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.xds.core.v3.ResourceLocator.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.xds.core.v3.ResourceLocator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.xds.core.v3.ResourceLocator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.xds.core.v3.ResourceLocator.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getScheme();
  if (f !== 0.0) {
    writer.writeEnum(1, f);
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getAuthority();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
  f = message.getResourceType();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
  f = message.getExactContext();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      xds_core_v3_context_params_pb.ContextParams.serializeBinaryToWriter
    );
  }
  f = message.getDirectivesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.xds.core.v3.ResourceLocator.Directive.serializeBinaryToWriter
    );
  }
};

/**
 * @enum {number}
 */
proto.xds.core.v3.ResourceLocator.Scheme = {
  XDSTP: 0,
  HTTP: 1,
  FILE: 2
};

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.xds.core.v3.ResourceLocator.Directive.oneofGroups_ = [[1, 2]];

/**
 * @enum {number}
 */
proto.xds.core.v3.ResourceLocator.Directive.DirectiveCase = {
  DIRECTIVE_NOT_SET: 0,
  ALT: 1,
  ENTRY: 2
};

/**
 * @return {proto.xds.core.v3.ResourceLocator.Directive.DirectiveCase}
 */
proto.xds.core.v3.ResourceLocator.Directive.prototype.getDirectiveCase =
  function () {
    return /** @type {proto.xds.core.v3.ResourceLocator.Directive.DirectiveCase} */ (
      jspb.Message.computeOneofCase(
        this,
        proto.xds.core.v3.ResourceLocator.Directive.oneofGroups_[0]
      )
    );
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.xds.core.v3.ResourceLocator.Directive.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.xds.core.v3.ResourceLocator.Directive.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.xds.core.v3.ResourceLocator.Directive} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.xds.core.v3.ResourceLocator.Directive.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        alt:
          (f = msg.getAlt()) &&
          proto.xds.core.v3.ResourceLocator.toObject(includeInstance, f),
        entry: jspb.Message.getFieldWithDefault(msg, 2, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.xds.core.v3.ResourceLocator.Directive}
 */
proto.xds.core.v3.ResourceLocator.Directive.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.xds.core.v3.ResourceLocator.Directive();
  return proto.xds.core.v3.ResourceLocator.Directive.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.xds.core.v3.ResourceLocator.Directive} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.xds.core.v3.ResourceLocator.Directive}
 */
proto.xds.core.v3.ResourceLocator.Directive.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.xds.core.v3.ResourceLocator();
          reader.readMessage(
            value,
            proto.xds.core.v3.ResourceLocator.deserializeBinaryFromReader
          );
          msg.setAlt(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setEntry(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.xds.core.v3.ResourceLocator.Directive.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.xds.core.v3.ResourceLocator.Directive.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.xds.core.v3.ResourceLocator.Directive} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.xds.core.v3.ResourceLocator.Directive.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getAlt();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.xds.core.v3.ResourceLocator.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(2, f);
  }
};

/**
 * optional ResourceLocator alt = 1;
 * @return {?proto.xds.core.v3.ResourceLocator}
 */
proto.xds.core.v3.ResourceLocator.Directive.prototype.getAlt = function () {
  return /** @type{?proto.xds.core.v3.ResourceLocator} */ (
    jspb.Message.getWrapperField(this, proto.xds.core.v3.ResourceLocator, 1)
  );
};

/**
 * @param {?proto.xds.core.v3.ResourceLocator|undefined} value
 * @return {!proto.xds.core.v3.ResourceLocator.Directive} returns this
 */
proto.xds.core.v3.ResourceLocator.Directive.prototype.setAlt = function (
  value
) {
  return jspb.Message.setOneofWrapperField(
    this,
    1,
    proto.xds.core.v3.ResourceLocator.Directive.oneofGroups_[0],
    value
  );
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.xds.core.v3.ResourceLocator.Directive} returns this
 */
proto.xds.core.v3.ResourceLocator.Directive.prototype.clearAlt = function () {
  return this.setAlt(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.xds.core.v3.ResourceLocator.Directive.prototype.hasAlt = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional string entry = 2;
 * @return {string}
 */
proto.xds.core.v3.ResourceLocator.Directive.prototype.getEntry = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.xds.core.v3.ResourceLocator.Directive} returns this
 */
proto.xds.core.v3.ResourceLocator.Directive.prototype.setEntry = function (
  value
) {
  return jspb.Message.setOneofField(
    this,
    2,
    proto.xds.core.v3.ResourceLocator.Directive.oneofGroups_[0],
    value
  );
};

/**
 * Clears the field making it undefined.
 * @return {!proto.xds.core.v3.ResourceLocator.Directive} returns this
 */
proto.xds.core.v3.ResourceLocator.Directive.prototype.clearEntry = function () {
  return jspb.Message.setOneofField(
    this,
    2,
    proto.xds.core.v3.ResourceLocator.Directive.oneofGroups_[0],
    undefined
  );
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.xds.core.v3.ResourceLocator.Directive.prototype.hasEntry = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional Scheme scheme = 1;
 * @return {!proto.xds.core.v3.ResourceLocator.Scheme}
 */
proto.xds.core.v3.ResourceLocator.prototype.getScheme = function () {
  return /** @type {!proto.xds.core.v3.ResourceLocator.Scheme} */ (
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};

/**
 * @param {!proto.xds.core.v3.ResourceLocator.Scheme} value
 * @return {!proto.xds.core.v3.ResourceLocator} returns this
 */
proto.xds.core.v3.ResourceLocator.prototype.setScheme = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};

/**
 * optional string id = 2;
 * @return {string}
 */
proto.xds.core.v3.ResourceLocator.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.xds.core.v3.ResourceLocator} returns this
 */
proto.xds.core.v3.ResourceLocator.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string authority = 3;
 * @return {string}
 */
proto.xds.core.v3.ResourceLocator.prototype.getAuthority = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};

/**
 * @param {string} value
 * @return {!proto.xds.core.v3.ResourceLocator} returns this
 */
proto.xds.core.v3.ResourceLocator.prototype.setAuthority = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional string resource_type = 4;
 * @return {string}
 */
proto.xds.core.v3.ResourceLocator.prototype.getResourceType = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};

/**
 * @param {string} value
 * @return {!proto.xds.core.v3.ResourceLocator} returns this
 */
proto.xds.core.v3.ResourceLocator.prototype.setResourceType = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * optional ContextParams exact_context = 5;
 * @return {?proto.xds.core.v3.ContextParams}
 */
proto.xds.core.v3.ResourceLocator.prototype.getExactContext = function () {
  return /** @type{?proto.xds.core.v3.ContextParams} */ (
    jspb.Message.getWrapperField(
      this,
      xds_core_v3_context_params_pb.ContextParams,
      5
    )
  );
};

/**
 * @param {?proto.xds.core.v3.ContextParams|undefined} value
 * @return {!proto.xds.core.v3.ResourceLocator} returns this
 */
proto.xds.core.v3.ResourceLocator.prototype.setExactContext = function (value) {
  return jspb.Message.setOneofWrapperField(
    this,
    5,
    proto.xds.core.v3.ResourceLocator.oneofGroups_[0],
    value
  );
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.xds.core.v3.ResourceLocator} returns this
 */
proto.xds.core.v3.ResourceLocator.prototype.clearExactContext = function () {
  return this.setExactContext(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.xds.core.v3.ResourceLocator.prototype.hasExactContext = function () {
  return jspb.Message.getField(this, 5) != null;
};

/**
 * repeated Directive directives = 6;
 * @return {!Array<!proto.xds.core.v3.ResourceLocator.Directive>}
 */
proto.xds.core.v3.ResourceLocator.prototype.getDirectivesList = function () {
  return /** @type{!Array<!proto.xds.core.v3.ResourceLocator.Directive>} */ (
    jspb.Message.getRepeatedWrapperField(
      this,
      proto.xds.core.v3.ResourceLocator.Directive,
      6
    )
  );
};

/**
 * @param {!Array<!proto.xds.core.v3.ResourceLocator.Directive>} value
 * @return {!proto.xds.core.v3.ResourceLocator} returns this
 */
proto.xds.core.v3.ResourceLocator.prototype.setDirectivesList = function (
  value
) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};

/**
 * @param {!proto.xds.core.v3.ResourceLocator.Directive=} opt_value
 * @param {number=} opt_index
 * @return {!proto.xds.core.v3.ResourceLocator.Directive}
 */
proto.xds.core.v3.ResourceLocator.prototype.addDirectives = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    6,
    opt_value,
    proto.xds.core.v3.ResourceLocator.Directive,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.xds.core.v3.ResourceLocator} returns this
 */
proto.xds.core.v3.ResourceLocator.prototype.clearDirectivesList = function () {
  return this.setDirectivesList([]);
};

goog.object.extend(exports, proto.xds.core.v3);
