/**
 * @fileoverview gRPC-Web generated client stub for pomerium.dashboard
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!

/* eslint-disable */
// @ts-nocheck

import * as grpcWeb from 'grpc-web';

import * as routes_pb from './routes_pb';

export class RouteServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string };
  options_: null | { [index: string]: any };

  constructor(
    hostname: string,
    credentials?: null | { [index: string]: string },
    options?: null | { [index: string]: any }
  ) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoDeleteRoute = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.RouteService/DeleteRoute',
    grpcWeb.MethodType.UNARY,
    routes_pb.DeleteRouteRequest,
    routes_pb.DeleteRouteResponse,
    (request: routes_pb.DeleteRouteRequest) => {
      return request.serializeBinary();
    },
    routes_pb.DeleteRouteResponse.deserializeBinary
  );

  deleteRoute(
    request: routes_pb.DeleteRouteRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<routes_pb.DeleteRouteResponse>;

  deleteRoute(
    request: routes_pb.DeleteRouteRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: routes_pb.DeleteRouteResponse
    ) => void
  ): grpcWeb.ClientReadableStream<routes_pb.DeleteRouteResponse>;

  deleteRoute(
    request: routes_pb.DeleteRouteRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: routes_pb.DeleteRouteResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + '/pomerium.dashboard.RouteService/DeleteRoute',
        request,
        metadata || {},
        this.methodInfoDeleteRoute,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + '/pomerium.dashboard.RouteService/DeleteRoute',
      request,
      metadata || {},
      this.methodInfoDeleteRoute
    );
  }

  methodInfoGetRoute = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.RouteService/GetRoute',
    grpcWeb.MethodType.UNARY,
    routes_pb.GetRouteRequest,
    routes_pb.GetRouteResponse,
    (request: routes_pb.GetRouteRequest) => {
      return request.serializeBinary();
    },
    routes_pb.GetRouteResponse.deserializeBinary
  );

  getRoute(
    request: routes_pb.GetRouteRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<routes_pb.GetRouteResponse>;

  getRoute(
    request: routes_pb.GetRouteRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: routes_pb.GetRouteResponse
    ) => void
  ): grpcWeb.ClientReadableStream<routes_pb.GetRouteResponse>;

  getRoute(
    request: routes_pb.GetRouteRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: routes_pb.GetRouteResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + '/pomerium.dashboard.RouteService/GetRoute',
        request,
        metadata || {},
        this.methodInfoGetRoute,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + '/pomerium.dashboard.RouteService/GetRoute',
      request,
      metadata || {},
      this.methodInfoGetRoute
    );
  }

  methodInfoListRoutes = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.RouteService/ListRoutes',
    grpcWeb.MethodType.UNARY,
    routes_pb.ListRoutesRequest,
    routes_pb.ListRoutesResponse,
    (request: routes_pb.ListRoutesRequest) => {
      return request.serializeBinary();
    },
    routes_pb.ListRoutesResponse.deserializeBinary
  );

  listRoutes(
    request: routes_pb.ListRoutesRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<routes_pb.ListRoutesResponse>;

  listRoutes(
    request: routes_pb.ListRoutesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: routes_pb.ListRoutesResponse
    ) => void
  ): grpcWeb.ClientReadableStream<routes_pb.ListRoutesResponse>;

  listRoutes(
    request: routes_pb.ListRoutesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: routes_pb.ListRoutesResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + '/pomerium.dashboard.RouteService/ListRoutes',
        request,
        metadata || {},
        this.methodInfoListRoutes,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + '/pomerium.dashboard.RouteService/ListRoutes',
      request,
      metadata || {},
      this.methodInfoListRoutes
    );
  }

  methodInfoLoadRoutes = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.RouteService/LoadRoutes',
    grpcWeb.MethodType.UNARY,
    routes_pb.LoadRoutesRequest,
    routes_pb.LoadRoutesResponse,
    (request: routes_pb.LoadRoutesRequest) => {
      return request.serializeBinary();
    },
    routes_pb.LoadRoutesResponse.deserializeBinary
  );

  loadRoutes(
    request: routes_pb.LoadRoutesRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<routes_pb.LoadRoutesResponse>;

  loadRoutes(
    request: routes_pb.LoadRoutesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: routes_pb.LoadRoutesResponse
    ) => void
  ): grpcWeb.ClientReadableStream<routes_pb.LoadRoutesResponse>;

  loadRoutes(
    request: routes_pb.LoadRoutesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: routes_pb.LoadRoutesResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + '/pomerium.dashboard.RouteService/LoadRoutes',
        request,
        metadata || {},
        this.methodInfoLoadRoutes,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + '/pomerium.dashboard.RouteService/LoadRoutes',
      request,
      metadata || {},
      this.methodInfoLoadRoutes
    );
  }

  methodInfoSetRoute = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.RouteService/SetRoute',
    grpcWeb.MethodType.UNARY,
    routes_pb.SetRouteRequest,
    routes_pb.SetRouteResponse,
    (request: routes_pb.SetRouteRequest) => {
      return request.serializeBinary();
    },
    routes_pb.SetRouteResponse.deserializeBinary
  );

  setRoute(
    request: routes_pb.SetRouteRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<routes_pb.SetRouteResponse>;

  setRoute(
    request: routes_pb.SetRouteRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: routes_pb.SetRouteResponse
    ) => void
  ): grpcWeb.ClientReadableStream<routes_pb.SetRouteResponse>;

  setRoute(
    request: routes_pb.SetRouteRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: routes_pb.SetRouteResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + '/pomerium.dashboard.RouteService/SetRoute',
        request,
        metadata || {},
        this.methodInfoSetRoute,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + '/pomerium.dashboard.RouteService/SetRoute',
      request,
      metadata || {},
      this.methodInfoSetRoute
    );
  }

  methodInfoMoveRoutes = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.RouteService/MoveRoutes',
    grpcWeb.MethodType.UNARY,
    routes_pb.MoveRoutesRequest,
    routes_pb.MoveRoutesResponse,
    (request: routes_pb.MoveRoutesRequest) => {
      return request.serializeBinary();
    },
    routes_pb.MoveRoutesResponse.deserializeBinary
  );

  moveRoutes(
    request: routes_pb.MoveRoutesRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<routes_pb.MoveRoutesResponse>;

  moveRoutes(
    request: routes_pb.MoveRoutesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: routes_pb.MoveRoutesResponse
    ) => void
  ): grpcWeb.ClientReadableStream<routes_pb.MoveRoutesResponse>;

  moveRoutes(
    request: routes_pb.MoveRoutesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: routes_pb.MoveRoutesResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + '/pomerium.dashboard.RouteService/MoveRoutes',
        request,
        metadata || {},
        this.methodInfoMoveRoutes,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + '/pomerium.dashboard.RouteService/MoveRoutes',
      request,
      metadata || {},
      this.methodInfoMoveRoutes
    );
  }
}
