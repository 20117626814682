import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Empty } from 'google-protobuf/google/protobuf/empty_pb';

import { DeviceServiceClient } from '../pb/DevicesServiceClientPb';
import {
  ApproveDeviceRequest,
  CreateDeviceEnrollmentRequest,
  DeleteDeviceRequest,
  ListDevicesRequest,
  ListDevicesResponse,
  ListDeviceTypesResponse
} from '../pb/devices_pb';
import { pushError, pushSuccess } from './notifications';

import { AppThunk, DevicesState } from 'src/store/state';
import { getErrorMessage } from 'src/utils/errors';
import { getClient, getMetadata } from 'src/utils/grpc';

const initialState: DevicesState = {
  list: [],
  types: [],
  loading: false,
  newEnrollmentUrl: ''
};

const slice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    getList(
      state: DevicesState,
      action: PayloadAction<ListDevicesResponse>
    ): void {
      state.list = action.payload.getDevicesList().map((e) => e.toObject());
      state.loading = false;
    },
    setTypes(
      state: DevicesState,
      action: PayloadAction<ListDeviceTypesResponse>
    ): void {
      state.types = action.payload.getTypesList().map((e) => e.toObject());
    },
    setLoading(state: DevicesState, action: PayloadAction<boolean>): void {
      state.loading = action.payload;
    },
    setEnrollmentUrl(state: DevicesState, action: PayloadAction<string>): void {
      state.newEnrollmentUrl = action.payload;
    }
  }
});

export const { reducer } = slice;

const client = getClient(DeviceServiceClient);

export const getDevices =
  (req?: ListDevicesRequest): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    req = req || new ListDevicesRequest();
    try {
      const res = await client.listDevices(
        req,
        getMetadata(getState().session)
      );
      dispatch(slice.actions.getList(res));
    } catch (e) {
      dispatch(pushError(getErrorMessage(e)));
    }
  };

export const getDeviceTypes =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    try {
      const res = await client.listDeviceTypes(
        new Empty(),
        getMetadata(getState().session)
      );
      dispatch(slice.actions.setTypes(res));
    } catch (e) {
      dispatch(pushError(getErrorMessage(e)));
    }
  };

export const addEnrollment =
  (req?: CreateDeviceEnrollmentRequest): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    try {
      const res = await client.createDeviceEnrollment(
        req,
        getMetadata(getState().session)
      );
      dispatch(slice.actions.setEnrollmentUrl(res.getEnrollmentUrl()));
      dispatch(getDevices());
      dispatch(pushSuccess('Enrollment Added!'));
    } catch (e) {
      dispatch(pushError(getErrorMessage(e)));
    }
  };

export const clearEnrollmentUrl =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setEnrollmentUrl(''));
  };

export const approveDevice =
  (req?: ApproveDeviceRequest): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    try {
      await client.approveDevice(req, getMetadata(getState().session));
      dispatch(getDevices());
      dispatch(
        pushSuccess(
          'Device ' + req?.getEnrollmentId() ||
            req?.getCredentialId() + ' Approved!'
        )
      );
    } catch (e) {
      dispatch(pushError(getErrorMessage(e)));
    }
  };

export const deleteDevice =
  (req?: DeleteDeviceRequest): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    try {
      dispatch(slice.actions.setLoading(true));
      await client.deleteDevice(req, getMetadata(getState().session));
      dispatch(getDevices());
      dispatch(
        pushSuccess(
          'Device ' + req?.getEnrollmentId() ||
            req?.getCredentialId() + ' Deleted!'
        )
      );
    } catch (e) {
      dispatch(pushError(getErrorMessage(e)));
    }
  };
