// source: xds/core/v3/collection_entry.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_any_pb = require('google-protobuf/google/protobuf/any_pb.js');
goog.object.extend(proto, google_protobuf_any_pb);
var udpa_annotations_status_pb = require('../../../udpa/annotations/status_pb.js');
goog.object.extend(proto, udpa_annotations_status_pb);
var xds_core_v3_resource_locator_pb = require('../../../xds/core/v3/resource_locator_pb.js');
goog.object.extend(proto, xds_core_v3_resource_locator_pb);
var validate_validate_pb = require('../../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.xds.core.v3.CollectionEntry', null, global);
goog.exportSymbol(
  'proto.xds.core.v3.CollectionEntry.InlineEntry',
  null,
  global
);
goog.exportSymbol(
  'proto.xds.core.v3.CollectionEntry.ResourceSpecifierCase',
  null,
  global
);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.xds.core.v3.CollectionEntry = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    null,
    proto.xds.core.v3.CollectionEntry.oneofGroups_
  );
};
goog.inherits(proto.xds.core.v3.CollectionEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.xds.core.v3.CollectionEntry.displayName =
    'proto.xds.core.v3.CollectionEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.xds.core.v3.CollectionEntry.InlineEntry = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.xds.core.v3.CollectionEntry.InlineEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.xds.core.v3.CollectionEntry.InlineEntry.displayName =
    'proto.xds.core.v3.CollectionEntry.InlineEntry';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.xds.core.v3.CollectionEntry.oneofGroups_ = [[1, 2]];

/**
 * @enum {number}
 */
proto.xds.core.v3.CollectionEntry.ResourceSpecifierCase = {
  RESOURCE_SPECIFIER_NOT_SET: 0,
  LOCATOR: 1,
  INLINE_ENTRY: 2
};

/**
 * @return {proto.xds.core.v3.CollectionEntry.ResourceSpecifierCase}
 */
proto.xds.core.v3.CollectionEntry.prototype.getResourceSpecifierCase =
  function () {
    return /** @type {proto.xds.core.v3.CollectionEntry.ResourceSpecifierCase} */ (
      jspb.Message.computeOneofCase(
        this,
        proto.xds.core.v3.CollectionEntry.oneofGroups_[0]
      )
    );
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.xds.core.v3.CollectionEntry.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.xds.core.v3.CollectionEntry.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.xds.core.v3.CollectionEntry} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.xds.core.v3.CollectionEntry.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        locator:
          (f = msg.getLocator()) &&
          xds_core_v3_resource_locator_pb.ResourceLocator.toObject(
            includeInstance,
            f
          ),
        inlineEntry:
          (f = msg.getInlineEntry()) &&
          proto.xds.core.v3.CollectionEntry.InlineEntry.toObject(
            includeInstance,
            f
          )
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.xds.core.v3.CollectionEntry}
 */
proto.xds.core.v3.CollectionEntry.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.xds.core.v3.CollectionEntry();
  return proto.xds.core.v3.CollectionEntry.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.xds.core.v3.CollectionEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.xds.core.v3.CollectionEntry}
 */
proto.xds.core.v3.CollectionEntry.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new xds_core_v3_resource_locator_pb.ResourceLocator();
        reader.readMessage(
          value,
          xds_core_v3_resource_locator_pb.ResourceLocator
            .deserializeBinaryFromReader
        );
        msg.setLocator(value);
        break;
      case 2:
        var value = new proto.xds.core.v3.CollectionEntry.InlineEntry();
        reader.readMessage(
          value,
          proto.xds.core.v3.CollectionEntry.InlineEntry
            .deserializeBinaryFromReader
        );
        msg.setInlineEntry(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.xds.core.v3.CollectionEntry.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.xds.core.v3.CollectionEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.xds.core.v3.CollectionEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.xds.core.v3.CollectionEntry.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getLocator();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      xds_core_v3_resource_locator_pb.ResourceLocator.serializeBinaryToWriter
    );
  }
  f = message.getInlineEntry();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.xds.core.v3.CollectionEntry.InlineEntry.serializeBinaryToWriter
    );
  }
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.xds.core.v3.CollectionEntry.InlineEntry.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.xds.core.v3.CollectionEntry.InlineEntry.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.xds.core.v3.CollectionEntry.InlineEntry} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.xds.core.v3.CollectionEntry.InlineEntry.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        name: jspb.Message.getFieldWithDefault(msg, 1, ''),
        version: jspb.Message.getFieldWithDefault(msg, 2, ''),
        resource:
          (f = msg.getResource()) &&
          google_protobuf_any_pb.Any.toObject(includeInstance, f)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.xds.core.v3.CollectionEntry.InlineEntry}
 */
proto.xds.core.v3.CollectionEntry.InlineEntry.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.xds.core.v3.CollectionEntry.InlineEntry();
  return proto.xds.core.v3.CollectionEntry.InlineEntry.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.xds.core.v3.CollectionEntry.InlineEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.xds.core.v3.CollectionEntry.InlineEntry}
 */
proto.xds.core.v3.CollectionEntry.InlineEntry.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setName(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setVersion(value);
          break;
        case 3:
          var value = new google_protobuf_any_pb.Any();
          reader.readMessage(
            value,
            google_protobuf_any_pb.Any.deserializeBinaryFromReader
          );
          msg.setResource(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.xds.core.v3.CollectionEntry.InlineEntry.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.xds.core.v3.CollectionEntry.InlineEntry.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.xds.core.v3.CollectionEntry.InlineEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.xds.core.v3.CollectionEntry.InlineEntry.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
    f = message.getVersion();
    if (f.length > 0) {
      writer.writeString(2, f);
    }
    f = message.getResource();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        google_protobuf_any_pb.Any.serializeBinaryToWriter
      );
    }
  };

/**
 * optional string name = 1;
 * @return {string}
 */
proto.xds.core.v3.CollectionEntry.InlineEntry.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.xds.core.v3.CollectionEntry.InlineEntry} returns this
 */
proto.xds.core.v3.CollectionEntry.InlineEntry.prototype.setName = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string version = 2;
 * @return {string}
 */
proto.xds.core.v3.CollectionEntry.InlineEntry.prototype.getVersion =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 2, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.xds.core.v3.CollectionEntry.InlineEntry} returns this
 */
proto.xds.core.v3.CollectionEntry.InlineEntry.prototype.setVersion = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional google.protobuf.Any resource = 3;
 * @return {?proto.google.protobuf.Any}
 */
proto.xds.core.v3.CollectionEntry.InlineEntry.prototype.getResource =
  function () {
    return /** @type{?proto.google.protobuf.Any} */ (
      jspb.Message.getWrapperField(this, google_protobuf_any_pb.Any, 3)
    );
  };

/**
 * @param {?proto.google.protobuf.Any|undefined} value
 * @return {!proto.xds.core.v3.CollectionEntry.InlineEntry} returns this
 */
proto.xds.core.v3.CollectionEntry.InlineEntry.prototype.setResource = function (
  value
) {
  return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.xds.core.v3.CollectionEntry.InlineEntry} returns this
 */
proto.xds.core.v3.CollectionEntry.InlineEntry.prototype.clearResource =
  function () {
    return this.setResource(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.xds.core.v3.CollectionEntry.InlineEntry.prototype.hasResource =
  function () {
    return jspb.Message.getField(this, 3) != null;
  };

/**
 * optional ResourceLocator locator = 1;
 * @return {?proto.xds.core.v3.ResourceLocator}
 */
proto.xds.core.v3.CollectionEntry.prototype.getLocator = function () {
  return /** @type{?proto.xds.core.v3.ResourceLocator} */ (
    jspb.Message.getWrapperField(
      this,
      xds_core_v3_resource_locator_pb.ResourceLocator,
      1
    )
  );
};

/**
 * @param {?proto.xds.core.v3.ResourceLocator|undefined} value
 * @return {!proto.xds.core.v3.CollectionEntry} returns this
 */
proto.xds.core.v3.CollectionEntry.prototype.setLocator = function (value) {
  return jspb.Message.setOneofWrapperField(
    this,
    1,
    proto.xds.core.v3.CollectionEntry.oneofGroups_[0],
    value
  );
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.xds.core.v3.CollectionEntry} returns this
 */
proto.xds.core.v3.CollectionEntry.prototype.clearLocator = function () {
  return this.setLocator(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.xds.core.v3.CollectionEntry.prototype.hasLocator = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional InlineEntry inline_entry = 2;
 * @return {?proto.xds.core.v3.CollectionEntry.InlineEntry}
 */
proto.xds.core.v3.CollectionEntry.prototype.getInlineEntry = function () {
  return /** @type{?proto.xds.core.v3.CollectionEntry.InlineEntry} */ (
    jspb.Message.getWrapperField(
      this,
      proto.xds.core.v3.CollectionEntry.InlineEntry,
      2
    )
  );
};

/**
 * @param {?proto.xds.core.v3.CollectionEntry.InlineEntry|undefined} value
 * @return {!proto.xds.core.v3.CollectionEntry} returns this
 */
proto.xds.core.v3.CollectionEntry.prototype.setInlineEntry = function (value) {
  return jspb.Message.setOneofWrapperField(
    this,
    2,
    proto.xds.core.v3.CollectionEntry.oneofGroups_[0],
    value
  );
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.xds.core.v3.CollectionEntry} returns this
 */
proto.xds.core.v3.CollectionEntry.prototype.clearInlineEntry = function () {
  return this.setInlineEntry(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.xds.core.v3.CollectionEntry.prototype.hasInlineEntry = function () {
  return jspb.Message.getField(this, 2) != null;
};

goog.object.extend(exports, proto.xds.core.v3);
