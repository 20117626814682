type FeatureId = symbol;

// ReportsTraffic enables per-route traffic reporting
export const ReportsTraffic: FeatureId = Symbol('com.pomerium.reports.traffic');
export const RuntimeInfo: FeatureId = Symbol('com.pomerium.reports.runtime');
export const LogView: FeatureId = Symbol('com.pomerium.reports.log');

const features = Object.freeze(
  new Map<FeatureId, boolean>([
    [ReportsTraffic, true],
    [RuntimeInfo, true],
    [LogView, true]
  ])
);

export default features;
