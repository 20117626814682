/**
 * @fileoverview gRPC-Web generated client stub for pomerium.dashboard
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!

/* eslint-disable */
// @ts-nocheck

import * as grpcWeb from 'grpc-web';

import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';
import * as devices_pb from './devices_pb';

export class DeviceServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string };
  options_: null | { [index: string]: any };

  constructor(
    hostname: string,
    credentials?: null | { [index: string]: string },
    options?: null | { [index: string]: any }
  ) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoApproveDevice = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.DeviceService/ApproveDevice',
    grpcWeb.MethodType.UNARY,
    devices_pb.ApproveDeviceRequest,
    google_protobuf_empty_pb.Empty,
    (request: devices_pb.ApproveDeviceRequest) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  approveDevice(
    request: devices_pb.ApproveDeviceRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<google_protobuf_empty_pb.Empty>;

  approveDevice(
    request: devices_pb.ApproveDeviceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: google_protobuf_empty_pb.Empty
    ) => void
  ): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  approveDevice(
    request: devices_pb.ApproveDeviceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: google_protobuf_empty_pb.Empty
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + '/pomerium.dashboard.DeviceService/ApproveDevice',
        request,
        metadata || {},
        this.methodInfoApproveDevice,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + '/pomerium.dashboard.DeviceService/ApproveDevice',
      request,
      metadata || {},
      this.methodInfoApproveDevice
    );
  }

  methodInfoCreateDeviceEnrollment = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.DeviceService/CreateDeviceEnrollment',
    grpcWeb.MethodType.UNARY,
    devices_pb.CreateDeviceEnrollmentRequest,
    devices_pb.CreateDeviceEnrollmentResponse,
    (request: devices_pb.CreateDeviceEnrollmentRequest) => {
      return request.serializeBinary();
    },
    devices_pb.CreateDeviceEnrollmentResponse.deserializeBinary
  );

  createDeviceEnrollment(
    request: devices_pb.CreateDeviceEnrollmentRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<devices_pb.CreateDeviceEnrollmentResponse>;

  createDeviceEnrollment(
    request: devices_pb.CreateDeviceEnrollmentRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: devices_pb.CreateDeviceEnrollmentResponse
    ) => void
  ): grpcWeb.ClientReadableStream<devices_pb.CreateDeviceEnrollmentResponse>;

  createDeviceEnrollment(
    request: devices_pb.CreateDeviceEnrollmentRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: devices_pb.CreateDeviceEnrollmentResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/pomerium.dashboard.DeviceService/CreateDeviceEnrollment',
        request,
        metadata || {},
        this.methodInfoCreateDeviceEnrollment,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ +
        '/pomerium.dashboard.DeviceService/CreateDeviceEnrollment',
      request,
      metadata || {},
      this.methodInfoCreateDeviceEnrollment
    );
  }

  methodInfoSetDeviceType = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.DeviceService/SetDeviceType',
    grpcWeb.MethodType.UNARY,
    devices_pb.SetDeviceTypeRequest,
    devices_pb.SetDeviceTypeResponse,
    (request: devices_pb.SetDeviceTypeRequest) => {
      return request.serializeBinary();
    },
    devices_pb.SetDeviceTypeResponse.deserializeBinary
  );

  setDeviceType(
    request: devices_pb.SetDeviceTypeRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<devices_pb.SetDeviceTypeResponse>;

  setDeviceType(
    request: devices_pb.SetDeviceTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: devices_pb.SetDeviceTypeResponse
    ) => void
  ): grpcWeb.ClientReadableStream<devices_pb.SetDeviceTypeResponse>;

  setDeviceType(
    request: devices_pb.SetDeviceTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: devices_pb.SetDeviceTypeResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + '/pomerium.dashboard.DeviceService/SetDeviceType',
        request,
        metadata || {},
        this.methodInfoSetDeviceType,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + '/pomerium.dashboard.DeviceService/SetDeviceType',
      request,
      metadata || {},
      this.methodInfoSetDeviceType
    );
  }

  methodInfoDeleteDevice = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.DeviceService/DeleteDevice',
    grpcWeb.MethodType.UNARY,
    devices_pb.DeleteDeviceRequest,
    google_protobuf_empty_pb.Empty,
    (request: devices_pb.DeleteDeviceRequest) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  deleteDevice(
    request: devices_pb.DeleteDeviceRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<google_protobuf_empty_pb.Empty>;

  deleteDevice(
    request: devices_pb.DeleteDeviceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: google_protobuf_empty_pb.Empty
    ) => void
  ): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  deleteDevice(
    request: devices_pb.DeleteDeviceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: google_protobuf_empty_pb.Empty
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + '/pomerium.dashboard.DeviceService/DeleteDevice',
        request,
        metadata || {},
        this.methodInfoDeleteDevice,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + '/pomerium.dashboard.DeviceService/DeleteDevice',
      request,
      metadata || {},
      this.methodInfoDeleteDevice
    );
  }

  methodInfoDeleteDeviceType = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.DeviceService/DeleteDeviceType',
    grpcWeb.MethodType.UNARY,
    devices_pb.DeleteDeviceTypeRequest,
    google_protobuf_empty_pb.Empty,
    (request: devices_pb.DeleteDeviceTypeRequest) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  deleteDeviceType(
    request: devices_pb.DeleteDeviceTypeRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<google_protobuf_empty_pb.Empty>;

  deleteDeviceType(
    request: devices_pb.DeleteDeviceTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: google_protobuf_empty_pb.Empty
    ) => void
  ): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  deleteDeviceType(
    request: devices_pb.DeleteDeviceTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: google_protobuf_empty_pb.Empty
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + '/pomerium.dashboard.DeviceService/DeleteDeviceType',
        request,
        metadata || {},
        this.methodInfoDeleteDeviceType,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + '/pomerium.dashboard.DeviceService/DeleteDeviceType',
      request,
      metadata || {},
      this.methodInfoDeleteDeviceType
    );
  }

  methodInfoListDevices = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.DeviceService/ListDevices',
    grpcWeb.MethodType.UNARY,
    devices_pb.ListDevicesRequest,
    devices_pb.ListDevicesResponse,
    (request: devices_pb.ListDevicesRequest) => {
      return request.serializeBinary();
    },
    devices_pb.ListDevicesResponse.deserializeBinary
  );

  listDevices(
    request: devices_pb.ListDevicesRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<devices_pb.ListDevicesResponse>;

  listDevices(
    request: devices_pb.ListDevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: devices_pb.ListDevicesResponse
    ) => void
  ): grpcWeb.ClientReadableStream<devices_pb.ListDevicesResponse>;

  listDevices(
    request: devices_pb.ListDevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: devices_pb.ListDevicesResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + '/pomerium.dashboard.DeviceService/ListDevices',
        request,
        metadata || {},
        this.methodInfoListDevices,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + '/pomerium.dashboard.DeviceService/ListDevices',
      request,
      metadata || {},
      this.methodInfoListDevices
    );
  }

  methodInfoListDeviceTypes = new grpcWeb.MethodDescriptor(
    '/pomerium.dashboard.DeviceService/ListDeviceTypes',
    grpcWeb.MethodType.UNARY,
    google_protobuf_empty_pb.Empty,
    devices_pb.ListDeviceTypesResponse,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    devices_pb.ListDeviceTypesResponse.deserializeBinary
  );

  listDeviceTypes(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null
  ): Promise<devices_pb.ListDeviceTypesResponse>;

  listDeviceTypes(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: devices_pb.ListDeviceTypesResponse
    ) => void
  ): grpcWeb.ClientReadableStream<devices_pb.ListDeviceTypesResponse>;

  listDeviceTypes(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: devices_pb.ListDeviceTypesResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + '/pomerium.dashboard.DeviceService/ListDeviceTypes',
        request,
        metadata || {},
        this.methodInfoListDeviceTypes,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + '/pomerium.dashboard.DeviceService/ListDeviceTypes',
      request,
      metadata || {},
      this.methodInfoListDeviceTypes
    );
  }
}
