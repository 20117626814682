import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { pushError } from './notifications';

import { ActivityLogServiceClient } from 'src/pb/Activity_logServiceClientPb';
import {
  GetActivityLogEntryRequest,
  GetActivityLogEntryResponse,
  ListActivityLogEntriesRequest,
  ListActivityLogEntriesResponse
} from 'src/pb/activity_log_pb';
import { AppThunk, ActivityLogState } from 'src/store/state';
import { getErrorMessage } from 'src/utils/errors';
import { getClient, getMetadata } from 'src/utils/grpc';

const initialState: ActivityLogState = {
  entries: null,
  entryCount: 0,
  jsonList: [],

  diff: {
    a: null,
    b: null
  },

  loading: false
};

const slice = createSlice({
  name: 'activityLog',
  initialState,
  reducers: {
    listActivityLogEntries(
      state: ActivityLogState,
      action: PayloadAction<ListActivityLogEntriesResponse>
    ): void {
      if (!action.payload) {
        state.entries = null;
        state.jsonList = [];
        state.entryCount = 0;
      } else {
        state.entries = action.payload.getEntriesList();
        state.jsonList = action.payload
          .getEntriesList()
          .map((entry) => entry.toObject());
        state.entryCount = action.payload.getTotalCount();
      }
      state.loading = false;
    },

    getActivityLogEntryDiff(
      state: ActivityLogState,
      action: PayloadAction<{
        a: GetActivityLogEntryResponse;
        b: GetActivityLogEntryResponse;
      }>
    ): void {
      if (!action.payload) {
        state.diff = { a: null, b: null };
      } else {
        state.diff = action.payload;
      }
      state.loading = false;
    },

    setLoading(state: ActivityLogState, action: PayloadAction<boolean>): void {
      state.loading = action.payload;
    }
  }
});

export const { reducer } = slice;

const client = getClient(ActivityLogServiceClient);

export const listActivityLogEntries =
  (req: ListActivityLogEntriesRequest): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(slice.actions.setLoading(true));
    try {
      const res = await client.listActivityLogEntries(
        req,
        getMetadata(getState().session)
      );
      dispatch(slice.actions.listActivityLogEntries(res));
    } catch (e) {
      dispatch(pushError(getErrorMessage(e)));
    }
  };

export const getActivityLogEntryDiff =
  (aID: string, bID: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(slice.actions.setLoading(true));
    try {
      const resA = await client.getActivityLogEntry(
        new GetActivityLogEntryRequest().setId(aID),
        getMetadata(getState().session)
      );
      const resB = await client.getActivityLogEntry(
        new GetActivityLogEntryRequest().setId(bID),
        getMetadata(getState().session)
      );
      dispatch(
        slice.actions.getActivityLogEntryDiff({
          a: resA,
          b: resB
        })
      );
    } catch (e) {
      dispatch(pushError(getErrorMessage(e)));
    }
  };
