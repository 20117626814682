import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StatusCode } from 'grpc-web';

import { UserServiceClient } from 'src/pb/UsersServiceClientPb';
import { GetUserInfoRequest, GetUserInfoResponse } from 'src/pb/users_pb';
import { AppThunk, SessionState } from 'src/store/state';
import {
  getClient,
  getMetadata,
  WithMetadata,
  withMetadata
} from 'src/utils/grpc';

const initialState: SessionState = {
  jwt: localStorage.getItem('session.jwt'),
  userInfo: JSON.parse(localStorage.getItem('session.userInfo') || '{}')
};

const slice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setJWT(state: SessionState, action: PayloadAction<string>): void {
      if (action.payload) {
        localStorage.setItem('session.jwt', action.payload);
      } else {
        localStorage.removeItem('session.jwt');
      }
      state.jwt = action.payload;
    },

    getUserInfo(
      state: SessionState,
      action: PayloadAction<WithMetadata<GetUserInfoResponse>>
    ): void {
      const userInfo = action.payload?.response?.getUserInfo();
      if (userInfo) {
        localStorage.setItem('session.userInfo', JSON.stringify(userInfo));
      } else {
        localStorage.removeItem('session.userInfo');
      }
      state.userInfo = userInfo;
      state.pomeriumVersion = action.payload?.metadata?.['x-pomerium-version'];
      state.pomeriumConsoleVersion =
        action.payload?.metadata?.['x-pomerium-console-version'];
    }
  }
});

export const { reducer } = slice;

const client = getClient(UserServiceClient);

export const getUserInfo =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(slice.actions.getUserInfo(null));
    const req = new GetUserInfoRequest();
    try {
      const res = await withMetadata<GetUserInfoRequest, GetUserInfoResponse>(
        (request, metadata, callback) =>
          client.getUserInfo(request, metadata, callback)
      )(req, getMetadata(getState().session));
      dispatch(slice.actions.getUserInfo(res));
    } catch (e) {
      if (e?.code === StatusCode.UNAUTHENTICATED) {
        dispatch(slice.actions.getUserInfo(null));
      } else {
        throw e;
      }
    }
  };

export const loginWithImplicitCredentials =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setJWT(null));
    dispatch(getUserInfo());
  };

export const loginWithRecoveryToken =
  (jwt: string): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setJWT(jwt));
    dispatch(getUserInfo());
  };
