// source: envoy/config/endpoint/v3/endpoint_components.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var envoy_config_core_v3_address_pb = require('../../../../envoy/config/core/v3/address_pb.js');
goog.object.extend(proto, envoy_config_core_v3_address_pb);
var envoy_config_core_v3_base_pb = require('../../../../envoy/config/core/v3/base_pb.js');
goog.object.extend(proto, envoy_config_core_v3_base_pb);
var envoy_config_core_v3_health_check_pb = require('../../../../envoy/config/core/v3/health_check_pb.js');
goog.object.extend(proto, envoy_config_core_v3_health_check_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var udpa_annotations_status_pb = require('../../../../udpa/annotations/status_pb.js');
goog.object.extend(proto, udpa_annotations_status_pb);
var udpa_annotations_versioning_pb = require('../../../../udpa/annotations/versioning_pb.js');
goog.object.extend(proto, udpa_annotations_versioning_pb);
var validate_validate_pb = require('../../../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.envoy.config.endpoint.v3.Endpoint', null, global);
goog.exportSymbol(
  'proto.envoy.config.endpoint.v3.Endpoint.HealthCheckConfig',
  null,
  global
);
goog.exportSymbol('proto.envoy.config.endpoint.v3.LbEndpoint', null, global);
goog.exportSymbol(
  'proto.envoy.config.endpoint.v3.LbEndpoint.HostIdentifierCase',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.endpoint.v3.LocalityLbEndpoints',
  null,
  global
);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.endpoint.v3.Endpoint = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.envoy.config.endpoint.v3.Endpoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.endpoint.v3.Endpoint.displayName =
    'proto.envoy.config.endpoint.v3.Endpoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.endpoint.v3.Endpoint.HealthCheckConfig = function (
  opt_data
) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(
  proto.envoy.config.endpoint.v3.Endpoint.HealthCheckConfig,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.endpoint.v3.Endpoint.HealthCheckConfig.displayName =
    'proto.envoy.config.endpoint.v3.Endpoint.HealthCheckConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.endpoint.v3.LbEndpoint = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    null,
    proto.envoy.config.endpoint.v3.LbEndpoint.oneofGroups_
  );
};
goog.inherits(proto.envoy.config.endpoint.v3.LbEndpoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.endpoint.v3.LbEndpoint.displayName =
    'proto.envoy.config.endpoint.v3.LbEndpoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.endpoint.v3.LocalityLbEndpoints = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.envoy.config.endpoint.v3.LocalityLbEndpoints.repeatedFields_,
    null
  );
};
goog.inherits(proto.envoy.config.endpoint.v3.LocalityLbEndpoints, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.endpoint.v3.LocalityLbEndpoints.displayName =
    'proto.envoy.config.endpoint.v3.LocalityLbEndpoints';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.endpoint.v3.Endpoint.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.config.endpoint.v3.Endpoint.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.endpoint.v3.Endpoint} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.endpoint.v3.Endpoint.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        address:
          (f = msg.getAddress()) &&
          envoy_config_core_v3_address_pb.Address.toObject(includeInstance, f),
        healthCheckConfig:
          (f = msg.getHealthCheckConfig()) &&
          proto.envoy.config.endpoint.v3.Endpoint.HealthCheckConfig.toObject(
            includeInstance,
            f
          ),
        hostname: jspb.Message.getFieldWithDefault(msg, 3, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.endpoint.v3.Endpoint}
 */
proto.envoy.config.endpoint.v3.Endpoint.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.endpoint.v3.Endpoint();
  return proto.envoy.config.endpoint.v3.Endpoint.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.endpoint.v3.Endpoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.endpoint.v3.Endpoint}
 */
proto.envoy.config.endpoint.v3.Endpoint.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new envoy_config_core_v3_address_pb.Address();
        reader.readMessage(
          value,
          envoy_config_core_v3_address_pb.Address.deserializeBinaryFromReader
        );
        msg.setAddress(value);
        break;
      case 2:
        var value =
          new proto.envoy.config.endpoint.v3.Endpoint.HealthCheckConfig();
        reader.readMessage(
          value,
          proto.envoy.config.endpoint.v3.Endpoint.HealthCheckConfig
            .deserializeBinaryFromReader
        );
        msg.setHealthCheckConfig(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setHostname(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.endpoint.v3.Endpoint.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.endpoint.v3.Endpoint.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.endpoint.v3.Endpoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.endpoint.v3.Endpoint.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      envoy_config_core_v3_address_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getHealthCheckConfig();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.envoy.config.endpoint.v3.Endpoint.HealthCheckConfig
        .serializeBinaryToWriter
    );
  }
  f = message.getHostname();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.endpoint.v3.Endpoint.HealthCheckConfig.prototype.toObject =
    function (opt_includeInstance) {
      return proto.envoy.config.endpoint.v3.Endpoint.HealthCheckConfig.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.endpoint.v3.Endpoint.HealthCheckConfig} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.endpoint.v3.Endpoint.HealthCheckConfig.toObject =
    function (includeInstance, msg) {
      var f,
        obj = {
          portValue: jspb.Message.getFieldWithDefault(msg, 1, 0),
          hostname: jspb.Message.getFieldWithDefault(msg, 2, '')
        };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }
      return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.endpoint.v3.Endpoint.HealthCheckConfig}
 */
proto.envoy.config.endpoint.v3.Endpoint.HealthCheckConfig.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.envoy.config.endpoint.v3.Endpoint.HealthCheckConfig();
    return proto.envoy.config.endpoint.v3.Endpoint.HealthCheckConfig.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.endpoint.v3.Endpoint.HealthCheckConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.endpoint.v3.Endpoint.HealthCheckConfig}
 */
proto.envoy.config.endpoint.v3.Endpoint.HealthCheckConfig.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {number} */ (reader.readUint32());
          msg.setPortValue(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setHostname(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.endpoint.v3.Endpoint.HealthCheckConfig.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.endpoint.v3.Endpoint.HealthCheckConfig.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.endpoint.v3.Endpoint.HealthCheckConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.endpoint.v3.Endpoint.HealthCheckConfig.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getPortValue();
    if (f !== 0) {
      writer.writeUint32(1, f);
    }
    f = message.getHostname();
    if (f.length > 0) {
      writer.writeString(2, f);
    }
  };

/**
 * optional uint32 port_value = 1;
 * @return {number}
 */
proto.envoy.config.endpoint.v3.Endpoint.HealthCheckConfig.prototype.getPortValue =
  function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };

/**
 * @param {number} value
 * @return {!proto.envoy.config.endpoint.v3.Endpoint.HealthCheckConfig} returns this
 */
proto.envoy.config.endpoint.v3.Endpoint.HealthCheckConfig.prototype.setPortValue =
  function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
  };

/**
 * optional string hostname = 2;
 * @return {string}
 */
proto.envoy.config.endpoint.v3.Endpoint.HealthCheckConfig.prototype.getHostname =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 2, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.config.endpoint.v3.Endpoint.HealthCheckConfig} returns this
 */
proto.envoy.config.endpoint.v3.Endpoint.HealthCheckConfig.prototype.setHostname =
  function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
  };

/**
 * optional envoy.config.core.v3.Address address = 1;
 * @return {?proto.envoy.config.core.v3.Address}
 */
proto.envoy.config.endpoint.v3.Endpoint.prototype.getAddress = function () {
  return /** @type{?proto.envoy.config.core.v3.Address} */ (
    jspb.Message.getWrapperField(
      this,
      envoy_config_core_v3_address_pb.Address,
      1
    )
  );
};

/**
 * @param {?proto.envoy.config.core.v3.Address|undefined} value
 * @return {!proto.envoy.config.endpoint.v3.Endpoint} returns this
 */
proto.envoy.config.endpoint.v3.Endpoint.prototype.setAddress = function (
  value
) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.endpoint.v3.Endpoint} returns this
 */
proto.envoy.config.endpoint.v3.Endpoint.prototype.clearAddress = function () {
  return this.setAddress(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.endpoint.v3.Endpoint.prototype.hasAddress = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional HealthCheckConfig health_check_config = 2;
 * @return {?proto.envoy.config.endpoint.v3.Endpoint.HealthCheckConfig}
 */
proto.envoy.config.endpoint.v3.Endpoint.prototype.getHealthCheckConfig =
  function () {
    return /** @type{?proto.envoy.config.endpoint.v3.Endpoint.HealthCheckConfig} */ (
      jspb.Message.getWrapperField(
        this,
        proto.envoy.config.endpoint.v3.Endpoint.HealthCheckConfig,
        2
      )
    );
  };

/**
 * @param {?proto.envoy.config.endpoint.v3.Endpoint.HealthCheckConfig|undefined} value
 * @return {!proto.envoy.config.endpoint.v3.Endpoint} returns this
 */
proto.envoy.config.endpoint.v3.Endpoint.prototype.setHealthCheckConfig =
  function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.endpoint.v3.Endpoint} returns this
 */
proto.envoy.config.endpoint.v3.Endpoint.prototype.clearHealthCheckConfig =
  function () {
    return this.setHealthCheckConfig(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.endpoint.v3.Endpoint.prototype.hasHealthCheckConfig =
  function () {
    return jspb.Message.getField(this, 2) != null;
  };

/**
 * optional string hostname = 3;
 * @return {string}
 */
proto.envoy.config.endpoint.v3.Endpoint.prototype.getHostname = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};

/**
 * @param {string} value
 * @return {!proto.envoy.config.endpoint.v3.Endpoint} returns this
 */
proto.envoy.config.endpoint.v3.Endpoint.prototype.setHostname = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.envoy.config.endpoint.v3.LbEndpoint.oneofGroups_ = [[1, 5]];

/**
 * @enum {number}
 */
proto.envoy.config.endpoint.v3.LbEndpoint.HostIdentifierCase = {
  HOST_IDENTIFIER_NOT_SET: 0,
  ENDPOINT: 1,
  ENDPOINT_NAME: 5
};

/**
 * @return {proto.envoy.config.endpoint.v3.LbEndpoint.HostIdentifierCase}
 */
proto.envoy.config.endpoint.v3.LbEndpoint.prototype.getHostIdentifierCase =
  function () {
    return /** @type {proto.envoy.config.endpoint.v3.LbEndpoint.HostIdentifierCase} */ (
      jspb.Message.computeOneofCase(
        this,
        proto.envoy.config.endpoint.v3.LbEndpoint.oneofGroups_[0]
      )
    );
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.endpoint.v3.LbEndpoint.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.config.endpoint.v3.LbEndpoint.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.endpoint.v3.LbEndpoint} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.endpoint.v3.LbEndpoint.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        endpoint:
          (f = msg.getEndpoint()) &&
          proto.envoy.config.endpoint.v3.Endpoint.toObject(includeInstance, f),
        endpointName: jspb.Message.getFieldWithDefault(msg, 5, ''),
        healthStatus: jspb.Message.getFieldWithDefault(msg, 2, 0),
        metadata:
          (f = msg.getMetadata()) &&
          envoy_config_core_v3_base_pb.Metadata.toObject(includeInstance, f),
        loadBalancingWeight:
          (f = msg.getLoadBalancingWeight()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.endpoint.v3.LbEndpoint}
 */
proto.envoy.config.endpoint.v3.LbEndpoint.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.endpoint.v3.LbEndpoint();
  return proto.envoy.config.endpoint.v3.LbEndpoint.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.endpoint.v3.LbEndpoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.endpoint.v3.LbEndpoint}
 */
proto.envoy.config.endpoint.v3.LbEndpoint.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.envoy.config.endpoint.v3.Endpoint();
          reader.readMessage(
            value,
            proto.envoy.config.endpoint.v3.Endpoint.deserializeBinaryFromReader
          );
          msg.setEndpoint(value);
          break;
        case 5:
          var value = /** @type {string} */ (reader.readString());
          msg.setEndpointName(value);
          break;
        case 2:
          var value = /** @type {!proto.envoy.config.core.v3.HealthStatus} */ (
            reader.readEnum()
          );
          msg.setHealthStatus(value);
          break;
        case 3:
          var value = new envoy_config_core_v3_base_pb.Metadata();
          reader.readMessage(
            value,
            envoy_config_core_v3_base_pb.Metadata.deserializeBinaryFromReader
          );
          msg.setMetadata(value);
          break;
        case 4:
          var value = new google_protobuf_wrappers_pb.UInt32Value();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
          );
          msg.setLoadBalancingWeight(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.endpoint.v3.LbEndpoint.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.endpoint.v3.LbEndpoint.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.endpoint.v3.LbEndpoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.endpoint.v3.LbEndpoint.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getEndpoint();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.envoy.config.endpoint.v3.Endpoint.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(5, f);
  }
  f = message.getHealthStatus();
  if (f !== 0.0) {
    writer.writeEnum(2, f);
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      envoy_config_core_v3_base_pb.Metadata.serializeBinaryToWriter
    );
  }
  f = message.getLoadBalancingWeight();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
    );
  }
};

/**
 * optional Endpoint endpoint = 1;
 * @return {?proto.envoy.config.endpoint.v3.Endpoint}
 */
proto.envoy.config.endpoint.v3.LbEndpoint.prototype.getEndpoint = function () {
  return /** @type{?proto.envoy.config.endpoint.v3.Endpoint} */ (
    jspb.Message.getWrapperField(
      this,
      proto.envoy.config.endpoint.v3.Endpoint,
      1
    )
  );
};

/**
 * @param {?proto.envoy.config.endpoint.v3.Endpoint|undefined} value
 * @return {!proto.envoy.config.endpoint.v3.LbEndpoint} returns this
 */
proto.envoy.config.endpoint.v3.LbEndpoint.prototype.setEndpoint = function (
  value
) {
  return jspb.Message.setOneofWrapperField(
    this,
    1,
    proto.envoy.config.endpoint.v3.LbEndpoint.oneofGroups_[0],
    value
  );
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.endpoint.v3.LbEndpoint} returns this
 */
proto.envoy.config.endpoint.v3.LbEndpoint.prototype.clearEndpoint =
  function () {
    return this.setEndpoint(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.endpoint.v3.LbEndpoint.prototype.hasEndpoint = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional string endpoint_name = 5;
 * @return {string}
 */
proto.envoy.config.endpoint.v3.LbEndpoint.prototype.getEndpointName =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 5, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.config.endpoint.v3.LbEndpoint} returns this
 */
proto.envoy.config.endpoint.v3.LbEndpoint.prototype.setEndpointName = function (
  value
) {
  return jspb.Message.setOneofField(
    this,
    5,
    proto.envoy.config.endpoint.v3.LbEndpoint.oneofGroups_[0],
    value
  );
};

/**
 * Clears the field making it undefined.
 * @return {!proto.envoy.config.endpoint.v3.LbEndpoint} returns this
 */
proto.envoy.config.endpoint.v3.LbEndpoint.prototype.clearEndpointName =
  function () {
    return jspb.Message.setOneofField(
      this,
      5,
      proto.envoy.config.endpoint.v3.LbEndpoint.oneofGroups_[0],
      undefined
    );
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.endpoint.v3.LbEndpoint.prototype.hasEndpointName =
  function () {
    return jspb.Message.getField(this, 5) != null;
  };

/**
 * optional envoy.config.core.v3.HealthStatus health_status = 2;
 * @return {!proto.envoy.config.core.v3.HealthStatus}
 */
proto.envoy.config.endpoint.v3.LbEndpoint.prototype.getHealthStatus =
  function () {
    return /** @type {!proto.envoy.config.core.v3.HealthStatus} */ (
      jspb.Message.getFieldWithDefault(this, 2, 0)
    );
  };

/**
 * @param {!proto.envoy.config.core.v3.HealthStatus} value
 * @return {!proto.envoy.config.endpoint.v3.LbEndpoint} returns this
 */
proto.envoy.config.endpoint.v3.LbEndpoint.prototype.setHealthStatus = function (
  value
) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};

/**
 * optional envoy.config.core.v3.Metadata metadata = 3;
 * @return {?proto.envoy.config.core.v3.Metadata}
 */
proto.envoy.config.endpoint.v3.LbEndpoint.prototype.getMetadata = function () {
  return /** @type{?proto.envoy.config.core.v3.Metadata} */ (
    jspb.Message.getWrapperField(this, envoy_config_core_v3_base_pb.Metadata, 3)
  );
};

/**
 * @param {?proto.envoy.config.core.v3.Metadata|undefined} value
 * @return {!proto.envoy.config.endpoint.v3.LbEndpoint} returns this
 */
proto.envoy.config.endpoint.v3.LbEndpoint.prototype.setMetadata = function (
  value
) {
  return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.endpoint.v3.LbEndpoint} returns this
 */
proto.envoy.config.endpoint.v3.LbEndpoint.prototype.clearMetadata =
  function () {
    return this.setMetadata(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.endpoint.v3.LbEndpoint.prototype.hasMetadata = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional google.protobuf.UInt32Value load_balancing_weight = 4;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.endpoint.v3.LbEndpoint.prototype.getLoadBalancingWeight =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        4
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.endpoint.v3.LbEndpoint} returns this
 */
proto.envoy.config.endpoint.v3.LbEndpoint.prototype.setLoadBalancingWeight =
  function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.endpoint.v3.LbEndpoint} returns this
 */
proto.envoy.config.endpoint.v3.LbEndpoint.prototype.clearLoadBalancingWeight =
  function () {
    return this.setLoadBalancingWeight(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.endpoint.v3.LbEndpoint.prototype.hasLoadBalancingWeight =
  function () {
    return jspb.Message.getField(this, 4) != null;
  };

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.envoy.config.endpoint.v3.LocalityLbEndpoints.repeatedFields_ = [2];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.endpoint.v3.LocalityLbEndpoints.prototype.toObject =
    function (opt_includeInstance) {
      return proto.envoy.config.endpoint.v3.LocalityLbEndpoints.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.endpoint.v3.LocalityLbEndpoints} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.endpoint.v3.LocalityLbEndpoints.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        locality:
          (f = msg.getLocality()) &&
          envoy_config_core_v3_base_pb.Locality.toObject(includeInstance, f),
        lbEndpointsList: jspb.Message.toObjectList(
          msg.getLbEndpointsList(),
          proto.envoy.config.endpoint.v3.LbEndpoint.toObject,
          includeInstance
        ),
        loadBalancingWeight:
          (f = msg.getLoadBalancingWeight()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
        priority: jspb.Message.getFieldWithDefault(msg, 5, 0),
        proximity:
          (f = msg.getProximity()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.endpoint.v3.LocalityLbEndpoints}
 */
proto.envoy.config.endpoint.v3.LocalityLbEndpoints.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.envoy.config.endpoint.v3.LocalityLbEndpoints();
    return proto.envoy.config.endpoint.v3.LocalityLbEndpoints.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.endpoint.v3.LocalityLbEndpoints} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.endpoint.v3.LocalityLbEndpoints}
 */
proto.envoy.config.endpoint.v3.LocalityLbEndpoints.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new envoy_config_core_v3_base_pb.Locality();
          reader.readMessage(
            value,
            envoy_config_core_v3_base_pb.Locality.deserializeBinaryFromReader
          );
          msg.setLocality(value);
          break;
        case 2:
          var value = new proto.envoy.config.endpoint.v3.LbEndpoint();
          reader.readMessage(
            value,
            proto.envoy.config.endpoint.v3.LbEndpoint
              .deserializeBinaryFromReader
          );
          msg.addLbEndpoints(value);
          break;
        case 3:
          var value = new google_protobuf_wrappers_pb.UInt32Value();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
          );
          msg.setLoadBalancingWeight(value);
          break;
        case 5:
          var value = /** @type {number} */ (reader.readUint32());
          msg.setPriority(value);
          break;
        case 6:
          var value = new google_protobuf_wrappers_pb.UInt32Value();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
          );
          msg.setProximity(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.endpoint.v3.LocalityLbEndpoints.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.endpoint.v3.LocalityLbEndpoints.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.endpoint.v3.LocalityLbEndpoints} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.endpoint.v3.LocalityLbEndpoints.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getLocality();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        envoy_config_core_v3_base_pb.Locality.serializeBinaryToWriter
      );
    }
    f = message.getLbEndpointsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        2,
        f,
        proto.envoy.config.endpoint.v3.LbEndpoint.serializeBinaryToWriter
      );
    }
    f = message.getLoadBalancingWeight();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
      );
    }
    f = message.getPriority();
    if (f !== 0) {
      writer.writeUint32(5, f);
    }
    f = message.getProximity();
    if (f != null) {
      writer.writeMessage(
        6,
        f,
        google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
      );
    }
  };

/**
 * optional envoy.config.core.v3.Locality locality = 1;
 * @return {?proto.envoy.config.core.v3.Locality}
 */
proto.envoy.config.endpoint.v3.LocalityLbEndpoints.prototype.getLocality =
  function () {
    return /** @type{?proto.envoy.config.core.v3.Locality} */ (
      jspb.Message.getWrapperField(
        this,
        envoy_config_core_v3_base_pb.Locality,
        1
      )
    );
  };

/**
 * @param {?proto.envoy.config.core.v3.Locality|undefined} value
 * @return {!proto.envoy.config.endpoint.v3.LocalityLbEndpoints} returns this
 */
proto.envoy.config.endpoint.v3.LocalityLbEndpoints.prototype.setLocality =
  function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.endpoint.v3.LocalityLbEndpoints} returns this
 */
proto.envoy.config.endpoint.v3.LocalityLbEndpoints.prototype.clearLocality =
  function () {
    return this.setLocality(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.endpoint.v3.LocalityLbEndpoints.prototype.hasLocality =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

/**
 * repeated LbEndpoint lb_endpoints = 2;
 * @return {!Array<!proto.envoy.config.endpoint.v3.LbEndpoint>}
 */
proto.envoy.config.endpoint.v3.LocalityLbEndpoints.prototype.getLbEndpointsList =
  function () {
    return /** @type{!Array<!proto.envoy.config.endpoint.v3.LbEndpoint>} */ (
      jspb.Message.getRepeatedWrapperField(
        this,
        proto.envoy.config.endpoint.v3.LbEndpoint,
        2
      )
    );
  };

/**
 * @param {!Array<!proto.envoy.config.endpoint.v3.LbEndpoint>} value
 * @return {!proto.envoy.config.endpoint.v3.LocalityLbEndpoints} returns this
 */
proto.envoy.config.endpoint.v3.LocalityLbEndpoints.prototype.setLbEndpointsList =
  function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 2, value);
  };

/**
 * @param {!proto.envoy.config.endpoint.v3.LbEndpoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.envoy.config.endpoint.v3.LbEndpoint}
 */
proto.envoy.config.endpoint.v3.LocalityLbEndpoints.prototype.addLbEndpoints =
  function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(
      this,
      2,
      opt_value,
      proto.envoy.config.endpoint.v3.LbEndpoint,
      opt_index
    );
  };

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.envoy.config.endpoint.v3.LocalityLbEndpoints} returns this
 */
proto.envoy.config.endpoint.v3.LocalityLbEndpoints.prototype.clearLbEndpointsList =
  function () {
    return this.setLbEndpointsList([]);
  };

/**
 * optional google.protobuf.UInt32Value load_balancing_weight = 3;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.endpoint.v3.LocalityLbEndpoints.prototype.getLoadBalancingWeight =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        3
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.endpoint.v3.LocalityLbEndpoints} returns this
 */
proto.envoy.config.endpoint.v3.LocalityLbEndpoints.prototype.setLoadBalancingWeight =
  function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.endpoint.v3.LocalityLbEndpoints} returns this
 */
proto.envoy.config.endpoint.v3.LocalityLbEndpoints.prototype.clearLoadBalancingWeight =
  function () {
    return this.setLoadBalancingWeight(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.endpoint.v3.LocalityLbEndpoints.prototype.hasLoadBalancingWeight =
  function () {
    return jspb.Message.getField(this, 3) != null;
  };

/**
 * optional uint32 priority = 5;
 * @return {number}
 */
proto.envoy.config.endpoint.v3.LocalityLbEndpoints.prototype.getPriority =
  function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };

/**
 * @param {number} value
 * @return {!proto.envoy.config.endpoint.v3.LocalityLbEndpoints} returns this
 */
proto.envoy.config.endpoint.v3.LocalityLbEndpoints.prototype.setPriority =
  function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
  };

/**
 * optional google.protobuf.UInt32Value proximity = 6;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.endpoint.v3.LocalityLbEndpoints.prototype.getProximity =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        6
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.endpoint.v3.LocalityLbEndpoints} returns this
 */
proto.envoy.config.endpoint.v3.LocalityLbEndpoints.prototype.setProximity =
  function (value) {
    return jspb.Message.setWrapperField(this, 6, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.endpoint.v3.LocalityLbEndpoints} returns this
 */
proto.envoy.config.endpoint.v3.LocalityLbEndpoints.prototype.clearProximity =
  function () {
    return this.setProximity(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.endpoint.v3.LocalityLbEndpoints.prototype.hasProximity =
  function () {
    return jspb.Message.getField(this, 6) != null;
  };

goog.object.extend(exports, proto.envoy.config.endpoint.v3);
