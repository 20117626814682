// source: envoy/config/core/v3/health_check.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var envoy_config_core_v3_base_pb = require('../../../../envoy/config/core/v3/base_pb.js');
goog.object.extend(proto, envoy_config_core_v3_base_pb);
var envoy_config_core_v3_event_service_config_pb = require('../../../../envoy/config/core/v3/event_service_config_pb.js');
goog.object.extend(proto, envoy_config_core_v3_event_service_config_pb);
var envoy_type_matcher_v3_string_pb = require('../../../../envoy/type/matcher/v3/string_pb.js');
goog.object.extend(proto, envoy_type_matcher_v3_string_pb);
var envoy_type_v3_http_pb = require('../../../../envoy/type/v3/http_pb.js');
goog.object.extend(proto, envoy_type_v3_http_pb);
var envoy_type_v3_range_pb = require('../../../../envoy/type/v3/range_pb.js');
goog.object.extend(proto, envoy_type_v3_range_pb);
var google_protobuf_any_pb = require('google-protobuf/google/protobuf/any_pb.js');
goog.object.extend(proto, google_protobuf_any_pb);
var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js');
goog.object.extend(proto, google_protobuf_duration_pb);
var google_protobuf_struct_pb = require('google-protobuf/google/protobuf/struct_pb.js');
goog.object.extend(proto, google_protobuf_struct_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var envoy_annotations_deprecation_pb = require('../../../../envoy/annotations/deprecation_pb.js');
goog.object.extend(proto, envoy_annotations_deprecation_pb);
var udpa_annotations_status_pb = require('../../../../udpa/annotations/status_pb.js');
goog.object.extend(proto, udpa_annotations_status_pb);
var udpa_annotations_versioning_pb = require('../../../../udpa/annotations/versioning_pb.js');
goog.object.extend(proto, udpa_annotations_versioning_pb);
var validate_validate_pb = require('../../../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.envoy.config.core.v3.HealthCheck', null, global);
goog.exportSymbol(
  'proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck.ConfigTypeCase',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.core.v3.HealthCheck.GrpcHealthCheck',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.core.v3.HealthCheck.HealthCheckerCase',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.core.v3.HealthCheck.Payload',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.core.v3.HealthCheck.Payload.PayloadCase',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.core.v3.HealthCheck.RedisHealthCheck',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.core.v3.HealthCheck.TcpHealthCheck',
  null,
  global
);
goog.exportSymbol(
  'proto.envoy.config.core.v3.HealthCheck.TlsOptions',
  null,
  global
);
goog.exportSymbol('proto.envoy.config.core.v3.HealthStatus', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.HealthCheck = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    null,
    proto.envoy.config.core.v3.HealthCheck.oneofGroups_
  );
};
goog.inherits(proto.envoy.config.core.v3.HealthCheck, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.HealthCheck.displayName =
    'proto.envoy.config.core.v3.HealthCheck';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.HealthCheck.Payload = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    null,
    proto.envoy.config.core.v3.HealthCheck.Payload.oneofGroups_
  );
};
goog.inherits(proto.envoy.config.core.v3.HealthCheck.Payload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.HealthCheck.Payload.displayName =
    'proto.envoy.config.core.v3.HealthCheck.Payload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.repeatedFields_,
    null
  );
};
goog.inherits(
  proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.displayName =
    'proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.HealthCheck.TcpHealthCheck = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.envoy.config.core.v3.HealthCheck.TcpHealthCheck.repeatedFields_,
    null
  );
};
goog.inherits(
  proto.envoy.config.core.v3.HealthCheck.TcpHealthCheck,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.HealthCheck.TcpHealthCheck.displayName =
    'proto.envoy.config.core.v3.HealthCheck.TcpHealthCheck';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.HealthCheck.RedisHealthCheck = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(
  proto.envoy.config.core.v3.HealthCheck.RedisHealthCheck,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.HealthCheck.RedisHealthCheck.displayName =
    'proto.envoy.config.core.v3.HealthCheck.RedisHealthCheck';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.HealthCheck.GrpcHealthCheck = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(
  proto.envoy.config.core.v3.HealthCheck.GrpcHealthCheck,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.HealthCheck.GrpcHealthCheck.displayName =
    'proto.envoy.config.core.v3.HealthCheck.GrpcHealthCheck';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    null,
    proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck.oneofGroups_
  );
};
goog.inherits(
  proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck,
  jspb.Message
);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck.displayName =
    'proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.envoy.config.core.v3.HealthCheck.TlsOptions = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.envoy.config.core.v3.HealthCheck.TlsOptions.repeatedFields_,
    null
  );
};
goog.inherits(proto.envoy.config.core.v3.HealthCheck.TlsOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.envoy.config.core.v3.HealthCheck.TlsOptions.displayName =
    'proto.envoy.config.core.v3.HealthCheck.TlsOptions';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.envoy.config.core.v3.HealthCheck.oneofGroups_ = [[8, 9, 11, 13]];

/**
 * @enum {number}
 */
proto.envoy.config.core.v3.HealthCheck.HealthCheckerCase = {
  HEALTH_CHECKER_NOT_SET: 0,
  HTTP_HEALTH_CHECK: 8,
  TCP_HEALTH_CHECK: 9,
  GRPC_HEALTH_CHECK: 11,
  CUSTOM_HEALTH_CHECK: 13
};

/**
 * @return {proto.envoy.config.core.v3.HealthCheck.HealthCheckerCase}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.getHealthCheckerCase =
  function () {
    return /** @type {proto.envoy.config.core.v3.HealthCheck.HealthCheckerCase} */ (
      jspb.Message.computeOneofCase(
        this,
        proto.envoy.config.core.v3.HealthCheck.oneofGroups_[0]
      )
    );
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.HealthCheck.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.config.core.v3.HealthCheck.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.HealthCheck} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.HealthCheck.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        timeout:
          (f = msg.getTimeout()) &&
          google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
        interval:
          (f = msg.getInterval()) &&
          google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
        initialJitter:
          (f = msg.getInitialJitter()) &&
          google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
        intervalJitter:
          (f = msg.getIntervalJitter()) &&
          google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
        intervalJitterPercent: jspb.Message.getFieldWithDefault(msg, 18, 0),
        unhealthyThreshold:
          (f = msg.getUnhealthyThreshold()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
        healthyThreshold:
          (f = msg.getHealthyThreshold()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
        altPort:
          (f = msg.getAltPort()) &&
          google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
        reuseConnection:
          (f = msg.getReuseConnection()) &&
          google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
        httpHealthCheck:
          (f = msg.getHttpHealthCheck()) &&
          proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.toObject(
            includeInstance,
            f
          ),
        tcpHealthCheck:
          (f = msg.getTcpHealthCheck()) &&
          proto.envoy.config.core.v3.HealthCheck.TcpHealthCheck.toObject(
            includeInstance,
            f
          ),
        grpcHealthCheck:
          (f = msg.getGrpcHealthCheck()) &&
          proto.envoy.config.core.v3.HealthCheck.GrpcHealthCheck.toObject(
            includeInstance,
            f
          ),
        customHealthCheck:
          (f = msg.getCustomHealthCheck()) &&
          proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck.toObject(
            includeInstance,
            f
          ),
        noTrafficInterval:
          (f = msg.getNoTrafficInterval()) &&
          google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
        noTrafficHealthyInterval:
          (f = msg.getNoTrafficHealthyInterval()) &&
          google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
        unhealthyInterval:
          (f = msg.getUnhealthyInterval()) &&
          google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
        unhealthyEdgeInterval:
          (f = msg.getUnhealthyEdgeInterval()) &&
          google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
        healthyEdgeInterval:
          (f = msg.getHealthyEdgeInterval()) &&
          google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
        eventLogPath: jspb.Message.getFieldWithDefault(msg, 17, ''),
        eventService:
          (f = msg.getEventService()) &&
          envoy_config_core_v3_event_service_config_pb.EventServiceConfig.toObject(
            includeInstance,
            f
          ),
        alwaysLogHealthCheckFailures: jspb.Message.getBooleanFieldWithDefault(
          msg,
          19,
          false
        ),
        tlsOptions:
          (f = msg.getTlsOptions()) &&
          proto.envoy.config.core.v3.HealthCheck.TlsOptions.toObject(
            includeInstance,
            f
          ),
        transportSocketMatchCriteria:
          (f = msg.getTransportSocketMatchCriteria()) &&
          google_protobuf_struct_pb.Struct.toObject(includeInstance, f)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.HealthCheck}
 */
proto.envoy.config.core.v3.HealthCheck.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.HealthCheck();
  return proto.envoy.config.core.v3.HealthCheck.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.HealthCheck} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.HealthCheck}
 */
proto.envoy.config.core.v3.HealthCheck.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new google_protobuf_duration_pb.Duration();
        reader.readMessage(
          value,
          google_protobuf_duration_pb.Duration.deserializeBinaryFromReader
        );
        msg.setTimeout(value);
        break;
      case 2:
        var value = new google_protobuf_duration_pb.Duration();
        reader.readMessage(
          value,
          google_protobuf_duration_pb.Duration.deserializeBinaryFromReader
        );
        msg.setInterval(value);
        break;
      case 20:
        var value = new google_protobuf_duration_pb.Duration();
        reader.readMessage(
          value,
          google_protobuf_duration_pb.Duration.deserializeBinaryFromReader
        );
        msg.setInitialJitter(value);
        break;
      case 3:
        var value = new google_protobuf_duration_pb.Duration();
        reader.readMessage(
          value,
          google_protobuf_duration_pb.Duration.deserializeBinaryFromReader
        );
        msg.setIntervalJitter(value);
        break;
      case 18:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setIntervalJitterPercent(value);
        break;
      case 4:
        var value = new google_protobuf_wrappers_pb.UInt32Value();
        reader.readMessage(
          value,
          google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
        );
        msg.setUnhealthyThreshold(value);
        break;
      case 5:
        var value = new google_protobuf_wrappers_pb.UInt32Value();
        reader.readMessage(
          value,
          google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
        );
        msg.setHealthyThreshold(value);
        break;
      case 6:
        var value = new google_protobuf_wrappers_pb.UInt32Value();
        reader.readMessage(
          value,
          google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader
        );
        msg.setAltPort(value);
        break;
      case 7:
        var value = new google_protobuf_wrappers_pb.BoolValue();
        reader.readMessage(
          value,
          google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader
        );
        msg.setReuseConnection(value);
        break;
      case 8:
        var value =
          new proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck();
        reader.readMessage(
          value,
          proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck
            .deserializeBinaryFromReader
        );
        msg.setHttpHealthCheck(value);
        break;
      case 9:
        var value = new proto.envoy.config.core.v3.HealthCheck.TcpHealthCheck();
        reader.readMessage(
          value,
          proto.envoy.config.core.v3.HealthCheck.TcpHealthCheck
            .deserializeBinaryFromReader
        );
        msg.setTcpHealthCheck(value);
        break;
      case 11:
        var value =
          new proto.envoy.config.core.v3.HealthCheck.GrpcHealthCheck();
        reader.readMessage(
          value,
          proto.envoy.config.core.v3.HealthCheck.GrpcHealthCheck
            .deserializeBinaryFromReader
        );
        msg.setGrpcHealthCheck(value);
        break;
      case 13:
        var value =
          new proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck();
        reader.readMessage(
          value,
          proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck
            .deserializeBinaryFromReader
        );
        msg.setCustomHealthCheck(value);
        break;
      case 12:
        var value = new google_protobuf_duration_pb.Duration();
        reader.readMessage(
          value,
          google_protobuf_duration_pb.Duration.deserializeBinaryFromReader
        );
        msg.setNoTrafficInterval(value);
        break;
      case 24:
        var value = new google_protobuf_duration_pb.Duration();
        reader.readMessage(
          value,
          google_protobuf_duration_pb.Duration.deserializeBinaryFromReader
        );
        msg.setNoTrafficHealthyInterval(value);
        break;
      case 14:
        var value = new google_protobuf_duration_pb.Duration();
        reader.readMessage(
          value,
          google_protobuf_duration_pb.Duration.deserializeBinaryFromReader
        );
        msg.setUnhealthyInterval(value);
        break;
      case 15:
        var value = new google_protobuf_duration_pb.Duration();
        reader.readMessage(
          value,
          google_protobuf_duration_pb.Duration.deserializeBinaryFromReader
        );
        msg.setUnhealthyEdgeInterval(value);
        break;
      case 16:
        var value = new google_protobuf_duration_pb.Duration();
        reader.readMessage(
          value,
          google_protobuf_duration_pb.Duration.deserializeBinaryFromReader
        );
        msg.setHealthyEdgeInterval(value);
        break;
      case 17:
        var value = /** @type {string} */ (reader.readString());
        msg.setEventLogPath(value);
        break;
      case 22:
        var value =
          new envoy_config_core_v3_event_service_config_pb.EventServiceConfig();
        reader.readMessage(
          value,
          envoy_config_core_v3_event_service_config_pb.EventServiceConfig
            .deserializeBinaryFromReader
        );
        msg.setEventService(value);
        break;
      case 19:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setAlwaysLogHealthCheckFailures(value);
        break;
      case 21:
        var value = new proto.envoy.config.core.v3.HealthCheck.TlsOptions();
        reader.readMessage(
          value,
          proto.envoy.config.core.v3.HealthCheck.TlsOptions
            .deserializeBinaryFromReader
        );
        msg.setTlsOptions(value);
        break;
      case 23:
        var value = new google_protobuf_struct_pb.Struct();
        reader.readMessage(
          value,
          google_protobuf_struct_pb.Struct.deserializeBinaryFromReader
        );
        msg.setTransportSocketMatchCriteria(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.envoy.config.core.v3.HealthCheck.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.HealthCheck} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.HealthCheck.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getTimeout();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getInterval();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getInitialJitter();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getIntervalJitter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getIntervalJitterPercent();
  if (f !== 0) {
    writer.writeUint32(18, f);
  }
  f = message.getUnhealthyThreshold();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getHealthyThreshold();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getAltPort();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getReuseConnection();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getHttpHealthCheck();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck
        .serializeBinaryToWriter
    );
  }
  f = message.getTcpHealthCheck();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.envoy.config.core.v3.HealthCheck.TcpHealthCheck
        .serializeBinaryToWriter
    );
  }
  f = message.getGrpcHealthCheck();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.envoy.config.core.v3.HealthCheck.GrpcHealthCheck
        .serializeBinaryToWriter
    );
  }
  f = message.getCustomHealthCheck();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck
        .serializeBinaryToWriter
    );
  }
  f = message.getNoTrafficInterval();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getNoTrafficHealthyInterval();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getUnhealthyInterval();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getUnhealthyEdgeInterval();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getHealthyEdgeInterval();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getEventLogPath();
  if (f.length > 0) {
    writer.writeString(17, f);
  }
  f = message.getEventService();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      envoy_config_core_v3_event_service_config_pb.EventServiceConfig
        .serializeBinaryToWriter
    );
  }
  f = message.getAlwaysLogHealthCheckFailures();
  if (f) {
    writer.writeBool(19, f);
  }
  f = message.getTlsOptions();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.envoy.config.core.v3.HealthCheck.TlsOptions.serializeBinaryToWriter
    );
  }
  f = message.getTransportSocketMatchCriteria();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
};

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.envoy.config.core.v3.HealthCheck.Payload.oneofGroups_ = [[1, 2]];

/**
 * @enum {number}
 */
proto.envoy.config.core.v3.HealthCheck.Payload.PayloadCase = {
  PAYLOAD_NOT_SET: 0,
  TEXT: 1,
  BINARY: 2
};

/**
 * @return {proto.envoy.config.core.v3.HealthCheck.Payload.PayloadCase}
 */
proto.envoy.config.core.v3.HealthCheck.Payload.prototype.getPayloadCase =
  function () {
    return /** @type {proto.envoy.config.core.v3.HealthCheck.Payload.PayloadCase} */ (
      jspb.Message.computeOneofCase(
        this,
        proto.envoy.config.core.v3.HealthCheck.Payload.oneofGroups_[0]
      )
    );
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.HealthCheck.Payload.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.envoy.config.core.v3.HealthCheck.Payload.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.HealthCheck.Payload} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.HealthCheck.Payload.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        text: jspb.Message.getFieldWithDefault(msg, 1, ''),
        binary: msg.getBinary_asB64()
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.HealthCheck.Payload}
 */
proto.envoy.config.core.v3.HealthCheck.Payload.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.HealthCheck.Payload();
  return proto.envoy.config.core.v3.HealthCheck.Payload.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.HealthCheck.Payload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.HealthCheck.Payload}
 */
proto.envoy.config.core.v3.HealthCheck.Payload.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setText(value);
          break;
        case 2:
          var value = /** @type {!Uint8Array} */ (reader.readBytes());
          msg.setBinary(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.HealthCheck.Payload.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.HealthCheck.Payload.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.HealthCheck.Payload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.HealthCheck.Payload.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = /** @type {string} */ (jspb.Message.getField(message, 1));
    if (f != null) {
      writer.writeString(1, f);
    }
    f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
    if (f != null) {
      writer.writeBytes(2, f);
    }
  };

/**
 * optional string text = 1;
 * @return {string}
 */
proto.envoy.config.core.v3.HealthCheck.Payload.prototype.getText = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.HealthCheck.Payload} returns this
 */
proto.envoy.config.core.v3.HealthCheck.Payload.prototype.setText = function (
  value
) {
  return jspb.Message.setOneofField(
    this,
    1,
    proto.envoy.config.core.v3.HealthCheck.Payload.oneofGroups_[0],
    value
  );
};

/**
 * Clears the field making it undefined.
 * @return {!proto.envoy.config.core.v3.HealthCheck.Payload} returns this
 */
proto.envoy.config.core.v3.HealthCheck.Payload.prototype.clearText =
  function () {
    return jspb.Message.setOneofField(
      this,
      1,
      proto.envoy.config.core.v3.HealthCheck.Payload.oneofGroups_[0],
      undefined
    );
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.HealthCheck.Payload.prototype.hasText = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional bytes binary = 2;
 * @return {string}
 */
proto.envoy.config.core.v3.HealthCheck.Payload.prototype.getBinary =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 2, '')
    );
  };

/**
 * optional bytes binary = 2;
 * This is a type-conversion wrapper around `getBinary()`
 * @return {string}
 */
proto.envoy.config.core.v3.HealthCheck.Payload.prototype.getBinary_asB64 =
  function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getBinary()));
  };

/**
 * optional bytes binary = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getBinary()`
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.HealthCheck.Payload.prototype.getBinary_asU8 =
  function () {
    return /** @type {!Uint8Array} */ (
      jspb.Message.bytesAsU8(this.getBinary())
    );
  };

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.envoy.config.core.v3.HealthCheck.Payload} returns this
 */
proto.envoy.config.core.v3.HealthCheck.Payload.prototype.setBinary = function (
  value
) {
  return jspb.Message.setOneofField(
    this,
    2,
    proto.envoy.config.core.v3.HealthCheck.Payload.oneofGroups_[0],
    value
  );
};

/**
 * Clears the field making it undefined.
 * @return {!proto.envoy.config.core.v3.HealthCheck.Payload} returns this
 */
proto.envoy.config.core.v3.HealthCheck.Payload.prototype.clearBinary =
  function () {
    return jspb.Message.setOneofField(
      this,
      2,
      proto.envoy.config.core.v3.HealthCheck.Payload.oneofGroups_[0],
      undefined
    );
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.HealthCheck.Payload.prototype.hasBinary =
  function () {
    return jspb.Message.getField(this, 2) != null;
  };

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.repeatedFields_ = [
  6, 8, 9
];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.prototype.toObject =
    function (opt_includeInstance) {
      return proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        host: jspb.Message.getFieldWithDefault(msg, 1, ''),
        path: jspb.Message.getFieldWithDefault(msg, 2, ''),
        send:
          (f = msg.getSend()) &&
          proto.envoy.config.core.v3.HealthCheck.Payload.toObject(
            includeInstance,
            f
          ),
        receive:
          (f = msg.getReceive()) &&
          proto.envoy.config.core.v3.HealthCheck.Payload.toObject(
            includeInstance,
            f
          ),
        requestHeadersToAddList: jspb.Message.toObjectList(
          msg.getRequestHeadersToAddList(),
          envoy_config_core_v3_base_pb.HeaderValueOption.toObject,
          includeInstance
        ),
        requestHeadersToRemoveList:
          (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
        expectedStatusesList: jspb.Message.toObjectList(
          msg.getExpectedStatusesList(),
          envoy_type_v3_range_pb.Int64Range.toObject,
          includeInstance
        ),
        codecClientType: jspb.Message.getFieldWithDefault(msg, 10, 0),
        serviceNameMatcher:
          (f = msg.getServiceNameMatcher()) &&
          envoy_type_matcher_v3_string_pb.StringMatcher.toObject(
            includeInstance,
            f
          )
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck}
 */
proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck();
    return proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck}
 */
proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setHost(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setPath(value);
          break;
        case 3:
          var value = new proto.envoy.config.core.v3.HealthCheck.Payload();
          reader.readMessage(
            value,
            proto.envoy.config.core.v3.HealthCheck.Payload
              .deserializeBinaryFromReader
          );
          msg.setSend(value);
          break;
        case 4:
          var value = new proto.envoy.config.core.v3.HealthCheck.Payload();
          reader.readMessage(
            value,
            proto.envoy.config.core.v3.HealthCheck.Payload
              .deserializeBinaryFromReader
          );
          msg.setReceive(value);
          break;
        case 6:
          var value = new envoy_config_core_v3_base_pb.HeaderValueOption();
          reader.readMessage(
            value,
            envoy_config_core_v3_base_pb.HeaderValueOption
              .deserializeBinaryFromReader
          );
          msg.addRequestHeadersToAdd(value);
          break;
        case 8:
          var value = /** @type {string} */ (reader.readString());
          msg.addRequestHeadersToRemove(value);
          break;
        case 9:
          var value = new envoy_type_v3_range_pb.Int64Range();
          reader.readMessage(
            value,
            envoy_type_v3_range_pb.Int64Range.deserializeBinaryFromReader
          );
          msg.addExpectedStatuses(value);
          break;
        case 10:
          var value = /** @type {!proto.envoy.type.v3.CodecClientType} */ (
            reader.readEnum()
          );
          msg.setCodecClientType(value);
          break;
        case 11:
          var value = new envoy_type_matcher_v3_string_pb.StringMatcher();
          reader.readMessage(
            value,
            envoy_type_matcher_v3_string_pb.StringMatcher
              .deserializeBinaryFromReader
          );
          msg.setServiceNameMatcher(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getHost();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
    f = message.getPath();
    if (f.length > 0) {
      writer.writeString(2, f);
    }
    f = message.getSend();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        proto.envoy.config.core.v3.HealthCheck.Payload.serializeBinaryToWriter
      );
    }
    f = message.getReceive();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        proto.envoy.config.core.v3.HealthCheck.Payload.serializeBinaryToWriter
      );
    }
    f = message.getRequestHeadersToAddList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        6,
        f,
        envoy_config_core_v3_base_pb.HeaderValueOption.serializeBinaryToWriter
      );
    }
    f = message.getRequestHeadersToRemoveList();
    if (f.length > 0) {
      writer.writeRepeatedString(8, f);
    }
    f = message.getExpectedStatusesList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        9,
        f,
        envoy_type_v3_range_pb.Int64Range.serializeBinaryToWriter
      );
    }
    f = message.getCodecClientType();
    if (f !== 0.0) {
      writer.writeEnum(10, f);
    }
    f = message.getServiceNameMatcher();
    if (f != null) {
      writer.writeMessage(
        11,
        f,
        envoy_type_matcher_v3_string_pb.StringMatcher.serializeBinaryToWriter
      );
    }
  };

/**
 * optional string host = 1;
 * @return {string}
 */
proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.prototype.getHost =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.prototype.setHost =
  function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
  };

/**
 * optional string path = 2;
 * @return {string}
 */
proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.prototype.getPath =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 2, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.prototype.setPath =
  function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
  };

/**
 * optional Payload send = 3;
 * @return {?proto.envoy.config.core.v3.HealthCheck.Payload}
 */
proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.prototype.getSend =
  function () {
    return /** @type{?proto.envoy.config.core.v3.HealthCheck.Payload} */ (
      jspb.Message.getWrapperField(
        this,
        proto.envoy.config.core.v3.HealthCheck.Payload,
        3
      )
    );
  };

/**
 * @param {?proto.envoy.config.core.v3.HealthCheck.Payload|undefined} value
 * @return {!proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.prototype.setSend =
  function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.prototype.clearSend =
  function () {
    return this.setSend(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.prototype.hasSend =
  function () {
    return jspb.Message.getField(this, 3) != null;
  };

/**
 * optional Payload receive = 4;
 * @return {?proto.envoy.config.core.v3.HealthCheck.Payload}
 */
proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.prototype.getReceive =
  function () {
    return /** @type{?proto.envoy.config.core.v3.HealthCheck.Payload} */ (
      jspb.Message.getWrapperField(
        this,
        proto.envoy.config.core.v3.HealthCheck.Payload,
        4
      )
    );
  };

/**
 * @param {?proto.envoy.config.core.v3.HealthCheck.Payload|undefined} value
 * @return {!proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.prototype.setReceive =
  function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.prototype.clearReceive =
  function () {
    return this.setReceive(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.prototype.hasReceive =
  function () {
    return jspb.Message.getField(this, 4) != null;
  };

/**
 * repeated HeaderValueOption request_headers_to_add = 6;
 * @return {!Array<!proto.envoy.config.core.v3.HeaderValueOption>}
 */
proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.prototype.getRequestHeadersToAddList =
  function () {
    return /** @type{!Array<!proto.envoy.config.core.v3.HeaderValueOption>} */ (
      jspb.Message.getRepeatedWrapperField(
        this,
        envoy_config_core_v3_base_pb.HeaderValueOption,
        6
      )
    );
  };

/**
 * @param {!Array<!proto.envoy.config.core.v3.HeaderValueOption>} value
 * @return {!proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.prototype.setRequestHeadersToAddList =
  function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 6, value);
  };

/**
 * @param {!proto.envoy.config.core.v3.HeaderValueOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.envoy.config.core.v3.HeaderValueOption}
 */
proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.prototype.addRequestHeadersToAdd =
  function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(
      this,
      6,
      opt_value,
      proto.envoy.config.core.v3.HeaderValueOption,
      opt_index
    );
  };

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.prototype.clearRequestHeadersToAddList =
  function () {
    return this.setRequestHeadersToAddList([]);
  };

/**
 * repeated string request_headers_to_remove = 8;
 * @return {!Array<string>}
 */
proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.prototype.getRequestHeadersToRemoveList =
  function () {
    return /** @type {!Array<string>} */ (
      jspb.Message.getRepeatedField(this, 8)
    );
  };

/**
 * @param {!Array<string>} value
 * @return {!proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.prototype.setRequestHeadersToRemoveList =
  function (value) {
    return jspb.Message.setField(this, 8, value || []);
  };

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.prototype.addRequestHeadersToRemove =
  function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
  };

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.prototype.clearRequestHeadersToRemoveList =
  function () {
    return this.setRequestHeadersToRemoveList([]);
  };

/**
 * repeated envoy.type.v3.Int64Range expected_statuses = 9;
 * @return {!Array<!proto.envoy.type.v3.Int64Range>}
 */
proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.prototype.getExpectedStatusesList =
  function () {
    return /** @type{!Array<!proto.envoy.type.v3.Int64Range>} */ (
      jspb.Message.getRepeatedWrapperField(
        this,
        envoy_type_v3_range_pb.Int64Range,
        9
      )
    );
  };

/**
 * @param {!Array<!proto.envoy.type.v3.Int64Range>} value
 * @return {!proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.prototype.setExpectedStatusesList =
  function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 9, value);
  };

/**
 * @param {!proto.envoy.type.v3.Int64Range=} opt_value
 * @param {number=} opt_index
 * @return {!proto.envoy.type.v3.Int64Range}
 */
proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.prototype.addExpectedStatuses =
  function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(
      this,
      9,
      opt_value,
      proto.envoy.type.v3.Int64Range,
      opt_index
    );
  };

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.prototype.clearExpectedStatusesList =
  function () {
    return this.setExpectedStatusesList([]);
  };

/**
 * optional envoy.type.v3.CodecClientType codec_client_type = 10;
 * @return {!proto.envoy.type.v3.CodecClientType}
 */
proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.prototype.getCodecClientType =
  function () {
    return /** @type {!proto.envoy.type.v3.CodecClientType} */ (
      jspb.Message.getFieldWithDefault(this, 10, 0)
    );
  };

/**
 * @param {!proto.envoy.type.v3.CodecClientType} value
 * @return {!proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.prototype.setCodecClientType =
  function (value) {
    return jspb.Message.setProto3EnumField(this, 10, value);
  };

/**
 * optional envoy.type.matcher.v3.StringMatcher service_name_matcher = 11;
 * @return {?proto.envoy.type.matcher.v3.StringMatcher}
 */
proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.prototype.getServiceNameMatcher =
  function () {
    return /** @type{?proto.envoy.type.matcher.v3.StringMatcher} */ (
      jspb.Message.getWrapperField(
        this,
        envoy_type_matcher_v3_string_pb.StringMatcher,
        11
      )
    );
  };

/**
 * @param {?proto.envoy.type.matcher.v3.StringMatcher|undefined} value
 * @return {!proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.prototype.setServiceNameMatcher =
  function (value) {
    return jspb.Message.setWrapperField(this, 11, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.prototype.clearServiceNameMatcher =
  function () {
    return this.setServiceNameMatcher(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck.prototype.hasServiceNameMatcher =
  function () {
    return jspb.Message.getField(this, 11) != null;
  };

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.envoy.config.core.v3.HealthCheck.TcpHealthCheck.repeatedFields_ = [2];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.HealthCheck.TcpHealthCheck.prototype.toObject =
    function (opt_includeInstance) {
      return proto.envoy.config.core.v3.HealthCheck.TcpHealthCheck.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.HealthCheck.TcpHealthCheck} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.HealthCheck.TcpHealthCheck.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        send:
          (f = msg.getSend()) &&
          proto.envoy.config.core.v3.HealthCheck.Payload.toObject(
            includeInstance,
            f
          ),
        receiveList: jspb.Message.toObjectList(
          msg.getReceiveList(),
          proto.envoy.config.core.v3.HealthCheck.Payload.toObject,
          includeInstance
        )
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.HealthCheck.TcpHealthCheck}
 */
proto.envoy.config.core.v3.HealthCheck.TcpHealthCheck.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.envoy.config.core.v3.HealthCheck.TcpHealthCheck();
    return proto.envoy.config.core.v3.HealthCheck.TcpHealthCheck.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.HealthCheck.TcpHealthCheck} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.HealthCheck.TcpHealthCheck}
 */
proto.envoy.config.core.v3.HealthCheck.TcpHealthCheck.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new proto.envoy.config.core.v3.HealthCheck.Payload();
          reader.readMessage(
            value,
            proto.envoy.config.core.v3.HealthCheck.Payload
              .deserializeBinaryFromReader
          );
          msg.setSend(value);
          break;
        case 2:
          var value = new proto.envoy.config.core.v3.HealthCheck.Payload();
          reader.readMessage(
            value,
            proto.envoy.config.core.v3.HealthCheck.Payload
              .deserializeBinaryFromReader
          );
          msg.addReceive(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.HealthCheck.TcpHealthCheck.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.HealthCheck.TcpHealthCheck.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.HealthCheck.TcpHealthCheck} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.HealthCheck.TcpHealthCheck.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getSend();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        proto.envoy.config.core.v3.HealthCheck.Payload.serializeBinaryToWriter
      );
    }
    f = message.getReceiveList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        2,
        f,
        proto.envoy.config.core.v3.HealthCheck.Payload.serializeBinaryToWriter
      );
    }
  };

/**
 * optional Payload send = 1;
 * @return {?proto.envoy.config.core.v3.HealthCheck.Payload}
 */
proto.envoy.config.core.v3.HealthCheck.TcpHealthCheck.prototype.getSend =
  function () {
    return /** @type{?proto.envoy.config.core.v3.HealthCheck.Payload} */ (
      jspb.Message.getWrapperField(
        this,
        proto.envoy.config.core.v3.HealthCheck.Payload,
        1
      )
    );
  };

/**
 * @param {?proto.envoy.config.core.v3.HealthCheck.Payload|undefined} value
 * @return {!proto.envoy.config.core.v3.HealthCheck.TcpHealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.TcpHealthCheck.prototype.setSend =
  function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.HealthCheck.TcpHealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.TcpHealthCheck.prototype.clearSend =
  function () {
    return this.setSend(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.HealthCheck.TcpHealthCheck.prototype.hasSend =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

/**
 * repeated Payload receive = 2;
 * @return {!Array<!proto.envoy.config.core.v3.HealthCheck.Payload>}
 */
proto.envoy.config.core.v3.HealthCheck.TcpHealthCheck.prototype.getReceiveList =
  function () {
    return /** @type{!Array<!proto.envoy.config.core.v3.HealthCheck.Payload>} */ (
      jspb.Message.getRepeatedWrapperField(
        this,
        proto.envoy.config.core.v3.HealthCheck.Payload,
        2
      )
    );
  };

/**
 * @param {!Array<!proto.envoy.config.core.v3.HealthCheck.Payload>} value
 * @return {!proto.envoy.config.core.v3.HealthCheck.TcpHealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.TcpHealthCheck.prototype.setReceiveList =
  function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 2, value);
  };

/**
 * @param {!proto.envoy.config.core.v3.HealthCheck.Payload=} opt_value
 * @param {number=} opt_index
 * @return {!proto.envoy.config.core.v3.HealthCheck.Payload}
 */
proto.envoy.config.core.v3.HealthCheck.TcpHealthCheck.prototype.addReceive =
  function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(
      this,
      2,
      opt_value,
      proto.envoy.config.core.v3.HealthCheck.Payload,
      opt_index
    );
  };

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.envoy.config.core.v3.HealthCheck.TcpHealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.TcpHealthCheck.prototype.clearReceiveList =
  function () {
    return this.setReceiveList([]);
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.HealthCheck.RedisHealthCheck.prototype.toObject =
    function (opt_includeInstance) {
      return proto.envoy.config.core.v3.HealthCheck.RedisHealthCheck.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.HealthCheck.RedisHealthCheck} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.HealthCheck.RedisHealthCheck.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        key: jspb.Message.getFieldWithDefault(msg, 1, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.HealthCheck.RedisHealthCheck}
 */
proto.envoy.config.core.v3.HealthCheck.RedisHealthCheck.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.envoy.config.core.v3.HealthCheck.RedisHealthCheck();
    return proto.envoy.config.core.v3.HealthCheck.RedisHealthCheck.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.HealthCheck.RedisHealthCheck} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.HealthCheck.RedisHealthCheck}
 */
proto.envoy.config.core.v3.HealthCheck.RedisHealthCheck.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setKey(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.HealthCheck.RedisHealthCheck.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.HealthCheck.RedisHealthCheck.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.HealthCheck.RedisHealthCheck} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.HealthCheck.RedisHealthCheck.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getKey();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
  };

/**
 * optional string key = 1;
 * @return {string}
 */
proto.envoy.config.core.v3.HealthCheck.RedisHealthCheck.prototype.getKey =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.HealthCheck.RedisHealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.RedisHealthCheck.prototype.setKey =
  function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.HealthCheck.GrpcHealthCheck.prototype.toObject =
    function (opt_includeInstance) {
      return proto.envoy.config.core.v3.HealthCheck.GrpcHealthCheck.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.HealthCheck.GrpcHealthCheck} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.HealthCheck.GrpcHealthCheck.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        serviceName: jspb.Message.getFieldWithDefault(msg, 1, ''),
        authority: jspb.Message.getFieldWithDefault(msg, 2, '')
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.HealthCheck.GrpcHealthCheck}
 */
proto.envoy.config.core.v3.HealthCheck.GrpcHealthCheck.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.envoy.config.core.v3.HealthCheck.GrpcHealthCheck();
    return proto.envoy.config.core.v3.HealthCheck.GrpcHealthCheck.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.HealthCheck.GrpcHealthCheck} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.HealthCheck.GrpcHealthCheck}
 */
proto.envoy.config.core.v3.HealthCheck.GrpcHealthCheck.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setServiceName(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setAuthority(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.HealthCheck.GrpcHealthCheck.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.HealthCheck.GrpcHealthCheck.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.HealthCheck.GrpcHealthCheck} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.HealthCheck.GrpcHealthCheck.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getServiceName();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
    f = message.getAuthority();
    if (f.length > 0) {
      writer.writeString(2, f);
    }
  };

/**
 * optional string service_name = 1;
 * @return {string}
 */
proto.envoy.config.core.v3.HealthCheck.GrpcHealthCheck.prototype.getServiceName =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.HealthCheck.GrpcHealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.GrpcHealthCheck.prototype.setServiceName =
  function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
  };

/**
 * optional string authority = 2;
 * @return {string}
 */
proto.envoy.config.core.v3.HealthCheck.GrpcHealthCheck.prototype.getAuthority =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 2, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.HealthCheck.GrpcHealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.GrpcHealthCheck.prototype.setAuthority =
  function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
  };

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck.oneofGroups_ = [[3]];

/**
 * @enum {number}
 */
proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck.ConfigTypeCase = {
  CONFIG_TYPE_NOT_SET: 0,
  TYPED_CONFIG: 3
};

/**
 * @return {proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck.ConfigTypeCase}
 */
proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck.prototype.getConfigTypeCase =
  function () {
    return /** @type {proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck.ConfigTypeCase} */ (
      jspb.Message.computeOneofCase(
        this,
        proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck.oneofGroups_[0]
      )
    );
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck.prototype.toObject =
    function (opt_includeInstance) {
      return proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        name: jspb.Message.getFieldWithDefault(msg, 1, ''),
        typedConfig:
          (f = msg.getTypedConfig()) &&
          google_protobuf_any_pb.Any.toObject(includeInstance, f)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck}
 */
proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck.deserializeBinary =
  function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck();
    return proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck.deserializeBinaryFromReader(
      msg,
      reader
    );
  };

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck}
 */
proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.setName(value);
          break;
        case 3:
          var value = new google_protobuf_any_pb.Any();
          reader.readMessage(
            value,
            google_protobuf_any_pb.Any.deserializeBinaryFromReader
          );
          msg.setTypedConfig(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(1, f);
    }
    f = message.getTypedConfig();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        google_protobuf_any_pb.Any.serializeBinaryToWriter
      );
    }
  };

/**
 * optional string name = 1;
 * @return {string}
 */
proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck.prototype.getName =
  function () {
    return /** @type {string} */ (
      jspb.Message.getFieldWithDefault(this, 1, '')
    );
  };

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck.prototype.setName =
  function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
  };

/**
 * optional google.protobuf.Any typed_config = 3;
 * @return {?proto.google.protobuf.Any}
 */
proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck.prototype.getTypedConfig =
  function () {
    return /** @type{?proto.google.protobuf.Any} */ (
      jspb.Message.getWrapperField(this, google_protobuf_any_pb.Any, 3)
    );
  };

/**
 * @param {?proto.google.protobuf.Any|undefined} value
 * @return {!proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck.prototype.setTypedConfig =
  function (value) {
    return jspb.Message.setOneofWrapperField(
      this,
      3,
      proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck.oneofGroups_[0],
      value
    );
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck.prototype.clearTypedConfig =
  function () {
    return this.setTypedConfig(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck.prototype.hasTypedConfig =
  function () {
    return jspb.Message.getField(this, 3) != null;
  };

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.envoy.config.core.v3.HealthCheck.TlsOptions.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.envoy.config.core.v3.HealthCheck.TlsOptions.prototype.toObject =
    function (opt_includeInstance) {
      return proto.envoy.config.core.v3.HealthCheck.TlsOptions.toObject(
        opt_includeInstance,
        this
      );
    };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.envoy.config.core.v3.HealthCheck.TlsOptions} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.envoy.config.core.v3.HealthCheck.TlsOptions.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        alpnProtocolsList:
          (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.envoy.config.core.v3.HealthCheck.TlsOptions}
 */
proto.envoy.config.core.v3.HealthCheck.TlsOptions.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.envoy.config.core.v3.HealthCheck.TlsOptions();
  return proto.envoy.config.core.v3.HealthCheck.TlsOptions.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.envoy.config.core.v3.HealthCheck.TlsOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.envoy.config.core.v3.HealthCheck.TlsOptions}
 */
proto.envoy.config.core.v3.HealthCheck.TlsOptions.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString());
          msg.addAlpnProtocols(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.envoy.config.core.v3.HealthCheck.TlsOptions.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.envoy.config.core.v3.HealthCheck.TlsOptions.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.envoy.config.core.v3.HealthCheck.TlsOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.envoy.config.core.v3.HealthCheck.TlsOptions.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined;
    f = message.getAlpnProtocolsList();
    if (f.length > 0) {
      writer.writeRepeatedString(1, f);
    }
  };

/**
 * repeated string alpn_protocols = 1;
 * @return {!Array<string>}
 */
proto.envoy.config.core.v3.HealthCheck.TlsOptions.prototype.getAlpnProtocolsList =
  function () {
    return /** @type {!Array<string>} */ (
      jspb.Message.getRepeatedField(this, 1)
    );
  };

/**
 * @param {!Array<string>} value
 * @return {!proto.envoy.config.core.v3.HealthCheck.TlsOptions} returns this
 */
proto.envoy.config.core.v3.HealthCheck.TlsOptions.prototype.setAlpnProtocolsList =
  function (value) {
    return jspb.Message.setField(this, 1, value || []);
  };

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.envoy.config.core.v3.HealthCheck.TlsOptions} returns this
 */
proto.envoy.config.core.v3.HealthCheck.TlsOptions.prototype.addAlpnProtocols =
  function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
  };

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.envoy.config.core.v3.HealthCheck.TlsOptions} returns this
 */
proto.envoy.config.core.v3.HealthCheck.TlsOptions.prototype.clearAlpnProtocolsList =
  function () {
    return this.setAlpnProtocolsList([]);
  };

/**
 * optional google.protobuf.Duration timeout = 1;
 * @return {?proto.google.protobuf.Duration}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.getTimeout = function () {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 1)
  );
};

/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.setTimeout = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.clearTimeout = function () {
  return this.setTimeout(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.hasTimeout = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional google.protobuf.Duration interval = 2;
 * @return {?proto.google.protobuf.Duration}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.getInterval = function () {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 2)
  );
};

/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.setInterval = function (
  value
) {
  return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.clearInterval = function () {
  return this.setInterval(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.hasInterval = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional google.protobuf.Duration initial_jitter = 20;
 * @return {?proto.google.protobuf.Duration}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.getInitialJitter =
  function () {
    return /** @type{?proto.google.protobuf.Duration} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_duration_pb.Duration,
        20
      )
    );
  };

/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.setInitialJitter = function (
  value
) {
  return jspb.Message.setWrapperField(this, 20, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.clearInitialJitter =
  function () {
    return this.setInitialJitter(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.hasInitialJitter =
  function () {
    return jspb.Message.getField(this, 20) != null;
  };

/**
 * optional google.protobuf.Duration interval_jitter = 3;
 * @return {?proto.google.protobuf.Duration}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.getIntervalJitter =
  function () {
    return /** @type{?proto.google.protobuf.Duration} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_duration_pb.Duration,
        3
      )
    );
  };

/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.setIntervalJitter = function (
  value
) {
  return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.clearIntervalJitter =
  function () {
    return this.setIntervalJitter(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.hasIntervalJitter =
  function () {
    return jspb.Message.getField(this, 3) != null;
  };

/**
 * optional uint32 interval_jitter_percent = 18;
 * @return {number}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.getIntervalJitterPercent =
  function () {
    return /** @type {number} */ (
      jspb.Message.getFieldWithDefault(this, 18, 0)
    );
  };

/**
 * @param {number} value
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.setIntervalJitterPercent =
  function (value) {
    return jspb.Message.setProto3IntField(this, 18, value);
  };

/**
 * optional google.protobuf.UInt32Value unhealthy_threshold = 4;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.getUnhealthyThreshold =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        4
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.setUnhealthyThreshold =
  function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.clearUnhealthyThreshold =
  function () {
    return this.setUnhealthyThreshold(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.hasUnhealthyThreshold =
  function () {
    return jspb.Message.getField(this, 4) != null;
  };

/**
 * optional google.protobuf.UInt32Value healthy_threshold = 5;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.getHealthyThreshold =
  function () {
    return /** @type{?proto.google.protobuf.UInt32Value} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.UInt32Value,
        5
      )
    );
  };

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.setHealthyThreshold =
  function (value) {
    return jspb.Message.setWrapperField(this, 5, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.clearHealthyThreshold =
  function () {
    return this.setHealthyThreshold(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.hasHealthyThreshold =
  function () {
    return jspb.Message.getField(this, 5) != null;
  };

/**
 * optional google.protobuf.UInt32Value alt_port = 6;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.getAltPort = function () {
  return /** @type{?proto.google.protobuf.UInt32Value} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_wrappers_pb.UInt32Value,
      6
    )
  );
};

/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.setAltPort = function (value) {
  return jspb.Message.setWrapperField(this, 6, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.clearAltPort = function () {
  return this.setAltPort(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.hasAltPort = function () {
  return jspb.Message.getField(this, 6) != null;
};

/**
 * optional google.protobuf.BoolValue reuse_connection = 7;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.getReuseConnection =
  function () {
    return /** @type{?proto.google.protobuf.BoolValue} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.BoolValue,
        7
      )
    );
  };

/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.setReuseConnection = function (
  value
) {
  return jspb.Message.setWrapperField(this, 7, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.clearReuseConnection =
  function () {
    return this.setReuseConnection(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.hasReuseConnection =
  function () {
    return jspb.Message.getField(this, 7) != null;
  };

/**
 * optional HttpHealthCheck http_health_check = 8;
 * @return {?proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.getHttpHealthCheck =
  function () {
    return /** @type{?proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck} */ (
      jspb.Message.getWrapperField(
        this,
        proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck,
        8
      )
    );
  };

/**
 * @param {?proto.envoy.config.core.v3.HealthCheck.HttpHealthCheck|undefined} value
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.setHttpHealthCheck = function (
  value
) {
  return jspb.Message.setOneofWrapperField(
    this,
    8,
    proto.envoy.config.core.v3.HealthCheck.oneofGroups_[0],
    value
  );
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.clearHttpHealthCheck =
  function () {
    return this.setHttpHealthCheck(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.hasHttpHealthCheck =
  function () {
    return jspb.Message.getField(this, 8) != null;
  };

/**
 * optional TcpHealthCheck tcp_health_check = 9;
 * @return {?proto.envoy.config.core.v3.HealthCheck.TcpHealthCheck}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.getTcpHealthCheck =
  function () {
    return /** @type{?proto.envoy.config.core.v3.HealthCheck.TcpHealthCheck} */ (
      jspb.Message.getWrapperField(
        this,
        proto.envoy.config.core.v3.HealthCheck.TcpHealthCheck,
        9
      )
    );
  };

/**
 * @param {?proto.envoy.config.core.v3.HealthCheck.TcpHealthCheck|undefined} value
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.setTcpHealthCheck = function (
  value
) {
  return jspb.Message.setOneofWrapperField(
    this,
    9,
    proto.envoy.config.core.v3.HealthCheck.oneofGroups_[0],
    value
  );
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.clearTcpHealthCheck =
  function () {
    return this.setTcpHealthCheck(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.hasTcpHealthCheck =
  function () {
    return jspb.Message.getField(this, 9) != null;
  };

/**
 * optional GrpcHealthCheck grpc_health_check = 11;
 * @return {?proto.envoy.config.core.v3.HealthCheck.GrpcHealthCheck}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.getGrpcHealthCheck =
  function () {
    return /** @type{?proto.envoy.config.core.v3.HealthCheck.GrpcHealthCheck} */ (
      jspb.Message.getWrapperField(
        this,
        proto.envoy.config.core.v3.HealthCheck.GrpcHealthCheck,
        11
      )
    );
  };

/**
 * @param {?proto.envoy.config.core.v3.HealthCheck.GrpcHealthCheck|undefined} value
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.setGrpcHealthCheck = function (
  value
) {
  return jspb.Message.setOneofWrapperField(
    this,
    11,
    proto.envoy.config.core.v3.HealthCheck.oneofGroups_[0],
    value
  );
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.clearGrpcHealthCheck =
  function () {
    return this.setGrpcHealthCheck(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.hasGrpcHealthCheck =
  function () {
    return jspb.Message.getField(this, 11) != null;
  };

/**
 * optional CustomHealthCheck custom_health_check = 13;
 * @return {?proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.getCustomHealthCheck =
  function () {
    return /** @type{?proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck} */ (
      jspb.Message.getWrapperField(
        this,
        proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck,
        13
      )
    );
  };

/**
 * @param {?proto.envoy.config.core.v3.HealthCheck.CustomHealthCheck|undefined} value
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.setCustomHealthCheck =
  function (value) {
    return jspb.Message.setOneofWrapperField(
      this,
      13,
      proto.envoy.config.core.v3.HealthCheck.oneofGroups_[0],
      value
    );
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.clearCustomHealthCheck =
  function () {
    return this.setCustomHealthCheck(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.hasCustomHealthCheck =
  function () {
    return jspb.Message.getField(this, 13) != null;
  };

/**
 * optional google.protobuf.Duration no_traffic_interval = 12;
 * @return {?proto.google.protobuf.Duration}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.getNoTrafficInterval =
  function () {
    return /** @type{?proto.google.protobuf.Duration} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_duration_pb.Duration,
        12
      )
    );
  };

/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.setNoTrafficInterval =
  function (value) {
    return jspb.Message.setWrapperField(this, 12, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.clearNoTrafficInterval =
  function () {
    return this.setNoTrafficInterval(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.hasNoTrafficInterval =
  function () {
    return jspb.Message.getField(this, 12) != null;
  };

/**
 * optional google.protobuf.Duration no_traffic_healthy_interval = 24;
 * @return {?proto.google.protobuf.Duration}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.getNoTrafficHealthyInterval =
  function () {
    return /** @type{?proto.google.protobuf.Duration} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_duration_pb.Duration,
        24
      )
    );
  };

/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.setNoTrafficHealthyInterval =
  function (value) {
    return jspb.Message.setWrapperField(this, 24, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.clearNoTrafficHealthyInterval =
  function () {
    return this.setNoTrafficHealthyInterval(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.hasNoTrafficHealthyInterval =
  function () {
    return jspb.Message.getField(this, 24) != null;
  };

/**
 * optional google.protobuf.Duration unhealthy_interval = 14;
 * @return {?proto.google.protobuf.Duration}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.getUnhealthyInterval =
  function () {
    return /** @type{?proto.google.protobuf.Duration} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_duration_pb.Duration,
        14
      )
    );
  };

/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.setUnhealthyInterval =
  function (value) {
    return jspb.Message.setWrapperField(this, 14, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.clearUnhealthyInterval =
  function () {
    return this.setUnhealthyInterval(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.hasUnhealthyInterval =
  function () {
    return jspb.Message.getField(this, 14) != null;
  };

/**
 * optional google.protobuf.Duration unhealthy_edge_interval = 15;
 * @return {?proto.google.protobuf.Duration}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.getUnhealthyEdgeInterval =
  function () {
    return /** @type{?proto.google.protobuf.Duration} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_duration_pb.Duration,
        15
      )
    );
  };

/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.setUnhealthyEdgeInterval =
  function (value) {
    return jspb.Message.setWrapperField(this, 15, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.clearUnhealthyEdgeInterval =
  function () {
    return this.setUnhealthyEdgeInterval(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.hasUnhealthyEdgeInterval =
  function () {
    return jspb.Message.getField(this, 15) != null;
  };

/**
 * optional google.protobuf.Duration healthy_edge_interval = 16;
 * @return {?proto.google.protobuf.Duration}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.getHealthyEdgeInterval =
  function () {
    return /** @type{?proto.google.protobuf.Duration} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_duration_pb.Duration,
        16
      )
    );
  };

/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.setHealthyEdgeInterval =
  function (value) {
    return jspb.Message.setWrapperField(this, 16, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.clearHealthyEdgeInterval =
  function () {
    return this.setHealthyEdgeInterval(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.hasHealthyEdgeInterval =
  function () {
    return jspb.Message.getField(this, 16) != null;
  };

/**
 * optional string event_log_path = 17;
 * @return {string}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.getEventLogPath = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ''));
};

/**
 * @param {string} value
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.setEventLogPath = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 17, value);
};

/**
 * optional EventServiceConfig event_service = 22;
 * @return {?proto.envoy.config.core.v3.EventServiceConfig}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.getEventService = function () {
  return /** @type{?proto.envoy.config.core.v3.EventServiceConfig} */ (
    jspb.Message.getWrapperField(
      this,
      envoy_config_core_v3_event_service_config_pb.EventServiceConfig,
      22
    )
  );
};

/**
 * @param {?proto.envoy.config.core.v3.EventServiceConfig|undefined} value
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.setEventService = function (
  value
) {
  return jspb.Message.setWrapperField(this, 22, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.clearEventService =
  function () {
    return this.setEventService(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.hasEventService = function () {
  return jspb.Message.getField(this, 22) != null;
};

/**
 * optional bool always_log_health_check_failures = 19;
 * @return {boolean}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.getAlwaysLogHealthCheckFailures =
  function () {
    return /** @type {boolean} */ (
      jspb.Message.getBooleanFieldWithDefault(this, 19, false)
    );
  };

/**
 * @param {boolean} value
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.setAlwaysLogHealthCheckFailures =
  function (value) {
    return jspb.Message.setProto3BooleanField(this, 19, value);
  };

/**
 * optional TlsOptions tls_options = 21;
 * @return {?proto.envoy.config.core.v3.HealthCheck.TlsOptions}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.getTlsOptions = function () {
  return /** @type{?proto.envoy.config.core.v3.HealthCheck.TlsOptions} */ (
    jspb.Message.getWrapperField(
      this,
      proto.envoy.config.core.v3.HealthCheck.TlsOptions,
      21
    )
  );
};

/**
 * @param {?proto.envoy.config.core.v3.HealthCheck.TlsOptions|undefined} value
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.setTlsOptions = function (
  value
) {
  return jspb.Message.setWrapperField(this, 21, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.clearTlsOptions = function () {
  return this.setTlsOptions(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.hasTlsOptions = function () {
  return jspb.Message.getField(this, 21) != null;
};

/**
 * optional google.protobuf.Struct transport_socket_match_criteria = 23;
 * @return {?proto.google.protobuf.Struct}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.getTransportSocketMatchCriteria =
  function () {
    return /** @type{?proto.google.protobuf.Struct} */ (
      jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 23)
    );
  };

/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.setTransportSocketMatchCriteria =
  function (value) {
    return jspb.Message.setWrapperField(this, 23, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.envoy.config.core.v3.HealthCheck} returns this
 */
proto.envoy.config.core.v3.HealthCheck.prototype.clearTransportSocketMatchCriteria =
  function () {
    return this.setTransportSocketMatchCriteria(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.envoy.config.core.v3.HealthCheck.prototype.hasTransportSocketMatchCriteria =
  function () {
    return jspb.Message.getField(this, 23) != null;
  };

/**
 * @enum {number}
 */
proto.envoy.config.core.v3.HealthStatus = {
  UNKNOWN: 0,
  HEALTHY: 1,
  UNHEALTHY: 2,
  DRAINING: 3,
  TIMEOUT: 4,
  DEGRADED: 5
};

goog.object.extend(exports, proto.envoy.config.core.v3);
